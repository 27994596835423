export function getNumericalKeys(inputEnum: any): number[] {
  return Object.keys(inputEnum).filter(x => !isNaN(Number(x))).map(x => Number(x)).sort((a, b) => a - b);
}

export function getNormalizedEnumString(inputEnum: any): string {
  if (inputEnum) {
    return inputEnum.replace(/_/g, ' ');
  }
  return '';
}

export function mapEnumToOptions(inputEnum: any, exclusions?: any[]): any {
  const keys = getNumericalKeys(inputEnum);
  const result: { label: string, value: number }[] = [];
  keys.forEach((key: any, index: number) => {
    if (exclusions && exclusions.includes(key)) {
      return;
    }
    result[index] = {
      label: getNormalizedEnumString(inputEnum[key]),
      value: key,
    };
  });
  return result;
}
