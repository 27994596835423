import { IGetUserPendingOrdersParams, getUserPendingOrders } from '../../api/Orders/userPendingOrdersApi';
import { Dispatch } from 'redux';

export const GET_USER_PENDING_ORDERS_REQUEST = 'GET_USER_PENDING_ORDERS_REQUEST';
export type GET_USER_PENDING_ORDERS_REQUEST = typeof GET_USER_PENDING_ORDERS_REQUEST;

export const GET_USER_PENDING_ORDERS_RESPONSE = 'GET_USER_PENDING_ORDERS_RESPONSE';
export type GET_USER_PENDING_ORDERS_RESPONSE = typeof GET_USER_PENDING_ORDERS_RESPONSE;

export const GET_USER_PENDING_ORDERS_ERROR = 'GET_USER_PENDING_ORDERS_ERROR';
export type GET_USER_PENDING_ORDERS_ERROR = typeof GET_USER_PENDING_ORDERS_ERROR;

export const userPendingOrdersRequest = (
  params: IGetUserPendingOrdersParams,
): (dispatch: Dispatch) => Promise<void> => {
  return async (dispatch: Dispatch): Promise<void> => {
    try {
      dispatch({
        type: GET_USER_PENDING_ORDERS_REQUEST,
      });

      const response = await getUserPendingOrders(params);

      if (response.status === 200) {
        dispatch({
          type: GET_USER_PENDING_ORDERS_RESPONSE,
          response: response.data.pendingOrders,
        });
      } else {
        onError(dispatch, response.data as unknown as string);
      }
    } catch (error) {
      console.log(error);
      onError(dispatch);
    }
  };
};

const onError = (dispatch: Dispatch, message: string = null): any => dispatch({
  type: GET_USER_PENDING_ORDERS_ERROR,
  response: message,
});
