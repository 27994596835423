import { ICancelOrderParams, cancelOrder } from '../../api/Orders/cancelOrderApi';
import { Dispatch } from 'redux';

export const CANCEL_ORDER_REQUEST = 'CANCEL_ORDER_REQUEST';
export type CANCEL_ORDER_REQUEST = typeof CANCEL_ORDER_REQUEST;

export const CANCEL_ORDER_RESPONSE = 'CANCEL_ORDER_RESPONSE';
export type CANCEL_ORDER_RESPONSE = typeof CANCEL_ORDER_RESPONSE;

export const CANCEL_ORDER_ERROR = 'CANCEL_ORDER_ERROR';
export type CANCEL_ORDER_ERROR = typeof CANCEL_ORDER_ERROR;

export const cancelOrderRequest = (params: ICancelOrderParams,
): (dispatch: Dispatch) => Promise<void> => {
  return async (dispatch: Dispatch): Promise<void> => {
    try {
      dispatch({
        type: CANCEL_ORDER_REQUEST,
      });

      const response = await cancelOrder(params);

      if (response.status === 200) {
        dispatch({
          type: CANCEL_ORDER_RESPONSE,
          response: {},
        });
      } else {
        onError(dispatch, response.data as unknown as string);
      }
    } catch (error) {
      console.log(error);
      onError(dispatch);
    }
  };
};

const onError = (dispatch: Dispatch, message: string = null): any => dispatch({
  type: CANCEL_ORDER_ERROR,
  response: message,
});
