import { Reducer } from 'redux';
import {
  GET_PRODUCT_EXCLUSIONS_ERROR,
  GET_PRODUCT_EXCLUSIONS_REQUEST,
  GET_PRODUCT_EXCLUSIONS_RESPONSE } from '../actions/productExclusionAction';
import { IProductExclusion, IApiRequest } from '../types';

const initialState: IApiRequest<IProductExclusion[]> = {
  data: [],
  error: '',
  UpdatedAt: null,
  loading: false,
};

const productExclusionsReducer: Reducer<IApiRequest<IProductExclusion[]>>
= (state: IApiRequest<IProductExclusion[]> = initialState, action : any) => {
  switch (action.type) {
    case GET_PRODUCT_EXCLUSIONS_RESPONSE: {
      return {
        data: action.response,
        error: '',
        UpdatedAt: new Date(),
        loading: false,
      };
    }
    case GET_PRODUCT_EXCLUSIONS_REQUEST: {
      return {
        data: [],
        error: '',
        UpdatedAt: null,
        loading: true,
      };
    }
    case GET_PRODUCT_EXCLUSIONS_ERROR:
    default: {
      return state;
    }
  }
};

export default productExclusionsReducer;
