import Fetch from '../apiConfig/apiConfig';
import { AxiosPromise } from 'axios';
import { IDocument } from '../../types';

export interface IUploadStoreDocumentParams {
  retailerId: number;
  storeId?: number;
  customerGroup?: string;
  fileName: string;
  data: string;
  type: string;
  documentType: number;
}

export interface IUploadStoreDocumentResponse {
  uploaded: IDocument;
}

export const uploadStoreDocument = (
  params: IUploadStoreDocumentParams,
  updateProgress: (fileName: string, percentComplete: number) => void): AxiosPromise<IUploadStoreDocumentResponse> => {
  return Fetch().then(axios => axios.post('store-document/admin/upload', params, {
    onUploadProgress: (progressEvent) => {
      let percentComplete = progressEvent.loaded / progressEvent.total;
      percentComplete = percentComplete * 100;
      updateProgress(params.fileName, percentComplete);
    },
  }));
};
