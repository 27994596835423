import React from 'react';
import './SearchError.scss';
import { Button } from '@orderly/morrisons-component-library';
import { navigateTo } from '../../routing/Navigation';
import ProductCarousel from '../ProductCarousel/ProductCarousel';
import { IStoreState } from '../../types';
import { connect } from 'react-redux';

interface ISearchErrorProps {
  children: any;
  maxOrderQuantity: number;
}

class SearchError extends React.Component<ISearchErrorProps & any, any> {
  public render(): JSX.Element {
    const { children, relatedProductsArray, maxOrderQuantity } = this.props;
    const relatedProducts = relatedProductsArray[Object.keys(relatedProductsArray)[0]];

    return (
    <div className="search-error-wrapper">
      <section className="search-error">
        <h1 className="error-message">{children}</h1>
        <Button
          type="button"
          className="secondary nav-homepage"
          icon="icon-home"
          iconPos="left"
          text="Back to Homepage"
          onClick={() => navigateTo('/home')}
        />
        <hr />
        <section className="search-tips-wrapper">
          <h3 className="tips-heading">Search tips</h3>
          <ul className="search-tips">
            <li>Use a search term without punctuation</li>
            <li>Try a similar search term, like jam instead of compote</li>
            <li>Check your spelling and any spacing</li>
            <li>Keep your search term simple</li>
          </ul>
        </section>
      </section>
      <section className="related-products">
        <div className="background" />
        <h2>You also might like</h2>
        {
          relatedProducts !== undefined &&
          <ProductCarousel
            products={relatedProducts.data}
            maxOrderQuantity={maxOrderQuantity}
          />
        }
      </section>
    </div>);
  }
}

const mapStateToProps = (state: IStoreState) => {
  return {
    relatedProductsArray: state.topOffers,
  };
};

export default connect(mapStateToProps)(SearchError);
