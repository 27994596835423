export const formatDateToMonthYear = (date: Date, shortenMonth: boolean = false): string => {
  const months = [
    'January', 'February', 'March', 'April', 'May', 'June',
    'July', 'August', 'September', 'October', 'November', 'December',
  ];
  let month = months[date.getMonth()];
  const year = date.getFullYear();

  if (shortenMonth) {
    month = month.substring(0, 3);
  }

  return `${month} ${year}`;
};
