import { Reducer } from 'redux';
import { IApiRequest, IFolder } from '../../types/index';
import {
  DELETE_FOLDER_RESPONSE,
  DELETE_FOLDER_REQUEST,
  DELETE_FOLDER_ERROR,
} from '../../actions/Documents/deleteFolderAction';

const initialState: IApiRequest<IFolder> = {
  data: undefined,
  error: '',
  UpdatedAt: null,
  loading: false,
};

const deleteFolderReducer: Reducer<IApiRequest<IFolder>>
= (state: IApiRequest<IFolder> = initialState, action : any) => {
  switch (action.type) {
    case DELETE_FOLDER_RESPONSE: {
      return {
        data: action.response,
        error: '',
        UpdatedAt: new Date(),
        loading: false,
      };
    }
    case DELETE_FOLDER_REQUEST: {
      return {
        data: undefined,
        error: '',
        UpdatedAt: null,
        loading: true,
      };
    }
    case DELETE_FOLDER_ERROR:
    default: {
      return state;
    }
  }
};

export default deleteFolderReducer;
