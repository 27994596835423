import { AxiosPromise } from 'axios';
import { ITradingPack } from '../../types';
import Fetch from '../apiConfig/apiConfig';

interface ICurrentTradingPackResponse {
  tradingPack: ITradingPack;
}

export const getCurrentTradingPack = (): AxiosPromise<ICurrentTradingPackResponse> => {
  return Fetch().then(axios => axios.get('trading-pack/current'));
};
