import { Reducer } from 'redux';
import { IApiRequest, IDocument } from '../../types/index';
import {
  DOCUMENT_DETAILS_REQUEST,
  DOCUMENT_DETAILS_RESPONSE,
  DOCUMENT_DETAILS_ERROR,
} from '../../actions/Documents/getDocumentDetailsAction';

const initialState: IApiRequest<IDocument> = {
  data: undefined,
  error: '',
  UpdatedAt: null,
  loading: false,
};

const getDocumentDetailsReducer: Reducer<IApiRequest<IDocument>>
= (state: IApiRequest<IDocument> = initialState, action : any) => {
  switch (action.type) {
    case DOCUMENT_DETAILS_RESPONSE: {
      return {
        data: action.response,
        error: '',
        UpdatedAt: new Date(),
        loading: false,
      };
    }
    case DOCUMENT_DETAILS_REQUEST: {
      return {
        data: undefined,
        error: '',
        UpdatedAt: null,
        loading: true,
      };
    }
    case DOCUMENT_DETAILS_ERROR:
      return {
        data: state.data,
        error: action.response,
        loading: false,
        UpdatedAt: new Date(),
      };
    default: {
      return state;
    }
  }
};

export default getDocumentDetailsReducer;
