import { store } from '../../../store';
import { IOrder } from '../../../pages/Orders/IOrder';
import { IOrderStatusFilter } from '../../../helpers/Orders/IOrderStatusFilter';

const orderStatusFiltersAction = (orderStatusLabels: IOrderStatusFilter[]) => {
  const ordersList: any = store.getState().ordersList;
  const filterByStatus: string[] = [];
  let filteredByStatus: IOrder[] = ordersList && ordersList.data && ordersList.data.mappedOrders;

  for (const label of orderStatusLabels) {
    if (label.isChecked) {
      filterByStatus.push(label.status);
    }
  }

  filteredByStatus = filteredByStatus.filter((item) => {
    if (!!filterByStatus.length) {
      return filterByStatus.indexOf(item.status) > -1;
    }

    return true;
  });

  return {
    type: 'ORDER_STATUS_FILTERED_ORDERS',
    data: filteredByStatus,
  };
};

export default orderStatusFiltersAction;
