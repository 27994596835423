import Fetch from './apiConfig/apiConfig';
import { AxiosPromise } from 'axios';
import queryString from 'query-string';

export interface IGetTradingGroupsParams {
  [key: string]: any;
  categoryId: number;
}

export interface ITradingGroupsResponse {
  tradingGroups: string[];
}

export const getTradingGroups = (params: IGetTradingGroupsParams): AxiosPromise<ITradingGroupsResponse> => {
  return Fetch().then(axios => axios.get(`products/trading-groups?${queryString.stringify(params)}`));
};
