import React, { useEffect, useRef } from 'react';
import { Checkbox, Dropdown, TextArea, TextInput } from '@orderly/morrisons-component-library';
import { POSTemplates } from '../../../../helpers/POSCreator/POSTemplates.enum';
import { POSPromotionTypes } from '../../../../helpers/POSCreator/POSPromotionTypes.enum';
import './PersonalizationPane.scss';
import { CurrencyType } from '../../../../helpers/Product/ProductPriceHelper';
import { IPOSProductPreviewDetails } from '../POSCreator';
import { convertToCurrency } from '../../../../helpers/POSCreator/POSCreatorHelper';
import { mapEnumToOptions } from '../../../../helpers/EnumHelper';
import useDebounce from '../../../../helpers/Hooks/useDebounce';

export interface IProps {
  product: IPOSProductPreviewDetails;
  updateProductDetails: Function;
  search: Function;
  templateType: POSTemplates;
  productDetailsCount: number;
}

const promotions = mapEnumToOptions(POSPromotionTypes);
const IDENTIFIER_FIELD_MAX_LENGTH = 35;

const TEMPLATE_DESCRIPTION_LIMITS: Record<POSTemplates, number> = {
  [POSTemplates.Six_By_Three]: 80,
  [POSTemplates.A4]: 30,
};

const PROMOTION_DESCRIPTION_LIMITS: Record<POSPromotionTypes | number, number> = {
  [POSPromotionTypes.Better_Than_Half_Price]: 50,
  [POSPromotionTypes.Half_Price]: 50,
  [POSPromotionTypes.BOGOF]: 50,
  [POSPromotionTypes.Save_a_Third]: 50,
  [POSPromotionTypes.POS_General]: 80,
};

const MULTIBUY_VALUE_LIMITS = [2, 6];

const POUND_INCREMENT_STEP = '0.01';
const PENCE_INCREMENT_STEP = '1';

export const enum POSPriceType {
  Normal_Price = 0,
  Prev_price = 1,
  Multibuy_Price = 2,
}

export const PersonalizationPane = (props: IProps) => {
  const [debouncedIdentifier] = useDebounce([props.product.identifier], 500);

  const prevProps = useRef<IProps>();

  const {
    product,
    updateProductDetails,
    templateType,
    productDetailsCount,
    search,
  } = props;

  const {
    identifier,
    description,
    price,
    prevPrice,
    priceCurrency,
    prevPriceCurrency,
    prevPriceStrikethrough,
    weight,
    multibuyValue,
    multiBuyProductCount,
    multibuyValueCurrency,
    promotionType,
    challenge25,
    drinkaware,
    maxAllowedPrice,
  } = product;

  useEffect(() => {
    search(identifier);
  }, [debouncedIdentifier]);

  useEffect(() => {
    const min = MULTIBUY_VALUE_LIMITS[0];
    const max = MULTIBUY_VALUE_LIMITS[1];

    if (multiBuyProductCount < min) {
      updateProductDetails({ ...product, multiBuyProductCount: min });
    } else if (multiBuyProductCount > max) {
      updateProductDetails({ ...product, multiBuyProductCount: max });
    }
  }, [multiBuyProductCount]);

  useEffect(() => {
    if (weight.length > 12) {
      updateProductDetails({ ...product, weight: weight.slice(0, 12) });
    }
  }, [weight]);

  useEffect(() => {
    const updatedProduct = { ...product };

    const promotionLimit = PROMOTION_DESCRIPTION_LIMITS[promotionType];
    const templateLimit = TEMPLATE_DESCRIPTION_LIMITS[templateType];

    const limit = promotionLimit ? promotionLimit : templateLimit;

    if (description.length > limit) {
      updateProductDetails({ ...product, description: description.slice(0, limit) });
      updatedProduct.description = description.slice(0, limit);
    }

    if ((promotionType === POSPromotionTypes.No_Strikethrough) &&
      parseFloat(prevPrice) > 0) {
      updatedProduct.prevPrice = '';
    }

    if ([POSPromotionTypes.Strikethrough,
      POSPromotionTypes.Half_Price,
      POSPromotionTypes.Better_Than_Half_Price,
      POSPromotionTypes.BOGOF].includes(promotionType)) {
      updatedProduct.prevPriceStrikethrough = true;
    }

    if (prevProps.current && prevProps.current.product.promotionType !== promotionType &&
      promotionType === POSPromotionTypes.POS_General) {
      updatedProduct.description = '';
    }

    if (promotionType === POSPromotionTypes.Multibuy) {
      updatedProduct.prevPriceStrikethrough = false;
    }

    prevProps.current = props;

    updateProductDetails(updatedProduct);
  }, [promotionType, description, templateType]);

  const validatePriceLength = (price: string) => {
    const maxLength = 5;
    const maxDecimalLength = 2;

    if (price.includes('.')) {
      const [integerPart, decimalPart] = price.split('.');

      const truncatedInteger = integerPart.slice(0, maxDecimalLength).replace(/\D/g, '');
      const truncatedDecimal = decimalPart.slice(0, maxDecimalLength).replace(/\D/g, '');

      return (`${truncatedInteger}.${truncatedDecimal}`).slice(0, maxLength);
    }

    if (!price.includes('.') && price.length > 2) {
      return price.slice(0, 2);
    }
    return price;
  };

  const handleEndZero = (originalPrice: string, validatedPrice: string) => {
    let newPrice = validatedPrice;

    const regex = /\.0(?!\d)/; // matches a 0 after decimal point

    if (regex.test(originalPrice)) {
      newPrice += '.0';
    } else if (originalPrice.endsWith('0')) {
      newPrice += '0';
    }

    newPrice = validatePriceLength(newPrice);

    return newPrice;
  };

  const validatePrice = (
    price: string,
    inPences: boolean,
    priceType: POSPriceType,
    currencyType: CurrencyType,
    maxAllowedPrice?: number) => {
    let newPrice = 0;

    if (!price || parseFloat(price) <= 0) {
      updatePrice('', currencyType, priceType, maxAllowedPrice);
    } else {
      const newCurrency = inPences ? CurrencyType.Pence : CurrencyType.Pound;
      let newMaxAllowedPrice = maxAllowedPrice;

      const validatedPrice = validatePriceLength(price);
      newPrice = parseFloat(validatedPrice);

      if (currencyType !== newCurrency) {
        newPrice = convertToCurrency(newPrice, inPences ? CurrencyType.Pence : CurrencyType.Pound);
        newMaxAllowedPrice = convertToCurrency(newMaxAllowedPrice, inPences ? CurrencyType.Pence : CurrencyType.Pound);
        newPrice = Math.round(newPrice * 100) / 100;
      }

      if (newCurrency === CurrencyType.Pence) {
        newPrice = parseInt(newPrice.toString(), 10);
      }

      // parseFloat removes the last 0 but we need it if explicitly typed
      const newPriceStr = handleEndZero(price, newPrice.toString());

      updatePrice(newPriceStr, newCurrency, priceType, newMaxAllowedPrice);
    }
  };

  const updatePrice = (price: string, currency: CurrencyType, type: POSPriceType, maxAllowedPrice?: number) => {
    switch (type) {
      case POSPriceType.Prev_price:
        updateProductDetails({ ...product, prevPrice: price, prevPriceCurrency: currency });
        break;
      case POSPriceType.Multibuy_Price:
        updateProductDetails({ ...product, multibuyValue: price, multibuyValueCurrency: currency });
        break;
      default:
        updateProductDetails({ ...product, price, maxAllowedPrice, priceCurrency: currency });
        break;
    }
  };

  const getProductRelatedFields = () => {
    return (
      <>
        <div className="personalization-pane-input">
          <TextInput
            error={!identifier ? 'This field is required' : ''}
            label="MIN / PIN / Barcode / Name"
            name="min"
            onChange={value => updateProductDetails({ ...product, identifier: value.slice(0, IDENTIFIER_FIELD_MAX_LENGTH) })}
            type="text"
            value={identifier.length > 0 ? identifier : ''}
          />
        </div>
        <>
          <div className="personalization-pane-input">
            <TextInput
              error={!description ? 'This field is required' : ''}
              label="Product description"
              name="description"
              onChange={value => updateProductDetails({ ...product, description: value })}
              type="text"
              value={description}
            />
          </div>
          <div className="personalization-pane-input">
            <TextInput
              label="Weight/Size"
              name="weight/size"
              onChange={value => updateProductDetails({ ...product, weight: value })}
              type="text"
              value={weight}
            />
          </div>

        </>
      </>
    );
  };

  const getStickerFields = () => {
    return (
      <div className="personalization-pane-input row">
        <Checkbox
          checked={drinkaware}
          label="Drinkaware"
          name={`drinkaware ${product.idx}`}
          onChange={(id, checked) => updateProductDetails({ ...product, drinkaware: checked })}
        />
        <Checkbox
          checked={challenge25}
          label="Challenge25"
          name={`challenge25 ${product.idx}`}
          onChange={(id, checked) => updateProductDetails({ ...product, challenge25: checked })}
        />
      </div>
    );
  };

  const getPriceErrorMessage = (priceType: POSPriceType) => {
    let productPrice = parseFloat(price);
    const prevPriceFloat = parseFloat(prevPrice);
    const multibuyValueFloat = parseFloat(multibuyValue);

    let errorMessage = '';

    if (priceType === POSPriceType.Normal_Price) {
      if (!productPrice) {
        errorMessage = 'Price: This field is required';
      } else if (productPrice > maxAllowedPrice) {
        errorMessage = 'Price: Cannot be greater than RRP';
      }
    } else if (priceType === POSPriceType.Prev_price) {
      if (!prevPrice) {
        errorMessage = 'Previous price: This field is required';
      } else {
        if (priceCurrency !== prevPriceCurrency) {
          productPrice = convertToCurrency(productPrice, prevPriceCurrency);
        }

        if (prevPriceFloat <= productPrice) {
          errorMessage = 'Previous price cannot be smaller than price';
        }
      }
    } else {
      if (!multibuyValueFloat) {
        errorMessage = 'Multibuy: This field is required';
      }
    }
    return errorMessage;
  };

  const getPriceFields = () => {
    return (
      <div className="personalization-pane-input">
        <div
          className={`textbox ${parseFloat(price) >= 0 ? 'isNotEmpty' : ''}
          ${getPriceErrorMessage(POSPriceType.Normal_Price) ? 'error' : ''}`}
        >
          <input
            value={price}
            onChange={e =>
              validatePrice(
                e.target.value,
                priceCurrency === CurrencyType.Pence,
                POSPriceType.Normal_Price,
                priceCurrency,
                maxAllowedPrice,
              )}
            type="number"
            name="price"
            id="price"
            step={priceCurrency === CurrencyType.Pence ? PENCE_INCREMENT_STEP : POUND_INCREMENT_STEP}
          />
          <label htmlFor="price" className="input-label">
            {
              getPriceErrorMessage(POSPriceType.Normal_Price) ? getPriceErrorMessage(POSPriceType.Normal_Price) : 'Price'
            }
          </label>
        </div>
        {
          (parseFloat(price) < 1 || priceCurrency === CurrencyType.Pence) &&
          <Checkbox
            checked={priceCurrency === CurrencyType.Pence}
            label="Pences"
            name={`currencyType ${product.idx}`}
            onChange={(id, checked) =>
              validatePrice(
                price,
                checked,
                POSPriceType.Normal_Price,
                priceCurrency,
                maxAllowedPrice,
              )}
          />
        }
      </div>
    );
  };

  const getPrevPriceFields = () => {
    return (
      <div className="personalization-pane-input">
        <div
          className={`textbox ${parseFloat(prevPrice) >= 0 ? 'isNotEmpty' : ''}
          ${getPriceErrorMessage(POSPriceType.Prev_price) ? 'error' : ''}
          ${templateType === POSTemplates.Six_By_Three ? 'compact' : ''}`}
        >
          <input
            value={prevPrice}
            onChange={e =>
              validatePrice(
                e.target.value,
                prevPriceCurrency === CurrencyType.Pence,
                POSPriceType.Prev_price,
                prevPriceCurrency,
              )}
            type="number"
            name="prev price"
            id="prev-price"
            step={priceCurrency === CurrencyType.Pence ? PENCE_INCREMENT_STEP : POUND_INCREMENT_STEP}
          />
          <label htmlFor="prev price" className="input-label">
            {
              getPriceErrorMessage(POSPriceType.Prev_price) ?
                getPriceErrorMessage(POSPriceType.Prev_price) : 'Previous Price'
            }
          </label>
        </div>
        {
          (parseFloat(prevPrice) < 1 || prevPriceCurrency === CurrencyType.Pence) &&
          <Checkbox
            checked={prevPriceCurrency === CurrencyType.Pence}
            label="Pences"
            name={`prevCurrencyType ${product.idx}`}
            onChange={(id, checked) =>
              validatePrice(
                prevPrice,
                checked,
                POSPriceType.Prev_price,
                prevPriceCurrency,
              )}
          />
        }
        {
          (parseFloat(prevPrice) > 0) &&
          promotionType !== POSPromotionTypes.Strikethrough &&
          promotionType !== POSPromotionTypes.Half_Price &&
          promotionType !== POSPromotionTypes.Better_Than_Half_Price &&
          promotionType !== POSPromotionTypes.BOGOF &&
          <Checkbox
            checked={prevPriceStrikethrough}
            label="Strikethrough"
            name={`strikethrough ${product.idx}`}
            onChange={(id, checked) => updateProductDetails({ ...product, prevPriceStrikethrough: checked })}
          />
        }
      </div>
    );
  };

  const getPromotionRelatedFields = () => {
    const fields = [getProductRelatedFields()];
    switch (promotionType) {
      case POSPromotionTypes.POS_General:
        return (
          <div className="personalization-pane-input">
            <TextArea
              validationError={!description ? 'This field is required' : ''}
              label="Description"
              name="description"
              onChange={value => updateProductDetails({ ...product, description: value })}
              value={description}
            />
          </div>
        );
      case POSPromotionTypes.No_Strikethrough:
        fields.push(getPriceFields());
        break;
      case POSPromotionTypes.Half_Price:
      case POSPromotionTypes.Better_Than_Half_Price:
      case POSPromotionTypes.Save_a_Third:
      case POSPromotionTypes.BOGOF:
        fields.push(getPrevPriceFields());
        fields.push(getPriceFields());
        break;
      case POSPromotionTypes.Strikethrough:
        fields.push(getPriceFields(), getPrevPriceFields());
        break;
      case POSPromotionTypes.Multibuy:
        fields.push(getPriceFields());
        fields.push(
          <div className="personalization-pane-input">
            <div
              className={`textbox ${parseFloat(multibuyValue) >= 0 ? 'isNotEmpty' : ''}
              ${getPriceErrorMessage(POSPriceType.Multibuy_Price) ? 'error' : ''}`}
            >
              <input
                value={multibuyValue ? multibuyValue : ''}
                onChange={e =>
                  validatePrice(
                    e.target.value,
                    multibuyValueCurrency === CurrencyType.Pence,
                    POSPriceType.Multibuy_Price,
                    multibuyValueCurrency,
                  )}
                type="number"
                name="multibuy"
                id="multibuy"
                step={priceCurrency === CurrencyType.Pence ? PENCE_INCREMENT_STEP : POUND_INCREMENT_STEP}
              />
              <label htmlFor="multibuy" className="input-label">
                {getPriceErrorMessage(POSPriceType.Multibuy_Price) ?
                  getPriceErrorMessage(POSPriceType.Multibuy_Price) : 'Multibuy Value'}
              </label>
            </div>
            {
              (parseFloat(multibuyValue) < 1 || multibuyValueCurrency === CurrencyType.Pence) &&
              <Checkbox
                checked={multibuyValueCurrency === CurrencyType.Pence}
                label="Pences"
                name={`mutibuyCurrencyType ${product.idx}`}
                onChange={(id, checked) =>
                  validatePrice(
                    multibuyValue,
                    checked,
                    POSPriceType.Multibuy_Price,
                    multibuyValueCurrency,
                  )}
              />
            }
          </div>,
          (
            <div className="personalization-pane-input">
              <TextInput
                label="Quantity"
                name="multibuy-amount"
                onChange={value => updateProductDetails({ ...product, multiBuyProductCount: value })}
                type="number"
                value={multiBuyProductCount.toString()}
              />
            </div>
          ),
        );
        break;
    }

    fields.push(getStickerFields());

    return (
      <>
        {
          fields.map(x => x)
        }
        <hr />
      </>
    );
  };

  return (
    <div className="personalization-pane-wrapper">
      <div className="personalization-pane-container">
        <div className="personalization-pane-input identifier">
          <Dropdown
            label={identifier.length > 0 ? `Product: ${identifier}` : 'Product details'}
            name="promotion"
            onChange={e => updateProductDetails({ ...product, promotionType: e.value })}
            options={promotions}
            selectedValue={promotionType}
          />
          <div className="personalization-pane-actions">
            {
              templateType === POSTemplates.Six_By_Three && productDetailsCount < 4 &&
              <span
                title="Duplicate"
                className="personalization-pane-btn icon-copy"
                onClick={() => updateProductDetails(product, true)}
              />
            }
            {
              product.idx > 0 &&
              <span
                title="Delete"
                className="personalization-pane-btn icon-Delete"
                onClick={() => updateProductDetails({ ...product, identifier: '-1' })}
              />
            }
          </div>
        </div>
        {
          getPromotionRelatedFields()
        }
      </div>
    </div>
  );
};
