import { AxiosPromise } from 'axios';
import { ITradingPack } from '../../types';
import Fetch from '../apiConfig/apiConfig';

interface ITradingPacksResponse {
  tradingPacks: ITradingPack[];
}

export const getTradingPacks = (): AxiosPromise<ITradingPacksResponse> => {
  return Fetch().then(axios => axios.get('trading-packs'));
};

interface ITradingPackResponse {
  tradingPack: ITradingPack;
}

export const getTradingPack = (id: number): AxiosPromise<ITradingPackResponse> => {
  return Fetch().then(axios => axios.get(`trading-pack/get?id=${id}`));
};

interface ITradingPackResponse {
  tradingPacks: ITradingPack[];
}

export const deleteTradingPack = (id: number): AxiosPromise<ITradingPackResponse> => {
  return Fetch().then(axios => axios.post('trading-pack/delete', { id }));
};

export interface ISaveTradingPackBody {
  id: number;
  description: string;
  startDate: string;
  endDate: string;
  retailerIds: number[];
  customerGroups: string[];
  importItems: boolean;
  data: string;
}

interface ITradingPackResponse {
  response: ISaveTradingPackResponse;
}

interface ISaveTradingPackResponse {
  results: string[];
}

export const saveTradingPack = (body: ISaveTradingPackBody): AxiosPromise<ISaveTradingPackResponse> => {
  return Fetch().then(axios => axios.post('trading-pack/save', body));
};
