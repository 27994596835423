import { Action } from 'redux';
import { IDocument } from '../../types';
import {
  IWatchFolderParams,
  watchFolder,
  IWatchFolderResponse as IWatchFolderResponseAPI,
} from '../../api/Documents/watchFolderApi';
import { AxiosResponse } from 'axios';

export const WATCH_FOLDER_REQUEST = 'WATCH_FOLDER_REQUEST';
export type WATCH_FOLDER_REQUEST = typeof WATCH_FOLDER_REQUEST;

export const WATCH_FOLDER_RESPONSE = 'WATCH_FOLDER_RESPONSE';
export type WATCH_FOLDER_RESPONSE = typeof WATCH_FOLDER_RESPONSE;

export const WATCH_FOLDER_ERROR = 'WATCH_FOLDER_ERROR';
export type WATCH_FOLDER_ERROR = typeof WATCH_FOLDER_ERROR;

export interface IWatchFolderResponse extends Action {
  type: WATCH_FOLDER_RESPONSE;
  response: IDocument;
}

export interface IWatchFolderError extends Action {
  type: WATCH_FOLDER_ERROR;
  error?: string;
}

export const watchFolderRequest =
(parameters: IWatchFolderParams): any => {
  return (dispatch : any) => {

    dispatch({
      type: WATCH_FOLDER_REQUEST,
      payload: parameters,
    });

    return watchFolder(parameters)
      .then((response: AxiosResponse<IWatchFolderResponseAPI>) => {
        if (response.status === 200) {
          dispatch({
            type: WATCH_FOLDER_RESPONSE,
          });
        } else {
          onError(dispatch);
        }
      });
  };
};

const onError = (dispatch: any) => dispatch({
  type: WATCH_FOLDER_ERROR,
});
