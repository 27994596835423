import { Reducer } from 'redux';
import { IReviewOrderClaimsDisplayModeState } from '../../pages/Orders/OrderClaims/IReviewOrderClaimsDisplayModeState';

const initialState: IReviewOrderClaimsDisplayModeState = {
  displayMode: 'active',
};

const reviewOrderClaimsDisplayModeReducer: Reducer<IReviewOrderClaimsDisplayModeState> = (
  state: IReviewOrderClaimsDisplayModeState = initialState,
  action: any,
): IReviewOrderClaimsDisplayModeState => {
  if (action.type === 'CLAIMS_DISPLAY_MODE_PERSIST') {
    return {
      displayMode: action.data.displayMode,
    };
  }

  return state;
};

export default reviewOrderClaimsDisplayModeReducer;
