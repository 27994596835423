import { Reducer } from 'redux';
import { IPresalesState } from '../../types';
import * as PresalesActions from '../../actions/SupplyChain/presalesActions';

const initialState: IPresalesState = {
  error: '',
  loading: false,
  campaign: null,
  campaigns: {
    activeCampaigns: [],
    expiredCampaigns: [],
  },
  exampleTemplate: null,
};

const presalesReducer: Reducer<IPresalesState> =
  (state: IPresalesState = initialState, action: any) => {
    switch (action.type) {
      case PresalesActions.GET_CAMPAIGNS_REQUEST:
      case PresalesActions.GET_CAMPAIGN_REQUEST:
      case PresalesActions.DELETE_CAMPAIGN_REQUEST:
      case PresalesActions.SUBMIT_CAMPAIGN_REQUEST:
      case PresalesActions.CREATE_CAMPAIGN_REQUEST:
      case PresalesActions.EDIT_CAMPAIGN_REQUEST:
      case PresalesActions.UPDATE_CAMPAIGN_SUBMISSION_REQUEST:
      case PresalesActions.DOWNLOAD_EXAMPLE_TEMPLATE_REQUEST:
      case PresalesActions.PUBLISH_CAMPAIGN_REQUEST: {
        return {
          ...state,
          error: '',
          loading: true,
        };
      }
      case PresalesActions.GET_CAMPAIGNS_RESPONSE: {
        const { campaigns, active } = action.response;
        return {
          ...state,
          error: '',
          loading: false,
          campaigns: {
            ...state.campaigns,
            [active ? 'activeCampaigns' : 'expiredCampaigns']: campaigns,
          },
        };
      }
      case PresalesActions.EDIT_CAMPAIGN_RESPONSE: {
        return {
          ...state,
          error: '',
          loading: false,
          campaign: action.response,
        };
      }
      case PresalesActions.GET_CAMPAIGN_RESPONSE:
      case PresalesActions.CREATE_CAMPAIGN_RESPONSE: {
        return {
          ...state,
          error: '',
          loading: false,
          campaign: action.response,
        };
      }
      case PresalesActions.DELETE_CAMPAIGN_RESPONSE:
      case PresalesActions.SUBMIT_CAMPAIGN_RESPONSE:
      case PresalesActions.PUBLISH_CAMPAIGN_RESPONSE:
      case PresalesActions.UPDATE_CAMPAIGN_SUBMISSION_RESPONSE: {
        return {
          ...state,
          error: '',
          loading: false,
        };
      }
      case PresalesActions.DOWNLOAD_EXAMPLE_TEMPLATE_RESPONSE: {
        return {
          ...state,
          error: '',
          loading: false,
          exampleTemplate: action.response,
        };
      }
      case PresalesActions.GET_CAMPAIGNS_ERROR:
      case PresalesActions.GET_CAMPAIGN_ERROR:
      case PresalesActions.DELETE_CAMPAIGN_ERROR:
      case PresalesActions.SUBMIT_CAMPAIGN_ERROR:
      case PresalesActions.CREATE_CAMPAIGN_ERROR:
      case PresalesActions.EDIT_CAMPAIGN_ERROR:
      case PresalesActions.PUBLISH_CAMPAIGN_ERROR:
      case PresalesActions.DOWNLOAD_EXAMPLE_TEMPLATE_ERROR:
      case PresalesActions.UPDATE_CAMPAIGN_SUBMISSION_ERROR: {
        return {
          ...state,
          error: action.response || 'An error has occured. Please try again',
          loading: false,
        };
      }
      default: {
        return state;
      }
    }
  };

export default presalesReducer;
