import { Action } from 'redux';
import { IDocument } from '../../types';
import { AxiosResponse } from 'axios';
import { editDocument } from '../../api/Documents/editDocumentApi';
import { IEditDocumentParams } from '../../api/Documents/IEditDocumentParams';
import { IEditDocumentResponse } from '../../api/Documents/IEditDocumentResponse';

export const EDIT_DOCUMENT_REQUEST = 'EDIT_DOCUMENT_REQUEST';
export type EDIT_DOCUMENT_REQUEST = typeof EDIT_DOCUMENT_REQUEST;

export const EDIT_DOCUMENT_RESPONSE = 'EDIT_DOCUMENT_RESPONSE';
export type EDIT_DOCUMENT_RESPONSE = typeof EDIT_DOCUMENT_RESPONSE;

export const EDIT_DOCUMENT_ERROR = 'EDIT_DOCUMENT_ERROR';
export type EDIT_DOCUMENT_ERROR = typeof EDIT_DOCUMENT_ERROR;

export interface IEditDocumentResponse extends Action {
  type: EDIT_DOCUMENT_RESPONSE;
  response: IDocument;
}

export interface IEditDocumentError extends Action {
  type: EDIT_DOCUMENT_ERROR;
  error?: string;
}

export const editDocumentRequest =
(parameters: IEditDocumentParams,
 onSuccess: () => void,
 onFailure: (err: any) => void): any => {
  return (dispatch : any) => {

    dispatch({
      type: EDIT_DOCUMENT_REQUEST,
    });

    return editDocument(parameters)
      .then((response: AxiosResponse<IEditDocumentResponse>) => {
        if (response.status === 200) {
          dispatch({
            type: EDIT_DOCUMENT_RESPONSE,
            response: null,
          });

          onSuccess();
        } else {

          if (response instanceof Error) {
            onFailure(response);
          }
        }
      });
  };
};

const onError = (dispatch: any) => dispatch({
  type: EDIT_DOCUMENT_ERROR,
});
