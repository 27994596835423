import { IApiRequest, IBasket } from '../../types';
import { requireNewSlot } from './requireNewSlot';

export const addToBasketActive = (
  basket: IApiRequest<IBasket>,
  productCategory: string,
  productStatus: string,
  available: boolean): boolean => {

  const { data, loading } = basket;

  if (!data || data === null || loading) {
    return false;
  }

  const { categoryCode, combinedOrder } = data;
  if (requireNewSlot(basket)) {
    return false;
  }

  const sameCategory = combinedOrder || categoryCode === productCategory;
  const orderable = productStatus !== null && productStatus !== undefined && productStatus.toLowerCase() === 'orderable';
  return !(sameCategory && orderable && available);
};
