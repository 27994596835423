import { getBasketDetails } from '../../api/Basket/BasketApi';
import { IBasketResponse } from '../../api/Basket/IBasketResponse';
import { AxiosResponse } from 'axios';

export const CLEAR_BASKET_CONTENTS = 'CLEAR_BASKET_CONTENTS';
export type CLEAR_BASKET_CONTENTS = typeof CLEAR_BASKET_CONTENTS;

export const getBasketRequest = (): any => {

  return (dispatch : any) => {
    dispatch({
      type: 'GET_BASKET_REQUEST',
    });

    return getBasketDetails()
      .then((response: AxiosResponse<IBasketResponse>) => {
        if (response.status === 200) {
          dispatch({
            type: 'GET_BASKET_RESPONSE',
            response: response.data.order,
          });
        } else {
          onError(dispatch, response.data as unknown as string);
        }
      })
      .catch((err: any) => {
        console.log(err);
        onError(dispatch);
      });
  };
};

const onError = (dispatch: any, message: string = null) => dispatch({
  type: 'GET_BASKET_ERROR',
  response: message,
});

export const clearBasketContents = () => {
  return {
    type: CLEAR_BASKET_CONTENTS,
  };
};
