import { IOrderDetails } from '../../../types/index';
import { Dispatch } from 'redux';
import { getOrderDeliveryDetails, getOrders } from '../../../api/Retailers/retailersApi';
import { IGetOrdersParams } from '../../../api/Retailers/IGetOrdersParams';
import { mapOrders } from '../../../helpers/Orders/MapOrders';
import { IOrdersList } from '../../../reducers/Orders/OrdersList/IOrdersList';
import { IOrder } from '../../../pages/Orders/IOrder';
import { getOrderStatusFilters } from '../../../helpers/Orders/OrderStatusHelper';
import {
  IValidateOrdersClaimEligibilityResponse,
  validateOrdersClaimEligibilityApi,
} from '../../../api/Orders/orderClaimsApi';
import { AxiosResponse } from 'axios';
import { orderClaimEligibilityFilter } from '../../../helpers/Orders/OrderClaimEligibilityFilter';

const orderListOrdersAction = (params: IGetOrdersParams): (dispatch: Dispatch) => Promise<IOrdersList> => {
  return async (dispatch: Dispatch): Promise<IOrdersList> => {
    if (params) {
      try {
        dispatch({
          type: 'GET_ORDER_LIST_ORDERS_REQUEST',
        });

        const response: any = await getOrders(params);

        if (response.status === 200) {
          const result: IOrderDetails[] = response.data.orders.orders;
          const mappedOrders: IOrder[] = mapOrders(result);

          const claimsEligibleOrderNumbers: string[] = orderClaimEligibilityFilter(mappedOrders);
          const orderNumbers: string[] = mappedOrders.map(item => item.id);
          const deliveryResponse: any = await getOrderDeliveryDetails(orderNumbers);
          const deliveryData = deliveryResponse.data;
          const dhlEnabledOrders = deliveryData.orderDeliveryDetails ? deliveryData.orderDeliveryDetails : [];

          let validOrderClaimsResponse: AxiosResponse<IValidateOrdersClaimEligibilityResponse>;
          let ordersEligibleToRaiseClaims: { [key: string]: boolean } = {};

          if (claimsEligibleOrderNumbers.length > 0) {
            validOrderClaimsResponse = await validateOrdersClaimEligibilityApi({
              orderNumbers: claimsEligibleOrderNumbers,
            });

            ordersEligibleToRaiseClaims = validOrderClaimsResponse.data.getClaimEligibleOrders;
          }

          mappedOrders.forEach((order) => {
            const isDhlDelivery = order.deliveryNetwork ? order.deliveryNetwork.toString().toLowerCase() === 'dhl' : false;
            const isCashAndCarry = order.orderIdentifier ? order.orderIdentifier === 'CC' : false;

            order.dhlEnabled = isDhlDelivery && !isCashAndCarry ? true : false;

            order.canRaiseClaim = ordersEligibleToRaiseClaims[order.id] !== undefined
              ? ordersEligibleToRaiseClaims[order.id]
              : null;
          });

          const data: IOrdersList = {
            mappedOrders,
            response: result,
            orderStatusFilters: getOrderStatusFilters(mappedOrders),
          };

          dispatch({
            data,
            type: 'GET_ORDER_LIST_ORDERS_RESPONSE',
          });

          return data;
        }
      } catch (e) {
        console.log('An error has occured in orderListOrdersAction ', e);
      }
    }
  };
};

export default orderListOrdersAction;
