import { Card, Checkbox, Dropdown, TextInput } from '@orderly/morrisons-component-library';
import { EmptyRecord } from '../../../../../components';
import { BannerLocations } from '../../../../../types';
import React from 'react';
import styles from './BannerDetail.module.scss';
import { IBannerDetailProps } from './IBannerDetailProps';

const BannerDetail: React.FC<IBannerDetailProps> = React.memo(({
  formState,
  formErrors,
  handleInputChange,
  handleCustomerGroupSelection,
  handleCustomerGroupSearchChange,
  imagePreview,
  customerGroupSearch,
  filteredCustomerGroups,
}): JSX.Element => {
  return (
    <>
      <Card className={`${styles.detail} ${styles.card}`}>
        <h4>Banner Details</h4>
        <Dropdown
          name="displayLocation"
          label=""
          className="contact-us-subject"
          placeholder="Select a location"
          options={[{ label: 'Homepage', value: BannerLocations.Homepage }]}
          selectedValue={formState.displayLocation}
          onChange={value => handleInputChange('displayLocation', value.value)}
        />
        <TextInput
          error={formErrors.title}
          label="Title"
          name="title"
          onChange={value => handleInputChange('title', value.substring(0, 255))}
          type="text"
          value={formState.title}
        />
        <TextInput
          error={formErrors.description}
          label="Description"
          name="description"
          onChange={value => handleInputChange('description', value.substring(0, 255))}
          type="text"
          value={formState.description}
        />
        <TextInput
          error={formErrors.startDate}
          label="Start Date"
          name="startDate"
          onChange={value => handleInputChange('startDate', value)}
          type="date"
          value={formState.startDate}
        />
        <TextInput
          error={formErrors.endDate}
          label="End Date"
          name="endDate"
          onChange={value => handleInputChange('endDate', value)}
          type="date"
          value={formState.endDate}
        />
        <TextInput
          error=""
          label="Link"
          name="link"
          onChange={value => handleInputChange('link', value.substring(0, 255))}
          type="text"
          value={formState.link}
        />
        <TextInput
          error={formErrors.duration}
          label="Duration (seconds)"
          name="duration"
          onChange={value => handleInputChange('duration', parseInt(value, 10))}
          type="number"
          value={formState.duration.toString()}
        />
        <TextInput
          error={formErrors.order}
          label="Order"
          name="order"
          onChange={value => handleInputChange('order', parseInt(value, 10))}
          type="number"
          value={formState.order.toString()}
        />
        <Checkbox
          checked={formState.allRetailers}
          label="All Retailers"
          name="allRetailers"
          onChange={(id, checked) => handleInputChange('allRetailers', checked)}
          isSmall
        />
      </Card>
      <Card className={styles.card}>
        <h4>Customer Groups</h4>
        <div className={styles.search}>
          <TextInput
            error=""
            label="Search"
            name="customerGroupSearch"
            onChange={handleCustomerGroupSearchChange}
            type="text"
            value={customerGroupSearch}
          />
        </div>
        <div className={styles.maxHeight}>
          {filteredCustomerGroups.map(group => (
            <Checkbox
              key={`customer_group_${group}`}
              checked={formState.customerGroups.includes(group)}
              label={group}
              name={`customer_group_${group}`}
              onChange={(id, checked) => handleCustomerGroupSelection(group, checked)}
              isSmall
              disabled={formState.allRetailers}
            />
          ))}
        </div>
      </Card>

      {imagePreview ? (
        <Card className={styles.card}>
          <h4>Preview</h4>
          {imagePreview && <img src={imagePreview} alt="Banner Preview" />}
        </Card>
      ) : (
        <EmptyRecord message={'Please upload a banner image'} />
      )}
    </>
  );
});

export default BannerDetail;
