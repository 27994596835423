import { Action } from 'redux';
import { IGetUsersParams, getUsers } from '../api/adminUserApi';
import { IPagedDataResponse, IUser } from '../types';
import { AxiosResponse } from 'axios';

export const GET_USER_PAGE_REQUEST = 'GET_USER_PAGE_REQUEST';
export type GET_USER_PAGE_REQUEST = typeof GET_USER_PAGE_REQUEST;
export const GET_USER_PAGE_RESPONSE = 'GET_USER_PAGE_RESPONSE';
export type GET_USER_PAGE_RESPONSE = typeof GET_USER_PAGE_RESPONSE;
export const GET_USER_PAGE_ERROR = 'GET_USER_PAGE_ERROR';
export type GET_USER_PAGE_ERROR = typeof GET_USER_PAGE_ERROR;
export const CLEAR_USER_PAGE = 'CLEAR_USER_PAGE';
export type CLEAR_USER_PAGE = typeof CLEAR_USER_PAGE;

export interface IGetUserPageResponse extends Action {
  type: GET_USER_PAGE_RESPONSE;
  response: IPagedDataResponse<IUser>;
}

export interface IGetUserPageError extends Action {
  type: GET_USER_PAGE_ERROR;
  error?: string;
}

export const getUserPageRequest = (parameters: IGetUsersParams): any => {
  return (dispatch : any) => {
    dispatch({
      type: GET_USER_PAGE_REQUEST,
      loadMore: parameters.startIndex > 0,
    });

    return getUsers(parameters)
      .then((response: AxiosResponse<IPagedDataResponse<IUser[]>>) => {
        if (response.status === 200) {
          dispatch({
            type: GET_USER_PAGE_RESPONSE,
            response: response.data,
            loadMore: parameters.startIndex > 0,
          });

        } else {
          onError(dispatch);
        }
      })
      .catch((err: any) => {
        console.log(err);
        onError(dispatch);
      });
  };
};

const onError = (dispatch: any) => dispatch({
  type: GET_USER_PAGE_ERROR,
});

export const clearUsersRequest = () => {
  return {
    type: CLEAR_USER_PAGE,
  };
};
