import { IOrdersListState } from './IOrdersListState';

const initialState: IOrdersListState = {
  data: null,
  loading: true,
  error: '',
};

const ordersListReducer = (state: any = initialState, action: any): IOrdersListState => {
  switch (action.type) {
    case 'GET_ORDER_LIST_ORDERS_REQUEST': {
      return {
        ...initialState,
      };
    }
    case 'GET_ORDER_LIST_ORDERS_RESPONSE': {
      return {
        data: {
          response: action.data.response,
          mappedOrders: action.data.mappedOrders,
          orderStatusFilters: action.data.orderStatusFilters,
        },
        loading: false,
        error: null,
      };
    }
    case 'GET_ORDER_LIST_ORDERS_ERROR': {
      return {
        data: null,
        loading: false,
        error: 'An error has occured',
      };
    }
    default: {
      return state;
    }
  }
};

export default ordersListReducer;
