import React, { Dispatch, useEffect, useState } from 'react';
import { IPresaleCampaign, IStoreState, IUserDetails } from '../../types';
import './AdminPresaleCampaignsTable.scss';
import { navigateTo } from '../../routing/Navigation';
import { getBasePath } from '../../helpers/Users/UserHelper';
import { connect } from 'react-redux';
import { Button } from '@orderly/morrisons-component-library';
import moment from 'moment';
import { GenerateDownload } from '../../helpers/DownloadHelper';
import { downloadPresalesCampaignReportRequest } from '../../actions/SupplyChain/presalesActions';
import Table from '../Table/Table';

interface IPresalesCampaignsTableProps {
  userDetails: IUserDetails;
  campaigns: IPresaleCampaign[];
  noRecordsMessage: string;
  deletePresalesCampaign: (campaignId: number, onSuccess: () => void) => void;
  downloadPresalesCampaignReport:
  (campaignId: number,
    onSuccess: (data: any) => void, onFailure: () => void) => void;
  loadData: () => void;
  setCampaignLoading: (id: number, loading: boolean) => void;
}

const AdminPresalesCampaignsTable = ({
  campaigns,
  userDetails,
  noRecordsMessage,
  loadData,
  deletePresalesCampaign,
  downloadPresalesCampaignReport,
  setCampaignLoading }: IPresalesCampaignsTableProps) => {
  const [reportLoadingMap, setReportLoadingMap] = useState<Record<number, boolean>>({});

  useEffect(() => {
    if (campaigns.length > 0) {
      const loadingMap: Record<number, boolean> = {};

      campaigns.forEach((c) => {
        loadingMap[c.id] = false;
      });

      setReportLoadingMap(loadingMap);
    }
  }, [campaigns]);

  const deleteCampaign = (rowId: number) => {
    if (window.confirm('Are you sure you want to delete the campaign?')) {
      deletePresalesCampaign(rowId, () => {
        loadData();
      });
    }
  };

  const campaignPastDeadline = (campaign: IPresaleCampaign) => {
    const today = new Date().setHours(0, 0, 0, 0);
    const deadline = new Date(campaign.deadline).setHours(0, 0, 0, 0);
    return campaign.published && today > deadline;
  };

  const onDownloadCampaignReport = (campaignId: number): void => {
    setReportLoadingMap({ ...reportLoadingMap, [campaignId]: true });
    setCampaignLoading(campaignId, true);
    downloadPresalesCampaignReport(campaignId,
      (response: any) => {
        const file = response.presales;
        GenerateDownload(file.content, file.filename);
        setReportLoadingMap({ ...reportLoadingMap, [campaignId]: false });
        setCampaignLoading(campaignId, false);
      },
      () => {
        setReportLoadingMap({ ...reportLoadingMap, [campaignId]: false });
        setCampaignLoading(campaignId, false);
      },
    );
  };

  return (
    <Table className="presales-campaigns-table">
      <thead>
        <tr>
          <th>#</th>
          <th>Event</th>
          <th>Category</th>
          <th>Presale</th>
          <th>Deadline</th>
          <th>Published</th>
          <th>Actions</th>
          <th />
        </tr>
      </thead>
      <tbody>
        {
          campaigns.length === 0 ?
            <tr>
              <td colSpan={7}>{noRecordsMessage}</td>
            </tr> :
            campaigns.map((campaign, index) => {
              return (
                <tr key={campaign.id}>
                  <td>
                    {index + 1}
                  </td>
                 <td>
                    {campaign.event}
                  </td>
                 <td>
                    {campaign.category}
                  </td>
                 <td>
                  <Button
                    type="button"
                    className="Table-viewDetailsAction"
                    text={campaign.name}
                    onClick={() => {
                      navigateTo(`${getBasePath(userDetails)}presales-campaigns/campaign/${campaign.id}`);
                    }}
                  />
                  </td>
                 <td>
                  {(() => {
                    const formattedDate = moment(campaign.deadline).format('DD/MM/YYYY');

                    const today = moment().startOf('day');
                    const deadlineDate = moment(campaign.deadline).startOf('day');
                    const differenceInDays = deadlineDate.diff(today, 'days');

                    let color = 'red';
                    let suffix = `${differenceInDays} day${differenceInDays === 1 ? '' : 's'}`;

                    if (differenceInDays < 0) {
                      suffix = 'Overdue';
                      color = 'red';
                    } else if (differenceInDays === 0) {
                      suffix = 'Today';
                    } else if (differenceInDays <= 3) {
                      color = 'orange';
                    } else {
                      color = 'green';
                    }

                    return (
                      <p style={{ color }}>
                        {formattedDate} ({suffix})
                      </p>
                    );
                  })()}
                  </td>
                  <td>
                    {campaign.published === true ? 'Yes' : 'No'}
                  </td>
                  <td>
                    <div className="actions-cell">
                        <span title="Delete campaign">
                          <Button
                            className="delete-campaign"
                            icon="icon-Delete"
                            type="button"
                            text=""
                            onClick={(): void => deleteCampaign(campaign.id)}
                          />
                        </span>
                      {
                        campaignPastDeadline(campaign) &&
                        <span title="Download campaign report">
                          <Button
                            className="download-campaign-report"
                            icon="icon-excel-file"
                            type="button"
                            text=""
                            onClick={(): void => onDownloadCampaignReport(campaign.id)}
                            disabled={reportLoadingMap[campaign.id]}
                          />
                        </span>
                      }
                    </div>
                  </td>
                </tr>
              );
            })
        }
      </tbody>
    </Table>
  );
};

const mapStateToProps = (state: IStoreState) => {
  return {
    userDetails: state.userDetails,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<any>) => {
  return {
    downloadPresalesCampaignReport: (campaignId: number, onSuccess: (data: any) => void, onFailure: () => void) =>
      dispatch(downloadPresalesCampaignReportRequest(campaignId, onSuccess, onFailure)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AdminPresalesCampaignsTable);
