import Fetch from '.././apiConfig/apiConfig';
import { AxiosPromise } from 'axios';
import { ITransportCostLine } from '../../types';

export interface IImportTransportCostsParams {
  [key: string]: any;
  file: string;
}

export interface IImportTransportCostsResponse {
  importTransportCosts: {
    success: boolean;
    results: string[];
  };
}

export const importTransportCosts = (params: IImportTransportCostsParams): AxiosPromise<IImportTransportCostsResponse> => {
  return Fetch().then(axios => axios.post('stores/transport-costs/import', params));
};

export interface IExportTransportCostsResponse {
  fileName: string;
  fileData: String;
}

export const exportTransportCosts = (): AxiosPromise<IExportTransportCostsResponse> => {
  return Fetch().then(axios => axios.get('stores/transport-costs/export'));
};

export interface IStoreTransportCostResponse {
  transportCosts: ITransportCostLine[];
}

export const getTransportCosts = (): AxiosPromise<IStoreTransportCostResponse> => {
  return Fetch().then(axios => axios.get('stores/transport-costs'));
};
