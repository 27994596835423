import { IBasket, IApiRequest } from '../../types';

const initialState: IApiRequest<IBasket[]> = {
  data: null,
  loading: true,
  error: '',
  UpdatedAt: '',
};

const retailerOrdersReducer = (state: any = initialState, action: any): IApiRequest<IBasket[]> => {
  switch (action.type) {
    case 'GET_RETAILER_ORDERS_REQUEST': {
      return {
        ...state,
        data: null,
        loading: true,
        error: '',
        UpdatedAt: '',
      };
    }
    case 'GET_RETAILER_ORDERS_RESPONSE': {
      return {
        ...state,
        data: action.response,
        loading: false,
        error: null,
        UpdatedAt: '',
      };
    }
    case 'GET_RETAILER_ORDERS_ERROR': {
      return {
        ...state,
        data: null,
        loading: false,
        error: 'An error has occured',
        UpdatedAt: '',
      };
    }
    default: {
      return state;
    }
  }
};

export default retailerOrdersReducer;
