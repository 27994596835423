import React from 'react';
import { Card } from '@orderly/morrisons-component-library';
import { NavLink } from 'react-router-dom';
import './CategoryPromo.scss';

interface ICategoryPromoParams {
  id: number;
  text: string;
}

const CategoryPromo = ({ id, text } : ICategoryPromoParams) : any => {

  if (id < 0) {
    return null;
  }

  let imageSource;

  switch (text) {
    case 'Ambient': // Ambient
      imageSource = require('../../assets/images/Ambient.png');
      break;
    case 'Chilled long life': // Chilled Long Life
      imageSource = require('../../assets/images/Chilled_Long_Life.png');
      break;
    case 'Chilled short life': // Chilled Short Life
      imageSource = require('../../assets/images/Chilled_Short_Life.png');
      break;
    case 'Frozen': // Frozen
      imageSource = require('../../assets/images/Frozen.png');
      break;
    case 'Tobacco': // Tobacco
      imageSource = require('../../assets/images/Tobacco.png');
      break;
    case 'Expenses': // Tobacco
      imageSource = require('../../assets/images/Expenses.png');
      break;
    case 'Chilled': // Chilled Long Life
      imageSource = require('../../assets/images/Chilled_Long_Life.png');
      break;
  }

  let imageClass = 'image-';
  imageClass += text.replace(/ /g, '-');

  return (
    <NavLink className="color-inherit" exact to={`/catalogue?category=${id}`}>
      <Card className="category-promo">
        <p>{text}</p>
        <img className={imageClass} src={imageSource} />
      </Card>
    </NavLink>);
};

export default CategoryPromo;
