import { Reducer } from 'redux';
import {
  GET_TOP_OFFERS_ERROR,
  GET_TOP_OFFERS_REQUEST,
  GET_TOP_OFFERS_RESPONSE } from '../actions/topOffersAction';
import { ITopOffers } from '../types';

const initialState: ITopOffers = [];

const topOffersReducer: Reducer<ITopOffers> = (state: ITopOffers = initialState, action : any) => {
  switch (action.type) {
    case GET_TOP_OFFERS_RESPONSE: {
      return {
        ...state,
        ...action.response,
      };
    }
    case GET_TOP_OFFERS_REQUEST:
    case GET_TOP_OFFERS_ERROR:
    default: {
      return state;
    }
  }
};

export default topOffersReducer;
