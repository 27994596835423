import Fetch from '../apiConfig/apiConfig';
import { AxiosPromise } from 'axios';
import { IPendingOrder, IProduct } from '../../types';

export interface IReviewOrderParams {
  orderId: number;
  approved: boolean;
}

export interface IEditOrderParams {
  order: IPendingOrder;
}

export const getProductsForPendingReviewApi = (orderId: number, searchPhrase: string) : AxiosPromise<IProduct[]> => {
  return Fetch().then(axios => axios.get(`orders/review/products?orderId=${orderId}&searchPhrase=${searchPhrase}`));
};

export const getOrdersPendingReviewApi = () : AxiosPromise<IPendingOrder[]> => {
  return Fetch().then(axios => axios.get('orders/review/pending'));
};

export const editOrderPendingReviewApi = (params: IEditOrderParams) : AxiosPromise<void> => {
  return Fetch().then(axios => axios.post('orders/review/edit', params));
};

export const submitOrderReviewApi = (params: IReviewOrderParams) : AxiosPromise<void> => {
  return Fetch().then(axios => axios.post('orders/review/submit', params));
};
