import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import FullPageLayout from '../../layouts/FullPageLayout/FullPageLayout';
import { Button, Checkbox } from '@orderly/morrisons-component-library';
import {
  IPresaleCampaign,
  IStoreState,
  ISubmitPresalesCampaign,
} from '../../types';
import './CampaignEntry.scss';

import { Dispatch } from 'redux';
import {
  getPresalesCampaignRequest,
  submitPresalesCampaignRequest,
} from '../../actions/SupplyChain/presalesActions';
import { navigateTo } from '../../routing/Navigation';
import CampaignEntryTable from '../../components/Presales/CampaignEntryTable';
import { toastr } from 'react-redux-toastr';

interface ICampaignEntryProps {
  presalesCampaign: IPresaleCampaign;
  match: {
    params: {
      campaignId: string;
      storeId: string;
    };
  };
  getPresalesCampaign: (campaignId: number, storeId: number) => void;
  submitPresaleCampaign: (campaignDetails: ISubmitPresalesCampaign, onSuccess: () => void) => void;
}

const CampaignEntry = ({
  presalesCampaign,
  match,
  getPresalesCampaign,
  submitPresaleCampaign,
}: ICampaignEntryProps) => {
  const [campaign, setCampaign] = useState<IPresaleCampaign>(null);
  const [optOut, setOptOut] = useState(false);
  const [dropsCompleted, setDropsCompleted] = useState(false);
  const [dropEntered, setDropEntered] = useState(false);

  const campaignId = parseInt(match.params.campaignId, 10);
  const storeId = parseInt(match.params.storeId, 10);
  const campaignSubmitted =
    campaign && campaign.stores.find(x => x.storeId === storeId).submitted;

  useEffect(() => {
    getPresalesCampaign(campaignId, storeId);
  }, []);

  useEffect(() => {
    if (campaign && campaign.products) {
      setOptOut(campaign.stores.find(x => x.storeId === storeId).optedOut);

      const allDropsValid = campaign.products.every(product =>
      product.drops.every(drop =>
          drop.date === null || (drop.allocatedVolume !== null && drop.allocatedVolume >= 0),
      ),
    );
      setDropsCompleted(allDropsValid);

      const productdropEntered = campaign.products.some(product =>
      product.drops.some(drop => drop.allocatedVolume && drop.allocatedVolume > 0));
      setDropEntered(productdropEntered);
    }
  }, [campaign]);

  useEffect(() => {
    if (presalesCampaign) {
      if (presalesCampaign.id === campaignId) {
        setCampaign(presalesCampaign);
      } else {
        getPresalesCampaign(campaignId, storeId);
      }
    }

  }, [presalesCampaign]);

  const onSubmitCampaign = () => {
    const defaultSubmitMessage = 'Are you sure you want to submit this campaign?';
    const optOutMessage = `You are opting out of all items on the campaign ${campaign.name}. Are you sure?`;

    const toastrConfirmOptions = {
      onOk: () => {
        submitPresaleCampaign({ campaignId, storeId, optOut }, () => {
          navigateTo('/presales');
        });
      },
    };
    toastr.confirm(optOut ? optOutMessage : defaultSubmitMessage, toastrConfirmOptions);
  };

  const breadcrumbs = [
    { key: 0, text: 'Home', url: '/home/' },
    { key: 1, text: 'Presales Campaigns', url: '/presales' },
    { key: 2, text: 'Campaign Drops', url: window.location.pathname },
  ];

  return (
    <FullPageLayout heading="Campaign Drops" breadcrumbs={breadcrumbs}>
    <div className="campaignEntry-wrapper">
      <CampaignEntryTable
        campaign={campaign}
        isAdminPreviewMode={false}
        campaignSubmitted={campaignSubmitted}
        storeId={storeId}
        onSetCampaign={setCampaign}
      />
      <div className="submit-container">
        <div
          title={dropEntered
          ? 'Product forecast(s) have been entered, these need to be removed to opt out of the campaign'
          : 'Opt-Out'}
        >
          <Checkbox
            checked={optOut}
            label="Opt out of campaign"
            name="opt-out"
            onChange={(_, checked) => setOptOut(checked)}
            isSmall={true}
            className="opt-out-checkbox"
            disabled={dropEntered || campaignSubmitted}
          />
        </div>
        <div className="submit-campaign-action-container" title="Submit">
          <Button
            className="submit-campaign tertiary"
            type="submit"
            text="Submit"
            onClick={() => onSubmitCampaign()}
            disabled={campaignSubmitted || (!optOut && !dropsCompleted)}
          />
        </div>
      </div>
</div>
    </FullPageLayout>
  );
};

const mapStateToProps = (state: IStoreState) => {
  return {
    presalesCampaign: state.presales.campaign,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<any>) => ({
  getPresalesCampaign: (campaignId: number, storeId: number) =>
    dispatch(getPresalesCampaignRequest(campaignId, storeId)),
  submitPresaleCampaign: (parameters: ISubmitPresalesCampaign, onSuccess: () => void) =>
    dispatch(submitPresalesCampaignRequest(parameters, onSuccess)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CampaignEntry);
