import React from 'react';
import { Card } from '@orderly/morrisons-component-library';
import { connect } from 'react-redux';
import { IApiRequest, IStoreState, IUploadingFile } from '../../../../../types';
import { IPendingUploadsProps } from './IPendingUploadsProps';

import styles from './PendingUploads.module.scss';
import Table from '../../../../../components/Table/Table';

function PendingUploads({
  uploadStoreDocuments,
}: IPendingUploadsProps): JSX.Element {
  const files: IApiRequest<IUploadingFile>[] = Object.values(uploadStoreDocuments);

  if (files && files.length <= 0) {
    return null;
  }

  return (
    <Card className={styles.container}>
      <h4>Uploading Files</h4>
      <div className={styles.fileContainer}>
        <Table className="">
          <thead>
            <tr>
              <th>
                File name
              </th>
              <th />
            </tr>
          </thead>
          <tbody>
            {
              files.map((file: IApiRequest<IUploadingFile>): JSX.Element => {
                return (
                  <tr key={file.data.fileName}>
                    <td>
                      {file.data.fileName}
                    </td>
                    <td>
                      {file.error ? file.error : `${file.data.percentageComplete.toFixed(2)}%`}
                    </td>
                  </tr>
                );
              })
            }
          </tbody>
        </Table>
      </div>
    </Card>
  );
}

const mapStateToProps = (state: IStoreState) => {
  return {
    uploadStoreDocuments: state.uploadStoreDocuments,
  };
};

export default connect(mapStateToProps, null)(PendingUploads);
