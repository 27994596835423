export enum RetailerSubTypes {
  All = 0,
  Motor_Fuel_Group = 1,
  Morrisons_Daily = 2,
  Morrisons_CoBranded = 3,
}

export const RetailerSubTypesObject: {[key: string]: number} = {
  All: 0,
  'Motor Fuel Group': 1,
  'Morrisons Daily': 2,
  'Morrisons CoBranded': 3,
};
