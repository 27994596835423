import Fetch from '../apiConfig/apiConfig';
import { AxiosInstance, AxiosResponse } from 'axios';
import {
  IDeletePresalesCampaign,
  IPresaleCampaign,
  ISubmitPresalesCampaign,
  IUpdatePresalesSubmission,
  IReport,
} from '../../types';
import { IBaseFileImportResponseProperties } from '../IBaseFileImportResponseProperties';

interface IGetPresalesCampaignsResponse {
  presalesCampaigns: IPresaleCampaign[];
}

interface IGetPresalesCampaignResponse {
  presalesCampaign: IPresaleCampaign;
}

interface ICreatePresalesCampaignResponse {
  createPresalesCampaign: IBaseFileImportResponseProperties;
}

interface IEditPresalesCampaignResponse {
  editPresalesCampaign: IBaseFileImportResponseProperties;
}

export interface IPublishPresalesCampaignRequest {
  id: number;
  published: boolean;
}

interface IDeletePresalesCampaignResponse {
  deletePresalesCampaign: IBaseFileImportResponseProperties;
}

export interface IDownloadPresalesCampaignReportResponse {
  report: IReport;
}

export const getPresalesCampaigns = async (
  activeCampaigns: boolean,
): Promise<AxiosResponse<IGetPresalesCampaignsResponse>> => {
  const axiosInstance: AxiosInstance = await Fetch();
  return axiosInstance.get<IGetPresalesCampaignsResponse>(`presales-campaigns/get?activeCampaigns=${activeCampaigns}`);
};

export const getPresalesCampaign = async (
  campaignId: number,
  storeId: number | null = null,
): Promise<AxiosResponse<IGetPresalesCampaignResponse>> => {
  const axiosInstance: AxiosInstance = await Fetch();
  return axiosInstance
    .get<IGetPresalesCampaignResponse>(`presales-campaign/get?campaignId=${campaignId}&storeId=${storeId}`);
};

export const createPresalesCampaignApi = async (
  params: IPresaleCampaign,
): Promise<AxiosResponse<ICreatePresalesCampaignResponse>> => {
  const axios = await Fetch();
  return axios.post('presales-campaign/create', params);
};

export const editPresalesCampaignApi = async (
  params: IPresaleCampaign,
): Promise<AxiosResponse<IEditPresalesCampaignResponse>> => {
  const axios = await Fetch();
  return axios.post('presales-campaign/edit', params);
};

export const publishPresalesCampaignApi = async (
  params: IPublishPresalesCampaignRequest,
): Promise<AxiosResponse<IGetPresalesCampaignsResponse>> => {
  const axios = await Fetch();
  return axios.post('presales-campaign/publish', params);
};

export const deletePresalesCampaignApi = async (
  params: IDeletePresalesCampaign,
): Promise<AxiosResponse<IDeletePresalesCampaignResponse>> => {
  const axios = await Fetch();
  return axios.post('presales-campaign/delete', params);
};

export const submitPresalesCampaignApi = async (
  params: ISubmitPresalesCampaign,
): Promise<AxiosResponse<void>> => {
  const axios = await Fetch();
  return axios.post('presales-campaign/submit', params);
};

export const updatePresaleSubmissionApi = async (
  params: IUpdatePresalesSubmission,
): Promise<AxiosResponse<void>> => {
  const axios = await Fetch();
  return axios.post('presales-campaign/update-submission', params);
};

export const downloadPresalesCampaignReportApi = async (campaignId: number):
  Promise<AxiosResponse<IDownloadPresalesCampaignReportResponse>> => {
  const axiosInstance: AxiosInstance = await Fetch();
  return axiosInstance.get<IDownloadPresalesCampaignReportResponse>(`presales-campaign/report?campaignId=${campaignId}`);
};

export const downloadExamplePresalesTemplateApi = async ():
  Promise<AxiosResponse<IReport>> => {
  const axiosInstance: AxiosInstance = await Fetch();
  return axiosInstance.get<IReport>('presales-campaign/download-template');
};
