import React, { useEffect } from 'react';
import { IFolder } from '../../types';
import QueryString from 'query-string';

import './FolderSelect.scss';

const FolderSelect = (props: any) => {

  const getCustomerGroup = () => {
    return QueryString.parse(window.location.search).customerGroup;
  };

  return (
    <div className="folderSelect">
      {
        getCustomerGroup() && props.folders.length === 0 && <span>No folders available</span>
      }
      {
        !getCustomerGroup() && <div className={`rootFolder ${!props.selectedFolder ? 'selected' : ''}`}>
          <span className="icon icon-folder-open" />
          <h3
            className="foldersHeading"
            onClick={() => props.onFolderClick('')}
          >
            {props.catalogue}
          </h3>
        </div>}
      {
        props.folders.map((folder: IFolder) => {
          return (
            <div key={folder.folderId} className="folder-container">
              <button
                className={`folder ${props.selectedFolder ===
                  folder.description ? 'selected' : ''}`}
                onClick={() => {
                  props.onFolderClick(folder.description);
                }}
                title={folder.description}
                data-qaid={`${folder.description}Id`}
                disabled={props.loading || props.selectedFolder === folder.description}
              >
                <div className="folderWrapper">
                  <span
                    className={`folderIcon icon ${props.selectedFolder ===
                      folder.description ? 'icon-folder-open' : 'icon-folder'}`}
                  />
                  <span className="folderDescription">
                    {
                      `${folder.description} ${folder.customerGroups.length > 0 ? `(${folder.customerGroups})` : ''}`}
                  </span>
                  <div
                    onClick={(e) => {
                      e.stopPropagation();
                      if (props.onWatchClick) {
                        props.onWatchClick(folder.folderId, folder, props.userDetails);
                      }
                    }}
                    className="watch-icon watch-action"
                  >
                    {props.userDetails !== undefined &&
                      (
                        folder.users && folder.users.find(x => x.id === props.userDetails.userId) === undefined
                          ? <span
                            className="icon icon-eye-blocked"
                            title="Click icon to add watch and receive email notification of changes."
                          />
                          : <span
                            className="icon icon-eye"
                            title="You are watching this folder and will receive email notifications of changes.
Click icon to remove watch."
                          />
                      )
                    }
                    {props.userDetails === undefined &&
                      (
                        folder.users && folder.users.length === 0
                          ? <span
                            className="icon icon-eye-blocked"
                            title=""
                          />
                          : <span
                            className="icon icon-eye"
                            title={`Watched by:
${folder.users.map(u => `${u.firstname} ${u.lastname}`).join(', ')}`}
                          />
                      )
                    }
                  </div>
                </div>
              </button>
            </div>
          );
        })
      }
    </div>
  );
};

export default FolderSelect;
