import { Action } from 'redux';
import { IFolderContents } from '../../types';
import { AxiosResponse } from 'axios';
import { deleteDocument, IDeleteDocumentParams, IDeleteDocumentResponse } from '../../api/Documents/deleteDocumentApi';

export const DELETE_DOCUMENT_REQUEST = 'DELETE_DOCUMENT_REQUEST';
export type DELETE_DOCUMENT_REQUEST = typeof DELETE_DOCUMENT_REQUEST;

export const DELETE_DOCUMENT_RESPONSE = 'DELETE_DOCUMENT_RESPONSE';
export type DELETE_DOCUMENT_RESPONSE = typeof DELETE_DOCUMENT_RESPONSE;

export const DELETE_DOCUMENT_ERROR = 'DELETE_DOCUMENT_ERROR';
export type DELETE_DOCUMENT_ERROR = typeof DELETE_DOCUMENT_ERROR;

export interface IDeleteDocumentResponse extends Action {
  type: DELETE_DOCUMENT_RESPONSE;
  response: IFolderContents;
}

export interface IDeleteDocumentError extends Action {
  type: DELETE_DOCUMENT_ERROR;
  error?: string;
}

export const deleteDocumentRequest =
 (parameters: IDeleteDocumentParams,
  onSuccess: () => void,
  onFailure: () => void): any => {
   return (dispatch : any) => {

     dispatch({
       type: DELETE_DOCUMENT_REQUEST,
     });

     return deleteDocument(parameters)
      .then((response: AxiosResponse<IDeleteDocumentResponse>) => {
        if (response.status === 200) {
          dispatch({
            type: DELETE_DOCUMENT_RESPONSE,
            response: response.data.document,
          });
          onSuccess();
        } else {
          onError(dispatch);
          onFailure();
        }
      })
      .catch((err: any) => {
        console.log(err);
        onError(dispatch);
        onFailure();
      });
   };
 };

const onError = (dispatch: any) => dispatch({
  type: DELETE_DOCUMENT_ERROR,
});
