import Fetch from '../apiConfig/apiConfig';
import { AxiosPromise } from 'axios';

import { IRetailerStoreDocumentsParams } from './Interfaces/IRetailerStoreDocumentsParams';
import { IRetailerStoreDocumentsResponse } from './Interfaces/IRetailerStoreDocumentsResponse';

export const getRetailerStoreDocuments = (params: IRetailerStoreDocumentsParams):
  AxiosPromise<IRetailerStoreDocumentsResponse> => {
  const url = `store-documents/admin/get?retailerId=${params.retailerId}&documentType=${params.documentType}`
    + `&storeId=${params.storeId}&customerGroup=${params.customerGroup ? params.customerGroup : ''}`;

  return Fetch().then(axios => axios.get(url));
};
