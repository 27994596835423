import { Dispatch } from 'redux';
import { impersonateUser } from '../../api/UserManagement/userManagementApi';
import { setUserLoginData } from '../userLoginAction/userLoginAction';
import { persistor } from '../../store';
import configureApi from '../../api/apiConfig/apiConfig';

export const impersonateUserRequest = (userId: number, onSuccess: () => void): any => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: 'USER_IMPERSONATE_REQUEST',
    });

    const result = await impersonateUser(userId);

    const adminDetails = {
      token: localStorage.getItem('token'),
      refreshToken: localStorage.getItem('refreshToken'),
    };
    localStorage.setItem('adminDetails', JSON.stringify(adminDetails));

    // Clear old redux data
    try {
      await persistor.purge();
    } catch (ex) {
      console.log(ex);
    }

    dispatch({
      type: 'PURGE_REDUX',
    });

    const userDetails = setUserLoginData(result.data);

    await configureApi();

    dispatch({
      type: 'USER_IMPERSONATE_RESPONSE',
      payload: result.data,
    });

    dispatch({
      type: 'USER_DETAILS',
      payload: userDetails,
    });

    dispatch({
      type: 'USER_LOGIN_RESPONSE',
      response: userDetails,
    });

    onSuccess();
  };
};
