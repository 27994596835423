import React, { Dispatch } from 'react';
import './OrdersListFilters.scss';
import { StyledCalendar, RadioButton, RadioButtons, Checkbox } from '@orderly/morrisons-component-library';
import { OrderDateValue } from './OrderDateValue.enum';
import { IOrdersListFiltersProps } from './IOrdersListFiltersProps';
import { IOrderStatusFilter } from '../../../helpers/Orders/IOrderStatusFilter';
import orderStatusFiltersAction from '../../../actions/Orders/OrderList/orderStatusFiltersAction';
import { IOrderStatusForm } from './IOrderStatusForm';
import { IOrdersListFiltersCalendar } from './IOrdersListFiltersCalendar';
import { OrderDateKeyValues } from './OrderDateKeyValues.constant';
import { IStoreState } from '../../../types';
import { persistOrderFiltersAction } from '../../../actions/Orders/OrderList/persistOrderFiltersAction';
import { connect } from 'react-redux';
import { orderStatusLabels } from '../../../helpers/Orders/OrdersListFilters/OrderStatusFilters';
import { getOrderStatusFilters } from '../../../helpers/Orders/OrderStatusHelper';
import OrdersListSearch from '../OrdersListSearch/OrdersListSearch';
import { IOrder } from '../../../pages/Orders/IOrder';
import { persistOrderListSearchAction } from '../../../actions/Orders/OrderList/persistOrderListSearchAction';
import { IOrdersListFiltersState } from './IOrdersListFiltersState';
import FormatDateHelper from '../../../helpers/Format/Date/FormatDateHelper';
import MobileFiltersActionHelper from '../../MobileFiltersAction/helper/MobileFiltersActionHelper';

class OrdersListFilters extends React.Component<IOrdersListFiltersProps, IOrdersListFiltersState> {

  private maxApplyClientFilterCount: number = 3;

  private selectedValueMapper: any = {
    0: 'shipToLocationId',
    1: 'id',
  };

  private searchFieldMapper: any = {
    shipToLocationId: 0,
    id: 1,
  };

  constructor(props: any) {
    super(props);

    this.state = {
      form: {
        selectedRadioButtonValue: OrderDateValue.ORDER_DATE,
        isOrderDateSelected: true,
        isDeliveryDateChecked: false,
      },
      calendar: {
        raw: new Date(),
        formatted: '',
      },
      orderStatus: {
        checkedFilters: [],
      },
      applyClientFilterCount: 0,
      clearPersistedValue: false,
      searchOptionSelectedValue: 0,
      searchOptionDisplayValue: 'Store ID',
      fieldToSearch: this.props.isImpersonating ? 'shipToLocationId' : 'id',
      optionsTemplate: null,
      isSearchOptionsOpen: false,
    };
    window.addEventListener('click', this.dismissOnOutsideClick, true);
  }

  public componentDidMount(): void {
    if (
        this.props.lastLocation !== null
        && this.props.lastLocation.pathname.indexOf('/orders/') === 0
        && this.props.persistedOrderListFilters !== null
    ) {
      this.setState({
        calendar: {
          raw: this.props.persistedOrderListFilters.date,
          formatted: FormatDateHelper.formatDate(this.props.persistedOrderListFilters.date, 'YYYY-MM-DD'),
        },
      });

      this.setState({
        form: {
          selectedRadioButtonValue: this.props.persistedOrderListFilters.dateType,
          isDeliveryDateChecked: this.props.persistedOrderListFilters.dateType === OrderDateValue.DELIVERY_DATE,
          isOrderDateSelected: this.props.persistedOrderListFilters.dateType === OrderDateValue.ORDER_DATE,
        },
      });
    }
  }

  public componentDidUpdate(prevProps: IOrdersListFiltersProps): void {
    if (
        this.state.applyClientFilterCount < this.maxApplyClientFilterCount
        && this.props.ordersLoading === false
        && this.props.ordersList !== null
        && this.props.ordersList.response.length > 0
        && this.props.lastLocation !== null
        && this.props.lastLocation.pathname.indexOf('/orders/') === 0
    ) {

      this.setState((prevState: any) => {
        return {
          applyClientFilterCount: prevState.applyClientFilterCount + 1,
        };
      });

      this.props.filterOrderStatus(orderStatusLabels);
      this.props.config.ordersList.onOrderStatusFilter();

      if (
          this.props.isImpersonating
          && this.props.persistOrderListSearchData.fieldToSearch
      ) {
        const value: number = this.searchFieldMapper[this.props.persistOrderListSearchData.fieldToSearch];

        this.setState(
          {
            searchOptionSelectedValue: value,
            searchOptionDisplayValue: value === 0 ? 'Store ID' : 'Order ID',
          },
        );
      }
    }
  }

  public componentWillUnmount(): void {
    this.props.persistOrderListFilters(
        this.state.calendar.raw,
        this.state.form.selectedRadioButtonValue,
        this.state.orderStatus.checkedFilters,
    );

    window.removeEventListener('click', this.dismissOnOutsideClick, true);
  }

  public onFormSubmit(e: any): void {
    e.preventDefault();
  }

  public handleSortOptionsOnChange = (searchOptionSelectedValue: number): void => {
    const persistedSearchValue: string = this.props.persistOrderListSearchData.searchedValue;

    this.setState(
      {
        searchOptionSelectedValue,
        clearPersistedValue: !!persistedSearchValue,
        fieldToSearch: this.selectedValueMapper[searchOptionSelectedValue],
      },
    );

    if (persistedSearchValue) {
      this.props.persistOrderListSearch('', this.props.config.ordersList.items);
    }
    this.unCheckOrderStatusFilters();
  }

  public handleSortOptionsClick = (event: any): void => {
    const optionsTemplate: JSX.Element = this.generateSearchOptions(this.state.searchOptionDisplayValue);

    if (!this.state.isSearchOptionsOpen) {
      this.setState(
        {
          optionsTemplate,
          isSearchOptionsOpen: !this.state.isSearchOptionsOpen,
        },
      );
    } else {
      this.setState(
        {
          optionsTemplate: null,
          isSearchOptionsOpen: !this.state.isSearchOptionsOpen,
        },
      );
    }
  }

  public onClearingPersistedValue = (): void => {
    if (!this.props.persistOrderListSearchData.searchedValue) {
      this.setState(
        {
          clearPersistedValue: false,
        },
      );
    }
  }

  public render(): JSX.Element {
    return (
        <div id="ordersListFiltersContainer" className={`OrdersListFilters-container ${this.props.config.className}`}>
          <form className="OrdersListFilters-orderStatusForm" onSubmit={this.onFormSubmit}>
            <div className="OrdersListFilters-orderStatusFiltersContainer">
              <div className="OrdersListFilters-headingContainer">
                <h3 className="OrdersListFilters-heading">FILTER BY</h3>
              </div>
              {
                this.props.isImpersonating
                &&
                <div className="OrdersListFilters-searchOptionsContainer">
                    <label
                        className={
                          `OrdersListFilters-searchOptionLabel ${this.state.isSearchOptionsOpen ? 'isMenuOpen' : ''}`}
                        htmlFor="searchOptionsSelect"
                    >
                        <span className="OrdersListFilters-optionsValue">{this.state.searchOptionDisplayValue}</span>
                        <span className="icon-arrow-down"/>
                    </label>
                  {this.state.optionsTemplate}
                    <input
                        hidden={true}
                        readOnly={true}
                        className="OrdersListFilters-searchOptions"
                        name="searchOptions"
                        id="searchOptionsSelect"
                        value={this.state.searchOptionSelectedValue}
                        onClick={this.handleSortOptionsClick}
                    />
                </div>
              }
              {
                <OrdersListSearch
                    config={
                      {
                        listToSearch: this.props.config.listToSearch,
                        triggerOrderStatusFilter: this.props.filterOrderStatus,
                        isImpersonating: this.props.isImpersonating,
                        fieldToSearch: this.state.fieldToSearch,
                        onClearingPersistedValue: this.onClearingPersistedValue,
                      }
                    }
                />
              }
              <div className="OrdersListFilters-orderStatusFilter">
                <div className="OrdersListFilters-labelContainer">
                  <span className="OrdersListFilters-label">Order status</span>
                </div>
                {
                  this.populateOrdersStatusFilters()
                }
              </div>
            </div>
            <div className="OrdersListFilters-dateOptionsContainer">
              <div className="OrdersListFilters-labelContainer">
                <span className="OrdersListFilters-label">Date</span>
              </div>
              <div className="OrdersListFilters-radioButtonsContainer" >
                <RadioButtons
                    onChange={this.updateFormState}
                    value={this.state.form.selectedRadioButtonValue}
                    id="radio-group"
                >
                  <RadioButton
                      value={OrderDateValue.ORDER_DATE}
                      label="Order Date"
                      description=""
                      data-qaid="orderDate"
                  />
                  <RadioButton
                      value={OrderDateValue.DELIVERY_DATE}
                      label="Delivery Date"
                      description=""
                      data-qaid="orderDate"
                  />
                </RadioButtons>
              </div>
            </div>
            <div className="OrdersListFilters-calendarContainer">
              <StyledCalendar
                  onChange={this.updateCalendarState}
                  value={this.state.calendar.raw}
                  selectRange={false}
              />
            </div>
          </form>
        </div>
    );
  }

  private updateFormState = async (selectedValue: number): Promise<void> => {
    const formValues: IOrderStatusForm = {
      selectedRadioButtonValue: selectedValue,
      isOrderDateSelected: selectedValue === OrderDateValue.ORDER_DATE,
      isDeliveryDateChecked: selectedValue === OrderDateValue.DELIVERY_DATE,
      date: FormatDateHelper.formatDate(this.state.calendar.raw, 'YYYY-MM-DD'),
    };

    this.setState({
      form: formValues,
    });

    this.props.config.ordersList.onDateFilter({
      date: FormatDateHelper.formatCalendarDateToISO(this.state.calendar.raw),
      key: OrderDateKeyValues[formValues.selectedRadioButtonValue],
      retailer: '',
    }).then(
        (): void => {
          orderStatusLabels.forEach((orderStatusLabels: IOrderStatusFilter): any => {
            if (orderStatusLabels.isChecked) {
              this.handleCheckboxChange(orderStatusLabels, `${orderStatusLabels.status.toLowerCase()}Filter}`);
              this.unCheckOrderStatusFilters();
              MobileFiltersActionHelper.isApplyFilters = false;
              this.setState(
                {
                  searchOptionSelectedValue: 0,
                  searchOptionDisplayValue: 'Store ID',
                },
              );
            }
          });
        },
        (e: any): void => {
          console.log('An error has occurred: ', e);
        },
    );
  }

  private updateCalendarState = (selectedDate: Date | Date[]): any => {
    const calendar: IOrdersListFiltersCalendar = {
      raw: selectedDate as Date,
      formatted: FormatDateHelper.formatCalendarDateToISO(selectedDate as Date),
    };

    this.setState({ calendar });

    const radioValue: number = this.state.form.selectedRadioButtonValue as number;

    this.props.config.ordersList.onCalendarDateSelect({
      date: calendar.formatted,
      key: OrderDateKeyValues[radioValue],
      retailer: '',
    }).then(
        (): void => {
          this.unCheckOrderStatusFilters();
          MobileFiltersActionHelper.isApplyFilters = false;
          this.setState(
            {
              searchOptionSelectedValue: 0,
              searchOptionDisplayValue: 'Store ID',
            },
          );
        },
        (e: any): void => {
          console.log('An error has occurred', e);
        },
    );
  }

  private handleCheckboxChange = (orderStatusFilter: IOrderStatusFilter, checkBoxValue: string): any => {
    const selectedFilterValue: string = checkBoxValue.split('Filter')[0];

    if (this.state.orderStatus.checkedFilters.indexOf(orderStatusFilter.status.toLowerCase()) === -1) {

      this.setState({
        orderStatus: {
          checkedFilters: [selectedFilterValue, ...this.state.orderStatus.checkedFilters],
        },
      });
    }

    this.toggleOrderStatusFilter(orderStatusFilter);
    this.props.filterOrderStatus(orderStatusLabels);
    this.props.config.ordersList.onOrderStatusFilter();
    MobileFiltersActionHelper.isApplyFilters = false;
  }

  private toggleOrderStatusFilter = (filter: IOrderStatusFilter): void => {
    orderStatusLabels.map((orderStatusFilter: IOrderStatusFilter): IOrderStatusFilter => {
      if (orderStatusFilter.status.toLowerCase() === filter.status.toLowerCase() && !filter.isChecked) {
        orderStatusFilter.isChecked = true;

        this.props.persistOrderListSearch('', []);

      } else if (orderStatusFilter.status.toLowerCase() === filter.status.toLowerCase() && filter.isChecked) {
        orderStatusFilter.isChecked = false;

        if (this.props.persistOrderListSearchData.searchedValue) {
          this.props.persistOrderListSearch('', []);
        }
      }

      return orderStatusFilter;
    });
  }

  private populateOrdersStatusFilters = (): JSX.Element[] => {
    let orderStatusFilters: IOrderStatusFilter[] = [];

    if (this.props.ordersList && this.props.ordersList.mappedOrders) {
      orderStatusFilters = getOrderStatusFilters(this.props.ordersList.mappedOrders);
    }

    return orderStatusLabels.map((orderStatusLabel: IOrderStatusFilter, index: number): JSX.Element => {
      return (
          <Checkbox
              className="OrdersListFilters-orderStatusCheckbox"
              checked={orderStatusLabel.isChecked}
              key={index}
              label={`${orderStatusLabel.status} (${this.extractCount(orderStatusFilters, index, orderStatusLabel)})`}
              name={(`${orderStatusLabel.status.toLowerCase()}Filter`)}
              onChange={(value: string) => this.handleCheckboxChange(orderStatusLabel, value)}
              isSmall={true}
          />
      );
    });
  }

  private extractCount = (filters: IOrderStatusFilter[], index: number, orderStatusLabel: IOrderStatusFilter): number => {
    let count: number = 0;
    const found: IOrderStatusFilter = filters.find((item): any => {
      return item.status.toLowerCase() === orderStatusLabel.status.toLowerCase();
    });

    if (found && found.count) {
      count = found.count;
    }

    return count;
  }

  private unCheckOrderStatusFilters(): void {
    orderStatusLabels.forEach((orderStatusLabels: IOrderStatusFilter): any => {
      if (orderStatusLabels.isChecked) {
        this.handleCheckboxChange(orderStatusLabels, `${orderStatusLabels.status.toLowerCase()}Filter}`);
        // this.setState({ onDateOrCalendarFetch: false });
      }
    });
  }

  private onSearchOptionClick = (config: Partial<IOrdersListFiltersState>): void => {
    this.setState(
      {
        isSearchOptionsOpen: false,
        optionsTemplate: null,
        searchOptionSelectedValue: config.searchOptionSelectedValue,
        searchOptionDisplayValue: config.searchOptionDisplayValue,
      },
    );
    this.handleSortOptionsOnChange(config.searchOptionSelectedValue);
  }

  private generateSearchOptions(active: string): JSX.Element {
    return (
          <div className="OrdersListFilters-optionsMenu">
            <button
                className={
                  `OrdersListFilters-searchOption ${active === 'Store ID' ? 'OrdersListFilters--selectedOption' : ''}`}
                onClick={(): void => {
                  this.onSearchOptionClick(
                    {
                      searchOptionSelectedValue: 0,
                      searchOptionDisplayValue: 'Store ID',
                    },
                  );
                }}
            >Store ID
            </button>
            <button
                className={
                  `OrdersListFilters-searchOption ${active === 'Order ID' ? 'OrdersListFilters--selectedOption' : ''}`}
                onClick={(): void => {
                  this.onSearchOptionClick(
                    {
                      searchOptionSelectedValue: 1,
                      searchOptionDisplayValue: 'Order ID',
                    },
                  );
                }}
            >
              Order ID
            </button>
          </div >
    );
  }

  private dismissOnOutsideClick = (event: any): void => {
    const isActualTarget: boolean =  event.target.classList.contains('OrdersListFilters-optionsValue')
        || event.target.classList.contains('OrdersListFilters-searchOptions')
        || event.target.classList.contains('OrdersListFilters-searchOption');

    if (!isActualTarget && this.state.isSearchOptionsOpen) {
      this.setState(
        {
          isSearchOptionsOpen: false,
          optionsTemplate: null,
        },
      );
    }
  }
}

const mapStateToProps = (state: IStoreState) => {
  return {
    ordersLoading: state.ordersList.loading,
    ordersList: state.ordersList.data,
    persistedOrderListFilters: state.persistedOrderListFilters,
    persistOrderListSearchData: state.persistOrderListSearch.data,
    isImpersonating: state.userDetails && state.userDetails.isImpersonating,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<any>) => {
  return {
    persistOrderListFilters: (date: Date, dateType: OrderDateValue, statusFilters: string[]) =>
        dispatch(persistOrderFiltersAction(date, dateType, statusFilters)),
    filterOrderStatus: (labels: IOrderStatusFilter[]) => dispatch(orderStatusFiltersAction(labels)),
    persistOrderListSearch: (searchedValue: string, searchedOrders: IOrder[]) => dispatch(
        persistOrderListSearchAction(searchedValue, searchedOrders),
    ),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(OrdersListFilters);
