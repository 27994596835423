import React, { useEffect, useState } from 'react';
import PresaleCampaignEditor from '../../../components/Presales/PresaleCampaignEditor';
import FullPageLayout from '../../../layouts/FullPageLayout/FullPageLayout';
import { getBasePath } from '../../../helpers/Users/UserHelper';
import {
  IPresaleCampaign,
  IPresalesState,
  IStoreState,
  IUserDetails,
} from '../../../types';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import LoadingThrobber from '../../../components/LoadingThrobber/LoadingThrobber';
import {
  editPresalesCampaignRequest,
  getPresalesCampaignRequest,
  publishPresalesCampaignRequest,
} from '../../../actions/SupplyChain/presalesActions';
import { isEqual } from 'lodash';
import { navigateTo } from '../../../routing/Navigation';
import { IPublishPresalesCampaignRequest } from '../../../api/SupplyChain/presalesApi';
import moment from 'moment';
import { BreadcrumbSegment } from '@orderly/morrisons-component-library';

interface ICreateCampaignProps {
  userDetails: IUserDetails;
  uploadResults: string[];
  presales: IPresalesState;
  match: {
    params: {
      campaignId: string;
    };
  };
  getPresalesCampaign: (campaignId: number) => void;
  editPresaleCampaign: (
    campaignDetails: IPresaleCampaign,
    onSuccess: (data: string[]) => void,
  ) => void;
  publishPresaleCampaign: (campaign: IPublishPresalesCampaignRequest) => Promise<any>;
}

const EditCampaign = ({
  userDetails,
  presales,
  match,
  getPresalesCampaign,
  editPresaleCampaign,
  publishPresaleCampaign,
}: ICreateCampaignProps) => {
  const [uploadResults, setUploadResults] = useState<string[]>([]);
  const [showUploadResponseModal, setShowUploadResponseModal] = useState<boolean>(false);
  const [template, setTemplate] = useState<any>();
  const [filteredCustomerList, setFilteredCustomerList] = useState<any[]>();
  const [visibleStoreList, setVisibleStoreList] = useState<any[]>();
  const [presaleCampaign, setPresaleCampaign] = useState<IPresaleCampaign>();
  const [initialCampaignData, setInitialCampaignData] = useState<IPresaleCampaign>(null);
  const [isButtonDisabled, setIsButtonDisabled] = useState<boolean>(true);
  const [campaignExpired, setCampaignExpired] = useState<boolean>(false);
  const [campaignPublished, setCampaignPublished] = useState<boolean>(false);
  const campaignId = parseInt(match.params.campaignId, 10);

  useEffect(() => {
    if (!presales.campaign) {
      getPresalesCampaign(campaignId);
    }
  }, []);

  useEffect(() => {
    if (presales.campaign) {
      if (presales.campaign.id === campaignId) {
        const existingCampaign: IPresaleCampaign = {
          id: presales.campaign.id,
          name: presales.campaign.name,
          deadline: presales.campaign.deadline,
          extendLines: presales.campaign.extendLines,
          storeIds: Object.keys(presales.campaign.storeSubmissions).map(storeId => parseInt(storeId, 10)),
          associatedCampaignId: presales.campaign.associatedCampaignId
            ? presales.campaign.associatedCampaignId : 0,
          customerGroups: [],
          assignmentType: 'Retailer',
          published: presales.campaign.published,
          template: '',
        };

        setCampaignExpired(moment(existingCampaign.deadline).isBefore(moment().startOf('day')));
        setCampaignPublished(existingCampaign.published);

        setPresaleCampaign(existingCampaign);
        setInitialCampaignData(existingCampaign);
      } else {
        presales.campaign = null;
        getPresalesCampaign(campaignId);
      }
    }
  }, [presales.campaign]);

  useEffect(() => {
    if (initialCampaignData) {
      const customerParamsEmpty =
        presaleCampaign.storeIds.length === 0 && presaleCampaign.customerGroups.length === 0;

      const invalidCampaignName = !presaleCampaign.name || presaleCampaign.name.length > 150;

      const sortedInitialStoreIds = [...initialCampaignData.storeIds].sort();
      const sortedPresaleStoreIds = [...presaleCampaign.storeIds].sort();
      const sortedInitialCustomerGroups = [...initialCampaignData.customerGroups].sort();
      const sortedPresaleCustomerGroups = [...presaleCampaign.customerGroups].sort();

      const hasChanges =
        !isEqual(sortedInitialCustomerGroups, sortedPresaleCustomerGroups) ||
        !isEqual(sortedInitialStoreIds, sortedPresaleStoreIds) ||
        initialCampaignData.extendLines !== presaleCampaign.extendLines ||
        initialCampaignData.name !== presaleCampaign.name ||
        initialCampaignData.deadline !== presaleCampaign.deadline ||
        initialCampaignData.template !== presaleCampaign.template ||
        initialCampaignData.associatedCampaignId !== presaleCampaign.associatedCampaignId ||
        initialCampaignData.assignmentType !== presaleCampaign.assignmentType;
      setIsButtonDisabled(!hasChanges || invalidCampaignName || customerParamsEmpty || presaleCampaign.published);
    }
  }, [presaleCampaign]);

  const onEditCampaign = () => {
    editPresaleCampaign(presaleCampaign, (results: string[]) => {
      if (results.length > 0) {
        setUploadResults(results);
        setShowUploadResponseModal(true);
      } else {
        navigateTo(`${getBasePath(userDetails)}presales-campaigns/preview/campaign/${campaignId}`);
      }
    });
  };

  const onPublishCampaign = () => {
    const confirmationMessage = `Are you sure you want to ${
      presaleCampaign.published ? 'unpublish' : 'publish'
    } this campaign?`;
    if (window.confirm(confirmationMessage)) {
      publishPresaleCampaign({
        id: presaleCampaign.id,
        published: !presaleCampaign.published,
      })
        .then(() => {
          navigateTo(`${getBasePath(userDetails)}presales-campaigns`);
        })
        .catch();
    }
  };

  const breadcrumbs: BreadcrumbSegment[] =
    [
      { key: 0, text: 'Presales Campaigns', url: `${getBasePath(userDetails)}presales-campaigns` },
      { key: 1, text: `${campaignExpired ? 'Expired' : 'Edit'}`, url: window.location.pathname },
    ];

  return (
    <FullPageLayout
      heading={`${
        campaignExpired ? 'Expired ' : campaignPublished ? 'Published ' : 'Edit '
      }Presale Campaign`}
      breadcrumbs={breadcrumbs}
      homeRoute="/admin/home"
    >
      {!presaleCampaign || presales.loading ? (
        <LoadingThrobber />
      ) : (
        <PresaleCampaignEditor
          action="Edit"
          uploadResults={uploadResults}
          showUploadResponseModal={showUploadResponseModal}
          presaleCampaign={presaleCampaign}
          isButtonDisabled={isButtonDisabled}
          template={template}
          filteredCustomerList={filteredCustomerList}
          visibleStoreList={visibleStoreList}
          campaignExpired={campaignExpired}
          campaignPublished={campaignPublished}
          setShowUploadResponseModal={setShowUploadResponseModal}
          setPresaleCampaign={setPresaleCampaign}
          submitCampaignAction={onEditCampaign}
          publishCampaignAction={onPublishCampaign}
          setTemplate={setTemplate}
          setFilteredCustomerList={setFilteredCustomerList}
          setVisibleStoreList={setVisibleStoreList}
          setUploadResults={setUploadResults}
        />
      )}
    </FullPageLayout>
  );
};

const mapStateToProps = (state: IStoreState) => {
  return {
    userDetails: state.userDetails,
    presales: state.presales,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<any>) => ({
  getPresalesCampaign: (campaignId: number) => dispatch(getPresalesCampaignRequest(campaignId)),
  editPresaleCampaign: (
    parameters: IPresaleCampaign,
    onSuccess: (data: string[]) => void,
  ) => dispatch(editPresalesCampaignRequest(parameters, onSuccess)),
  publishPresaleCampaign: (campaign: IPublishPresalesCampaignRequest) =>
    dispatch(publishPresalesCampaignRequest(campaign)),
});

export default connect(mapStateToProps, mapDispatchToProps)(EditCampaign);
