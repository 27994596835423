import {
  IGetOrderImportCsvParams,
  getOrderImportCsv,
} from '../../api/Basket/OrderImportCsv/GetOrderImportCsv';

export const GET_ORDER_CSV_REQUEST = 'GET_ORDER_CSV_REQUEST';
export type GET_ORDER_CSV_REQUEST = typeof GET_ORDER_CSV_REQUEST;

export const GET_ORDER_CSV_RESPONSE = 'GET_ORDER_CSV_RESPONSE';
export type GET_ORDER_CSV_RESPONSE = typeof GET_ORDER_CSV_RESPONSE;

export const CLEAR_ORDER_CSV_DOWNLOAD = 'CLEAR_ORDER_CSV_DOWNLOAD';
export type CLEAR_ORDER_CSV_DOWNLOAD = typeof CLEAR_ORDER_CSV_DOWNLOAD;

export const GET_ORDER_CSV_ERROR = 'GET_ORDER_CSV_ERROR';
export type GET_ORDER_CSV_ERROR = typeof GET_ORDER_CSV_ERROR;

export const getOrderImportCsvRequest = (
  parameters: IGetOrderImportCsvParams,
): any => {
  return async (dispatch: any) => {
    dispatch({
      type: GET_ORDER_CSV_REQUEST,
    });

    const response = await getOrderImportCsv(parameters);

    try {
      if (response.status === 200) {
        dispatch({
          type: GET_ORDER_CSV_RESPONSE,
          response: response.data.ordercsv,
        });
      } else {
        onError(dispatch, (response.data as unknown) as string);
      }
    } catch (error) {
      console.log(error);
      onError(dispatch);
    }
  };
};

export const clearOrderImportDownload = (): any => {
  return (dispatch: any) => {
    dispatch({
      type: CLEAR_ORDER_CSV_DOWNLOAD,
    });
  };
};

const onError = (dispatch: any, message: string = null) => dispatch({
  type: GET_ORDER_CSV_ERROR,
  response: message,
});
