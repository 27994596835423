import { getEdnDetails } from '../../api/Orders/OrderEdnApi';
import { IOrderEdnResponse } from '../../api/Orders/IOrderEdnResponse';
import { AxiosResponse } from 'axios';

export const CLEAR_ORDER_EDN_CONTENTS = 'CLEAR_ORDER_EDN_CONTENTS';
export type CLEAR_ORDER_EDN_CONTENTS = typeof CLEAR_ORDER_EDN_CONTENTS;

export const orderEdnRequest = (orderId: string, retailerName: string): any => {

  return (dispatch : any) => {
    dispatch({
      type: 'GET_ORDER_EDN_REQUEST',
    });

    return getEdnDetails(orderId, retailerName)
      .then((response: AxiosResponse<IOrderEdnResponse>) => {
        if (response.status === 200) {
          dispatch({
            type: 'GET_ORDER_EDN_RESPONSE',
            response: response.data.edns,
          });
        } else {
          onError(dispatch, response.data as unknown as string);
        }
      })
      .catch((err: any) => {
        console.log(err);
        onError(dispatch);
      });
  };
};

const onError = (dispatch: any, message: string = null) => dispatch({
  type: 'GET_ORDER_EDN_ERROR',
  response: message,
});

export const clearOrderEdnContents = () => {
  return {
    type: CLEAR_ORDER_EDN_CONTENTS,
  };
};
