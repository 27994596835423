import { Dispatch } from 'redux';
import {
  IDeleteShoppingListsBody,
  IUploadShoppingListBody,
  deleteShoppingLists,
  getAdminShoppingLists,
  getShoppingLists,
  uploadShoppingList,
} from '../../api/ShoppingLists/shoppingListsApi';
import { loadCurrentShoppingList } from './shoppingListActions';
import { toastr } from 'react-redux-toastr';

export const GET_ADMIN_SHOPPING_LISTS_REQUEST = 'GET_ADMIN_SHOPPING_LISTS_REQUEST';
export const GET_ADMIN_SHOPPING_LISTS_RESPONSE = 'GET_ADMIN_SHOPPING_LISTS_RESPONSE';
export const GET_ADMIN_SHOPPING_LISTS_ERROR = 'GET_ADMIN_SHOPPING_LISTS_ERROR';

export const getAdminShoppingListsRequest = (): (dispatch: Dispatch) => Promise<void> => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: GET_ADMIN_SHOPPING_LISTS_REQUEST,
    });

    try {
      const response = await getAdminShoppingLists();
      if (response.status === 200) {
        const { shoppingLists } = response.data;

        dispatch({
          type: GET_ADMIN_SHOPPING_LISTS_RESPONSE,
          response: shoppingLists,
        });
      } else {
        if (response instanceof Error) {
          onError(dispatch);
        }
      }
    } catch (error) {
      onError(dispatch);
    }
  };
};

const onError = (dispatch: Dispatch) => dispatch({
  type: GET_ADMIN_SHOPPING_LISTS_ERROR,
});

export const GET_SHOPPING_LISTS_REQUEST = 'GET_SHOPPING_LISTS_REQUEST';
export const GET_SHOPPING_LISTS_RESPONSE = 'GET_SHOPPING_LISTS_RESPONSE';
export const GET_SHOPPING_LISTS_ERROR = 'GET_SHOPPING_LISTS_ERROR';

export const getShoppingListsRequest = (): (dispatch: Dispatch) => Promise<void> => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: GET_SHOPPING_LISTS_REQUEST,
    });

    try {
      const response = await getShoppingLists();
      if (response.status === 200) {
        const { shoppingLists } = response.data;

        dispatch({
          type: GET_SHOPPING_LISTS_RESPONSE,
          response: shoppingLists,
        });

        loadCurrentShoppingList(shoppingLists, dispatch);
      } else {
        if (response instanceof Error) {
          onError(dispatch);
        }
      }
    } catch (error) {
      onError(dispatch);
    }
  };
};

export const UPLOAD_SHOPPING_LIST_REQUEST = 'UPLOAD_SHOPPING_LIST_REQUEST';
export const UPLOAD_SHOPPING_LIST_RESPONSE = 'UPLOAD_SHOPPING_LIST_RESPONSE';
export const UPLOAD_SHOPPING_LIST_ERROR = 'UPLOAD_SHOPPING_LIST_ERROR';

export const uploadShoppingListRequest = (
  body: IUploadShoppingListBody,
): (dispatch: Dispatch) => Promise<void> => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: UPLOAD_SHOPPING_LIST_REQUEST,
    });

    try {
      const response = await uploadShoppingList(body);
      if (response.status === 200) {
        const { success } = response.data;

        dispatch({
          type: UPLOAD_SHOPPING_LIST_RESPONSE,
          response: success,
        });

        toastr.success(
          'Shopping list uploaded successfully',
          `The shopping list "${body.description}" has been uploaded.`,
        );
      } else {
        if (response instanceof Error) {
          onUploadError(dispatch);
        }
      }
    } catch (error) {
      onUploadError(dispatch);
    }
  };
};

const onUploadError = (dispatch: Dispatch) => dispatch({
  type: UPLOAD_SHOPPING_LIST_ERROR,
});

export const DELETE_SHOPPING_LISTS_REQUEST = 'DELETE_SHOPPING_LISTS_REQUEST';
export const DELETE_SHOPPING_LISTS_RESPONSE = 'DELETE_SHOPPING_LISTS_RESPONSE';
export const DELETE_SHOPPING_LISTS_ERROR = 'DELETE_SHOPPING_LISTS_ERROR';

export const deleteShoppingListsRequest = (
  body: IDeleteShoppingListsBody,
  onSuccess: () => void,
  onFailure: () => void,
): (dispatch: Dispatch) => Promise<void> => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: DELETE_SHOPPING_LISTS_REQUEST,
    });

    try {
      const response = await deleteShoppingLists(body);
      if (response.status === 200) {
        const { shoppingLists } = response.data;

        dispatch({
          type: DELETE_SHOPPING_LISTS_RESPONSE,
          response: shoppingLists,
        });

        onSuccess();
        loadCurrentShoppingList(shoppingLists, dispatch, true);
      } else {
        if (response instanceof Error) {
          onRemoveError(dispatch);
          onFailure();
        }
      }
    } catch (error) {
      onRemoveError(dispatch);
      onFailure();
    }
  };
};

const onRemoveError = (dispatch: Dispatch) => dispatch({
  type: DELETE_SHOPPING_LISTS_ERROR,
});
