import { Dispatch } from 'redux';
import { AxiosResponse } from 'axios';
import {
  IGetOrderOpportunitiesParams,
  IGetOrderOpportunitiesResponse,
  getOrderOpportunities,
} from '../../api/OrderOpportunities/orderOpportunitiesApi';

export const GET_ORDER_OPPORTUNITIES_COUNTDOWN_REQUEST = 'GET_ORDER_OPPORTUNITIES_COUNTDOWN_REQUEST';
export const GET_ORDER_OPPORTUNITIES_COUNTDOWN_RESPONSE = 'GET_ORDER_OPPORTUNITIES_COUNTDOWN_RESPONSE';
export const GET_ORDER_OPPORTUNITIES_COUNTDOWN_ERROR = 'GET_ORDER_OPPORTUNITIES_COUNTDOWN_ERROR';

export const getOrderOpportunitiesCountdownRequest = (parameters: IGetOrderOpportunitiesParams): any => {
  return (dispatch: Dispatch) => {
    dispatch({
      type: GET_ORDER_OPPORTUNITIES_COUNTDOWN_REQUEST,
    });

    return getOrderOpportunities(parameters)
      .then((response: AxiosResponse<IGetOrderOpportunitiesResponse>) => {
        if (response.status === 200) {
          dispatch({
            type: GET_ORDER_OPPORTUNITIES_COUNTDOWN_RESPONSE,
            response: response.data.orderOpportunities,
          });
        } else {
          onError(dispatch);
        }
      })
      .catch((err: any) => {
        console.log(err);
        onError(dispatch);
      });
  };
};

const onError = (dispatch: Dispatch) => dispatch({
  type: GET_ORDER_OPPORTUNITIES_COUNTDOWN_ERROR,
});
