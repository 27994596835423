import { Dispatch } from 'redux';
import { createUser } from '../../api/UserManagement/userManagementApi';

export const createUserRequest = (
  endpoint: string,
  payload: any,
  onSuccess: () => void): (dispatch: Dispatch) => Promise<void> => {
  return async (dispatch: Dispatch): Promise<void> => {
    try {
      const result = await createUser(endpoint, payload);
      if (result) {
        onSuccess();
      }
    } catch (err) {
          // Refactor - implement error handling
    }
  };
};
