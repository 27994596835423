import React, { Dispatch } from 'react';
import './PasswordReset.scss';
import { connect } from 'react-redux';
import * as siteWrapperAction from '../../actions/siteWrapperAction';
import * as forgotPasswordAction from '../../actions/forgotPasswordAction';
import ReCAPTCHA from '../../components/ReCAPTCHA/ReCAPTCHA';
import { TextInput, Button, Card } from '@orderly/morrisons-component-library';
import { NavLink } from 'react-router-dom';
import logo from '../../assets/images/login_logo.svg';
import { IForgotPasswordParams } from '../../api/userApi';
import Settings from '../../settings';
import { IStoreState, IForgotPassword } from '../../types';
import IPasswordResetState from './IPasswordResetState';

class PasswordReset extends React.Component<any, IPasswordResetState> {
  public state: IPasswordResetState = {
    username: {
      value: '',
      error: '',
    },
    recaptcha: {
      value: '',
      error: '',
    },
  };

  public componentWillUnmount(): void {
    this.props.updateSiteWrapperVisibility(true);
  }

  public isFormValid = (): boolean => {
    const { username, recaptcha } = this.state;
    let errorCount = 0;

    if (!username.value || username.value.length <= 0) {
      username.error = 'Email address is required';
      errorCount += 1;
    }
    if (Settings().RecaptchaEnabled && (!recaptcha.value || recaptcha.value.length === 0)) {
      recaptcha.error = 'reCAPTCHA is required';
      errorCount += 1;
    }

    this.setState({ username });
    return errorCount <= 0;
  }

  public onSubmit = (e: React.SyntheticEvent): void => {
    e.preventDefault();

    if (!this.isFormValid()) {
      return;
    }

    const { username, recaptcha } = this.state;
    const parameters : IForgotPasswordParams = {
      Email: username.value,
      Recaptcha: recaptcha.value,
    };

    this.props.forgotPassword(parameters, () => this.props.history.push('/'));
  }

  public render(): JSX.Element {
    const { username, recaptcha } = this.state;
    const { loading, error } = this.props;
    return (
      <div className="password-reset-page">
        <div className="password-reset-underlay" />
        <div className="container content-wrapper">
          <div className="logo-wrapper">
            <NavLink className="logo" exact to={'/'}>
              <img src={logo} alt="site logo" className="img-responsive" />
            </NavLink>
          </div>
          <Card>
            <h1>Reset your password</h1>
            <form noValidate onSubmit={this.onSubmit}>
              <TextInput
                type="text"
                label="Email Address"
                name="email"
                value={username.value}
                error={error || username.error}
                onChange={(e: any) => this.setState({ username: { value: e, error: '' } })}
              />
              {
                recaptcha.error &&
                <span className="recaptcha-error-text">
                  {recaptcha.error}
                </span>
              }
              <ReCAPTCHA
                onChange={(value: string) => this.setState({ recaptcha: { value, error: '' } })}
              />
              <Button
                disabled={loading}
                type="submit"
                text="Send password reset email"
                className="secondary password-reset-btn"
              />
            </form>
          </Card>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: IStoreState) : IForgotPassword => {
  return {
    loading: state.forgotPassword.loading,
    error: state.forgotPassword.error,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<
                                        siteWrapperAction.IUpdateSiteWrapperVisibility
                                        | forgotPasswordAction.IForgotPasswordRequest>) => {
  return {
    updateSiteWrapperVisibility: (status: any) =>
      dispatch(siteWrapperAction.updateSiteWrapperVisibility(status)),
    forgotPassword: (parameters: IForgotPasswordParams, onSuccess: () => void) =>
      dispatch(forgotPasswordAction.forgotPasswordRequest(parameters, onSuccess)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PasswordReset);
