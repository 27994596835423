import React from 'react';
import './FooterPanelItem.scss';
import { IFooterPanelItem, IFooterLinks } from '../interfaces';

const FooterPanelItem = ({ id, title, links, isExpanded, onExpanded }: IFooterPanelItem) => (
  <div className={`footer-panel-item ${isExpanded ? 'is-expanded' : ''}`}>
    <h4 onClick={() => onExpanded(id)}>{title}</h4>
    <div className="content-wrapper">
      <ul>
        {links && links.length > 0 &&
          links.map((link: IFooterLinks, index) =>
            <li key={index}>
              <a
                href={link.url}
                target="_blank"
                rel="noopener noreferrer"
              >
                {link.name}
              </a>
            </li>,
          )
        }
      </ul>
    </div>
  </div>
);

export default FooterPanelItem;
