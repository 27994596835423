import { getSearchResults, getSearchResultsAdmin, ISearchResponse } from '../api/searchApi';
import { store } from '../store';
import { AxiosResponse } from 'axios';
import {
  GET_SEARCH_RESULTS_ERROR,
  GET_SEARCH_RESULTS_REQUEST,
  GET_SEARCH_RESULTS_RESPONSE,
} from './Search/types/GetSearchResults';

export const getSearchResultsRequest = (searchPhrase: string, startIndex: number, pageSize: number): any => {
  const searchResults = store.getState().searchResults[searchPhrase.toLowerCase()];

  if (searchResults !== undefined && startIndex < searchResults.data.products.length) {
    return {
      type: GET_SEARCH_RESULTS_RESPONSE,
      searchPhrase: searchPhrase.toLowerCase(),
      response: searchResults.data,
      loadMore: startIndex > 0,
    };
  }

  return (dispatch : any) => {
    dispatch({
      type: GET_SEARCH_RESULTS_REQUEST,
      searchPhrase: searchPhrase.toLowerCase(),
      loadMore: startIndex > 0,
    });

    return getSearchResults(searchPhrase, startIndex, pageSize)
      .then((response: AxiosResponse<ISearchResponse>) => {
        if (response.status === 200) {
          dispatch({
            type: GET_SEARCH_RESULTS_RESPONSE,
            searchPhrase: searchPhrase.toLowerCase(),
            response: response.data.results,
            loadMore: startIndex > 0,
          });
        } else {
          onError(dispatch);
        }
      })
      .catch((err: any) => {
        console.log(err);
        onError(dispatch);
      });
  };
};

export const getSearchResultsAdminRequest = (
  searchPhrase: string,
  startIndex: number,
  pageSize: number,
  retailerId: number,
  categoryCode: string): any => {
  const searchResults = store.getState().searchResults[searchPhrase.toLowerCase()];

  if (searchResults !== undefined && startIndex < searchResults.data.products.length) {
    return {
      type: GET_SEARCH_RESULTS_RESPONSE,
      searchPhrase: searchPhrase.toLowerCase(),
      response: searchResults.data,
      loadMore: startIndex > 0,
    };
  }

  return (dispatch : any) => {
    dispatch({
      type: GET_SEARCH_RESULTS_REQUEST,
      searchPhrase: searchPhrase.toLowerCase(),
      loadMore: startIndex > 0,
    });

    return getSearchResultsAdmin(searchPhrase, startIndex, pageSize, retailerId, categoryCode)
      .then((response: AxiosResponse<ISearchResponse>) => {
        if (response.status === 200) {
          dispatch({
            type: GET_SEARCH_RESULTS_RESPONSE,
            searchPhrase: searchPhrase.toLowerCase(),
            response: response.data.results,
            loadMore: startIndex > 0,
          });
        } else {
          onError(dispatch);
        }
      })
      .catch((err: any) => {
        console.log(err);
        onError(dispatch);
      });
  };
};

const onError = (dispatch: any) => dispatch({
  type: GET_SEARCH_RESULTS_ERROR,
});
