import React, { useState, useEffect, useRef } from 'react';
import './MobileHeader.scss';
import { Button, MobileMenu, MenuItem } from '@orderly/morrisons-component-library';
import { DebounceInput } from 'react-debounce-input';
import { NavLink } from 'react-router-dom';
import { IMobileHeaderProps } from './IMobileHeaderProps';
import { navigateTo } from '../../../routing/Navigation';
import { ICategory } from '../../../types';
import { mapCategory } from '../../../helpers/Category/mapCategory';
import { getTotalPrice } from '../../../helpers/Product/ProductPriceHelper';
import logo from '../../../assets/images/logo.svg';
import { isAdmin, isBulkUser } from '../../../helpers/Users/UserHelper';
import { StringToBoolean } from '../../../helpers/TypesHelper';
import ShoppingListControls from '../../ShoppingListControls';

function highlightDescriptionSearchTerms(description: string, term: string): any {
  const regex = new RegExp(`(${term})`, 'gi');
  return { __html: description.replace(regex, '<b>$1</b>') };
}

const MobileHeader = (props: IMobileHeaderProps) => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [basketTotal, setBasketTotal] = useState('£0.00');

  const {
    showCompleteMenu,
    logout,
    onSearch,
    onShowSearch,
    onHideSearch,
    searchResult,
    searchPhrase,
    showSearch,
    onCompleteSearch,
    showSearchResults,
    onClick,
    isImpersonating,
    basket,
    categories,
    userDetails,
    retailerDetails,
  } = props;

  useEffect(
    () => {
      if (basket && basket.data) {
        const total = getTotalPrice(basket.data.items);
        setBasketTotal(total);
      }
    },
    [basket],
  );

  const handleNavigateTo = (url: string) => {
    if (url === '/logout') {
      logout();
    } else {
      navigateTo(url);
    }
  };

  const createMenuItem = (key: number, text: string, allText: string,
    onRedirect: any, url: string, close: any, children?: MenuItem[]) => {
    return {
      key,
      text,
      allText,
      onRedirect,
      url,
      close,
      children,
    };
  };

  const constructMenuItems = (): MenuItem[] => {
    const basicMenuItems: MenuItem[] = [
      {
        key: 0,
        text: 'Shop',
        url: '/catalogue',
        allText: 'Shop',
        children: [{
          key: -1,
          text: 'Shop',
          allText: 'All Products',
          onRedirect: navigateTo,
          url: '/catalogue',
          close: console.log,
          children: categories.data.map((category: ICategory): MenuItem => (
            createMenuItem(
              category.id,
              mapCategory(category.description),
              `All ${mapCategory(category.description)} products`,
              navigateTo,
              `/catalogue?category=${category.id}`,
              console.log,
            ))),
        }, createMenuItem(-2, 'My account', 'My account', navigateTo, '/my-account', console.log, [
          createMenuItem(-3, 'My orders', 'My orders', navigateTo, '/orders', console.log),
          createMenuItem(-4, 'My documents', 'My documents', navigateTo, '/documents', console.log),
        ]), createMenuItem(-5,
          isImpersonating ? 'Stop impersonating' : 'Log out',
          isImpersonating ? 'Stop impersonating' : 'Log out',
          navigateTo, '/logout', console.log),
        ],
        close: console.log,
        onRedirect: navigateTo,
        includeViewAll: false,
      }];

    const additonalMenuItems: MenuItem[] = [];

    if (!isAdmin(userDetails) && !isBulkUser(retailerDetails)) {
      const deliveriesEnabled: boolean = retailerDetails && StringToBoolean(retailerDetails.meta.RebatesEnabled);
      const rebatesEnabled: boolean = retailerDetails && StringToBoolean(retailerDetails.meta.DhlEnabled);
      const planogramsEnabled: boolean = retailerDetails && StringToBoolean(retailerDetails.meta.PlanogramsEnabled);
      const printshopEnabled: boolean = retailerDetails && StringToBoolean(retailerDetails.meta.PrintshopEnabled);

      if (deliveriesEnabled) {
        additonalMenuItems.push(createMenuItem(1, 'Deliveries', 'Deliveries',
          navigateTo,
          '/deliveries', console.log));
      }

      if (planogramsEnabled) {
        additonalMenuItems.push(createMenuItem(-6, 'Planograms', 'Planograms', navigateTo, '/store-documents', console.log));
      }

      if (rebatesEnabled) {
        additonalMenuItems.push(
          createMenuItem(
            -7,
            'Spend More, Save More',
            'Spend More, Save More',
            navigateTo,
            '/rebates',
            console.log,
          ),
        );
      }

      additonalMenuItems.push(
        createMenuItem(-8, 'Promotions', 'Promotions', navigateTo, '/catalogue?tradingPromotion=true', console.log),
      );

      if (printshopEnabled) {
        additonalMenuItems.push(createMenuItem(-9, 'Printshop', 'Printshop', navigateTo, '/printshop', console.log));
      }

      if (deliveriesEnabled) {
        additonalMenuItems.push(
          createMenuItem(-10, 'Customer Services', 'Customer Services',
            navigateTo, '/customer-services', console.log));
      }
      additonalMenuItems.push(createMenuItem(-11, 'Presales', 'Presales', navigateTo, '/presales', console.log));
    }
    basicMenuItems[0].children = [...basicMenuItems[0].children, ...additonalMenuItems];

    return basicMenuItems;
  };

  return (
    <div className="header-bar-content mobile-header">
      <div className="row">
        {showCompleteMenu && categories && categories.data &&
          <Button
            type="button"
            text={mobileMenuOpen ? 'Close' : 'Menu'}
            className="menu"
            onClick={() => setMobileMenuOpen(!mobileMenuOpen)}
          />
        }
        {showCompleteMenu && categories && categories.data &&
          <MobileMenu
            options={constructMenuItems()}
            onRedirect={handleNavigateTo}
            isOpen={mobileMenuOpen}
            close={() => setMobileMenuOpen(false)}
            topLevelText={undefined}
            includeViewAll={true}
            allText="Shop"
            Url="/catalogue"
            prependUrl=""
            appendUrl=""
          />
        }
        <div className="logo-wrapper">
          <a className="logo" onClick={onClick}>
            <img src={logo} className="img-responsive" alt="logo" />
          </a>
        </div>
        {showCompleteMenu
          &&
          <div className="mini-basket-wrapper">
            <Button
              className="basket tertiary"
              icon="icon-basket"
              iconPos="left"
              text={basketTotal}
              type="button"
              onClick={() => navigateTo('/basket')}
            />
            <span className="item-count">
              {basket && basket.data ? basket.data.items.length : '0'}
            </span>
            {
              basket && basket.data && basket.data.categoryCode &&
              <span
                className={`${mapCategory(basket.data.categoryCode).toLowerCase().replace(' ', '-')}`}
              >
                {mapCategory(basket.data.categoryCode)}
              </span>
            }
          </div>
        }
      </div>
      {showCompleteMenu
        && (<div className="row">
          {showSearch && <div className="search-section">
            <DebounceInput
              type="text"
              name="search"
              id="search"
              minLength={3}
              placeholder="Find a product"
              className="search-bar"
              debounceTimeout={500}
              onFocus={(event: React.FocusEvent<HTMLInputElement>) => onShowSearch()}
              onBlur={(event: React.FocusEvent<HTMLInputElement>) => setTimeout(() => onHideSearch(), 200)}
              onKeyDown={(event: React.KeyboardEvent<HTMLInputElement>) => {
                if (event.keyCode === 13) {
                  event.preventDefault();
                  onCompleteSearch(event.currentTarget.value);
                }
              }}
              onChange={event => onSearch(event.target.value)}
            />
            <span className="icon-search" />
            <div className="mobile-additional-options">
              <Button
                className="scan-button"
                type="button"
                text="Scan Barcode"
                onClick={() => navigateTo('/barcode-scanner')}
              />
              <ShoppingListControls />
            </div>

            {showSearchResults && searchResult && (
              (searchResult.subCategories && searchResult.subCategories.length > 0)
              || (searchResult.products && searchResult.products.length > 0)
            )
              && <div className="search-results">
                <ul>
                  {(searchResult.subCategories ? searchResult.subCategories : []).map((subcategory: any, index: number) => {
                    return <li key={`s_${index}`} >
                      <NavLink
                        exact
                        to={
                          `/catalogue?category=${subcategory.categoryId}`
                          + `&subcategory=${subcategory.id}`}
                      >
                        <span
                          className="icon-categories"
                        />
                        <div
                          dangerouslySetInnerHTML={
                            highlightDescriptionSearchTerms(subcategory.description, searchPhrase)}
                        />
                      </NavLink>
                    </li>;
                  })}

                  {(searchResult.products ? searchResult.products : []).map((product: any, index: number) => {
                    if (index > 5) return null;

                    return <li key={`p_${index}`}>
                      <NavLink exact to={`/product/${product.itemId}`}>
                        <div
                          dangerouslySetInnerHTML={
                            highlightDescriptionSearchTerms(product.description, searchPhrase)}
                        />
                      </NavLink>
                    </li>;
                  })}
                </ul>
              </div>}
          </div>}
        </div>)
      }
    </div>
  );
};

export default MobileHeader;
