export const StringToBoolean = (stringValue: string) => {
  if (!stringValue) {
    return false;
  }

  switch (stringValue.toLowerCase().trim()) {
    case 'false':
    case 'no':
    case '0':
    case null:
    case undefined:
      return false;

    default:
      return true;
  }
};
