import { Reducer } from 'redux';
import {
  GET_USER_STORES_REQUEST,
  GET_USER_STORES_RESPONSE,
  GET_USER_STORES_ERROR,
  GET_USER_STORES_CLEAR } from '../../actions/Users/userStoresAction';
import { IRetailerStores, IApiRequest } from '../../types';
import { CLEAR_USER_PAGE } from '../../actions/usersAction';

const initialState: IApiRequest<IRetailerStores> = {
  loading: false,
  UpdatedAt: null,
  data: null,
  error: '',
};

const getUserStoreReducer: Reducer<IApiRequest<IRetailerStores>> = (
  state: IApiRequest<IRetailerStores> = initialState,
  action : any,
  ) => {
  switch (action.type) {
    case GET_USER_STORES_CLEAR:
    case CLEAR_USER_PAGE: {
      return {
        ...initialState,
      };
    }
    case GET_USER_STORES_RESPONSE: {
      return {
        ...initialState,
        UpdatedAt: new Date().toISOString(),
        data: { stores: action.response.stores },
      };
    }
    case GET_USER_STORES_REQUEST: {
      return {
        ...initialState,
        UpdatedAt: new Date().toISOString(),
        loading: true,
      };
    }
    case GET_USER_STORES_ERROR: {
      return {
        ...initialState,
        UpdatedAt: new Date(),
        error: action.error || 'There was an error loading this users stores.',
      };
    }
    default: {
      return state;
    }
  }
};

export default getUserStoreReducer;
