import { AnyAction, Reducer } from 'redux';
import {
  DELETE_TRADING_PACKS_RESPONSE,
  DELETE_TRADING_PACKS_REQUEST,
  DELETE_TRADING_PACKS_ERROR,
} from '../../actions/Products';
import { IBaseRequest } from '../../types';

const initialState: IBaseRequest = {
  error: '',
  loading: false,
};

const deleteTradingPackReducer: Reducer<IBaseRequest> = (
  state: IBaseRequest = initialState,
  action: AnyAction,
) => {
  switch (action.type) {
    case DELETE_TRADING_PACKS_REQUEST: {
      return {
        error: '',
        loading: true,
      };
    }
    case DELETE_TRADING_PACKS_RESPONSE: {
      return {
        error: '',
        loading: false,
      };
    }
    case DELETE_TRADING_PACKS_ERROR: {
      return {
        ...initialState,
        error: 'An error has occured',
      };
    }
    default: {
      return state;
    }
  }
};

export default deleteTradingPackReducer;
