import { Dispatch } from 'redux';
import { getOrderItemsWithChanges } from '../../api/Orders/orderItemsApi';

export const GET_ORDER_ITEMS_WITH_CHANGES_REQUEST = 'GET_ORDER_ITEMS_WITH_CHANGES_REQUEST';
export const GET_ORDER_ITEMS_WITH_CHANGES_RESPONSE = 'GET_ORDER_ITEMS_WITH_CHANGES_RESPONSE';
export const GET_ORDER_ITEMS_WITH_CHANGES_ERROR = 'GET_ORDER_ITEMS_WITH_CHANGES_ERROR';

export const getOrderItemsWithChangesRequest = (orderNumber: string): (dispatch: Dispatch) => Promise<any> => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: GET_ORDER_ITEMS_WITH_CHANGES_REQUEST,
    });

    try {
      const response = await getOrderItemsWithChanges(orderNumber);
      if (response.status === 200) {
        const { orderItems } = response.data;
        dispatch({
          type: GET_ORDER_ITEMS_WITH_CHANGES_RESPONSE,
          response: orderItems,
        });

      } else {
        onError(dispatch);
      }
    } catch (error) {
      console.error(error);
      onError(dispatch);
    }
  };
};

const onError = (dispatch: Dispatch) => dispatch({
  type: GET_ORDER_ITEMS_WITH_CHANGES_ERROR,
});
