import { Reducer } from 'redux';
import {
  POST_CONTACT_FORM_ERROR,
  POST_CONTACT_FORM_REQUEST,
  POST_CONTACT_FORM_RESPONSE } from '../../actions/MyAccount/contactFormAction';
import { IContactForm } from '../../types';

const initialState: IContactForm = {
  loading: false,
  error: '',
};

const contactFormReducer: Reducer<IContactForm> = (
  state: IContactForm = initialState,
  action : any,
  ) => {
  switch (action.type) {
    case POST_CONTACT_FORM_RESPONSE: {
      return {
        loading: false,
        error: '',
      };
    }
    case POST_CONTACT_FORM_REQUEST: {
      return {
        loading: true,
        error: '',
      };
    }
    case POST_CONTACT_FORM_ERROR: {
      return {
        loading: false,
        error: 'An error has occurred. Please try again.',
      };
    }
    default: {
      return state;
    }
  }
};

export default contactFormReducer;
