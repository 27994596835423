import React, { useMemo, useState } from 'react';
import { FullPageLayout } from '../../../layouts';
import { useQuery } from '@tanstack/react-query';
import { getAdminBanners } from '../../../api/Banners/bannersApi';
import { BannerLocations, IBanner } from '../../../types';
import { LoadingThrobber } from '../../../components';
import { navigateTo } from '../../../routing/Navigation';
import { Button, Tab, Tabs } from '@orderly/morrisons-component-library';
import { QUERY_KEYS } from '../../../constants';
import { BannerTable } from './components';
import styles from './Banners.module.scss';

function Banners(): JSX.Element {
  const [activeTab, setActiveTab] = useState<BannerLocations>(BannerLocations.Homepage); // Default to Homepage tab
  const parts = window.location.pathname.split('/');
  const newPath = `/${parts[1]}/home`;

  const { data: banners, isLoading } = useQuery({
    queryKey: [QUERY_KEYS.ADMIN_BANNERS],
    queryFn: getAdminBanners,
  });

  const filteredBanners = useMemo<IBanner[]>(
    () => {
      if (!banners) {
        return [];
      }

      return banners.filter((banner: IBanner) => banner.displayLocation === activeTab);
    },
    [banners, activeTab],
  );

  const bannerStatusTabs = useMemo(() => {
    return Object.keys(BannerLocations)
      .filter(key => isNaN(Number(key)))
      .map(status => ({
        id: BannerLocations[status as keyof typeof BannerLocations].toString(),
        title: `${status} Banners`,
      }));
  }, []);

  const onChange = (value: string) => {
    setActiveTab(Number(value) as BannerLocations);
  };

  return (
    <FullPageLayout
      heading="Manage Banners"
      breadcrumbs={[
        {
          key: 0,
          text: 'Manage Banners',
          url: '/admin/manage-banners',
        },
      ]}
      headerAdditionalContent={
        <Button
          className="tertiary"
          type="button"
          text="Create Banner"
          onClick={() => navigateTo('/admin/manage-banners/create')}
        />
      }
      homeRoute={newPath}
    >
      <div className={styles.container}>
        {isLoading && <LoadingThrobber />}
        {!isLoading && (
          <Tabs onChange={onChange} activeTab={activeTab.toString()}>
            {bannerStatusTabs.map(tab => (
              <Tab key={tab.id} id={tab.id} title={tab.title}>
                <BannerTable tabId={tab.id} data={filteredBanners} />
              </Tab>
            ))}
          </Tabs>
        )}
      </div>
    </FullPageLayout>
  );
}

export default Banners;
