import { Reducer } from 'redux';
import { IApiRequest, IReport } from '../../types';

const intitalState: IApiRequest<IReport> = {
  loading: false,
  UpdatedAt: null,
  data: null,
  error: '',
};

const userAcceptanceReducer: Reducer<IApiRequest<IReport>> = (
  state: IApiRequest<IReport> = intitalState,
  action: any,
) => {
  switch (action.type) {
    case 'GET_USER_ACCEPTANCE_REPORT_REQUEST': {
      return {
        ...intitalState,
        loading: true,
        UpdatedAt: new Date().toISOString(),
      };
    }
    case 'GET_USER_ACCEPTANCE_REPORT_RESPONSE': {
      return {
        ...intitalState,
        UpdatedAt: new Date().toISOString(),
        data: {
          content: action.response.content,
          filename: action.response.filename,
        },
      };
    }
    case 'GET_USER_ACCEPTANCE_REPORT_ERROR': {
      return {
        ...intitalState,
        UpdatedAt: new Date().toISOString(),
        error: action.error || 'There was an error loading this report.',
      };
    }
    case 'RESET_USER_ACCEPTANCE_REPORT': {
      return {
        ...intitalState,
      };
    }
    default: {
      return state;
    }
  }
};

export default userAcceptanceReducer;
