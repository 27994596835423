import React, { useEffect, useState } from 'react';
import './Search.scss';
import '../../index.scss';
import { ISearchProps } from './ISearchProps';
import { Breadcrumbs, Dropdown } from '@orderly/morrisons-component-library';
import { navigateTo } from '../../routing/Navigation';
import { getQueryString } from '../../helpers/UrlHelper';
import { IStoreState, IProduct } from '../../types';
import { connect } from 'react-redux';
import ProductPromo from '../../components/ProductPromo/ProductPromo';
import SearchError from '../../components/SearchError/SearchError';
import InfiniteScroll from 'react-infinite-scroller';
import { getSearchResultsRequest } from '../../actions/searchAction';
import { Dispatch } from 'redux';
import LoadingThrobber from '../../components/LoadingThrobber/LoadingThrobber';
import ProductList from '../Catalogue/ProductList/ProductList';

const PAGE_SIZE = 20;

const Search = ({ results, retailerDetails, search }: ISearchProps) => {
  const [displayMode, setDisplayMode] = useState('list');
  const [maxOrderQuantity, setMaxOrderQuantity] = useState(undefined);
  const [pagingIndex, setPagingIndex] = useState(0);

  useEffect(
    () => {
      if (retailerDetails &&
        retailerDetails.data &&
        retailerDetails.data.meta.MaxOrderQuantity) {
        const maximumOrderQuantity = parseInt(retailerDetails.data.meta.MaxOrderQuantity, 10);
        setMaxOrderQuantity(maximumOrderQuantity);
      }
    },
    [retailerDetails],
  );

  const searchTerm = getQueryString('q').toLowerCase();
  const data = results[searchTerm] && results[searchTerm].data || undefined;
  const products = data && data.products || [];

  useEffect(
    () => {
      search(searchTerm, pagingIndex, PAGE_SIZE);
    },
    [],
  );

  if (products.length === 0 && results[searchTerm] && results[searchTerm].loading) {
    return (
      <>
        <SearchError>
          Loading results for
          <br />
          <span className="mainText">{getQueryString('q')}</span>
        </SearchError>
        <LoadingThrobber />
      </>
    );
  }

  if (products.length === 0 && !(results[searchTerm] && results[searchTerm].loading)) {
    return (
      <SearchError>
        Sorry, we couldn't find any products to match for
        <br />
        <span className="mainText">{getQueryString('q')}</span>
      </SearchError>
    );
  }

  const loadSearchResults = () => {
    if (results[searchTerm] && !results[searchTerm].loading) {
      setPagingIndex(pagingIndex + PAGE_SIZE);
      search(searchTerm, pagingIndex, PAGE_SIZE);
    }
  };

  const appendList = (): JSX.Element => {
    if (products.length > 0) {
      return (
        <ProductList
          products={products}
          maxOrderQuantity={maxOrderQuantity}
        />
      );
    }
  };

  const appendGrid = (): JSX.Element => {
    let grid: any = null;
    if (products.length > 0) {
      // Refactor - revise or map response to be of the correct type
      grid = products.map((item: IProduct): any => {
        return (
          <ProductPromo
            key={item.id}
            product={item}
            lazyLoad
            maxOrderQuantity={maxOrderQuantity}
          />
        );
      });
    }
    return grid;
  };

  return (
    <div className="search-page-wrapper">
      <div className="background" />
      <Breadcrumbs
        onHomeClick={() => navigateTo('/home')}
        onRedirect={navigateTo}
        segments={[
          { key: 0, text: 'Search', url: null },
        ]}
      />
      <section className="heading-wrapper">
        <h1>
          <span className="result-detail">
            {data.totalResults} results for&nbsp;
          </span>
          <span className="result-search-term">
            {decodeURIComponent(searchTerm)}
          </span>
        </h1>
        <div className="display-filters">
          <Dropdown
            className="catalogue-display-mode"
            label=""
            name="catalogue-display-mode"
            onChange={(option: any) => setDisplayMode(option.value)}
            selectedValue={displayMode}
            options={[
              { label: <span className="dropdown-option"><span className="icon-list" />List view</span>, value: 'list' },
              { label: <span className="dropdown-option"><span className="icon-grid" />Grid view</span>, value: 'grid' },
            ]}
          />
          <button
            className={`btn-display-mode ${displayMode === 'list' ? 'active' : 'inactive'}`}
            type="button"
            onClick={() => setDisplayMode('list')}
          >
            <span className="icon-list" />
          </button>
          <button
            className={`btn-display-mode ${displayMode === 'grid' ? 'active' : 'inactive'}`}
            type="button"
            onClick={() => setDisplayMode('grid')}
          >
            <span className="icon-grid" />
          </button>
        </div>
      </section>
      <InfiniteScroll
        className="search-results-wrapper"
        pageStart={0}
        loadMore={() => { loadSearchResults(); }}
        initialLoad={false}
        hasMore={data.canLoadMore}
        loader={
          <div key="infiniteScrollSpinnerContainer" className="infinite-scroll-spinner-container">
            <LoadingThrobber preventScroll={false} />
          </div>
        }
      >
        {
          (displayMode === 'list') && <div className="search-results-list">
            {appendList()}
          </div>
        }
        {
          (displayMode === 'grid') && appendGrid()
        }
      </InfiniteScroll>
    </div>
  );
};

const mapStateToProps = (state: IStoreState) => {
  return {
    results: state.searchResults,
    retailerDetails: state.retailerDetails,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    search: (searchPhrase: string, startIndex: number, pageSize: number) =>
      dispatch(getSearchResultsRequest(searchPhrase, startIndex, pageSize)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Search);
