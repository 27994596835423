import { Dispatch } from 'redux';
import {
  createPresalesCampaignApi,
  editPresalesCampaignApi,
  deletePresalesCampaignApi,
  getPresalesCampaign,
  getPresalesCampaigns,
  IPublishPresalesCampaignRequest,
  publishPresalesCampaignApi,
  submitPresalesCampaignApi,
  updatePresaleSubmissionApi,
  downloadPresalesCampaignReportApi,
  downloadExamplePresalesTemplateApi,
} from '../../api/SupplyChain/presalesApi';
import {
  IDeletePresalesCampaign,
  IPresaleCampaign,
  ISubmitPresalesCampaign,
  IUpdatePresalesSubmission,
} from '../../types';
import { toastr } from 'react-redux-toastr';

export const GET_CAMPAIGNS_REQUEST = 'GET_CAMPAIGNS_REQUEST';
export const GET_CAMPAIGNS_RESPONSE = 'GET_CAMPAIGNS_RESPONSE';
export const GET_CAMPAIGNS_ERROR = 'GET_CAMPAIGNS_ERROR';
export const CREATE_CAMPAIGN_REQUEST = 'CREATE_CAMPAIGN_REQUEST';
export const CREATE_CAMPAIGN_RESPONSE = 'CREATE_CAMPAIGN_RESPONSE';
export const CREATE_CAMPAIGN_ERROR = 'CREATE_CAMPAIGN_ERROR';
export const GET_CAMPAIGN_REQUEST = 'GET_CAMPAIGN_REQUEST';
export const GET_CAMPAIGN_RESPONSE = 'GET_CAMPAIGN_RESPONSE';
export const GET_CAMPAIGN_ERROR = 'GET_CAMPAIGN_ERROR';
export const PUBLISH_CAMPAIGN_REQUEST = 'PUBLISH_CAMPAIGN_REQUEST';
export const PUBLISH_CAMPAIGN_RESPONSE = 'PUBLISH_CAMPAIGN_SUCCESS';
export const PUBLISH_CAMPAIGN_ERROR = 'PUBLISH_CAMPAIGN_ERROR';
export const EDIT_CAMPAIGN_REQUEST = 'EDIT_CAMPAIGN_REQUEST';
export const EDIT_CAMPAIGN_RESPONSE = 'EDIT_CAMPAIGN_RESPONSE';
export const EDIT_CAMPAIGN_ERROR = 'EDIT_CAMPAIGN_ERROR';
export const DELETE_CAMPAIGN_REQUEST = 'DELETE_CAMPAIGN_REQUEST';
export const DELETE_CAMPAIGN_RESPONSE = 'DELETE_CAMPAIGN_RESPONSE';
export const DELETE_CAMPAIGN_ERROR = 'DELETE_CAMPAIGN_ERROR';
export const SUBMIT_CAMPAIGN_REQUEST = 'SUBMIT_CAMPAIGN_REQUEST';
export const SUBMIT_CAMPAIGN_RESPONSE = 'SUBMIT_CAMPAIGN_RESPONSE';
export const SUBMIT_CAMPAIGN_ERROR = 'SUBMIT_CAMPAIGN_ERROR';
export const UPDATE_CAMPAIGN_SUBMISSION_REQUEST = 'UPDATE_CAMPAIGN_SUBMISSION_REQUEST';
export const UPDATE_CAMPAIGN_SUBMISSION_RESPONSE = 'UPDATE_CAMPAIGN_SUBMISSION_RESPONSE';
export const UPDATE_CAMPAIGN_SUBMISSION_ERROR = 'UPDATE_CAMPAIGN_SUBMISSION_ERROR';
export const DOWNLOAD_PRESALES_CAMPAIGN_REQUEST = 'DOWNLOAD_PRESALES_CAMPAIGN_REQUEST';
export const DOWNLOAD_PRESALES_CAMPAIGN_RESPONSE = 'DOWNLOAD_PRESALES_CAMPAIGN_RESPONSE';
export const DOWNLOAD_PRESALES_CAMPAIGN_ERROR = 'DOWNLOAD_PRESALES_CAMPAIGN_ERROR';
export const DOWNLOAD_EXAMPLE_TEMPLATE_REQUEST = 'DOWNLOAD_EXAMPLE_TEMPLATE_REQUEST';
export const DOWNLOAD_EXAMPLE_TEMPLATE_RESPONSE = 'DOWNLOAD_EXAMPLE_TEMPLATE_RESPONSE';
export const DOWNLOAD_EXAMPLE_TEMPLATE_ERROR = 'DOWNLOAD_EXAMPLE_TEMPLATE_ERROR';

export const getPresalesCampaignsRequest = (activeCampaigns: boolean) => {
  return async (dispatch: Dispatch): Promise<void> => {
    dispatch({
      type: GET_CAMPAIGNS_REQUEST,
    });

    try {
      const response = await getPresalesCampaigns(activeCampaigns);
      if (response.status === 200) {
        dispatch({
          type: GET_CAMPAIGNS_RESPONSE,
          response: {
            campaigns: response.data.presalesCampaigns,
            active: activeCampaigns,
          },
        });
      } else {
        getPresalesCampaignsError(dispatch);
      }
    } catch (error) {
      getPresalesCampaignsError(dispatch);
    }
  };
};

const getPresalesCampaignsError = (dispatch: any) => dispatch({
  type: GET_CAMPAIGNS_ERROR,
});

export const createPresalesCampaignRequest = (
  parameters: IPresaleCampaign,
  onSuccess: (results: string[], campaign: IPresaleCampaign) => void,
): any => {
  return async (dispatch: any) => {
    dispatch({
      type: CREATE_CAMPAIGN_REQUEST,
      data: parameters,
    });

    try {
      const response = await createPresalesCampaignApi(parameters);

      if (response.status === 200) {
        onSuccess(
          response.data.createPresalesCampaign.results,
          response.data.createPresalesCampaign.campaign,
        );

        if (response.data.createPresalesCampaign.success) {
          toastr.success('Success', 'The presales campaign has been created successfully.');
          dispatch({
            type: CREATE_CAMPAIGN_RESPONSE,
            response: response.data.createPresalesCampaign.campaign,
          });
        } else {
          createPresalesCampaignError(dispatch);
        }
      } else {
        createPresalesCampaignError(dispatch);
      }
    } catch (error) {
      createPresalesCampaignError(dispatch);
    }
  };
};

const createPresalesCampaignError = (dispatch: any) => {
  dispatch({
    type: CREATE_CAMPAIGN_ERROR,
  });
};

export const getPresalesCampaignRequest = (campaignId: number, storeId?: number) => {
  return async (dispatch: any) => {
    dispatch({
      type: GET_CAMPAIGN_REQUEST,
      data: { campaignId, storeId },
    });

    try {
      const response = await getPresalesCampaign(campaignId, storeId);
      if (response.status === 200) {
        dispatch({
          type: GET_CAMPAIGN_RESPONSE,
          response: response.data.presalesCampaign,
        });
      } else {
        getPresalesCampaignError(dispatch);
      }
    } catch (error) {
      getPresalesCampaignError(dispatch);
    }
  };
};

const getPresalesCampaignError = (dispatch: any) => dispatch({
  type: GET_CAMPAIGN_ERROR,
});

export const publishPresalesCampaignRequest = (
  parameters: IPublishPresalesCampaignRequest,
): any => {
  return async (dispatch: any) => {
    dispatch({
      type: PUBLISH_CAMPAIGN_REQUEST,
      data: parameters,
    });

    try {
      const response = await publishPresalesCampaignApi(parameters);

      if (response.status === 200) {
        toastr.success('Success',
          `The presales campaign has been ${parameters.published ? 'published' : 'unpublished'} successfully.`);
        dispatch({
          type: PUBLISH_CAMPAIGN_RESPONSE,
        });
      } else {
        publishPresalesCampaignError(dispatch);
      }
    } catch (error) {
      publishPresalesCampaignError(dispatch);
    }
  };
};

const publishPresalesCampaignError = (dispatch: any) => dispatch({
  type: PUBLISH_CAMPAIGN_ERROR,
});

export const editPresalesCampaignRequest = (
  parameters: IPresaleCampaign,
  onSuccess: (data: string[], campaign: IPresaleCampaign) => void,
): any => {
  return async (dispatch: any) => {
    dispatch({
      type: EDIT_CAMPAIGN_REQUEST,
      data: parameters,
    });

    try {
      const response = await editPresalesCampaignApi(parameters);

      if (response.status === 200) {
        onSuccess(response.data.editPresalesCampaign.results, response.data.editPresalesCampaign.campaign);
        if (response.data.editPresalesCampaign.success) {
          toastr.success('Success', 'The presales campaign has been edited successfully.');
          dispatch({
            type: CREATE_CAMPAIGN_RESPONSE,
            response: response.data.editPresalesCampaign.campaign,
          });
        } else {
          editPresalesCampaignError(dispatch);
        }
      } else {
        editPresalesCampaignError(dispatch);
      }
    } catch (error) {
      editPresalesCampaignError(dispatch);
    }
  };
};

const editPresalesCampaignError = (dispatch: any) => {
  dispatch({
    type: EDIT_CAMPAIGN_ERROR,
  });
};

export const deletePresalesCampaignRequest = (
  parameters: IDeletePresalesCampaign,
  onSuccess: () => void,
): any => {
  return async (dispatch: any) => {
    dispatch({
      type: DELETE_CAMPAIGN_REQUEST,
      data: parameters,
    });

    try {
      const response = await deletePresalesCampaignApi(parameters);

      if (response.status === 200) {
        toastr.success('Success', 'The presales campaign has been deleted successfully.');
        dispatch({
          type: DELETE_CAMPAIGN_RESPONSE,
        });
        onSuccess();
      } else {
        deletePresalesCampaignError(dispatch);
      }
    } catch (error) {
      deletePresalesCampaignError(dispatch);
    }
  };
};

const deletePresalesCampaignError = (dispatch: any) => {
  dispatch({
    type: DELETE_CAMPAIGN_ERROR,
  });
  toastr.error(
    'Error', 'An error has occurred whilst deleting the presales campaign. Please review the errors and try again.',
  );
};

export const submitPresalesCampaignRequest = (
  parameters: ISubmitPresalesCampaign,
  onSuccess: () => void,
): any => {
  return async (dispatch: any) => {
    dispatch({
      type: SUBMIT_CAMPAIGN_REQUEST,
      data: parameters,
    });

    try {
      const response = await submitPresalesCampaignApi(parameters);

      if (response.status === 200) {
        toastr.success('Success', 'The presales campaign has been submitted successfully.');
        dispatch({
          type: SUBMIT_CAMPAIGN_RESPONSE,
        });
        onSuccess();
      } else {
        submitPresalesCampaignError(dispatch);
      }
    } catch (error) {
      submitPresalesCampaignError(dispatch);
    }
  };
};

const submitPresalesCampaignError = (dispatch: any) => {
  dispatch({
    type: SUBMIT_CAMPAIGN_ERROR,
  });
  toastr.error(
    'Error', 'An error has occurred whilst submiting the presales campaign. Please review the errors and try again.',
  );
};

export const updatePresalesSubmissionRequest = (
  parameters: IUpdatePresalesSubmission,
  onSuccess: () => void,
): any => {
  return async (dispatch: any) => {
    dispatch({
      type: UPDATE_CAMPAIGN_SUBMISSION_REQUEST,
      data: parameters,
    });

    try {
      const response = await updatePresaleSubmissionApi(parameters);

      if (response.status === 200) {
        dispatch({
          type: UPDATE_CAMPAIGN_SUBMISSION_RESPONSE,
        });
        onSuccess();
      } else {
        updatePresalesCampaignError(dispatch);
      }
    } catch (error) {
      updatePresalesCampaignError(dispatch);
    }
  };
};

const updatePresalesCampaignError = (dispatch: any) => {
  dispatch({
    type: UPDATE_CAMPAIGN_SUBMISSION_ERROR,
  });
  toastr.error(
    'Error', 'An error has occurred whilst updating your submission details.',
  );
};

export const downloadPresalesCampaignReportRequest = (
  campaignId: number,
  onSuccess: (data: any) => void,
  onFailure: () => void,
): any => {
  return async (dispatch: any) => {
    dispatch({
      type: DOWNLOAD_PRESALES_CAMPAIGN_REQUEST,
      data: campaignId,
    });

    try {
      const response = await downloadPresalesCampaignReportApi(campaignId);

      if (response.status === 200) {
        toastr.success(
          'Report Generated',
          'Your report has successfully been generated.',
        );
        dispatch({
          type: DOWNLOAD_PRESALES_CAMPAIGN_RESPONSE,
        });
        onSuccess(response.data);
      } else {
        onDownloadPresalesCampaignError(dispatch);
        onFailure();
      }
    } catch (error) {
      onDownloadPresalesCampaignError(dispatch);
      onFailure();
    }
  };
};

const onDownloadPresalesCampaignError = (dispatch: any) => {
  toastr.error(
    'Error',
    'An error has occured while generating the report');

  dispatch({
    type: DOWNLOAD_PRESALES_CAMPAIGN_ERROR,
    response: 'An error has occured while generating the report',
  });
};

export const downloadExamplePresalesTemplateRequest = (
  onSuccess: (data: any) => void,
  onFailure: () => void,
): any => {
  return async (dispatch: any) => {
    dispatch({
      type: DOWNLOAD_EXAMPLE_TEMPLATE_REQUEST,
    });

    try {
      const response = await downloadExamplePresalesTemplateApi();

      if (response.status === 200) {
        dispatch({
          type: DOWNLOAD_EXAMPLE_TEMPLATE_RESPONSE,
        });
        onSuccess(response.data);
      } else {
        downloadExamplePresalesTemplateError(dispatch);
        onFailure();
      }
    } catch (error) {
      downloadExamplePresalesTemplateError(dispatch);
      onFailure();
    }
  };
};

const downloadExamplePresalesTemplateError = (dispatch: any) => {
  toastr.error(
    'Error',
    'An error has occured while downloading example template');

  dispatch({
    type: DOWNLOAD_EXAMPLE_TEMPLATE_ERROR,
    response: 'An error has occured while downloading example template',
  });
};
