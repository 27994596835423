import { Reducer } from 'redux';
import { IApiRequest, IUserDetails } from '../types';

const initialState: IApiRequest<IUserDetails> = {
  loading: false,
  UpdatedAt: null,
  data: null,
  error: '',
};

const impersonateUserReducer: Reducer<IApiRequest<IUserDetails>> =
(state: IApiRequest<IUserDetails> = initialState, action : any) => {
  switch (action.type) {
    case 'IMPERSONATE_USER_REQUEST':
      return {
        ...state,
        loading: true,
      };
    case 'IMPERSONATE_USER_RESPONSE':
      return {
        ...state,
        data: action.payload,
        loading: false,
      };
    default:
      return state;
  }
};

export default impersonateUserReducer;
