import Fetch from '../apiConfig/apiConfig';
import { AxiosPromise } from 'axios';
import { IOrderItemsResponse } from './IOrderItemsResponse';
import { IOrderItemWithChanges } from '../../types';

export const getOrderItems = (orderId: string, retailer: string): AxiosPromise<IOrderItemsResponse> => {
  return Fetch().then(axios => axios.get(`orders/items?retailer=${retailer}&order=${orderId}`));
};

export interface IOrderItemsWithChangesResponse {
  orderItems: IOrderItemWithChanges[];
}

export const getOrderItemsWithChanges = (orderNumber: string): AxiosPromise<IOrderItemsWithChangesResponse> => {
  return Fetch().then(axios => axios.get(`orders/item-changes?orderNumber=${orderNumber}`));
};
