import React from 'react';
import './TermsOfUse.scss';

export class TermsOfUse extends React.Component {
  public render(): JSX.Element {
    return (
      <div className="faq-wrapper">
        <div className="background"/>
        {/* <Breadcrumbs
          onHomeClick={() => navigateTo('/home')}
          onRedirect={navigateTo}
          segments={breadcrumbs} \\ Need to add breadcrumbs
        /> */}
        <section className="heading-wrapper">
          <h1>Terms of use</h1>
        </section>
      </div>
    );
  }
}
