import { Reducer } from 'redux';
import {
  FORGOT_PASSWORD_REQUEST,
  FORGOT_PASSWORD_RESPONSE,
  FORGOT_PASSWORD_ERROR } from '../actions/forgotPasswordAction';
import { IForgotPassword } from '../types';

const initialState: IForgotPassword = {
  loading: false,
  error: '',
};

const userLoginReducer: Reducer<IForgotPassword> = (state: IForgotPassword = initialState, action : any) => {
  switch (action.type) {
    case FORGOT_PASSWORD_REQUEST: {
      return {
        error: '',
        loading: true,
      } as IForgotPassword;
    }
    case FORGOT_PASSWORD_RESPONSE: {
      return {
        error: '',
        loading: false,
      } as IForgotPassword;
    }
    case FORGOT_PASSWORD_ERROR: {
      return {
        error: action.error || 'An error has occurred. Please try again.',
        loading: false,
      } as IForgotPassword;
    }
    default: {
      return state;
    }
  }
};

export default userLoginReducer;
