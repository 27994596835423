import { Action } from 'redux';
import { AxiosResponse } from 'axios';
import { logout } from '../../api/Logout/logoutApi';
import { ILogoutParams } from '../../api/Logout/ILogoutParams';

export const USER_LOGOUT_REQUEST = 'USER_LOGOUT_REQUEST';
export type USER_LOGOUT_REQUEST = typeof USER_LOGOUT_REQUEST;
export const USER_LOGOUT_RESPONSE = 'USER_LOGOUT_RESPONSE';
export type USER_LOGOUT_RESPONSE = typeof USER_LOGOUT_RESPONSE;
export const USER_LOGOUT_ERROR = 'USER_LOGOUT_ERROR';
export type USER_LOGOUT_ERROR = typeof USER_LOGOUT_ERROR;

export const userLogoutRequest = (params: ILogoutParams, onSuccess : () => void): any => {
  return (dispatch : any) => {
    dispatch({
      type: USER_LOGOUT_REQUEST,
    });

    localStorage.removeItem('token');
    localStorage.removeItem('refreshToken');
    localStorage.removeItem('userId');

    return logout(params)
      .then((response: AxiosResponse): void => {
        if (response.status === 200) {
          dispatch({
            type: USER_LOGOUT_RESPONSE,
            response: {},
          });

          onSuccess();
        } else {
          dispatch({
            type: USER_LOGOUT_ERROR,
            error: 'Something went wrong logging out, please try again.',
          });
        }
      })
      .catch((err: any) => {
        console.log(err);
        dispatch({
          type: USER_LOGOUT_ERROR,
        });
      });
  };
};
