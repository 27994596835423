import { IPOSProductPreviewDetails } from '../../pages/Printshop/POSCreator/POSCreator';
import { CurrencyType } from '../Product/ProductPriceHelper';
import { POSPromotionTypes } from './POSPromotionTypes.enum';

export const promotionIncludesPrice = (promotionType: POSPromotionTypes) => {
  return [
    POSPromotionTypes.No_Strikethrough,
    POSPromotionTypes.Strikethrough,
    POSPromotionTypes.Multibuy,
    POSPromotionTypes.Better_Than_Half_Price,
    POSPromotionTypes.Half_Price,
    POSPromotionTypes.Save_a_Third,
    POSPromotionTypes.BOGOF,
  ].includes(promotionType);
};

export const promotionIncludesPrevPrice = (promotionType: POSPromotionTypes) => {
  return [
    POSPromotionTypes.Strikethrough,
    POSPromotionTypes.Half_Price,
    POSPromotionTypes.Better_Than_Half_Price,
    POSPromotionTypes.Save_a_Third,
    POSPromotionTypes.BOGOF,
  ].includes(promotionType);
};

export const promotionHasPriceAtTheBottom = (promotionType: POSPromotionTypes) => {
  return [
    POSPromotionTypes.Better_Than_Half_Price,
    POSPromotionTypes.Half_Price,
    POSPromotionTypes.BOGOF,
    POSPromotionTypes.Save_a_Third,
  ].includes(promotionType);
};

export const convertToCurrency = (price: number, currency: CurrencyType) => {
  if (currency === CurrencyType.Pence) {
    return price * 100;
  }
  return price / 100;
};

export const validateProducts = (products: IPOSProductPreviewDetails[]): boolean => {
  let result = true;

  products.forEach((product) => {
    const {
      description,
      promotionType,
      price,
      weight,
      prevPrice,
      multibuyValue,
      priceCurrency,
      prevPriceCurrency,
      maxAllowedPrice,
    } = product;

    let productPrice = parseFloat(price);
    const productPrevPrice = parseFloat(prevPrice);

    if (!description) {
      result = false;
    }

    if (promotionIncludesPrice(promotionType) && (!productPrice || productPrice > maxAllowedPrice)) {
      result = false;
    }

    if (priceCurrency !== prevPriceCurrency) {
      productPrice = convertToCurrency(productPrice, prevPriceCurrency);
    }

    if (promotionIncludesPrevPrice(promotionType) && (!productPrevPrice || productPrevPrice <= productPrice)) {
      result = false;
    }

    if (promotionType === POSPromotionTypes.Multibuy && !parseFloat(multibuyValue)) {
      result = false;
    }
  });

  return result;
};
