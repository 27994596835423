import { Reducer } from 'redux';
import {
  UPDATE_RETAILER_META_RESPONSE,
  UPDATE_RETAILER_META_REQUEST,
  UPDATE_RETAILER_META_ERROR } from '../actions/updateRetailerMetaAction';
import { IRetailer, IApiRequest } from '../types';

const initialState: IApiRequest<IRetailer[]> = {
  data: [],
  error: '',
  UpdatedAt: null,
  loading: false,
};

const retailersReducer: Reducer<IApiRequest<IRetailer[]>>
= (state: IApiRequest<IRetailer[]> = initialState, action : any) => {
  switch (action.type) {
    case UPDATE_RETAILER_META_RESPONSE: {
      return {
        data: action.response,
        error: '',
        UpdatedAt: new Date(),
        loading: false,
      };
    }
    case UPDATE_RETAILER_META_REQUEST: {
      return {
        data: [],
        error: '',
        UpdatedAt: null,
        loading: true,
      };
    }
    case UPDATE_RETAILER_META_ERROR:
    default: {
      return {
        ...state,
        loading: false,
        error: action.response,
      };
    }
  }
};

export default retailersReducer;
