import React, { Dispatch, useEffect, useState } from 'react';
import { IDocumentBlob, IStoreDocument, IStoreState } from '../../types';
import { connect } from 'react-redux';
import LoadingThrobber from '../../components/LoadingThrobber/LoadingThrobber';
import { getUserStoresDocumentsRequest } from '../../actions/StoreDocuments/getUserStoresDocumentsAction';
import Table from '../../components/Table/Table';
import { IDownloadStoreDocumentBlobParams } from '../../api/StoreDocuments/downloadStoreDocumentBlobApi';
import { downloadStoreDocumentBlobRequest } from '../../actions/StoreDocuments/downloadStoreDocumentBlobAction';
import { IStoreDocumentProps } from './IStoreDocumentProps';
import { GenerateDownload } from '../../helpers/DownloadHelper';
import { Button, Dropdown, DropdownOption } from '@orderly/morrisons-component-library';

import styles from './StoreDocuments.module.scss';
import { mapEnumToOptions, getNormalizedEnumString } from '../../helpers/EnumHelper';
import { PlanogramTypes } from '../../helpers/Documents/PlanogramTypes.enum';
import { DebounceInput } from 'react-debounce-input';
import _ from 'lodash';
import FormatDateHelper from '../../helpers/Format/Date/FormatDateHelper';

const StoreDocuments = ({
  getUserStoresDocuments,
  userStoresDocuments,
  downloadStoreDocument,
  documentDownloading,
  userStores,
}: IStoreDocumentProps) => {
  const [documentType, setDocumentType] = useState(-1);
  const [customerGroups, setCustomerGroups] = useState<string[]>([]);
  const [storeIds, setStoreIds] = useState<number[]>([]);
  const [searchValue, setSearchValue] = useState('');

  useEffect(
    () => {
      getUserStoresDocuments();
    },
    [],
  );

  useEffect(() => {
    if (userStoresDocuments.data && userStoresDocuments.data.length > 0) {
      setCustomerGroups([...new Set(userStoresDocuments.data.filter(x => x.customerGroup).map(x => x.customerGroup))]);
      setStoreIds([...new Set(userStoresDocuments.data.filter(x => x.storeId).map(x => x.storeId))]);
    }
  }, [userStoresDocuments.data]);

  const downloadFile = (documentId: number) => {
    downloadStoreDocument(
      { documentId },
      (data: IDocumentBlob) => {
        GenerateDownload(data.data, data.fileName);
      },
      () => {
      },
    );
  };

  const getFilteredDocuments = (data: IStoreDocument[], filteringValue: string | number): IStoreDocument[] => {
    let documents;
    if (_.isString(filteringValue)) {
      documents = data.filter(d => d.customerGroup === filteringValue);
    } else {
      documents = data.filter(d => d.storeId === filteringValue);
    }

    if (documentType > -1) {
      documents = documents.filter(x => x.documentType === documentType);
    }

    if (searchValue) {
      documents = documents.filter(x => x.description.toLowerCase().includes(searchValue.toLowerCase()));
    }

    documents.sort((a, b) => a.description.localeCompare(b.description));

    return documents;
  };

  const { loading, data } = userStoresDocuments;

  return (
    <div className={styles.wrapper}>
      <div className={styles.headingWrapper}>
        <h1 className={styles.title}>Store Documents</h1>
        <div className={styles.actions}>
          <DebounceInput
            type="text"
            name="documentName"
            placeholder="Search for document"
            className={styles.searchBox}
            value={searchValue}
            debounceTimeout={100}
            onChange={event => setSearchValue(event.target.value)}
          />
          <Dropdown
            placeholder="Document Type"
            error=""
            label=""
            name="documentType"
            onChange={(e: DropdownOption) => setDocumentType(parseInt(e.value.toString(), 10))}
            selectedValue={documentType}
            options={mapEnumToOptions(PlanogramTypes)}
            className={styles.dropdown}
          />
        </div>
      </div>
      <div>
        {
          loading && <LoadingThrobber />
        }
        {
          !loading && data &&
          <>
            {
              data.length === 0 ? <EmptyState /> :
                <div>
                  {
                    customerGroups.map((customerGroup) => {
                      const filteredDocuments = getFilteredDocuments(data, customerGroup);

                      return (
                        <div key={customerGroup} className={styles.storeWrapper}>
                          <div>
                            <h2>{customerGroup} - ({filteredDocuments.length})</h2>
                          </div>
                          <div>
                            <Table className="">
                              <thead>
                                <tr>
                                <th className={`Table--stickyColumn ${styles.documentName}`}>
                                    Document name
                                  </th>
                                  <th className={`Table--stickyColumn ${styles.dateColumn}`}>
                                    Effective Start Date
                                  </th>
                                  <th className={`Table--stickyColumn ${styles.dateColumn}`}>
                                    Effective End Date
                                  </th>
                                  <th className={`Table--stickyColumn ${styles.dateColumn}`}>
                                    Last updated
                                  </th>
                                  <th className={`Table--stickyColumn ${styles.type}`}>
                                    Type
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                {
                                  filteredDocuments.length <= 0 ? <tr>
                                    <td colSpan={3}>
                                      No documents have been uploaded for this group.
                                    </td>
                                  </tr> :
                                    filteredDocuments.map((document) => {
                                      return (
                                        <tr key={document.documentId}>
                                          <td>
                                            {document.description}
                                          </td>
                                          <td>
                                            {FormatDateHelper.formatStandardizedDate(document.effectiveStartDate)}
                                          </td>
                                          <td>
                                            {FormatDateHelper.formatStandardizedDate(document.effectiveEndDate)}
                                          </td>
                                          <td>
                                            {FormatDateHelper.formatStandardizedDate(document.lastEditDate, true)}
                                          </td>
                                          <td>
                                            {getNormalizedEnumString(PlanogramTypes[document.documentType])}
                                          </td>
                                          <td className={styles.buttonRight}>
                                            <span title="Download document">
                                              <Button
                                                type="button"
                                                className={styles.downloadFile}
                                                icon="icon-Download"
                                                iconPos="left"
                                                text=""
                                                onClick={() => downloadFile(document.documentId)}
                                                disabled={documentDownloading}
                                              />
                                            </span>
                                          </td>
                                        </tr>
                                      );
                                    })
                                }
                              </tbody>
                            </Table>
                          </div>
                        </div>
                      );
                    })
                  }
                  {
                    storeIds.map((storeId) => {
                      const filteredDocuments = getFilteredDocuments(data, storeId);
                      const store = userStores.find(x => x.id === storeId);
                      return (
                        <div key={store.externalId} className={styles.storeWrapper}>
                          <div>
                            <h2>{store.externalId} - {store.name} ({filteredDocuments.length})</h2>
                          </div>
                          <div>
                            <Table className="">
                              <thead>
                                <tr>
                                  <th className={`Table--stickyColumn ${styles.documentName}`}>
                                    Document name
                                  </th>
                                  <th className={`Table--stickyColumn ${styles.dateColumn}`}>
                                    Effective Start Date
                                  </th>
                                  <th className={`Table--stickyColumn ${styles.dateColumn}`}>
                                    Effective End Date
                                  </th>
                                  <th className={`Table--stickyColumn ${styles.dateColumn}`}>
                                    Last updated
                                  </th>
                                  <th className={`Table--stickyColumn ${styles.type}`}>
                                    Type
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                {
                                  filteredDocuments.length <= 0 ? <tr>
                                    <td colSpan={3}>
                                      No documents have been uploaded for this store.
                                    </td>
                                  </tr> :
                                    filteredDocuments.map((document) => {
                                      return (
                                        <tr key={document.documentId}>
                                          <td>
                                            {document.description}
                                          </td>
                                          <td>
                                            {FormatDateHelper.formatStandardizedDate(document.effectiveStartDate)}
                                          </td>
                                          <td>
                                            {FormatDateHelper.formatStandardizedDate(document.effectiveEndDate)}
                                          </td>
                                          <td>
                                            {FormatDateHelper.formatStandardizedDate(document.lastEditDate, true)}
                                          </td>
                                          <td>
                                            {getNormalizedEnumString(PlanogramTypes[document.documentType])}
                                          </td>
                                          <td className={styles.buttonRight}>
                                            <span title="Download document">
                                              <Button
                                                type="button"
                                                className={styles.downloadFile}
                                                icon="icon-Download"
                                                iconPos="left"
                                                text=""
                                                onClick={() => downloadFile(document.documentId)}
                                                disabled={documentDownloading}
                                              />
                                            </span>
                                          </td>
                                        </tr>
                                      );
                                    })
                                }
                              </tbody>
                            </Table>
                          </div>
                        </div>
                      );
                    })
                  }
                </div>
            }
          </>
        }
      </div>
    </div>
  );
};

const EmptyState = (): JSX.Element => {
  return (
    <div>
      <Table className="">
        <thead>
          <tr>
            <th className={`Table--stickyColumn ${styles.documentName}`}>
              Document name
            </th>
            <th className={`Table--stickyColumn ${styles.lastUpdated}`}>
              Last updated
            </th>
            <th className={`Table--stickyColumn ${styles.type}`}>
              Type
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td colSpan={3}>
              No documents have been uploaded for the stores you can access.
            </td>
          </tr>
        </tbody>
      </Table>
    </div>
  );
};

const mapStateToProps = (state: IStoreState) => {
  return {
    userStoresDocuments: state.userStoresDocuments,
    documentDownloading: state.storeDocumentsDownloadBlob.loading,
    userStores: state.retailerDetails.data.stores,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<any>) => {
  return {
    getUserStoresDocuments: () => dispatch(getUserStoresDocumentsRequest()),
    downloadStoreDocument: (params: IDownloadStoreDocumentBlobParams, onSuccess: (data: IDocumentBlob) => void,
      onFailure: () => void) => dispatch(downloadStoreDocumentBlobRequest(params, onSuccess, onFailure)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(StoreDocuments);
