import React from 'react';
import { Dispatch } from 'redux';
import { IShoppingListButtonProps } from './IShoppingListButtonProps';
import { connect } from 'react-redux';
import { updateShoppingListItemRequest } from '../../actions/ShoppingLists/shoppingListActions';
import { IUpdateShoppingListItemBody } from '../../api/ShoppingLists/shoppingListsApi';
import { IStoreState } from '../../types';
import { Button } from '@orderly/morrisons-component-library';
import './ShoppingListButton.scss';

function ShoppingListButton({
  product,
  shoppingList,
  updateShoppingListItem,
}: IShoppingListButtonProps): JSX.Element {
  const { data, loading } = shoppingList;
  const { products, description, id } = data;

  const hasItem = products.findIndex(x => x.itemId === product.itemId) !== -1;
  const text = hasItem ?
    `Remove ${product.description} from ${description}` :
    `Add ${product.description} to ${description}`;

  const buttonAction = () => {
    updateShoppingListItem({
      id,
      itemId: product.itemId,
    });
  };

  if (id === -1) {
    return (
      <span title="Please select a shopping list">
        <Button
          type="button"
          className="shopping-list-button icon-star-empty"
          text=""
          onClick={() => {}}
          disabled={true}
        />
      </span>
    );
  }

  return (
    <span title={text}>
      <Button
        type="button"
        className={`shopping-list-button ${hasItem ? 'icon-star-full yellow' : 'icon-star-empty'}`}
        text=""
        onClick={buttonAction}
        disabled={loading}
      />
    </span>
  );
}

const mapStateToProps = (state: IStoreState) => {
  return {
    shoppingList: state.shoppingList,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<any>) => {
  return {
    updateShoppingListItem: (body: IUpdateShoppingListItemBody) => dispatch(updateShoppingListItemRequest(body)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ShoppingListButton);
