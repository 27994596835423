import Fetch from '../apiConfig/apiConfig';
import { AxiosPromise } from 'axios';

export interface IPostAdminContactFormParams {
  subject: string;
  message: string;
}

export interface IAdminContactFormResponse {
  [key: string]: any;
}

export const postAdminContactForm = (params: IPostAdminContactFormParams): AxiosPromise<IAdminContactFormResponse> => {
  return Fetch().then(axios => axios.post('admin/contact', params));
};
