import { Action } from 'redux';
import { IForgotPasswordParams, IForgotPasswordResponse, forgotPassword } from '../api/userApi';
import { toastr } from 'react-redux-toastr';
import { AxiosResponse } from 'axios';

export const FORGOT_PASSWORD_REQUEST = 'FORGOT_PASSWORD_REQUEST';
export type FORGOT_PASSWORD_REQUEST = typeof FORGOT_PASSWORD_REQUEST;
export const FORGOT_PASSWORD_RESPONSE = 'FORGOT_PASSWORD_RESPONSE';
export type FORGOT_PASSWORD_RESPONSE = typeof FORGOT_PASSWORD_RESPONSE;
export const FORGOT_PASSWORD_ERROR = 'FORGOT_PASSWORD_ERROR';
export type FORGOT_PASSWORD_ERROR = typeof FORGOT_PASSWORD_ERROR;

export interface IForgotPasswordRequest extends Action {
  type: FORGOT_PASSWORD_REQUEST;
  payload: IForgotPasswordParams;
}

export interface IForgotPasswordResponse extends Action {
  type: FORGOT_PASSWORD_RESPONSE;
  response: IForgotPasswordResponse;
}

export interface IForgotPasswordError extends Action {
  type: FORGOT_PASSWORD_ERROR;
  error?: string;
}

export const forgotPasswordRequest = (parameters: IForgotPasswordParams, onSuccess: () => void): any => {
  return (dispatch : any) => {
    dispatch({
      type: FORGOT_PASSWORD_REQUEST,
    });
    return forgotPassword(parameters)
    .then((response: AxiosResponse<IForgotPasswordResponse>) => {
      if (response.status === 200) {
        if (response.data) {
          toastr.success(
            'Email sent',
            'An email has been sent to the specified account with instructions to reset the password.');

          dispatch({
            type: FORGOT_PASSWORD_RESPONSE,
          });

          onSuccess();
        } else {
          onError(dispatch);
        }
      } else {
        onError(dispatch);
      }
    })
    .catch((err: any) => {
      console.log(err);
      onError(dispatch);
    });
  };
};

const onError = (dispatch : any) => {
  dispatch({
    type: FORGOT_PASSWORD_ERROR,
  });
};
