import React from 'react';
import { Card } from '@orderly/morrisons-component-library';
import { IOrderInfoCardProps } from './IOrderInfoCardProps';
import styles from './OrderInfoCard.module.scss';

function OrderInfoCard({
  orderDay,
  orderMonth,
  orderNumber,
  children,
}: IOrderInfoCardProps): JSX.Element {
  return (
    <Card className={styles.container}>
      <div className={styles.deliveryDate}>
        <div className={styles.day}>
          {orderDay}
        </div>
        <div className={styles.month}>
          {orderMonth}
        </div>
      </div>
      <div className={styles.orderNumber}>
        <p>Order #{orderNumber}</p>
      </div>
      <div>
        {children}
      </div>
    </Card>
  );
}

export default OrderInfoCard;
