import { padLeft } from './padLeft';

export const formatTime = (value: Date) : string => {
  if (value === null) {
    return '-';
  }

  const minute = padLeft(value.getMinutes());

  let hour = value.getHours();
  let suffix = 'am';

  if (hour >= 12) {
    suffix = 'pm';

    if (hour > 12) {
      hour -= 12;
    }
  }

  return `${padLeft(hour)}:${minute}${suffix}`;
};
