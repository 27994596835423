import React, { Dispatch } from 'react';
import { connect } from 'react-redux';
import { IStoreState } from '../../types';
import { IShoppingListControlsProps } from './IShoppingListControlsProps';
import { Dropdown, DropdownOption } from '@orderly/morrisons-component-library';
import { getShoppingListRequest } from '../../actions/ShoppingLists/shoppingListActions';
import { IGetShoppingListParams } from '../../api/ShoppingLists/shoppingListsApi';
import ManageShoppingList from '../ManageShoppingList';
import './ShoppingListControls.scss';

function ShoppingListControls({
  shoppingList,
  shoppingLists,
  getShoppingList,
}: IShoppingListControlsProps): JSX.Element {
  const { id } = shoppingList.data;
  const { data } = shoppingLists;

  const options: DropdownOption[] = data.map((list) => {
    const item: DropdownOption = {
      value: list.id,
      label: list.description,
    };

    return item;
  });

  return (
    <div className="shopping-list-controls-wrapper">
      <ManageShoppingList />
      <Dropdown
        label=""
        name="shopping-list"
        selectedValue={id}
        options={options}
        onChange={item => getShoppingList({ id: parseInt(item.value.toString(), 10) })}
        error=""
        placeholder="Select a Shopping List"
        className="shopping-list-dropdown"
      />
    </div>
  );
}

const mapStateToProps = (state: IStoreState) => {
  return {
    shoppingLists: state.shoppingLists,
    shoppingList: state.shoppingList,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<any>) => {
  return {
    getShoppingList: (parameters: IGetShoppingListParams) => dispatch(getShoppingListRequest(parameters)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ShoppingListControls);
