import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router';

class ScrollTop extends React.Component<RouteComponentProps<any>, any> {
  public componentDidUpdate(prevProps: Readonly<RouteComponentProps<any>>): void {
    if (this.props.location !== prevProps.location) {
      window.scrollTo(0, 0);
    }
  }

  public render(): JSX.Element {
    return null;
  }
}

export default withRouter(ScrollTop);
