import { Action } from 'redux';
import { getRetailers } from '../api/Retailers/retailersApi';
import { IGetRetailersResponse } from '../api/Retailers/IGetRetailersResponse';
import { IStoreState } from '../types';
import { store } from '../store';
import { AxiosResponse } from 'axios';

export const GET_RETAILERS_REQUEST = 'GET_RETAILERS_REQUEST';
export type GET_RETAILERS_REQUEST = typeof GET_RETAILERS_REQUEST;
export const GET_RETAILERS_RESPONSE = 'GET_RETAILERS_RESPONSE';
export type GET_RETAILERS_RESPONSE = typeof GET_RETAILERS_RESPONSE;
export const GET_RETAILERS_ERROR = 'GET_RETAILERS_ERROR';
export type GET_RETAILERS_ERROR = typeof GET_RETAILERS_ERROR;

export interface IGetRetailersError extends Action {
  type: GET_RETAILERS_ERROR;
  error?: string;
}

export const getRetailersRequest = (forceLoad?: boolean): (dispatch: any) => Promise<void> => {
  return (dispatch : any) => {

    const state : IStoreState = store.getState();

    if (!forceLoad && state.retailers.data.length > 0
      && state.catalogueMeta.meta.updatedAt < state.retailers.UpdatedAt) {
      return;
    }

    dispatch({
      type: GET_RETAILERS_REQUEST,
    });

    return getRetailers()
      .then((response : AxiosResponse<IGetRetailersResponse>) => {
        if (response.status === 200) {

          dispatch({
            type: GET_RETAILERS_RESPONSE,
            response: response.data.retailers,
          });

        } else {
          onError(dispatch);
        }
      })
      .catch((err: any) => {
        console.log(err);
        onError(dispatch);
      });
  };
};

const onError = (dispatch: any) => dispatch({
  type: GET_RETAILERS_ERROR,
});
