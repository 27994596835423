import { Reducer } from 'redux';
import { IOrderItem, IApiRequest } from '../types';

const initialState: IApiRequest<IOrderItem[]> = {
  data: null,
  error: '',
  UpdatedAt: null,
  loading: false,
};

const orderItemsReducer: Reducer<IApiRequest<IOrderItem[]>> =
(state: IApiRequest<IOrderItem[]> = initialState, action : any) => {
  switch (action.type) {
    case 'GET_ORDER_ITEMS_RESPONSE': {
      return {
        data: action.response,
        error: '',
        UpdatedAt: null,
        loading: false,
      };
    }
    case 'GET_ORDER_ITEMS_REQUEST': {
      return {
        data: initialState.data,
        error: '',
        UpdatedAt: null,
        loading: true,
      };
    }
    case 'GET_ORDER_ITEMS_ERROR': {
      return {
        data: null,
        error: action.response || 'There was an error finding this order. Please try again',
        UpdatedAt: null,
        loading: false,
      };
    }
    default: {
      return state;
    }
  }
};

export default orderItemsReducer;
