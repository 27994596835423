import { IConfirmedOrdersOptionsAction } from './IConfirmedOrdersOptionsAction';

export const confirmedOrdersOptionsAction = (
  sortOrder: number,
): IConfirmedOrdersOptionsAction => {
  return {
    type: 'CONFIRMED_ORDERS_OPTIONS_PERSIST',
    data: {
      sortOrder,
    },
  };
};
