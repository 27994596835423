import { AxiosPromise } from 'axios';
import { IRetailerProductPricing, IRetailerProductPricingExport } from '../../types';
import Fetch from '../apiConfig/apiConfig';

interface IRetailerPricingResponse {
  retailerPricing: IRetailerProductPricing[];
}

export const getRetailerProductPricing = (id: number): AxiosPromise<IRetailerPricingResponse> => {
  return Fetch().then(axios => axios.get(`admin/products/retailer-pricing/get?retailerId=${id}`));
};

interface IRetailerPricingExportResponse {
  export: IRetailerProductPricingExport;
}

export const getRetailerProductPricingExport = (id: number): AxiosPromise<IRetailerPricingExportResponse> => {
  return Fetch().then(axios => axios.get(`admin/products/retailer-pricing/export?retailerId=${id}`));
};

export interface IImportRetailerProductPricingBody {
  retailerId: number;
  data: string;
}

interface IImportRetailerProductPricingResponse {
  results: string[];
}

export const importRetailerProductPricing = (
  body: IImportRetailerProductPricingBody,
): AxiosPromise<IImportRetailerProductPricingResponse> => {
  return Fetch().then(axios => axios.post('admin/products/retailer-pricing/import', body));
};
