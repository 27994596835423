import { Action } from 'redux';
import { IDocument } from '../../types';
import { AxiosResponse } from 'axios';
import { getDocumentDetails } from '../../api/Documents/getDocumentDetails';
import { IDocumentDetailsParams } from '../../api/Documents/IDocumentDetailsParams';
import { IDocumentDetailsResponse } from '../../api/Documents/IDocumentDetailsResponse';

export const DOCUMENT_DETAILS_REQUEST = 'DOCUMENT_DETAILS_REQUEST';
export type DOCUMENT_DETAILS_REQUEST = typeof DOCUMENT_DETAILS_REQUEST;

export const DOCUMENT_DETAILS_RESPONSE = 'DOCUMENT_DETAILS_RESPONSE';
export type DOCUMENT_DETAILS_RESPONSE = typeof DOCUMENT_DETAILS_RESPONSE;

export const DOCUMENT_DETAILS_ERROR = 'DOCUMENT_DETAILS_ERROR';
export type DOCUMENT_DETAILS_ERROR = typeof DOCUMENT_DETAILS_ERROR;

export interface IEditDocumentResponse extends Action {
  type: DOCUMENT_DETAILS_RESPONSE;
  response: IDocument;
}

export interface IEditDocumentError extends Action {
  type: DOCUMENT_DETAILS_ERROR;
  error?: string;
}

export const getDocumentDetailsRequest =
(parameters: IDocumentDetailsParams,
 onSuccess: (document: IDocument) => void,
 onFailure: (err: any) => void): any => {
  return (dispatch : any) => {

    dispatch({
      type: DOCUMENT_DETAILS_REQUEST,
    });

    return getDocumentDetails(parameters)
      .then((response: AxiosResponse<IDocumentDetailsResponse>) => {
        if (response.status === 200) {
          dispatch({
            type: DOCUMENT_DETAILS_RESPONSE,
            response: response.data.document,
          });

          onSuccess(response.data.document);
        } else {
          if (response instanceof Error) {
            onFailure(response);
          }
        }
      });
  };
};

const onError = (dispatch: any) => dispatch({
  type: DOCUMENT_DETAILS_ERROR,
});
