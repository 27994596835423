import './PendingOrdersList.scss';
import React, { useEffect, useState } from 'react';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import Table, { isHeaderSorted } from '../../../components/Table/Table';
import { IPendingOrder, IStoreState } from '../../../types';
import { IPendingOrdersListProps } from './IPendingOrdersListProps';
import { mapCategory } from '../../../helpers/Category/mapCategory';
import { formatShortDateString, formatDateString } from '../../../helpers/Format';
import { PendingOrdersHelper } from '../../../helpers/Orders/PendingOrdersHelper';
import { IGetUserPendingOrdersParams } from '../../../api/Orders/userPendingOrdersApi';
import { userPendingOrdersRequest } from '../../../actions/Orders/userPendingOrdersAction';
import LoadingThrobber from '../../../components/LoadingThrobber/LoadingThrobber';
import { IAmendOrderParams } from '../../../api/Basket/AmendOrder/IAmendOrderParams';
import { amendOrderRequest } from '../../../actions/Basket/amendOrderAction';
import { navigateTo } from '../../../routing/Navigation';
import { getTotalPrice } from '../../../helpers/Product/ProductPriceHelper';
import PendingOrdersListFilters from '../../../components/PendingOrdersListFilters/PendingOrdersListFilters';
import useDebounce from '../../../helpers/Hooks/useDebounce';

const PendingOrdersList = ({
  userPendingOrders,
  getUserPendingOrders,
  pendingOrdersOptions,
  amendOrder,
  showFilters,
  categories,
}: IPendingOrdersListProps): JSX.Element => {
  const pendingOrdersHelper = new PendingOrdersHelper();

  const [orders, setOrders] = useState([]);
  const [orderNumber, setOrderNumber] = useState('');
  const debouncedOrderNumber = useDebounce(orderNumber, 1000);
  const [storeId, setStoreId] = useState('');
  const debouncedStoreId = useDebounce(storeId, 1000);
  const [modifiedDate, setModifiedDate] = useState('');
  const [deliveryDate, setDeliveryDate] = useState('');
  const [categoryCode, setCategoryCode] = useState('');

  useEffect(
    () => {
      getUserPendingOrders({
        modifiedDate,
        deliveryDate,
        categoryCode,
        orderNumber: debouncedOrderNumber,
        storeId: debouncedStoreId,
      });
    },
    [
      modifiedDate,
      deliveryDate,
      categoryCode,
      debouncedOrderNumber,
      debouncedStoreId,
    ],
  );

  useEffect(
    () => {
      if (!userPendingOrders.loading && userPendingOrders.data) {
        setOrders(pendingOrdersHelper.sortOrders(userPendingOrders.data, pendingOrdersOptions.sortOrder));
      }
    },
    [userPendingOrders, pendingOrdersOptions.sortOrder],
  );

  const amendPendingOrder = ({ id }: IPendingOrder) => {
    amendOrder({ OrderId: id }).then(
      (): void => {
        navigateTo('/basket');
      },
      (error: any): void => {
        console.log('An error has occurred ', error);
      },
    );
  };

  const populateTableHeader = (): JSX.Element => {
    const { property, descending } = pendingOrdersHelper.getOrderByProperty(pendingOrdersOptions.sortOrder);

    return (
      <thead>
        <tr>
          <th>Order ID {isHeaderSorted('orderNumber', property, descending)}</th>
          <th>Modified Date {isHeaderSorted('modifiedDate', property, descending)}</th>
          <th>Delivery Date {isHeaderSorted('deliveryDate', property, descending)}</th>
          <th>Store {isHeaderSorted('storeId', property, descending)}</th>
          <th>Category {isHeaderSorted('category', property, descending)}</th>
          <th>Items {isHeaderSorted('items', property, descending)}</th>
          <th>Value {isHeaderSorted('value', property, descending)}</th>
          <th>Cut-off {isHeaderSorted('lockAt', property, descending)}</th>
          <th>Status {isHeaderSorted('status', property, descending)}</th>
          <th />
          <th />
        </tr>
      </thead>
    );
  };

  const populateTableBody = (): JSX.Element => {
    return (
      <tbody>
        {
          orders.length > 0 ? orders.map((order: IPendingOrder, key: number) => {
            const confirmed = order.status.description === 'Confirmed';
            const lockAt = new Date(order.lockAt);
            const locked = lockAt < new Date();
            const lockAtDate = order.combinedOrder && confirmed || !order.combinedOrder ?
              order.lockAt : null;
            const deliveryDate = order.combinedOrder && confirmed || !order.combinedOrder ?
              order.deliveryDate : null;

            return <tr key={key}>
              <td>{order.orderNumber}</td>
              <td>{formatShortDateString(order.modifiedDate)}</td>
              <td>{formatShortDateString(deliveryDate)}</td>
              <td>{order.storeId}</td>
              <td>{mapCategory(order.categoryCode)}</td>
              <td>{order.items.length}</td>
              <td>{getTotalPrice(order.items)}</td>
              <td>{formatDateString(lockAtDate)}</td>
              <td>
                <div className={`status ${order.status.description}`}>
                  {order.status.description}
                </div>
              </td>
              <td>
                {
                  !locked &&
                  <button
                    className="Table-iconAction"
                    onClick={() => amendPendingOrder(order)}
                  >
                    <span className="icon-Edit" /><span className="underline">Amend order</span>
                  </button>
                }
              </td>
              <td>
                <button
                  className="Table-iconAction"
                  onClick={() => navigateTo(`/orders/copy?orderNumber=${order.orderNumber}`)}
                >
                  <span className="icon-copy" /><span className="underline">Copy order</span>
                </button>
              </td>
            </tr>;
          }) : null
        }
      </tbody>
    );
  };

  return (
    <>
      <div className="PendingOrdersList-filtersContainer">
        <PendingOrdersListFilters
          className={showFilters.className}
          categories={categories.data}
          orderNumber={orderNumber}
          onOrderNumberChanged={(orderNumber: string) => setOrderNumber(orderNumber)}
          storeId={storeId}
          onStoreIdChanged={(storeId: string) => setStoreId(storeId)}
          modifiedDate={modifiedDate}
          onModifiedDateChanged={(modifiedDate: string) => setModifiedDate(modifiedDate)}
          deliveryDate={deliveryDate}
          onDeliveryDateChanged={(deliveryDate: string) => setDeliveryDate(deliveryDate)}
          selectedCategory={categoryCode}
          onSelectedCategoryChanged={(category: string) => setCategoryCode(category)}
        />
      </div>
      <div className="PendingOrdersList-tableContainer">

        {userPendingOrders.loading ? <LoadingThrobber /> : null}
        <Table className="PendingOrdersList-table">
          {populateTableHeader()}
          {populateTableBody()}
        </Table>
      </div>
    </>
  );
};

const mapStateToProps = (state: IStoreState) => {
  return {
    userPendingOrders: state.userPendingOrders,
    categories: state.categories,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<any>) => {
  return {
    getUserPendingOrders: (params: IGetUserPendingOrdersParams) => dispatch(
      userPendingOrdersRequest(params),
    ),
    amendOrder: (parameters: IAmendOrderParams) => dispatch(amendOrderRequest(parameters)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PendingOrdersList);
