import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import './StockAllocation.scss';
import { IApiRequest, IStockAllocation, IStockAllocationItem, IStoreState } from '../../../types';
import { editStockAllocationRequest, getStockAllocationRequest, lockInOrderRequest }
  from '../../../actions/Orders/stockAllocationAction';
import LoadingThrobber from '../../../components/LoadingThrobber/LoadingThrobber';
import BootstrapTable from 'react-bootstrap-table-next';
import cellEditFactory, { Type } from 'react-bootstrap-table2-editor';
import { Button } from '@orderly/morrisons-component-library';
import { OrderStatuses } from '../../../components/OrderStatus/OrderStatus';
import { toastr } from 'react-redux-toastr';
import FormatDateHelper from '../../../helpers/Format/Date/FormatDateHelper';
import { FullPageLayout } from '../../../layouts';
import SearchBar from '../../../components/SearchBar';

interface IStockAllocationProps {
  stockAllocations: IApiRequest<IStockAllocation[]>;
  getStockAllocations: () => void;
  editStockAllocation: (order: IStockAllocation) => void;
  lockInOrder: (order: IStockAllocation) => void;
}

const StockAllocation = ({
  stockAllocations,
  getStockAllocations,
  editStockAllocation,
  lockInOrder,
}: IStockAllocationProps) => {
  const [orderItems, setOrderItems] = useState<IStockAllocation[]>([]);
  const [lastLockedInOrder, setLastLockedInOrder] = useState<string>('');
  const [filteredOrders, setFilteredOrders] = useState<IStockAllocation[]>([]);
  const [search, setSearch] = useState('');

  useEffect(() => {
    getStockAllocations();
  }, []);

  useEffect(() => {
    if (stockAllocations.UpdatedAt && lastLockedInOrder) {
      setLastLockedInOrder('');
      let updatedOrders = [...orderItems];
      updatedOrders = updatedOrders.filter(x => x.orderNumber !== lastLockedInOrder);
      setOrderItems(updatedOrders);
    }
  }, [stockAllocations.UpdatedAt]);

  useEffect(() => {
    if (stockAllocations.data !== null) {
      const updatedOrderItems = stockAllocations.data.map(order => ({
        key: `${order.orderNumber}`,
        ...order,
        orderDate: FormatDateHelper.formatDate(new Date(order.orderDate)),
        deliveryDate: FormatDateHelper.formatDate(new Date(order.deliveryDate)),
        items: order.items.map((item, index) => ({ ...item, index: index + 1 })),
      }));

      setOrderItems(updatedOrderItems);
      setFilteredOrders([...updatedOrderItems]);
    }
  }, [stockAllocations.data]);

  useEffect(() => {
    setFilteredOrders([...orderItems.filter(x => x.orderNumber.toLowerCase().includes(search.toLowerCase()))]);
  }, [search]);

  const isStockEditable = (column: string, row: IStockAllocationItem) => {
    const order = stockAllocations.data.find(x => x.id === row.orderId);

    if (!order) {
      return;
    }

    const { status } = order;

    if (
      (column === 'safetyAllocatedCases' && status.id !== OrderStatuses.CONFIRMED) ||
      ((column === 'allocatedCases' || column === 'tag2StoreNumber') && status.id !== OrderStatuses.SUBMITTED)
    ) {
      return false;
    }

    return true;
  };

  const canEditCell = (column: string, row: IStockAllocationItem, event: any) => {
    if (!isStockEditable(column, row)) {
      event.stopPropagation(); // do not trigger any edit cell events
    }
  };

  const editFormatter = (cell: any, row: any, title: string): JSX.Element => {
    return (
      <div
        onClick={e => canEditCell(title, row, e)}
        title={`Edit ${title}`}
        className={`edit-cell pointer ${cell ? 'populated' : ''} ${!isStockEditable(title, row) ? 'disabled' : ''}`}
      >
        <div className="edit-cell-value">
          {cell || !isStockEditable(title, row) ? cell : 'Edit'}
        </div>
        {
          isStockEditable(title, row) && cell ?
            <div className="icon icon-Edit ml-1" />
            :
            <></>
        }
      </div>
    );
  };

  const canUpdateOrder = (order: IStockAllocation) => {
    if (order.status.id === OrderStatuses.SUBMITTED) {
      // we need to make sure that all items have both 'safetyAllocatedCases'
      // and 'tag2StoreNumber' either both populated or both zero
      return order.updateId && order.items.every((item) => {
        return ((!item.allocatedCases && !item.tag2StoreNumber) ||
          (item.allocatedCases >= 0 && item.tag2StoreNumber > 0));
      });
    }
    return order.updateId;
  };

  const canLockInOrder = (order: IStockAllocation) => {
    if (order.status.id !== OrderStatuses.SUBMITTED) {
      return false;
    }

    return order.items.every((item) => {
      return item.allocatedCases && item.allocatedCases >= 0 && item.tag2StoreNumber > 0;
    });
  };

  const updateOrderInList = (updatedOrder: IStockAllocation, updatedItem: IStockAllocationItem, column: string): void => {
    const index = orderItems.findIndex(x => x.orderNumber === updatedOrder.orderNumber);
    if (index !== -1) {
      const updatedOrders = [...orderItems];
      const newOrder = recalculateTotals(updatedOrder);
      newOrder.updateId = `${updatedOrder.id}-${updatedItem.itemId}-${column}`;
      updatedOrders[index] = newOrder;
      setOrderItems(updatedOrders);
    }
  };

  const recalculateTotals = (order: IStockAllocation): IStockAllocation => {
    order.items.map((item: IStockAllocationItem) => {
      item.variance = calculateVariance(item, order.status.id);
    });
    return order;
  };

  const calculateVariance = (item: IStockAllocationItem, orderStatus: number): number => {
    if (orderStatus === OrderStatuses.CONFIRMED) {
      return item.safetyAllocatedCases - item.orderedCases;
    }
    return item.allocatedCases - item.orderedCases;
  };

  const isQuantityValid = (value: any, minValue: number, maxValue: number, errorMessage: string): boolean => {
    if (isNaN(parseInt(value, 10)) || value < minValue || value > maxValue) {
      toastr.error('Save Error', errorMessage, { timeOut: 10000 });
      return false;
    }
    return true;
  };

  const onSaveOrder = (order: IStockAllocation, lockIn: boolean) => {
    if (lockIn) {
      setLastLockedInOrder(order.orderNumber);
      lockInOrder(order);
    } else {
      setLastLockedInOrder('');
      editStockAllocation(order);
    }
    const index = orderItems.findIndex(x => x.orderNumber === order.orderNumber);
    const updatedOrders = [...orderItems];
    const newOrder = updatedOrders[index];
    newOrder.updateId = '';
    setOrderItems(updatedOrders);
  };

  const columns = [
    {
      dataField: 'poNumber',
      text: 'PO Number',
      editable: false,
    },
    {
      dataField: 'orderNumber',
      text: 'Order Number',
      editable: false,
      headerClasses: 'order-number',
    },
    {
      dataField: 'customerName',
      text: 'Customer Name',
      editable: false,
    },
    {
      dataField: 'storeNumber',
      text: 'Store Number',
      editable: false,
    },
    {
      dataField: 'orderDate',
      text: 'Order Date',
      editable: false,
    },
    {
      dataField: 'deliveryDate',
      text: 'Requested Delivery Date',
      editable: false,
    },
    {
      dataField: 'depotNumber',
      text: 'Depot No',
      editable: false,
    },
    {
      dataField: 'updateId',  // this is used to re-render the cell so the switches get updated along the state
      text: 'Actions',
      editable: false,
      formatter: (cell: any, row: IStockAllocation) => {
        return (
          <div className="actions-cell">
            <div title={!canUpdateOrder(row) ? 'No changes detected' : 'Save Order'}>
              <Button
                type="button"
                onClick={(): void => onSaveOrder(row, false)}
                disabled={!canUpdateOrder(row)}
                className="btn btn-info action-btn"
                text="Save"
              />
            </div>
            <div
              title={row.status.id !== OrderStatuses.SUBMITTED ?
                'Order needs to be confirmed by an account manager first.'
                :
                !canLockInOrder(row)
                  ?
                  'All items should have allocated cases'
                  :
                  'Lock in Order'}
            >
              <Button
                type="button"
                onClick={(): void => onSaveOrder(row, true)}
                disabled={!canLockInOrder(row)}
                className="btn btn-success action-btn"
                text="Lock In"
              />
            </div>
          </div>
        );
      },
    },
  ];

  const subColumns = [
    {
      dataField: 'index',
      text: '#',
      editable: false,
    },
    {
      dataField: 'morrisonsId',
      text: 'MIN',
      editable: false,
    },
    {
      dataField: 'itemId',
      text: 'PIN',
      editable: false,
    },
    {
      dataField: 'legacyCode',
      text: 'Prod',
      editable: false,
    },
    {
      dataField: 'nationalDepotNo',
      text: 'National Depot',
      editable: false,
    },
    {
      dataField: 'description',
      text: 'Item Description',
      editable: false,
      headerClasses: 'description',
    },
    {
      dataField: 'caseSize',
      text: 'Case Size',
      editable: false,
    },
    {
      dataField: 'casesPerPallet',
      text: 'Cases per pallet',
      editable: false,
    },
    {
      dataField: 'orderedUnits',
      text: 'Ordered (units)',
      editable: false,
    },
    {
      dataField: 'orderedCases',
      text: 'Ordered (cases)',
      editable: false,
    },
    {
      dataField: 'safetyAllocatedCases',
      text: 'Safety Stock (cases)',
      headerClasses: 'w-10',
      editable: true,
      errorMessage: 'Safety stock must be >= 0 and not exceed ordered quantity',
      minValue: 0,
      editor: {
        type: Type.Number,
      },
      editorStyle: { backgroundColor: 'lightcyan' },
      formatter: (cell: any, row: any) => {
        return editFormatter(cell, row, 'safetyAllocatedCases');
      },
    },
    {
      dataField: 'allocatedCases',
      text: 'Allocated (cases)',
      headerClasses: 'w-10',
      editable: true,
      errorMessage: 'Allocated cases must be >= 0 and not exceed ordered quantity',
      minValue: 0,
      editor: {
        type: Type.Number,
      },
      editorStyle: { backgroundColor: 'lightcyan' },
      formatter: (cell: any, row: any) => {
        return editFormatter(cell, row, 'allocatedCases');
      },
    },
    {
      dataField: 'variance',
      text: 'Variance',
      editable: false,
      classes: (cell: any) => cell < 0 ? 'error' : cell === 0 ? 'success' : 'warning',
    },
    {
      dataField: 'tag2StoreNumber',
      text: 'Tag 2 Store Number',
      headerClasses: 'w-10',
      editable: true,
      errorMessage: 'Tag 2 store number must be a positive number',
      minValue: 1,
      maxValue: 999,
      editor: {
        type: Type.Number,
      },
      editorStyle: { backgroundColor: 'lightcyan' },
      formatter: (cell: any, row: any) => {
        return editFormatter(cell, row, 'tag2StoreNumber');
      },
    },
  ];

  const expandRow: any = {
    renderer: (order: IStockAllocation) => (
      <BootstrapTable
        classes="stock-allocation-table items"
        keyField="itemId"
        columns={subColumns}
        data={order.items}
        hover
        cellEdit={cellEditFactory({
          mode: 'click',
          blurToSave: true,
          afterSaveCell: (oldValue: any, newValue: any, row: IStockAllocationItem, column: any) => {
            const { dataField, minValue, maxValue, errorMessage } = column;
            const maxValueToUse = maxValue || row.orderedCases;

            if (!isQuantityValid(newValue, minValue, maxValueToUse, errorMessage)) {
              switch (dataField) {
                case 'safetyAllocatedCases':
                  row.safetyAllocatedCases = oldValue;
                  break;
                case 'allocatedCases':
                  row.allocatedCases = oldValue;
                  break;
                default:
                  row.tag2StoreNumber = oldValue;
                  break;
              }
            } else {
              updateOrderInList(order, row, dataField);
            }
          },
        })}
        headerClasses="table-sub-header"
        noDataIndication="No items in the order"
      />
    ),
    showExpandColumn: true,
    expandByColumnOnly: true,
    expandHeaderColumnRenderer: () => {
      return '';
    },
    expandColumnRenderer: ({ expanded }: { expanded: any }) => {
      if (expanded) {
        return <div className="icon-arrow-down expand" />;
      }
      return <div className="icon-arrow-right expand right" />;
    },
  };

  return (
    <FullPageLayout
      heading="Stock Allocation Management"
      breadcrumbs={[
        {
          key: 0,
          text: 'Stock Allocation Management',
          url: '/admin/stock-allocation',
        },
      ]}
      homeRoute="/admin/home"
    >
      {!stockAllocations.data && stockAllocations.loading ? <LoadingThrobber /> : null}
      <div className="stock-allocation-container">
        <SearchBar
          label="Search Orders"
          value={search}
          onChange={value => setSearch(value)}
        />
        <BootstrapTable
          classes="stock-allocation-table"
          keyField="orderNumber"
          columns={columns}
          expandRow={expandRow}
          data={filteredOrders}
          noDataIndication="No orders to review at this time"
          cellEdit={cellEditFactory({
            mode: 'click',
            blurToSave: true,
          })}
          headerClasses="table-header"
        />
      </div>
    </FullPageLayout>
  );
};

const mapStateToProps = (state: IStoreState) => {
  return {
    stockAllocations: state.stockAllocations,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<any>) => ({
  getStockAllocations: () => dispatch(getStockAllocationRequest()),
  editStockAllocation: (order: IStockAllocation) => dispatch(editStockAllocationRequest(order)),
  lockInOrder: (order: IStockAllocation) => dispatch(lockInOrderRequest(order)),
});

export default connect(mapStateToProps, mapDispatchToProps)(StockAllocation);
