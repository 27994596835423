class MobileFiltersActionService {
  public isApplyFilters: boolean = false;

  public disableBodyScroll(): void {
    document.body.classList.add('MobileFiltersAction--noScroll');
  }

  public enableBodyScroll(): void {
    document.body.classList.remove('MobileFiltersAction--noScroll');
  }
}

const MobileFiltersActionHelper = new MobileFiltersActionService();

export default MobileFiltersActionHelper;
