import { signUp, ISignUpParameters } from '../api/signUpApi';
import { AxiosResponse } from 'axios';

export const SIGNUP_REQUEST = 'SIGNUP_REQUEST';
export type SIGNUP_REQUEST = typeof SIGNUP_REQUEST;
export const SIGNUP_SUCCESS = 'SIGNUP_SUCCESS';
export type SIGNUP_SUCCESS = typeof SIGNUP_SUCCESS;
export const SIGNUP_ERROR = 'SIGNUP_ERROR';
export type SIGNUP_ERROR = typeof SIGNUP_ERROR;

export const signUpRequest = (params: ISignUpParameters, onSuccess: () => void): any => {
  return (dispatch : any) => {
    dispatch({
      type: SIGNUP_REQUEST,
    });

    return signUp(params)
      .then((response: AxiosResponse<any>): void => {
        if (response.status === 200) {

          dispatch({
            type: SIGNUP_SUCCESS,
          });

          onSuccess();
        } else {
          onError(dispatch);
        }
      })
      .catch((err: any) => {
        console.log(err);
        onError(dispatch);
      });
  };
};

const onError = (dispatch: any) => dispatch({
  type: SIGNUP_ERROR,
});
