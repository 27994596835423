import { Reducer } from 'redux';
import { IApiRequest, IRebatesResponse } from '../../types';
import {
  GET_REBATES_ERROR,
  GET_REBATES_REQUEST,
  GET_REBATES_RESPONSE,
} from '../../actions/Rebates/rebatesAction';

const initialState: IApiRequest<IRebatesResponse> = {
  data: {
    rebates: [],
    metadata: null,
  },
  error: '',
  UpdatedAt: null,
  loading: false,
};

const rebatesReducer: Reducer<IApiRequest<IRebatesResponse>>
  = (state: IApiRequest<IRebatesResponse> = initialState, action: any) => {
    switch (action.type) {
      case GET_REBATES_RESPONSE: {
        return {
          data: action.response,
          error: '',
          UpdatedAt: new Date(),
          loading: false,
        };
      }
      case GET_REBATES_REQUEST: {
        return {
          data: {
            rebates: [],
            metadata: null,
          },
          error: '',
          UpdatedAt: null,
          loading: true,
        };
      }
      case GET_REBATES_ERROR:
        return {
          data: {
            rebates: [],
            metadata: null,
          },
          error: action.response,
          loading: false,
          UpdatedAt: null,
        };
      default: {
        return state;
      }
    }
  };

export default rebatesReducer;
