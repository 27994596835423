import {
  IDeleteStoreDocumentParams,
  IDeleteStoreDocumentResponse,
  deleteStoreDocument,
} from '../../api/StoreDocuments/deleteStoreDocumentAdminApi';
import { AxiosResponse } from 'axios';

export const DELETE_STORE_DOCUMENT_REQUEST = 'DELETE_STORE_DOCUMENT_REQUEST';
export const DELETE_STORE_DOCUMENT_RESPONSE = 'DELETE_STORE_DOCUMENT_RESPONSE';
export const DELETE_STORE_DOCUMENT_ERROR = 'DELETE_STORE_DOCUMENT_ERROR';

export const deleteStoreDocumentRequest = (parameters: IDeleteStoreDocumentParams,
  onSuccess: () => void,
  onFailure: () => void): any => {
  return (dispatch: any) => {
    dispatch({
      type: DELETE_STORE_DOCUMENT_REQUEST,
    });

    return deleteStoreDocument(parameters)
      .then((response: AxiosResponse<IDeleteStoreDocumentResponse>) => {
        if (response.status === 200) {
          dispatch({
            type: DELETE_STORE_DOCUMENT_RESPONSE,
            response: response.data.success,
          });
          onSuccess();
        } else {
          onError(dispatch);
          onFailure();
        }
      })
      .catch((err: any) => {
        console.log(err);
        onError(dispatch);
        onFailure();
      });
  };
};

const onError = (dispatch: any) => dispatch({
  type: DELETE_STORE_DOCUMENT_ERROR,
});
