import React, { Dispatch, useEffect, useState } from 'react';
import './ProductExclusions.scss';
import {
  Breadcrumbs,
  BreadcrumbSegment,
  Button,
  Card,
  Checkbox,
} from '@orderly/morrisons-component-library';
import { navigateTo } from '../../../routing/Navigation';
import Table from '../../../components/Table/Table';
import * as types from '../../../types';
import { connect } from 'react-redux';
import LoadingThrobber from '../../../components/LoadingThrobber/LoadingThrobber';
import { getRetailersRequest } from '../../../actions/retailersAction';
import { IProductExclusionsProps } from './IProductExclusionsProps';
import queryString from 'query-string';
import { getProductPageRequest } from '../../../actions/productPageAction';
import { IGetProductPageParams } from '../../../api/IGetProductPageParams';
import { getProductExclusionsRequest } from '../../../actions/productExclusionAction';
import InfiniteScroll from 'react-infinite-scroller';
import {
  IGetProductExclusionsParams,
  IUpdateProductExclusionsParams,
} from '../../../api/productsApi';
import { updateProductExclusionsRequest } from '../../../actions/updateProductExclusionsAction';
import Modal from '../../../components/Modal/Modal';
import { DebounceInput } from 'react-debounce-input';
import { Badge } from 'react-bootstrap';

const ProductExclusions = ({
  exclusions,
  products,
  retailers,
  getRetailers,
  getProducts,
  getProductExclusions,
  updateProductExclusions,
}: IProductExclusionsProps) => {
  const PAGE_SIZE = 100;
  const retailerQuery = queryString.parse(window.location.search).retailer;
  const retailerQueryId = parseInt(
    retailerQuery && retailerQuery.length > 0 ? retailerQuery.toString() : '1',
    10,
  );
  const [selectedRetailerId, setSelectedRetailerId] = useState<number>(retailerQueryId);
  const [exclusionsCount, setExclusionsCount] = useState<number>(0);
  const [productExclusions, setProductExclusions] = useState<types.IProductExclusion[]>([]);
  const [excludedProductIds, setExcludedProductIds] = useState<number[]>([]);
  const [showExcludedProducts, setShowExcludedProducts] = useState<boolean>(false);
  const [pagingIndex, setPagingIndex] = useState(0);
  const [search, setSearch] = useState<string>('');
  const [unsavedChanges, setUnsavedChanges] = useState<boolean>(false);
  useEffect(() => {
    getRetailers();
  }, []);

  useEffect(() => {
    getRetailerProductExclusions();
    setUnsavedChanges(false);
  }, [selectedRetailerId]);

  useEffect(() => {
    setProductExclusions(exclusions.data);
    setExclusionsCount(exclusions.data.length);
    setUnsavedChanges(false);
  }, [exclusions]);

  useEffect(() => {
    let newExcludedProductIds: number[] = [];
    if (productExclusions && productExclusions.length > 0) {
      newExcludedProductIds = productExclusions.map(x => x.productId);
    }
    setExcludedProductIds(newExcludedProductIds);
  }, [productExclusions]);

  useEffect(() => {
    if (pagingIndex !== 0) {
      setPagingIndex(0);
    } else if (!products.loading) {
      loadProducts();
    }
  }, [search]);

  useEffect(() => {
    if (selectedRetailerId && !products.loading) {
      loadProducts();
    }
  }, [pagingIndex]);

  const getExcludedProducts = (): types.IProduct[] => {
    if (productExclusions && productExclusions.length > 0) {
      const excludedProductIds = productExclusions.map(x => x.productId);
      const excludedProducts = products.data.data.filter(x =>
        excludedProductIds.includes(x.id),
      );
      return excludedProducts;
    }
    return [];
  };

  const retailerExcludedProducts = getExcludedProducts();

  const handleNavigation = (url?: string): ((url?: string) => void) => {
    return (): void => {
      navigateTo(url);
    };
  };

  const retailerClicked = (value: string, checked: boolean): void => {
    const parsedValue = parseInt(value, 10);
    if (checked && selectedRetailerId !== parsedValue) {
      setSelectedRetailerId(parsedValue);
    }
  };

  const productExcludeClicked = (value: string, checked: boolean): void => {
    const productId = parseInt(value, 10);
    const newExcludedProductIds = checked
      ? [...excludedProductIds, productId]
      : excludedProductIds.filter(id => id !== productId);
    setExcludedProductIds(newExcludedProductIds);
    const retailerProductIds = productExclusions.map(x => x.productId);
    const noNewChanges =
      newExcludedProductIds.length === retailerProductIds.length &&
      retailerProductIds.every(
        item => newExcludedProductIds.indexOf(item) > -1,
      );
    setUnsavedChanges(!noNewChanges);
  };

  const breadcrumbs: BreadcrumbSegment[] = [
    { key: 0, text: 'Product Exclusions', url: 'admin/product/exclusions' },
  ];

  const loadMore = () => setPagingIndex(pagingIndex + PAGE_SIZE);

  const updateProductExclusionsClicked = () => {
    const updateProductExclusionsParams: IUpdateProductExclusionsParams = {
      retailerId: selectedRetailerId,
      productIds: excludedProductIds,
    };
    updateProductExclusions(updateProductExclusionsParams, () => {
      getRetailerProductExclusions();
    });
  };

  const loadProducts = () => {
    const getProductsParams: IGetProductPageParams = {
      isAdmin: true,
      retailerId: selectedRetailerId,
      categoryId: -1,
      maximumCaseSize: null,
      minimumCaseSize: null,
      searchPhrase: search,
      orderBy: 'description',
      orderByDescending: false,
      pageSize: PAGE_SIZE,
      startIndex: pagingIndex,
      subCategories: [],
      tradingGroups: [],
      tradingPromotion: false,
      retailPromotion: false,
      shoppingListId: -1,
      currentTradingPack: false,
      tradingPackCategories: [],
      tradingPackLocations: [],
    };
    getProducts(getProductsParams);
  };

  const getRetailerProductExclusions = () => {
    const getProductExclusionsParams: IGetProductExclusionsParams = {
      retailerId: selectedRetailerId,
    };
    getProductExclusions(getProductExclusionsParams, () => {
      loadProducts();
    });
  };

  const onExcludedProductsModalClose = (): void => {
    setShowExcludedProducts(false);
  };

  const onExcludedProductsModalCancel = (): void => {
    setShowExcludedProducts(false);
  };

  return (
    <div className="admin-product-exclusions">
      <Breadcrumbs
        onHomeClick={handleNavigation('/admin/home')}
        onRedirect={handleNavigation(window.location.pathname)}
        segments={breadcrumbs}
      />
      {(products.loading || retailers.loading || exclusions.loading) && (
        <LoadingThrobber />
      )}
      <section className="heading-wrapper">
        <h1>Product Exclusions</h1>
      </section>
      <div className="info-and-save-changes-wrapper">
        <div className="info-wrapper">
          <Badge className="info-label">
            <strong>{products.data && (products.data.totalData || 0)}</strong>{' '}
            products
          </Badge>
          <Badge
            className="excluded-info-label"
            onClick={() => setShowExcludedProducts(true)}
          >
            <strong>{exclusionsCount}</strong> excluded products
          </Badge>
        </div>
        <div className="save-changes-wrapper">
          <Button
            type="button"
            className="save-product-exclusions"
            text="SAVE CHANGES"
            onClick={() => updateProductExclusionsClicked()}
            disabled={!unsavedChanges}
          >
            Save Changes
          </Button>
          {unsavedChanges && (
            <Badge className="unsaved-info-label">
              <span className="icon icon-zip-file" />
              Unsaved changes
            </Badge>
          )}
        </div>
      </div>
      <div className="content-wrapper">
        <div className="product-controls">
          <Card>
            <div className="product-search-wrapper">
              <div className="searchbar-wrapper">
                <DebounceInput
                  name="product-search"
                  debounceTimeout={500}
                  onKeyDown={(event: React.KeyboardEvent<HTMLInputElement>) => {
                    if (event.keyCode === 13) {
                      event.preventDefault();
                      setSearch(event.currentTarget.value);
                    }
                  }}
                  onChange={event => setSearch(event.target.value)}
                  type="text"
                  value={search}
                  className="product-search"
                  placeholder="Search products"
                />
                <span className="icon-search" />
              </div>
              <h4>Select a retailer</h4>
              {retailers.data.map((x: types.IRetailer) => (
                <Checkbox
                  checked={selectedRetailerId === x.id}
                  key={x.id}
                  label={x.externalIdentifier}
                  name={x.id.toString()}
                  onChange={retailerClicked}
                  isSmall={true}
                />
              ))}
            </div>
          </Card>
        </div>
        <div className="products-list">
          {!retailers.loading && (
            <InfiniteScroll
              pageStart={0}
              loadMore={() => loadMore()}
              hasMore={products.data.canLoadMore}
              initialLoad={false}
            >
              <Table className="manage-product-exclusions">
                <thead>
                  <tr>
                    <th className="Table--stickyColumn">Description</th>
                    <th>Category</th>
                    <th>Case Size</th>
                    <th className="Table--fixedColumn tableActions">
                      Excluded
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {products.data.data &&
                    products.data.data.length > 0 &&
                    products.data.data.map(product => (
                      <tr key={product.id}>
                        <td className="Table--stickyColumn">
                          {product.description}
                        </td>
                        <td>{product.category}</td>
                        <td>{product.caseSize}</td>
                        <td className="Table--actions">
                          <Checkbox
                            checked={excludedProductIds.includes(product.id)}
                            key={product.id}
                            label={''}
                            name={product.id.toString()}
                            onChange={productExcludeClicked}
                            isSmall={true}
                          />
                        </td>
                      </tr>
                    ))}
                </tbody>
              </Table>
            </InfiniteScroll>
          )}
        </div>
      </div>
      <Modal
        header="Excluded Products"
        isOpen={showExcludedProducts}
        buttonText="Ok"
        onClose={() => onExcludedProductsModalClose()}
        onCancel={() => onExcludedProductsModalCancel()}
      >
        <Table className="excluded-products-table">
          <thead>
            <tr>
              <th className="Table--stickyColumn">Description</th>
              <th>Category</th>
              <th>Case Size</th>
            </tr>
          </thead>
          <tbody>
            {retailerExcludedProducts &&
              retailerExcludedProducts.length > 0 &&
              retailerExcludedProducts.map(
                product =>
                  product &&
                  product.id && (
                    <tr key={product.id}>
                      <td className="Table--stickyColumn">
                        {product.description}
                      </td>
                      <td>{product.category}</td>
                      <td>{product.caseSize}</td>
                    </tr>
                  ),
              )}
          </tbody>
        </Table>
      </Modal>
    </div>
  );
};

const mapStateToProps = (state: types.IStoreState): any => {
  return {
    exclusions: state.exclusions,
    products: state.productPage,
    retailers: state.retailers,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<any>) => ({
  getProductExclusions: (
    params: IGetProductExclusionsParams,
    onExclusionsLoaded: () => void,
  ) => dispatch(getProductExclusionsRequest(params, onExclusionsLoaded)),
  getProducts: (parameters: IGetProductPageParams) =>
    dispatch(getProductPageRequest(parameters)),
  getRetailers: () => dispatch(getRetailersRequest()),
  updateProductExclusions: (
    parameters: IUpdateProductExclusionsParams,
    onSuccess: () => void,
  ) => dispatch(updateProductExclusionsRequest(parameters, onSuccess)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProductExclusions);
