import { useMutation, useQuery } from '@tanstack/react-query';
import React, {
  useState,
  useEffect,
  ChangeEvent,
  useMemo,
  useRef,
  useCallback,
} from 'react';
import { BannerLocations, IBanner, IImageSize, IStoreState } from '../../../types';
import { getBanner, ISaveBannerBody, saveBanner } from '../../../api/Banners/bannersApi';
import { FullPageLayout } from '../../../layouts';
import { connect } from 'react-redux';
import { getCustomerGroupsRequest } from '../../../actions/customerGroupsAction';
import { Dispatch } from 'redux';
import { EmptyRecord, LoadingThrobber } from '../../../components';
import { QUERY_KEYS } from '../../../constants';
import {
  Button,
} from '@orderly/morrisons-component-library';
import { arrayBufferToBase64 } from '../../../helpers/ArrayBufferHelper';
import { toastr } from 'react-redux-toastr';
import FormatDateHelper from '../../../helpers/Format/Date/FormatDateHelper';
import { navigateTo } from '../../../routing/Navigation';
import { IFormErrors, IFormState, IEditBannerProps } from './IEditBannerProps';
import { BannerDetail } from './components';
import styles from './EditBanner.module.scss';

function EditBanner({
  match,
  customerGroups,
  getCustomerGroups,
}: Readonly<IEditBannerProps>): JSX.Element {
  const bannerIdParam = match.params.id;
  const isCreating = bannerIdParam === 'create';
  const bannerId = isCreating ? null : Number(bannerIdParam);
  const parts = window.location.pathname.split('/');
  const newPath = `/${parts[1]}/home`;
  const listView = `/${parts[1]}/manage-banners`;
  const [isUserInteracted, setIsUserInteracted] = useState(false);
  const [formErrors, setFormErrors] = useState<IFormErrors>({});
  const [imagePreview, setImagePreview] = useState<string | null>(null);
  const [customerGroupSearch, setCustomerGroupSearch] = useState<string>('');
  const imageInputRef = useRef<HTMLInputElement>(null);
  const [formState, setFormState] = useState<IFormState>({
    title: '',
    description: '',
    startDate: FormatDateHelper.formatDate(new Date()),
    endDate: FormatDateHelper.formatDate(new Date()),
    allRetailers: true,
    order: 1,
    displayLocation: BannerLocations.Homepage,
    link: '',
    duration: 4,
    customerGroups: [],
    imageBase64: null,
  });

  useEffect(() => {
    getCustomerGroups();
  }, []);

  const { data: banner, error, isLoading } = useQuery<IBanner, Error>(
    [QUERY_KEYS.ADMIN_BANNER, bannerId],
    () => getBanner(bannerId),
    {
      enabled: !isCreating,
    },
  );

  useEffect(
    () => {
      if (!banner) {
        return;
      }

      setFormState({
        title: banner.title,
        description: banner.description,
        startDate: FormatDateHelper.formatDate(new Date(banner.startDate)),
        endDate: FormatDateHelper.formatDate(new Date(banner.endDate)),
        allRetailers: banner.allRetailers,
        order: banner.order,
        displayLocation: banner.displayLocation,
        link: banner.link,
        duration: banner.duration,
        customerGroups: banner.customerGroups,
        imageBase64: null,
      });

      setImagePreview(banner.imageUrl);
    },
    [banner],
  );

  const mutation = useMutation<IBanner, Error, ISaveBannerBody>(saveBanner, {
    onSettled: (data: IBanner) => {
      toastr.success(
        isCreating ? 'Banner Created' : 'Banner Updated',
        `${data.title} has been ${isCreating ? 'created' : 'updated'} successfully`,
      );
      navigateTo(listView);
    },
  });

  const handleInputChange = useCallback(
    (name: string, value: string | boolean | number) => {
      setIsUserInteracted(true);

      setFormState((prevState) => {
        let newValue: string | boolean | number = value;

        if (name === 'allRetailers' && typeof value === 'boolean') {
          return {
            ...prevState,
            allRetailers: value,
            customerGroups: value ? [] : prevState.customerGroups,
          };
        }

        if (typeof value === 'number') {
          newValue = value;
          if (isNaN(newValue)) {
            newValue = 0;
          }
        }

        return {
          ...prevState,
          [name]: newValue,
        };
      });
    },
    [],
  );

  const handleCustomerGroupSelection = useCallback(
    (group: string, checked: boolean) => {
      setIsUserInteracted(true);
      setFormState(prevState => ({
        ...prevState,
        customerGroups: checked
          ? [...prevState.customerGroups, group]
          : prevState.customerGroups.filter(g => g !== group),
      }));
    },
    [],
  );

  const handleCustomerGroupSearchChange = useCallback((value: string) => {
    setCustomerGroupSearch(value);
  }, []);

  const filteredCustomerGroups = useMemo(
    () => {
      if (customerGroupSearch.trim() === '') {
        return customerGroups.data || [];
      }

      return (customerGroups.data || []).filter((group: string) =>
        group.toLowerCase().includes(customerGroupSearch.toLowerCase()),
      );
    },
    [customerGroupSearch, customerGroups.data],
  );

  useEffect(
    () => {
      if (!isUserInteracted) {
        return;
      }

      validateForm();
    },
    [formState, isUserInteracted],
  );

  const dimennsions: IImageSize = useMemo(
    () => {
      switch (formState.displayLocation) {
        case BannerLocations.Homepage: {
          return {
            height: 280,
            width: 1240,
          };
        }
        default: {
          return {
            height: 0,
            width: 0,
          };
        }
      }
    },
    [formState.displayLocation],
  );

  const validateForm = (): boolean => {
    const errors: IFormErrors = {};
    const newStartDate = new Date(formState.startDate);
    const newEndDate = new Date(formState.endDate);
    let errorFound = false;

    if (!formState.title.trim()) {
      errors.title = 'Title is required';
      errorFound = true;
    }

    if (!formState.description.trim()) {
      errors.description = 'Description is required';
      errorFound = true;
    }

    if (newStartDate > newEndDate) {
      errors.startDate = 'Start date must be before the end date';
      errorFound = true;
    }

    if (formState.order <= 0) {
      errors.order = 'Order must be a positive number';
      errorFound = true;
    }

    if (formState.duration <= 0) {
      errors.duration = 'Duration must be a positive number';
      errorFound = true;
    }

    if (!formState.imageBase64 && isCreating) {
      errors.imageBase64 = 'Banner image is required';
      errorFound = true;
    }

    setFormErrors(errors);
    return !errorFound;
  };

  const handleImageChange = (e: ChangeEvent<HTMLInputElement>) => {
    setIsUserInteracted(true);

    if (e.target.files && e.target.files[0]) {
      const file = e.target.files[0];

      // Check if the file is a valid image type
      if (!file.type.startsWith('image/')) {
        toastr.error('Invalid File Type', 'Please upload a valid image file.');
        return;
      }

      const reader = new FileReader();
      reader.onloadend = () => {
        const arrayBuffer = reader.result as ArrayBuffer;

        const blob = new Blob([arrayBuffer], { type: file.type });
        const imageUrl = URL.createObjectURL(blob);

        if (formState.displayLocation === BannerLocations.Homepage) {
          const img = new Image();
          img.src = imageUrl;

          img.onload = () => {
            const { width, height } = img;

            if (width !== dimennsions.width || height !== dimennsions.height) {
              toastr.error(
                'Invalid Dimensions',
                `Please upload an image with ${dimennsions.width}x${dimennsions.height} dimensions.`,
              );
              URL.revokeObjectURL(imageUrl);
              return;
            }

            setFormState(prevState => ({
              ...prevState,
              imageBase64: arrayBufferToBase64(arrayBuffer),
            }));
            setImagePreview(imageUrl);
          };

          img.onerror = () => {
            toastr.error('Invalid Image', 'There was an error loading the image.');
            URL.revokeObjectURL(imageUrl);
          };
        }
      };
      reader.readAsArrayBuffer(file);
    }
  };

  const hasCustomersAssigned =
    formState.customerGroups.length > 0 ||
    formState.allRetailers;

  const handleSaveClick = () => {
    const isValid = validateForm();

    if (isValid && hasCustomersAssigned) {
      mutation.mutate({
        ...formState,
        id: bannerId || -1,
        data: formState.imageBase64,
      });
    }
  };

  const onUploadImage = () => {
    if (imageInputRef.current) {
      imageInputRef.current.click();
    }
  };

  return (
    <FullPageLayout
      heading={isCreating ? 'Create Banner' : 'Edit Banner'}
      breadcrumbs={[
        {
          key: 0,
          text: 'Manage Banners',
          url: '/admin/manage-banners',
        },
        {
          key: 1,
          text: isCreating ? 'Create' : 'Edit',
          url: window.location.href,
        },
      ]}
      homeRoute={newPath}
      headerAdditionalContent={
        <div className={styles.controls}>
          <input
            type="file"
            accept="image/*"
            ref={imageInputRef}
            style={{ display: 'none' }}
            onChange={handleImageChange}
            aria-label="Upload Image"
          />
          <Button
            type="button"
            className="tertiary"
            text="Upload Image"
            onClick={onUploadImage}
          />
          <Button
            type="button"
            className="primary"
            text={isCreating ? 'Create Banner' : 'Save Banner'}
            disabled={mutation.isLoading || !hasCustomersAssigned}
            onClick={handleSaveClick}
          />
        </div>}
    >
      <div className={styles.container}>
        {!isCreating && isLoading ? (
          <LoadingThrobber />
        ) : error ? (
          <EmptyRecord message={`An error has occurred: ${error.message}`} />
        ) : (
          <BannerDetail
            formState={formState}
            formErrors={formErrors}
            handleInputChange={handleInputChange}
            handleCustomerGroupSelection={handleCustomerGroupSelection}
            handleCustomerGroupSearchChange={handleCustomerGroupSearchChange}
            imagePreview={imagePreview}
            customerGroupSearch={customerGroupSearch}
            filteredCustomerGroups={filteredCustomerGroups}
          />
        )}
      </div>
    </FullPageLayout>
  );
}

const mapStateToProps = (state: IStoreState) => ({
  customerGroups: state.customerGroups,
});

const mapDispatchToProps = (dispatch: Dispatch<any>) => ({
  getCustomerGroups: () => dispatch(getCustomerGroupsRequest()),
});

export default connect(mapStateToProps, mapDispatchToProps)(EditBanner);
