import { DropdownOption } from '@orderly/morrisons-component-library';
import { IOrder } from '../../../pages/Orders/IOrder';
import { ISortOrdersSelection } from './interfaces/ISortOrdersSelection';
import { sortOrdersSelection } from './mappers/SortOrdersSelection';
import { sortOrdersFieldMapper } from './mappers/SortOrdersFieldMapper';

export class OrdersHelper {
  public sortOnChange(e: DropdownOption, orders: IOrder[]): IOrder[] {
    const value: number = e.value as number;
    const field: string = e.label.split(':')[0];
    const selection: ISortOrdersSelection = sortOrdersSelection(field, value);

    if (!!selection.field && !!selection.value) {
      const sorted: IOrder[] = orders.sort((a: IOrder, b:IOrder): any => {

        if (selection.value === 'Ascending') {
          return (a[selection.field] as string).localeCompare(b[selection.field] as string);
        }

        if (selection.value === 'Descending') {
          return (b[selection.field] as string).localeCompare(a[selection.field] as string);
        }

        return 0;
      });
      return sorted;
    }

    return orders;
  }

  public getSortOptions(): DropdownOption[] {
    return [
            { label: 'DELIVERY DATE: Ascending', value: 1 },
            { label: 'DELIVERY DATE: Descending', value: 2 },
            { label: 'ORDER DATE: Ascending', value: 3 },
            { label: 'ORDER DATE: Descending', value: 4 },
            { label: 'ORDER ID: low to high', value: 5 },
            { label: 'ORDER ID: high to low', value: 6 },
            { label: 'STATUS: A-Z', value: 7 },
            { label: 'STATUS: Z-A', value: 8 },
    ];
  }

  public getSortedColumnName(mapValue: string): string {
    return sortOrdersFieldMapper[mapValue];
  }
}
