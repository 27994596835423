import { IOrder } from '../../pages/Orders/IOrder';
import { IOrderDetails } from '../../types';
import { IStatusMapping } from '../../components/OrderStatus/IStatusMapping';
import { mapCategory } from '../Category/mapCategory';

const orderStatusTextMapping: IStatusMapping = {
  raised: 'Ordered',
  confirmed: 'Confirmed',
  picked: 'Confirmed',
  shipped: 'Shipped',
  accounted: 'Shipped',
  invoiced: 'Shipped',
  'confirmed-partial': 'Ordered',
  'picked-partial': 'Ordered',
  'shipped-partial': 'Confirmed',
  'accounted-partial': 'Shipped',
  'invoiced-partial': 'Shipped',
};

export const mapOrderStatus: (orderStatus: string) => string = (orderStatus: string): string => {
  return orderStatusTextMapping[orderStatus];
};

export const mapOrders: (result: IOrderDetails[]) => IOrder[] = (result: IOrderDetails[]): IOrder[] => {
  const mappedOrders: IOrder[] = result.map((order: IOrderDetails): IOrder => {
    const {
      orderId,
      orderRaisedDate,
      shipToDeliverAt,
      orderReferenceCode,
      itemsOrderedSumTotal,
      statusCurrent,
      shipToLocationId,
      customerPONumber,
      deliveryNetwork,
      orderIdentifier,
    } = order;

    return {
      orderIdentifier,
      deliveryNetwork,
      shipToLocationId,
      customerPONumber,
      id: orderId,
      orderDate: orderRaisedDate,
      deliveryDate: shipToDeliverAt,
      category: mapCategory(orderReferenceCode),
      itemsQuantity: itemsOrderedSumTotal,
      status: mapOrderStatus(statusCurrent) || statusCurrent,
      items: [],
      users: [],
    };
  });

  return mappedOrders;
};
