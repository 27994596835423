import { Reducer } from 'redux';
import { IApiRequest, IFolder } from '../../types/index';
import { ENSURE_PATH_RESPONSE, ENSURE_PATH_REQUEST, ENSURE_PATH_ERROR } from '../../actions/Documents/ensurePathAction';

const initialState: IApiRequest<IFolder> = {
  data: undefined,
  error: '',
  UpdatedAt: null,
  loading: false,
};

const ensurePathReducer: Reducer<IApiRequest<IFolder>> = (state: IApiRequest<IFolder> = initialState, action : any) => {
  switch (action.type) {
    case ENSURE_PATH_RESPONSE: {
      return {
        data: action.response,
        error: '',
        UpdatedAt: new Date(),
        loading: false,
      };
    }
    case ENSURE_PATH_REQUEST: {
      return {
        data: undefined,
        error: '',
        UpdatedAt: null,
        loading: true,
      };
    }
    case ENSURE_PATH_ERROR: {
      return {
        ...initialState,
        error: action.error,
      };
    }
    default: {
      return state;
    }
  }
};

export default ensurePathReducer;
