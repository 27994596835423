import { IProduct, IProductDetail, IBasketItem } from '../../types/index';
import { formatCurrency } from '../Format';

export enum ViewMode {
  Price = 'Price',
  RRP = 'RRP',
}

export enum PriceType {
  UnitPrice = 'UnitPrice',
  CasePrice = 'CasePrice',
}

export enum CurrencyType {
  None = 'None',
  Pound = 'pound',
  Pence = 'pence',
}

export enum PriceSelectionType {
  NormalPrice = 'NormalPrice',
  NonSalePrice = 'NonSalePrice',
}

const calculatePrice = (
  item: IBasketItem): number => {
  const { singlePick, unitPrice, casePrice } = item;
  const price: number = singlePick ? unitPrice : casePrice;
  return price;
};

export const calculateTotalPrice = (items: IBasketItem[]) => {
  return items.reduce(
    (total: number, item : IBasketItem) =>
      total + calculatePrice(item) * item.quantity,
    0);
};

export const getPriceExlusiveOfVAT = (price: number, vatRate: number) => {
  const vatRateCalc = vatRate + 100;
  return (price / vatRateCalc) * 100;
};

export const getPriceInclusiveOfVAT = (price: number, vatRate: number) => {
  const vatRateCalc = vatRate + 100;
  return (price * vatRateCalc) / 100;
};

export const calculatePricePerItem = (product: IProduct | IProductDetail): number => {
  const {
    singlePick,
    caseSize,
  } = product;

  const actualCaseSize = singlePick ? 1 : caseSize;
  const salePrice = calculatePriceByType(
    product,
    singlePick ? PriceType.UnitPrice : PriceType.CasePrice,
    PriceSelectionType.NormalPrice,
    1,
  );

  const accurateUnitPrice = salePrice / actualCaseSize;
  return accurateUnitPrice;
};

export const calculatePOR = (product: IProduct | IProductDetail) => {
  const {
    vatRate,
    recommendedRetailPrice,
  } = product;

  const accurateUnitPrice = calculatePricePerItem(product);
  if (recommendedRetailPrice <= 0) {
    return 0;
  }

  let retailPriceWithoutVat = recommendedRetailPrice;

  if (vatRate > 0) {
    retailPriceWithoutVat = getPriceExlusiveOfVAT(recommendedRetailPrice, vatRate);
  }

  const netSalePrice = retailPriceWithoutVat - accurateUnitPrice;
  const porValue = (netSalePrice / retailPriceWithoutVat) * 100;
  return porValue > 0 ? porValue : 0;
};

export const getPriceByTypeBasket = (
  product: IBasketItem,
  quantity: number = 1): string => {
  const price = calculatePrice(product) * quantity;
  return formatCurrency(price);
};

export const getCostIncVATBasket = (
  product: IBasketItem,
  quantity: number = 1): string => {
  const price = calculatePrice(product) * quantity;

  const priceIncVAT = getPriceInclusiveOfVAT(price, product.vatRate);
  return formatCurrency(priceIncVAT);
};

export const getPriceByType = (
  product: IProduct | IProductDetail,
  priceType: PriceType,
  priceSelectionType: PriceSelectionType,
  quantity: number = 1): string => {

  const price = calculatePriceByType(
    product,
    priceType,
    priceSelectionType,
    quantity);

  return formatCurrency(price);
};

const calculatePriceByType = (
  product: IProduct | IProductDetail,
  priceType: PriceType,
  priceSelectionType: PriceSelectionType,
  quantity: number = 1): number => {
  const {
    unitPrice,
    casePrice,
    standardUnitPrice,
    standardCasePrice,
  } = product;

  if (priceType === PriceType.UnitPrice) {
    switch (priceSelectionType) {
      case PriceSelectionType.NormalPrice:
        return unitPrice * quantity;
      case PriceSelectionType.NonSalePrice:
        return standardUnitPrice * quantity;
      default:
        return 0;
    }
  }  if (priceType === PriceType.CasePrice) {
    switch (priceSelectionType) {
      case PriceSelectionType.NormalPrice:
        return casePrice * quantity;
      case PriceSelectionType.NonSalePrice:
        return standardCasePrice * quantity;
      default:
        return 0;
    }
  }
  return 0;
};

export const getTotalPrice = (items: IBasketItem[]): string => {
  const total = calculateTotalPrice(items);
  return formatCurrency(total);
};

export const calculatePriceIncVat = (
  itemPrice: number,
  quantity: number,
  vatRate: number): number => {

  const total = itemPrice * quantity;
  const vat = (total / 100) * vatRate;
  const totalPlusVat = total + vat;

  return totalPlusVat;
};
