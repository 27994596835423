import React, { useEffect, useState } from 'react';
import { ISearchBarProps } from './ISearchBarProps';
import styles from './SearchBar.module.scss';
import { TextInput } from '@orderly/morrisons-component-library';
import useDebounce from '../../helpers/Hooks/useDebounce';

function SearchBar({
  value,
  label,
  onChange,
}: ISearchBarProps): JSX.Element {
  const [searchValue, setSearchValue] = useState('');
  const debouncedSearch = useDebounce(searchValue, 1000);

  useEffect(
    () => {
      onChange(debouncedSearch);
    },
    [debouncedSearch],
  );

  return (
    <div className={styles.container}>
      <TextInput
        type="text"
        value={value}
        label={label}
        name={label.replace(' ', '')}
        onChange={setSearchValue}
      />
      <span className={`${styles.icon} icon-search`} />
    </div>
  );
}

export default SearchBar;
