import { ICreateOrder } from '../../types';

const initialState: ICreateOrder = {
  loading: false,
  error: '',
};

const createOrderReducer = (state: any = initialState, action: any) => {
  switch (action.type) {
    case 'POST_ORDER_REQUEST': {
      return {
        loading: true,
        error: '',
      };
    }
    case 'POST_ORDER_RESPONSE': {
      return {
        loading: false,
        error: '',
      };
    }
    case 'POST_ORDER_ERROR': {
      return {
        loading: false,
        error: 'An error has occured',
      };
    }
    default: {
      return state;
    }
  }
};

export default createOrderReducer;
