import React, { Dispatch, useEffect, useState, FormEvent } from 'react';
import './MyAccount.scss';
import { Button, TextArea, Dropdown } from '@orderly/morrisons-component-library';
import { connect } from 'react-redux';
import { IStoreState } from '../../types';
import { navigateTo } from '../../routing/Navigation';
import { IMyAccountProps } from './IMyAccountProps';
import { getMyAccountRequest } from '../../actions/MyAccount/myAccountAction';
import { postContactFormRequest } from '../../actions/MyAccount/contactFormAction';
import { IPostContactFormParams } from '../../api/MyAccount/contactFormApi';
import { postResetPasswordRequest } from '../../actions/MyAccount/resetPasswordAction';
import OrderOpportunities from '../../components/OrderOpportunities/OrderOpportunities';
import { FullPageLayout } from '../../layouts';
import retailerDetailsAction from '../../actions/retailerDetailAction';

const MyAccount = ({
  accountDetails,
  retailerDetails,
  contactForm,
  resetPassword,
  getMyAccount,
  getRetailerDetails,
  postContactForm,
  postResetPassword,
}: IMyAccountProps) => {

  const [subject, setSubject] = useState('');
  const [message, setMessage] = useState('');
  const [subjectError, setSubjectError] = useState('');
  const [messageError, setMessageError] = useState('');
  const contactOptions = [
    { label: 'Order Query', value: 'Order Query' },
    { label: 'Product/Catalogue Query', value: 'Product/Catalogue Query' },
    { label: 'Website Query', value: 'Website Query' },
    { label: 'Order Opportunities Query', value: 'Order Opportunities Query' },
    { label: 'Account Query', value: 'Account Query' },
    { label: 'Other', value: 'Other' },
  ];

  const isMessageValid = (input: string) => {
    setMessage(input);
    setMessageValidationMessage(input.trim().length > 0);
  };

  const setMessageValidationMessage = (isValid: boolean) => {
    if (!isValid) {
      setMessageError('This is a required field');
    } else {
      setMessageError('');
    }
  };

  const setSubjectValidationMessage = (isValid: boolean) => {
    if (!isValid) {
      setSubjectError('This is a required field');
    } else {
      setSubjectError('');
    }
  };

  const isValid = (message: string, subject: any) => {
    const messageValid = message.trim().length > 0;
    const subjectValid = subject !== null && subject.trim().length > 0;

    setMessageValidationMessage(messageValid);
    setSubjectValidationMessage(subjectValid);

    if (!messageValid || !subjectValid) {
      return false;
    }

    return true;
  };

  const submitContactForm = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (!isValid(message, subject)) {
      return;
    }

    if (retailerDetails) {
      postContactForm({
        subject,
        message,
        retailer: retailerDetails.externalIdentifier,
      }).then(
        (): void => {
          resetForm();
        },
        (e: any): void => {
          console.log('An error has occurred', e);
        },
      );
    }
  };

  const resetForm = () => {
    setSubject('');
    setSubjectError('');
    setMessage('');
    setMessageError('');
  };

  useEffect(
    (): void => {
      getRetailerDetails();
      getMyAccount();
    },
    [],
  );

  return (
    <FullPageLayout
      heading="My Account"
      breadcrumbs={[
        {
          key: 0,
          text: 'My Account',
          url: '/my-account',
        },
      ]}
    >
      <div className="my-account-container">
        <div className="my-account-column-details">
          <section className="my-account-information card">
            <div>
              <h4>Personal Details</h4>
            </div>
            {
              accountDetails.data &&
              accountDetails.data.userDetails &&
              <>
                <div>
                  <span className="icon icon-user" />
                  <span>
                    {accountDetails.data.userDetails.firstName} {accountDetails.data.userDetails.lastName}
                  </span>
                </div>

                <div className="info-row">
                  <span className="icon icon-envelop" />
                  <span>{accountDetails.data.userDetails.emailAddress}</span>
                </div>
              </>
            }
            <div className="spacer">
              <h4>My Orders</h4>
            </div>
            <div>
              <p>
                View past and pending orders.
              </p>
            </div>
            <div>
              <Button
                type="button"
                className="link full-width"
                text="My Orders"
                onClick={() => navigateTo('/orders')}
              />
            </div>
            <div className="spacer">
              <h4>Change Password</h4>
            </div>
            <div>
              <p>
                Click below to change your password.
                We will send you an email with instructions to change your password.
              </p>
            </div>
            <div>
              <Button
                type="button"
                className="tertiary full-width"
                text={resetPassword.loading ? 'Sending' : 'Change Password'}
                onClick={() => postResetPassword()}
                disabled={resetPassword.loading}
              />
            </div>
            <div className="spacer">
              <h4>My Documents</h4>
            </div>
            <div>
              <p>
                Click below to view your shared {retailerDetails && retailerDetails.externalIdentifier} documents.
              </p>
            </div>
            <div>
              <Button
                type="button"
                className="link full-width"
                text="My Documents"
                onClick={() => navigateTo('/documents')}
              />
            </div>
          </section>
          <section className="my-account-contact card">
            <div>
              <h4>Contact Us</h4>
            </div>
            <div>
              <p>Please use the form below to get in touch with us for any queries.</p>
            </div>
            <div>
              <form onSubmit={submitContactForm}>
                <Dropdown
                  label="Subject"
                  name="subject"
                  selectedValue={subject}
                  options={contactOptions}
                  onChange={(val: any) => setSubject(val.value)}
                  className="contact-subject"
                  error={subjectError}
                  placeholder="Select a Subject"
                />
                <TextArea
                  className="contact-message"
                  label="Message"
                  name="contactmessage"
                  value={message}
                  validationError={messageError}
                  onChange={(val: string) => isMessageValid(val)}
                />
                <div className="contact-submit">
                  <Button
                    disabled={contactForm.loading}
                    type="submit"
                    className="secondary full-width"
                    text={contactForm.loading ? 'Sending' : 'Get in touch!'}
                  />
                </div>
                <div className="contact-reset">
                  <Button
                    type="button"
                    className="button link full-width"
                    text="Reset Form"
                    onClick={() => resetForm()}
                  />
                </div>
              </form>
            </div>
          </section>
        </div>
        <div className="my-account-column-opportunities">
          <section className="my-account-opportunities card">
            <div className="my-account-opportunities-heading">
              <h4>Order Opportunities</h4>
              <p>Here you can view your future order opportunities for Wholesale deliveries</p>
            </div>
            <OrderOpportunities />
          </section>
        </div>
      </div>
    </FullPageLayout>

  );
};

const mapStateToProps = (state: IStoreState) => {
  return {
    retailerDetails: state.retailerDetails.data,
    accountDetails: state.accountDetails,
    contactForm: state.contactForm,
    resetPassword: state.resetPassword,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<any>) => {
  return {
    getRetailerDetails: () => dispatch(retailerDetailsAction()),
    getMyAccount: () => dispatch(getMyAccountRequest()),
    postContactForm: (parameters: IPostContactFormParams) => dispatch(postContactFormRequest(parameters)),
    postResetPassword: () => dispatch(postResetPasswordRequest()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MyAccount);
