import React from 'react';
import { FullPageLayout } from '../../layouts';
import { navigateTo } from '../../routing/Navigation';
import BarcodeScanner from '../../components/BarcodeScanner/BarcodeScanner';

function NewBarcodeScannerPage(): JSX.Element {

  const onCompleteSearch = (searchPhrase: string) => {
    navigateTo(`/search?q=${searchPhrase}`);
  };

  return (
    <FullPageLayout
      heading="Barcode Scanner"
      breadcrumbs={[]}
    >
    <BarcodeScanner onScan={(result: string) => onCompleteSearch(result)} />
    </FullPageLayout>
  );
}

export default NewBarcodeScannerPage;
