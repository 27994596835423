import { IBasket, IApiRequest } from '../../types';

const initialState: IApiRequest<IBasket> = {
  data: null,
  loading: true,
  error: '',
  UpdatedAt: '',
};

const basketReducer = (state: any = initialState, action: any): IApiRequest<IBasket> => {
  switch (action.type) {
    case 'GET_BASKET_REQUEST':
    case 'UPDATE_BASKET_REQUEST': {
      return {
        ...state,
        loading: true,
      };
    }
    case 'SET_CURRENT_BASKET':
    case 'GET_BASKET_RESPONSE':
    case 'UPDATE_BASKET_RESPONSE':
    case 'UPLOAD_ORDER_CSV_RESPONSE':
    case 'UPDATE_ORDER_PO_NUMBER_RESPONSE': {
      return {
        data: action.response,
        loading: false,
        error: null,
        UpdatedAt: '',
      };
    }
    case 'GET_BASKET_ERROR': {
      return {
        data: null,
        loading: false,
        error: 'An error has occured',
        UpdatedAt: '',
      };
    }
    case 'CLEAR_BASKET_CONTENTS': {
      return initialState;
    }
    default: {
      return state;
    }
  }
};

export default basketReducer;
