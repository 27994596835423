import { refreshToken } from '../actions/userLoginAction/userLoginAction';
import { USER_DETAILS } from '../actions/userDetailsAction';
import { USER_LOGOUT_REQUEST } from '../actions/Logout/userLogoutAction';

let refresh: NodeJS.Timeout = undefined;

const refreshTokenMiddleware = (store: any) => (next: any) => (action: any) => {
  switch (action.type) {
    case USER_DETAILS: {
      const expiry = new Date(action.payload.expiration);
      const now = new Date();

      const tokenLife = expiry.getTime() - now.getTime();

      refresh = setTimeout(
        () => {
          store.dispatch(refreshToken());
        },
        tokenLife * 0.75,
      );

      return next(action);
    }
    case 'USER_IMPERSONATE_REQUEST':
    case USER_LOGOUT_REQUEST: {
      clearTimeout(refresh);
      return next(action);
    }
    default: {
      return next(action);
    }
  }
};

export default refreshTokenMiddleware;
