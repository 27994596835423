import Fetch from '../apiConfig/apiConfig';
import { AxiosPromise } from 'axios';
import { IFolder } from '../../types';

export interface IDeleteDocumentParams {
  documentId: number;
}

export interface IDeleteDocumentResponse {
  document: IFolder;
}

export const deleteDocument = (params: IDeleteDocumentParams) : AxiosPromise<IDeleteDocumentResponse> => {
  return Fetch().then(axios => axios.post('documents/delete', params));
};
