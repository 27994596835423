import { Reducer } from 'redux';
import { IApiRequest, IPendingOrder } from '../../types';
import {
  GET_ORDERS_PENDING_REVIEW_REQUEST,
  GET_ORDERS_PENDING_REVIEW_RESPONSE,
  GET_ORDERS_PENDING_REVIEW_ERROR,
} from '../../actions/Orders/orderReviewAction';

const initialState: IApiRequest<IPendingOrder[]> = {
  loading: false,
  UpdatedAt: null,
  data: null,
  error: '',
};

const adminOrdersReducer: Reducer<IApiRequest<IPendingOrder[]>> = (
  state: IApiRequest<IPendingOrder[]> = initialState,
  action: any,
) => {
  switch (action.type) {
    case GET_ORDERS_PENDING_REVIEW_REQUEST: {
      return {
        ...initialState,
        loading: true,
      };
    }
    case GET_ORDERS_PENDING_REVIEW_RESPONSE: {
      return {
        ...initialState,
        data: action.response.getOrdersPendingReview,
      };
    }
    case GET_ORDERS_PENDING_REVIEW_ERROR: {
      return {
        ...initialState,
        error: '',
      };
    }
    default: {
      return state;
    }
  }
};

export default adminOrdersReducer;
