import { getOrderDetails } from '../../api/Orders/orderSearchApi';
import { IOrderSearchResponse } from '../../api/Orders/IOrderSearchResponse';
import { AxiosResponse } from 'axios';

export const orderSearchRequest = (searchPhrase: string, retailerName: string): any => {

  return (dispatch : any) => {
    dispatch({
      type: 'GET_ORDER_SEARCH_REQUEST',
    });

    return getOrderDetails(searchPhrase, retailerName)
      .then((response: AxiosResponse<IOrderSearchResponse>) => {
        if (response.status === 200) {
          if (response.data.order.orders.length === 1) {
            dispatch({
              type: 'GET_ORDER_SEARCH_RESPONSE',
              response: response.data.order,
            });
          } else {
            onError(dispatch);
          }
        } else {
          onError(dispatch, response.data as unknown as string);
        }
      })
      .catch((err: any) => {
        console.log(err);
        onError(dispatch);
      });
  };
};

const onError = (dispatch: any, message: string = null) => dispatch({
  type: 'GET_ORDER_SEARCH_ERROR',
  response: message,
});
