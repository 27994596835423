import React, { Dispatch, useEffect, useState } from 'react';
import './ManageShoppingLists.scss';
import { Button, Card, Checkbox, Dropdown, TextInput } from '@orderly/morrisons-component-library';
import BootstrapTable from 'react-bootstrap-table-next';
import { toastr } from 'react-redux-toastr';
import {
  deleteShoppingListsRequest,
  getAdminShoppingListsRequest,
} from '../../../../actions/ShoppingLists/shoppingListsActions';
import { IDeleteShoppingListsBody } from '../../../../api/ShoppingLists/shoppingListsApi';
import LoadingThrobber from '../../../../components/LoadingThrobber/LoadingThrobber';
import { navigateTo } from '../../../../routing/Navigation';
import { IRetailer, IStoreState } from '../../../../types';
import { IManageShoppingListsProps } from './IManageShoppingListsProps';
import { connect } from 'react-redux';
import Modal from '../../../../components/Modal/Modal';
import { getRetailersRequest } from '../../../../actions/retailersAction';
import { FullPageLayout } from '../../../../layouts';

interface IShoppingListRow {
  id: number;
  retailerId: number;
  description: string;
  createdAt: Date;
  retailer: string;
  customerGroup: string;
}

const ManageShoppingLists = (
  {
    shoppingLists,
    retailers,
    loading,
    getShoppingLists,
    getRetailers,
    deleteShoppingLists,
  }: IManageShoppingListsProps) => {
  const [shoppingListsTable, setShoppingListsTable] = useState<IShoppingListRow[]>([]);
  const [search, setSearch] = useState('');
  const [tableSearch, setTableSearch] = useState('');
  const [orderBy, setOrderBy] = useState('description');
  const [orderDescending, setOrderDescending] = useState(false);
  const [showDeleteListModal, setShowDeleteListModal] = useState(false);
  const [selectedShoppingLists, setSelectedShoppingLists] = useState<IShoppingListRow[]>([]);
  const [customerGroups, setCustomerGroups] = useState<string[]>([]);
  const [selectedRetailers, setSelectedRetailers] = useState([]);
  const [selectedCustomerGroups, setSelectedCustomerGroups] = useState([]);

  useEffect(() => {
    getShoppingLists();

    if (retailers.data.length <= 0 && !retailers.loading) {
      getRetailers();
    }
  }, []);

  useEffect(() => {
    if (shoppingLists.data && retailers.data.length > 0) {
      const tableData = constructShoppingListsTableData();
      setCustomerGroups([...new Set(tableData.filter(x => x.customerGroup).map(x => x.customerGroup))]);
      setShoppingListsTable(tableData);
    }
  }, [shoppingLists, retailers]);

  useEffect(() => {
    if (shoppingLists.data && retailers.data.length > 0) {

      const tableData = constructShoppingListsTableData();

      if (selectedRetailers.length === 0) {
        setShoppingListsTable(tableData);
      } else {
        let filteredTableData = tableData.filter(x => selectedRetailers.includes(x.retailerId));

        if (selectedCustomerGroups.length > 0) {
          filteredTableData.forEach((row) => {
            const multipleRetailersHaveSameList = filteredTableData
              .filter(x => x.description === row.description &&
                x.customerGroup === row.customerGroup &&
                selectedCustomerGroups.includes(x.customerGroup)).length > 1;
            row.retailer = multipleRetailersHaveSameList ? '-' : row.retailer;
          });
        }

        const ids = filteredTableData.map(({ description }) => description);
        // remove duplicates
        filteredTableData = filteredTableData.filter(({ description }, index) => !ids.includes(description, index + 1));
        setShoppingListsTable(filteredTableData);
      }
    }
  }, [selectedRetailers]);

  const columns = [
    {
      dataField: 'description',
      text: 'Description',
      editable: false,
    },
    {
      dataField: 'retailer',
      text: 'Retailer',
      editable: false,
    },
    {
      dataField: 'customerGroup',
      text: 'Customer Group',
      editable: false,
    },
    {
      dataField: 'createdAt',
      text: 'Creation Date',
      editable: false,
    },
    {
      dataField: 'actions',
      text: 'Actions',
      editable: false,
      formatter: (cell: any, row: IShoppingListRow) => {
        return shoppingListsActionsCellRenderer(row);
      },
    },
  ];

  const constructShoppingListsTableData = () => {
    return shoppingLists.data.map((x) => {
      const retailer = retailers.data.find(r => r.id === x.retailerId);
      const row: IShoppingListRow = {
        id: x.id,
        createdAt: x.createdAt,
        description: x.description,
        retailerId: x.retailerId,
        retailer: retailer ? retailer.externalIdentifier : '',
        customerGroup: retailer ? retailer.meta.CustomerGroup || '' : '',
      };
      return row;
    });
  };

  const onActionBtnClick = (actionType: string, shoppingList: IShoppingListRow) => {
    if (selectedRetailers.length > 0) {
      const tableData = constructShoppingListsTableData();
      const listsToDelete = tableData
        .filter(x => x.description === shoppingList.description && selectedRetailers.includes(x.retailerId));
      setSelectedShoppingLists(listsToDelete);
    } else {
      setSelectedShoppingLists([shoppingList]);
    }

    switch (actionType) {
      case 'deleteShoppingList':
        setShowDeleteListModal(!showDeleteListModal);
    }
  };

  const onDelete = () => {
    if (selectedShoppingLists.length > 0) {
      const shoppingListIds = selectedShoppingLists.map(x => x.id);
      deleteShoppingLists({ ids: shoppingListIds },
        () => {
          toastr.success('Success',
            `Shopping list${selectedShoppingLists.length > 1 ? 's have' : ' has'} been deleted successfully.`);
          setSelectedShoppingLists([]);
          setSelectedCustomerGroups([]);
          setSelectedRetailers([]);
          setShowDeleteListModal(false);
          getShoppingLists();
        }, () => {

        });
    }
  };

  const shoppingListsActionsCellRenderer = (shoppingList: IShoppingListRow) => {
    return (
      <div className="table-actions">
        <span title="Delete shopping list">
          <Button
            className="delete-shopping-list"
            icon="icon-Delete"
            type="button"
            text=""
            onClick={() => onActionBtnClick('deleteShoppingList', shoppingList)}
          />
        </span>
      </div>
    );
  };

  const onOrderChanged =
    (option: any) => {
      const value: string = option.value;
      const seperator: number = value.indexOf('-');

      setOrderBy(value.substring(0, seperator));
      setOrderDescending(value.substring(seperator + 1) === 'dsc');
    };

  const retailerClicked = (value: string, checked: boolean): void => {
    const parsedValue = parseInt(value, 10);
    const customerGroup = retailers.data.find(x => x.id === parsedValue).meta.CustomerGroup;

    const updatedRetailers = checked
      ? [...new Set([...selectedRetailers, parsedValue])]
      : selectedRetailers.filter(x => x !== parsedValue);

    setSelectedRetailers(updatedRetailers);

    // if every retailer of a group is selected, make the group selected as well
    if (customerGroup) {
      const relatedRetailerIds = retailers.data
        .filter(x => shoppingLists.data.map(x => x.retailerId).includes(x.id))
        .filter(x => x.meta.CustomerGroup === customerGroup)
        .map(x => x.id);

      const customerGroupChecked = relatedRetailerIds.every(x => updatedRetailers.includes(x));

      const updatedCustomerGroups = customerGroupChecked
        ? [...new Set([...selectedCustomerGroups, customerGroup])]
        : selectedCustomerGroups.filter(x => x !== customerGroup);
      setSelectedCustomerGroups(updatedCustomerGroups);
    }
  };

  const customerGroupClicked = (value: string, checked: boolean): void => {
    const tableData = constructShoppingListsTableData();
    const customerGroupRetailerIds = tableData
      .filter(x => x.customerGroup === value)
      .map(x => x.retailerId);

    if (customerGroupRetailerIds.length > 0) {
      const updatedRetailers = checked
        ? [...new Set([...selectedRetailers, ...customerGroupRetailerIds])]
        : selectedRetailers.filter(x => !customerGroupRetailerIds.includes(x));
      setSelectedRetailers(updatedRetailers);
    }

    const updatedCustomerGroups = checked
      ? [...new Set([...selectedCustomerGroups, value])]
      : selectedCustomerGroups.filter(x => x !== value);
    setSelectedCustomerGroups(updatedCustomerGroups);
  };

  const onDeleteCancel = () => {
    setSelectedShoppingLists([]);
    setShowDeleteListModal(false);
  };

  const sortFunction = (a: IShoppingListRow, b: IShoppingListRow) => {
    const key = orderBy as keyof IShoppingListRow;

    if (a[key] < b[key]) return orderDescending ? 1 : -1;
    if (a[key] > b[key]) return orderDescending ? -1 : 1;
    return 0;
  };

  return (
    <FullPageLayout
      heading="Manage Shopping Lists"
      breadcrumbs={[
        {
          key: 0,
          text: 'Admin',
          url: '/admin/home',
        },
        {
          key: 1,
          text: 'Manage Shopping Lists',
          url: '/admin/manage-shopping-lists',
        },
      ]}
    >
      {(shoppingLists.loading || loading) && <LoadingThrobber />}
      <div className="manage-shopping-lists-content-wrapper">
        <div className="shopping-list-controls">
          <Card>
            <span className="box-shadow">
              <h3>Filter By</h3>
            </span>
            <div className="shopping-list-search-wrapper">
              <div className="searchbar-wrapper">
                <TextInput
                  error=""
                  label="Search"
                  name="shopping-list-search"
                  onChange={(val: string) => setSearch(val)}
                  type="text"
                  value={search}
                  className="shopping-list-search"
                />
                <span className="icon-search" />
              </div>
              <h4>Customer Groups</h4>
              {customerGroups.filter(x => x.toLowerCase().includes(search.toLowerCase())).map(
                (x: string) =>
                  <Checkbox
                    checked={selectedCustomerGroups.indexOf(x) !== -1}
                    key={x}
                    label={x}
                    name={x}
                    onChange={customerGroupClicked}
                    isSmall={true}
                  />,
              )}
              <h4>Retailer</h4>
              {shoppingLists.data && retailers.data
                .filter(x => shoppingLists.data.map(x => x.retailerId).includes(x.id))
                .filter(x => x.externalIdentifier.toLowerCase().includes(search.toLowerCase())).map(
                  (x: IRetailer) =>
                    <Checkbox
                      checked={selectedRetailers.indexOf(x.id) !== -1}
                      key={x.id}
                      label={x.externalIdentifier}
                      name={x.id.toString()}
                      onChange={retailerClicked}
                      isSmall={true}
                    />,
                )}
            </div>
          </Card>
        </div>
        <div className="shopping-lists-table">
          <div className="shopping-lists-header-controls">
            <div className="shopping-lists-search-wrapper">
              <div className="searchbar-wrapper">
                <TextInput
                  error=""
                  label="Search"
                  name="shopping-lists-search"
                  onChange={(val: string) => setTableSearch(val)}
                  type="text"
                  value={tableSearch}
                  className="shopping-lists-search"
                />
                <span className="icon-search" />
              </div>
            </div>
            <div className="shopping-lists-actions-wrapper">
              <Dropdown
                className="shopping-lists-sort"
                label=""
                name="shopping-lists-sort"
                onChange={onOrderChanged}
                selectedValue={`${orderBy}-${orderDescending ? 'dsc' : 'asc'}`}
                options={[
                  { label: 'DESCRIPTION: A-Z', value: 'description-asc' },
                  { label: 'DESCRIPTION: Z-A', value: 'description-dsc' },
                  { label: 'RETAILER: A-Z', value: 'retailer-asc' },
                  { label: 'RETAILER: Z-A', value: 'retailer-dsc' },
                  { label: 'CUSTOMER GROUP: A-Z', value: 'customerGroup-asc' },
                  { label: 'CUSTOMER GROUP: Z-A', value: 'customerGroup-dsc' },
                  { label: 'CREATION DATE: A-Z', value: 'createdAt-asc' },
                  { label: 'CREATION DATE: Z-A', value: 'createdAt-dsc' },
                ]}
              />
              <Button
                className="primary"
                text="Upload Shopping Lists"
                type="button"
                onClick={() => navigateTo('/admin/upload-shopping-lists')}
              />
            </div>
          </div>
          {
            !(shoppingLists && shoppingLists.loading) &&
            <BootstrapTable
              classes="shopping-lists-table-bootstrap"
              keyField="id"
              columns={columns}
              data={shoppingListsTable.sort((a: IShoppingListRow, b: IShoppingListRow) =>
                sortFunction(a, b)).filter((x: IShoppingListRow) =>
                  x.description.toLowerCase().includes(tableSearch.toLowerCase()))}
              noDataIndication="No shopping lists available"
            />
          }
        </div>
      </div>
      <Modal
        header={`Are you sure you want to delete:
          "${selectedShoppingLists[0] ? selectedShoppingLists[0].description : ''}"?`}
        isOpen={showDeleteListModal}
        buttonText="Delete"
        onClose={() => onDelete()}
        onCancel={() => onDeleteCancel()}
      >
        <div className="delete-shopping-list-modal-body">
          Impacted retailers: <b>{selectedShoppingLists.map(x => x.retailer).join(', ')}</b>
        </div>
      </Modal>
    </FullPageLayout>
  );
};

const mapStateToProps = (state: IStoreState): any => {
  return {
    retailers: state.retailers,
    shoppingLists: state.shoppingLists,
    loading: state.updateRetailerMeta.loading,
    ensurePathState: state.documentsEnsurePath,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<any>) => ({
  getShoppingLists: () => dispatch(getAdminShoppingListsRequest()),
  getRetailers: () => dispatch(getRetailersRequest()),
  deleteShoppingLists: (parameters: IDeleteShoppingListsBody,
    onSuccess: () => void,
    onFailure: () => void) => dispatch(deleteShoppingListsRequest(parameters, onSuccess, onFailure)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ManageShoppingLists);
