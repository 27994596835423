import { Reducer } from 'redux';
import { IRetailerDetails, IApiRequest } from '../types';

const initialState: IApiRequest<IRetailerDetails> = {
  data: null,
  error: '',
  UpdatedAt: null,
  loading: false,
};

const retailerDetailsReducer: Reducer<IApiRequest<IRetailerDetails>> =
(state: IApiRequest<IRetailerDetails> = initialState, action : any) => {
  switch (action.type) {
    case 'GET_RETAILER_DETAILS_REQUEST': {
      return {
        ...initialState,
        loading: true,
      };
    }
    case 'GET_RETAILER_DETAILS_RESPONSE': {
      return {
        ...initialState,
        data: action.response,
      };
    }
    case 'GET_RETAILER_DETAILS_ERROR': {
      return {
        ...initialState,
        error: 'An error has occured',
      };
    }
    default: {
      return state;
    }
  }
};

export default retailerDetailsReducer;
