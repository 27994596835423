import React, { useEffect, useMemo, useState } from 'react';
import { IAdminTradingPacksProps } from './IAdminTradingPacksProps';
import { FullPageLayout } from '../../../layouts';
import { Button, Tab, Tabs } from '@orderly/morrisons-component-library';
import { IStoreState, ITradingPack } from '../../../types';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { getTradingPacksRequest } from '../../../actions/Products';
import { LoadingThrobber, TradingPacksTable } from '../../../components';
import { navigateTo } from '../../../routing/Navigation';

import './AdminTradingPacks.scss';

function AdminTradingPacks({
  getTradingPacks,
  tradingPacks,
}: IAdminTradingPacksProps): JSX.Element {
  const [activeTab, setActiveTab] = useState<string>('current-trading-packs');

  useEffect(
    () => {
      getTradingPacks();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [],
  );

  const activeTradingPacks = useMemo<ITradingPack[]>(
    () => {
      return tradingPacks.data.filter((x: ITradingPack) => !x.isExpired);
    },
    [tradingPacks],
  );

  const expiredTradingPacks = useMemo<ITradingPack[]>(
    () => {
      return tradingPacks.data.filter((x: ITradingPack) => x.isExpired);
    },
    [tradingPacks],
  );

  const CreateTradingPack = () => {
    return (
      <Button
        type="button"
        className="tertiary"
        text="Create Trading Pack"
        onClick={() => navigateTo('/admin/trading-packs/create')}
      />
    );
  };

  return (
    <FullPageLayout
      heading="Manage Trading Packs"
      breadcrumbs={[
        {
          key: 0,
          text: 'Manage Trading Packs',
          url: '/admin/trading-packs',
        },
      ]}
      homeRoute="/admin/home"
      headerAdditionalContent={<CreateTradingPack />}
    >
      <div className="admin-trading-packs-container">
        {tradingPacks.loading && <LoadingThrobber />}
        <Tabs onChange={setActiveTab} activeTab={activeTab}>
          <Tab id="current-trading-packs" title="Active / Future Trading Packs">
            <TradingPacksTable
              tabId="current-trading-packs"
              rows={activeTradingPacks}
              noRecordsMessage="No active trading packs"
            />
          </Tab>
          <Tab id="inactive-trading-packs" title="Inactive Trading Packs">
            <TradingPacksTable
              tabId="inactive-trading-packs"
              rows={expiredTradingPacks}
              noRecordsMessage="No inactive trading packs"
            />
          </Tab>
        </Tabs>
      </div>
    </FullPageLayout>
  );
}

const mapStateToProps = (state: IStoreState) => {
  return {
    tradingPacks: state.tradingPacks,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<any>) => {
  return {
    getTradingPacks: () => dispatch(getTradingPacksRequest()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AdminTradingPacks);
