import { Reducer } from 'redux';
import {
  GET_PRODUCT_PAGE_ERROR,
  GET_PRODUCT_PAGE_REQUEST,
  GET_PRODUCT_PAGE_RESPONSE } from '../actions/productPageAction';
import { ProductPage, IPagedDataResponse, IProduct } from '../types';

const defaultData = { canLoadMore: false, data: [] } as IPagedDataResponse<IProduct[]>;
const initialState: ProductPage = { loading: true, data: defaultData, error: '', UpdatedAt: null };

const productsReducer: Reducer<ProductPage> = (state: ProductPage = initialState, action : any) => {

  switch (action.type) {
    case GET_PRODUCT_PAGE_RESPONSE: {
      const data = { ...state.data };

      if (action.loadMore) {
        data.data = data.data.concat(action.response.data);
      } else {
        data.data = action.response.data;
      }

      data.canLoadMore = action.response.canLoadMore;
      data.totalData = action.response.totalData;

      return {
        data,
        UpdatedAt: null,
        loading: false,
        error: '',
      };
    }
    case GET_PRODUCT_PAGE_REQUEST: {
      return {
        UpdatedAt: null,
        loading: true,
        error: '',
        data: action.loadMore ? state.data : defaultData,
      };
    }
    case GET_PRODUCT_PAGE_ERROR: {
      return {
        UpdatedAt: null,
        loading: false,
        error: action.error || 'There was an error loading this product. Please try again.',
        data: null,
      };
    }
    default: {
      return state;
    }
  }
};

export default productsReducer;
