import Fetch from '../apiConfig/apiConfig';
import { AxiosPromise } from 'axios';
import { IOrderOpportunity } from '../../types';

export interface IGetOrderOpportunitiesParams {
  storeId: number;
  categoryCode: string;
}

export interface IGetOrderOpportunitiesResponse {
  orderOpportunities: IOrderOpportunity[];
}

export const getOrderOpportunities =
  (params: IGetOrderOpportunitiesParams): AxiosPromise<IGetOrderOpportunitiesResponse> => {
    return Fetch().then(axios => axios.get(`order-opportunities?storeId=${params.storeId}` +
      `&categoryCode=${params.categoryCode}`));
  };
