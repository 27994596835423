import React, { Dispatch, useEffect, useState } from 'react';
import { Breadcrumbs } from '@orderly/morrisons-component-library';
import { navigateTo } from '../../../routing/Navigation';
import {
  editOrderPendingReviewRequest,
  getOrdersPendingReviewRequest,
  submitOrderReviewRequest,
} from '../../../actions/Orders/orderReviewAction';
import { IApiRequest, IPendingOrder, IStoreState } from '../../../types';
import { connect } from 'react-redux';
import OrderReviewView from '../../../components/OrderReviewView';
import styles from './Orders.module.scss';

interface IOrdersProps {
  orderReview: IApiRequest<IPendingOrder[]>;
  getOrdersPendingReview: () => void;
  submitOrderReview: (orderId: number, approved: boolean) => Promise<any>;
  editOrderPendingReview: (order: IPendingOrder) => Promise<any>;
}

function Orders({
  orderReview,
  getOrdersPendingReview,
  submitOrderReview,
  editOrderPendingReview,
}: IOrdersProps): JSX.Element {
  const [localOrders, setLocalOrders] = useState<IPendingOrder[]>([]);

  useEffect(() => {
    getOrdersPendingReview();
  }, []);

  useEffect(() => {
    if (orderReview.data) {
      setLocalOrders(orderReview.data);
    }
  }, [orderReview]);

  function submitOrder(orderId: number, approved: boolean): void {
    const confirmationMessage = `Are you sure you want to ${approved ? 'approve' : 'reject'} this order?`;
    if (window.confirm(confirmationMessage)) {
      submitOrderReview(orderId, approved)
        .then(() => getOrdersPendingReview())
        .catch(() => console.warn('An error has occured'));
    }
  }

  function saveOrder(order: IPendingOrder): void {
    setTimeout(() => {
      const confirmationMessage = 'Are you sure you want to save this order?';
      if (window.confirm(confirmationMessage)) {
        editOrderPendingReview(order)
          .then(() => getOrdersPendingReview())
          .catch(() => console.warn('An error has occured'));
      }
    }, 500);
  }

  return (
    <div className={styles.container}>
      <Breadcrumbs
        className={styles.breadcrumbs}
        onHomeClick={() => navigateTo('/admin/home')}
        onRedirect={() => navigateTo(window.location.pathname)}
        segments={[{ key: 0, text: 'Orders', url: '' }]}
      />
      <section className={styles.headingWrapper}>
        <h1>Orders</h1>
      </section>
      <div className={styles.contentWrapper}>
        <div className="content card">
          <OrderReviewView
            loading={orderReview.loading}
            submitReviewAction={submitOrder}
            editOrderAction={saveOrder}
            orders={localOrders}
            setOrders={setLocalOrders}
          />
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state: IStoreState) => {
  return {
    userDetails: state.userDetails,
    orderReview: state.orderReview,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<any>) => {
  return {
    getOrdersPendingReview: () => dispatch(getOrdersPendingReviewRequest()),
    submitOrderReview: (orderId: number, approved: boolean) => dispatch(submitOrderReviewRequest(orderId, approved)),
    editOrderPendingReview: (order: IPendingOrder) => dispatch(editOrderPendingReviewRequest(order)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Orders);
