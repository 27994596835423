import { Reducer } from 'redux';
import { REMOVE_USER_RESPONSE } from '../actions/removeUsersAction';
import { IRemoveUser } from '../types';

const initialState: IRemoveUser = { userId:  -1 };

const removeUserReducer: Reducer<IRemoveUser> = (state: IRemoveUser = initialState, action : any) => {
  if (action.type === REMOVE_USER_RESPONSE) {
    return {
      userId: action.userId,
    };
  }
  return state;
};

export default removeUserReducer;
