import React from 'react';
import './PageNotFound.scss';
import { Button } from '@orderly/morrisons-component-library';
import { navigateTo } from '../../routing/Navigation';
import { IStoreState } from '../../types';
import { connect } from 'react-redux';
import { IPageNotFoundProps } from './IPageNotFoundProps';
import { getBasePath, isLoggedIn } from '../../helpers/Users/UserHelper';

const PageNotFound = ({ userDetails }: IPageNotFoundProps) => (
  <div className="not-found">
    <section>
      <h1 className="error-message">Page not found</h1>
      <p>Check the URL and try again, or go back to the homepage.</p>
      <Button
        type="button"
        className="secondary nav-homepage"
        icon="icon-home"
        iconPos="left"
        text="Back to Homepage"
        onClick={() => navigateTo(isLoggedIn(userDetails) ? `${getBasePath(userDetails)}home` : '/')}
      />
    </section>
  </div>
);

const mapStateToProps = (state: IStoreState) => {
  return {
    userDetails: state.userDetails,
  };
};

export default connect(mapStateToProps)(PageNotFound);
