import React from 'react';
import Slider from 'react-slick';
import './CategoryCarousel.scss';
import CategoryPromo from '../../components/CategoryPromo/CategoryPromo';
import { mapCategory } from '../../helpers/Category/mapCategory';
import { ICategory } from '../../types';
import SectionHeading from '../SectionHeading/SectionHeading';

export default class CategoryCarousel extends React.Component<any, any> {
  private slider: any;

  public render(): JSX.Element {
    const { categories } = this.props;

    const settings = {
      arrows: false,
      draggable: false,
      dots: false,
      infinite: true,
      useTransform: false,
      slidesToShow: Math.min(5, categories.data.length),
      slidesToScroll: 2,
      className: 'carousel-items',
      responsive: [
        {
          breakpoint: 1580,
          settings: {
            slidesToShow: Math.min(4.3, categories.data.length),
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 1380,
          settings: {
            slidesToShow: 3.3,
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 1080,
          settings: {
            slidesToShow: 2.3,
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 680,
          settings: {
            slidesToShow: 1.8,
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 530,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            centerMode: true,
          },
        },
        {
          breakpoint: 400,
          settings: {
            slidesToShow: 0.8,
            slidesToScroll: 1,
            centerMode: true,
          },
        },
      ],
    };

    return (
      <div className="categories-wrapper">
        <SectionHeading>
          Shop by categories
          <div className="carousel-navigation-wrapper">
            <button onClick={this.movePrev} className="circle">
              <span className="icon icon-arrow-left" />
            </button>
            <button onClick={this.moveNext} className="circle">
              <span className="icon icon-arrow-right" />
            </button>
          </div>
        </SectionHeading>
        <div className="categories">
          <Slider ref={s => (this.slider = s)} {...settings}>
            {categories.data.map((category: ICategory): any =>
              <CategoryPromo
                key={category.id}
                id={category.id}
                text={mapCategory(category.description)}
              />)
            }
          </Slider>
        </div>
      </div>
    );
  }

  private moveNext = () => {
    this.slider.slickNext();
  }

  private movePrev = () => {
    this.slider.slickPrev();
  }
}
