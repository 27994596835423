import { Action } from 'redux';
import { createOrder } from '../../api/Basket/CreateOrder/CreateOrderApi';
import { ICreateOrderParams } from '../../api/Basket/CreateOrder/ICreateOrderParams';
import { ICreateOrderResponse } from '../../api/Basket/CreateOrder/ICreateOrderResponse';
import { AxiosResponse } from 'axios';
import { toastr } from 'react-redux-toastr';

export const POST_ORDER_REQUEST = 'POST_ORDER_REQUEST';
export type POST_ORDER_REQUEST = typeof POST_ORDER_REQUEST;
export const POST_ORDER_RESPONSE = 'POST_ORDER_RESPONSE';
export type POST_ORDER_RESPONSE = typeof POST_ORDER_RESPONSE;
export const POST_ORDER_ERROR = 'POST_ORDER_ERROR';
export type POST_ORDER_ERROR = typeof POST_ORDER_ERROR;
export const SET_CURRENT_BASKET = 'SET_CURRENT_BASKET';
export type SET_CURRENT_BASKET = typeof SET_CURRENT_BASKET;
export interface IRetailerOrdersResponse extends Action {
  type: POST_ORDER_RESPONSE;
  response: ICreateOrderResponse;
}

export interface IGetMyAccountError extends Action {
  type: POST_ORDER_ERROR;
  error?: string;
}

export const createOrderRequest = (parameters: ICreateOrderParams): any => {
  return (dispatch : any) => {
    dispatch({
      type: POST_ORDER_REQUEST,
      RetailerId: parameters.RetailerId,
    });

    return createOrder(parameters)
      .then((response: AxiosResponse<ICreateOrderResponse>) => {
        if (response.status === 200) {
          if (parameters.OrderNumberToCopy && parameters.OrderNumberToCopy.length > 0) {
            toastr.success(
              'Order Copied',
              'Your order has successfully been copied.');
          } else {
            toastr.success(
              'Order Created',
              'Your order has successfully been created.');
          }

          dispatch({
            type: SET_CURRENT_BASKET,
            response: response.data.basket,
          });

          dispatch({
            type: POST_ORDER_RESPONSE,
          });
        } else {
          onError(dispatch);
        }
      })
      .catch((err: any) => {
        console.log(err);
        onError(dispatch);
      });
  };
};

const onError = (dispatch: any) => dispatch({
  type: POST_ORDER_ERROR,
});
