import { IOrder } from '../../pages/Orders/IOrder';
import { IOrderStatusFilter } from './IOrderStatusFilter';
import { IOrderStatusCount } from './IOrderStatusCount';

export const getOrderStatusFilters = (mappedOrders: IOrder[]): IOrderStatusFilter[] => {
  const statuses: string[] = extractOrdersStatuses(mappedOrders);
  const filters: IOrderStatusFilter[] = extractOrderStatusFilters(statuses);

  return filters;
};

const extractOrdersStatuses = (mappedOrders: IOrder[]): string[] => {
  const statuses: string[] = [];

  if (mappedOrders) {
    mappedOrders.forEach((order: IOrder): void => {
      if (order.status) {
        statuses.push(order.status);
      }
    });
  }

  return statuses;
};

const extractOrderStatusFilters = (statuses: string[]): IOrderStatusFilter[] => {
  const orderStatus: IOrderStatusCount = {};
  const orderStatusFilters: IOrderStatusFilter[] = [];

  statuses.sort();
  statuses.forEach((status: string): void => {
    orderStatus[status] =  (orderStatus[status] || 0) + 1;
  });

  for (const key in orderStatus) {
    orderStatusFilters.push({
      status: key,
      count: orderStatus[key],
      isChecked: false,
    });
  }

  return orderStatusFilters;
};
