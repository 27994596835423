import { Reducer } from 'redux';
import {
  GET_SUB_CATEGORIES_ERROR,
  GET_SUB_CATEGORIES_REQUEST,
  GET_SUB_CATEGORIES_RESPONSE } from '../actions/subCategoriesAction';
import { ISubCategoriesWithProductCount } from '../types';

const initialState: ISubCategoriesWithProductCount = [];

const subCategoriesReducer: Reducer<ISubCategoriesWithProductCount>
= (state: ISubCategoriesWithProductCount = initialState, action : any) => {
  switch (action.type) {
    case GET_SUB_CATEGORIES_RESPONSE: {
      return {
        ...state,
        ...action.response,
      };
    }
    case GET_SUB_CATEGORIES_REQUEST:
    case GET_SUB_CATEGORIES_ERROR:
    default: {
      return state;
    }
  }
};

export default subCategoriesReducer;
