import { Reducer } from 'redux';
import { IOrderSearch, IApiRequest } from '../types';

const initialState: IApiRequest<IOrderSearch> = {
  data: null,
  error: '',
  UpdatedAt: null,
  loading: false,
};

const retailerDetailsReducer: Reducer<IApiRequest<IOrderSearch>> =
(state: IApiRequest<IOrderSearch> = initialState, action : any) => {
  switch (action.type) {
    case 'GET_ORDER_SEARCH_RESPONSE': {
      return {
        data: action.response,
        error: '',
        UpdatedAt: null,
        loading: false,
      };
    }
    case 'GET_ORDER_SEARCH_REQUEST': {
      return {
        data: initialState.data,
        error: '',
        UpdatedAt: null,
        loading: true,
      };
    }
    case 'GET_ORDER_SEARCH_ERROR': {
      return {
        data: null,
        error: action.response || 'There was an error finding this order. Please try again',
        UpdatedAt: null,
        loading: false,
      };
    }
    default: {
      return state;
    }
  }
};

export default retailerDetailsReducer;
