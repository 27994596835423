import { Dispatch } from 'redux';
import { updateOrderPoNumber } from '../../api/Basket/updatePoNumberApi';

export const UPDATE_ORDER_PO_NUMBER_REQUEST = 'UPDATE_ORDER_PO_NUMBER_REQUEST';
export type UPDATE_ORDER_PO_NUMBER_REQUEST = typeof UPDATE_ORDER_PO_NUMBER_REQUEST;

export const UPDATE_ORDER_PO_NUMBER_RESPONSE = 'UPDATE_ORDER_PO_NUMBER_RESPONSE';
export type UPDATE_ORDER_PO_NUMBER_RESPONSE = typeof UPDATE_ORDER_PO_NUMBER_RESPONSE;

export const UPDATE_ORDER_PO_NUMBER_ERROR = 'UPDATE_ORDER_PO_NUMBER_ERROR';
export type UPDATE_ORDER_PO_NUMBER_ERROR = typeof UPDATE_ORDER_PO_NUMBER_ERROR;

export const updateOrderPoNumberRequest = (poNumber: string): any => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: UPDATE_ORDER_PO_NUMBER_REQUEST,
    });

    try {
      const response = await updateOrderPoNumber(poNumber);

      if (response.status === 200) {
        dispatch({
          type: UPDATE_ORDER_PO_NUMBER_RESPONSE,
          response: response.data.order,
        });
      } else {
        onError(dispatch, response.data as unknown as string);
      }
    } catch (error) {
      onError(dispatch);
    }
  };
};

const onError = (dispatch: any, message: string = null) => dispatch({
  type: UPDATE_ORDER_PO_NUMBER_ERROR,
  response: message,
});
