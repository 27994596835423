import { IOrderStatusFilter } from './../IOrderStatusFilter';

export const orderStatusLabels: IOrderStatusFilter[] = [
  {
    count: 0,
    isChecked: false,
    status: 'Confirmed',
  },
  {
    count: 0,
    isChecked: false,
    status: 'Ordered',
  },
  {
    count: 0,
    isChecked: false,
    status: 'Shipped',
  },
];
