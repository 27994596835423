import { AxiosResponse } from 'axios';
import { IConfirmedOrdersParams } from '../../api/Reports/ConfirmedOrders/IConfirmedOrdersParams';
import { IConfirmedOrdersResponse } from '../../api/Reports/ConfirmedOrders/IConfirmedOrdersResponse';
import { getConfirmedOrdersReport } from '../../api/Reports/ReportsApi';
import { toastr } from 'react-redux-toastr';

export const getConfirmedOrdersReportRequest = (parameters: IConfirmedOrdersParams): any => {

  return (dispatch : any) => {
    dispatch({
      type: 'GET_CONFIRMED_ORDERS_REPORT_REQUEST',
    });

    return getConfirmedOrdersReport(parameters)
      .then((response: AxiosResponse<IConfirmedOrdersResponse>) => {
        if (response.status === 200) {
          if (response.data.report !== undefined) {
            dispatch({
              type: 'GET_CONFIRMED_ORDERS_REPORT_RESPONSE',
              response: response.data.report,
            });

            toastr.success(
              'Report Generated',
              'Your report has successfully been generated.',
            );

          } else {
            onError(dispatch);
          }
        } else {
          onError(dispatch);
        }
      })
      .catch((err: any) => {
        console.log(err);
        onError(dispatch);
      });
  };
};

export const resetReportData = () => ({
  type: 'RESET_CONFIRMED_ORDERS_REPORT',
});

const onError = (dispatch: any) => {
  toastr.error(
    'Error',
    'An error has occured while generating the report');

  dispatch({
    type: 'GET_CONFIRMED_ORDERS_REPORT_ERROR',
    response: 'An error has occured while generating the report',
  });
};
