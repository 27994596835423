
import { Action } from 'redux';

export const SITE_WRAPPER_VISIBILITY = 'SITE_WRAPPER_VISIBILITY';
export type SITE_WRAPPER_VISIBILITY = typeof SITE_WRAPPER_VISIBILITY;

export interface IUpdateSiteWrapperVisibility extends Action {
  type: SITE_WRAPPER_VISIBILITY;
  payload: boolean;
}

export type SiteWrapperAction = IUpdateSiteWrapperVisibility;

export const updateSiteWrapperVisibility = (siteWrapper: any): IUpdateSiteWrapperVisibility => {
  return {
    type: SITE_WRAPPER_VISIBILITY,
    payload: siteWrapper,
  };
};
