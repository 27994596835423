import Fetch from '../../apiConfig/apiConfig';
import { AxiosPromise } from 'axios';
import { IDispatchedOrder } from '../../../types';
import { IDispatchedOrderResponse } from './IDispatchedOrderResponse';

export interface ISubmitDispatchedOrderParams {
  order: IDispatchedOrder;
}

export const getDispatchedOrdersApi = (): AxiosPromise<IDispatchedOrderResponse> => {
  return Fetch().then(axios => axios.get('orders/dispatched'));
};

export const submitDispatchedOrderApi = (params: ISubmitDispatchedOrderParams): AxiosPromise<void> => {
  return Fetch().then(axios => axios.post('orders/dispatched/submit', params));
};
