import { Reducer } from 'redux';
import { IApiRequest, IDispatchedOrder } from '../../types';
import {
  GET_DISPATCHED_ORDERS_REQUEST,
  GET_DISPATCHED_ORDERS_RESPONSE,
  GET_DISPATCHED_ORDERS_ERROR,
  SUBMIT_DISPATCHED_ORDER_REQUEST,
  SUBMIT_DISPATCHED_ORDER_RESPONSE,
  SUBMIT_DISPATCHED_ORDER_ERROR,

} from '../../actions/Orders/dispatchedOrdersAction';

const initialState: IApiRequest<IDispatchedOrder[]> = {
  data: null,
  error: '',
  UpdatedAt: null,
  loading: false,
};

const dispatchedOrders: Reducer<IApiRequest<IDispatchedOrder[]>> =
  (state: IApiRequest<IDispatchedOrder[]> = initialState, action: any) => {
    switch (action.type) {
      case GET_DISPATCHED_ORDERS_RESPONSE: {
        return {
          ...initialState,
          data: action.response,
        };
      }
      case GET_DISPATCHED_ORDERS_REQUEST: {
        return {
          ...initialState,
          loading: true,
        };
      }
      case GET_DISPATCHED_ORDERS_ERROR: {
        return {
          ...initialState,
          error: action.response || 'There was an error retrieving dispatched orders. Please try again',
        };
      }
      case SUBMIT_DISPATCHED_ORDER_REQUEST: {
        return {
          ...state,
          loading: true,
        };
      }
      case SUBMIT_DISPATCHED_ORDER_RESPONSE: {
        return {
          ...state,
          loading: false,
          UpdatedAt: new Date(),
        };
      }
      case SUBMIT_DISPATCHED_ORDER_ERROR: {
        return {
          ...state,
          loading: false,
          error: action.response || 'There was an error submitting dispatched order. Please try again',
        };
      }
      default: {
        return state;
      }
    }
  };

export default dispatchedOrders;
