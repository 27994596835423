import { MutableRefObject, useCallback, useEffect, useRef, useState } from 'react';
import BrowserDetector from '../../../helpers/BrowserDetector/BrowserDetector';
import { SupportedBrowsers } from '../../../helpers/BrowserDetector/enums/SupportedBrowsers.enum';

const useTable = (children: JSX.Element[]): any => {
  const tableRef: MutableRefObject<HTMLTableElement> = useRef(null);
  const tableWrapperRef = useRef(null);
  const tableContainerRef = useRef(null);
  const [stickyStyles, setStickyStyles] = useState(
    {
      left: 0,
      height: 0,
    },
  );
  const [hideScrollBar, setHideScrollBar] = useState('');
  const [result, setResult] = useState(
    {
      tableWrapperRef,
      stickyStyles,
      tableRef,
      hideScrollBar,
      isMSEdge: false,
    },
  );

  useEffect(
    (): any => {
      // This is to maintain Table--sticky placeholder div position when window is resized.
      // Failed to find a css only solution.
      const onWindowResize = (): void => {
        applyStickyStyles();
      };
      const isMSEdge: boolean = BrowserDetector.is(SupportedBrowsers.MICROSOFT_EDGE);

      applyStickyStyles();

      if (tableRef && tableRef.current && tableRef.current.tBodies[0]) {
        if (!tableRef.current.tBodies[0].childNodes.length) {
          setHideScrollBar('Table--noScroll');
        } else {
          setHideScrollBar('');
        }

        if (isMSEdge && tableRef.current.tBodies[0].childNodes.length) {
          const stickyElements: Element[] = Array.from<Element>(
            document.getElementsByClassName('Table--stickyColumn'),
          );

          stickyElements.forEach((element: Element): void => {
            element.classList.add('Table--noStickyMSEdge');
          });
        }
      }

      addResizeEvent(tableRef.current, onWindowResize);
      setResult({ tableWrapperRef, stickyStyles, tableRef, hideScrollBar, isMSEdge });

      return (): void => {
        window.removeEventListener('resize', onWindowResize);
      };
    },
    [children, tableContainerRef.current, tableRef.current, stickyStyles.height, stickyStyles.left],
  );

  const addResizeEvent = useCallback(
    (
      tableElement: HTMLTableElement,
      onWindowResize: () => void,
    ): void => {

      if (tableElement) {
        window.addEventListener('resize', onWindowResize);
      }
    },
    [],
  );

  const applyStickyStyles = useCallback(
    (): void => {
      const th: any = tableRef.current && tableRef.current.getElementsByTagName('th')[0];

      if (th) {
        setStickyStyles(
          {
            left: th.scrollWidth,
            height: tableRef.current.clientHeight,
          },
        );
      }
    },
    [],
  );

  return result;
};

export default useTable;
