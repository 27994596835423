import { Action } from 'redux';
import { IDocument } from '../../types';
import { IUploadDocumentParams, uploadDocument, IUploadDocumentResponse } from '../../api/Documents/uploadDocumentApi';
import { AxiosResponse } from 'axios';

export const UPLOAD_DOCUMENT_REQUEST = 'UPLOAD_DOCUMENT_REQUEST';
export type UPLOAD_DOCUMENT_REQUEST = typeof UPLOAD_DOCUMENT_REQUEST;

export const UPLOAD_DOCUMENT_RESPONSE = 'UPLOAD_DOCUMENT_RESPONSE';
export type UPLOAD_DOCUMENT_RESPONSE = typeof UPLOAD_DOCUMENT_RESPONSE;

export const UPLOAD_DOCUMENT_ERROR = 'UPLOAD_DOCUMENT_ERROR';
export type UPLOAD_DOCUMENT_ERROR = typeof UPLOAD_DOCUMENT_ERROR;

export interface IUploadDocumentError extends Action {
  type: UPLOAD_DOCUMENT_ERROR;
  error?: string;
}

export const uploadDocumentRequest =
(parameters: IUploadDocumentParams,
 onSuccess: () => void,
 onFailure: (err: any) => void): any => {
  return (dispatch : any) => {

    dispatch({
      type: UPLOAD_DOCUMENT_REQUEST,
    });

    return uploadDocument(parameters)
      .then((response: AxiosResponse<IUploadDocumentResponse>) => {
        if (response.status === 200) {
          dispatch({
            type: UPLOAD_DOCUMENT_RESPONSE,
            response: response.data.uploaded,
          });

          onSuccess();
        } else {

          if (response instanceof Error) {
            onFailure(response);
          }
        }
      });
  };
};

const onError = (dispatch: any) => dispatch({
  type: UPLOAD_DOCUMENT_ERROR,
});
