import Fetch from '../apiConfig/apiConfig';
import { AxiosPromise } from 'axios';
import { IDocumentBlob } from '../../types';

export interface IDownloadStoreDocumentBlobParams {
  documentId: number;
  customerGroup: string;
}

export interface IDownloadStoreDocumentBlobResponse {
  document: IDocumentBlob;
}

export const downloadStoreDocumentBlob = (params: IDownloadStoreDocumentBlobParams):
  AxiosPromise<IDownloadStoreDocumentBlobResponse> => {
  return Fetch().then(axios => axios.get(`store-documents/blob?documentId=${params.documentId}`
    + `&customerGroup=${params.customerGroup}`));
};
