import { Reducer } from 'redux';
import { IPersistOrderListSearch } from './IPersistOrderListSearch';

const initialState: IPersistOrderListSearch  = {
  data: {
    searchedOrders: [],
    searchedValue: '',
    fieldToSearch: '',
  },
};

const persistOrderListSearch: Reducer<IPersistOrderListSearch> = (
    state: IPersistOrderListSearch = initialState,
    action: any,
): any => {
  if (action.type === 'ORDERS_LIST_SEARCH_PERSIST') {
    return {
      data: {
        searchedOrders: action.data.searchedOrders,
        searchedValue: action.data.searchedValue,
        fieldToSearch: action.data.fieldToSearch,
      },
    };
  }
  return state;
};

export default  persistOrderListSearch;
