import { ISortOrdersSelection } from '../interfaces/ISortOrdersSelection';
import { sortOrdersFieldMapper } from './SortOrdersFieldMapper';
import { sortOrdersValueMapper } from './SortOrdersValueMapper';

export const sortOrdersSelection: (field: string, value: number) => ISortOrdersSelection
    = (field: string, value: number): ISortOrdersSelection => {
      return {
        field: sortOrdersFieldMapper[field],
        value: sortOrdersValueMapper[value],
      };
    };
