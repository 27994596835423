import { Reducer } from 'redux';
import { IApiRequest, IDocument } from '../../types/index';
import {
  UPLOAD_DOCUMENT_REQUEST,
  UPLOAD_DOCUMENT_RESPONSE,
  UPLOAD_DOCUMENT_ERROR,
} from '../../actions/Documents/uploadDocumentAction';

const initialState: IApiRequest<IDocument> = {
  data: undefined,
  error: '',
  UpdatedAt: null,
  loading: false,
};

const uploadDocumentReducer: Reducer<IApiRequest<IDocument>>
= (state: IApiRequest<IDocument> = initialState, action : any) => {
  switch (action.type) {
    case UPLOAD_DOCUMENT_RESPONSE: {
      return {
        data: action.response,
        error: '',
        UpdatedAt: new Date(),
        loading: false,
      };
    }
    case UPLOAD_DOCUMENT_REQUEST: {
      return {
        data: undefined,
        error: '',
        UpdatedAt: null,
        loading: true,
      };
    }
    case UPLOAD_DOCUMENT_ERROR:
    default: {
      return state;
    }
  }
};

export default uploadDocumentReducer;
