import { Reducer } from 'redux';
import { IApiRequest, IOrderClaimReview } from '../../types';
import {
  GET_ORDER_CLAIM_REQUEST,
  GET_ORDER_CLAIM_RESPONSE,
  GET_ORDER_CLAIM_ERROR,
} from '../../actions/Orders/OrderClaims/orderClaimsAction';

const initialState: IApiRequest<IOrderClaimReview> = {
  data: null,
  error: '',
  UpdatedAt: null,
  loading: false,
};

const reviewOrderClaimReducer: Reducer<IApiRequest<IOrderClaimReview>> =
  (state: IApiRequest<IOrderClaimReview> = initialState, action: any) => {
    switch (action.type) {
      case GET_ORDER_CLAIM_RESPONSE: {
        return {
          ...initialState,
          data: action.response,
          loading: false,
        };
      }
      case GET_ORDER_CLAIM_REQUEST: {
        return {
          ...initialState,
          loading: true,
        };
      }
      case GET_ORDER_CLAIM_ERROR: {
        return {
          ...initialState,
          error: action.response || 'There was an error retrieving the order claim. Please try again',
        };
      }
      default: {
        return state;
      }
    }
  };

export default reviewOrderClaimReducer;
