import React, { Dispatch, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { IApiRequest, IDispatchedOrder, IStoreState } from '../../../types';
import {
  getDispatchedOrdersRequest,
  submitDispatchedOrderRequest,
} from '../../../actions/Orders/dispatchedOrdersAction';
import LoadingThrobber from '../../../components/LoadingThrobber/LoadingThrobber';
import DispatchedOrdersTable from './DispatchedOrdersTable';
import { FullPageLayout } from '../../../layouts';

interface IOrderDispatchReviewProps {
  dispatchedOrders: IApiRequest<IDispatchedOrder[]>;
  getDispatchedOrders: () => void;
  submitDispatchedOrder: (order: IDispatchedOrder) => void;
}

const DispatchedOrdersReview = ({
  dispatchedOrders,
  getDispatchedOrders,
  submitDispatchedOrder,
}: IOrderDispatchReviewProps) => {
  const [orders, setOrders] = useState<IDispatchedOrder[]>([]);

  useEffect(() => {
    getDispatchedOrders();
  }, []);

  useEffect(() => {
    if (dispatchedOrders.data) {
      setOrders(dispatchedOrders.data);
    }
  }, [dispatchedOrders]);

  function processDispatchedOrder(order: IDispatchedOrder): void {
    const confirmationMessage = 'Are you sure you want to submit this order?';
    if (window.confirm(confirmationMessage)) {
      submitDispatchedOrder(order);
    }
  }

  return (
    <FullPageLayout
      heading="Dispatched Orders Review"
      breadcrumbs={[
        {
          key: 0,
          text: 'Dispatched Orders Review',
          url: '/admin/order-dispatch-review',
        },
      ]}
      homeRoute="/admin/home"
    >
      {dispatchedOrders.loading && <LoadingThrobber />}
      <DispatchedOrdersTable
        submitDispatchedOrdersAction={processDispatchedOrder}
        orders={orders}
        setOrders={setOrders}
      />
    </FullPageLayout>
  );
};

const mapStateToProps = (state: IStoreState) => {
  return {
    dispatchedOrders: state.dispatchedOrders,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<any>) => ({
  getDispatchedOrders: () => dispatch(getDispatchedOrdersRequest()),
  submitDispatchedOrder: (order: IDispatchedOrder) => dispatch(submitDispatchedOrderRequest(order)),
});

export default connect(mapStateToProps, mapDispatchToProps)(DispatchedOrdersReview);
