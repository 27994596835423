import { IApiRequest, IOrderItemWithChanges } from '../../types';
import { Reducer } from 'redux';
import {
  GET_ORDER_ITEMS_WITH_CHANGES_ERROR,
  GET_ORDER_ITEMS_WITH_CHANGES_REQUEST,
  GET_ORDER_ITEMS_WITH_CHANGES_RESPONSE,
} from '../../actions/Orders/orderItemsWithChangesAction';

const initialState: IApiRequest<IOrderItemWithChanges[]> = {
  data: [],
  error: null,
  UpdatedAt: null,
  loading: false,
};

const orderItemsWithChangesReducer: Reducer<IApiRequest<IOrderItemWithChanges[]>> =
  (state: IApiRequest<IOrderItemWithChanges[]> = initialState, action: any) => {
    switch (action.type) {
      case GET_ORDER_ITEMS_WITH_CHANGES_REQUEST: {
        return {
          ...state,
          loading: true,
        };
      }
      case GET_ORDER_ITEMS_WITH_CHANGES_RESPONSE: {
        return {
          ...state,
          data: action.response,
          loading: false,
        };
      }
      case GET_ORDER_ITEMS_WITH_CHANGES_ERROR: {
        return {
          ...initialState,
          error: 'Unable to retrive order items with price changes.',
        };
      }
      default: {
        return state;
      }
    }
  };

export default orderItemsWithChangesReducer;
