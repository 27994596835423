import Fetch from '../apiConfig/apiConfig';
import { AxiosPromise } from 'axios';
import { FolderType, IDocumentBlob } from '../../types';

export interface IDownloadDocumentBlobParams {
  retailerId: number;
  documentId: number;
  prefix: string;
  userAcceptance: boolean;
  folderType: FolderType;
}

export interface IDownloadDocumentBlobResponse {
  document: IDocumentBlob;
}

export const downloadDocumentBlob = (params: IDownloadDocumentBlobParams): AxiosPromise<IDownloadDocumentBlobResponse> => {
  return Fetch().then(axios =>
    axios.get(`${params.prefix}documents/blob?retailerId=${params.retailerId}`
      + `&documentId=${params.documentId}&userAcceptance=${params.userAcceptance}&folderType=${params.folderType}`));
};
