import { IApiRequest, IBasket } from '../../types';
import { Reducer } from 'redux';
import {
  GET_ORDER_BY_ORDERNUMBER_ERROR,
  GET_ORDER_BY_ORDERNUMBER_REQUEST,
  GET_ORDER_BY_ORDERNUMBER_RESPONSE,
} from '../../actions/Orders/orderLookupAction';

const initialState: IApiRequest<IBasket> = {
  data: null,
  error: null,
  UpdatedAt: null,
  loading: false,
};

const orderLookupReducer: Reducer<IApiRequest<IBasket>> =
  (state: IApiRequest<IBasket> = initialState, action: any) => {
    switch (action.type) {
      case GET_ORDER_BY_ORDERNUMBER_REQUEST: {
        return {
          ...state,
          loading: true,
        };
      }
      case GET_ORDER_BY_ORDERNUMBER_RESPONSE: {
        return {
          ...state,
          data: action.response,
          loading: false,
        };
      }
      case GET_ORDER_BY_ORDERNUMBER_ERROR: {
        return {
          ...initialState,
          error: 'Unable to retrive order.',
        };
      }
      default: {
        return state;
      }
    }
  };

export default orderLookupReducer;
