import Axios, { AxiosInstance, AxiosResponse } from 'axios';
import History from '../../routing/History';
import { toastr } from 'react-redux-toastr';
import IApiLocatorResponse from './IApiLocatorResponse';
import IFetchConfiguration from './IFetchConfiguration';

const configureFetch = (
  { interceptIgnoreStatusErrorCodes = [] }: IFetchConfiguration = {},
) : Promise<AxiosInstance> => {

  let baseUrl = sessionStorage.getItem('settings.apiUrl');
  const token = localStorage.getItem('token');

  const headers : any = {
    'Access-Control-Allow-Origin': '*',
    'Content-Type': 'application/json',
    'Cache-Control': 'no-cache',
    Pragma: 'no-cache',
  };

  if (token !== null && token !== '') {
    const key : (keyof any) = 'Authorization';
    headers[key] = `Bearer ${token}`;
  }

  if (baseUrl === null) {

    const apiLocatorUrl = 'https://apilocator.orderly.io/api/';
    const apiLocator : AxiosInstance = Axios.create({
      headers: {
        'Access-Control-Allow-Origin': '*',
      },
      baseURL: apiLocatorUrl,
    });

    return apiLocator
      .get<IApiLocatorResponse>(`locate/${window.location.hostname}`)
        .then((response: AxiosResponse<IApiLocatorResponse>) => {
          baseUrl = response.data.apiLocation;
          sessionStorage.setItem('settings.apiUrl', baseUrl);
          sessionStorage.setItem('settings.apiConfig', JSON.stringify(response.data.configSettings));

          const inst = Axios.create({
            headers,
            baseURL: baseUrl,
          });

          inst.interceptors.response.use(
          (response: AxiosResponse<any>) => {
            return response;
          },
          (error: any) => {
            if (error.response.status === 401) {
              History.push(`${ window.location.pathname.indexOf('/admin') === 0 ? '/admin' : ''}/login`);
            }
            return error;
          });

          return inst;
        });
  }
    // Return as a promise to keep the same type as above

  return new Promise((resolve) => {
    const inst = Axios.create({
      headers,
      baseURL: baseUrl,
    });

    inst.interceptors.response.use(
    (response: AxiosResponse<any>) => {
      return response;
    },
    (error: any) => {

      if (interceptIgnoreStatusErrorCodes &&
        !interceptIgnoreStatusErrorCodes.includes(error.response.status)) {

        if (error.response.status === 401) {
          History.push(`${ window.location.pathname.indexOf('/admin') === 0 ? '/admin' : ''}/login`);
        } else {
          const errorMessage = `There was an error processing your request.
          Please try again or contact support if this persists.`;

          toastr.error(
            'Request error',
            errorMessage);
        }
      }

      return error;
    });

    resolve(inst);
  });
};

export default (config?: IFetchConfiguration) => {
  return configureFetch(config);
};
