import { AxiosPromise } from 'axios';
import Fetch from '../apiConfig/apiConfig';
import { IStockAllocationResponse } from './IStockAllocationResponse';
import { IStockAllocation } from '../../types';

export interface IEditStockAllocationParams {
  order: IStockAllocation;
}

export interface ILockInOrderParams {
  order: IStockAllocation;
}

export const getStockAllocationApi = (): AxiosPromise<IStockAllocationResponse> => {
  return Fetch().then(axios => axios.get('orders/stockallocation/get'));
};

export const editStockAllocationApi = (params: IEditStockAllocationParams): AxiosPromise<void> => {
  return Fetch().then(axios => axios.post('orders/stockallocation/edit', params));
};

export const lockInOrderApi = (params: ILockInOrderParams): AxiosPromise<void> => {
  return Fetch().then(axios => axios.post('orders/stockallocation/lockin', params));
};
