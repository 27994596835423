import { Action } from 'redux';
import { IFolderContents } from '../../types';
import { IUpdateFolderParams, updateFolder } from '../../api/Documents/updateFolderApi';
import { AxiosResponse } from 'axios';

export const UPDATE_FOLDER_REQUEST = 'UPDATE_FOLDER_REQUEST';
export type UPDATE_FOLDER_REQUEST = typeof UPDATE_FOLDER_REQUEST;

export const UPDATE_FOLDER_RESPONSE = 'UPDATE_FOLDER_RESPONSE';
export type UPDATE_FOLDER_RESPONSE = typeof UPDATE_FOLDER_RESPONSE;

export const UPDATE_FOLDER_ERROR = 'UPDATE_FOLDER_ERROR';
export type UPDATE_FOLDER_ERROR = typeof UPDATE_FOLDER_ERROR;

export interface IGetFolderContentsResponse extends Action {
  type: UPDATE_FOLDER_RESPONSE;
  response: IFolderContents;
}

export interface IGetFolderContentsError extends Action {
  type: UPDATE_FOLDER_ERROR;
  error?: string;
}

export const updateFolderRequest =
 (parameters: IUpdateFolderParams,
  onSuccess: () => void,
  onFailure: () => void): any => {
   return (dispatch : any) => {

     dispatch({
       type: UPDATE_FOLDER_REQUEST,
     });

     return updateFolder(parameters)
      .then((response: AxiosResponse) => {
        if (response.status === 200) {
          dispatch({
            type: UPDATE_FOLDER_RESPONSE,
            payload: parameters,
          });

          onSuccess();
        } else {
          onError(dispatch);
          onFailure();
        }
      })
      .catch((err: any) => {
        console.log(err);
        onError(dispatch);
        onFailure();
      });
   };
 };

const onError = (dispatch: any) => dispatch({
  type: UPDATE_FOLDER_ERROR,
});
