import { Action } from 'redux';
import { IFolderContents } from '../../types';
import { AxiosResponse } from 'axios';
import { IDeleteFolderParams, IDeleteFolderResponse, deleteFolderApi } from '../../api/Documents/deleteFolderApi';

export const DELETE_FOLDER_REQUEST = 'DELETE_FOLDER_REQUEST';
export type DELETE_FOLDER_REQUEST = typeof DELETE_FOLDER_REQUEST;

export const DELETE_FOLDER_RESPONSE = 'DELETE_FOLDER_RESPONSE';
export type DELETE_FOLDER_RESPONSE = typeof DELETE_FOLDER_RESPONSE;

export const DELETE_FOLDER_ERROR = 'DELETE_FOLDER_ERROR';
export type DELETE_FOLDER_ERROR = typeof DELETE_FOLDER_ERROR;

export interface IDeleteDocumentResponse extends Action {
  type: DELETE_FOLDER_RESPONSE;
  response: IFolderContents;
}

export interface IDeleteDocumentError extends Action {
  type: DELETE_FOLDER_ERROR;
  error?: string;
}

export const deleteFolderRequest =
 (parameters: IDeleteFolderParams,
  onSuccess: () => void,
  onFailure: () => void): any => {
   return (dispatch : any) => {

     dispatch({
       type: DELETE_FOLDER_REQUEST,
     });

     return deleteFolderApi(parameters)
      .then((response: AxiosResponse<IDeleteFolderResponse>) => {
        if (response.status === 200) {
          dispatch({
            type: DELETE_FOLDER_RESPONSE,
            response: response.data.document,
          });
          onSuccess();
        } else {
          onError(dispatch);
          onFailure();
        }
      })
      .catch((err: any) => {
        console.log(err);
        onError(dispatch);
        onFailure();
      });
   };
 };

const onError = (dispatch: any) => dispatch({
  type: DELETE_FOLDER_ERROR,
});
