import Fetch from '../apiConfig/apiConfig';
import { IBanner } from '../../types';

interface IBannersResponse {
  banners: IBanner[];
}

interface IBannerResponse {
  banner: IBanner;
}

export const getAdminBanners = async (): Promise<IBanner[]> => {
  const axios = await Fetch();
  const { data } = await axios.get<IBannersResponse>('banners/list');

  return data.banners;
};

export const getBanners = async (): Promise<IBanner[]> => {
  const axios = await Fetch();
  const { data } = await axios.get<IBannersResponse>('banners/get');

  return data.banners;
};

export const getBanner = async (id: number): Promise<IBanner> => {
  const axios = await Fetch();
  const { data } = await axios.get<IBannerResponse>(`banner/get?id=${id}`);

  return data.banner;
};

export interface ISaveBannerBody {
  id: number;
  title: string;
  description: string;
  startDate: string;
  endDate: string;
  allRetailers: boolean;
  order: number;
  displayLocation: number;
  customerGroups: string[];
  link: string;
  duration: number;
  data: string;
}

export const saveBanner = async (body: ISaveBannerBody): Promise<IBanner> => {
  const axios = await Fetch();
  const { data } = await axios.post<IBannerResponse>('banner/save', body);

  return data.banner;
};

export const deleteBanner = async (id: number): Promise<void> => {
  const axios = await Fetch();
  await axios.post('/api/banner/delete', { id });
};
