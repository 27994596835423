import { AnyAction, Reducer } from 'redux';
import {
  GET_TRADING_PACKS_REQUEST,
  GET_TRADING_PACKS_RESPONSE,
  GET_TRADING_PACKS_ERROR,
  DELETE_TRADING_PACKS_RESPONSE,
} from '../../actions/Products';
import { IApiRequest, ITradingPack } from '../../types';

const initialState: IApiRequest<ITradingPack[]> = {
  data: [],
  error: '',
  UpdatedAt: null,
  loading: false,
};

const tradingPacksReducer: Reducer<IApiRequest<ITradingPack[]>> = (
  state: IApiRequest<ITradingPack[]> = initialState,
  action: AnyAction,
) => {
  switch (action.type) {
    case GET_TRADING_PACKS_REQUEST: {
      return {
        data: [],
        error: '',
        UpdatedAt: null,
        loading: true,
      };
    }
    case DELETE_TRADING_PACKS_RESPONSE:
    case GET_TRADING_PACKS_RESPONSE: {
      return {
        data: action.response,
        error: '',
        UpdatedAt: new Date(),
        loading: false,
      };
    }
    case GET_TRADING_PACKS_ERROR: {
      return {
        ...initialState,
        error: 'An error has occured',
      };
    }
    default: {
      return state;
    }
  }
};

export default tradingPacksReducer;
