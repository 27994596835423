import Fetch from '../apiConfig/apiConfig';
import { AxiosPromise } from 'axios';
import { FolderType, IFolder } from '../../types';

export interface IEnsureFolderPathParams {
  retailerId: number;
  folderPath: string;
  folderType: FolderType;
  expiryDate?: Date;
  retailerSubTypes: number[];
  customerGroups?: string[];
}

export interface IEnsureFolderPathResponse {
  ensurePath: IFolder;
}

export const ensurePath = (params: IEnsureFolderPathParams) : AxiosPromise<IEnsureFolderPathResponse> => {
  return Fetch().then(axios => axios.post('documents/ensure-path', params));
};
