import { Action } from 'redux';
import { IGetCatalogueMetaResponse, getCatalogueMeta } from '../api/catalogueMetaApi';
import { ICatalogueMeta } from '../types';
import { AxiosResponse } from 'axios';

export const GET_CATALOGUE_META_REQUEST = 'GET_CATALOGUE_META_REQUEST';
export type GET_CATALOGUE_META_REQUEST = typeof GET_CATALOGUE_META_REQUEST;
export const GET_CATALOGUE_META_RESPONSE = 'GET_CATALOGUE_META_RESPONSE';
export type GET_CATALOGUE_META_RESPONSE = typeof GET_CATALOGUE_META_RESPONSE;
export const GET_CATALOGUE_META_ERROR = 'GET_CATALOGUE_META_ERROR';
export type GET_CATALOGUE_META_ERROR = typeof GET_CATALOGUE_META_ERROR;

export interface IGetCatalogueMetaResponse extends Action {
  type: GET_CATALOGUE_META_RESPONSE;
  response: ICatalogueMeta;
}

export interface IGetCatalogueMetaError extends Action {
  type: GET_CATALOGUE_META_ERROR;
  error?: string;
}

export const getCatalogueMetaRequest = (): any => {

  return (dispatch : any) => {
    dispatch({
      type: GET_CATALOGUE_META_REQUEST,
    });

    return getCatalogueMeta()
      .then((response: AxiosResponse<IGetCatalogueMetaResponse>) => {
        if (response.status === 200) {

          dispatch(
            {
              type: GET_CATALOGUE_META_RESPONSE,
              response: response.data,
            },
          );

        } else {
          onError(dispatch);
        }
      })
      .catch((err: any) => {
        console.log(err);
        onError(dispatch);
      });
  };
};

const onError = (dispatch: any) => dispatch({
  type: GET_CATALOGUE_META_ERROR,
});
