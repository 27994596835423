import React from 'react';
import './OrderError.scss';
import { Button } from '@orderly/morrisons-component-library';
import { navigateTo } from '../../routing/Navigation';

const OrderError = () => (
  <div className="order-error-wrapper">
    <section className="order-error">
      <h1 className="error-message">There was an error displaying this order.</h1>
      <h3 className="error-detail">The order might not exist or there could have been an error retrieving the details.</h3>
      <h3 className="error-detail">Check the order number is correct and try again.</h3>
      <Button
        type="button"
        className="secondary nav-homepage"
        icon="icon-home"
        iconPos="left"
        text="Back to Orders"
        onClick={() => navigateTo('/orders')}
      />
    </section>
  </div>
);

export default OrderError;
