import { AnyAction, Reducer } from 'redux';
import {
  GET_RETAILER_PRODUCT_PRICING_EXPORT_ERROR,
  GET_RETAILER_PRODUCT_PRICING_EXPORT_REQUEST,
  GET_RETAILER_PRODUCT_PRICING_EXPORT_RESPONSE,
} from '../../actions/Products';
import { IBaseRequest } from '../../types';

const initialState: IBaseRequest = {
  error: '',
  loading: false,
};

const exportProductPricingReducer: Reducer<IBaseRequest> = (
  state: IBaseRequest = initialState,
  action: AnyAction,
) => {
  switch (action.type) {
    case GET_RETAILER_PRODUCT_PRICING_EXPORT_REQUEST: {
      return {
        error: '',
        loading: true,
      };
    }
    case GET_RETAILER_PRODUCT_PRICING_EXPORT_RESPONSE: {
      return {
        error: '',
        loading: false,
      };
    }
    case GET_RETAILER_PRODUCT_PRICING_EXPORT_ERROR: {
      return {
        ...initialState,
        error: 'An error has occured',
      };
    }
    default: {
      return state;
    }
  }
};

export default exportProductPricingReducer;
