import { AnyAction, Reducer } from 'redux';
import { IApiRequest, ITradingPack } from '../../types';
import {
  GET_CURRENT_TRADING_PACK_ERROR,
  GET_CURRENT_TRADING_PACK_REQUEST,
  GET_CURRENT_TRADING_PACK_RESPONSE,
} from '../../actions/Products/currentTradingPackAction';

const initialState: IApiRequest<ITradingPack> = {
  data: null,
  error: '',
  UpdatedAt: null,
  loading: false,
};

const currentTradingPackReducer: Reducer<IApiRequest<ITradingPack>> = (
  state: IApiRequest<ITradingPack> = initialState,
  action: AnyAction,
) => {
  switch (action.type) {
    case GET_CURRENT_TRADING_PACK_REQUEST: {
      return {
        data: null,
        error: '',
        UpdatedAt: null,
        loading: true,
      };
    }
    case GET_CURRENT_TRADING_PACK_RESPONSE: {
      return {
        data: action.response,
        error: '',
        UpdatedAt: new Date(),
        loading: false,
      };
    }
    case GET_CURRENT_TRADING_PACK_ERROR: {
      return {
        ...initialState,
        error: 'An error has occured',
      };
    }
    default: {
      return state;
    }
  }
};

export default currentTradingPackReducer;
