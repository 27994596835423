import React from 'react';
import { IProductCardInfoProps } from './IProductCardInfoProps';
import styles from './ProductCardInfo.module.scss';

function ProductCardInfo({
  value,
  description,
  strikethrough,
}: IProductCardInfoProps): JSX.Element {
  return (
    <div className={styles.container}>
      {strikethrough &&
        <span className={styles.strikethrough}>{strikethrough}</span>
      }
      <strong className={styles.value}>
        {value}
      </strong>
      <span className={styles.description}>({description})</span>
    </div>
  );
}

export default ProductCardInfo;
