import React, { useEffect, useState } from 'react';
import { navigateTo } from '../../../routing/Navigation';
import { Breadcrumbs, BreadcrumbSegment } from '@orderly/morrisons-component-library';
import FileUploadInformation from '../../../components/FileUploadInformation/FileUploadInformation';
import { IStoreState, IDocument, FolderType } from '../../../types';
import { connect } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import './DocumentsEdit.scss';
import { Dispatch } from 'redux';
import { getFolderContentsRequest } from '../../../actions/Documents/folderContentsAction';
import { IGetFolderContentsParams } from '../../../api/Documents/getFolderContentsApi';
import { IDocumentsEditProps } from './IDocumentsEditProps';
import { editDocumentRequest } from '../../../actions/Documents/editDocumentAction';
import { IEditDocumentParams } from '../../../api/Documents/IEditDocumentParams';
import { IDocumentDetailsParams } from '../../../api/Documents/IDocumentDetailsParams';
import { getDocumentDetailsRequest } from '../../../actions/Documents/getDocumentDetailsAction';
import { IGetUsersParams } from '../../../api/adminUserApi';
import { getUserPageRequest } from '../../../actions/usersAction';
import { IGetTagsParams } from '../../../api/Documents/getTags';
import { getTagsRequest } from '../../../actions/Documents/getTagsAction';
import { isPrintshopPage } from '../../../helpers/Documents/IsPrintshopPage';
import QueryString from 'query-string';
import { constructNavigationLink } from '../../../helpers/Documents/DocumentNavigationHelper';

const DocumentsUpload = (props: IDocumentsEditProps) => {
  const [name, setName] = useState('');
  const [savedName, setSavedName] = useState('');
  const [tags, setTags] = useState([]);
  const [folder, setFolder] = useState('');
  const [additionalUsers, setAdditionalUsers] = useState([]);
  const [requiresAcceptance, setRequiresAcceptance] = useState(false);

  const { match, retailers, documentDetails, folderContents, availableUsers, documentTags,
    getFolderContents, getDocumentDetails, getDocumentTags, getAvailableUsers, editDocument,
  } = props;

  useEffect(() => {
    const parentFolderPath: string = QueryString.parse(window.location.search).parent as string;
    const retailerId = isPrintshopPage() ? -1 : parseInt(match.params.retailerId, 10);

    getFolderContents({
      retailerId: isPrintshopPage() ? -1 : parseInt(match.params.retailerId, 10),
      folderPath: parentFolderPath,
      prefix: '',
      folderType: isPrintshopPage() ? FolderType.Printshop : FolderType.Document,
    });

    getAvailableUsers({
      orderBy: 'firstname',
      orderDescending: false,
      pageSize: 1000,
      retailers: [retailerId],
      roles: [-1],
      search: '',
      startIndex: 0,
    });

    const documentId = match.params.documentId;
    getDocumentDetails(
      {
        documentId: parseInt(documentId, 10),
        retailerId: isPrintshopPage() ? -1 : parseInt(match.params.retailerId, 10),
        folderType: isPrintshopPage() ? FolderType.Printshop : FolderType.Document,
      },
      (document: IDocument) => {
        setName(document.description);
        setTags(document.tags);
        setSavedName(document.description);
        setRequiresAcceptance(document.requiresAcceptance);
      },
      () => {
        toastr.error('Error', 'Unable to find document');
      });

    getDocumentTags({ retailerId });
  }, []);

  const parentIsPresent = () => {
    const parent = QueryString.parse(window.location.search).parent;
    return parent !== undefined && parent !== '';
  };

  const getFolderPath = (folder: string) => {
    let folderParam = folder;

    if (!folder) {
      return '';
    }

    if (parentIsPresent()) {
      folderParam = `${QueryString.parse(window.location.search).parent}/${folder}`;
    }

    return folderParam;
  };

  const onFolderChange = (folder: string): void => {
    getFolderContents({
      folderPath: isPrintshopPage() ? getFolderPath(folder) : folder,
      retailerId: isPrintshopPage() ? -1 : parseInt(match.params.retailerId, 10),
      prefix: '',
      folderType: isPrintshopPage() ? FolderType.Printshop : FolderType.Document,
    });

    setFolder(folder);

    if (isPrintshopPage()) {
      navigateTo(
        `/admin/printshop/edit/${match.params.documentId}?parent=${getFolderPath(folder)}`,
        );
    }
  };

  const onUserSelect = (userId: number, checked: boolean): void => {
    const included = additionalUsers.includes(userId);
    if (checked && !included) {
      additionalUsers.push(userId);
      setAdditionalUsers(additionalUsers);
    } else if (!checked && included) {
      const index = additionalUsers.indexOf(userId);
      additionalUsers.splice(index, 1);
      setAdditionalUsers(additionalUsers);
    }
  };

  const onCancel = (): void => {
    const toastrConfirmOptions = {
      onOk: () =>
        navigateTo(`/admin/${isPrintshopPage() ? 'printshop' : `documents/${parseInt(match.params.retailerId, 10)}`}`),
    };
    toastr.confirm('Are you sure you wish to discard these changes?', toastrConfirmOptions);
  };

  const onSave = (onError?: (isError: boolean) => void) => {
    const folderPath: string = isPrintshopPage() ? QueryString.parse(window.location.search).parent as string : folder;
    editDocument(
      {
        folderPath,
        requiresAcceptance,
        retailerId: isPrintshopPage() ? -1 : parseInt(match.params.retailerId, 10),
        documentId: parseInt(match.params.documentId, 10),
        description: savedName,
        tags: tags.filter(x => x !== ''),
        notificationUsers: additionalUsers,
        folderType: isPrintshopPage() ? FolderType.Printshop : FolderType.Document,
      },
      () => {
        toastr.success('Updated', 'Document updated successfully.');

        navigateTo(constructNavigationLink(match));
      },
      () => {
        toastr.error('Error', 'Unable to find document');
      });
  };

  const retailerId = isPrintshopPage() ? -1 : parseInt(match.params.retailerId, 10);
  const retailerName = isPrintshopPage() ? '' : retailers.filter(x => x.id === retailerId)[0].externalIdentifier;

  let catalogueName = '...';
  if (folderContents.data && folderContents.data.catalogue) {
    catalogueName = folderContents.data.catalogue;
  }

  const breadcrumbs: BreadcrumbSegment[] = [
    { key: 0, text: 'Admin', url: '/admin/home/' },
    { key: 1, text: isPrintshopPage() ? 'Printshop' : 'Documents', url: '/admin/retailer' },
    {
      key: 2,
      text: catalogueName,
      url: `/admin/${isPrintshopPage() ? 'printshop' : `documents/${match.params.retailerId}`}`,
    },
    {
      key: 3,
      text: 'Document Edit',
      url: `/admin/${isPrintshopPage()
        ?
        'printshop'
        :
        `documents/${match.params.retailerId}`}/edit/${match.params.documentId}`,
    },
  ];

  return (
    <div className="documents-wrapper">
      <Breadcrumbs
        onHomeClick={() => navigateTo('/admin/home')}
        onRedirect={navigateTo}
        segments={breadcrumbs}
      />
      <section className="heading-wrapper">
        <h1>Document Edit</h1>
      </section>
      <div className="content-wrapper">
        <FileUploadInformation
          files={[documentDetails.data]}
          name={name}
          savedName={savedName}
          tags={tags}
          folder={folder}
          folderList={folderContents && folderContents.data
            ? folderContents.data.folders : []}
          onNameChange={(savedName: string) => setSavedName(savedName)}
          onTagsChange={(tags: string[]) => {
            setTags(tags);
          }}
          onFolderChange={(folder: string) => onFolderChange(folder)}
          selectedFolder={folder}
          onCancel={() => onCancel()}
          onSave={onSave}
          retailerId={isPrintshopPage() ? -1 : parseInt(match.params.retailerId, 10)}
          retailerName={retailerName}
          defaultUsers={folderContents.data ? folderContents.data.users : []}
          users={availableUsers.data}
          selectedUsers={additionalUsers}
          onUserSelect={onUserSelect}
          loading={folderContents.loading}
          catalogue={catalogueName}
          requiresAcceptance={requiresAcceptance}
          onRequiresAcceptanceChange={(requiresAcceptance: boolean) => setRequiresAcceptance(requiresAcceptance)}
          allDocumentTags={documentTags.data ? documentTags.data : []}
          editMode={true}
          folderCustomerGroups={folderContents.data.customerGroups || []}
        />
      </div>
    </div>
  );
};

const mapStateToProps = (state: IStoreState) => {
  return {
    folderContents: state.documentsFolderContents,
    edittedDocument: state.documentsEditDocument,
    userDetails: state.userDetails,
    documentDetails: state.documentsDocumentDetails,
    retailers: state.retailers.data,
    availableUsers: state.users,
    documentTags: state.documentTags,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<any>) => {
  return {
    getFolderContents: (parameters: IGetFolderContentsParams) => dispatch(getFolderContentsRequest(parameters)),
    editDocument: (parameters: IEditDocumentParams,
      onSuccess: () => void,
      onFailure: (err: any) => void) => dispatch(editDocumentRequest(parameters, onSuccess, onFailure)),
    getDocumentDetails: (parameters: IDocumentDetailsParams,
      onSuccess: (document: IDocument) => void,
      onFailure: (err: any) => void) =>
      dispatch(getDocumentDetailsRequest(parameters, onSuccess, onFailure)),
    getAvailableUsers: (params: IGetUsersParams) => dispatch(getUserPageRequest(params)),
    getDocumentTags: (params: IGetTagsParams) => dispatch(getTagsRequest(params)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DocumentsUpload);
