import React, { useEffect, useState } from 'react';
import { Button, Card, Checkbox, StyledCalendar, TextInput } from '@orderly/morrisons-component-library';
import { navigateTo } from '../../../routing/Navigation';
import QueryString from 'query-string';

import './CreateFolder.scss';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { IEnsureFolderPathParams } from '../../../api/Documents/ensurePathApi';
import { ensurePathRequest } from '../../../actions/Documents/ensurePathAction';
import { FolderType, IStoreState } from '../../../types';
import { isPrintshopPage } from '../../../helpers/Documents/IsPrintshopPage';
import Modal from '../../../components/Modal/Modal';
import { ICreateFolderProps } from './ICreateFolderProps';
import { constructNavigationLink } from '../../../helpers/Documents/DocumentNavigationHelper';
import { RetailerSubTypesObject } from '../../../helpers/SupplyChain/RetailerSubTypes.enum';
import { formatShortDateString } from '../../../helpers/Format';
import { getCustomerGroupsRequest } from '../../../actions/customerGroupsAction';
import LoadingThrobber from '../../../components/LoadingThrobber/LoadingThrobber';
import { toastr } from 'react-redux-toastr';

const CreateFolder = (props: ICreateFolderProps) => {
  const [name, setName] = useState('');
  const [expiryDate, setExpiryDate] = useState(null);
  const [calendarOpened, setCalendarOpened] = useState(false);
  const [error, setError] = useState('');
  const [subTypes, setSubTypes] = useState<number[]>([]);
  const [selectedCustomerGroups, setSelectedCustomerGroups] = useState<string[]>([]);

  const { catalogueMeta, customerGroups, getCustomerGroups, ensurePath, match } = props;

  useEffect(() => {
    getCustomerGroups();
  }, [catalogueMeta]);

  const onFolderNameChanged = (name: string) => {
    const invalidRegex = /[^A-Za-z0-9., ]/;
    if (invalidRegex.test(name)) {
      setError('Must not include special characters.');
    } else if (name.length > 30) {
      setError('Maximum length 30 characters.');
    } else {
      setError('');
    }

    setName(name);
  };

  const getFolderType = () => {
    if (isPrintshopPage()) {
      return FolderType.Printshop;
    }

    if (selectedCustomerGroups.length > 0) {
      return FolderType.CustomerGroup;
    }

    return FolderType.Document;
  };

  const onSaveFolder = () => {
    if (name !== '') {
      let parentFolderPath: string = QueryString.parse(window.location.search).parent as string;
      if (!parentFolderPath.endsWith('/')) {
        parentFolderPath = `${parentFolderPath}/`;
      }
      const newFolderPath = `${parentFolderPath}${name}`;
      const retailerId = isPrintshopPage() || selectedCustomerGroups.length > 0 ? -1 : match.params.retailerId;

      ensurePath({
        retailerId,
        expiryDate,
        retailerSubTypes: subTypes,
        customerGroups: selectedCustomerGroups,
        folderPath: newFolderPath,
        folderType: getFolderType(),
      },
        () => {
          toastr.success(
            'Success',
            'Folder was created successfully.');
          navigateTo(constructNavigationLink(match));
        },
        (error: string) => {
          toastr.error('Error', error);
        });
    } else {
      toastr.error('Error', 'Folder path must not be null');
    }
  };

  const isSubTypeSelected = (subType: string) => {
    if (subType === 'All' && subTypes.length === 0) {
      return true;
    }
    return subTypes.includes(RetailerSubTypesObject[subType]);
  };

  const onSubTypeSelect = (name: string, value: boolean) => {
    if (name === 'All') {
      setSubTypes([]);
    } else {
      if (value) {
        setSubTypes([...subTypes, RetailerSubTypesObject[name]]);
      } else {
        const index = subTypes.findIndex(x => x === RetailerSubTypesObject[name]);
        const newSubTypes = [...subTypes];
        newSubTypes.splice(index, 1);
        setSubTypes(newSubTypes);
      }
    }
  };

  const onCustomerGroupSelect = (name: string, checked: boolean) => {
    if (checked) {
      setSelectedCustomerGroups([...selectedCustomerGroups, name]);
    } else {
      const index = selectedCustomerGroups.findIndex(x => x === name);
      const newCustomerGroups = [...selectedCustomerGroups];
      newCustomerGroups.splice(index, 1);
      setSelectedCustomerGroups(newCustomerGroups);
    }
  };

  return (
    <div className="outer-folder">
      {customerGroups.loading && <LoadingThrobber />}
      <div className="folder">
        <Card className="upload-form">
          <h3>Folder Information</h3>
          <TextInput
            type="text"
            name="folderName"
            label="Folder Name"
            value={name}
            onChange={(name: string) => { onFolderNameChanged(name); }}
            error={error}
          />
          {
            isPrintshopPage() &&
            <div className="subTypes-checklist">
              <h4>Customer type visibility</h4>
              {
                Object.keys(RetailerSubTypesObject).map((x) => {
                  return (
                    <Checkbox
                      key={x}
                      checked={isSubTypeSelected(x)}
                      label={x}
                      name={x}
                      onChange={(id, checked) => onSubTypeSelect(id, checked)}
                      isSmall={true}
                    />
                  );
                })
              }
            </div>
          }
          {
            !isPrintshopPage() && customerGroups.data && customerGroups.data.length > 0 &&
            <div className="subTypes-checklist">
              <h4>Customer Group visibility</h4>
              {
                customerGroups.data.map((x) => {
                  return (
                    <Checkbox
                      key={x}
                      checked={selectedCustomerGroups.includes(x)}
                      label={x}
                      name={x}
                      onChange={(id, checked) => onCustomerGroupSelect(id, checked)}
                      isSmall={true}
                    />
                  );
                })
              }
            </div>
          }
          <div className="create-folder actions save-controls">
            <div className="expiry-date-wrapper">
              {
                isPrintshopPage() &&
                <>
                  <Button
                    className={`expiry-date-btn ${expiryDate ? 'populated' : ''}`}
                    type="button"
                    text={expiryDate ? `Exp: ${formatShortDateString(expiryDate)}` : 'Set expiry date'}
                    onClick={() => setCalendarOpened(true)}
                  />
                  {expiryDate && <div className="unset-expiry-date" onClick={() => setExpiryDate(null)}>x</div>}
                </>
              }
            </div>
            <div className="create-folder-action-buttons">
              <Button
                type="button"
                className="create-folder-action-cancel"
                text="Cancel"
                onClick={() => navigateTo(constructNavigationLink(match))}
              />
              <Button
                type="button"
                className="create-folder-action-create-new-folder"
                text="Create New Folder"
                onClick={() => { onSaveFolder(); }}
                disabled={error.length > 0 || name.length <= 0}
              />
            </div>
          </div>
        </Card>
      </div>
      <Modal
        header="Folder expiry date"
        isOpen={calendarOpened}
        onClose={() => setCalendarOpened(false)}
      >
        <StyledCalendar
          value={expiryDate}
          minDate={new Date()}
          onChange={date => setExpiryDate(date as Date)}
          selectRange={false}
        />
      </Modal>
    </div>
  );
};

const mapStateToProps = (state: IStoreState) => {
  return {
    customerGroups: state.customerGroups,
    documentsEnsurePath: state.documentsEnsurePath,
    catalogueMeta: state.catalogueMeta,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<any>) => {
  return {
    getCustomerGroups: () => dispatch(getCustomerGroupsRequest()),
    ensurePath: (parameters: IEnsureFolderPathParams,
      onSuccess: () => void,
      onFailure: (error: string) => void) => dispatch(ensurePathRequest(parameters, onSuccess, onFailure)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateFolder);
