import Fetch from '../apiConfig/apiConfig';
import { AxiosPromise, AxiosInstance } from 'axios';
import { IGetRetailersResponse } from './IGetRetailersResponse';
import { IGetRetailerDetailsResponse } from './IGetRetailerDetailsResponse';
import { IGetOrdersParams } from './IGetOrdersParams';
import { IGetOrdersResponse } from './IGetOrdersResponse';
import { IGetOrdersDeliveryDetails } from './IGetOrdersDeliveryDetails';
import { IUpdateRetailerMetaParams } from './IUpdateRetailerMetaParams';
import { IUpdateRetailersTenantsParams } from './IUpdateRetailersTenantsParams';

export const getRetailers = () : AxiosPromise<IGetRetailersResponse> => {
  return Fetch().then(axios => axios.get('supplychain/retailers'));
};

export const getRetailerDetails = () : AxiosPromise<IGetRetailerDetailsResponse> => {
  return Fetch().then(axios => axios.get('supplychain/retailer/detail'));
};

export const updateRetailerMeta = (params: IUpdateRetailerMetaParams): AxiosPromise => {
  return Fetch().then(axios => axios.post('supplychain/retailer/update-meta', params));
};

export const getOrders = (params: IGetOrdersParams): AxiosPromise<IGetOrdersResponse> => {
  return Fetch().then((axios: AxiosInstance): AxiosPromise<IGetOrdersResponse> => {
    return axios.get(
      `orders/list?retailer=${params.retailer}` +
      `&key=${params.key ? params.key : 'customerorderRaisedDate'}&value=${params.retailer}${params.date}`,
    );
  });
};

export const getOrderDeliveryDetails = (params: string[]): AxiosPromise<IGetOrdersDeliveryDetails> => {
  return Fetch().then((axios: AxiosInstance): AxiosPromise<IGetOrdersDeliveryDetails> =>
    axios.post('orders/delivery-details', { orderNumbers: params }),
  );
};

export const updateTenants = (params: IUpdateRetailersTenantsParams): AxiosPromise => {
  return Fetch().then(axios => axios.post('users/update-tenant', params));
};
