import React, { Dispatch, useEffect, useState } from 'react';
import { navigateTo } from '../../../routing/Navigation';
import { Card, Breadcrumbs, BreadcrumbSegment, Button, TextInput } from '@orderly/morrisons-component-library';
import QueryString from 'query-string';
import Table from '../../../components/Table/Table';
import { connect } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import './Printshop.scss';
import { IStoreState, IDocument, IDocumentBlob, FolderType } from '../../../types';
import { IGetFolderContentsParams } from '../../../api/Documents/getFolderContentsApi';
import { getFolderContentsRequest, clearFolderContents } from '../../../actions/Documents/folderContentsAction';
import { IDownloadDocumentBlobParams } from '../../../api/Documents/downloadDocumentBlobApi';
import { downloadDocumentBlobRequest } from '../../../actions/Documents/downloadDocumentBlobAction';
import { IDeleteDocumentParams } from '../../../api/Documents/deleteDocumentApi';
import { deleteDocumentRequest } from '../../../actions/Documents/deleteDocumentAction';
import { getFileIcon } from '../../../helpers/Documents/DocumentUploadHelper';
import { isAdmin } from '../../../helpers/Users/UserHelper';
import { IPrintshopProps } from './IPrintshopProps';
import { IPopupPropsConfigContentAction } from '../../../components/popup/interfaces/IPopupPropsConfigContentAction';
import EmptyDocumentsCard from '../../../components/EmptyDocumentsCard/EmptyDocumentsCard';
import { getRetailersRequest } from '../../../actions/retailersAction';
import LoadingThrobber from '../../../components/LoadingThrobber/LoadingThrobber';
import { IUserAcceptanceParams } from '../../../api/Reports/ConfirmedOrders/IUserAcceptanceParams';
import {
  getUserAcceptanceReportRequest,
  resetUserAcceptanceReportData,
} from '../../../actions/Reports/userAcceptanceAction';
import { GenerateDownload } from '../../../helpers/DownloadHelper';
import { IDeleteFolderParams } from '../../../api/Documents/deleteFolderApi';
import { deleteFolderRequest } from '../../../actions/Documents/deleteFolderAction';
import { formatShortDateString } from '../../../helpers/Format';

const Printshop = (props: IPrintshopProps) => {
  const [search, setSearch] = useState<string>('');

  const {
    getRetailers,
    getFolderContents,
    userAcceptanceCsvDownload,
    retailers,
    folderContents,
    userDetails,
    downloadDocument,
    downloadUserAcceptanceCsv,
    clearUserAcceptanceCsvDownload,
  } = props;

  const { data, loading } = folderContents;
  const { folders, documents, parentFolderId } = data;

  const loadData = () => {
    const queryString = QueryString.parse(window.location.search);
    const folderPath = queryString.folder as string || '';
    getFolderContents(
      {
        folderPath,
        retailerId: -1,
        prefix: window.location.pathname.indexOf('/admin') === 0 ? 'admin/' : '',
        folderType: FolderType.Printshop,
      });
  };

  const getFilteredDocuments = (search: string) => {
    let filteredDocs: IDocument[] = [];

    if (documents.length > 0) {
      filteredDocs = documents.filter((document: IDocument) => {
        return document.description.toLowerCase().indexOf(search.toLowerCase()) > -1;
      });
    }

    return filteredDocs;
  };

  useEffect(() => {
    getRetailers();
    clearFolderContents();
    loadData();
  }, []);

  useEffect(() => {
    if (userAcceptanceCsvDownload.data) {
      const { filename, content } = userAcceptanceCsvDownload.data;
      generateDownload(filename, content);
    }
  }, [userAcceptanceCsvDownload]);

  useEffect(() => {
    getFilteredDocuments(search);
  }, [search]);

  useEffect(() => {
    if (folderContents.error) {
      navigateTo('/admin/printshop');
      loadData();
    }
  }, [folderContents.error]);

  if (retailers.loading) {
    return <LoadingThrobber />;
  }

  const editFile = (documentId: number) => {
    const folder = QueryString.parse(window.location.search).folder;
    navigateTo(`/admin/printshop/edit/${documentId}?parent=${folder ? folder : ''}`);
  };

  const deleteDocument = (documentId: number) => {
    const toastrConfirmOptions = {
      onOk: () => {
        props.deleteDocument(
          {
            documentId,
          },
          () => {
            toastr.success(
              'Success',
              'File has been deleted.');
            loadData();
          },
          () => {
            toastr.success(
              'Success',
              'File has been deleted.');
            loadData();
          },
        );
      },
    };
    toastr.confirm('Are you sure you wish to delete this file?', toastrConfirmOptions);
  };

  const deleteFolder = (folderId: number) => {
    const toastrConfirmOptions = {
      onOk: () => {
        props.deleteFolder(
          {
            folderId,
          },
          () => {
            toastr.success(
              'Success',
              'Folder and its contents have been deleted.');
            navigateTo('/admin/printshop');
            loadData();
          },
          () => {
            toastr.error(
              'Failure',
              'There was an error deleting the folder. Please try again.');
            navigateTo('/admin/printshop');
            loadData();
          },
        );
      },
    };
    toastr.confirm('Are you sure you wish to delete this folder and its contents?', toastrConfirmOptions);
  };

  const folderIsPresent = () => {
    const folder = QueryString.parse(window.location.search).folder;
    return folder !== undefined && folder !== '';
  };

  const getBreadcrumbs = () => {
    const queryString = QueryString.parse(window.location.search);
    const folderPath = queryString.folder as string;

    const breadcrumbs: BreadcrumbSegment[] = [
      { key: 0, text: 'Admin', url: '/admin/home/' },
      { key: 1, text: 'Printshop', url: '/admin/printshop' },
    ];

    if (folderPath) {
      const folderParts = folderPath.split('/');

      for (let i = 0; i < folderParts.length; i++) {
        let url = `/admin/printshop?folder=${folderParts[0]}`;
        for (let j = 1; j <= i; j++) {
          url = `${url}/${folderParts[j]}`;
        }
        breadcrumbs.push({ url, key: i + 3, text: folderParts[i] });
      }
    }

    return breadcrumbs;
  };

  const navigateToFolder = (folder: string) => {
    let folderParam = folder;
    if (folderIsPresent()) {
      folderParam = `${QueryString.parse(window.location.search).folder}/${folder}`;
    }

    navigateTo(`/admin/printshop?folder=${folderParam}`);
    loadData();
  };

  const downloadFile = (fileId: number) => {
    downloadDocument(
      {
        retailerId: -1,
        documentId: fileId,
        prefix: 'admin/',
        userAcceptance: false,
        folderType: FolderType.Printshop,
      },
      (data: IDocumentBlob) => {
        generateDownload(data.fileName, data.data);
      },
      () => {
        alert('Failure');
      });
  };

  const downloadUserAcceptanceReport = async (documentId: number) => {
    await downloadUserAcceptanceCsv(
      {
        documentId,
        retailerId: -1,
      });
  };

  const generateDownload = (filename: string, contents: string) => {
    GenerateDownload(contents, filename);
    clearUserAcceptanceCsvDownload();
  };

  const setPopupConfig = (document: IDocument) => {
    return {
      content: {
        actions: [
          ...(document.requiresAcceptance
            ? [{
              onClick: async (): Promise<void> => {
                await downloadUserAcceptanceReport(document.documentId);
              },
              label: {
                icon: 'stats-bars',
                text: 'User Acceptance Report',
              },
            }]
            : []),
          {
            onClick: (): void => {
              editFile(document.documentId);
            },
            label: {
              icon: '',
              text: 'Edit',
            },
          },
          {
            onClick: (): void => {
              downloadFile(document.documentId);
            },
            label: {
              icon: '',
              text: 'Download',
            },
          },
          {
            onClick: (): void => {
              deleteDocument(document.documentId);
            },
            label: {
              icon: '',
              text: 'Delete',
            },
          },
        ],
      },
    };
  };

  const breadcrumbs = getBreadcrumbs();

  return (
    <div className="printshop-container">
      <Breadcrumbs
        className="printshop-breadCrumbs"
        onHomeClick={() => navigateTo('/admin/home')}
        onRedirect={(route: string) => {
          navigateTo(route);
          loadData();
        }}
        segments={breadcrumbs}
      />
      <section className="printshop-heading-section">
        <h1 className="printshop-heading">PRINTSHOP</h1>
        <div className="printshop-heading-actions">
          {
            folderIsPresent() && !parentFolderId &&
            <Button
              type="button"
              className="printshop-action-create-folder"
              text="Create Subfolder"
              onClick={
                () => navigateTo('printshop/create-folder?'
                  + `parent=${QueryString.parse(window.location.search).folder
                    ? QueryString.parse(window.location.search).folder : ''}`)}
            />
          }
          {
            folderIsPresent() &&
            <Button
              type="button"
              className="printshop-action-create-folder"
              text="Update Folder"
              onClick={
                () => navigateTo('printshop/update-folder?'
                  + `name=${QueryString.parse(window.location.search).folder
                    ? QueryString.parse(window.location.search).folder : ''}`)}
            />
          }
          {
            folderIsPresent() && parentFolderId &&
            <Button
              type="button"
              className="printshop-action-upload-document"
              text="Upload Documents"
              onClick={
                () => navigateTo(`printshop/upload?parent=${QueryString.parse(window.location.search).folder
                  ? QueryString.parse(window.location.search).folder : ''}`)}
            />
          }
          {
            folderIsPresent() && parentFolderId &&
            <Button
              type="button"
              className="printshop-action-delete-folder"
              text="Delete Folder"
              onClick={() => deleteFolder(data.folderId)}
            />
          }
        </div>
      </section>
      {
        loading && <LoadingThrobber />
      }
      <div className="printshop-content-container">
        <section className="printshop-filters-section">
          <Card className="printshop-card">
            <h3 className="printshop-filters-heading printshop-filter-by-heading">FILTER</h3>
            <TextInput
              className="search"
              name="documentSearch"
              type="text"
              label="Filter by name"
              value={search}
              onChange={(e: any) => setSearch(e)}
            />
          </Card>
          {
            folderContents.data && folderContents.data.expiryDate &&
            <Card className="printshop-card">
              <h3 className="printshop-filters-heading printshop-filter-by-heading">FOLDER EXPIRY DATE</h3>
              <div className="printshop-folder-expiry">{formatShortDateString(folderContents.data.expiryDate)}</div>
            </Card>
          }
        </section>
        <div className="printshop-folders-contents-container">
          {
            folders.length > 0 &&
            <Card className="printshop-card printshop-base-folders-content">
              {
                folders.filter(x =>
                  x.description.toLowerCase().indexOf(search.toLowerCase()) > -1)
                  .map((baseFolder) => {
                    return (
                      <div
                        key={baseFolder.folderId}
                        title="Click to browse"
                        className="base-folder"
                        onClick={() => navigateToFolder(baseFolder.description)}
                      >
                        <span className={'icon icon-folder'} />
                        <span className="base-folder-description">{baseFolder.description}</span>
                      </div>
                    );
                  })
              }
            </Card>
          }
          {
            folderIsPresent() && folders.length <= 0 && folderContents.data.documents.length <= 0 &&
            <EmptyDocumentsCard
              text={parentFolderId ? 'Upload documents' : 'Create Subfolder'}
              url={parentFolderId
                ?
                'printshop/upload?'
                + `parent=${QueryString.parse(window.location.search).folder
                  ? QueryString.parse(window.location.search).folder : ''}`
                :
                'printshop/create-folder?'
                + `parent=${QueryString.parse(window.location.search).folder
                  ? QueryString.parse(window.location.search).folder : ''}`}
              isAdmin={isAdmin(userDetails)}
              icon={parentFolderId ? 'icon-text-file' : 'icon-folder'}
            />
          }
          {
            getFilteredDocuments(search) &&
            getFilteredDocuments(search).length > 0 &&
            <Table className="printshop-list">
              <thead>
                <tr>
                  <th className="Table--sticky-column">Document Name</th>
                  <th>Badges</th>
                  <th className="Table--fixed-column">Last Modified</th>
                  <th className="Table--fixed-column">Owner</th>
                  <th className="Table--fixed-column table-actions">Actions</th>
                </tr>
              </thead>
              <tbody>
                {getFilteredDocuments(search)
                  .map((document: IDocument) => {
                    return (
                      <tr key={document.documentId}>
                        <td className="Table--sticky-column">
                          <span className={`icon ${getFileIcon(document.mimeType).className}`} />{document.description}
                        </td>
                        <td>{document.tags.join(', ')}</td>
                        <td>{formatShortDateString(document.lastEditDate)}</td>
                        <td>
                          {document.createdByUser &&
                            `${document.createdByUser.firstname} ${document.createdByUser.lastname}`}
                        </td>
                        <td className="Table--actions">
                          {
                            setPopupConfig(document).content.actions
                              .map((action: IPopupPropsConfigContentAction, index: number): JSX.Element => {
                                return (
                                  <div className="table-buttons" key={index} >
                                    <button
                                      className="Popup-button"
                                      name="popupButton"
                                      onClick={
                                        (): void => {
                                          action.onClick();
                                        }
                                      }
                                    >
                                      {<span
                                        className={`Popup-button-icon icon-${action.label.icon || action.label.text}`}
                                        title={action.label.text}
                                      />
                                      }
                                    </button>
                                  </div>
                                );
                              },
                              )
                          }
                        </td>
                      </tr>
                    );
                  })
                }
              </tbody>
            </Table>
          }
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state: IStoreState) => {
  return {
    folderContents: state.documentsFolderContents,
    downloadedDocument: state.documentsDownloadBlob,
    userDetails: state.userDetails,
    deletedDocument: state.documentsDeleteDocument,
    retailers: state.retailers,
    userAcceptanceCsvDownload: state.userAcceptance,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<any>) => {
  return {
    getFolderContents: (parameters: IGetFolderContentsParams) => dispatch(getFolderContentsRequest(parameters)),
    downloadDocument: (parameters: IDownloadDocumentBlobParams, onSuccess: (data: IDocumentBlob) => void,
      onFailure: () => void) => dispatch(downloadDocumentBlobRequest(parameters, onSuccess, onFailure)),
    deleteDocument: (parameters: IDeleteDocumentParams, onSuccess: () => void, onFailure: () => void) =>
      dispatch(deleteDocumentRequest(parameters, onSuccess, onFailure)),
    deleteFolder: (parameters: IDeleteFolderParams, onSuccess: () => void, onFailure: () => void) =>
      dispatch(deleteFolderRequest(parameters, onSuccess, onFailure)),
    clearFolderContents: () => dispatch(clearFolderContents()),
    getRetailers: () => dispatch(getRetailersRequest()),
    downloadUserAcceptanceCsv: (parameters: IUserAcceptanceParams) => dispatch(getUserAcceptanceReportRequest(parameters)),
    clearUserAcceptanceCsvDownload: () => dispatch(resetUserAcceptanceReportData()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Printshop);
