import React, { useState, useEffect, useRef, useCallback, SyntheticEvent } from 'react';
import './Popup.scss';
import { IPopupProps } from './interfaces/IPopupProps';
import ReactDOM from 'react-dom';
import { IPopupPropsConfigContentAction } from './interfaces/IPopupPropsConfigContentAction';

const Popup = (
    {
      className,
      config,
      show,
      targetElement,
      onOpen,
      isRelative,
      onClose,
      uniqueKey,
      close,
    }: IPopupProps,
): any => {
  const [popupTemplate, setPopupTemplate] = useState(null);
  const instance = useRef(null);

  const onOutsideClick = (e: any): void => {
    const isPopupActionClick: Node = e.target
        && e.target.parentNode
        && e.target.parentNode.classList.contains('Popup-button');

    if ((e.target.id !== targetElement.id) && !isPopupActionClick) {
      close(targetElement);
    }
  };

  const registerOnOutsideClick = (): void => {
    window.addEventListener('click', onOutsideClick, true);
  };

  const removeOnOutsideClick = (): void => {
    window.removeEventListener('click', onOutsideClick, true);
  };

  const dismissOnScroll = (): void => {
    const popupContainer: HTMLElement = document.getElementsByClassName('Popup-container')[0] as HTMLElement;

    if (popupContainer) {
      close(targetElement);
    }

  };

  const registerOnScroll = (): void => {
    window.addEventListener('scroll', dismissOnScroll, true);
  };

  const removeScrollEvent = (): void => {
    window.removeEventListener('scroll', dismissOnScroll, true);
  };

  useEffect(
    (): any => {
      extractTargetElementBCR(targetElement);

      if (show) {
        registerOnOutsideClick();
        registerOnScroll();
        if (onOpen && onOpen instanceof Function) {
          onOpen({ isOpen: true });
        }
      } else {
        if (onClose && onClose instanceof Function) {
          onClose({ isOpen: false });
        }
      }

      return (): void => {
        removeOnOutsideClick();
        removeScrollEvent();
      };
    },
    [show],
  );

  const template: JSX.Element = (
    <div className={`Popup-content ${className ? className : ''}`}>
      { config &&
        config.content.actions.map(
            (action: IPopupPropsConfigContentAction, index: number): JSX.Element => {
              return (
                  <div className="Popup-buttonContainer" key={index} >
                    <button
                        className="Popup-button"
                        name="popupButton"
                        onClick={
                          (): void => {
                            action.onClick();
                          }
                        }
                    >
                      {/* <span
                          className={`Popup-buttonIcon icon-${action.label.text}`}
                      />
                      { */}
                        <span className="Popup-buttonText">{action.label.text}</span>
                    </button>
                  </div>
              );
            },
        )
      }
      {
        !config &&
        (
            <div className="popup-emptyPlaceHolder">
              <span>No config provided</span>
            </div>
        )
      }
    </div>
  );

  const extractTargetElementBCR: any = useCallback(
      (element: Element): void => {
        if (element) {
          const elementBCR: ClientRect | DOMRect = element.getBoundingClientRect();
          const { bottom, left, width } = elementBCR;
          const styles = { width, left, top: bottom };
          const setTemplate: JSX.Element = applyContainerPositionStyles(styles);

          setPopupTemplate(setTemplate);
        }
      },
      [],
  );

  const applyContainerPositionStyles = useCallback(
      (styles: any): JSX.Element => {

        return (
            <div
                id={uniqueKey.toString()}
                className={`Popup-container ${className ? className : ''}${isRelative ? 'Popup--fixed' : ''}`}
                style={styles}
                ref={instance}
            >
              {
                template
              }
            </div>
        );
      },
      [],
  );

  return (
    show && !isRelative && ReactDOM.createPortal(
      popupTemplate,
      document.getElementsByClassName('App')[0],
    ) || show && isRelative && popupTemplate
  );
};

export default Popup;
