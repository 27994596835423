import { IApiRequest } from '../../types';
import { Reducer } from 'redux';
import {
  CANCEL_ORDER_REQUEST,
  CANCEL_ORDER_RESPONSE,
  CANCEL_ORDER_ERROR,
} from '../../actions/Orders/cancelOrderAction';

const initialState: IApiRequest<void> = {
  data: null,
  error: null,
  UpdatedAt: null,
  loading: false,
};

const cancelOrderReducer: Reducer<IApiRequest<any>> =
  (state: IApiRequest<any> = initialState, action: any) => {
    switch (action.type) {
      case CANCEL_ORDER_REQUEST: {
        return {
          ...state,
          loading: true,
        };
      }
      case CANCEL_ORDER_RESPONSE: {
        return {
          ...state,
          data: action.response,
          loading: false,
        };
      }
      case CANCEL_ORDER_ERROR: {
        return {
          ...initialState,
          error: action.response,
        };
      }
      default: {
        return state;
      }
    }
  };

export default cancelOrderReducer;
