import { Action } from 'redux';
import {
  IPostContactFormParams,
  postContactForm,
  IContactFormResponse as IContactFormResponseAPI,
} from '../../api/MyAccount/contactFormApi';
import { AxiosResponse } from 'axios';
import { toastr } from 'react-redux-toastr';

export const POST_CONTACT_FORM_REQUEST = 'POST_CONTACT_FORM_REQUEST';
export type POST_CONTACT_FORM_REQUEST = typeof POST_CONTACT_FORM_REQUEST;
export const POST_CONTACT_FORM_RESPONSE = 'POST_CONTACT_FORM_RESPONSE';
export type POST_CONTACT_FORM_RESPONSE = typeof POST_CONTACT_FORM_RESPONSE;
export const POST_CONTACT_FORM_ERROR = 'POST_CONTACT_FORM_ERROR';
export type POST_CONTACT_FORM_ERROR  = typeof POST_CONTACT_FORM_ERROR ;

export interface IContactFormResponse extends Action {
  type: POST_CONTACT_FORM_RESPONSE;
  response: IContactFormResponseAPI;
}

export interface IPostContactFormError extends Action {
  type: POST_CONTACT_FORM_ERROR;
  error?: string;
}

export const postContactFormRequest = (params: IPostContactFormParams): any => {
  return (dispatch : any) => {
    dispatch({
      type: POST_CONTACT_FORM_REQUEST,
      parameters: params,
    });

    return postContactForm(params)
      .then((response: AxiosResponse<IContactFormResponseAPI>) => {
        if (response.status === 200) {

          toastr.success(
            'Email sent',
            'Your message has been sent to the Wholesale team.');

          dispatch({
            type: POST_CONTACT_FORM_RESPONSE,
          });

        } else {
          onError(dispatch);
        }
      })
      .catch((err: any) => {
        console.log(err);
        onError(dispatch);
      });
  };
};

const onError = (dispatch: any) => dispatch({
  type: POST_CONTACT_FORM_ERROR,
});
