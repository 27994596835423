import { Button } from '@orderly/morrisons-component-library';
import Table from '../Table/Table';
import React from 'react';
import { formatDateString } from '../../helpers/Format';
import { ITradingPacksTableProps } from './ITradingPacksTableProps';
import styles from './TradingPacksTable.module.scss';
import { navigateTo } from '../../routing/Navigation';
import { IStoreState } from '../../types';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { deleteTradingPackRequest } from '../../actions/Products';
import { toastr } from 'react-redux-toastr';

function TradingPacksTable({
  tabId,
  noRecordsMessage,
  rows,
  deleteTradingPack,
  disableDelete,
}: ITradingPacksTableProps): JSX.Element {

  const onDeleteTradingPack = (id: number, name: string) => {
    const toastrConfirmOptions = {
      onOk: () => deleteTradingPack(id),
      onCancel: () => {},
    };
    toastr.confirm(`Are you sure you wish to remove ${name}?`, toastrConfirmOptions);
  };

  return (
    <Table className="">
      <thead>
        <tr>
          <th>Description</th>
          <th>Created At</th>
          <th>Start Date</th>
          <th>End Date</th>
          <th>Customer Groups</th>
          <th>Items</th>
          <th />
        </tr>
      </thead>
      <tbody>
        {
          rows.length === 0 ?
            <tr>
              <td colSpan={6}>{noRecordsMessage}</td>
            </tr> :
            rows.map((x) => {
              return (
                <tr key={`${tabId}_${x.id}`}>
                  <td className={x.isActive ? styles.active : null}>
                    {x.description}
                  </td>
                  <td>
                    {formatDateString(x.createdAt)}
                  </td>
                  <td>
                    {formatDateString(x.startDate)}
                  </td>
                  <td>
                    {formatDateString(x.endDate)}
                  </td>
                  <td>
                    {x.customerGroups.join(', ')}
                  </td>
                  <td>
                    {x.items.length}
                  </td>
                  <td className={styles.actions}>
                    <span title="Edit Trading Pack">
                      <Button
                        className="edit-trading-pack"
                        icon="icon-Edit"
                        type="button"
                        text=""
                        onClick={() => navigateTo(`/admin/trading-packs/${x.id}`)}
                      />
                    </span>
                    <span title="Delete Trading Pack">
                      <Button
                        className="delete-trading-pack"
                        icon="icon-Delete"
                        type="button"
                        text=""
                        onClick={() => onDeleteTradingPack(x.id, x.description)}
                        disabled={disableDelete}
                      />
                    </span>
                  </td>
                </tr>
              );
            })
        }
      </tbody>
    </Table>
  );
}

const mapStateToProps = (state: IStoreState) => {
  return {
    disableDelete: state.deleteTradingPack.loading,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<any>) => {
  return {
    deleteTradingPack: (id: number) => dispatch(deleteTradingPackRequest(id)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TradingPacksTable);
