import { Reducer } from 'redux';
import { IApiRequest, IUploadOrderImportCsv } from '../../types/index';
import {
  UPLOAD_ORDER_CSV_REQUEST,
  UPLOAD_ORDER_CSV_RESPONSE,
  UPLOAD_ORDER_CSV_ERROR,
} from '../../actions/Basket/uploadOrderImportCsvAction';

const initialState: IApiRequest<IUploadOrderImportCsv> = {
  data: null,
  loading: false,
  error: '',
  UpdatedAt: '',
};

const uploadOrderImportCsvReducer: Reducer<IApiRequest<IUploadOrderImportCsv>> = (
  state: IApiRequest<IUploadOrderImportCsv> = initialState,
  action: any,
) => {
  switch (action.type) {
    case UPLOAD_ORDER_CSV_REQUEST: {
      return {
        ...initialState,
        loading: true,
      };
    }
    case UPLOAD_ORDER_CSV_RESPONSE: {
      return {
        ...state,
        loading: false,
      };
    }
    case UPLOAD_ORDER_CSV_ERROR: {
      return {
        ...initialState,
        data: action.response,
      };
    }
    default: {
      return state;
    }

  }
};

export default uploadOrderImportCsvReducer;
