import { Reducer } from 'redux';
import { IApiRequest, IReport } from '../../types';

const initialState: IApiRequest<IReport> = {
  loading: false,
  UpdatedAt: null,
  data: null,
  error: '',
};

const confirmedOrdersReducer: Reducer<IApiRequest<IReport>> = (
  state: IApiRequest<IReport> = initialState,
  action : any,
  ) => {
  switch (action.type) {
    case 'GET_CONFIRMED_ORDERS_REPORT_RESPONSE': {
      return {
        UpdatedAt: new Date().toISOString(),
        loading: false,
        error: '',
        data: {
          content: action.response.content,
          filename: action.response.filename,
        },
      };
    }
    case 'GET_CONFIRMED_ORDERS_REPORT_REQUEST': {
      return {
        UpdatedAt: new Date().toISOString(),
        loading: true,
        error: '',
        data: null,
      };
    }
    case 'GET_CONFIRMED_ORDERS_REPORT_ERROR': {
      return {
        UpdatedAt: new Date(),
        loading: false,
        error: action.error || 'There was an error loading this report.',
        data: null,
      };
    }
    case 'RESET_CONFIRMED_ORDERS_REPORT':
      return initialState;
    default: {
      return state;
    }
  }
};

export default confirmedOrdersReducer;
