import './PendingOrdersListFilters.scss';
import React, { useEffect, useState } from 'react';
import { TextInput, Checkbox, Dropdown } from '@orderly/morrisons-component-library';
import { IPendingOrdersListFiltersProps } from './IPendingOrdersListFiltersProps';
import { mapCategory } from '../../helpers/Category/mapCategory';

const PendingOrdersListFilters = ({
  className,
  categories,
  orderNumber,
  onOrderNumberChanged,
  storeId,
  onStoreIdChanged,
  modifiedDate,
  onModifiedDateChanged,
  deliveryDate,
  onDeliveryDateChanged,
  selectedCategory,
  onSelectedCategoryChanged,
}: IPendingOrdersListFiltersProps): JSX.Element => {

  const [filterMode, setFilterMode] = useState('store');

  useEffect(
    () => {
      onStoreIdChanged('');
      onOrderNumberChanged('');
    },
    [filterMode]);

  return (
    <div className={`PendingOrdersListFilters-container ${className}`}>
        <div className="PendingOrdersListFilters-headingContainer">
          <h3 className="PendingOrdersListFilters-heading">FILTER BY</h3>
        </div>
        <div className="PendingOrdersListFilters-searchContainer">
          <div className="PendingOrdersListFilters-labelContainer">
            <Dropdown
              className="Orders-sortDropdown"
              label=""
              name="sortOrders"
              onChange={val => setFilterMode(val.value as string)}
              selectedValue={filterMode}
              options={[{ label: 'Store ID', value: 'store' }, { label: 'Order ID', value: 'order' }]}
            />
          </div>
          <div className="OrdersListSearch-textInputContainer">
            {filterMode === 'store'
              ? <TextInput
                error=""
                className="OrdersListSearch-input icon-search"
                label="Search"
                name="storeId"
                onChange={onStoreIdChanged}
                type="text"
                value={storeId}
              />
              : <TextInput
                error=""
                className="OrdersListSearch-input icon-search"
                label="Search"
                name="orderNumber"
                onChange={onOrderNumberChanged}
                type="text"
                value={orderNumber}
              />
            }
          </div>
        </div>
        <div className="PendingOrdersListFilters-dateOptionsContainer">
          <div className="PendingOrdersListFilters-labelContainer">
            <span className="PendingOrdersListFilters-label">Modified Date</span>
          </div>
          <div>
            <TextInput
              error=""
              className={`OrdersListSearch-input ${modifiedDate.length === 0 ? 'empty' : ''}`}
              label=""
              name="modifiedDate"
              onChange={onModifiedDateChanged}
              type="date"
              value={modifiedDate}
            />
          </div>
        </div>
        <div className="PendingOrdersListFilters-dateOptionsContainer">
          <div className="PendingOrdersListFilters-labelContainer">
            <span className="PendingOrdersListFilters-label">Delivery Date</span>
          </div>
          <div>
            <TextInput
              error=""
              className={`OrdersListSearch-input ${modifiedDate.length === 0 ? 'empty' : ''}`}
              label=""
              name="deliveryDate"
              onChange={onDeliveryDateChanged}
              type="date"
              value={deliveryDate}
            />
          </div>
        </div>
        <div className="PendingOrdersListFilters-orderStatusFilter">
          <div className="PendingOrdersListFilters-labelContainer">
            <span className="PendingOrdersListFilters-label">Category</span>
          </div>
          {
            categories && categories.length > 0
            ? categories.map(category =>
              <Checkbox
                className="PendingOrdersListFilters-orderStatusCheckbox"
                checked={selectedCategory === category.description}
                key={category.description}
                label={mapCategory(category.description)}
                name={category.description}
                onChange={(description: string, checked: boolean) => {
                  onSelectedCategoryChanged(checked ? description : '');
                }}
                isSmall={true}
              />)
            : null
          }
        </div>
      </div>
  );
};

export default PendingOrdersListFilters;
