import { IApiRequest } from '../../types';
import { Reducer } from 'redux';
import {
  UPDATE_ORDER_STATUS_REQUEST,
  UPDATE_ORDER_STATUS_RESPONSE,
  UPDATE_ORDER_STATUS_ERROR,
} from '../../actions/Orders/updateOrderStatusAction';

const initialState: IApiRequest<void> = {
  data: null,
  error: null,
  UpdatedAt: null,
  loading: false,
};

const updateOrderStatusReducer: Reducer<IApiRequest<any>> =
  (state: IApiRequest<any> = initialState, action: any) => {
    switch (action.type) {
      case UPDATE_ORDER_STATUS_REQUEST: {
        return {
          ...state,
          loading: true,
        };
      }
      case UPDATE_ORDER_STATUS_RESPONSE: {
        return {
          ...state,
          loading: false,
        };
      }
      case UPDATE_ORDER_STATUS_ERROR: {
        return {
          ...initialState,
          error: action.response,
        };
      }
      default: {
        return state;
      }
    }
  };

export default updateOrderStatusReducer;
