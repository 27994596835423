import Fetch from '../apiConfig/apiConfig';
import { AxiosPromise, AxiosInstance } from 'axios';
import { IBasket } from '../../types';
import queryString from 'query-string';

export interface IGetOrderByOrderNumberParams {
  [key: string]: string;
  orderNumber: string;
}

export interface IGetOrderByOrderNumberResponse {
  order: IBasket;
}

export const getOrderByOrderNumber =
  (params: IGetOrderByOrderNumberParams): AxiosPromise<IGetOrderByOrderNumberResponse> =>
    Fetch().then((axios: AxiosInstance):
      AxiosPromise<IGetOrderByOrderNumberResponse> => axios.get(`orders/lookup/get?${queryString.stringify(params)}`));
