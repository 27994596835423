import { IApiRequest, IRetailerDetails, IUserDetails, OrderRetailerType } from '../../types';
import { Claims } from './Claims.enum';

const isLoggedIn = (user: IUserDetails): boolean => {
  if (localStorage.getItem('token') === null) {
    return false;
  }
  return user.userId !== -1;
};

const hasRetailerMeta = (meta: { [key: string]: string}, retailerDetails: IApiRequest<IRetailerDetails>): boolean => {
  const key = Object.keys(meta)[0];
  if (retailerDetails.data.meta && key in retailerDetails.data.meta) {
    return retailerDetails.data.meta[key] === meta[key];
  }
  return false;
};

const isStaff = (user: IUserDetails): boolean => {
  if (!isLoggedIn(user)) return false;

  return (
    isAdmin(user) ||
    isAccountManager(user) ||
    isSupplyTeam(user) ||
    isLogisticsTeam(user)
  );
};

const isAdmin = (user: IUserDetails): boolean => {
  if (!isLoggedIn(user)) return false;

  return user.claims !== undefined
    && user.claims.indexOf(Claims.Admin) !== -1;
};

const isBulkUser = (retailer: IRetailerDetails): boolean => {
  if (retailer) {
    return parseInt(retailer.meta.OrderType, 10) === OrderRetailerType.Bulk;
  }
  return false;
};

const isAccountManager = (user: IUserDetails): boolean => {
  if (!isLoggedIn(user)) return false;

  return user.claims !== undefined
    && user.claims.indexOf(Claims['Account Manager']) !== -1;
};

const isSupplyTeam = (user: IUserDetails) : boolean => {
  if (!isLoggedIn(user)) return false;

  return user.claims !== undefined
    && user.claims.indexOf(Claims['Supply Team']) !== -1;
};

const isLogisticsTeam = (user: IUserDetails) : boolean => {
  if (!isLoggedIn(user)) return false;

  return user.claims !== undefined
    && user.claims.indexOf(Claims['Logistics Team']) !== -1;
};

const getBasePath = (user: IUserDetails) => {
  if (isAdmin(user)) {
    return '/admin/';
  }

  if (isAccountManager(user)) {
    return '/account-manager/';
  }

  if (isSupplyTeam(user)) {
    return '/supply-team/';
  }

  if (isLogisticsTeam(user)) {
    return '/logistics-team/';
  }

  return '/';
};

export {
  isAdmin,
  isLoggedIn,
  isAccountManager,
  isStaff,
  isBulkUser,
  isSupplyTeam,
  isLogisticsTeam,
  Claims,
  getBasePath,
  hasRetailerMeta,
};
