import { Action } from 'redux';
import { AxiosResponse } from 'axios';
import { IUpdateRetailerMetaParams } from '../api/Retailers/IUpdateRetailerMetaParams';
import { updateRetailerMeta } from '../api/Retailers/retailersApi';

export const UPDATE_RETAILER_META_REQUEST = 'UPDATE_RETAILER_META_REQUEST';
export type UPDATE_RETAILER_META_REQUEST = typeof UPDATE_RETAILER_META_REQUEST;

export const UPDATE_RETAILER_META_RESPONSE = 'UPDATE_RETAILER_META_RESPONSE';
export type UPDATE_RETAILER_META_RESPONSE = typeof UPDATE_RETAILER_META_RESPONSE;

export const UPDATE_RETAILER_META_ERROR = 'UPDATE_RETAILER_META_ERROR';
export type UPDATE_RETAILER_META_ERROR = typeof UPDATE_RETAILER_META_ERROR;

export interface IUpdateRetailerMetaResponse extends Action {
  type: UPDATE_RETAILER_META_RESPONSE;
}

export interface IUpdateRetailerMetaError extends Action {
  type: UPDATE_RETAILER_META_ERROR;
  error?: string;
}

export const updateRetailerMetaRequest =
 (parameters: IUpdateRetailerMetaParams,
  onSuccess: () => void,
  onFailure: () => void): any => {
   return (dispatch : any) => {

     dispatch({
       type: UPDATE_RETAILER_META_REQUEST,
     });

     return updateRetailerMeta(parameters)
      .then((response: AxiosResponse) => {
        if (response.status === 200) {
          dispatch({
            type: UPDATE_RETAILER_META_RESPONSE,
          });

          onSuccess();

        } else {
          onError(dispatch);
          onFailure();
        }
      })
      .catch((err: any) => {
        console.log(err);
        onError(dispatch);
        onFailure();
      });
   };
 };

const onError = (dispatch: any) => dispatch({
  type: UPDATE_RETAILER_META_ERROR,
});
