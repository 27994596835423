import React, { Dispatch, useState } from 'react';
import { connect } from 'react-redux';
import { IManageShoppingListProps } from './IManageShoppingListProps';
import { Button, TextArea } from '@orderly/morrisons-component-library';
import Modal from '../Modal/Modal';
import { IShoppingList, IStoreState } from '../../types';
import {
  ICreateShoppingListBody,
  IDeleteShoppingListBody,
  IAddProductsToShoppingListBody,
} from '../../api/ShoppingLists/shoppingListsApi';
import './ManageShoppingList.scss';
import {
  createShoppingListRequest,
  deleteShoppingListRequest,
  submitProductBarcodesRequest,
} from '../../actions/ShoppingLists/shoppingListActions';
import { toastr } from 'react-redux-toastr';
import LoadingThrobber from '../LoadingThrobber';

function ManageShoppingList({
  shoppingLists,
  createShoppingList,
  deleteShoppingList,
  submitProductBarcodes,
}: IManageShoppingListProps): JSX.Element {
  const [isOpen, setIsOpen] = useState(false);
  const [newListName, setNewListName] = useState('');
  const { data, loading } = shoppingLists;
  const [openBarcodeModal, setOpenBarcodeModal] = useState(false);
  const [barcodes, setBarcodes] = useState('');
  const [shoppingListDetails, setShoppingListDetails] = useState({ id: null, description: '' });
  const [uploadResults, setUploadResults] = useState<string[]>([]);
  const [showUploadResponseModal, setShowUploadResponseModal] = useState<boolean>(false);

  const validateShoppingList = () => {
    if (newListName.length === 0) {
      return;
    }

    const duplicateName = data.findIndex(x => x.description.toLowerCase() === newListName.toLowerCase()) !== -1;
    if (duplicateName) {
      return;
    }

    createShoppingList({ description: newListName });
    setNewListName('');
  };

  const confirmShoppingListDeletion = (list:IShoppingList) => {
    const { id, description } = list;
    const toastrConfirmOptions = {
      onOk: () => {
        deleteShoppingList({ id });
      },
    };
    toastr.confirm(
      `Are you sure you want to delete the shopping list ${description}?`,
      toastrConfirmOptions,
    );
  };

  const validateInput = () => {
    const trimmedBarcodes = barcodes.trim();
    return trimmedBarcodes.length > 0;
  };

  const onSubmitProductBarcodes = () => {
    const toastrConfirmOptions = {
      onOk: () => {
        submitProductBarcodes(
          { barcodes, id: shoppingListDetails.id },
          (_, unmatchedBarcodes: string[]) => {
            if (unmatchedBarcodes.length > 0) {
              toastr.warning(
                'Unmatched Barcodes',
                'Some barcodes could not be matched with products and were not added to the shopping list.',
              );
              setUploadResults(unmatchedBarcodes);
              setShowUploadResponseModal(true);
              setBarcodes('');
            } else {
              toastr.success(
                'Product Barcodes Submitted',
                'Products have been submitted to the shopping list successfully.',
              );
              setBarcodes('');
              setOpenBarcodeModal(false);
            }
          },
        );
      },
    };

    toastr.confirm(
      `Are you sure you want to submit the provided list of barcodes to the shopping list
    ${shoppingListDetails.description}?`,
      toastrConfirmOptions,
    );
  };

  const onUploadFavourites = (list: IShoppingList) => {
    const { id, description } = list;

    setShoppingListDetails({ id, description });
    setOpenBarcodeModal(true);
  };

  return (
    <div className="manage-shopping-list">
    {shoppingLists.loading && <LoadingThrobber />}
      <span title="Manage Shopping lists">
        <Button
          icon="icon-list"
          type="button"
          className="icon-button manage-shopping-list-button primary"
          text=""
          onClick={() => setIsOpen(true)}
        />
      </span>
      {
        isOpen && <Modal
          header="Manage Shopping Lists"
          isOpen={isOpen}
          onClose={() => setIsOpen(false)}
        >
          <div className="create-container">
            <input
              type="text"
              onChange={e => setNewListName(e.target.value)}
              className="create-list-input"
              placeholder="Create shopping list"
              value={newListName}
              maxLength={255}
            />
            <Button
              icon="icon-plus"
              type="button"
              className="icon-button primary"
              text=""
              onClick={validateShoppingList}
              disabled={shoppingLists.loading}
            />
          </div>
          <hr />
          <div>
            {
              data.length === 0 ?
                <div>
                  {loading ? 'Loading...' : 'No shopping lists found.'}
                </div>
                : data.map((item) => {
                  return (
                    <div className="list" key={item.id}>
                      <span>
                        {item.description}
                      </span>
                    <div className="action-buttons">
                      <span title="Upload favourites">
                        <Button
                          icon="icon-plus"
                          type="button"
                          className="icon-button primary"
                          text=""
                          onClick={() => {
                            setOpenBarcodeModal(true);
                            onUploadFavourites(item);
                          }}
                          disabled={shoppingLists.loading}
                        />
                      </span>
                      <span title={`Delete ${item.description}`}>
                        <Button
                          icon="icon-Delete"
                          type="button"
                          className="icon-button primary"
                          text=""
                          onClick={() => {
                            confirmShoppingListDeletion(item);
                          }}
                          disabled={shoppingLists.loading}
                        />
                      </span>
                    </div>
                  </div>
                  );
                })
            }
          </div>
        </Modal>
      }
      {openBarcodeModal && (
        <Modal
          header="Enter product barcodes"
          isOpen={openBarcodeModal}
          buttonText="Submit"
          onCancel={() => {
            setBarcodes('');
            setOpenBarcodeModal(false);
          }}
          onClose={onSubmitProductBarcodes}
          disableClose={!validateInput()}
        >
          <TextArea
            name="product-barcodes-textarea"
            label={`
              Enter a comma separated list of product barcodes to add to the shopping list
              ${shoppingListDetails.description}
            `}
            className="product-barcode-area"
            value={barcodes}
            onChange={(value: string) => {
              setBarcodes(value);
            }}
          />
        </Modal>
      )}
      {showUploadResponseModal && (
        <Modal
          header="Unmatched barcodes"
          isOpen={showUploadResponseModal}
          buttonText="Close"
          onClose={() =>  {
            setShowUploadResponseModal(false);
            setOpenBarcodeModal(false);
          }
          }
        >
          The following barcodes could not be matched:
          <ul>
            {uploadResults.map((result, index) => (
              <li key={index}>{result}</li>
            ))}
          </ul>
        </Modal>
      )}
    </div>
  );
}

const mapStateToProps = (state: IStoreState) => {
  return {
    shoppingLists: state.shoppingLists,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<any>) => {
  return {
    createShoppingList: (body: ICreateShoppingListBody) =>
      dispatch(createShoppingListRequest(body)),
    deleteShoppingList: (body: IDeleteShoppingListBody) =>
      dispatch(deleteShoppingListRequest(body)),
    submitProductBarcodes: (
      body: IAddProductsToShoppingListBody,
      onSuccess: (shoppingList: IShoppingList[], unmatchedBarcodes: string[]) => void,
    ) => dispatch(submitProductBarcodesRequest(body, onSuccess)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ManageShoppingList);
