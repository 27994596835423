import React, { useEffect, useState } from 'react';
import {
  IPresaleCampaign,
  IPresaleProduct,
  IPresaleProductDrops,
  IStoreState,
  IUpdatePresalesSubmission,
} from '../../types';
import './CampaignEntryTable.scss';
import moment from 'moment';
import NotFoundImage from '../../assets/images/404.jpg';
import LazyLoad from 'react-lazy-load';
import CounterWrapper from './CounterWrapper';
import { Pagination } from 'react-bootstrap';
import { Button, Checkbox } from '@orderly/morrisons-component-library';
import { updatePresalesSubmissionRequest } from '../../actions/SupplyChain/presalesActions';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import LoadingThrobber from '../LoadingThrobber';
import { toastr } from 'react-redux-toastr';

interface ICampaignEntryProps {
  campaign: IPresaleCampaign;
  isAdminPreviewMode?: boolean;
  campaignSubmitted?: boolean;
  storeId?: number;
  loading?: boolean;
  onSetCampaign: (campaign: IPresaleCampaign) => void;
  onUpdatePresaleSubmission?: (
    submissionDetails: IUpdatePresalesSubmission,
    onSuccess: () => void,
  ) => void;
}

const CampaignEntryTable = ({
  campaign,
  isAdminPreviewMode,
  campaignSubmitted,
  storeId,
  loading,
  onUpdatePresaleSubmission,
  onSetCampaign,
}: ICampaignEntryProps) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState<number>();
  const [dropColumns, setDropColumns] = useState<any>();
  const [currentProducts, setCurrentProducts] = useState<any>([]);
  const itemsPerPage = 10;

  const allDatesNullForDrop = (products: IPresaleProduct[], dropIndex: number) => {
    return products.every(product => product.drops[dropIndex].date === null);
  };

  useEffect(() => {
    if (campaign && campaign.products) {
      const current = campaign.products
        .slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage)
        .map(product => ({
          ...product,
          optOut: false,
        }));

      setCurrentProducts(current);
    }
  }, [campaign, currentPage]);

  useEffect(() => {
    if (campaign) {
      let columnNumber = 1;

      const columns = campaign.dropDates
        .map((date, index) => {
          if (!allDatesNullForDrop(campaign.products, index)) {
            const weekCommencing = date ? moment(new Date(date)).format('DD/MM/YYYY') : 'N/A';
            return {
              index,
              title: (
                <>
                  Drop {columnNumber++} <br /> w/c <br />
                  {weekCommencing}
                </>
              ),
            };
          }
          return null;
        })
        .filter(column => column !== null);

      setDropColumns(columns);

      setTotalPages(Math.ceil(campaign.products.length / itemsPerPage));
    }
  }, [campaign]);

  const formatDueDate = () => {
    const today = moment().startOf('day');
    const deadlineDate = moment(campaign.deadline).startOf('day');
    const differenceInDays = deadlineDate.diff(today, 'days');

    let color = 'red';
    let suffix = `${differenceInDays} day${differenceInDays === 1 ? '' : 's'}`;

    if (differenceInDays < 0) {
      suffix = 'Overdue';
    } else if (differenceInDays === 0) {
      suffix = 'Today';
    } else if (differenceInDays <= 3) {
      color = 'orange';
    } else {
      color = 'green';
    }
    return (
      <>
        {moment(campaign.deadline).format('DD/MM/YYYY')} <span style={{ color }}>({suffix})</span>
      </>
    );
  };

  const getImage = (product: IPresaleProduct) => {
    if (!product.imageUrl) {
      return (
        <div className="image-holder">
          <img className="image-thumbnail" src={NotFoundImage} />
        </div>
      );
    }
    return (
      <div className="image-holder">
        <LazyLoad
          debounce={false}
          offset={750}
          placeholder={<img className="image-thumbnail" src={NotFoundImage} />}
        >
          <img className="image-thumbnail" src={product.imageUrl} />
        </LazyLoad>
      </div>
    );
  };

  const dropFormatter = (drop: IPresaleProductDrops, productId: number) => {
    if (!drop) {
      return null;
    }
    return (
    <div title={`${(!drop.date) ? 'Drop not available' : ''}`}>
      <CounterWrapper
        className={`${(campaignSubmitted || !drop.date) ? 'isDisabled' : ''}`}
        changeBy={1}
        minValue={0}
        maxValue={99}
        initialValue={(drop.allocatedVolume || drop.allocatedVolume === 0) ? drop.allocatedVolume : ''}
        onChange={quantity => onQuantityChange(productId, drop.id, quantity)}
      />
     </div>
    );
  };

  const uncheckOptOut = (productId: number) => {
    const updatedProduct = {
      ...campaign,
      products: campaign.products.map((product: IPresaleProduct) =>
        product.id === productId
          ? {
            ...product,
            drops: product.drops.map(drop => ({
              ...drop,
              allocatedVolume: 0,
            })),
            checked: false,
          }
          : product,
      ),
    };

    onSetCampaign(updatedProduct);
  };

  const onQuantityChange = (
    productId: number,
    dropId: number = 0,
    quantity: number = 0,
    productOptOut: boolean = false,
  ) => {
    if (!isAdminPreviewMode) {
      let updatedCampaign = { ...campaign };

      if (productOptOut) {
        updatedCampaign = {
          ...campaign,
          products: campaign.products.map((product: IPresaleProduct) =>
            product.id === productId
              ? {
                ...product,
                drops: product.drops.map(drop => ({
                  ...drop,
                  allocatedVolume: 0,
                })),
                checked: true,
              }
              : product,
          ),
        };
      } else {
        updatedCampaign = {
          ...campaign,
          products: campaign.products.map((product: any) =>
            product.id === productId
              ? {
                ...product,
                drops: product.drops.map((drop: IPresaleProductDrops) =>
                    drop.id === dropId ? { ...drop, allocatedVolume: quantity } : drop,
                  ),
                checked: quantity > 0 ? false : product.checked,
              }
              : product,
          ),
        };
      }

      onUpdatePresaleSubmission(
        {
          campaignId: campaign.id,
          dropValues: { storeId, quantity, productOptOut, productId, productDropId: dropId },
        },
        () => {
          onSetCampaign(updatedCampaign);
        },
      );
    }
  };

  return (
    <>
      {loading && <LoadingThrobber preventScroll={false} />}
      {!campaign || !campaign.products ? (
        <div>No campaign data available</div>
      ) : (
        <div className="CampaignEntryTable">
          <div className="header">
            <div>Presales - {campaign.name}</div>
            <div className="deadline">Due: {formatDueDate()}</div>
            <Pagination>
                <Button
                  className="tertiary"
                  type="submit"
                  text="Previous"
                  onClick={() => setCurrentPage(prev => Math.max(prev - 1, 1))}
                  disabled={currentPage === 1}
                />
                <span>
                  Page {currentPage} of {totalPages}
                </span>
                <Button
                  className="tertiary"
                  type="submit"
                  text="Next"
                  onClick={() => setCurrentPage(prev => Math.min(prev + 1, totalPages))}
                  disabled={currentPage === totalPages}
                />
            </Pagination>
          </div>
          <div className="presales-data-entry-table">
            <div className="table-header">
              <div className="table-row">
                <div className="row-column" />
                <div className="row-column description-column" />
                {dropColumns &&
                  dropColumns.map((column: any, index: number) => (
                    <div key={index} className="row-column">
                      {column.title}
                    </div>
                  ))}
                <div className="row-column" />
              </div>
            </div>

            <div className="table-body">
              {currentProducts.map((product: any) => (
                <div key={product.id} className="table-row">
                  <div className="row-column">{getImage(product)}</div>
                  <div className="row-column description-column description-column-text">
                    <div>
                      <span className="description">{product.description}</span>
                    </div>
                    <div>
                      <div className="values">
                        <span>
                          <b>£{product.promoCasePrice}</b> ({product.caseSize} pack)
                        </span>
                        <span>{product.vat}% VAT</span>
                        <span>{product.pinCode}</span>
                      </div>
                    </div>
                    <div className="values">
                      <span>£{product.promoRetailEach} RRP</span>
                      <span>{product.por}% POR</span>
                      <span>{product.space}</span>
                      <span>{product.promoMechanic}</span>
                    </div>
                  </div>
                  {dropColumns.map((column: any, index: number) => (
                    <div key={index} className="row-column">
                      {dropFormatter(product.drops[column.index], product.id)}
                    </div>
                  ))}
                  <div className="row-column">
                    <Checkbox
                      checked={product.checked}
                      label="Opt out"
                      name={`product-opt-out-${product.id}`}
                      onChange={(_, checked) => {
                        if (checked) {
                          const toastrConfirmOptions = {
                            onOk: () => {
                              onQuantityChange(product.id, undefined, undefined, checked);
                            },
                          };
                          toastr.confirm(
                            'Are you sure you want to opt out of the item?',
                            toastrConfirmOptions,
                          );
                        } else {
                          uncheckOptOut(product.id);
                        }
                      }}
                      isSmall={true}
                      className="opt-out-checkbox"
                      disabled={campaignSubmitted}
                    />
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

const mapStateToProps = (state: IStoreState) => {
  return {
    loading: state.presales.loading,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<any>) => ({
  onUpdatePresaleSubmission: (parameters: IUpdatePresalesSubmission, onSuccess: () => void) =>
    dispatch(updatePresalesSubmissionRequest(parameters, onSuccess)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CampaignEntryTable);
