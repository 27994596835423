import { Action } from 'redux';
import { IGetProductParams, getProduct, IGetProductResponse } from '../api/productsApi';
import { IStoreState } from '../types';
import { store } from '../store';
import { AxiosResponse } from 'axios';

export const GET_PRODUCT_REQUEST = 'GET_PRODUCT_REQUEST';
export type GET_PRODUCT_REQUEST = typeof GET_PRODUCT_REQUEST;
export const GET_PRODUCT_RESPONSE = 'GET_PRODUCT_RESPONSE';
export type GET_PRODUCT_RESPONSE = typeof GET_PRODUCT_RESPONSE;
export const GET_PRODUCT_ERROR = 'GET_PRODUCT_ERROR';
export type GET_PRODUCT_ERROR = typeof GET_PRODUCT_ERROR;

export interface IGetProductResponse extends Action {
  type: GET_PRODUCT_RESPONSE;
  response: IGetProductResponse;
}

export interface IGetProductError extends Action {
  type: GET_PRODUCT_ERROR;
  error?: string;
}

export const getProductRequest = (parameters: IGetProductParams): any => {
  return (dispatch : any) => {

    const state : IStoreState = store.getState();
    const product = state.products[parameters.productId];
    const expiredAt = state.catalogueMeta.meta.updatedAt;

    if (product !== undefined
      && !product.error
      && expiredAt < product.UpdatedAt) {
      return {
        type: GET_PRODUCT_RESPONSE,
        response: product,
      };
    }

    dispatch({
      type: GET_PRODUCT_REQUEST,
      productId: parameters.productId,
    });

    return getProduct(parameters)
      .then((response: AxiosResponse<IGetProductResponse>) => {
        if (response.status === 200) {
          dispatch({
            type: GET_PRODUCT_RESPONSE,
            productId: parameters.productId,
            response: response.data.product,
          });
        } else {
          onError(dispatch, parameters.productId);
        }
      })
      .catch((err: any) => {
        console.log(err);
        onError(dispatch, parameters.productId);
      });
  };
};

const onError = (dispatch: any, productId: number) => dispatch({
  productId,
  type: GET_PRODUCT_ERROR,
});
