import { Action } from 'redux';
import { getCategories, IGetCategoriesResponse } from '../api/categoriesApi';
import { IStoreState } from '../types';
import { store } from '../store';
import { AxiosResponse } from 'axios';

export const GET_CATEGORIES_REQUEST = 'GET_CATEGORIES_REQUEST';
export type GET_CATEGORIES_REQUEST = typeof GET_CATEGORIES_REQUEST;
export const GET_CATEGORIES_RESPONSE = 'GET_CATEGORIES_RESPONSE';
export type GET_CATEGORIES_RESPONSE = typeof GET_CATEGORIES_RESPONSE;
export const GET_CATEGORIES_ERROR = 'GET_CATEGORIES_ERROR';
export type GET_CATEGORIES_ERROR = typeof GET_CATEGORIES_ERROR;

export interface IGetCategoriesResponse extends Action {
  type: GET_CATEGORIES_RESPONSE;
  response: IGetCategoriesResponse;
}

export interface IGetCategoriesError extends Action {
  type: GET_CATEGORIES_ERROR;
  error?: string;
}

export const getCategoriesRequest = (): any => {
  return (dispatch : any) => {

    const state : IStoreState = store.getState();

    if (state.categories.data.length > 0
      && state.catalogueMeta.meta.updatedAt < state.categories.UpdatedAt) {
      return;
    }

    dispatch({
      type: GET_CATEGORIES_REQUEST,
    });

    return getCategories()
      .then((response: AxiosResponse<IGetCategoriesResponse>) => {
        if (response.status === 200) {
          dispatch({
            type: GET_CATEGORIES_RESPONSE,
            response: response.data.categories,
          });

        } else {
          onError(dispatch);
        }
      })
      .catch((err: any) => {
        console.log(err);
        onError(dispatch);
      });
  };
};

const onError = (dispatch: any) => dispatch({
  type: GET_CATEGORIES_ERROR,
});
