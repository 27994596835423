import React, { Dispatch, useState, useRef, useEffect } from 'react';
import heroInnerImage from './homepage_banner_inner_image.png';
import storeBanner from './store_banner.jpg';
import './LandingPage.scss';
import { Button, Card, TextInput, TextArea } from '@orderly/morrisons-component-library';
import Validator from '../../helpers/validator/Validator';
import ReCAPTCHA from '../../components/ReCAPTCHA/ReCAPTCHA';
import { IStoreState } from '../../types';
import { connect } from 'react-redux';
import { ISignUpParameters } from '../../api/signUpApi';
import { signUpRequest } from '../../actions/signUpAction';
import LoadingThrobber from '../../components/LoadingThrobber/LoadingThrobber';
import GoogleReCAPTCHA from 'react-google-recaptcha';
import { ValidatorOptionRules } from '../../helpers/validator/ValidatorOptionRules';
import ScrollHelper from '../../helpers/ScrollHelper';
import personalisedCatalogue from './personalised-catalogue.svg';
import editReviewOrders from './edit-review-orders.svg';
import acessibleEverywhere from './acessible-everywhere.svg';
import allInOnePlace from './all-in-one-place.svg';
import accountInfoUptodate from './account-info-uptodate.svg';
import detailsAvailable from './details-available.svg';
import registrationComplete from './registration-complete.svg';

const HomePage = ({ signUpResult, signUp, history }: any) => {

  const [signupName, setSignupName] = useState({ value: '', error: '' });
  const [signupEmail, setSignupEmail] = useState({ value: '', error: '' });
  const [signupPhone, setSignupPhone] = useState({ value: '', error: '' });
  const [signupCompany, setSignupCompany] = useState({ value: '', error: '' });
  const [signupReason, setSignupReason] = useState({ value: '', error: '' });
  const [signupRecaptcha, setSignupRecaptcha] = useState({ value: '', error: '' });
  const [signupActive, setSignupActive] = useState(false);
  const [signupComplete, setSignupComplete] = useState(false);

  const validator: Validator = new Validator({
    signupname: {
      [Validator.Rules.required]: {
        [ValidatorOptionRules.MAXLENGTH]: 512,
      },
    },
    signupemail: {
      [Validator.Rules.required]: {
        [ValidatorOptionRules.MAXLENGTH]: 512,
      },
      [Validator.Rules.email]: true,
    },
    signupphone: {
      [Validator.Rules.required]: {
        [ValidatorOptionRules.MAXLENGTH]: 128,
      },
      [Validator.Rules.telephone]: true,
    },
    signupcompany: {
      [Validator.Rules.required]: {
        [ValidatorOptionRules.MAXLENGTH]: 512,
      },
    },
    signupreason: {
      [Validator.Rules.required]: {
        [ValidatorOptionRules.MAXLENGTH]: 4000,
      },
    },
    recaptcha: {
      [Validator.Rules.required]: {
        [ValidatorOptionRules.MINLENGTH]: 5,
      },
    },
  });
  const recaptchaRef: React.MutableRefObject<GoogleReCAPTCHA> = useRef(null);

  const showSignUp = () : void => {
    recaptchaRef.current.reset();

    setSignupActive(true);
    setSignupComplete(false);
    setSignupName({ value: '', error: '' });
    setSignupEmail({ value: '', error: '' });
    setSignupPhone({ value: '', error: '' });
    setSignupCompany({ value: '', error: '' });
    setSignupReason({ value: '', error: '' });
    setSignupRecaptcha({ value: '', error: '' });
  };

  const signup = (event: React.FormEvent<HTMLFormElement>) : void => {
    event.preventDefault();

    if (isValid()) {
      let param: ISignUpParameters;
      param = {
        Company: signupCompany.value,
        Email: signupEmail.value,
        FullName: signupName.value,
        Reason: signupReason.value,
        Telephone: signupPhone.value,
        Recaptcha: signupRecaptcha.value,
      };

      signUp(
        param,
        () => setSignupComplete(true),
      );
    }
  };

  const isValid = () : boolean => {
    const result = validator.validate(
      [
        { name: 'signupname', value: signupName.value },
        { name: 'signupemail', value: signupEmail.value },
        { name: 'signupphone', value: signupPhone.value },
        { name: 'signupcompany', value: signupCompany.value },
        { name: 'signupreason', value: signupReason.value },
        { name: 'recaptcha', value: signupRecaptcha.value },
      ],
      (state: any) => {
        if (state['signupname']) {
          setSignupName(state['signupname']);
        } else if (state['signupemail']) {
          setSignupEmail(state['signupemail']);
        } else if (state['signupphone']) {
          setSignupPhone(state['signupphone']);
        }  else if (state['signupcompany']) {
          setSignupCompany(state['signupcompany']);
        }  else if (state['signupreason']) {
          setSignupReason(state['signupreason']);
        }  else if (state['recaptcha']) {
          setSignupRecaptcha(state['recaptcha']);
        }
      });

    return result.isValid;
  };

  useEffect(
    () => {
      if (signupActive) {
        ScrollHelper.preventBodyScroll();
      } else {
        ScrollHelper.allowBodyScroll();
      }
    },
    [signupActive],
  );

  return (
    <div className={`home-page ${signupActive ? 'signup' : 'nosignup'}`}>
      {signUpResult.loading && <LoadingThrobber  />}
      <section className="hero">
        <div className="container">
          <div className="content">
            <div className="text-wrapper">
              <h1>Start shopping with Morrisons Wholesale</h1>
              <p>
                Exclusive for Morrisons Wholesale members, find great
                wholesale offers on food, drink and more to your business.
              </p>
              <Button
                type="button"
                className="tertiary login"
                text="Already registered? Log in"
                onClick={() => history.push('/login')}
              />
              <Button
                type="button"
                className="link"
                text="Want to be a member? Ask for details"
                onClick={showSignUp}
              />
            </div>
            <div className="image-wrapper">
              <img src={heroInnerImage} alt="hero image" className="img-responsive" />
            </div>
          </div>
        </div>
      </section>
      <section className="sub-content">
        <div className="underlay" />
        <div className="container">
          <div className="cards-wrapper">
            <div className="card-row">
              <Card>
                <img src={personalisedCatalogue} />
                <div className="label">Personalised Catalogues</div>
              </Card>
              <Card>
              <img src={editReviewOrders} />
                <div className="label">
                  <span className="line">Edit and review your orders</span>
                </div>
              </Card>
            </div>
            <div className="card-row">
              <Card>
                <img src={acessibleEverywhere} />
                <div className="label">
                  <span className="line">Accessible everywhere.&nbsp;</span>
                  <span className="line">Any type of screen or device</span>
                </div>
              </Card>
              <Card>
                <img src={allInOnePlace} />
                <div className="label">View your orders all in one place</div>
              </Card>
            </div>
            <div className="card-row">
              <Card>
                <img src={accountInfoUptodate} />
                <div className="label">Keep your account informaton up to date</div>
              </Card>
              <Card>
                <img src={detailsAvailable} />
                <div className="label">Details on our available wholesale range</div>
              </Card>
            </div>
            <div className="divider" />
            <div className="number-count-item-wrapper">
              <div className="number-count-item">
                <div className="count">9000</div>
                <div className="description">Products available</div>
              </div>
              <div className="number-count-item">
                <div className="count">5</div>
                <div className="description">Countries delivered to</div>
              </div>
              <div className="number-count-item">
                <div className="count">1400</div>
                <div className="description">Stores we deliver to</div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="subscription-wrapper">
        <img src={storeBanner} alt="morrisons store" className="img-responsive" />
        <div className="container">
          <div className="content">
            <h3>Interested? Let us know!</h3>
            <p>Enter your email address, let us know some details, as we'll be in touch as soon as we can.</p>
            <Button
              type="button"
              className="secondary"
              text="Let's get in touch!"
              onClick={showSignUp}
            />
          </div>
        </div>
      </section>
      <section className="signup-wrapper">
        {signupComplete && <div className="signup-complete" />}
        <div className="signup-container">
          <h4 className="signup-heading">Want to shop on our website? <br/>Let us know!</h4>
          <p className="signup-description">
            Fill out the following form, let us know some details, as we'll be in touch as soon as we can.
          </p>
          <form onSubmit={signup}>
            <TextInput
              className="signup-input"
              type="text"
              label="Full Name"
              name="signupname"
              value={signupName.value}
              error={signupName.error}
              onChange={(value: string) => setSignupName({ value, error: '' })}
            />
            <TextInput
              className="signup-input"
              type="email"
              label="Email Address"
              name="signupemail"
              value={signupEmail.value}
              error={signupEmail.error}
              onChange={(value: string) => setSignupEmail({ value, error: '' })}
            />
            <TextInput
              className="signup-input"
              type="tel"
              label="Phone Number"
              name="signupphone"
              value={signupPhone.value}
              error={signupPhone.error}
              onChange={(value: string) => setSignupPhone({ value, error: '' })}
            />
            <TextInput
              className="signup-input"
              type="text"
              label="Company Name"
              name="signupcompany"
              value={signupCompany.value}
              error={signupCompany.error}
              onChange={(value: string) => setSignupCompany({ value, error: '' })}
            />
            <TextArea
              className="signup-input"
              label="Reason to join us"
              name="signupreason"
              value={signupReason.value}
              validationError={signupReason.error}
              onChange={(value: string) => setSignupReason({ value, error: '' })}
            />
            {
              signupRecaptcha.error &&
              <span className="recaptcha-error-text">
                {signupRecaptcha.error}
              </span>
            }
            <ReCAPTCHA
              onChange={(value: string) => setSignupRecaptcha({ value, error: '' })}
              reference={recaptchaRef}
            />
            <Button
              disabled={signUpResult.loading}
              type="submit"
              className="secondary signup-submit"
              text="Let's get in touch!"
            />
            <Button
              type="button"
              className="link signup-cancel"
              text="No thanks, I'll do it later"
              onClick={() => setSignupActive(false)}
            />
            <div className={`submitted-container ${signupComplete ? 'open' : 'closed'}`}>
              <img src={registrationComplete} />
              <div className="submitted-info">
                <span className="submitted-message">
                  Your details were successfully submitted!
                </span>
                <Button
                  type="button"
                  className="link submitted-cancel"
                  text="Back to homepage"
                  onClick={() => setSignupActive(false)}
                />
              </div>
            </div>
          </form>
        </div>
      </section>
    </div>
  );
};

const mapStateToProps = (state: IStoreState) => {
  return {
    signUpResult: state.signUp,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<any>) => {
  return {
    signUp: (parameters: ISignUpParameters, onSuccess: () => void) : void => dispatch(signUpRequest(parameters, onSuccess)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(HomePage);
