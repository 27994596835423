import { Reducer } from 'redux';
import {
  GET_TRADING_GROUPS_ERROR,
  GET_TRADING_GROUPS_REQUEST,
  GET_TRADING_GROUPS_RESPONSE } from '../actions/tradingGroupsAction';
import { ITradingGroups } from '../types';

const initialState: ITradingGroups = [];

const tradingGroupsReducer: Reducer<ITradingGroups>
  = (state: ITradingGroups = initialState, action : any) => {
    switch (action.type) {
      case GET_TRADING_GROUPS_RESPONSE: {
        return {
          ...state,
          ...action.response,
        };
      }
      case GET_TRADING_GROUPS_REQUEST:
      case GET_TRADING_GROUPS_ERROR:
      default: {
        return state;
      }
    }
  };

export default tradingGroupsReducer;
