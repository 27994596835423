import { Reducer } from 'redux';
import { IApiRequest, IBasket } from '../../types';
import {
  UPDATE_ORDER_PO_NUMBER_REQUEST,
  UPDATE_ORDER_PO_NUMBER_RESPONSE,
  UPDATE_ORDER_PO_NUMBER_ERROR,
} from '../../actions/Basket/updateOrderPoNumberAction';

const initialState: IApiRequest<IBasket> = {
  data: null,
  loading: false,
  error: '',
  UpdatedAt: null,
};

const updateOrderPoNumberReducer: Reducer<IApiRequest<IBasket>> = (
  state: IApiRequest<IBasket> = initialState,
  action: any,
) => {
  switch (action.type) {
    case UPDATE_ORDER_PO_NUMBER_REQUEST: {
      return {
        ...initialState,
        loading: true,
      };
    }
    case UPDATE_ORDER_PO_NUMBER_RESPONSE: {
      return {
        ...initialState,
        loading: false,
      };
    }
    case UPDATE_ORDER_PO_NUMBER_ERROR: {
      return {
        ...initialState,
        error: action.response,
      };
    }
    default: {
      return state;
    }
  }
};

export default updateOrderPoNumberReducer;
