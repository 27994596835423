import React, { useState, useEffect } from 'react';
import GoogleReCaptcha from 'react-google-recaptcha';
import './ReCAPTCHA.scss';
import configureApi from '../../api/apiConfig/apiConfig';
import IConfigSettings from '../../api/apiConfig/IConfigSettings';
import Settings from '../../settings';

interface IReCAPTCHAProps {
  onChange: (value: string) => void;
  reference?: React.RefObject<GoogleReCaptcha>;
}

const ReCAPTCHA = ({ onChange, reference = null } : IReCAPTCHAProps) => {
  const [settings, setSettings] = useState(undefined);

  useEffect(
    () => {
      if (settings === undefined || settings.RecaptchaKey === 'undefined') {
        configureApi()
          .then(() => {
            const settings = Settings();
            setSettings(settings);
          })
          .catch(() => {
            console.log('An error occurred fetching api config.');
          });
      }
    },
    []);

  return (
    <div className="recaptcha-container">
      {
        settings !== undefined && settings.RecaptchaKey !== 'undefined' &&
        <GoogleReCaptcha
          ref={reference}
          onChange={onChange}
          sitekey={settings.RecaptchaKey}
        />
      }
    </div>);
};

export default ReCAPTCHA;
