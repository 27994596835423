import React, { useCallback, useEffect, useState } from 'react';
import { IMobileFiltersActionProps } from './interfaces/IMobileFiltersActionProps';
import './MobileFiltersAction.scss';
import MobileFiltersActionHelper from './helper/MobileFiltersActionHelper';
import {
  CSSTransition, TransitionGroup,
} from 'react-transition-group';

const MobileFiltersAction = ({ attributes, config }: IMobileFiltersActionProps): JSX.Element => {
  const [showBackdrop, setShowBackdrop] = useState(false);

  const setApplyFilters = useCallback(
    (stringToApply: string, elseValue = ''): string => {

      return (
        config.showFilters &&
        (MobileFiltersActionHelper.isApplyFilters || config.isApplyFilters) ? stringToApply : elseValue
      );
    },
    [
      config.showFilters,
      config.isApplyFilters,
      MobileFiltersActionHelper.isApplyFilters,
    ],
  );

  const handleShowOrHideFilters = (): void => {
    if (config && config.showFilters) {
      setShowBackdrop(true);
    } else {
      MobileFiltersActionHelper.enableBodyScroll();
      setShowBackdrop(false);
    }
  };

  useEffect(handleShowOrHideFilters, [config, showBackdrop, MobileFiltersActionHelper.isApplyFilters]);

  return (
    <React.Fragment>
      {
        <TransitionGroup className="MobileFiltersAction-transitionGroupContainer">
          {
            showBackdrop && <CSSTransition timeout={{ enter: 100, exit: 500 }}>
              {
                <div
                  className="MobileFiltersAction-backdrop"
                />
              }
            </CSSTransition>}
        </TransitionGroup>
      }
      {
        <div
          id={(attributes && attributes.id) ? attributes.id : 'mobileFiltersActionContainer'}
          className={
            `MobileFiltersAction-container ${
              (attributes && attributes.className) ? attributes.className : ''
            }`
          }
        >
          <button
            className={
              `MobileFiltersAction-filtersAction ${setApplyFilters('MobileFiltersAction--applyFilters')}`}
            onClick={config.onClick}
          >
            {`${setApplyFilters('Apply Filters', 'Filters')}`}
          </button>
        </div>
      }
    </React.Fragment>
  );
};

export default MobileFiltersAction;
