import Fetch from './apiConfig/apiConfig';
import { AxiosPromise } from 'axios';
import { ICatalogueMeta } from '../types';

export interface IGetCatalogueMetaResponse {
  Products_GetCatalogueMetaQueue_R: ICatalogueMeta;
}

export const getCatalogueMeta = () : AxiosPromise<IGetCatalogueMetaResponse> => {
  return Fetch().then(axios => axios.get('catalogue/meta'));
};
