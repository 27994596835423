import Fetch from '../apiConfig/apiConfig';
import { AxiosPromise } from 'axios';

export interface IGetRebatesParams {
  retailerId: number;
  storeId: number;
  date: string;
  limit: number;
  offset: number;
}

export const getRebatesApi = (params: IGetRebatesParams): AxiosPromise => {
  const { retailerId, storeId, date, limit, offset } = params;
  return Fetch().then(axios =>
    axios.get(
      `rebates/get?retailerId=${retailerId}&storeId=${storeId}&date=${date}&limit=${limit}&offset=${offset}`,
    ));
};
