import { Dispatch } from 'redux';
import {
  IApiRequest,
  IPresaleCampaign,
  IPresalesState,
  IRetailer,
  IRetailerStore,
  IStoreState,
  IUserDetails,
} from '../../types';
import React, { useEffect, useRef, useState } from 'react';
import { arrayBufferToBase64 } from '../../helpers/ArrayBufferHelper';
import { connect } from 'react-redux';
import { getCustomerGroupsRequest } from '../../actions/customerGroupsAction';
import Modal from '../Modal/Modal';
import LoadingThrobber from '../LoadingThrobber/LoadingThrobber';
import {
  Button,
  Card,
  Checkbox,
  Dropdown,
  DropdownOption,
  StyledCalendar,
  TextInput,
} from '@orderly/morrisons-component-library';
import './PresaleCampaignEditor.scss';
import { getRetailersRequest } from '../../actions/retailersAction';
import { navigateTo } from '../../routing/Navigation';
import { getBasePath } from '../../helpers/Users/UserHelper';

interface IPresaleCampaignEditorProps {
  retailers?: IApiRequest<IRetailer[]>;
  userDetails?: IUserDetails;
  customerGroups?: IApiRequest<string[]>;
  presaleCampaign?: IPresaleCampaign;
  isButtonDisabled?: boolean;
  loading: boolean;
  dbActiveCampaigns? :IPresaleCampaign[];
  action: string;
  uploadResults: string[];
  showUploadResponseModal: boolean;
  template?: any;
  filteredCustomerList?: any[];
  visibleStoreList?: any[];
  campaignPublished?: boolean;
  campaignExpired?: boolean;
  setPresaleCampaign: (campaign: IPresaleCampaign) => void;
  setShowUploadResponseModal: React.Dispatch<React.SetStateAction<boolean>>;
  submitCampaignAction: () => void;
  setUploadResults: (results: any[]) => void;
  setTemplate: (template: any) => void;
  setFilteredCustomerList: (customers: any[]) => void;
  setVisibleStoreList: (stores: any[]) => void;
  publishCampaignAction?: () => void;
  getCustomerGroups?: () => void;
  getRetailers?: (forceLoad: boolean) => void;
}

const PresalesCampaignEditor = ({
  retailers,
  customerGroups,
  presaleCampaign,
  action,
  userDetails,
  isButtonDisabled,
  uploadResults,
  showUploadResponseModal,
  template,
  filteredCustomerList,
  visibleStoreList,
  campaignPublished,
  campaignExpired,
  loading,
  dbActiveCampaigns,
  setPresaleCampaign,
  setShowUploadResponseModal,
  getCustomerGroups,
  getRetailers,
  submitCampaignAction,
  publishCampaignAction,
  setUploadResults,
  setTemplate,
  setFilteredCustomerList,
  setVisibleStoreList,
}: IPresaleCampaignEditorProps) => {
  const [errors, setErrors] = useState<{ [key: string]: string }>({});
  const uploadPresalesCampaignElement = useRef(null);
  const [allRetailerStores, setAllRetailerStores] = useState([]);
  const [fetchFlag, setFetchFlag] = useState<boolean>(false);
  const [selectAllCustomersToggle, setSelectAllCustomersToggle] = useState<boolean>(false);
  const [associatedCampaignList, setAssociatedCampaignList] = useState<DropdownOption[]>([]);
  const [retailersWithStoresList, setRetailersWithStoresList] = useState([]);
  const [isEditMode, setIsEditMode] = useState(action === 'Edit');

  const wordboundaryRegex = /\b\w/g;
  const hyphenRegex = /-/g;

  const customerGroupsSelected = presaleCampaign.assignmentType === 'CustomerGroup';
  const campaignExpiredOrPublished = campaignPublished || campaignExpired;

  useEffect(() => {
    setSelectAllCustomersToggle(false);

    if (customerGroupsSelected) {
      setVisibleStoreList([]);
      updateCampaign({ storeIds: [] });

      if (!customerGroups.data.length) {
        getCustomerGroups();
      } else {
        setFetchFlag(!fetchFlag);
      }
    } else {
      updateCampaign({ customerGroups: [] });
      if (!retailers.data.length) {
        getRetailers(true);
      }
    }
  }, [presaleCampaign.assignmentType]);

  useEffect(() => {
    if (!retailers.loading && retailers.data && !uploadResults.length) {
      const retailersList: any[] = [];
      retailers.data.forEach((retailer) => {
        if (retailer.stores.length > 0) {

          retailersList.push({
            id: retailer.id,
            externalIdentifier: retailer.externalIdentifier,
            checked: false,
            stores:retailer.stores,
          });
        }
      });

      setRetailersWithStoresList(retailersList);
    }
  }, [retailers]);

  useEffect(() => {
    if (retailersWithStoresList.length && !uploadResults.length) {
      const retailerList: any[] = [];
      const retailerStoreList: any[] = [];
      retailersWithStoresList.forEach((retailer) => {
        retailer.stores.map((store: IRetailerStore) => {
          retailerStoreList.push({
            storeId: store.id,
            externalId: store.externalId,
            storeName: store.name,
            retailerId: store.retailerId,
            checked: true,
          });
        });

        retailerList.push({
          value: retailer.id,
          label: retailer.externalIdentifier,
          checked: false,
        });
      });
      setAllRetailerStores(retailerStoreList);
      setFilteredCustomerList(retailerList);
    }
  }, [retailersWithStoresList, presaleCampaign.assignmentType]);

  useEffect(() => {
    if (
      !customerGroups.loading &&
      customerGroups.data &&
      presaleCampaign.assignmentType === 'CustomerGroup' &&
      !uploadResults.length
    ) {
      const sortedCustomerGroups = customerGroups.data.sort((a, b) => orderAlphabetically(a, b));

      const formatLabel = (str: string): string => {
        return str
          .replace(hyphenRegex, ' ')
          .replace(wordboundaryRegex, char => char.toUpperCase());
      };
      const customerGroupList: any[] = [];

      sortedCustomerGroups.forEach((customerGroup, index) => {
        customerGroupList.push({
          value: index,
          label: formatLabel(customerGroup),
          checked: false,
          name: customerGroup,
        });
      });
      setFilteredCustomerList(customerGroupList);
    }
  }, [customerGroups, fetchFlag]);

  useEffect(() => {
    if (!isEditMode && !customerGroupsSelected && allRetailerStores && !uploadResults.length) {
      const tempStores = allRetailerStores.filter((store) => {
        return filteredCustomerList.some(
          retailer => retailer.checked && retailer.value === store.retailerId,
        );
      });

      const selectedStoreIds = tempStores
        .filter(store => store.checked)
        .map(store => store.storeId);

      updateCampaign({ storeIds: selectedStoreIds });
      setVisibleStoreList(tempStores.sort((a, b) => orderAlphabetically(a.name, b.name)));
    } else if (!isEditMode && customerGroupsSelected && filteredCustomerList) {
      const selectedCustomerGroupNames = filteredCustomerList
        .filter(customer => customer.checked)
        .map(customer => customer.name);

      updateCampaign({ customerGroups: selectedCustomerGroupNames });
    }
  }, [filteredCustomerList]);

  useEffect(() => {
    let options: DropdownOption[] = [
       { value: 0, label: 'None' },
      ...dbActiveCampaigns.map(campaign => ({
        value: campaign.id,
        label: campaign.name,
      })).sort((a, b) => orderAlphabetically(a.label, b.label)),
    ];

    if (presaleCampaign) {
      options = options.filter(campaign => campaign.value !== presaleCampaign.id);
    }

    setAssociatedCampaignList(options);
  }, [dbActiveCampaigns]);

  useEffect(() => {
    if (isEditMode && presaleCampaign.storeIds.length) {
      if (retailersWithStoresList.length) {
        const assignedStoreIds = presaleCampaign.storeIds;

        const retailerList = retailersWithStoresList.map(retailer => ({
          value: retailer.id,
          label: retailer.externalIdentifier,
          checked: retailer.stores.some((store: IRetailerStore) => assignedStoreIds.includes(store.id)),
          stores: retailer.stores,
        }));

        const storeListWithChecked = retailerList.flatMap(retailer =>
          retailer.stores.filter((store: IRetailerStore) => assignedStoreIds.includes(store.id))
            .map((store: IRetailerStore) => ({
              storeId: store.id,
              externalId: store.externalId,
              storeName: store.name,
              retailerId: store.retailerId,
              checked: true,
            })),
        );

        setFilteredCustomerList(retailerList);
        setVisibleStoreList(storeListWithChecked.sort((a, b) => orderAlphabetically(a.name, b.name)));
        setIsEditMode(false);
      }
    }
  }, [retailersWithStoresList]);

  const orderAlphabetically = (a: string, b: string) => {
    if (a < b) {
      return -1;
    }

    if (a > b) {
      return 1;
    }
    return 0;
  };

  const toggleAllCustomersSelection = () => {
    const updatedList = filteredCustomerList.map((customer) => {
      return { ...customer, checked: !selectAllCustomersToggle };
    });

    setFilteredCustomerList(updatedList);
    setSelectAllCustomersToggle(!selectAllCustomersToggle);
  };

  const selectCustomer = (checkboxId: string) => {
    const retailerId = parseInt(checkboxId, 0);

    const updatedList = filteredCustomerList.map((customer) => {
      if (customer.value === retailerId) {
        return { ...customer, checked: !customer.checked };
      }
      return customer;
    });

    setFilteredCustomerList(updatedList);
  };

  const toggleStoreSelection = (checkboxId: string) => {
    if (allRetailerStores) {
      const storeId = parseInt(checkboxId, 0);

      const updatedStoreList = visibleStoreList.map((store) => {
        if (store.storeId === storeId) {
          return { ...store, checked: !store.checked };
        }
        return store;
      });
      setVisibleStoreList(updatedStoreList);

      const selectedStoreIds = updatedStoreList
        .filter(store => store.checked)
        .map(store => store.storeId);

      updateCampaign({ storeIds: selectedStoreIds });

      const allRetailerStoresList = allRetailerStores.map((store) => {
        if (store.storeId === storeId) {
          return { ...store, checked: !store.checked };
        }
        return store;
      });

      setAllRetailerStores(allRetailerStoresList);

      const retailerList = retailersWithStoresList.map(retailer => ({
        value: retailer.id,
        label: retailer.externalIdentifier,
        checked: retailer.stores.some((store:IRetailerStore) => selectedStoreIds.includes(store.id)),
        stores: retailer.stores,
      }));

      setFilteredCustomerList(retailerList);
    }
  };

  const onTemplateUpload = (element: React.MutableRefObject<HTMLInputElement>): void => {
    const [file] = element.current.files;

    const allowedTypes = [
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      'application/vnd.ms-excel',
    ];

    if (!allowedTypes.includes(file.type)) {
      alert('Please upload a valid Excel file.');
      element.current.value = '';
      return;
    }

    if (file) {
      const fileReader = new FileReader();

      fileReader.onabort = () => console.log('File reading was aborted');
      fileReader.onerror = () => console.log('File reading has failed');
      fileReader.onload = () => {
        const fileData = fileReader.result;
        const base64Data = arrayBufferToBase64(fileData as ArrayBuffer);

        updateCampaign({ template: base64Data });
        setTemplate(file);
        element.current.value = null;
      };

      fileReader.readAsArrayBuffer(file);
    }
  };

  const validateInput = (value: string, maxLength?: number) => {
    const trimmedValue = value.trim();
    if (maxLength) {
      return trimmedValue.length > 0 && trimmedValue.length <= maxLength;
    }
    return trimmedValue.length > 0;
  };

  const validateName = () => {
    const isValid = validateInput(presaleCampaign.name, 150);
    setErrors(prevErrors => ({
      ...prevErrors,
      name: isValid ? undefined : 'Presale name is required and must be 150 characters or less',
    }));
    return isValid;
  };

  const onSubmit = () => {
    if (!isButtonDisabled) {
      submitCampaignAction();
    } else {
      window.scrollTo(0, 0);
      return false;
    }
  };

  const buttonTitle = () => {
    if (isButtonDisabled) {
      if (!isEditMode) {
        return 'Complete Required Data';
      }

      if (isEditMode) {
        return 'No changes detected';
      }
    }

    return !isEditMode ? 'Create' : 'Edit';
  };

  const updateCampaign = (updatedFields: Partial<IPresaleCampaign>) => {
    const updatedCampaign = { ...presaleCampaign, ...updatedFields };
    setPresaleCampaign(updatedCampaign);
  };

  const closeModal = () => {
    setUploadResults([]);
    setShowUploadResponseModal(false);
  };

  return (
    <div className="presales-editor-container">
      {(customerGroups.loading || retailers.loading || loading) && <LoadingThrobber />}
      <div className="presales-create-row">
        <Card className={`presales-details-column flex-column ${campaignExpiredOrPublished ? 'isDisabled' : ''}`}>
          <h4>Details</h4>
          <div className="input-container">
            {campaignExpiredOrPublished ? (
              <h5>Presale Name: {presaleCampaign.name}</h5>
            ) : (
              <>
                <TextInput
                  className="presales-name-input"
                  label="Presales name"
                  name="presales-name-input"
                  onChange={(updatedName: string) => updateCampaign({ name: updatedName })}
                  type="text"
                  value={presaleCampaign.name}
                  onBlur={validateName}
                />
                {errors.name && <span className="error">{errors.name}</span>}
              </>
            )}
          </div>
          <div className="input-container deadline">
            <h5>Deadline</h5>
            <StyledCalendar
              minDate={
                campaignExpiredOrPublished ? new Date(presaleCampaign.deadline) : new Date()
              }
              maxDate={campaignExpiredOrPublished ? new Date(presaleCampaign.deadline) : null}
              value={
                presaleCampaign.deadline ? new Date(presaleCampaign.deadline) : new Date()
              }
              onChange={(updatedDate: any) =>
                updateCampaign({ deadline: updatedDate.toDateString() })
              }
              selectRange={false}
            />
          </div>
          <div className="input-container selectionContainer">
            <div className="extendLines">
              <Checkbox
                checked={presaleCampaign.extendLines}
                label="Extend Lines?"
                name="extend-lines"
                onChange={(_, checked) => updateCampaign({ extendLines: checked })}
                isSmall={true}
                className="extend-lines-checkbox"
                disabled={campaignExpiredOrPublished}
              />
            </div>
            <div className="campaignAssociation selection">
              <div className="title">
                <p>Associated Campaign</p>
              </div>
                <div className="dropdown">
                  <Dropdown
                    className="campaign-association-selection"
                    options={associatedCampaignList}
                    selectedValue={presaleCampaign.associatedCampaignId ? presaleCampaign.associatedCampaignId : 0}
                    label=""
                    name="CampaignAssociationSelection"
                    onChange={(value: DropdownOption) =>
                      updateCampaign({ associatedCampaignId: parseInt(value.value.toString(), 10) })
                    }
                  />
                </div>
            </div>
            <div className="customerSelection selection">
                {!campaignPublished && (
                  <>
                    <div className="title">
                      <p>Customer Selection</p>
                    </div>
                    <div className="dropdown">
                      <Dropdown
                        className="customer-selection-type"
                        options={[
                          { value: 'CustomerGroup', label: 'Customer Groups' },
                          { value: 'Retailer', label: 'Retailers' },
                        ]}
                        selectedValue={presaleCampaign.assignmentType}
                        label=""
                        name="CustomerSelectionType"
                        onChange={(value: DropdownOption) =>
                          updateCampaign({ assignmentType: value.value.toString() })
                        }
                      />
                    </div>
                  </>
                )}
            </div>
          </div>
        </Card>

        <Card className="presales-unselected-customers-column flex-column">
          <div className="unselected-customers-header">
            <h4>{customerGroupsSelected ? 'Customer Groups' : 'Retailers'}</h4>
            <Button
              text={`${selectAllCustomersToggle ? 'Remove all' : 'Assign all'}`}
              type="button"
              onClick={toggleAllCustomersSelection}
              className="tertiary small-button"
              disabled={campaignExpiredOrPublished}
            />
          </div>
          {filteredCustomerList && (
            <div className="customer-select-container">
              <div className="table-container">
                <table>
                  <tbody>
                    {filteredCustomerList.length === 0 ? (
                      <tr>
                        <td>No Customers Available</td>
                      </tr>
                    ) : (
                      filteredCustomerList.map((customer: any, index: number) => {
                        return (
                          <tr key={index}>
                            <td>
                              <Checkbox
                                checked={customer.checked}
                                label={customer.label}
                                name={customer.value.toString()}
                                onChange={selectCustomer}
                                isSmall={true}
                                disabled={campaignExpiredOrPublished}
                              />
                            </td>
                          </tr>
                        );
                      })
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          )}
        </Card>

        <Card
          className={`presales-selected-stores-column flex-column ${
            customerGroupsSelected ? 'hidden' : ''
          }`}
        >
          <div className="selected-store-header">
            <h4>Selected stores</h4>
          </div>
          {visibleStoreList && (
            <div className="customer-select-container">
              <div className="table-container">
                <table>
                  <tbody>
                    {visibleStoreList.length === 0 ? (
                      <tr>
                        <td>No Stores Selected</td>
                      </tr>
                    ) : (
                      visibleStoreList.map((store: any, index) => {
                        return (
                          <tr key={index}>
                            <td>
                              <Checkbox
                                checked={store.checked}
                                label={`${store.storeName} (${store.externalId})`}
                                name={store.storeId}
                                onChange={toggleStoreSelection}
                                isSmall={true}
                                disabled={campaignExpiredOrPublished}
                              />
                            </td>
                          </tr>
                        );
                      })
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          )}
        </Card>
      </div>
      <div className="footer">
        {!campaignExpiredOrPublished && (
          <div className="upload-template-action-container">
            <input
              type="file"
              accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
              ref={uploadPresalesCampaignElement}
              style={{ display: 'none' }}
              onChange={() => onTemplateUpload(uploadPresalesCampaignElement)}
              aria-label="Upload presales campaign template"
            />
            <div className="create-campaign-action-container" title={'Upload Template'}>
              <Button
                className="upload-campaign-template secondary"
                type="button"
                text="Upload Template"
                onClick={(): void => uploadPresalesCampaignElement.current.click()}
                disabled={campaignExpiredOrPublished}
              />
              {template ? (
                <span>Template uploaded: {template.name}</span>
              ) : (
                <span>Please upload a campaign template</span>
              )}
            </div>
          </div>
        )}
        <div className="submit-container">
          {!campaignExpiredOrPublished && (
            <div className="create-campaign-action-container" title={buttonTitle()}>
              <Button
                className="create-campaign-action-submit tertiary"
                type="submit"
                text={action !== 'Edit' ? 'Create' : 'Edit'}
                onClick={onSubmit}
                disabled={isButtonDisabled}
              />
            </div>
          )}
          {action === 'Edit' && (
          <>
            <div
              className="preview-campaign-products-action-container"
              title="Review campaign products"
            >
              <Button
                className="preview-campaign-products-action tertiary"
                type="submit"
                text="Review campaign products"
                onClick={() =>
                  navigateTo(`${getBasePath(userDetails)}presales-campaigns/preview/campaign/${presaleCampaign.id}`)}
              />
            </div>
            { !campaignExpired && (
            <div
              className="publish-campaign-action-container"
              title={`${campaignPublished ? 'Unpublish ' : 'Publish '}campaign`}
            >
              <Button
                className="publish-campaign-action-submit secondary"
                type="submit"
                text={campaignPublished ? 'Unpublish' : 'Publish'}
                onClick={publishCampaignAction}
              />
            </div>
            )}
          </>
          )}
        </div>
      </div>
      <Modal header="Upload Errors" isOpen={showUploadResponseModal} onClose={() => closeModal()}>
        <ul>
          {uploadResults.map((result, index) => (
            <li key={index}>{result}</li>
          ))}
        </ul>
      </Modal>
    </div>
  );
};

const mapStateToProps = (state: IStoreState) => {
  return {
    userDetails: state.userDetails,
    retailers: state.retailers,
    customerGroups: state.customerGroups,
    loading: state.presales.loading,
    dbActiveCampaigns: state.presales.campaigns.activeCampaigns,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<any>) => ({
  getCustomerGroups: () => dispatch(getCustomerGroupsRequest()),
  getRetailers: (forceLoad: boolean) => dispatch(getRetailersRequest(forceLoad)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PresalesCampaignEditor);
