import { OrderTextStatuses } from '../../components/OrderStatus/OrderStatus';
import { IOrder } from '../../pages/Orders/IOrder';
import FormatDateHelper from '../Format/Date/FormatDateHelper';

export const orderClaimEligibilityFilter = (orders: IOrder[]) => {
  const currentDate = new Date();
  const minDeliveryDate = FormatDateHelper.GetDateExcludingWeekends(currentDate, 48);
  const fomattedMinDeliveryDate = FormatDateHelper.formatDate(minDeliveryDate);
  const formatedCurrentDate = FormatDateHelper.formatDate(currentDate);

  const filteredOrderNumbers = orders
    .filter(
      x =>
        x.deliveryDate >= fomattedMinDeliveryDate &&
        x.deliveryDate <= formatedCurrentDate &&
        (x.status === OrderTextStatuses.SHIPPED || x.status === OrderTextStatuses.COMPLETE),
    )
    .map(order => order.id);

  return filteredOrderNumbers;
};
