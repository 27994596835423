import { Dispatch } from 'redux';
import {
  deleteTradingPack,
  getTradingPack,
  getTradingPacks,
  ISaveTradingPackBody,
  saveTradingPack,
} from '../../api/Products';
import { ITradingPack } from '../../types';
import { toastr } from 'react-redux-toastr';

export const GET_TRADING_PACKS_REQUEST = 'GET_TRADING_PACKS_REQUEST';
export const GET_TRADING_PACKS_RESPONSE = 'GET_TRADING_PACKS_RESPONSE';
export const GET_TRADING_PACKS_ERROR = 'GET_TRADING_PACKS_ERROR';

export const getTradingPacksRequest = (): (dispatch: Dispatch) => Promise<void> => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: GET_TRADING_PACKS_REQUEST,
    });

    try {
      const response = await getTradingPacks();
      if (response.status === 200) {
        dispatch({
          type: GET_TRADING_PACKS_RESPONSE,
          response: response.data.tradingPacks,
        });
      } else {
        dispatch({
          type: GET_TRADING_PACKS_ERROR,
        });
      }
    } catch (err) {
      console.log(err);
      dispatch({
        type: GET_TRADING_PACKS_ERROR,
      });
    }
  };
};

export const GET_TRADING_PACK_REQUEST = 'GET_TRADING_PACK_REQUEST';
export const GET_TRADING_PACK_RESPONSE = 'GET_TRADING_PACK_RESPONSE';
export const GET_TRADING_PACK_ERROR = 'GET_TRADING_PACK_ERROR';

export const getTradingPackRequest = (id: number): (dispatch: Dispatch) => Promise<void> => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: GET_TRADING_PACK_REQUEST,
    });

    if (id === -1) {
      const newPack: ITradingPack = {
        id: -1,
        description: '',
        items: [],
        categories: [],
        locations: [],
        endDate: '',
        startDate: '',
        createdAt: '',
        retailerIds: [],
        customerGroups: [],
        isExpired: false,
        isActive: false,
      };

      dispatch({
        type: GET_TRADING_PACK_RESPONSE,
        response: newPack,
      });

      return;
    }

    try {
      const response = await getTradingPack(id);
      if (response.status === 200) {
        dispatch({
          type: GET_TRADING_PACK_RESPONSE,
          response: response.data.tradingPack,
        });
      } else {
        dispatch({
          type: GET_TRADING_PACK_ERROR,
        });
      }
    } catch (err) {
      console.log(err);
      dispatch({
        type: GET_TRADING_PACK_ERROR,
      });
    }
  };
};

export const DELETE_TRADING_PACKS_REQUEST = 'DELETE_TRADING_PACKS_REQUEST';
export const DELETE_TRADING_PACKS_RESPONSE = 'DELETE_TRADING_PACKS_RESPONSE';
export const DELETE_TRADING_PACKS_ERROR = 'DELETE_TRADING_PACKS_ERROR';

export const deleteTradingPackRequest = (id: number): (dispatch: Dispatch) => Promise<void> => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: DELETE_TRADING_PACKS_REQUEST,
    });

    try {
      const response = await deleteTradingPack(id);
      if (response.status === 200) {
        dispatch({
          type: DELETE_TRADING_PACKS_RESPONSE,
          response: response.data.tradingPacks,
        });
      } else {
        dispatch({
          type: DELETE_TRADING_PACKS_ERROR,
        });
      }
    } catch (err) {
      console.log(err);
      dispatch({
        type: DELETE_TRADING_PACKS_ERROR,
      });
    }
  };
};

export const SAVE_TRADING_PACK_REQUEST = 'SAVE_TRADING_PACK_REQUEST';
export const SAVE_TRADING_PACK_RESPONSE = 'SAVE_TRADING_PACK_RESPONSE';
export const SAVE_TRADING_PACK_ERROR = 'SAVE_TRADING_PACK_ERROR';

export const saveTradingPackRequest = (
  body: ISaveTradingPackBody,
  onSuccess: () => void,
  onError: () => void,
): (dispatch: Dispatch) => Promise<void> => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: SAVE_TRADING_PACK_REQUEST,
    });

    try {
      const response = await saveTradingPack(body);
      if (response.status === 200) {
        const { results } = response.data;
        dispatch({
          type: SAVE_TRADING_PACK_RESPONSE,
          response: results,
        });
        if (results.length === 0) {
          toastr.success(
            body.id > 0 ? 'Trading Pack Updated' : 'Trading Pack Created',
            `${body.description} has been ${body.id > 0 ? 'updated' : 'created'} successfully.`,
          );
          onSuccess();
        } else {
          onError();
        }
      } else {
        dispatch({
          type: SAVE_TRADING_PACK_ERROR,
        });
      }
    } catch (err) {
      console.log(err);
      dispatch({
        type: SAVE_TRADING_PACK_ERROR,
      });
    }
  };
};
