import Fetch from '../apiConfig/apiConfig';
import { AxiosPromise } from 'axios';
import queryString from 'query-string';
import { IPendingOrder } from '../../types';

export interface IGetUserPendingOrdersParams {
  [key: string]: string;
  orderNumber?: string;
  storeId?: string;
  modifiedDate?: string;
  deliveryDate?: string;
  categoryCode?: string;
}

export interface IGetUserPendingOrdersResponse {
  pendingOrders: IPendingOrder[];
}

export const getUserPendingOrders = (
  params: IGetUserPendingOrdersParams,
): AxiosPromise<IGetUserPendingOrdersResponse> => {
  const parameters: IGetUserPendingOrdersParams = {
    orderNumber: params.orderNumber || null,
    storeId: params.storeId || null,
    modifiedDate: params.modifiedDate || null,
    deliveryDate: params.deliveryDate || null,
    categoryCode: params.categoryCode || null,
  };

  return Fetch().then(axios =>
    axios.get(`orders/pending/get?${queryString.stringify(parameters)}`),
  );
};
