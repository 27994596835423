// React
import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { connect, ConnectedComponentClass } from 'react-redux';
import { IApiRequest, IRetailerDetails, IStoreState, IUserDetails } from '../types';
import { isStaff, isLoggedIn, hasRetailerMeta } from '../helpers/Users/UserHelper';

interface IPrivateRouteParams {
  Component: ConnectedComponentClass<any, any>;
  userDetails: IUserDetails;
  retailerDetails: IApiRequest<IRetailerDetails>;
  retailerMeta?: { [key: string]: string };
}

const isUserAuthorized = (userDetails: any, retailerMeta: any, retailerDetails: any): boolean => {
  return isLoggedIn(userDetails) &&
    !isStaff(userDetails) &&
    (!retailerMeta || (retailerMeta && hasRetailerMeta(retailerMeta, retailerDetails)));
};

const PrivateRoute = ({
  component: Component,
  userDetails,
  retailerDetails,
  retailerMeta, ...rest
}: IPrivateRouteParams & any) => (
  <Route
    {...rest}
    render={props =>
      isUserAuthorized(userDetails, retailerMeta, retailerDetails) ? (
        <Component {...props} />
      ) : (
        <Redirect
          to={{
            pathname: '/login',
            state: { from: props.location },
          }}
        />
      )
    }
  />
);

const mapStateToProps = (state: IStoreState) => {
  return {
    userDetails: state.userDetails,
    retailerDetails: state.retailerDetails,
  };
};

export default connect(mapStateToProps)(PrivateRoute);
