import { IRetailerStoreDocumentsParams } from '../../api/StoreDocuments/Interfaces/IRetailerStoreDocumentsParams';
import { getRetailerStoreDocuments } from '../../api/StoreDocuments/getRetailerStoreDocumentsApi';

export const GET_RETAILER_STORE_DOCUMENTS_REQUEST = 'GET_RETAILER_STORE_DOCUMENTS_REQUEST';
export const GET_RETAILER_STORE_DOCUMENTS_RESPONSE = 'GET_RETAILER_STORE_DOCUMENTS_RESPONSE';
export const GET_RETAILER_STORE_DOCUMENTS_ERROR = 'GET_RETAILER_STORE_DOCUMENTS_ERROR';

export const getRetailerStoreDocumentsRequest = (parameters: IRetailerStoreDocumentsParams): any => {
  return async (dispatch: any) => {
    dispatch({
      type: GET_RETAILER_STORE_DOCUMENTS_REQUEST,
    });

    try {
      const response = await getRetailerStoreDocuments(parameters);
      if (response.status === 200) {
        dispatch({
          type: GET_RETAILER_STORE_DOCUMENTS_RESPONSE,
          response: response.data.storeDocuments,
        });
      } else {
        if (response instanceof Error) {
          onError(dispatch);
        }
      }
    } catch (error) {
      onError(dispatch);
    }
  };
};

const onError = (dispatch: any) => dispatch({
  type: GET_RETAILER_STORE_DOCUMENTS_ERROR,
});
