import React, { useMemo, useRef, useState } from 'react';
import { ITradingPackDetailProps } from './ITradingPackDetailProps';
import { Button, Card, Checkbox, TextInput } from '@orderly/morrisons-component-library';
import styles from './TradingPackDetail.module.scss';
import { IRetailer, IStoreState } from '../../types';
import { arrayBufferToBase64 } from '../../helpers/ArrayBufferHelper';
import { Dispatch } from 'redux';
import { ISaveTradingPackBody } from '../../api/Products';
import { saveTradingPackRequest } from '../../actions/Products';
import { connect } from 'react-redux';
import { navigateTo } from '../../routing/Navigation';
import Modal from '../Modal/Modal';
import FormatDateHelper from '../../helpers/Format/Date/FormatDateHelper';

function TradingPackDetail({
  tradingPack,
  customerGroups,
  retailers,
  saveLoading,
  errors,
  saveTradingPack,
}: ITradingPackDetailProps): JSX.Element {
  const isNewTradingPack = tradingPack.id === -1;
  const [description, setDescription] = useState<string>(tradingPack.description);
  const [startDateError, setStartDateError] = useState<string>('');
  const [endDateError, setEndDateError] = useState<string>('');
  const [selectedCustomerGroups, setSelectedCustomerGroups] = useState<string[]>(tradingPack.customerGroups);
  const [selectedRetailers, setSelectedRetailers] = useState<number[]>(tradingPack.retailerIds);
  const [customerGroupSearch, setCustomerGroupSearch] = useState<string>('');
  const [retailerSearch, setRetailerSearch] = useState<string>('');
  const [importItems, setImportItems] = useState<boolean>(isNewTradingPack);
  const [errorModalOpen, setErrorModalOpen] = useState<boolean>(false);
  const [startDate, setStartDate] = useState<string>(
    FormatDateHelper.formatDate(isNewTradingPack ? new Date() : new Date(tradingPack.startDate)),
  );
  const [endDate, setEndDate] = useState<string>(
    FormatDateHelper.formatDate(isNewTradingPack ? new Date() : new Date(tradingPack.endDate)),
  );
  const importTradingPackRef = useRef(null);
  const allowSave =
    description.length !== 0 && startDateError.length === 0 && endDateError.length === 0 && (
      selectedRetailers.length > 0 || selectedCustomerGroups.length > 0);

  const onStartDateChange = (value: string) => {
    const newStartDate = new Date(value);

    if (newStartDate > new Date(endDate)) {
      setStartDateError('Start date must be before the end date');
    } else {
      setStartDateError('');
      setEndDateError('');
    }

    setStartDate(value);
  };

  const onEndDateChange = (value: string) => {
    const newEndDate = new Date(value);

    if (newEndDate < new Date(startDate)) {
      setEndDateError('End date must be after the start date');
    } else {
      setStartDateError('');
      setEndDateError('');
    }

    setEndDate(value);
  };

  const onCustomerGroupSelected = (group: string, checked: boolean) => {
    let groups = selectedCustomerGroups;
    if (checked) {
      groups.push(group);
    } else {
      groups = groups.filter(x => x !== group);
    }

    setSelectedCustomerGroups([...groups]);
  };

  const onRetailerSelected = (retailerId: number, checked: boolean): void => {
    let newSelectedRetailers = selectedRetailers;
    if (checked) {
      newSelectedRetailers.push(retailerId);
    } else {
      newSelectedRetailers = newSelectedRetailers.filter(x => x !== retailerId);
    }

    setSelectedRetailers([...newSelectedRetailers]);
  };

  const filteredCustomerGroups = useMemo<string[]>(
    () => {
      if (customerGroupSearch.length > 0) {
        return customerGroups
          .filter(x => x
            .toLowerCase()
            .includes(customerGroupSearch.toLowerCase()),
          );
      }

      return customerGroups;
    },
    [customerGroups, customerGroupSearch],
  );

  const filteredRetailers = useMemo<IRetailer[]>(
    () => {
      if (retailerSearch.length > 0) {
        return retailers
          .filter(x => x.externalIdentifier
            .toLowerCase()
            .includes(retailerSearch.toLowerCase()),
          );
      }

      return retailers;
    },
    [retailers, retailerSearch],
  );

  const onFileUpload = (): void => {
    const file = importTradingPackRef.current.files[0];
    if (file) {
      const reader = new FileReader();
      reader.addEventListener('load', () => {
        const file = arrayBufferToBase64(reader.result as ArrayBuffer);
        saveCurrentTradingPack(file);
      });
      reader.readAsArrayBuffer(file);
    }
  };

  const saveCurrentTradingPack = (file?: string) => {
    const body: ISaveTradingPackBody = {
      description,
      startDate,
      endDate,
      importItems,
      id: tradingPack.id,
      retailerIds: selectedRetailers,
      customerGroups: selectedCustomerGroups,
      data: file ? file : '',
    };

    saveTradingPack(
      body,
      () => navigateTo('/admin/trading-packs'),
      onSaveError,
    );
  };

  const onSaveError = () => {
    setErrorModalOpen(true);
  };

  const onSaveClicked = () => {
    if (importItems) {
      importTradingPackRef.current.click();
    } else {
      saveCurrentTradingPack();
    }
  };

  const onDescriptionChange = (value: string) => {
    setDescription(value.substring(0, 255));
  };

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <Card className={styles.detail}>
          <h4>
            Trading Pack Details
          </h4>
          <TextInput
            error=""
            className=""
            label="Description"
            name="tradingPackDescription"
            onChange={onDescriptionChange}
            type="text"
            value={description}
          />
          <TextInput
            error={startDateError}
            className=""
            label="Start Date"
            name="tradingPackStartDate"
            onChange={onStartDateChange}
            type="date"
            value={startDate}
          />
          <TextInput
            error={endDateError}
            className=""
            label="End Date"
            name="tradingPackEndDate"
            onChange={onEndDateChange}
            type="date"
            value={endDate}
          />
          <Checkbox
            className=""
            checked={importItems}
            key={'import'}
            label="Import Items"
            name={'importItems'}
            onChange={(id, checked) => setImportItems(checked)}
            isSmall={true}
          />
        </Card>
        <Card className={styles.customerGroups}>
          <h4>
            Customer Groups
          </h4>
          <div className={styles.search}>
            <TextInput
              error=""
              className=""
              label="Search"
              name="customerGroupSearch"
              onChange={setCustomerGroupSearch}
              type="text"
              value={customerGroupSearch}
            />
          </div>
          <div className={styles.maxHeight}>
            {
              filteredCustomerGroups.map(
                group => <Checkbox
                  className=""
                  checked={selectedCustomerGroups.includes(group)}
                  key={`chk_customer_group_${group}`}
                  label={group}
                  name={`customer_group_${group}`}
                  onChange={(id, checked) => onCustomerGroupSelected(group, checked)}
                  isSmall={true}
                />,
              )
            }
          </div>
        </Card>
        <Card className={styles.customerGroups}>
          <h4>
            Retailers
          </h4>
          <div className={styles.search}>
            <TextInput
              error=""
              className=""
              label="Search"
              name="retailerSearch"
              onChange={setRetailerSearch}
              type="text"
              value={retailerSearch}
            />
          </div>
          <div className={styles.maxHeight}>
            {
              filteredRetailers.map((retailer) => {
                return (
                  <Checkbox
                    className=""
                    checked={selectedRetailers.includes(retailer.id)}
                    key={`chk_retailer_${retailer.id}`}
                    label={retailer.externalIdentifier}
                    name={`retailer_${retailer.id}`}
                    onChange={(id, checked) => onRetailerSelected(retailer.id, checked)}
                    isSmall={true}
                  />
                );
              })
            }
          </div>
        </Card>
      </div>
      <div className={styles.controls}>
        <input
          type="file"
          accept=".xlsx"
          ref={importTradingPackRef}
          style={{
            display: 'none',
          }}
          onChange={onFileUpload}
          aria-label="Upload Trading Pack"
        />
        <Button
          type="button"
          className="tertiary"
          text={tradingPack.id === -1 ? 'Create' : 'Save'}
          disabled={!allowSave || saveLoading}
          onClick={onSaveClicked}
        />
      </div>
      <Modal
        header="Uploading error"
        isOpen={errorModalOpen}
        onClose={() => setErrorModalOpen(false)}
        buttonText="Close"
      >
        {
          errors.map(e => <p key={e}>{e}</p>)}
      </Modal>
    </div>
  );
}

const mapStateToProps = (state: IStoreState) => {
  return {
    saveLoading: state.saveTradingPack.loading,
    errors: state.saveTradingPack.data,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<any>) => {
  return {
    saveTradingPack: (
      body: ISaveTradingPackBody,
      onSuccess: () => void,
      onError: () => void,
    ) => dispatch(saveTradingPackRequest(body, onSuccess, onError)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TradingPackDetail);
