import { Reducer } from 'redux';
import { IPersistedOrderFilters } from '../../../types';

const persistOrderFilters: Reducer<IPersistedOrderFilters> =
(state: IPersistedOrderFilters = null, action: any): IPersistedOrderFilters => {
  if (action.type === 'ORDERS_LIST_FILTERS_PERSIST') {
    return {
      date: action.payload.date,
      dateType: action.payload.dateType,
      statusFilters: action.payload.statusFilters,
    };
  }
  return state;
};

export default persistOrderFilters;
