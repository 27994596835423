import React, { Dispatch } from 'react';
import { connect } from 'react-redux';
import { Router } from 'react-router-dom';
import Header from './components/Header/Header';
import Footer from './components/Footer/Footer';
import { IStoreState } from './types';
import ReduxToastr from 'react-redux-toastr';
import configureApi from './api/apiConfig/apiConfig';
import History from './routing/History';
import ScrollTop from './routing/ScrollTop';
import { getCatalogueMetaRequest } from './actions/catalogueMetaAction';
import Routes from './routing/Routes';
import BuildSettings from './BuildSettings.json';
import { purgeStore } from './actions/purgeStoreAction';
import { LastLocationProvider } from 'react-router-last-location';

class App extends React.Component<any, any> {

  public componentDidMount(): any {
    configureApi()
      .then(() => {
        this.props.getCatalogueMeta();
      })
      .catch((reason: any) => {
        console.error('Failed to confiure api.');
      });

    const versionNumber = localStorage.getItem('version_number');

    if (BuildSettings.BuildNumber !== versionNumber) {
      this.props.purgeStore();
      localStorage.setItem('version_number', BuildSettings.BuildNumber);
    }
  }

  public render(): any {
    const { siteWrapper } = this.props;

    return (
      <div className="App">
        <Router history={History}>
          <LastLocationProvider>
            {siteWrapper.show && <Header />}
            <main className="site-content">
              <ScrollTop />
              <Routes />
            </main>
            {siteWrapper.show && <Footer />}
          </LastLocationProvider>
        </Router>
        <ReduxToastr preventDuplicates={true} transitionIn="fadeIn" transitionOut="fadeOut" />
      </div>
    );
  }
}

const mapStateToProps = (state: IStoreState) => {
  return {
    siteWrapper: state.siteWrapper,
  };
};

const mapDispatchToProps = (dispatch : Dispatch<any>) => {
  return {
    getCatalogueMeta: () => dispatch(getCatalogueMetaRequest()),
    purgeStore: () => dispatch(purgeStore()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
