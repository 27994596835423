import { IOrderEdnState } from './IOrderEdnState';

const initialState: IOrderEdnState = {
  data: null,
  loading: true,
  error: '',
};

const ordersEdnReducer = (state: any = initialState, action: any): IOrderEdnState => {
  switch (action.type) {
    case 'GET_ORDER_EDN_REQUEST': {
      return {
        ...state,
        loading: true,
      };
    }
    case 'GET_ORDER_EDN_RESPONSE': {
      return {
        data: action.response,
        loading: false,
        error: null,
      };
    }
    case 'GET_ORDER_EDN_ERROR': {
      return {
        data: null,
        loading: false,
        error: 'An error has occured',
      };
    }
    case 'CLEAR_ORDER_EDN_CONTENTS': {
      return initialState;
    }
    default: {
      return state;
    }
  }
};

export default ordersEdnReducer;
