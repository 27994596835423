import { Reducer } from 'redux';
import { IApiRequest, IDocument } from '../../types/index';
import { DELETE_DOCUMENT_RESPONSE, DELETE_DOCUMENT_REQUEST, DELETE_DOCUMENT_ERROR }
from '../../actions/Documents/deleteDocumentAction';

const initialState: IApiRequest<IDocument> = {
  data: undefined,
  error: '',
  UpdatedAt: null,
  loading: false,
};

const deleteDocumentReducer: Reducer<IApiRequest<IDocument>>
= (state: IApiRequest<IDocument> = initialState, action : any) => {
  switch (action.type) {
    case DELETE_DOCUMENT_RESPONSE: {
      return {
        data: action.response,
        error: '',
        UpdatedAt: new Date(),
        loading: false,
      };
    }
    case DELETE_DOCUMENT_REQUEST: {
      return {
        data: undefined,
        error: '',
        UpdatedAt: null,
        loading: true,
      };
    }
    case DELETE_DOCUMENT_ERROR:
    default: {
      return state;
    }
  }
};

export default deleteDocumentReducer;
