import React from 'react';
import './Table.scss';
import { ITableProps } from './ITableProps';
import useTable from './hook/useTable.hook';

const isHeaderSorted = (columnName: string, sortedColumnName: string, sortedDescending: boolean): any => {
  if (columnName === sortedColumnName) {
    return <span className={sortedDescending ? 'Table-arrowDown' : 'Table-arrowUp'} />;
  }
  return null;
};

const Table = ({ className, children }: ITableProps): JSX.Element => {
  const {
    stickyStyles,
    tableRef,
    tableWrapperRef,
    hideScrollBar,
    isMSEdge,
  } = useTable(children);

  return (
    <div className={`Table-wrapper ${hideScrollBar}`} ref={tableWrapperRef}>
      <div
        id="tableContainer"
        className="Table-container"
      >
        {
          (!isMSEdge) && (!!stickyStyles.left) && <div
            className="Table--sticky"
            style={{
              left: (stickyStyles.left - 11),
              height: stickyStyles.height,
            }}
          />
        }
        <table
          className={`Table-table ${className ? className : ''} `}
          ref={tableRef}
        >
          {children}
        </table>
      </div>
    </div>
  );
};

export default Table;

export {
  isHeaderSorted,
};
