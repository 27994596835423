import { IOrdersDisplayModeAction } from './IOrdersDisplayModeAction';

export const ordersDisplayModeAction = (
  displayMode: string,
): IOrdersDisplayModeAction => {
  return {
    type: 'ORDERS_DISPLAY_MODE_PERSIST',
    data: {
      displayMode,
    },
  };
};
