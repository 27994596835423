export const preventBodyScroll = () => {
  document.documentElement.classList.add('scroll-prevent');
  window.scrollTo(0, window.scrollY);
};

export const allowBodyScroll = () => {
  document.documentElement.classList.remove('scroll-prevent');
};

export default {
  preventBodyScroll,
  allowBodyScroll,
};
