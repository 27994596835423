import React, { Dispatch, useEffect, useMemo, useRef, useState } from 'react';
import {
  getRetailerProductPricingExportRequest,
  getRetailerProductPricingRequest,
  importRetailerProductPricingRequest,
} from '../../../actions/Products';
import { getRetailersRequest } from '../../../actions/retailersAction';
import { IRetailerProductPricingExport, IStoreState } from '../../../types';
import { connect } from 'react-redux';
import { FullPageLayout } from '../../../layouts';
import { IRetailerProductPricingProps } from './IRetailerProductPricingProps';
import Table from '../../../components/Table/Table';
import { Button } from '@orderly/morrisons-component-library';
import { formatCurrency, formatShortDateString } from '../../../helpers/Format';
import { LoadingThrobber, Modal, SearchBar } from '../../../components';
import { GenerateDownload } from '../../../helpers/DownloadHelper';
import { arrayBufferToBase64 } from '../../../helpers/ArrayBufferHelper';
import { IImportRetailerProductPricingBody } from '../../../api/Products';
import styles from './RetailerProductPricing.module.scss';

function RetailerProductPricing({
  match,
  retailers,
  retailerProductPricing,
  getRetailerProductPricing,
  getRetailers,
  exportRetailerProductPricing,
  exportRetailerProductPricingReq,
  importRetailerProductPricing,
  importRequest,
}: Readonly<IRetailerProductPricingProps>): JSX.Element {
  const retailerId: number = parseInt(match.params.id, 10);
  const retailer = retailers.filter(x => x.id === retailerId)[0];
  const importRef = useRef(null);
  const [errorModalOpen, setErrorModalOpen] = useState<boolean>(false);
  const [search, setSearch] = useState('');
  const parts = window.location.pathname.split('/');
  const newPath = `/${parts[1]}/home`;

  useEffect(
    () => {
      getRetailers();
      getRetailerProductPricing(retailerId);
    },
    [retailerId],
  );

  const title = useMemo(
    () => {
      if (!retailer) {
        return 'Product Pricing';
      }

      return `${retailer.externalIdentifier} - Product Pricing`;
    },
    [retailer],
  );

  const onExport = () => {
    exportRetailerProductPricingReq(retailerId, onExportComplete);
  };

  const onExportComplete = (file: IRetailerProductPricingExport) => {
    GenerateDownload(file.fileData, file.fileName);
  };

  const onFileUpload = (): void => {
    const fileInput = importRef.current;
    const file = fileInput.files[0];

    if (file) {
      const reader = new FileReader();
      reader.addEventListener('load', () => {
        const file = arrayBufferToBase64(reader.result as ArrayBuffer);
        onImportRetailerProductPricing(file);

        fileInput.value = '';
      });
      reader.readAsArrayBuffer(file);
    }
  };

  const onImportRetailerProductPricing = (file: string) => {
    importRetailerProductPricing({
      retailerId,
      data: file,
    },
      () => getRetailerProductPricing(retailerId),
      onSaveError,
    );
  };

  const onSaveError = () => {
    getRetailerProductPricing(retailerId);
    setErrorModalOpen(true);
  };

  const results = useMemo(
    () => {
      const prices = retailerProductPricing.data;
      if (prices.length === 0) {
        return prices;
      }

      if (search.length > 0) {
        const searchVal = search.toLowerCase().trimStart().trimEnd();
        return prices.filter(
          x => x.itemId.toString().toLowerCase().includes(searchVal) ||
          x.description.toLowerCase().includes(searchVal),
        );
      }

      return prices;
    },
    [retailerProductPricing, search],
  );

  const dataTable = useMemo(
    () => {
      return (
        <div>
          <div className={styles.help}>
            <i>
              Uploading a new sheet will remove any items not included,
              please ensure a fresh export is completed before re-importing.
              <br />
              <ul>
                <li>
                  If the current day is between the trading promotional dates,
                  unit / case price will be used, if not standard unit / case price will be used.
                </li>
                <li>
                  If the current day is between the retail promotional dates,
                  RRP will be used, if not standard RRP will be used.
                </li>
              </ul>
            </i>
          </div>
          <Table className={''}>
            <thead>
              <tr>
                <th>Description</th>
                <th>Item Id</th>
                <th>Unit Price</th>
                <th>Standard Unit Price</th>
                <th>Case Price</th>
                <th>Standard Case Price</th>
                <th>RRP</th>
                <th>Standard RRP</th>
                <th>Start Date</th>
                <th>End Date</th>
              </tr>
            </thead>
            <tbody>
              {
                results.length > 0 ?
                  results.map((price) => {
                    return (
                      <tr key={`table-row-${price.itemId}`}>
                        <td>{price.description}</td>
                        <td>{price.itemId}</td>
                        <td>{formatCurrency(price.unitPrice)}</td>
                        <td>{formatCurrency(price.standardUnitPrice)}</td>
                        <td>{formatCurrency(price.casePrice)}</td>
                        <td>{formatCurrency(price.standardCasePrice)}</td>
                        <td>{formatCurrency(price.recommendedRetailPrice)}</td>
                        <td>{formatCurrency(price.standardRecommendedRetailPrice)}</td>
                        <td>{formatShortDateString(price.startDate)}</td>
                        <td>{formatShortDateString(price.endDate)}</td>
                      </tr>
                    );
                  }) :
                  <tr>
                    <td colSpan={10}>No records can be found for this retailer</td>
                  </tr>
              }
            </tbody>
          </Table>
        </div>
      );
    },
    [results],
  );

  return (
    <FullPageLayout
      heading={title}
      breadcrumbs={[
        {
          key: 0,
          text: title,
          url: window.location.pathname,
        },
      ]}
      homeRoute={newPath}
      headerAdditionalContent={
        <div className={styles.headerContent}>
          <input
            type="file"
            accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
            ref={importRef}
            style={{ display: 'none' }}
            onChange={() => onFileUpload()}
          />
          <Button
            type="button"
            className="secondary"
            text="Import"
            onClick={() => importRef.current.click()}
            disabled={importRequest.loading}
          />
          <Button
            type="button"
            className="tertiary"
            text="Export"
            onClick={onExport}
            disabled={exportRetailerProductPricing.loading}
          />
        </div>}
    >
      <div className={styles.container}>
        <SearchBar
          label="Search for item (by description or Item Id)"
          value={search}
          onChange={value => setSearch(value)}
        />
        {
          retailerProductPricing.loading ?
            <LoadingThrobber /> :
            dataTable
        }
        <Modal
          header="Import Error"
          isOpen={errorModalOpen}
          onClose={() => setErrorModalOpen(false)}
          buttonText="Close"
        >
          {
            importRequest.data.map(e => <p key={e}>{e}</p>)}
        </Modal>
      </div>
    </FullPageLayout>
  );
}

const mapStateToProps = (state: IStoreState) => {
  return {
    retailerProductPricing: state.retailerProductPricing,
    retailers: state.retailers.data,
    exportRetailerProductPricing: state.exportRetailerProductPricing,
    importRequest: state.importRetailerProductPricing,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<any>) => {
  return {
    getRetailers: () => dispatch(getRetailersRequest()),
    getRetailerProductPricing: (id: number) => dispatch(getRetailerProductPricingRequest(id)),
    exportRetailerProductPricingReq: (
      id: number, onSuccess: (data: IRetailerProductPricingExport) => void,
    ) => dispatch(getRetailerProductPricingExportRequest(id, onSuccess)),
    importRetailerProductPricing: (
      body: IImportRetailerProductPricingBody,
      onSuccess: () => void,
      onError: () => void,
    ) => dispatch(importRetailerProductPricingRequest(body, onSuccess, onError)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(RetailerProductPricing);
