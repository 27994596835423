import { Breadcrumbs } from '@orderly/morrisons-component-library';
import React, { useEffect, useState } from 'react';
import { Dispatch } from 'redux';
import { navigateTo } from '../../../routing/Navigation';
import { getBasePath } from '../../../helpers/Users/UserHelper';
import { IApiRequest, IStoreState, IUserDetails, IOrderClaimReview } from '../../../types';
import { connect } from 'react-redux';
import { IReviewOrderClaimsDisplayModeState } from './IReviewOrderClaimsDisplayModeState';
import { viewClaimsDisplayModeAction } from '../../../actions/Orders/viewClaimsDisplayModeAction';
import LoadingThrobber from '../../../components/LoadingThrobber/LoadingThrobber';
import ReviewClaimsTable from '../../../components/OrderClaims/ReviewClaimsTable';
import { getOrderClaimsRequest } from '../../../actions/Orders/OrderClaims/orderClaimsAction';

interface IViewClaimsProps {
  userDetails: IUserDetails;
  orderClaims: IApiRequest<IOrderClaimReview[]>;
  persistedClaimsDisplayMode: IReviewOrderClaimsDisplayModeState;
  getClaims: (resolvedClaims: boolean) => void;
  persistClaimsDisplayMode: (displayMode: string) => Dispatch;
}

const ViewClaims = ({
  userDetails,
  getClaims,
  orderClaims,
  persistedClaimsDisplayMode,
  persistClaimsDisplayMode,
}: IViewClaimsProps) => {
  const [claims, setClaims] = useState<IOrderClaimReview[]>([]);

  useEffect(() => {
    const resolvedClaims = persistedClaimsDisplayMode.displayMode === 'active' ? false : true;
    getClaims(resolvedClaims);
  }, [persistedClaimsDisplayMode.displayMode]);

  useEffect(() => {
    if (orderClaims.data) {
      setClaims(orderClaims.data);
    }
  }, [orderClaims]);

  return (
    <div className="review-claims-page">
      <Breadcrumbs
        onHomeClick={() => navigateTo(`${getBasePath(userDetails)}home`)}
        onRedirect={() => navigateTo(window.location.pathname)}
        segments={[{ key: 0, text: 'Review Claims', url: '' }]}
      />
      <div className="content card">
        <section className="review-claims-section">
          <div className="Claims-headingAndFilterContainer">
            <div className="Claims-headingContainer">
              <h1 className="Claims-heading">
                Review
                {persistedClaimsDisplayMode.displayMode === 'processed'
                  ? ' Processed '
                  : ' Active '}
                Claims
              </h1>
            </div>
            <div className="Claims-display-filters">
              <div className="Claims-display-mode">
                <button
                  className={`btn-display-mode
                        ${
                          persistedClaimsDisplayMode.displayMode === 'active'
                            ? 'active'
                            : 'inactive'
                        }`}
                  type="button"
                  onClick={() => {
                    persistClaimsDisplayMode('active');
                  }}
                >
                  Active
                </button>
                <button
                  className={`btn-display-mode
                        ${
                          persistedClaimsDisplayMode.displayMode === 'processed'
                            ? 'active'
                            : 'inactive'
                        }`}
                  type="button"
                  onClick={() => {
                    persistClaimsDisplayMode('processed');
                  }}
                >
                  Processed
                </button>
              </div>
            </div>
          </div>
        </section>
        {orderClaims.loading && <LoadingThrobber />}
        <ReviewClaimsTable
          claimsList={claims}
          displayMode={persistedClaimsDisplayMode.displayMode}
        />
      </div>
    </div>
  );
};

const mapStateToProps = (state: IStoreState) => {
  return {
    userDetails: state.userDetails,
    persistedClaimsDisplayMode: state.reviewOrderClaimsDisplayMode,
    orderClaims: state.orderClaimsReview,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<any>) => {
  return {
    persistClaimsDisplayMode: (persistedClaimsDisplayMode: string) =>
      dispatch(viewClaimsDisplayModeAction(persistedClaimsDisplayMode)),
    getClaims: (resolvedClaims: boolean) => dispatch(getOrderClaimsRequest(resolvedClaims)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ViewClaims);
