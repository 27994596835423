import React, { Dispatch, useEffect, useState } from 'react';
import { TextInput } from '@orderly/morrisons-component-library';
import './OrdersListSearch.scss';
import { IOrder } from '../../../pages/Orders/IOrder';
import { IStoreState } from '../../../types';
import { connect } from 'react-redux';
import { IOrdersListSearchProps } from './IOrdersListSearchProps';
import { persistOrderListSearchAction } from '../../../actions/Orders/OrderList/persistOrderListSearchAction';
import { useLastLocation } from 'react-router-last-location';
import { orderStatusLabels } from '../../../helpers/Orders/OrdersListFilters/OrderStatusFilters';
import MobileFiltersActionHelper from '../../MobileFiltersAction/helper/MobileFiltersActionHelper';

const OrdersListSearch = (
    {
      config,
      persistOrderListSearch,
      persistOrderListSearchData,
      mappedOrders,
    }: IOrdersListSearchProps,
): JSX.Element => {
  const [searchValue, setSearchValue] = useState('');
  const lastLocation = useLastLocation();

  useEffect(
    (): void => {
      const isOrdersLocation: boolean = (lastLocation && lastLocation.pathname.indexOf('/orders/') === 0);

      if (isOrdersLocation && persistOrderListSearchData.searchedValue) {
        setSearchValue(persistOrderListSearchData.searchedValue);
      }

      if (!persistOrderListSearchData.searchedValue) {
        setSearchValue('');
        config.onClearingPersistedValue();
      }
    },
    [
      persistOrderListSearchData.searchedValue,
      config.fieldToSearch,
    ],
  );

  const handleOnTextInputChange = (value: string): any => {
    const ordersCollection: IOrder[] = config.listToSearch.length ? config.listToSearch : mappedOrders;

    setSearchValue(value);

    if (value) {
      const searched = ordersCollection.filter((item: IOrder): any => {
        return (item[config.fieldToSearch] as string).search(value) !== -1;
      });

      MobileFiltersActionHelper.isApplyFilters = true;

      persistOrderListSearch(value, searched, config.fieldToSearch);
    } else {
      persistOrderListSearch('', []);
      MobileFiltersActionHelper.isApplyFilters = false;
      // Reset the list back to order status filters list
      config.triggerOrderStatusFilter(orderStatusLabels);
    }
  };

  return (
      <div className="OrdersListSearch-container">
        <div className="OrdersListFilters-labelContainer">
          {
            !config.isImpersonating
            &&
            <span className="OrdersListFilters-label">Order ID</span>
          }
        </div>
        <div className="OrdersListSearch-textInputContainer">
          <TextInput
              error=""
              className="OrdersListSearch-input icon-search"
              label="Search"
              name="orderId"
              onChange={handleOnTextInputChange}
              type="text"
              value={searchValue}
          />
        </div>
      </div>
  );
};

const MapStateToProps = (state: IStoreState): any => {
  return {
    mappedOrders: state.ordersList.data && state.ordersList.data.mappedOrders,
    filteredOrders: state.filteredOrdersList.data,
    persistOrderListSearchData: state.persistOrderListSearch.data,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<any>): any => {
  return {
    persistOrderListSearch: (searchedValue: string, searchedOrders: IOrder[], fieldToSearch: string) => dispatch(
        persistOrderListSearchAction(searchedValue, searchedOrders, fieldToSearch),
    ),
  };
};

export default connect(MapStateToProps, mapDispatchToProps)(OrdersListSearch);
