import { Reducer } from 'redux';
import { ISearchResult, ISearchResults } from '../types';
import {
  GET_SEARCH_RESULTS_ERROR,
  GET_SEARCH_RESULTS_REQUEST,
  GET_SEARCH_RESULTS_RESPONSE,
} from '../actions/Search/types/GetSearchResults';

const defaultData: ISearchResult = {
  totalResults: 0,
  products: [],
  subCategories: [],
  canLoadMore: false,
};
const initialState: ISearchResults = {};

const searchResultsReducer: Reducer<ISearchResults> = (state: ISearchResults = initialState, action : any) => {
  switch (action.type) {
    case GET_SEARCH_RESULTS_RESPONSE: {
      if (state[action.searchPhrase] && state[action.searchPhrase].data) {
        const { products } = state[action.searchPhrase].data;
        const productResults = action.loadMore ? products.concat(action.response.products) : action.response.products;

        state[action.searchPhrase] = {
          data: {
            ...action.response,
            products: productResults,
          },
          loading: false,
          error: null,
          UpdatedAt: new Date(),
        };
      } else {
        state[action.searchPhrase] = {
          loading: false,
          error: null,
          data: action.response,
          UpdatedAt: new Date(),
        };
      }

      return { ...state };
    }
    case GET_SEARCH_RESULTS_REQUEST: {
      state[action.searchPhrase] = {
        loading: true,
        error: null,
        data: action.loadMore ? state[action.searchPhrase].data : defaultData,
        UpdatedAt: null,
      };
      return { ...state };
    }
    case GET_SEARCH_RESULTS_ERROR: {
      state[action.searchPhrase] = {
        loading: false,
        error: action.error || 'There was an error loading this product. Please check the URL and try again.',
        data: null,
        UpdatedAt: null };
      return { ...state };
    }
    default: {
      return { ...state };
    }
  }
};

export default searchResultsReducer;
