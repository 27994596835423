import Fetch from '../apiConfig/apiConfig';
import { AxiosPromise } from 'axios';

export interface IDeleteStoreDocumentParams {
  documentId: number;
}

export interface IDeleteStoreDocumentResponse {
  success: boolean;
}

export const deleteStoreDocument = (params: IDeleteStoreDocumentParams) : AxiosPromise<IDeleteStoreDocumentResponse> => {
  return Fetch().then(axios => axios.post('store-documents/admin/delete', params));
};
