import Fetch from '../apiConfig/apiConfig';
import queryString from 'query-string';
import { AxiosPromise } from 'axios';
import { IConfirmedOrdersParams } from './ConfirmedOrders/IConfirmedOrdersParams';
import { IConfirmedOrdersResponse } from './ConfirmedOrders/IConfirmedOrdersResponse';
import { IUserAcceptanceParams } from './ConfirmedOrders/IUserAcceptanceParams';
import { IUserAcceptanceResponse } from './ConfirmedOrders/IUserAcceptanceResponse';
import { IReport } from '../../types';
import { IImportSuppyDemandOrderFormParams } from './SupplyDemandOrderForm/IImportSuppyDemandOrderFormParams';
import { IImportSuppyDemandOrderFormResponse } from './SupplyDemandOrderForm/IImportSuppyDemandOrderFormResponse';
import { ISupplyDemandOrderFormParams } from './SupplyDemandOrderForm/ISupplyDemandOrderFormParams';
import { ISupplyDemandOrderFormResponse } from './SupplyDemandOrderForm/ISupplyDemandOrderFormResponse';
import { ICustomerOrderSalesReportParams } from './CustomerSales/ICustomerOrderSalesParams';
import { ICustomerItemSalesReportParams } from './CustomerSales/ICustomerItemSalesParams';
import { ICustomerOrderSalesReportResponse } from './CustomerSales/ICustomerOrderSalesReportResponse';
import { ICustomerItemSalesReportResponse } from './CustomerSales/ICustomerItemSalesReportResponse';
import { ICompletedOrdersParams } from './CompletedOrders/ICompletedOrdersParams';
import { ICompletedOrdersResponse } from './CompletedOrders/ICompletedOrdersResponse';

export const getConfirmedOrdersReport = (params: IConfirmedOrdersParams) : AxiosPromise<IConfirmedOrdersResponse> => {
  return Fetch().then(axios => axios.get(`reports/orders/confirmed?${queryString.stringify(params)}`));
};

export const getUserAcceptanceReport =
(params:  IUserAcceptanceParams): AxiosPromise<IUserAcceptanceResponse> => {
  return Fetch().then(axios => axios.get(`reports/documents/useracceptance?${queryString.stringify(params)}`));
};

export interface IProcessedBulkOrdersResponse {
  processedBulkOrders: IReport;
}

export const getProcessedBulkOrdersReport = () : AxiosPromise<IProcessedBulkOrdersResponse> => {
  return Fetch().then(axios => axios.get('reports/orders/processedbulk'));
};

export const importSupplyDemandOrderForm =
(params: IImportSuppyDemandOrderFormParams) : AxiosPromise<IImportSuppyDemandOrderFormResponse> => {
  return Fetch().then(axios => axios.post('orders/supplydemandform/import', params));
};

export const getSupplyDemandOrderForm =
(params: ISupplyDemandOrderFormParams) : AxiosPromise<ISupplyDemandOrderFormResponse> => {
  return Fetch().then(axios => axios.get(`reports/orders/supplydemandform?${queryString.stringify(params)}`));
};

export const getCustomerOrderSalesReport =
(params: ICustomerOrderSalesReportParams) : AxiosPromise<ICustomerOrderSalesReportResponse> => {
  return Fetch().then(axios => axios.get(`reports/orders/customerordersales?${queryString.stringify(params)}`));
};

export const getCustomerItemSalesReport =
(params: ICustomerItemSalesReportParams) : AxiosPromise<ICustomerItemSalesReportResponse> => {
  return Fetch().then(axios => axios.get(`reports/orders/customeritemsales?${queryString.stringify(params)}`));
};

export const getCompletedOrdersReport = (params: ICompletedOrdersParams) : AxiosPromise<ICompletedOrdersResponse> => {
  return Fetch().then(axios => axios.get(`reports/orders/completedorders?${queryString.stringify(params)}`));
};
