export enum Claims {
    User = -1,
    EWSAdmin = 1,
    Admin = 2,
    'Account Manager' = 3,
    'Credit Control' = 4,
    'Supply Team' = 5,
    'Logistics Team' = 6,
    'Finance Team' = 7,
    'Accounts Receivable' = 8,
}
