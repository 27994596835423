import React, { useEffect, useState } from 'react';
import { Button, Card, Checkbox, StyledCalendar, TextInput } from '@orderly/morrisons-component-library';
import { navigateTo } from '../../../routing/Navigation';
import QueryString from 'query-string';

import './UpdateFolder.scss';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { IUpdateFolderParams } from '../../../api/Documents/updateFolderApi';
import { updateFolderRequest } from '../../../actions/Documents/updateFolderAction';
import { FolderType, IStoreState } from '../../../types';
import { IUpdateFolderProps } from './IUpdateFolderProps';
import { getFolderContentsRequest } from '../../../actions/Documents/folderContentsAction';
import LoadingThrobber from '../../../components/LoadingThrobber/LoadingThrobber';
import { IGetFolderContentsParams } from '../../../api/Documents/getFolderContentsApi';
import { isPrintshopPage } from '../../../helpers/Documents/IsPrintshopPage';
import Modal from '../../../components/Modal/Modal';
import { constructNavigationLink } from '../../../helpers/Documents/DocumentNavigationHelper';
import { RetailerSubTypesObject } from '../../../helpers/SupplyChain/RetailerSubTypes.enum';
import { formatShortDateString } from '../../../helpers/Format';
import { toastr } from 'react-redux-toastr';

const UpdateFolder = (props: IUpdateFolderProps) => {
  const [name, setName] = useState('');
  const [expiryDate, setExpiryDate] = useState(null);
  const [calendarOpened, setCalendarOpened] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [subTypes, setSubTypes] = useState<number[]>([]);

  const { folderContents, getFolderContents, match, updateFolder } = props;

  useEffect(() => {
    const folderPath = getFolderPath();

    getFolderContents(
      {
        folderPath,
        retailerId: isPrintshopPage() ? -1 : match.params.retailerId,
        prefix: window.location.pathname.indexOf('/admin') === 0 ? 'admin/' : '',
        folderType: isPrintshopPage() ? FolderType.Printshop : FolderType.Document,
      });

    setName(getFolderName());
  }, []);

  useEffect(() => {
    if (folderContents.data) {
      setExpiryDate(folderContents.data.expiryDate);
      if (folderContents.data.retailerSubTypes) {
        setSubTypes(folderContents.data.retailerSubTypes);
      }
    }
  }, [folderContents.data]);

  const onFolderNameChanged = (name: string) => {
    if (!name.match(/^[a-zA-Z0-9 ]*$/)) {
      setError('Must not include special characters.');
    } else if (name.length > 30) {
      setError('Maximum length 30 characters.');
    } else {
      setError('');
    }

    setName(name);
  };

  const formSubmit = (e: React.FormEvent<HTMLElement>) => {
    e.preventDefault();
    onSaveFolder();
  };

  const getFolderName = () => {
    const folderName = QueryString.parse(window.location.search).name as string;
    return folderName.substring(folderName.lastIndexOf('/') + 1);
  };

  const getFolderPath = () => {
    return QueryString.parse(window.location.search).name as string;
  };

  const onSaveFolder = () => {
    if (name !== '') {
      setLoading(true);

      updateFolder(
        {
          expiryDate,
          retailerId: isPrintshopPage() ? -1 : match.params.retailerId,
          folderId: folderContents.data.folderId,
          updatedFolderName: name,
          folderType: isPrintshopPage() ? FolderType.Printshop : FolderType.Document,
          retailerSubTypes: subTypes,
        },
        () => {
          setLoading(false);
          toastr.success(
            'Success',
            'Folder was updated successfully.');
          navigateTo(constructNavigationLink(match, name));
        },
        () => {
          setLoading(false);
        });
    } else {
      throw new Error('Folder path must not be null');
    }
  };

  const isSubTypeSelected = (subType: string) => {
    if (subType === 'All' && subTypes.length === 0) {
      return true;
    }
    return subTypes.includes(RetailerSubTypesObject[subType]);
  };

  const onSubTypeSelect = (name: string, value: boolean) => {
    if (name === 'All') {
      setSubTypes([]);
    } else {
      if (value) {
        setSubTypes([...subTypes, RetailerSubTypesObject[name]]);
      } else {
        const index = subTypes.findIndex(x => x === RetailerSubTypesObject[name]);
        const newSubTypes = [...subTypes];
        newSubTypes.splice(index, 1);
        setSubTypes(newSubTypes);
      }
    }
  };

  return (
    <div className="outer-folder">
      {loading && <LoadingThrobber/>}
      {folderContents.loading && <LoadingThrobber />}
      <form noValidate onSubmit={e => formSubmit(e)}>
        <div className="folder">
          <Card className="upload-form">
            <h3>Folder Information</h3>
            <TextInput
              type="text"
              name="folderName"
              label="Folder Name"
              value={name}
              onChange={(name: string) => { onFolderNameChanged(name); }}
              error={error}
            />
            {
              !folderContents.loading && folderContents.data && isPrintshopPage() &&
              <div className="subTypes-checklist">
                <h4>Customer type visibility</h4>
                {
                  Object.keys(RetailerSubTypesObject).map((x) => {
                    return (
                      <Checkbox
                        key={x}
                        checked={isSubTypeSelected(x)}
                        label={x}
                        name={x}
                        onChange={(id, checked) => onSubTypeSelect(id, checked)}
                        isSmall={true}
                      />
                    );
                  })
                }
              </div>
            }

            <div className="update-folder actions save-controls">
              <div className="expiry-date-wrapper">
                {
                  isPrintshopPage() && folderContents.data.parentFolderId &&
                  <>
                    <Button
                      className={`expiry-date-btn ${expiryDate ? 'populated' : ''}`}
                      type="button"
                      text={expiryDate ? `Exp: ${formatShortDateString(expiryDate)}` : 'Set expiry date'}
                      onClick={() => setCalendarOpened(true)}
                    />
                    {expiryDate && <div className="unset-expiry-date" onClick={() => setExpiryDate(null)}>x</div>}
                  </>
                }
              </div>
              <div className="create-folder-action-buttons">
                <Button
                  type="button"
                  className="update-folder-action-cancel"
                  text="Cancel"
                  onClick={() =>
                    navigateTo(constructNavigationLink(match))}
                />
                <Button
                  type="button"
                  className="update-folder-action-update-new-folder"
                  text="Update Folder"
                  onClick={() => { onSaveFolder(); }}
                  disabled={error.length > 0}
                />
              </div>
            </div>
          </Card>
        </div>
      </form>
      <Modal
        header="Folder expiry date"
        isOpen={calendarOpened}
        onClose={() => setCalendarOpened(false)}
      >
        <StyledCalendar
          minDate={new Date()}
          value={expiryDate ? new Date(expiryDate) : new Date()}
          onChange={date => setExpiryDate(date as Date)}
          selectRange={false}
        />
      </Modal>
    </div>
  );
};

const mapStateToProps = (state: IStoreState) => {
  return {
    documentsUpdateFolder: state.documentsUpdateFolder,
    folderContents: state.documentsFolderContents,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<any>) => {
  return {
    getFolderContents: (parameters: IGetFolderContentsParams) => dispatch(getFolderContentsRequest(parameters, true)),
    updateFolder: (
      parameters: IUpdateFolderParams,
      onSuccess: () => void,
      onFailure: () => void) => dispatch(updateFolderRequest(parameters, onSuccess, onFailure)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UpdateFolder);
