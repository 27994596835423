import { Reducer } from 'redux';
import { USER_DETAILS } from '../actions/userDetailsAction';
import { IUserDetails } from '../types';

const initialState: IUserDetails = {
  userId: -1,
};

const userDetailsReducer: Reducer<IUserDetails> = (state: IUserDetails = initialState, action) => {
  if (action.type === USER_DETAILS) {
    return {
      ...state,
      ...action.payload,
    };
  }
  return state;
};

export default userDetailsReducer;
