import { Action } from 'redux';
import { IStoreState } from '../types';
import { store } from '../store';
import { AxiosResponse } from 'axios';
import { IGetCustomerGroupsResponse, getCustomerGroups } from '../api/CustomerGroups/customerGroupsApi';

export const GET_CUSTOMER_GROUPS_REQUEST = 'GET_CUSTOMER_GROUPS_REQUEST';
export type GET_CUSTOMER_GROUPS_REQUEST = typeof GET_CUSTOMER_GROUPS_REQUEST;
export const GET_CUSTOMER_GROUPS_RESPONSE = 'GET_CUSTOMER_GROUPS_RESPONSE';
export type GET_CUSTOMER_GROUPS_RESPONSE = typeof GET_CUSTOMER_GROUPS_RESPONSE;
export const GET_CUSTOMER_GROUPS_ERROR = 'GET_CUSTOMER_GROUPS_ERROR';
export type GET_CUSTOMER_GROUPS_ERROR = typeof GET_CUSTOMER_GROUPS_ERROR;

export interface IGetCustomerGroupsError extends Action {
  type: GET_CUSTOMER_GROUPS_ERROR;
  error?: string;
}

export const getCustomerGroupsRequest = (): (dispatch: any) => Promise<void> => {
  return (dispatch : any) => {

    const state : IStoreState = store.getState();

    if (state.customerGroups.data.length > 0 &&
        state.catalogueMeta.meta.updatedAt < state.customerGroups.UpdatedAt) {
      return;
    }

    dispatch({
      type: GET_CUSTOMER_GROUPS_REQUEST,
    });

    return getCustomerGroups()
      .then((response : AxiosResponse<IGetCustomerGroupsResponse>) => {
        if (response.status === 200) {

          dispatch({
            type: GET_CUSTOMER_GROUPS_RESPONSE,
            response: response.data.customerGroups,
          });

        } else {
          onError(dispatch);
        }
      })
      .catch((err: any) => {
        console.log(err);
        onError(dispatch);
      });
  };
};

const onError = (dispatch: any) => dispatch({
  type: GET_CUSTOMER_GROUPS_ERROR,
});
