import React from 'react';
import './Home.scss';
import { connect } from 'react-redux';
import PersonalDetailsCard from '../../../components/PersonalDetailsCard/PersonalDetailsCard';
import ActionsCard from '../../../components/ActionsCard/ActionsCard';
import ReportsCard from '../../../components/ReportsCard/ReportsCard';
import ImportsCard from '../../../components/ImportsCard/ImportsCard';
import ContactUsCard from '../../../components/ContactUsCard/ContactUsCard';

const Home = () => (
  <div className="supply-team-home">
    <div className="sidebar">
      <PersonalDetailsCard />
      <ActionsCard />
      <ReportsCard />
      <ImportsCard />
      <ContactUsCard />
    </div>
    <div className="content card">
      <h4>WIP</h4>
    </div>
  </div>
);

export default connect()(Home);
