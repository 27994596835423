import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { IDocumentBlob, IPresaleCampaign, IPresalesState, IStoreState, IUserDetails } from '../../../types';
import {
  deletePresalesCampaignRequest,
  downloadExamplePresalesTemplateRequest,
  getPresalesCampaignsRequest,
} from '../../../actions/SupplyChain/presalesActions';
import { Button, Tab, Tabs } from '@orderly/morrisons-component-library';
import { navigateTo } from '../../../routing/Navigation';
import { getBasePath } from '../../../helpers/Users/UserHelper';
import LoadingThrobber from '../../../components/LoadingThrobber/LoadingThrobber';
import AdminPresalesCampaignsTable from '../../../components/Presales/AdminPresaleCampaignsTable';
import FullPageLayout from '../../../layouts/FullPageLayout/FullPageLayout';
import './PresaleCampaignsList.scss';
import { GenerateDownload } from '../../../helpers/DownloadHelper';
import { toastr } from 'react-redux-toastr';

interface IPresaleCampaignsListProps {
  userDetails: IUserDetails;
  presales: IPresalesState;
  dbActiveCampaigns: IPresaleCampaign[];
  dbExpiredCampaigns: IPresaleCampaign[];
  getPresalesCampaigns: (expiredCampaigns: boolean) => void;
  deletePresalesCampaign: (campaignId: number) => void;
  downloadExamplePresalesTemplate: (onSuccess: (data: IDocumentBlob)
    => void, onFailure: () => void) => void;
}

const PresaleCampaignsList = ({
  userDetails,
  presales,
  dbActiveCampaigns,
  dbExpiredCampaigns,
  getPresalesCampaigns,
  deletePresalesCampaign,
  downloadExamplePresalesTemplate,
}: IPresaleCampaignsListProps) => {
  const [campaigns, setCampaigns] = useState<IPresaleCampaign[]>([]);
  const [activeCampaigns, setActiveCampaigns] = useState<IPresaleCampaign[]>([]);
  const [expiredCampaigns, setExpiredCampaigns] = useState<IPresaleCampaign[]>([]);
  const [activeTab, setActiveTab] = useState<string>('active-campaigns');

  useEffect(() => {
    presales.campaign = null;
  }, []);

  useEffect(() => {
    loadData();
  }, []);

  useEffect(() => {
    const processCampaigns = (
      dbCampaigns : IPresaleCampaign[],
      setCampaigns:React.Dispatch<React.SetStateAction<IPresaleCampaign[]>>,
    ) => {
      if (dbCampaigns) {
        const campaigns = dbCampaigns.map((campaign, index) => ({
          key: campaign.id,
          index: index + 1,
          ...campaign,
        }));
        setCampaigns(campaigns);
      }
    };

    processCampaigns(dbActiveCampaigns, setActiveCampaigns);
    processCampaigns(dbExpiredCampaigns, setExpiredCampaigns);
  }, [dbActiveCampaigns, dbExpiredCampaigns]);

  const loadData = () => {
    getPresalesCampaigns(true);
    getPresalesCampaigns(false);
  };

  const setCampaignLoading = (id: number, loading: boolean) => {
    const index = campaigns.findIndex(x => x.id === id);
    if (index > -1) {
      const currentCampaigns = [...campaigns];
      currentCampaigns[index].actions = loading.toString();
      setCampaigns([...currentCampaigns]);
    }
  };

  const downloadTemplate = () => {
    downloadExamplePresalesTemplate(
    (file: any) => {
      GenerateDownload(file.downloadTemplate.content, file.downloadTemplate.filename);
    },
    () => {
      toastr.error('Download Error',
        'An error has occurred whilst downloading the example presales template. Please try again.');
    });
  };

  const CreatePresaleCampaign = () => {
    return (
      <Button
        type="button"
        className="tertiary create-presales-campaign"
        text="Create new campaign"
        onClick={() => navigateTo(`${getBasePath(userDetails)}presale-campaigns/create`)}
      />
    );
  };

  const DownloadExampleTemplate = () => {
    return (
      <Button
        type="button"
        className="secondary create-presales-campaign"
        text="Download example template"
        onClick={downloadTemplate}
      />
    );
  };

  return (
    <FullPageLayout
      heading="Presales Campaigns"
      breadcrumbs={[
        {
          key: 0,
          text: 'Presales Campaigns',
          url: window.location.pathname,
        },
      ]}
      homeRoute="/admin/home"
      headerAdditionalContent={
        <div className="headerButtons">
          <DownloadExampleTemplate />
          <CreatePresaleCampaign />
        </div>
      }
    >

<div className="presale-campaigns">
        {(presales.loading || (!activeCampaigns && !expiredCampaigns))  && <LoadingThrobber />}
        <Tabs onChange={setActiveTab} activeTab={activeTab}>
          <Tab id="active-campaigns" title="Active / Future Campaigns">
            <AdminPresalesCampaignsTable
              campaigns={activeCampaigns}
              noRecordsMessage="No active campaigns"
              loadData={loadData}
              deletePresalesCampaign={deletePresalesCampaign}
              downloadPresalesCampaignReport={() => null}
              setCampaignLoading={(id: number, loading: boolean) => setCampaignLoading(id, loading)}
            />
          </Tab>
          <Tab id="expired-campaigns" title="Expired Campaigns">
            <AdminPresalesCampaignsTable
              campaigns={expiredCampaigns}
              noRecordsMessage="No expired campaigns"
              loadData={loadData}
              deletePresalesCampaign={deletePresalesCampaign}
              downloadPresalesCampaignReport={() => null}
              setCampaignLoading={(id: number, loading: boolean) => setCampaignLoading(id, loading)}
            />
          </Tab>
        </Tabs>
      </div>
    </FullPageLayout>
  );
};

const mapStateToProps = (state: IStoreState) => {
  return {
    userDetails: state.userDetails,
    presales: state.presales,
    dbActiveCampaigns: state.presales.campaigns.activeCampaigns,
    dbExpiredCampaigns: state.presales.campaigns.expiredCampaigns,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<any>) => ({
  getPresalesCampaigns: (activeCampaigns: boolean) =>
    dispatch(getPresalesCampaignsRequest(activeCampaigns)),
  deletePresalesCampaign: (campaignId: number, onSuccess: () => void) =>
    dispatch(deletePresalesCampaignRequest({ campaignId }, onSuccess)),
  downloadExamplePresalesTemplate: (onSuccess: (data: IDocumentBlob) => void,
    onFailure: () => void) => dispatch(downloadExamplePresalesTemplateRequest(onSuccess, onFailure)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PresaleCampaignsList);
