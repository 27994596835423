import React, { useEffect } from 'react';
import ScrollHelper from '../../helpers/ScrollHelper';
import './LoadingThrobber.scss';

const LoadingThrobber = ({ preventScroll = true }) => {

  useEffect(
    () => {
      if (preventScroll) {
        ScrollHelper.preventBodyScroll();

        return () => ScrollHelper.allowBodyScroll();
      }
    },
    [],
  );

  return (
    <span className="loading-container">
      <span className="loading icon-spinner" />
    </span>
  );
};

export default LoadingThrobber;
