import { Action } from 'redux';
import { getProductExclusions, IGetProductExclusionsParams, IGetProductExclusionsResponse } from '../api/productsApi';
import { IProductExclusion } from '../types';
import { AxiosResponse } from 'axios';

export const GET_PRODUCT_EXCLUSIONS_REQUEST = 'GET_PRODUCT_EXCLUSIONS_REQUEST';
export type GET_PRODUCT_EXCLUSIONS_REQUEST = typeof GET_PRODUCT_EXCLUSIONS_REQUEST;
export const GET_PRODUCT_EXCLUSIONS_RESPONSE = 'GET_PRODUCT_EXCLUSIONS_RESPONSE';
export type GET_PRODUCT_EXCLUSIONS_RESPONSE = typeof GET_PRODUCT_EXCLUSIONS_RESPONSE;
export const GET_PRODUCT_EXCLUSIONS_ERROR = 'GET_PRODUCT_EXCLUSIONS_ERROR';
export type GET_PRODUCT_EXCLUSIONS_ERROR = typeof GET_PRODUCT_EXCLUSIONS_ERROR;

export interface IGetProductExclusionsResponse extends Action {
  type: GET_PRODUCT_EXCLUSIONS_RESPONSE;
  response: IProductExclusion;
}

export interface IGetProductExclusionError extends Action {
  type: GET_PRODUCT_EXCLUSIONS_ERROR;
  error?: string;
}

export const getProductExclusionsRequest = (parameters: IGetProductExclusionsParams,
                                            onExclusionsLoaded: () => void): any => {
  return (dispatch : any) => {
    dispatch({
      type: GET_PRODUCT_EXCLUSIONS_REQUEST,
      loadMore: false,
    });

    return getProductExclusions(parameters)
      .then((response: AxiosResponse<IGetProductExclusionsResponse>) => {
        if (response.status === 200) {
          dispatch({
            type: GET_PRODUCT_EXCLUSIONS_RESPONSE,
            response: response.data.exclusions,
            loadMore: false,
          });
          onExclusionsLoaded();
        } else {
          onError(dispatch);
        }
      })
      .catch((err: any) => {
        console.log(err);
        onError(dispatch);
      });
  };
};

const onError = (dispatch: any) => dispatch({
  type: GET_PRODUCT_EXCLUSIONS_ERROR,
});
