import BootstrapTable from 'react-bootstrap-table-next';
import { IApiRequest, IOrderClaimReview, IStoreState, IUserDetails } from '../../types';
import React from 'react';
import DateHelper from '../../helpers/Format/Date/FormatDateHelper';
import { navigateTo } from '../../routing/Navigation';
import { getBasePath } from '../../helpers/Users/UserHelper';
import { connect } from 'react-redux';
import './ReviewClaimsTable.scss';

interface IReviewClaimsTableProps {
  userDetails: IUserDetails;
  claimsList: IOrderClaimReview[];
  orderClaimDetails: IApiRequest<IOrderClaimReview>;
  displayMode: string;
}

const ReviewClaimsTable = ({
  userDetails,
  claimsList,
  orderClaimDetails,
  displayMode,
}: IReviewClaimsTableProps): JSX.Element => {
  const setOrderClaimDetails = (claim: any) => {
    orderClaimDetails.data = claim;
  };

  const shouldDisplayStatusColumn = displayMode === 'processed';

  const columns = [
    {
      dataField: 'claimNumber',
      text: 'Claim Number',
      editable: false,
      headerClasses: 'w-10',
      attrs: { 'data-label': 'Claim Number' },
    },
    {
      dataField: 'dateRaised',
      text: 'Raised Date',
      headerClasses: 'w-10',
      formatter: (cell: any) => {
        return DateHelper.formatShortDate(cell);
      },
      attrs: { 'data-label': 'Raised Date' },
    },
    {
      dataField: 'customer',
      text: 'Customer',
      editable: false,
      headerClasses: 'w-25',
      attrs: { 'data-label': 'Customer' },
    },
    {
      dataField: 'actions',
      text: 'Actions',
      editable: false,
      headerClasses: 'w-25',
      formatter: (cell: any, row: any) => {
        return (
          <div className="actions-cell">
            <button
              onClick={() => {
                setOrderClaimDetails(row);
                navigateTo(
                  `${getBasePath(userDetails)}order-claims-review/claimNumber=${row.claimNumber}`,
                );
              }}
              className="Table-viewDetailsAction"
            >
              View Claim Details
            </button>
          </div>
        );
      },
      attrs: { 'data-label': 'Actions' },
    },
  ];

  if (shouldDisplayStatusColumn) {
    columns.splice(3, 0, {
      dataField: 'status',
      text: 'Claim Status',
      editable: false,
      headerClasses: 'w-25',
      attrs: { 'data-label': 'Claim Status' },
    });
  }

  return (
    <div className="review-claims-table">
      <BootstrapTable
        keyField="claimNumber"
        columns={columns}
        data={claimsList}
        headerClasses="table-header"
        noDataIndication="No claims to review at this time"
      />
    </div>
  );
};

const mapStateToProps = (state: IStoreState) => {
  return {
    userDetails: state.userDetails,
    orderClaimDetails: state.orderClaimDetails,
  };
};

export default connect(mapStateToProps)(ReviewClaimsTable);
