import { AnyAction, Reducer } from 'redux';
import { IApiRequest, IShoppingList } from '../../types';
import {
  GET_ADMIN_SHOPPING_LISTS_ERROR,
  GET_ADMIN_SHOPPING_LISTS_REQUEST,
  GET_ADMIN_SHOPPING_LISTS_RESPONSE,
  GET_SHOPPING_LISTS_ERROR,
  GET_SHOPPING_LISTS_REQUEST,
  GET_SHOPPING_LISTS_RESPONSE,
  UPLOAD_SHOPPING_LIST_ERROR,
  UPLOAD_SHOPPING_LIST_REQUEST,
  UPLOAD_SHOPPING_LIST_RESPONSE,
  DELETE_SHOPPING_LISTS_ERROR,
  DELETE_SHOPPING_LISTS_REQUEST,
  DELETE_SHOPPING_LISTS_RESPONSE,
} from '../../actions/ShoppingLists/shoppingListsActions';
import {
  CREATE_SHOPPING_LIST_RESPONSE,
  DELETE_SHOPPING_LIST_RESPONSE,
  CREATE_SHOPPING_LIST_REQUEST,
  DELETE_SHOPPING_LIST_REQUEST,
  CREATE_SHOPPING_LIST_ERROR,
  DELETE_SHOPPING_LIST_ERROR,
  ADD_PRODUCTS_TO_SHOPPING_LIST_REQUEST,
  ADD_PRODUCTS_TO_SHOPPING_LIST_ERROR,
  ADD_PRODUCTS_TO_SHOPPING_LIST_RESPONSE,
} from '../../actions/ShoppingLists/shoppingListActions';

const initialState: IApiRequest<IShoppingList[]> = {
  data: [],
  error: '',
  UpdatedAt: null,
  loading: false,
};

const shoppingListsReducer: Reducer<IApiRequest<IShoppingList[]>> = (
  state: IApiRequest<IShoppingList[]> = initialState,
  action: AnyAction,
) => {
  switch (action.type) {
    case UPLOAD_SHOPPING_LIST_RESPONSE:
    case GET_ADMIN_SHOPPING_LISTS_RESPONSE:
    case GET_SHOPPING_LISTS_RESPONSE:
    case DELETE_SHOPPING_LISTS_RESPONSE:
    case CREATE_SHOPPING_LIST_RESPONSE:
    case DELETE_SHOPPING_LIST_RESPONSE:
    case ADD_PRODUCTS_TO_SHOPPING_LIST_RESPONSE: {
      return {
        data: action.response,
        error: '',
        UpdatedAt: new Date(),
        loading: false,
      };
    }
    case UPLOAD_SHOPPING_LIST_REQUEST:
    case GET_ADMIN_SHOPPING_LISTS_REQUEST:
    case GET_SHOPPING_LISTS_REQUEST:
    case CREATE_SHOPPING_LIST_REQUEST:
    case DELETE_SHOPPING_LIST_REQUEST:
    case DELETE_SHOPPING_LISTS_REQUEST:
    case ADD_PRODUCTS_TO_SHOPPING_LIST_REQUEST: {
      return {
        data: [],
        error: '',
        UpdatedAt: null,
        loading: true,
      };
    }
    case UPLOAD_SHOPPING_LIST_ERROR:
    case GET_ADMIN_SHOPPING_LISTS_ERROR:
    case GET_SHOPPING_LISTS_ERROR:
    case DELETE_SHOPPING_LISTS_ERROR:
    case CREATE_SHOPPING_LIST_ERROR:
    case DELETE_SHOPPING_LIST_ERROR:
    case ADD_PRODUCTS_TO_SHOPPING_LIST_ERROR: {
      return {
        ...initialState,
        error: 'An error has occured',
      };
    }
    default: {
      return state;
    }
  }
};

export default shoppingListsReducer;
