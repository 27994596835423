import Fetch from '../apiConfig/apiConfig';
import { AxiosPromise, AxiosInstance } from 'axios';
import { IUserDetails } from '../../actions/userLoginAction/interfaces/userLoginAction.interface';

export const createUser = (endpoint: string, payload: any): AxiosPromise<any> => {
  return Fetch().then((axios: AxiosInstance): AxiosPromise<any> => {
    return axios.post(endpoint, payload);
  });
};

export const impersonateUser = (userId: number): AxiosPromise<IUserDetails> =>
  Fetch().then((axios: AxiosInstance): AxiosPromise<IUserDetails> => axios.post('users/impersonate', { UserId: userId }));
