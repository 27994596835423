import { Reducer } from 'redux';
import { IApiRequest, IStockAllocation } from '../../types';
import {
  GET_STOCK_ALLOCATION_ERROR,
  GET_STOCK_ALLOCATION_REQUEST,
  GET_STOCK_ALLOCATION_RESPONSE,
  EDIT_STOCK_ALLOCATION_REQUEST,
  EDIT_STOCK_ALLOCATION_RESPONSE,
  EDIT_STOCK_ALLOCATION_ERROR,
  LOCK_IN_ORDER_REQUEST,
  LOCK_IN_ORDER_RESPONSE,
  LOCK_IN_ORDER_ERROR,
} from '../../actions/Orders/stockAllocationAction';

const initialState: IApiRequest<IStockAllocation[]> = {
  data: null,
  error: '',
  UpdatedAt: null,
  loading: false,
};

const stockAllocationReducer: Reducer<IApiRequest<IStockAllocation[]>> =
  (state: IApiRequest<IStockAllocation[]> = initialState, action: any) => {
    switch (action.type) {
      case GET_STOCK_ALLOCATION_RESPONSE: {
        return {
          ...initialState,
          data: action.response,
        };
      }
      case GET_STOCK_ALLOCATION_REQUEST: {
        return {
          ...initialState,
          loading: true,
        };
      }
      case GET_STOCK_ALLOCATION_ERROR: {
        return {
          ...initialState,
          error: action.response || 'There was an error retrieving stock allocations. Please try again',
        };
      }
      case EDIT_STOCK_ALLOCATION_REQUEST:
      case LOCK_IN_ORDER_REQUEST: {
        return {
          ...state,
          loading: true,
        };
      }
      case EDIT_STOCK_ALLOCATION_RESPONSE:
      case LOCK_IN_ORDER_RESPONSE: {
        return {
          ...state,
          loading: false,
          UpdatedAt: new Date(),
        };
      }
      case EDIT_STOCK_ALLOCATION_ERROR:
      case LOCK_IN_ORDER_ERROR: {
        return {
          ...state,
          loading: false,
          error: action.response || 'There was an error updating stock allocations. Please try again',
        };
      }
      default: {
        return state;
      }
    }
  };

export default stockAllocationReducer;
