import { FolderType } from '../../types';
import Fetch from '../apiConfig/apiConfig';
import { AxiosPromise } from 'axios';

export interface IUpdateFolderParams {
  retailerId: number;
  folderId: number;
  updatedFolderName: string;
  folderType: FolderType;
  expiryDate?: Date;
  retailerSubTypes: number[];
}

export const updateFolder = (params: IUpdateFolderParams) : AxiosPromise => {
  return Fetch().then(axios => axios.post('api/documents/update-folder', params));
};
