import { toastr } from 'react-redux-toastr';
import { AxiosResponse } from 'axios';
import { getCustomerItemSalesReport } from '../../api/Reports/ReportsApi';
import { ICustomerItemSalesReportParams } from '../../api/Reports/CustomerSales/ICustomerItemSalesParams';
import { ICustomerItemSalesReportResponse } from '../../api/Reports/CustomerSales/ICustomerItemSalesReportResponse';

export const GET_CUSTOMER_ITEM_SALES_REPORT_REQUEST = 'GET_CUSTOMER_ITEM_SALES_REPORT_REQUEST';
export const GET_CUSTOMER_ITEM_SALES_REPORT_RESPONSE = 'GET_CUSTOMER_ITEM_SALES_REPORT_RESPONSE';
export const GET_CUSTOMER_ITEM_SALES_REPORT_ERROR = 'GET_CUSTOMER_ITEM_SALES_REPORT_ERROR';

export const getCustomerItemSalesReportRequest = (
  parameters: ICustomerItemSalesReportParams,
  onSuccess: (data: any) => void,
): any => {
  return (dispatch: any) => {
    dispatch({
      type: GET_CUSTOMER_ITEM_SALES_REPORT_REQUEST,
    });
    return getCustomerItemSalesReport(parameters)
      .then((response: AxiosResponse<ICustomerItemSalesReportResponse>) => {
        if (response.status === 200) {
          dispatch({
            type: GET_CUSTOMER_ITEM_SALES_REPORT_RESPONSE,
          });
          toastr.success('Report Generated', 'The customer item sales report has been exported successfully.');
          onSuccess(response.data);
        } else {
          onError(dispatch);
        }
      })
      .catch((err: any) => {
        console.log(err);
        onError(dispatch);
      });
  };
};
const onError = (dispatch: any) => {
  dispatch({
    type: GET_CUSTOMER_ITEM_SALES_REPORT_ERROR,
  });
  toastr.error('Error', 'An error has occurred whilst exporting the customer item sales report. Please try again.');
};
