import React, { Dispatch, useEffect, useRef, useState } from 'react';
import { IApiRequest, IProduct, IRetailerDetails, ISearchResults, IStoreState } from '../../../types';
import { connect } from 'react-redux';
import { PersonalizationPane } from './PersonalizationPane/PersonalizationPane';
import './POSCreator.scss';
import { BreadcrumbSegment, Breadcrumbs, Dropdown } from '@orderly/morrisons-component-library';
import { navigateTo } from '../../../routing/Navigation';
import { POSPromotionTypes } from '../../../helpers/POSCreator/POSPromotionTypes.enum';
import { CurrencyType, PriceSelectionType, PriceType, getPriceByType } from '../../../helpers/Product/ProductPriceHelper';
import { POSPreviewPane } from './POSPreviewPane/POSPreviewPane';
import { POSTemplates } from '../../../helpers/POSCreator/POSTemplates.enum';
import LoadingThrobber from '../../../components/LoadingThrobber/LoadingThrobber';
import { mapEnumToOptions } from '../../../helpers/EnumHelper';
import { getSearchResultsRequest } from '../../../actions/searchAction';
import { formatCurrency } from '../../../helpers/Format';
import _ from 'lodash';

export interface IPOSProductPreviewDetails {
  idx: number;
  identifier: string;
  description: string;
  price: string;
  maxAllowedPrice?: number;
  prevPrice?: string;
  priceCurrency: CurrencyType;
  prevPriceStrikethrough?: boolean;
  prevPriceCurrency?: CurrencyType;
  weight: string;
  multibuyValue?: string;
  multiBuyProductCount?: number;
  multibuyValueCurrency?: CurrencyType;
  promotionType: POSPromotionTypes;
  challenge25?: boolean;
  drinkaware?: boolean;
}

export interface IProps {
  search: (searchPhrase: string, startIndex: number, pageSize: number) => void;
  retailerDetails: IApiRequest<IRetailerDetails>;
  searchResults: ISearchResults;
}

let breadcrumbs: BreadcrumbSegment[] = [];

breadcrumbs = [
  { key: 0, text: 'POS Creator', url: '/pos-creator' },
];

const placeHolderProduct: IPOSProductPreviewDetails = {
  idx: 0,
  identifier: '',
  description: '',
  price: '',
  priceCurrency: CurrencyType.Pound,
  weight: '',
  prevPriceCurrency: CurrencyType.Pound,
  promotionType: POSPromotionTypes.No_Strikethrough,
  challenge25: false,
  drinkaware: false,
  multiBuyProductCount: 2,
  multibuyValue: '',
  multibuyValueCurrency: CurrencyType.Pound,
};

const templates = mapEnumToOptions(POSTemplates);

const POSCreator = (props: IProps) => {
  const [productDetails, setProductDetails] = useState<IPOSProductPreviewDetails[]>([placeHolderProduct]);
  const [templateType, setTemplateType] = useState(POSTemplates.A4);
  const [loading, setLoading] = useState(false);
  const [lastUpdatedIdx, setLastUpdatedIdx] = useState(-1);

  const previousSearchResults = useRef<ISearchResults | undefined>();
  const previousProductDetails = useRef<IPOSProductPreviewDetails[] | undefined>();

  const { search, searchResults } = props;

  const getProductFromSearchResults = (identifier: string) => {
    const data = (searchResults[identifier] && searchResults[identifier].data) || undefined;
    const productsFromDb = (data && data.products) || [];
    return productsFromDb.length > 0 ? productsFromDb[0] : null;
  };

  useEffect(() => {
    const previousResults = previousSearchResults.current;
    let resultsAreUpdated = false;

    if (previousResults) {
      resultsAreUpdated = !_.isEqualWith(previousResults, searchResults, (value1, value2, key) => {
        return key === 'UpdatedAt' ? true : undefined;
      });
    }

    if (!previousResults || resultsAreUpdated) {
      previousSearchResults.current = { ...searchResults };
    } else {
      setLoading(false);
      return;
    }

    Object.keys(searchResults).forEach((searchPhrase) => {
      if (lastUpdatedIdx !== -1) {
        const product = getProductFromSearchResults(searchPhrase);
        const productDetail = productDetails[lastUpdatedIdx];

        if (product && searchPhrase === productDetail.identifier) {
          const updatedProduct = constructProductDetail(product, productDetail);
          updateProduct(lastUpdatedIdx, updatedProduct);
        }
      }
    });
    setLoading(false);
  }, [searchResults]);

  useEffect(() => {
    const prevProductDetails = previousProductDetails.current;
    const currentProductDetails = [...productDetails];

    if (lastUpdatedIdx > -1) {
      const productDetail = currentProductDetails[lastUpdatedIdx];
      const prevProductDetail = prevProductDetails[lastUpdatedIdx];

      if (productDetail && prevProductDetail && productDetail.identifier !== prevProductDetail.identifier) {
        const product = getProductFromSearchResults(productDetail.identifier);

        if (product !== null) {
          currentProductDetails[lastUpdatedIdx] = constructProductDetail(product, productDetail);
          setProductDetails(currentProductDetails);
        }
      }
    }
    previousProductDetails.current = currentProductDetails;
  }, [productDetails]);

  const constructProductDetail = (product: IProduct, productDetail: IPOSProductPreviewDetails) => {
    const price = formatCurrency(product.recommendedRetailPrice).split('£')[1];
    const prevPrice = getPriceByType(product, PriceType.UnitPrice, PriceSelectionType.NonSalePrice).split('£')[1];
    const prevPriceFloat = parseFloat(prevPrice);
    const maxAllowedPrice = parseFloat((product.recommendedRetailPrice / 100).toFixed(2));

    let weight = '';
    if (!product.weight.toLowerCase().includes('each')) {
      weight = product.weight.toLowerCase();
    }

    const updatedProduct: IPOSProductPreviewDetails = {
      maxAllowedPrice,
      price,
      prevPrice,
      weight,
      idx: productDetail.idx,
      identifier: productDetail.identifier,
      description: product.description,
      priceCurrency: CurrencyType.Pound,
      prevPriceStrikethrough: prevPriceFloat > 0 || productDetail.promotionType === POSPromotionTypes.Strikethrough,
      prevPriceCurrency: CurrencyType.Pound,
      promotionType: productDetail.promotionType,
      multiBuyProductCount: 2,
      multibuyValueCurrency: CurrencyType.Pound,
      challenge25: false,
      drinkaware: false,
    };

    return updatedProduct;
  };

  const updateProduct = (idx: number, updatedProduct: IPOSProductPreviewDetails, newProduct?: boolean) => {
    if (newProduct) {
      const productToAdd = { ...updatedProduct, idx: productDetails.length };
      setProductDetails([...productDetails, productToAdd]);
      return;
    }

    if (idx !== -1) {
      setLastUpdatedIdx(idx);

      if (updatedProduct.identifier === '-1') {
        const updatedProducts = [...productDetails];
        updatedProducts.splice(idx, 1);
        setProductDetails(updatedProducts);
      } else {
        const updatedProducts = [...productDetails];
        updatedProducts[idx] = updatedProduct;
        setProductDetails(updatedProducts);
      }
    }
  };

  return (
    <div className="pos-creator-wrapper">
      <Breadcrumbs
        onHomeClick={() => navigateTo('/home')}
        onRedirect={navigateTo}
        segments={breadcrumbs}
      />
      <div className="pos-creator-container">
        {
          loading && <LoadingThrobber />
        }
        <div
          className={`pos-creator-inputs ${templateType === POSTemplates.Six_By_Three ? 'landscape' : 'portrait'}`}
        >
          <Dropdown
            label="Template"
            name="template"
            onChange={e => setTemplateType(e.value as POSTemplates)}
            options={templates}
            selectedValue={templateType}
          />
          {
            templateType !== POSTemplates.Six_By_Three &&
            <PersonalizationPane
              key={productDetails[0].idx}
              product={productDetails[0]}
              updateProductDetails={(value: any, newProduct?: boolean) =>
                updateProduct(productDetails[0].idx, value, newProduct)}
              templateType={templateType}
              productDetailsCount={productDetails.length}
              search={(identifier: string) => search(identifier, 0, 1)}
            />
          }
          {
            templateType === POSTemplates.Six_By_Three &&
            productDetails.map((product: IPOSProductPreviewDetails, idx: number) => {
              return <PersonalizationPane
                key={idx}
                product={product}
                updateProductDetails={(value: any, newProduct?: boolean) => updateProduct(idx, value, newProduct)}
                templateType={templateType}
                productDetailsCount={productDetails.length}
                search={(identifier: string) => search(identifier, 0, 1)}
              />;
            })
          }
          {
            templateType === POSTemplates.Six_By_Three && productDetails.length < 4 &&
            <div
              className="add-product-btn"
              onClick={() => setProductDetails([...productDetails, { ...placeHolderProduct, idx: productDetails.length }])}
            >
              Add product +
            </div>
          }
        </div>
        {
          productDetails &&
          <POSPreviewPane
            products={productDetails}
            orientation={templateType === POSTemplates.A4 ? 'portrait' : 'landscape'}
            setLoading={(value: boolean) => setLoading(value)}
          />
        }
      </div>
    </div>
  );
};

const mapStateToProps = (state: IStoreState) => {
  return {
    retailerDetails: state.retailerDetails,
    searchResults: state.searchResults,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<any>) => {
  return {
    search: (searchPhrase: string, startIndex: number, pageSize: number) =>
      dispatch(getSearchResultsRequest(searchPhrase, startIndex, pageSize)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(POSCreator);
