import { AxiosResponse } from 'axios';
import { toastr } from 'react-redux-toastr';
import { importNationalProductDepotForm } from '../api/productsApi';
import { IImportNationalProductDepotFormParams } from '../api/NationalProductDepot/IImportNationalProductDepotFormParams';
import { IImportNationalProductDepotFormResponse }
from '../api/NationalProductDepot/IImportNationalProductDepotFormResponse';

export const IMPORT_NATIONAL_DEPOT_DOC_REQUEST = 'IMPORT_NATIONAL_DEPOT_DOC_REQUEST';
export const IMPORT_NATIONAL_DEPOT_DOC_RESPONSE = 'IMPORT_NATIONAL_DEPOT_DOC_RESPONSE';
export const IMPORT_NATIONAL_DEPOT_DOC_ERROR = 'IMPORT_NATIONAL_DEPOT_DOC_ERROR';

export const importNationalProductDepotFormRequest = (
  parameters: IImportNationalProductDepotFormParams,
  onSuccess: (data: string[]) => void): any => {
  return (dispatch: any) => {
    dispatch({
      type: IMPORT_NATIONAL_DEPOT_DOC_REQUEST,
    });

    return importNationalProductDepotForm(parameters)
      .then((response: AxiosResponse<IImportNationalProductDepotFormResponse>) => {
        if (response.status === 200) {
          onSuccess(response.data.importNationalProductDepotForm.results);
          if (response.data.importNationalProductDepotForm.success) {
            toastr.success('Import Success', 'The product national depot document has been imported successfully.');
            dispatch({
              type: IMPORT_NATIONAL_DEPOT_DOC_RESPONSE,
            });
          } else {
            toastr.error('Import Error',
              'An error has occurred whilst importing the product national depot document. Please try again.');
          }
        } else {
          onError(dispatch);
        }
      })
      .catch((err: any) => {
        console.log(err);
        onError(dispatch);
      });
  };
};

const onError = (dispatch: any) => {
  dispatch({
    type: IMPORT_NATIONAL_DEPOT_DOC_ERROR,
  });
  toastr.error('Error', 'An error has occurred whilst importing the product national depot document. Please try again.');
};
