import { IApiRequest, IPendingOrder } from '../../types';
import { Reducer } from 'redux';
import {
  GET_USER_PENDING_ORDERS_REQUEST,
  GET_USER_PENDING_ORDERS_RESPONSE,
  GET_USER_PENDING_ORDERS_ERROR,
} from '../../actions/Orders/userPendingOrdersAction';

const initialState: IApiRequest<IPendingOrder[]> = {
  data: null,
  error: null,
  UpdatedAt: null,
  loading: false,
};

const userPendingOrdersReducer: Reducer<IApiRequest<IPendingOrder[]>> =
(state: IApiRequest<IPendingOrder[]> = initialState, action: any) => {
  switch (action.type) {
    case GET_USER_PENDING_ORDERS_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }
    case GET_USER_PENDING_ORDERS_RESPONSE: {
      return {
        ...state,
        data: action.response,
        loading: false,
      };
    }
    case GET_USER_PENDING_ORDERS_ERROR: {
      return {
        ...initialState,
        error: action.response,
      };
    }
    default: {
      return state;
    }
  }
};

export default userPendingOrdersReducer;
