import { padLeft } from './padLeft';

export const formatUTCDateTimeShort = (value: Date) : string => {

  const minutes = value.getMinutes();
  const hours = value.getHours();
  const day = value.getDate();
  const weekDay = value.toLocaleString('default', { weekday: 'short' });
  const month = value.toLocaleString('default', { month: 'short' });
  const year = value.getFullYear();

  return `${weekDay} ${day} ${month} ${year}, ${hours}:${padLeft(minutes)}`;
};
