import { Reducer } from 'redux';
import {
  GET_CATEGORIES_ERROR,
  GET_CATEGORIES_REQUEST,
  GET_CATEGORIES_RESPONSE } from '../actions/categoriesAction';
import { ICategory, IApiRequest } from '../types';

const initialState: IApiRequest<ICategory[]> = {
  data: [],
  error: '',
  UpdatedAt: null,
  loading: false,
};

const categoriesReducer: Reducer<IApiRequest<ICategory[]>>
= (state: IApiRequest<ICategory[]> = initialState, action : any) => {
  switch (action.type) {
    case GET_CATEGORIES_RESPONSE: {
      return {
        data: action.response,
        error: '',
        UpdatedAt: new Date(),
        loading: false,
      };
    }
    case GET_CATEGORIES_REQUEST: {
      return {
        data: [],
        error: '',
        UpdatedAt: null,
        loading: true,
      };
    }
    case GET_CATEGORIES_ERROR:
    default: {
      return state;
    }
  }
};

export default categoriesReducer;
