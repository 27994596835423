import React, { Dispatch, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import * as siteWrapperAction from '../../actions/siteWrapperAction';
import * as userLoginAction from '../../actions/userLoginAction/userLoginAction';
import { TextInput, Button, Card, Dropdown, DropdownOption } from '@orderly/morrisons-component-library';
import logo from '../../assets/images/login_logo.svg';
import './Login.scss';
import { ILoginParams, IRetailerLoginParams } from '../../api/userApi';
import ReCAPTCHA from '../../components/ReCAPTCHA/ReCAPTCHA';
import Settings from '../../settings';
import { ILoginProps } from './ILoginProps';
import { toastr } from 'react-redux-toastr';
import { IUserRetailer } from '../../actions/userLoginAction/interfaces/userLoginAction.interface';
import { navigateTo } from '../../routing/Navigation';
import { IStoreState } from '../../types';
import { LoadingThrobber } from '../../components';

interface ILoginValueErrorType {
  value: string;
  error: string;
}

const Login = ({
  userLogin,
  login,
  retailerLogin,
  updateSiteWrapperVisibility }: ILoginProps) => {
  const [username, setUsername] = useState<ILoginValueErrorType>({ value: '', error: '' });
  const [password, setPassword] = useState<ILoginValueErrorType>({ value: '', error: '' });
  const [recaptcha, setRecaptcha] = useState<ILoginValueErrorType>({ value: '', error: '' });
  const [retailerSelection, setRetailerSelection] = useState(false);
  const [retailerOptions, setRetailerOptions] = useState<DropdownOption[]>([]);
  const [selectedRetailer, setSelectedRetailer] = useState<number>(-1);

  const { authenticationResponse, loading } = userLogin;
  const { userRetailers } = authenticationResponse;

  const recaptchaRef: any = React.createRef();

  useEffect(() => {
    updateSiteWrapperVisibility(false);

    const emailInput = document.querySelector('input[name="email"]') as HTMLInputElement;
    const passwordInput = document.querySelector('input[name="password"]') as HTMLInputElement;

    setTimeout(() => handleAutoFill(emailInput), 100);
    setTimeout(() => handleAutoFill(passwordInput), 100);

    return () => {
      updateSiteWrapperVisibility(true);
      setRetailerSelection(false);
      setSelectedRetailer(-1);
    };
  }, []);

  useEffect(() => {
    if (userRetailers && userRetailers.length > 0) {
      const retailers: DropdownOption[] = userRetailers.map((x) => {
        return { label: x.retailerName, value: x.retailerId };
      });

      setRetailerOptions(retailers);
    }
  }, [userLogin.authenticationResponse]);

  useEffect(() => {
    if (selectedRetailer > -1) {
      const loginParams: IRetailerLoginParams = {
        username: username.value,
        password: password.value,
        retailerId: selectedRetailer,
      };

      retailerLogin(loginParams, () => navigateTo('/home'), () => onStoresFailure());
    }
  }, [selectedRetailer]);

  const handleAutoFill = (input: HTMLInputElement) => {
    if (input && input.matches('*:-webkit-autofill')) {
      input.parentElement.classList.add('isNotEmpty');
    }
  };

  const isFormValid = (): boolean => {
    let hasError = false;

    const updatedUsername = { ...username, error: '' };
    const updatedPassword = { ...password, error: '' };
    const updatedRecaptcha = { ...recaptcha, error: '' };

    if (!updatedUsername.value || updatedUsername.value.trim() === '') {
      updatedUsername.error = 'Email address is required';
      hasError = true;
    }

    if (!updatedPassword.value || updatedPassword.value.trim() === '') {
      updatedPassword.error = 'Password is required';
      hasError = true;
    }

    if (Settings().RecaptchaEnabled && (!updatedRecaptcha.value || updatedRecaptcha.value.trim() === '')) {
      updatedRecaptcha.error = 'reCAPTCHA is required';
      hasError = true;
    }

    setUsername(updatedUsername);
    setPassword(updatedPassword);
    setRecaptcha(updatedRecaptcha);

    return !hasError;
  };

  const onSubmit = (e: React.SyntheticEvent): void => {
    e.preventDefault();
    if (!isFormValid()) {
      return;
    }

    const parameters: ILoginParams = {
      Username: username.value,
      Password: password.value,
      Recaptcha: recaptcha.value,
    };

    login(
      parameters,
      (userRetailers: IUserRetailer[]) => onLoginSuccess(userRetailers),
      () => onStoresFailure());
  };

  const onLoginSuccess = (userRetailers: IUserRetailer[]) => {
    if (userRetailers && userRetailers.length > 0) {
      setRetailerSelection(true);
    } else {
      navigateTo('/home');
    }
  };

  const onStoresFailure = () => {
    if (recaptchaRef.current) {
      recaptchaRef.current.reset();
    }
    setRecaptcha({ value: '', error: '' });
    setRetailerSelection(false);
    setSelectedRetailer(-1);
    toastr.error(
      'Store Configuration Error',
      'There has been a problem with the Store configuration on your account. ' +
      'The Wholesale support team have been notified, ' +
      'but please contact your Administrator if you require urgent assistance.',
    );
  };

  return (
    <div className="login-page">
      <div className="login-underlay" />
      <div className="container content-wrapper">
        <div className="logo-wrapper">
          <NavLink className="logo" exact to={'/'}>
            <img src={logo} alt="site logo" className="img-responsive" />
          </NavLink>
        </div>
        <Card>
          {loading && <LoadingThrobber />}
          <h1>{retailerSelection ? 'Select retailer' : 'Sign in to your account'}</h1>
          {
            retailerSelection ?
              <Dropdown
                name="retailers"
                label="Retailers"
                className=""
                placeholder="Select a retailer"
                options={retailerOptions}
                selectedValue={selectedRetailer}
                onChange={(value: DropdownOption) => setSelectedRetailer(value.value as number)}
              />
              :
              <form noValidate onSubmit={onSubmit}>
                <TextInput
                  type="text"
                  label="Email Address"
                  name="email"
                  value={username.value}
                  error={username.error}
                  onChange={(e: any) => setUsername({ value: e, error: '' })}
                />
                <div className="password">
                  <TextInput
                    type="password"
                    label="Password"
                    name="password"
                    value={password.value}
                    error={password.error}
                    onChange={(e: any) => setPassword({ value: e, error: '' })}
                  />
                </div>
                <NavLink exact to={'/password-reset'}>
                  <div className="forgot-password">Forgotten your password?</div>
                </NavLink>
                {
                  recaptcha.error &&
                  <span className="recaptcha-error-text">
                    {recaptcha.error}
                  </span>
                }
                <ReCAPTCHA
                  onChange={(e: string) => setRecaptcha({ value: e, error: '' })}
                  reference={recaptchaRef}
                />
                <Button
                  type="submit"
                  text="Sign In"
                  className="secondary login-btn"
                />
              </form>
          }
        </Card>
      </div>
    </div>
  );
};

const mapStateToProps = (state: IStoreState) => {
  return {
    userLogin: state.userLogin,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<any>) => {
  return {
    updateSiteWrapperVisibility: (status: any) => dispatch(siteWrapperAction.updateSiteWrapperVisibility(status)),
    login: (parameters: ILoginParams, onSuccess: (userRetailers: IUserRetailer[]) => void, onStoresFailure: () => void) =>
      dispatch(userLoginAction.userLoginRequest(parameters, onSuccess, onStoresFailure)),
    retailerLogin: (parameters: IRetailerLoginParams, onSuccess: () => void, onStoresFailure: () => void) =>
      dispatch(userLoginAction.retailerLoginRequest(parameters, onSuccess, onStoresFailure)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
