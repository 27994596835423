import React, { useState, useEffect, useCallback } from 'react';
import Quagga from '@ericblade/quagga2';
import useSound from 'use-sound';
import './BarcodeScanner.scss';

const pingSound = require('../../assets/sounds/ping.mp3');

interface IProps {
  onScan: (result: string) => void;
}

const EAN8_BARCODE_LENGTH = 8;
const EAN13_BARCODE_LENGTH = 13;
const MIN_SCANNING_RESULTS = 5;

const BarcodeScanner = (props: IProps) => {
  const [playPing] = useSound(pingSound);
  const [scanning, setScanning] = useState(false);
  const [result, setResult] = useState<string | null>(null);
  const [torchOn, setTorchOn] = useState(false);
  const [zoomLevel, setZoomLevel] = useState(1);

  useEffect(() => {
    handleStartScanning();
    return () => handleStopScanning();
  }, []);

  useEffect(() => {
    if (scanning) {
      /* tslint:disable-next-line */
      Quagga.init(
        {
          inputStream: {
            type: 'LiveStream',
            constraints: {
              facingMode: 'environment',
            },
          },
          frequency: 10,
          numOfWorkers: navigator.hardwareConcurrency || 0,
          locator: {
            halfSample: true,
            patchSize: 'medium',
          },
          decoder: {
            readers: ['ean_reader', 'ean_8_reader'],
            multiple: false,
          },
          locate: true,
          debug: false,
        },
        (err) => {
          if (err) {
            console.log(err);
            return;
          }
          Quagga.start();
          Quagga.onDetected(handleDetected);
        },
      );
    } else {
      Quagga.stop().catch();
    }

    return () => {
      Quagga.offDetected(handleDetected);
      Quagga.stop().catch();
    };
  }, [scanning]);

  useEffect(() => {
    if (result) {
      props.onScan(result);
    }
  }, [result]);

  let lastResults: string[] = [];

  // used to get the most recurring prediction
  const mode = (array: string[]): string | null => {
    if (array.length === 0) {
      return null;
    }
    const modeMap: { [key: string]: number } = {};
    let maxEl = array[0];
    let maxCount = 1;
    for (const el of array) {
      if (modeMap[el] == null) {
        modeMap[el] = 1;
      } else {
        modeMap[el]++;
      }
      if (modeMap[el] > maxCount) {
        maxEl = el;
        maxCount = modeMap[el];
      }
    }
    return maxEl;
  };

  const handleDetected = (result: any) => {
    if (result && result.codeResult && result.codeResult.code.length > 0 &&
       (result.codeResult.code.length === EAN8_BARCODE_LENGTH || result.codeResult.code.length === EAN13_BARCODE_LENGTH)) {
      if (lastResults.length >= MIN_SCANNING_RESULTS) {
        const mostValidCode = mode(lastResults);
        playPing();
        setScanning(false);
        setResult(mostValidCode);
        lastResults = [];
      } else {
        lastResults.push(result.codeResult.code);
      }
    }
  };

  const handleStartScanning = () => {
    setScanning(true);
  };

  const handleStopScanning = () => {
    setScanning(false);
  };

  const handleTorchToggle = useCallback(async () => {
    const track = Quagga.CameraAccess.getActiveTrack() as any;
    const value = !torchOn;

    if (track) {
      const trackCapabilities = track.getCapabilities() as any;
      if (trackCapabilities.torch) {
        await track.applyConstraints({
          advanced: [{ torch: value }],
        });
        setTorchOn(value);
      }
    }
  }, [torchOn]);

  const getZoomRange = (track: MediaStreamTrack) => {
    const trackCapabilities = track.getCapabilities() as any;
    return {
      minZoomLevel: trackCapabilities.zoom ? trackCapabilities.zoom.min : 0,
      maxZoomLevel: trackCapabilities.zoom ? trackCapabilities.zoom.max : 0,
    };
  };

  const handleZoomUpdate = useCallback(async (value: number) => {
    const track = Quagga.CameraAccess.getActiveTrack() as any;
    if (!track) return;

    const { minZoomLevel, maxZoomLevel } = getZoomRange(track);

    if (minZoomLevel > 0 && maxZoomLevel > 0) {
      // Clamp the value within the available zoom range
      const newValue = Math.min(Math.max(value, minZoomLevel), maxZoomLevel);
      await track.applyConstraints({
        advanced: [{ zoom: newValue }],
      });
      setZoomLevel(value);
    }
  }, [zoomLevel]);

  const isIncreaseZoomBtnEnabled = () => {
    const track = Quagga.CameraAccess.getActiveTrack() as any;
    if (!track) return true;

    const { maxZoomLevel } = getZoomRange(track);

    return zoomLevel < maxZoomLevel;
  };

  return (
    <div className="barcode-scanner-wrapper">
      <div className="video-content">
        <div
          id="interactive"
          className="viewport"
          style={{
            position: 'relative',
            width: '100%',
            height: '100%',
          }}
        />
        {
          scanning && <div className="barcode-scanner-target-box" />
        }
      </div>
      <div className="barcode-scanner-actions">
        <button
          className={`icon-sun barcode-scanner-btn ${torchOn ? 'active' : ''}`}
          onClick={() => handleTorchToggle()}
        />
        <div className="barcode-scanner-zoom-actions">
          <button
            disabled={zoomLevel <= 1}
            className="icon-minus barcode-scanner-btn"
            type="button"
            onClick={() => handleZoomUpdate(zoomLevel - 1)}
          />
          <button
            disabled={!isIncreaseZoomBtnEnabled()}
            className="icon-plus barcode-scanner-btn"
            type="button"
            onClick={() => handleZoomUpdate(zoomLevel + 1)}
          />
        </div>
      </div>
      <h2>{result}</h2>
    </div>
  );
};

export default BarcodeScanner;
