import { Dispatch } from 'redux';
import { getTransportCosts } from '../../api/Stores/storesApi';

export const GET_TRANSPORT_COSTS_REQUEST = 'GET_TRANSPORT_COSTS_REQUEST';
export const GET_TRANSPORT_COSTS_RESPONSE = 'GET_TRANSPORT_COSTS_RESPONSE';
export const GET_TRANSPORT_COSTS_ERROR = 'GET_TRANSPORT_COSTS_ERROR';

export const getTransportCostsRequest = (): (dispatch: Dispatch) => Promise<any> => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: GET_TRANSPORT_COSTS_REQUEST,
    });

    try {
      const response = await getTransportCosts();

      if (response.status === 200) {
        const { transportCosts } = response.data;

        dispatch({
          type: GET_TRANSPORT_COSTS_RESPONSE,
          response: transportCosts,
        });
      } else {
        onError(dispatch);
      }
    } catch (error) {
      console.log(error);
      onError(dispatch);
    }
  };
};

const onError = (dispatch: Dispatch) => dispatch({
  type: GET_TRANSPORT_COSTS_ERROR,
});
