import React from 'react';
import { PriceSelectionType, PriceType, ViewMode, getPriceByType } from '../../helpers/Product/ProductPriceHelper';
import { formatCurrency, formatShortDateString } from '../../helpers/Format';
import { IProductPromotionalPriceProps } from './IProductPromotionalPriceProps';
import ProductDiscountPrice from '../ProductDiscountPrice';

function ProductPromotionalPrice({
  product,
  isListView,
  viewMode,
}: IProductPromotionalPriceProps): JSX.Element {
  const {
    singlePick,
    caseSize,
    unitPriceDifference,
    casePriceDifference,
    tradingPromotionalEndDate,
    standardRecommendedRetailPrice,
    recommendedRetailPrice,
    recommendedRetailPriceDifference,
    retailPromotionalEndDate,
  } = product;

  let oldPrice: string = '';
  let newPrice: string = '';
  let difference: number = 0;
  let tooltip: string = '';
  let description: null | string = null;

  if (viewMode === ViewMode.RRP) {
    const endDate = formatShortDateString(retailPromotionalEndDate);
    tooltip = retailPromotionalEndDate ? `Discount end date: ${endDate}` : '';

    oldPrice = formatCurrency(standardRecommendedRetailPrice);
    newPrice = formatCurrency(recommendedRetailPrice);
    difference = recommendedRetailPriceDifference;
    description = 'RRP';
  } else {
    const endDate = formatShortDateString(tradingPromotionalEndDate);
    tooltip = tradingPromotionalEndDate ? `Discount end date: ${endDate}` : '';

    oldPrice = getPriceByType(
      product,
      singlePick ? PriceType.UnitPrice : PriceType.CasePrice,
      PriceSelectionType.NonSalePrice);

    newPrice = getPriceByType(
      product,
      singlePick ? PriceType.UnitPrice : PriceType.CasePrice,
      PriceSelectionType.NormalPrice);

    difference = singlePick ? unitPriceDifference : casePriceDifference;
    description = singlePick ? '(single)' : `${caseSize} pack`;
  }

  return (
    <ProductDiscountPrice
      newPrice={newPrice}
      oldPrice={oldPrice}
      difference={difference}
      tooltip={tooltip}
      description={description}
      isListView={isListView}
    />
  );
}

export default ProductPromotionalPrice;
