import { Action } from 'redux';
import { IConfirmForgotPasswordParams, IConfirmForgotPasswordResponse, confirmForgotPassword } from '../api/userApi';
import { toastr } from 'react-redux-toastr';
import { AxiosPromise, AxiosResponse } from 'axios';

export const FORGOT_PASSWORD_CONFIRMATION_REQUEST = 'FORGOT_PASSWORD_CONFIRMATION_REQUEST';
export type FORGOT_PASSWORD_CONFIRMATION_REQUEST = typeof FORGOT_PASSWORD_CONFIRMATION_REQUEST;
export const FORGOT_PASSWORD_CONFIRMATION_RESPONSE = 'FORGOT_PASSWORD_CONFIRMATION_RESPONSE';
export type FORGOT_PASSWORD_CONFIRMATION_RESPONSE = typeof FORGOT_PASSWORD_CONFIRMATION_RESPONSE;
export const FORGOT_PASSWORD_CONFIRMATION_ERROR = 'FORGOT_PASSWORD_CONFIRMATION_ERROR';
export type FORGOT_PASSWORD_CONFIRMATION_ERROR = typeof FORGOT_PASSWORD_CONFIRMATION_ERROR;

export interface IConfirmForgotPasswordRequest extends Action {
  type: FORGOT_PASSWORD_CONFIRMATION_REQUEST;
  payload: IConfirmForgotPasswordParams;
}

export interface IConfirmForgotPasswordResponse extends Action {
  type: FORGOT_PASSWORD_CONFIRMATION_RESPONSE;
  response: IConfirmForgotPasswordResponse;
}

export interface IConfirmForgotPasswordError extends Action {
  type: FORGOT_PASSWORD_CONFIRMATION_ERROR;
  error?: string;
}

export const ConfirmForgotPasswordRequest = (parameters: IConfirmForgotPasswordParams, onSuccess: () => void): any => {
  return (dispatch : any) => {
    dispatch({
      type: FORGOT_PASSWORD_CONFIRMATION_REQUEST,
    });

    return confirmForgotPassword(parameters)
    .then((response: AxiosResponse<IConfirmForgotPasswordResponse>) => {
      if (response.status === 200) {
        toastr.success('Password reset', 'Your password has been reset successfully.');
        dispatch({
          type: FORGOT_PASSWORD_CONFIRMATION_RESPONSE,
        });

        onSuccess();
      } else {
        onError(dispatch);
      }
    })
    .catch((err: any) => {
      console.log(err);
      onError(dispatch);
    });
  };
};

const onError = (dispatch : any) => {
  dispatch({
    type: FORGOT_PASSWORD_CONFIRMATION_ERROR,
  });
};
