import Fetch from '../apiConfig/apiConfig';
import { AxiosPromise } from 'axios';

export interface IResetPasswordResponse {
  [key: string]: any;
}

export const postResetPassword = () : AxiosPromise<IResetPasswordResponse> => {
  return Fetch().then(axios => axios.post('users/password-reset'));
};
