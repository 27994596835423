import { formatTime } from './formatTime';
import { padLeft } from './padLeft';

export const formatUTCDate = (value: string) : string => {
  if (value === null) {
    return '-';
  }

  const date = new Date(value);
  const day = padLeft(date.getDate());
  const month = padLeft(date.getMonth() + 1);
  const year = date.getFullYear();

  return `${day}/${month}/${year} ${formatTime(date)}`;
};
