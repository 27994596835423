import { IApiRequest, IBasket } from '../../types';
import { requireNewSlot } from './requireNewSlot';
import { addToBasketActive } from './addToBasketActive';

export const allowAddToBasket = (
  basket: IApiRequest<IBasket>,
  productCategory: string,
  productStatus: string,
  productAvailability: boolean) : boolean => {

  if (requireNewSlot(basket)) {
    return false;
  }

  return !addToBasketActive(basket, productCategory, productStatus, productAvailability);
};
