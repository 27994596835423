import {
  Breadcrumbs,
  Button,
  Card,
  TextArea,
  TextInput,
} from '@orderly/morrisons-component-library';
import React, { useState, Dispatch, useEffect } from 'react';
import { navigateTo } from '../../../routing/Navigation';
import {
  IApiRequest,
  IOrderClaim,
  IOrderDetails,
  IOrderSearch,
  IRetailerDetails,
  IStoreState,
} from '../../../types';
import OrderError from '../../../components/OrderError/OrderError';
import OrderStatus from '../../../components/OrderStatus/OrderStatus';
import { connect } from 'react-redux';
import LoadingThrobber from '../../../components/LoadingThrobber/LoadingThrobber';
import FormatDateHelper from '../../../helpers/Format/Date/FormatDateHelper';
import { mapCategory } from '../../../helpers/Category/mapCategory';
import { orderSearchRequest } from '../../../actions/Orders/orderSearchAction';
import './OrderClaims.scss';
import { IDateValues } from '../../../helpers/Format/Date/IDateValues';
import FileDropzone from '../../../components/FileDropzone/FileDropzone';
import Table from '../../../components/Table/Table';
import { submitOrderClaimRequest } from '../../../actions/Orders/OrderClaims/orderClaimsAction';
import { ValidatorRegex } from '../../../helpers/validator/ValidatorRegex';

export interface IOrderClaimsProps {
  match: {
    params: {
      orderId: string;
    };
  };
  orderDetails: IApiRequest<IOrderSearch>;
  retailerDetails: IRetailerDetails;
  getOrderDetails: (order: string, retailerDetails: string) => void;
  submitOrderClaim: (claim: IOrderClaim) => void;
}

const RaiseClaim = ({
  match,
  orderDetails,
  retailerDetails,
  getOrderDetails,
  submitOrderClaim,
}: IOrderClaimsProps) => {
  const [orderDay, setOrderDay] = useState('');
  const [orderMonth, setOrderMonth] = useState('');
  const [storeName, setStoreName] = useState('');
  const [files, setFiles] = useState<any[]>([]);
  const [fileDataArray, setFileDataArray] = useState<(string | ArrayBuffer)[]>([]);
  const [claimDetail, setClaimDetail] = useState({
    name: '',
    email: '',
    phone: '',
    orderPoNumber: '',
    description: '',
  });

  const emailAddressRegex = new RegExp(ValidatorRegex.EMAIL);
  const phoneNumberRegex = new RegExp(ValidatorRegex.PHONENUMBER);
  const [errors, setErrors] = useState<{ [key: string]: string }>({});

  const validateInput = (value: string, maxLength?: number) => {
    const trimmedValue = value.trim();
    if (maxLength) {
      return trimmedValue.length > 0 && trimmedValue.length <= maxLength;
    }
    return trimmedValue.length > 0;
  };

  const validateName = () => {
    const isValid = validateInput(claimDetail.name, 50);
    setErrors(prevErrors => ({
      ...prevErrors, name: isValid ? undefined : 'Name is required and must be 50 characters or less',
    }));
    return isValid;
  };

  const validatePoNumber = () => {
    const isValid = validateInput(claimDetail.orderPoNumber, 20);
    setErrors(prevErrors => ({
      ...prevErrors,
      poNumber: isValid ? undefined : 'Po number required and must be 20 characters or less',
    }));
    return isValid;
  };

  const validatePhone = () => {
    const isValid = phoneNumberRegex.test(claimDetail.phone);
    setErrors(prevErrors => ({
      ...prevErrors,
      phone: isValid ? undefined : 'Phone number must be numeric with a max length of 12',
    }));
    return isValid;
  };

  const validateEmail = () => {
    const isValid = emailAddressRegex.test(claimDetail.email);
    setErrors(prevErrors => ({
      ...prevErrors,
      email: isValid ? undefined : 'Email is required, in the format name@domain.com',
    }));
    return isValid;
  };

  const validateDescription = () => {
    const isValid = validateInput(claimDetail.description);
    setErrors(prevErrors => ({
      ...prevErrors,
      description: isValid ? undefined : 'Claim description is required',
    }));
    return isValid;
  };

  const onSubmit = () => {
    const isNameValid = validateName();
    const isPhoneValid = validatePhone();
    const isEmailValid = validateEmail();
    const isDescriptionValid = validateDescription();
    const isOrderPoNumberValid = validatePoNumber();

    if (isNameValid && isPhoneValid && isEmailValid && isDescriptionValid && isOrderPoNumberValid) {
      submitOrderClaim({
        name: claimDetail.name,
        email: claimDetail.email,
        phoneNumber: claimDetail.phone,
        description: claimDetail.description,
        poNumber: claimDetail.orderPoNumber,
        orderNumber: match.params.orderId,
        attachments: files.map((file: any, index: number) => ({
          name: file.name,
          type: file.type,
          data: fileDataArray[index] as string,
        })),
      });
      navigateTo('/orders');
    } else {
      window.scrollTo(0, 0);
      return false;
    }
  };

  const removeFile = (index: number) => {
    const newFiles = [...files];
    const newFileDataArray = [...fileDataArray];

    newFiles.splice(index, 1);
    newFileDataArray.splice(index, 1);

    setFiles(newFiles);
    setFileDataArray(newFileDataArray);
  };

  const onDrop = (readFiles: any[]) => {
    const filesArray: any[] = [];
    const filesDataArray: any[] = [];

    let idCount = 1;

    readFiles.forEach((file) => {
      const reader = new FileReader();
      reader.onabort = () => console.log('file reading was aborted');
      reader.onerror = () => console.log('file reading has failed');
      reader.onload = (ev: any) => {
        const binaryStr = ev.target.result;
        filesDataArray.push(binaryStr);
        filesArray.push(file);
        idCount++;

        if (filesDataArray.length === readFiles.length) {
          setFileDataArray(filesDataArray);
          setFiles(filesArray);
        }
      };
      reader.readAsDataURL(file);
    });
  };

  let order: IOrderDetails = null;

  if (
    orderDetails.loading === false &&
    !orderDetails.error &&
    orderDetails.data !== null &&
    orderDetails.data.orders.length === 1
  ) {
    order = orderDetails.data.orders[0];

    if (order.orderId !== match.params.orderId) {
      order = null;
    }
  }

  if (order === null && !orderDetails.loading && !orderDetails.error) {
    getOrderDetails(match.params.orderId, retailerDetails.meta.ApiIdentifier);
  }

  useEffect(() => {
    if (order) {
      const dateValues: IDateValues = FormatDateHelper.getDateValues(order.orderRaisedDate);

      setOrderDay(dateValues.date);
      setOrderMonth(dateValues.month);
      setClaimDetail(prevClaimDetail => ({
        ...prevClaimDetail,
        orderPoNumber: order.customerPONumber,
      }));

      const storeName = retailerDetails.stores.find(
        x => x.externalId === parseInt(order.shipToLocationId.toString(), 10),
      ).name;

      setStoreName(storeName);
    }
  }, [order]);

  return (
    <div className="raise-order-claim-page">
      {orderDetails.loading && <LoadingThrobber />}
      <Breadcrumbs
        onHomeClick={() => navigateTo('/home')}
        onRedirect={navigateTo}
        segments={[
          { key: 0, text: 'My orders', url: '/orders' },
          {
            key: 1,
            text: 'Raise claim',
            url: window.location.pathname,
          },
        ]}
      />
      {orderDetails.error && <OrderError />}
      {!orderDetails.error && (
        <div className="order-claims-wrapper">
          <section className="order-claims-section">
            <div className="order-claims-left">
              <Card className="order-claims-card">
                <div className="order-claims-date">
                  <span className="day">{order && orderDay}</span>
                  <span className="month">{order && orderMonth}</span>
                </div>
                <div className="order-claims-card-wrapper">
                  <div className="row order-claims-row">
                    <h4 className="order-claims-id">
                      <span className="order-number-prefix">Order</span>
                      <span className="order-number">#{match.params.orderId}</span>
                    </h4>
                  </div>
                  <div className="row">
                    <table className="order-details-table">
                      <tbody>
                        <tr>
                          <td>PO Number</td>
                          <td>
                            {order && !order.customerPONumber ? (
                              <TextInput
                                className="order-po-number"
                                label="PO Number"
                                name="order-ponumber-input"
                                onChange={(poNumber: string) => {
                                  setClaimDetail(prevClaimDetail => ({
                                    ...prevClaimDetail,
                                    orderPoNumber: poNumber,
                                  }));
                                }}
                                type="text"
                                value={claimDetail.orderPoNumber}
                                onBlur={validatePoNumber}
                              />
                            ) : (
                              claimDetail.orderPoNumber
                            )}
                            {errors.poNumber && <span className="error">{errors.poNumber}</span>}
                          </td>
                        </tr>
                        <tr>
                          <td>Order date</td>
                          <td>
                            {order && FormatDateHelper.formatShortDate(order.orderRaisedDate)}
                          </td>
                        </tr>
                        <tr>
                          <td>Delivery date</td>
                          <td>
                            {order && FormatDateHelper.formatShortDate(order.shipToDeliverAt)}
                          </td>
                        </tr>
                        <tr>
                          <td>Category</td>
                          <td>{order && mapCategory(order.orderReferenceCode)}</td>
                        </tr>
                        <tr>
                          <td>Status</td>
                          <td>
                            <OrderStatus status={order && order.statusCurrent} />
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </Card>
            </div>
            <div className="order-claims-details-input">
              <section className="order-claims-details-input card">
                <h4>Raise Claim</h4>
                <p>Customer: {retailerDetails.externalIdentifier}</p>
                <p>Store: {storeName}</p>
                <div className="inputField">
                  <p>Contact Name</p>
                  <TextInput
                    className="contact-name-input"
                    label="Contact Name"
                    name="contact-name-input"
                    onChange={(name: string) => {
                      setClaimDetail(prevDetails => ({ ...prevDetails, name }));
                    }}
                    type="text"
                    value={claimDetail.name}
                    onBlur={validateName}
                  />
                  {errors.name && <span className="error">{errors.name}</span>}
                </div>
                <div className="inputField">
                  <p>Contact Email</p>
                  <TextInput
                    className="contact-email-input"
                    label="Contact Email"
                    name="contact-email-input"
                    onChange={(email: string) => {
                      setClaimDetail(prevDetails => ({ ...prevDetails, email }));
                    }}
                    type="text"
                    value={claimDetail.email}
                    onBlur={validateEmail}
                  />
                  {errors.email && <span className="error">{errors.email}</span>}
                </div>
                <div className="inputField">
                  <p>Contact Phone</p>
                  <TextInput
                    className="contact-phone-input"
                    label="Contact Phone"
                    name="contact-phone-input"
                    onChange={(phone: string) => {
                      setClaimDetail(prevDetails => ({ ...prevDetails, phone }));
                    }}
                    type="text"
                    value={claimDetail.phone}
                    onBlur={validatePhone}
                  />
                  {errors.phone && <span className="error">{errors.phone}</span>}
                </div>
                <div className="inputField">
                  <p>Description of Claim</p>
                  <TextArea
                    name="comment-input"
                    label="Claim Detail"
                    className="comment-input"
                    value={claimDetail.description}
                    onChange={(description: string) => {
                      setClaimDetail(prevDetails => ({ ...prevDetails, description }));
                      setErrors(prevErrors => ({ ...prevErrors, description: undefined }));
                    }}
                  />
                  {errors.description && <span className="error">{errors.description}</span>}
                </div>
                <div className="inputField">
                  Upload supporting documents (image, documents, etc)
                  <div className="content-wrapper">
                    <div className="file-dropzone">
                      <FileDropzone
                        onFileUpload={onDrop}
                        disabled={orderDetails.loading}
                        fileTypes={['image/*', 'application/*']}
                      />
                    </div>
                    <Card className="uploadedDocList">
                      <h4>Uploaded Documents</h4>
                      <Table className={'filesUploaded-tbl'}>
                        <thead>
                          <tr>
                            <th>Name</th>
                            <th />
                          </tr>
                        </thead>
                        <tbody>
                          {files &&
                            files.map((file, index) => {
                              return (
                                <tr key={index}>
                                  <td>{file.name}</td>
                                  <td className="table-icon">
                                    <span title="Remove document" className="buttonRight">
                                      <Button
                                        type="button"
                                        className="removeFile-btn"
                                        icon="icon-Delete"
                                        iconPos="left"
                                        text=""
                                        onClick={() => removeFile(index)}
                                      />
                                    </span>
                                  </td>
                                </tr>
                              );
                            })}
                          {!files ||
                            (files.length <= 0 && (
                              <tr>
                                <td colSpan={3}>No uploaded claims documents</td>
                              </tr>
                            ))}
                        </tbody>
                      </Table>
                    </Card>
                  </div>
                </div>
                <Button
                  type="button"
                  className="button primary"
                  text="Submit"
                  onClick={() => onSubmit()}
                />
              </section>
            </div>
          </section>
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state: IStoreState) => {
  return {
    retailerDetails: state.retailerDetails.data,
    orderDetails: state.orderSearch,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<any>) => {
  return {
    getOrderDetails: (order: string, retailer: string) =>
      dispatch(orderSearchRequest(order, retailer)),
    submitOrderClaim: (claim: IOrderClaim) => dispatch(submitOrderClaimRequest(claim)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(RaiseClaim);
