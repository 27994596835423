import React, { useState, useEffect } from 'react';
import './Home.scss';
import { IHomeProps } from './IHomeProps';
import { Button, Dropdown, DropdownOption, TextArea } from '@orderly/morrisons-component-library';
import Table, { isHeaderSorted } from '../../../components/Table/Table';
import { navigateTo } from '../../../routing/Navigation';
import { IStoreState, IReport, OrderRetailerType } from '../../../types';
import { Dispatch } from 'redux';
import { getAdminRetailersRequest } from '../../../actions/Admin/adminRetailersAction';
import { connect } from 'react-redux';
import LoadingThrobber from '../../../components/LoadingThrobber/LoadingThrobber';
import { IConfirmedOrdersParams } from '../../../api/Reports/ConfirmedOrders/IConfirmedOrdersParams';
import {
  getConfirmedOrdersReportRequest,
  resetReportData,
} from '../../../actions/Reports/confirmedOrdersAction';
import { getNumericalKeys } from '../../../helpers/EnumHelper';
import { RegionCodes } from '../../../helpers/Users/RegionCodes.enum';
import PersonalDetailsCard from '../../../components/PersonalDetailsCard/PersonalDetailsCard';
import ActionsCard from '../../../components/ActionsCard/ActionsCard';
import ReportsCard from '../../../components/ReportsCard/ReportsCard';
import { GenerateDownload } from '../../../helpers/DownloadHelper';
import ImportsCard from '../../../components/ImportsCard/ImportsCard';
import ContactUsCard from '../../../components/ContactUsCard/ContactUsCard';

const Home = ({
  userDetails,
  retailers,
  getAdminRetailers,
  confirmedOrdersCsvDownload,
  downloadConfirmedOrdersCsv,
  clearConfirmedOrdersCsvDownload,
}: IHomeProps) => {
  const [retailerDetails, setRetailerDetails] = useState([]);
  const [sortOrder, setSordOrder] = useState(0);
  const [filteredType, setFilteredType] = useState<number>(0);

  useEffect(() => {
    getAdminRetailers();
  }, []);

  const getLoadedRetailers = () => {
    if (!retailers.loading && retailers.data) {
      const mergedRetailers = retailers.data.retailers.map((retailer) => {
        let orderStats = retailers.data.orderStats.find(stats => stats.id === retailer.id);

        if (!orderStats) {
          orderStats = {
            id: retailer.id,
            confirmedOrderCount: 0,
            submittedOrderCount24Hours: 0,
            submittedOrderCount7Days: 0,
          };
        }

        return {
          ...retailer,
          ...orderStats,
        };
      });

      if (userDetails.regionCode > 0) {

        const regionFilteredRetailers = mergedRetailers.filter(
          r => r.meta['RegionCode'] === RegionCodes[userDetails.regionCode],
        );
        return regionFilteredRetailers;
      }

      return mergedRetailers;
    }
  };

  useEffect(() => {
    setRetailerDetails(getLoadedRetailers);
  }, [retailers]);

  useEffect(() => {
    if (confirmedOrdersCsvDownload && confirmedOrdersCsvDownload.data) {
      createConfirmedOrderDownload(confirmedOrdersCsvDownload.data);
    }
  }, [confirmedOrdersCsvDownload]);

  const typeOptions: number[] = getNumericalKeys(OrderRetailerType);

  const downloadConfirmedOrderCsv = async (retailerId: number) => {
    try {
      await downloadConfirmedOrdersCsv({ retailerId });
    } catch (error) {
      console.log('An error has occurred', error);
    }
  };

  const createConfirmedOrderDownload = ({ filename, content }: IReport): void => {
    GenerateDownload(content, filename);
    clearConfirmedOrdersCsvDownload();
  };

  const filterByType = (type: number) => {
    const retailers = getLoadedRetailers();
    const sorted = sortRetailers(retailers, sortOrder);
    if (type === 0) {
      setRetailerDetails(sorted);
    } else {
      setRetailerDetails(sorted.filter(x => Number(x.meta.OrderType) === type));
    }
    setFilteredType(type);
  };

  const sortRetailers = (retailers: any[], orderBy: number): any[] => {
    const sortedRetailers = [...retailers];
    const descending = sortOrder % 2 !== 0;
    switch (orderBy) {
      case 0:
      case 1:
        sortedRetailers.sort((a: any, b: any) =>
          a.externalIdentifier.localeCompare(b.externalIdentifier),
        );
        break;
      case 2:
      case 3:
        sortedRetailers.sort((a: any, b: any) => a.confirmedOrderCount - b.confirmedOrderCount);
        break;
      case 4:
      case 5:
        sortedRetailers.sort(
          (a: any, b: any) => a.submittedOrderCount24Hours - b.submittedOrderCount24Hours,
        );
        break;
      case 6:
      case 7:
        sortedRetailers.sort(
          (a: any, b: any) => a.submittedOrderCount7Days - b.submittedOrderCount7Days,
        );
        break;
    }

    if (descending) {
      sortedRetailers.reverse();
    }

    return sortedRetailers;
  };

  const getOrderByProperty = (sortOrder: number): { property: string; descending: boolean } => {
    let property = undefined;
    switch (sortOrder) {
      case 0:
      case 1:
        property = 'retailer';
        break;
      case 2:
      case 3:
        property = 'confirmedOrderCount';
        break;
      case 4:
      case 5:
        property = 'submittedOrderCount24Hours';
        break;
      case 6:
      case 7:
        property = 'submittedOrderCount7Days';
        break;
    }

    return { property, descending: sortOrder % 2 !== 0 };
  };

  const populateTableHeader = (): JSX.Element => {
    const { property, descending } = getOrderByProperty(sortOrder);
    return (
      <thead>
        <tr>
          <th>Retailer {isHeaderSorted('retailer', property, descending)}</th>
          <th>
            Retailer Type
            <select
              value={filteredType}
              onChange={newValue => filterByType(Number(newValue.target.value))}
              className="retailer-type-dropdown"
            >
              <option value="0">All</option>
              {typeOptions.map(key =>
                <option key={key} value={key}>{OrderRetailerType[key]}</option>,
              )}
            </select>
          </th>
          <th>Confirmed Orders {isHeaderSorted('confirmedOrderCount', property, descending)}</th>
          <th>
            Submitted (24hrs) {isHeaderSorted('submittedOrderCount24Hours', property, descending)}
          </th>
          <th>
            Submitted (7days) {isHeaderSorted('submittedOrderCount7Days', property, descending)}
          </th>
        </tr>
      </thead>
    );
  };

  return (
    <div className="account-manager-home">
      <div className="sidebar">
        <PersonalDetailsCard />
        <ActionsCard />
        <ReportsCard retailers={retailers} />
        <ImportsCard />
        <ContactUsCard />
      </div>
      <div className="content card">
        <h4>Retailer Management</h4>
        <div className="retailers-heading">
          <p>Manage your Retailer's users and documents, or view confirmed orders</p>
          <Dropdown
            className="retailers-sort"
            label=""
            name="sortRetailers"
            onChange={(value: DropdownOption) => {
              setSordOrder(value.value as number);
            }}
            selectedValue={sortOrder}
            options={[
              { label: 'RETAILER: A-Z', value: 0 },
              { label: 'RETAILER: Z-A', value: 1 },
              { label: 'CONFIRMED ORDERS: low to high', value: 2 },
              { label: 'CONFIRMED ORDERS: high to low', value: 3 },
              { label: 'SUBMITTED (24HRS): low to high', value: 4 },
              { label: 'SUBMITTED (24HRS): high to low', value: 5 },
              { label: 'SUBMITTED (7DAYS): low to high', value: 6 },
              { label: 'SUBMITTED (7DAYS): high to low', value: 7 },
            ]}
          />
        </div>
        {
          <Table className="retailers">
            {populateTableHeader()}
            <tbody>
              {
                retailers.loading &&
                <tr>
                  <td colSpan={4}><LoadingThrobber /></td>
                </tr> ||
                retailerDetails && sortRetailers(retailerDetails, sortOrder).map((retailer) => {
                  return (
                    <tr key={retailer.id}>
                      <td>{retailer.externalIdentifier}</td>
                      <td>{OrderRetailerType[retailer.meta.OrderType]}</td>
                      <td>{retailer.confirmedOrderCount}</td>
                      <td>{retailer.submittedOrderCount24Hours}</td>
                      <td>{retailer.submittedOrderCount7Days}</td>
                      <td className="actions">
                        <span title="Download confirmed orders">
                          <Button
                            className="confirmed-orders"
                            icon="icon-stats-bars"
                            type="button"
                            text=""
                            onClick={() => downloadConfirmedOrderCsv(retailer.id)}
                          />
                        </span>
                        <span title="Manage users">
                          <Button
                            className="manage-users"
                            icon="icon-users"
                            type="button"
                            text=""
                            onClick={() => navigateTo(`/account-manager/users?retailer=${retailer.id}`)}
                          />
                        </span>
                        <span title="Manage Retailer Pricing">
                          <Button
                            className="manage-retailer-pricing"
                            icon="icon-offer"
                            type="button"
                            text=""
                            onClick={() => navigateTo(`/account-manager/retailer-pricing/${retailer.id}`)}
                            disabled={parseInt(retailer.meta.OrderType, 10) !== OrderRetailerType.Bulk}
                          />
                        </span>
                      </td>
                    </tr>
                  );
                })
              }
            </tbody>
          </Table>
        }
        <Button
          type="button"
          className="secondary confirmed-orders"
          text="Download All Confirmed Orders"
          onClick={() => downloadConfirmedOrderCsv(null)}
        />
      </div>
    </div>
  );
};

const mapStateToProps = (state: IStoreState) => {
  return {
    userDetails: state.userDetails,
    retailers: state.adminRetailers,
    confirmedOrdersCsvDownload: state.confirmedOrders,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<any>) => {
  return {
    getAdminRetailers: () => dispatch(getAdminRetailersRequest()),
    downloadConfirmedOrdersCsv: (parameters: IConfirmedOrdersParams) =>
      dispatch(getConfirmedOrdersReportRequest(parameters)),
    clearConfirmedOrdersCsvDownload: () => dispatch(resetReportData()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Home);
