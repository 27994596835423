import { Reducer } from 'redux';
import { IApiRequest } from '../../types';

const initialState: IApiRequest<string[]> = {
  data: null,
  error: null,
  UpdatedAt: null,
  loading: false,
};

const getTagsReducer: Reducer<IApiRequest<string[]>> =
(state: IApiRequest<string[]> = initialState, action: any) => {
  switch (action.type){
    case 'GET_TAGS_REQUEST': {
      return {
        ...state,
        loading: true,
      };
    }
    case 'GET_TAGS_RESPONSE': {
      return {
        ...state,
        data: action.response,
        loading: false,
      };
    }
    case 'GET_TAGS_ERROR': {
      return {
        ...state,
        error: action.response,
        loading: false,
      };
    }
    default: {
      return state;
    }
  }
};

export default getTagsReducer;
