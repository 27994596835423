import { Dispatch } from 'redux';
import {
  getRetailerProductPricing,
  getRetailerProductPricingExport,
  IImportRetailerProductPricingBody,
  importRetailerProductPricing,
} from '../../api/Products';
import { IRetailerProductPricingExport } from '../../types';
import { toastr } from 'react-redux-toastr';

export const GET_RETAILER_PRODUCT_PRICING_REQUEST = 'GET_RETAILER_PRODUCT_PRICING_REQUEST';
export const GET_RETAILER_PRODUCT_PRICING_RESPONSE = 'GET_RETAILER_PRODUCT_PRICING_RESPONSE';
export const GET_RETAILER_PRODUCT_PRICING_ERROR = 'GET_RETAILER_PRODUCT_PRICING_ERROR';

export const getRetailerProductPricingRequest = (id: number): (dispatch: Dispatch) => Promise<void> => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: GET_RETAILER_PRODUCT_PRICING_REQUEST,
    });

    try {
      const response = await getRetailerProductPricing(id);
      if (response.status === 200) {
        dispatch({
          type: GET_RETAILER_PRODUCT_PRICING_RESPONSE,
          response: response.data.retailerPricing,
        });
      } else {
        dispatch({
          type: GET_RETAILER_PRODUCT_PRICING_ERROR,
        });
      }
    } catch (err) {
      console.log(err);
      dispatch({
        type: GET_RETAILER_PRODUCT_PRICING_ERROR,
      });
    }
  };
};

export const GET_RETAILER_PRODUCT_PRICING_EXPORT_REQUEST = 'GET_RETAILER_PRODUCT_PRICING_EXPORT_REQUEST';
export const GET_RETAILER_PRODUCT_PRICING_EXPORT_RESPONSE = 'GET_RETAILER_PRODUCT_PRICING_EXPORT_RESPONSE';
export const GET_RETAILER_PRODUCT_PRICING_EXPORT_ERROR = 'GET_RETAILER_PRODUCT_PRICING_EXPORT_ERROR';

export const getRetailerProductPricingExportRequest = (
  id: number,
  onSuccess: (data: IRetailerProductPricingExport) => void,
): (dispatch: Dispatch) => Promise<void> => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: GET_RETAILER_PRODUCT_PRICING_EXPORT_REQUEST,
    });

    try {
      const response = await getRetailerProductPricingExport(id);
      if (response.status === 200) {
        dispatch({
          type: GET_RETAILER_PRODUCT_PRICING_EXPORT_RESPONSE,
        });
        onSuccess(response.data.export);
      } else {
        dispatch({
          type: GET_RETAILER_PRODUCT_PRICING_EXPORT_ERROR,
        });
      }
    } catch (err) {
      console.log(err);
      dispatch({
        type: GET_RETAILER_PRODUCT_PRICING_EXPORT_ERROR,
      });
    }

    return null;
  };
};

export const IMPORT_RETAILER_PRODUCT_PRICING_REQUEST = 'IMPORT_RETAILER_PRODUCT_PRICING_REQUEST';
export const IMPORT_RETAILER_PRODUCT_PRICING_RESPONSE = 'IMPORT_RETAILER_PRODUCT_PRICING_RESPONSE';
export const IMPORT_RETAILER_PRODUCT_PRICING_ERROR = 'IMPORT_RETAILER_PRODUCT_PRICING_ERROR';

export const importRetailerProductPricingRequest = (
  body: IImportRetailerProductPricingBody,
  onSuccess: () => void,
  onError: () => void,
): (dispatch: Dispatch) => Promise<void> => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: IMPORT_RETAILER_PRODUCT_PRICING_REQUEST,
    });

    try {
      const response = await importRetailerProductPricing(body);
      if (response.status === 200) {
        const { results } = response.data;

        dispatch({
          type: IMPORT_RETAILER_PRODUCT_PRICING_RESPONSE,
          response: results,
        });

        if (results.length === 0) {
          toastr.success(
            'Retailer Pricing Updated',
            'The retailer product pricing has been updated successfully.',
          );
          onSuccess();
        } else {
          onError();
        }
      } else {
        dispatch({
          type: IMPORT_RETAILER_PRODUCT_PRICING_ERROR,
        });
      }
    } catch (err) {
      console.log(err);
      dispatch({
        type: IMPORT_RETAILER_PRODUCT_PRICING_ERROR,
      });
    }
  };
};
