import Fetch from '../apiConfig/apiConfig';
import { AxiosPromise } from 'axios';

export interface IGetCustomerGroupsResponse {
  customerGroups: string[];
}

export const getCustomerGroups = (): AxiosPromise<IGetCustomerGroupsResponse> => {
  return Fetch().then(axios => axios.get('supplychain/customer-groups'));
};
