import { Reducer } from 'redux';
import { IApiRequest, IRetailerStore } from '../../types';
import { EXPORT_TRANSPORT_COSTS_ERROR,
         EXPORT_TRANSPORT_COSTS_REQUEST,
         EXPORT_TRANSPORT_COSTS_RESPONSE,
         IMPORT_TRANSPORT_COSTS_RESPONSE,
         IMPORT_TRANSPORT_COSTS_REQUEST,
         IMPORT_TRANSPORT_COSTS_ERROR,
       } from '../../actions/StoresManagement/storesAction';

const initialState: IApiRequest<IRetailerStore[]> = {
  data: [],
  error: '',
  UpdatedAt: null,
  loading: false,
};

const storesReducer: Reducer<IApiRequest<IRetailerStore[]>>
= (state: IApiRequest<IRetailerStore[]> = initialState, action : any) => {
  switch (action.type) {
    case EXPORT_TRANSPORT_COSTS_RESPONSE: {
      return {
        data: action.response,
        error: '',
        UpdatedAt: new Date(),
        loading: false,
      };
    }
    case IMPORT_TRANSPORT_COSTS_RESPONSE: {
      return {
        data: action.response,
        error: '',
        UpdatedAt: new Date(),
        loading: false,
      };
    }
    case EXPORT_TRANSPORT_COSTS_REQUEST: {
      return {
        data: [],
        error: '',
        UpdatedAt: null,
        loading: true,
      };
    }
    case IMPORT_TRANSPORT_COSTS_REQUEST: {
      return {
        data: [],
        error: '',
        UpdatedAt: null,
        loading: true,
      };
    }
    case EXPORT_TRANSPORT_COSTS_ERROR: {
      return initialState;
    }
    case IMPORT_TRANSPORT_COSTS_ERROR: {
      return initialState;
    }

    default: {
      return state;
    }
  }
};

export default storesReducer;
