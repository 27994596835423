import { Reducer } from 'redux';
import {
  SIGNUP_ERROR,
  SIGNUP_REQUEST,
  SIGNUP_SUCCESS } from '../actions/signUpAction';
import { ISignUp } from '../types';

const initialState: ISignUp = {
  loading: false,
  error: '',
};

const signUpReducer: Reducer<ISignUp> = (state: ISignUp = initialState, action : any) => {
  switch (action.type) {
    case SIGNUP_REQUEST: {
      return {
        loading: true,
        error: '',
      };
    }
    case SIGNUP_SUCCESS: {
      return {
        loading: false,
        error: '',
      };
    }
    case SIGNUP_ERROR: {
      return {
        loading: false,
        error: 'An error has occurred. Please try again.',
      };
    }
    default: {
      return state;
    }
  }
};

export default signUpReducer;
