import React, { useState, useEffect } from 'react';
import './DesktopHeader.scss';
import { navigateTo } from '../../../routing/Navigation';
import { Button, DesktopMenu, MenuItem, Dropdown } from '@orderly/morrisons-component-library';
import { DebounceInput } from 'react-debounce-input';
import { NavLink } from 'react-router-dom';
import { IDesktopHeaderProps } from './IDesktopHeaderProps';
import { ICategory, ICountdown, IOrderOpportunity } from '../../../types';
import { mapCategory, mapCategoryStyle } from '../../../helpers/Category/mapCategory';
import { getTotalPrice } from '../../../helpers/Product/ProductPriceHelper';
import { isAccountManager, isAdmin, isBulkUser, isLoggedIn, isStaff } from '../../../helpers/Users/UserHelper';
import { StringToBoolean } from '../../../helpers/TypesHelper';
import { toastr } from 'react-redux-toastr';
import moment from 'moment';
import logo from '../../../assets/images/logo.svg';
import ShoppingListControls from '../../ShoppingListControls';
import { formatCountdown, getRemainingTime } from '../../../helpers/DateHelper';

function highlightDescriptionSearchTerms(description: string, term: string): any {
  const regex = new RegExp(`(${term})`, 'gi');
  return { __html: description.replace(regex, '<b>$1</b>') };
}

const DesktopHeader = (props: IDesktopHeaderProps) => {
  const [basketTotal, setBasketTotal] = useState('£0.00');
  const [upcomingOrders, setUpcomingOrder] = useState([]);

  const {
    showCompleteMenu,
    logout,
    onSearch,
    onShowSearch,
    onHideSearch,
    searchResult,
    searchPhrase,
    showSearch,
    onCompleteSearch,
    showSearchResults,
    onClick,
    isImpersonating,
    basket,
    categories,
    userDetails,
    retailerDetails,
    orderOpportunitiesCountdown,
    getOrderOpportunitiesCountdown,
  } = props;
  const [countdowns, setCountdowns] = useState<ICountdown[]>([]);

  useEffect(() => {
    if (!isLoggedIn(userDetails)) {
      return;
    }
    if (retailerDetails && retailerDetails.stores.length > 0 && !isStaff(userDetails)) {
      const store = retailerDetails.stores.slice(0, 1)[0];
      getOrderOpportunitiesCountdown({ storeId: store.externalId, categoryCode: '' });
    }
  }, [retailerDetails]);

  useEffect(() => {
    if (
      orderOpportunitiesCountdown &&
      !orderOpportunitiesCountdown.loading &&
      orderOpportunitiesCountdown.data &&
      orderOpportunitiesCountdown.data.length > 0
    ) {
      const deliverySlots: IOrderOpportunity[] = orderOpportunitiesCountdown.data;
      const soonestSlot = deliverySlots.slice(0, 1);

      updateCountdownTimers(soonestSlot);
      const interval = setInterval(() => {
        updateCountdownTimers(soonestSlot);
      }, 1000);
      setUpcomingOrder(soonestSlot);
      return () => clearInterval(interval);
    }
    setUpcomingOrder([]);
    setCountdowns([]);
  }, [orderOpportunitiesCountdown]);

  useEffect(() => {
    if (basket && basket.data) {
      const total = getTotalPrice(basket.data.items);
      setBasketTotal(total);
    }
  }, [basket]);

  function updateCountdownTimers(slots: IOrderOpportunity[]): void {
    const tempCountdowns: ICountdown[] = [];
    slots.map((slot, index) => {
      tempCountdowns[index] = getCountdownTimer(slot);
    });
    setCountdowns(tempCountdowns);
  }

  function getCountdownTimer(slot: IOrderOpportunity): ICountdown {
    const now = new Date().getTime();
    const cutoff = new Date(slot.cutOffDate).getTime();
    const differenceStamp = cutoff - now;
    const differenceMilliseconds = moment.duration(differenceStamp).asMilliseconds();

    return getRemainingTime(differenceMilliseconds);
  }

  const navLinkTo = (subcategory: any): string => {
    return `/catalogue?category=${subcategory.categoryId}&subcategory=${subcategory.id}`;
  };

  const accountOptions = [
    { label: 'My Account', value: 'My Account', url: '/my-account' },
    { label: 'My Orders', value: 'My Orders', url: '/orders' },
    { label: 'My Documents', value: 'My Documents', url: '/documents' },
  ];

  function onSlotClick(order: IOrderOpportunity): void {
    if (new Date(order.cutOffDate) > new Date()) {
      navigateTo('/my-account');
    } else {
      toastr.error('Error', 'Order has been cutoff');
    }
  }

  return (
    <div className="header">
      <div className="header-bar-content desktop-header header-top">
        <div className="logo-wrapper">
          <a className="logo" onClick={onClick}>
            <img src={logo} className="img-responsive" alt="logo" />
          </a>
        </div>
        {showCompleteMenu && (
          <div className="logged-in-menu-items">
            {!isStaff(userDetails) && upcomingOrders && countdowns.length > 0 && (
              <div className="pending-orders">
                {upcomingOrders.map((slot: IOrderOpportunity, idx) => {
                  if (new Date(slot.cutOffDate) > new Date()) {
                    return (
                      <div
                        key={`slot_${idx}`}
                        className="order-block"
                        onClick={() => onSlotClick(slot)}
                      >
                        <div className="order-category default-order">
                          NEXT ORDER CUT-OFF
                          <br />
                          {moment(slot.cutOffDate).format('ddd DD MMM').toUpperCase()}
                        </div>
                        <div className="timer">
                          {formatCountdown(countdowns[idx]).map((part, index) => {
                            return <div key={index}>{part}</div>;
                          })}
                        </div>
                      </div>
                    );
                  }
                })}
              </div>
            )}
            {showSearch && (
              <Dropdown
                className="account-dropdown"
                label=""
                name="accountDropdown"
                onChange={(account: any) => navigateTo(account.url)}
                selectedValue="My Account"
                options={accountOptions}
              />
            )}
            <Button
              className="link logout"
              icon="icon-user"
              iconPos="left"
              text={isImpersonating ? 'Stop impersonating' : 'Log out'}
              type="button"
              onClick={logout}
            />
          </div>
        )}
      </div>
      {showSearch && (
        <div className="header-bar-content desktop-header header-bottom">
          {showCompleteMenu && (
            <div className={`logged-in-menu-items ${showSearch ? 'search' : 'no-search'}`}>
              {categories && categories.data && (
                <DesktopMenu
                  options={[
                    {
                      key: 0,
                      text: 'Shop',
                      url: '/catalogue',
                      allText: 'All Products',
                      children: categories.data.map(
                        (category: ICategory): MenuItem => ({
                          key: category.id,
                          text: mapCategory(category.description),
                          allText: `All ${mapCategory(category.description)} products`,
                          onRedirect: navigateTo,
                          url: `/catalogue?category=${category.id}`,
                          close: console.log,
                        }),
                      ),
                      close: console.log,
                      onRedirect: navigateTo,
                    },
                  ]}
                  icon="icon-menu"
                  text="Shop"
                  includeViewAll={true}
                  className="menu shop"
                  close={console.log}
                  onRedirect={navigateTo}
                  url="/catalogue"
                />
              )}
              <div className="search-section">
                <DebounceInput
                  type="text"
                  name="search"
                  id="search"
                  placeholder="Find a product"
                  className="search-bar"
                  minLength={3}
                  debounceTimeout={500}
                  onFocus={(event: React.FocusEvent<HTMLInputElement>) => onShowSearch()}
                  onBlur={(event: React.FocusEvent<HTMLInputElement>) =>
                    setTimeout(() => onHideSearch(), 200)
                  }
                  onKeyDown={(event: React.KeyboardEvent<HTMLInputElement>) => {
                    if (event.keyCode === 13) {
                      event.preventDefault();
                      event.currentTarget.blur();
                      onCompleteSearch(event.currentTarget.value);
                    }
                  }}
                  onChange={event => onSearch(event.target.value)}
                  autoComplete="off"
                />
                {showSearchResults &&
                  searchResult &&
                  ((searchResult.subCategories && searchResult.subCategories.length > 0) ||
                    (searchResult.products && searchResult.products.length > 0)) && (
                    <div className="search-results">
                      <ul>
                        {(searchResult.subCategories ? searchResult.subCategories : []).map(
                          (subcategory, index) => {
                            return (
                              <li key={`ds_${index}`}>
                                <NavLink className="search-link" exact to={navLinkTo(subcategory)}>
                                  <span className="icon-categories" />
                                  <div
                                    dangerouslySetInnerHTML={highlightDescriptionSearchTerms(
                                      subcategory.description,
                                      searchPhrase,
                                    )}
                                  />
                                </NavLink>
                              </li>
                            );
                          },
                        )}

                        {(searchResult.products ? searchResult.products : []).map(
                          (product, index) => {
                            if (index > 5) return null;

                            return (
                              <li key={`dp_${index}`}>
                                <NavLink
                                  className="search-link"
                                  exact
                                  to={`/product/${product.itemId}`}
                                >
                                  <div
                                    dangerouslySetInnerHTML={highlightDescriptionSearchTerms(
                                      product.description,
                                      searchPhrase,
                                    )}
                                  />
                                </NavLink>
                              </li>
                            );
                          },
                        )}
                      </ul>
                    </div>
                  )}
              </div>
              <span className="icon-search" />
              <ShoppingListControls />
              <div className="mini-basket-wrapper">
                <Button
                  className="basket tertiary"
                  icon="icon-basket"
                  iconPos="left"
                  text={basketTotal}
                  type="button"
                  onClick={() => navigateTo('/basket')}
                />
                <span className="item-count">
                  {basket && basket.data ? basket.data.items.length : '0'}
                </span>
                {basket && basket.data && basket.data.categoryCode && (
                  <span
                    className={`${mapCategoryStyle(basket.data.categoryCode)}`}
                  >
                    {mapCategory(basket.data.categoryCode)}
                  </span>
                )}
              </div>
            </div>
          )}
        </div>
      )}
      {showCompleteMenu && !isAdmin(userDetails) && !isAccountManager(userDetails) && !isBulkUser(retailerDetails) && (
        <div className="top-menu-buttons">
          <div>
            <Button
              onClick={() => navigateTo('/orders')}
              text="My Orders"
              className=""
              type="button"
            />
            {
              retailerDetails && StringToBoolean(retailerDetails.meta.DhlEnabled) &&
              <Button
                onClick={() => navigateTo('/deliveries')}
                text="Deliveries"
                className=""
                type="button"
              />
            }
            {retailerDetails && StringToBoolean(retailerDetails.meta.PlanogramsEnabled) && (
              <Button
                text="Planograms"
                className=""
                type="button"
                onClick={() => navigateTo('/store-documents')}
              />
            )}
            {retailerDetails && StringToBoolean(retailerDetails.meta.RebatesEnabled) && (
              <Button
                text="Spend More, Save More"
                onClick={() => navigateTo('/rebates')}
                className=""
                type="button"
              />
            )}
            <Button
              text="Promotions"
              onClick={() => navigateTo('/catalogue?tradingPromotion=true')}
              className=""
              type="button"
            />
            {retailerDetails && StringToBoolean(retailerDetails.meta.PrintshopEnabled) && (
              <Button
                text="Printshop"
                onClick={() => navigateTo('/printshop')}
                className=""
                type="button"
              />
            )}
            {retailerDetails && StringToBoolean(retailerDetails.meta.DhlEnabled) && (
              <Button
                onClick={() => navigateTo('/customer-services')}
                text="Customer Services"
                className=""
                type="button"
              />
            )}
            <Button
              text="Presales"
              onClick={() => navigateTo('/presales')}
              className=""
              type="button"
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default DesktopHeader;
