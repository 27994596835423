import { Reducer } from 'redux';
import {
  REGISTER_USER_REQUEST,
  REGISTER_USER_RESPONSE,
  REGISTER_USER_ERROR } from '../actions/registerUserAction';
import { IRegisterUser } from '../types';

const initialState: IRegisterUser = {
  loading: false,
  error: '',
};

const registerUserReducer: Reducer<IRegisterUser> = (state: IRegisterUser = initialState, action : any) => {
  switch (action.type) {
    case REGISTER_USER_REQUEST: {
      return {
        error: '',
        loading: true,
      } as IRegisterUser;
    }
    case REGISTER_USER_RESPONSE: {
      return {
        error: '',
        loading: false,
      } as IRegisterUser;
    }
    case REGISTER_USER_ERROR: {
      return {
        error: action.error || 'An error has occurred. Please try again.',
        loading: false,
      } as IRegisterUser;
    }
    default: {
      return state;
    }
  }
};

export default registerUserReducer;
