import Fetch from '../apiConfig/apiConfig';
import { AxiosPromise } from 'axios';
import { IUpdateOrderStatusResponse } from './IUpdateOrderStatusResponse';

export interface IUpdateOrderStatusParams {
  [key: string]: number | boolean;
  OrderId: number;
  Confirmed: boolean;
}

export const updateOrderStatus = (params: IUpdateOrderStatusParams): AxiosPromise<IUpdateOrderStatusResponse> => {
  return Fetch().then(axios => axios.post('orders/update/confirmed', params));
};
