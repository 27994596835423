import GetInstance from './apiConfig/apiConfig';
import { AxiosPromise } from 'axios';

export interface ISignUpParameters {
  FullName: string;
  Email: string;
  Telephone: string;
  Company: string;
  Reason: string;
  Recaptcha: string;
}

export const signUp = (params: ISignUpParameters) : AxiosPromise<any> => {
  return GetInstance().then(axios => axios.post('signup/register-interest', params));
};
