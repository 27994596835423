import { AxiosResponse } from 'axios';
import { editStockAllocationApi, getStockAllocationApi, lockInOrderApi } from '../../api/Orders/stockAllocationApi';
import { IStockAllocationResponse } from '../../api/Orders/IStockAllocationResponse';
import { Dispatch } from 'redux';
import { toastr } from 'react-redux-toastr';
import { IStockAllocation, IStoreState } from '../../types';
import { store } from '../../store';

export const GET_STOCK_ALLOCATION_REQUEST = 'GET_STOCK_ALLOCATION_REQUEST';
export const GET_STOCK_ALLOCATION_RESPONSE = 'GET_STOCK_ALLOCATION_RESPONSE';
export const GET_STOCK_ALLOCATION_ERROR = 'GET_STOCK_ALLOCATION_ERROR';

export const EDIT_STOCK_ALLOCATION_REQUEST = 'EDIT_STOCK_ALLOCATION_REQUEST';
export const EDIT_STOCK_ALLOCATION_RESPONSE = 'EDIT_STOCK_ALLOCATION_RESPONSE';
export const EDIT_STOCK_ALLOCATION_ERROR = 'EDIT_STOCK_ALLOCATION_ERROR';

export const LOCK_IN_ORDER_REQUEST = 'LOCK_IN_ORDER_REQUEST';
export const LOCK_IN_ORDER_RESPONSE = 'LOCK_IN_ORDER_RESPONSE';
export const LOCK_IN_ORDER_ERROR = 'LOCK_IN_ORDER_ERROR';

export const getStockAllocationRequest = (): any => {
  return (dispatch: any) => {
    dispatch({
      type: GET_STOCK_ALLOCATION_REQUEST,
    });

    return getStockAllocationApi()
      .then((response: AxiosResponse<IStockAllocationResponse>) => {
        if (response.status === 200) {
          dispatch({
            type: GET_STOCK_ALLOCATION_RESPONSE,
            response: response.data.getStockAllocation,
          });
        } else {
          getStockAllocationError(dispatch, response.data as unknown as string);
        }
      })
      .catch((err: any) => {
        console.log(err);
        getStockAllocationError(dispatch);
      });
  };
};

const getStockAllocationError = (dispatch: any, message: string = null) => dispatch({
  type: GET_STOCK_ALLOCATION_ERROR,
  response: message,
});

export const editStockAllocationRequest = (order: IStockAllocation): (dispatch: Dispatch) => Promise<any> => {
  return async (dispatch: Dispatch) => {
    const state: IStoreState = store.getState();

    if (!state.stockAllocations.loading) {
      dispatch({
        type: EDIT_STOCK_ALLOCATION_REQUEST,
      });

      try {
        const response = await editStockAllocationApi({ order });

        if (response.status === 200) {
          toastr.success('Order Edited', 'Order has been edited successfully.');
          dispatch({
            type: EDIT_STOCK_ALLOCATION_RESPONSE,
          });
        } else {
          onEditStockAllocationError(dispatch);
        }
      } catch (error) {
        console.log(error);
        onEditStockAllocationError(dispatch);
      }
    }
  };
};

const onEditStockAllocationError = (dispatch: Dispatch) => dispatch({
  type: EDIT_STOCK_ALLOCATION_ERROR,
});

export const lockInOrderRequest = (order: IStockAllocation): (dispatch: Dispatch) => Promise<any> => {
  return async (dispatch: Dispatch) => {
    const state: IStoreState = store.getState();

    if (!state.stockAllocations.loading) {
      dispatch({
        type: LOCK_IN_ORDER_REQUEST,
      });

      try {
        const response = await lockInOrderApi({ order });

        if (response.status === 200) {
          toastr.success('Order Updated', 'Order has been updated successfully.');
          dispatch({
            type: LOCK_IN_ORDER_RESPONSE,
          });
        } else {
          onLockInOrderError(dispatch);
        }
      } catch (error) {
        console.log(error);
        onLockInOrderError(dispatch);
      }
    }
  };
};

const onLockInOrderError = (dispatch: Dispatch) => dispatch({
  type: LOCK_IN_ORDER_ERROR,
});
