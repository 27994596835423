import { toastr } from 'react-redux-toastr';
import { AxiosResponse } from 'axios';
import { ISupplyDemandOrderFormParams } from '../../api/Reports/SupplyDemandOrderForm/ISupplyDemandOrderFormParams';
import { ISupplyDemandOrderFormResponse } from '../../api/Reports/SupplyDemandOrderForm/ISupplyDemandOrderFormResponse';
import { getSupplyDemandOrderForm } from '../../api/Reports/ReportsApi';

export const GET_SUPPLY_DEMAND_ORDER_FROM_REQUEST = 'GET_SUPPLY_DEMAND_ORDER_FROM_REQUEST';
export const GET_SUPPLY_DEMAND_ORDER_FROM_RESPONSE = 'GET_SUPPLY_DEMAND_ORDER_FROM_RESPONSE';
export const GET_SUPPLY_DEMAND_ORDER_FROM_ERROR = 'GET_SUPPLY_DEMAND_ORDER_FROM_ERROR';

export const getSupplyDemandOrderFormRequest = (
    parameters: ISupplyDemandOrderFormParams,
    onSuccess: (data: any) => void,
): any => {
  return (dispatch: any) => {
    dispatch({
      type: GET_SUPPLY_DEMAND_ORDER_FROM_REQUEST,
    });

    return getSupplyDemandOrderForm(parameters)
      .then((response: AxiosResponse<ISupplyDemandOrderFormResponse>) => {
        if (response.status === 200) {
          dispatch({
            type: GET_SUPPLY_DEMAND_ORDER_FROM_RESPONSE,
          });
          toastr.success('Report Generated', 'The supply demand order form has been exported successfully.');
          onSuccess(response.data);
        } else {
          onError(dispatch);
        }
      })
      .catch((err: any) => {
        console.log(err);
        onError(dispatch);
      });
  };
};

const onError = (dispatch: any) => {
  dispatch({
    type: GET_SUPPLY_DEMAND_ORDER_FROM_ERROR,
  });
  toastr.error('Error', 'An error has occurred whilst exporting the supply demand order form. Please try again.');
};
