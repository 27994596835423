import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import './Footer.scss';
import FooterPanelItem from './FooterPanel/FooterPanelItem';
import { footerPanelLinks } from './footerLinks';
import { IFooterPanel } from './interfaces';

const Footer = () => {
  const [expandedPanelId, setExpandedPanelId] = useState('');

  const toggleExpandPanel = (id: string) => {
    if (expandedPanelId !== id) {
      setExpandedPanelId(id);
    } else {
      setExpandedPanelId('');
    }
  };

  return (
    <footer className={'main-footer alternative-main-footer'}>
      <div className="alternative-main-footer-content">
        <div className="footer-logo-wrapper">
          <NavLink className="footer-logo" exact to={'/'} />
          <div className="footer-social">
            <a
              className="facebook"
              href="http://www.facebook.com/morrisons"
              target="_blank"
              rel="noopener noreferrer"
            >
              facebook
            </a>

            <a
              className="twitter"
              href="http://www.twitter.com/Morrisons"
              target="_blank"
              rel="noopener noreferrer"
            >
              twitter
            </a>

            <a
              className="instagram"
              href="http://instagram.com/morrisons"
              target="_blank"
              rel="noopener noreferrer"
            >
              instagram
            </a>

            <a
              className="youtube"
              href="http://youtube.com/user/morrisons"
              target="_blank"
              rel="noopener noreferrer"
            >
              youtube
            </a>
          </div>
        </div>
        <div className="links">
          {
            footerPanelLinks.map((panel: IFooterPanel, index: number) =>
              <FooterPanelItem
                key={index}
                id={panel.id}
                title={panel.title}
                links={panel.links}
                isExpanded={expandedPanelId === panel.id}
                onExpanded={toggleExpandPanel}
              />,
            )
          }
        </div>
        <div className="footer-copyright">
            <span id="year" /> &copy;&nbsp;{new Date().getFullYear()}&nbsp;All rights reserved. Morrisons Ltd
        </div>
      </div>
    </footer>
  );
};

export default Footer;
