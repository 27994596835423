import React, { useState, useEffect, useRef } from 'react';
import { Counter } from '@orderly/morrisons-component-library';
import { IQuantityCounterProps } from './IQuantityCounterProps';
import useDebounce from '../../helpers/Hooks/useDebounce';
import styles from './QuantityCounter.module.scss';

const QuantityCounter = ({ item, onQuantityChange, maxOrderQuantity }: IQuantityCounterProps) => {
  const [quantity, setQuantity] = useState(item.quantity);
  const debouncedQuantity = useDebounce(quantity, 500);
  const firstRun = useRef(true);

  useEffect(
    () => {
      if (firstRun.current) {
        firstRun.current = false;
        return;
      }

      onQuantityChange(item, debouncedQuantity);
    },
    [debouncedQuantity],
  );

  return (
    <Counter
      className={`${styles.counter} quantity-counter`}
      changeBy={1}
      minValue={0}
      maxValue={maxOrderQuantity || 999}
      value={quantity}
      onChange={quantity => setQuantity(quantity)}
    />
  );
};

export default QuantityCounter;
