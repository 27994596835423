export const formatCurrency = (value: number) : string => `£${(value / 100).toFixed(2)}`;

export enum Locale {
  EN_US = 'en-US',
  EN_GB = 'en-GB',
  FR_FR = 'fr-FR',
}

export enum CurrencyCode {
  USD = 'USD',
  GBP = 'GBP',
  EUR = 'EUR',
}

export const formatCurrencyLocale = (amount: number,
   currencyCode: CurrencyCode = CurrencyCode.GBP,
   locale: Locale = Locale.EN_GB) => {
  return new Intl.NumberFormat(locale, {
    style: 'currency',
    currency: currencyCode,
  }).format(amount);
};
