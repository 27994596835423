import { Action } from 'redux';
import { FolderType } from '../../types';
import {
  getFolderContents,
  IGetFolderContentsParams,
  IGetFolderContentsResponse,
} from '../../api/Documents/getFolderContentsApi';
import { AxiosResponse } from 'axios';

export const CLEAR_FOLDER_CONTENTS = 'CLEAR_FOLDER_CONTENTS';
export type CLEAR_FOLDER_CONTENTS = typeof CLEAR_FOLDER_CONTENTS;

export const GET_FOLDER_CONTENTS = 'GET_FOLDER_CONTENTS';
export type GET_FOLDER_CONTENTS = typeof GET_FOLDER_CONTENTS;

export const GET_FOLDER_CONTENTS_RESPONSE = 'GET_FOLDER_CONTENTS_RESPONSE';
export type GET_FOLDER_CONTENTS_RESPONSE = typeof GET_FOLDER_CONTENTS_RESPONSE;

export const GET_FOLDER_CONTENTS_ERROR = 'GET_FOLDER_CONTENTS_ERROR';
export type GET_FOLDER_CONTENTS_ERROR = typeof GET_FOLDER_CONTENTS_ERROR;

export interface IGetFolderContentsError extends Action {
  type: GET_FOLDER_CONTENTS_ERROR;
  error?: string;
}

export const getFolderContentsRequest = (parameters: IGetFolderContentsParams,
                                         getDocumentsOnly = false): (dispatch: any) => Promise<void> => {
  return (dispatch : any) => {
    if (parameters.folderPath.length > 0 && !getDocumentsOnly && parameters.folderType !== FolderType.Printshop) {
      const folderParameters: IGetFolderContentsParams = {
        retailerId: parameters.retailerId,
        folderPath: '',
        prefix: '',
        getFoldersOnly: true,
        folderType: parameters.folderType,
        customerGroup: parameters.customerGroup,
      };
      dispatch(getFolderContentsRequest(folderParameters));
    }
    dispatch({
      type: GET_FOLDER_CONTENTS,
    });
    return getFolderContents(parameters)
      .then((response: AxiosResponse<IGetFolderContentsResponse>) => {
        if (response.status === 200) {
          dispatch({
            type: GET_FOLDER_CONTENTS_RESPONSE,
            response: {
              data: response.data.folderContents,
              useFolders: !parameters.folderPath || parameters.folderType === FolderType.Printshop,
              useDocuments: !parameters.getFoldersOnly,
            },
          });
        } else {
          onError(dispatch);
        }
      })
      .catch((err: any) => {
        console.log(err);
        onError(dispatch);
      });
  };
};

export const clearFolderContents = () => {
  return {
    type: CLEAR_FOLDER_CONTENTS,
  };
};

const onError = (dispatch: any) => dispatch({
  type: GET_FOLDER_CONTENTS_ERROR,
});
