import Fetch from './apiConfig/apiConfig';
import { AxiosPromise } from 'axios';
import { ISubCategory } from '../types';

export interface IGetSubCategoriesParams {
  [key: string]: any;
  categoryId: number;
  tradingGroups: string[];
}

export interface IGetSubCategoriesResponse {
  subcategories: ISubCategory[];
}

export const getSubCategories = (params: IGetSubCategoriesParams) : AxiosPromise<IGetSubCategoriesResponse> => {
  return Fetch().then(axios => axios.post('products/subcategories', params));
};
