import { IPostAdminContactFormParams, postAdminContactForm } from '../../api/Admin/adminContactFormApi';
import { Dispatch } from 'redux';
import { toastr } from 'react-redux-toastr';

export const POST_ADMIN_CONTACT_FORM_REQUEST = 'POST_ADMIN_CONTACT_FORM_REQUEST';
export type POST_ADMIN_CONTACT_FORM_REQUEST = typeof POST_ADMIN_CONTACT_FORM_REQUEST;
export const POST_ADMIN_CONTACT_FORM_RESPONSE = 'POST_ADMIN_CONTACT_FORM_RESPONSE';
export type POST_ADMIN_CONTACT_FORM_RESPONSE = typeof POST_ADMIN_CONTACT_FORM_RESPONSE;
export const POST_ADMIN_CONTACT_FORM_ERROR = 'POST_ADMIN_CONTACT_FORM_ERROR';
export type POST_ADMIN_CONTACT_FORM_ERROR = typeof POST_ADMIN_CONTACT_FORM_ERROR;

export const postAdminContactFormRequest = (params: IPostAdminContactFormParams):
(dispatch: Dispatch) => Promise<void> => {
  return async (dispatch: Dispatch): Promise<void> => {
    dispatch({
      type: POST_ADMIN_CONTACT_FORM_REQUEST,
      parameters: params,
    });

    try {
      const response = await postAdminContactForm(params);

      if (response.status === 200) {
        toastr.success(
          'Email sent',
          'Your message has been sent to the Wholesale team.',
        );

        dispatch({
          type: POST_ADMIN_CONTACT_FORM_RESPONSE,
        });
      } else {
        onError(dispatch);
      }
    } catch (error) {
      console.log(error);
      onError(error);
    }
  };
};

const onError = (dispatch: Dispatch) => dispatch({
  type: POST_ADMIN_CONTACT_FORM_ERROR,
});
