import { Action } from 'redux';
import { AxiosResponse } from 'axios';
import { updateTenants } from '../api/Retailers/retailersApi';
import { IUpdateRetailersTenantsParams } from '../api/Retailers/IUpdateRetailersTenantsParams';

export const UPDATE_TENANT_REQUEST = 'UPDATE_TENANT_REQUEST';
export type UPDATE_TENANT_REQUEST = typeof UPDATE_TENANT_REQUEST;

export const UPDATE_TENANT_RESPONSE = 'UPDATE_TENANT_RESPONSE';
export type UPDATE_TENANT_RESPONSE = typeof UPDATE_TENANT_RESPONSE;

export const UPDATE_TENANT_ERROR = 'UPDATE_TENANT_ERROR';
export type UPDATE_TENANT_ERROR = typeof UPDATE_TENANT_ERROR;

export interface IUpdateTenantResponse extends Action {
  type: UPDATE_TENANT_RESPONSE;
}

export interface IUpdateTenantError extends Action {
  type: UPDATE_TENANT_ERROR;
  error?: string;
}

export const updateTenantRequest = (parameters: IUpdateRetailersTenantsParams): any => {
  return (dispatch : any) => {

    dispatch({
      type: UPDATE_TENANT_REQUEST,
    });

    return updateTenants(parameters)
     .then((response: AxiosResponse) => {
       if (response.status === 200) {
         dispatch({
           type: UPDATE_TENANT_RESPONSE,
         });

       } else {
         onError(dispatch);
       }
     })
     .catch((err: any) => {
       console.log(err);
       onError(dispatch);
     });
  };
};

const onError = (dispatch: any) => dispatch({
  type: UPDATE_TENANT_ERROR,
});
