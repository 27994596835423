import React, { useEffect } from 'react';
import { Dispatch } from 'redux';
import { IStoreState } from '../../types';
import { connect } from 'react-redux';
import { IOrderItemsWithPriceChangesProps } from './IOrderItemsWithPriceChangesProps';
import { getOrderItemsWithChangesRequest } from '../../actions/Orders/orderItemsWithChangesAction';
import { CurrencyCode, Locale, formatCurrencyLocale } from '../../helpers/Format/formatCurrency';
import styles from './OrderItemsWithPriceChanges.module.scss';

function OrderItemsWithPriceChanges({
  orderNumber,
  orderItemsWithChanges,
  getOrderItemsWithChanges,
}: IOrderItemsWithPriceChangesProps): JSX.Element {
  const { loading, data } = orderItemsWithChanges;
  useEffect(() => {
    getOrderItemsWithChanges(orderNumber);
  }, [orderNumber]);

  if (loading || !data || (data && data.length === 0)) {
    return null;
  }

  return (
    <div className={styles.container}>
      <h4>
        The following order items have changed since this order was created
      </h4>
      <table className={styles.table}>
        <thead>
          <tr>
            <th>
              Description
            </th>
            <th>
              Old Price
            </th>
            <th>
              New Price
            </th>
          </tr>
        </thead>
        <tbody>
          {
            data.map((item) => {
              return (
                <tr key={item.description}>
                  <td>
                    {item.description}
                  </td>
                  {
                    item.available ? <>
                      <td>
                        {formatCurrencyLocale(item.oldPrice / 100, CurrencyCode.GBP, Locale.EN_GB)}
                      </td>
                      <td>
                        {formatCurrencyLocale(item.newPrice / 100, CurrencyCode.GBP, Locale.EN_GB)}
                      </td>
                    </> :
                    <td colSpan={2}>Unavailable</td>
                  }
                </tr>
              );
            })
          }
        </tbody>
      </table>
    </div>
  );
}

const mapStateToProps = (state: IStoreState) => {
  return {
    orderItemsWithChanges: state.orderItemsWithChanges,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<any>) => {
  return {
    getOrderItemsWithChanges: (orderNumber: string) => dispatch(getOrderItemsWithChangesRequest(orderNumber)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(OrderItemsWithPriceChanges);
