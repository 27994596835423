import Fetch from '../apiConfig/apiConfig';
import { AxiosPromise } from 'axios';
import { IDeliveryDate } from '../../types';

interface IScheduledDeliveriesResponse {
  deliveryDates: IDeliveryDate[];
}

export const getScheduledDeliveries = (storeId: number): AxiosPromise<IScheduledDeliveriesResponse> => {
  return Fetch().then(axios => axios.get(`orders/deliveries/scheduled?storeId=${storeId}`));
};
