import { Reducer } from 'redux';
import { IApiRequest, IOrderImportProductsCsv } from '../../types/index';
import {
  GET_ORDER_CSV_REQUEST,
  GET_ORDER_CSV_RESPONSE,
  GET_ORDER_CSV_ERROR,
  CLEAR_ORDER_CSV_DOWNLOAD,
} from '../../actions/Basket/getOrderImportCsvAction';

const initialState: IApiRequest<IOrderImportProductsCsv> = {
  data: null,
  loading: false,
  error: '',
  UpdatedAt: '',
};

const downloadOrderImportCsvReducer: Reducer<IApiRequest<IOrderImportProductsCsv>> = (
  state: IApiRequest<IOrderImportProductsCsv> = initialState,
  action: any,
) => {
  switch (action.type) {
    case GET_ORDER_CSV_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }
    case GET_ORDER_CSV_RESPONSE: {
      return {
        data: action.response,
        loading: false,
        error: null,
        UpdatedAt: '',
      };
    }
    case CLEAR_ORDER_CSV_DOWNLOAD: {
      return initialState;
    }
    case GET_ORDER_CSV_ERROR: {
      return {
        ...initialState,
        error: 'An error has occured',
      };
    }
    default: {
      return state;
    }

  }
};

export default downloadOrderImportCsvReducer;
