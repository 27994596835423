import { Reducer } from 'redux';
import {
  FORGOT_PASSWORD_CONFIRMATION_REQUEST,
  FORGOT_PASSWORD_CONFIRMATION_RESPONSE,
  FORGOT_PASSWORD_CONFIRMATION_ERROR } from '../actions/forgotPasswordConfirmationAction';
import { IForgotPasswordConfirmation } from '../types';

const initialState: IForgotPasswordConfirmation = {
  loading: false,
  error: '',
};

const userLoginReducer: Reducer<IForgotPasswordConfirmation>
= (state: IForgotPasswordConfirmation = initialState, action : any) => {
  switch (action.type) {
    case FORGOT_PASSWORD_CONFIRMATION_REQUEST: {
      return {
        error: '',
        loading: true,
      } as IForgotPasswordConfirmation;
    }
    case FORGOT_PASSWORD_CONFIRMATION_RESPONSE: {
      return {
        error: '',
        loading: false,
      } as IForgotPasswordConfirmation;
    }
    case FORGOT_PASSWORD_CONFIRMATION_ERROR: {
      return {
        error: action.error || 'An error has occurred. Please try again.',
        loading: false,
      } as IForgotPasswordConfirmation;
    }
    default: {
      return state;
    }
  }
};

export default userLoginReducer;
