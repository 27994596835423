import { AxiosResponse } from 'axios';
import { getDispatchedOrdersApi, submitDispatchedOrderApi } from '../../api/Orders/DispatchedOrder/dispatchedOrderApi';
import { IDispatchedOrderResponse } from '../../api/Orders/DispatchedOrder/IDispatchedOrderResponse';
import { Dispatch } from 'redux';
import { toastr } from 'react-redux-toastr';
import { IDispatchedOrder, IStoreState } from '../../types';
import { store } from '../../store';

export const GET_DISPATCHED_ORDERS_REQUEST = 'GET_DISPATCHED_ORDERS_REQUEST';
export const GET_DISPATCHED_ORDERS_RESPONSE = 'GET_DISPATCHED_ORDERS_RESPONSE';
export const GET_DISPATCHED_ORDERS_ERROR = 'GET_DISPATCHED_ORDERS_ERROR';

export const SUBMIT_DISPATCHED_ORDER_REQUEST = 'SUBMIT_DISPATCHED_ORDER_REQUEST';
export const SUBMIT_DISPATCHED_ORDER_RESPONSE = 'SUBMIT_DISPATCHED_ORDER_RESPONSE';
export const SUBMIT_DISPATCHED_ORDER_ERROR = 'SUBMIT_DISPATCHED_ORDER_ERROR';

export const getDispatchedOrdersRequest = (): any => {
  return (dispatch: any) => {
    dispatch({
      type: GET_DISPATCHED_ORDERS_REQUEST,
    });

    return getDispatchedOrdersApi()
      .then((response: AxiosResponse<IDispatchedOrderResponse>) => {
        if (response.status === 200) {
          dispatch({
            type: GET_DISPATCHED_ORDERS_RESPONSE,
            response: response.data.getDispatchedOrders,
          });
        } else {
          getDispatchedOrdersError(dispatch, response.data as unknown as string);
        }
      })
      .catch((err: any) => {
        console.log(err);
        getDispatchedOrdersError(dispatch);
      });
  };
};

const getDispatchedOrdersError = (dispatch: any, message: string = null) => dispatch({
  type: GET_DISPATCHED_ORDERS_ERROR,
  response: message,
});

export const submitDispatchedOrderRequest = (order: IDispatchedOrder): (dispatch: Dispatch) => Promise<any> => {
  return async (dispatch: Dispatch) => {
    const state: IStoreState = store.getState();

    if (!state.dispatchedOrders.loading) {
      dispatch({
        type: SUBMIT_DISPATCHED_ORDER_REQUEST,
      });

      try {
        const response = await submitDispatchedOrderApi({ order });

        if (response.status === 200) {
          toastr.success('Dispatch Order Submitted', 'Dispatch order has been successfully submitted.');
          dispatch({
            type: SUBMIT_DISPATCHED_ORDER_RESPONSE,
          });

          dispatch(getDispatchedOrdersRequest());

        } else {
          submitDispatchOrderError(dispatch);
        }
      } catch (error) {
        console.log(error);
        submitDispatchOrderError(dispatch);
      }
    }
  };
};

const submitDispatchOrderError = (dispatch: Dispatch) => dispatch({
  type: SUBMIT_DISPATCHED_ORDER_ERROR,
});
