import { Reducer } from 'redux';
import { IFilteredOrdersList } from './IFilteredOrdersList';

const initialState: IFilteredOrdersList = {
  data: null,
};

const orderStatusFiltersReducer: Reducer<any> = (state: any = initialState, action: any): any => {
  if (action.type === 'ORDER_STATUS_FILTERED_ORDERS') {
    return {
      data: action.data,
    };
  }
  return initialState;
};

export default orderStatusFiltersReducer;
