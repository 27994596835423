import { Dispatch } from 'redux';
import { getAdminRetailers } from '../../api/Admin/retailersApi';
import { IAdminRetailers } from '../../types';

export const GET_ADMIN_RETAILERS_REQUEST = 'GET_ADMIN_RETAILERS_REQUEST';
export type GET_ADMIN_RETAILERS_REQUEST = typeof GET_ADMIN_RETAILERS_REQUEST;

export const GET_ADMIN_RETAILERS_RESPONSE = 'GET_ADMIN_RETAILERS_RESPONSE';
export type GET_ADMIN_RETAILERS_RESPONSE = typeof GET_ADMIN_RETAILERS_RESPONSE;

export const GET_ADMIN_RETAILERS_ERROR = 'GET_ADMIN_RETAILERS_ERROR';
export type GET_ADMIN_RETAILERS_ERROR = typeof GET_ADMIN_RETAILERS_ERROR;

export const getAdminRetailersRequest = (): (dispatch: Dispatch) => Promise<any> => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: GET_ADMIN_RETAILERS_REQUEST,
    });

    try {
      const response = await getAdminRetailers();

      if (response.status === 200) {
        const data: IAdminRetailers = response.data;

        dispatch({
          type: GET_ADMIN_RETAILERS_RESPONSE,
          response: data,
        });
      } else {
        onError(dispatch);
      }
    } catch (error) {
      console.log(error);
      onError(dispatch);
    }
  };
};

const onError = (dispatch: Dispatch) => dispatch({
  type: GET_ADMIN_RETAILERS_ERROR,
});
