import Fetch from '../apiConfig/apiConfig';
import { AxiosPromise } from 'axios';
import { IUserDetails, IStores } from '../../types';

export interface IMyAccountResponse {
  userDetails: IUserDetails;
}

export const getMyAccount = () : AxiosPromise<IMyAccountResponse> => {
  return Fetch().then(axios => axios.get('my-account'));
};
