import React, { useState, useEffect } from 'react';

interface ICounterWrapperProps {
  initialValue: number | '';
  changeBy: number;
  maxValue: number;
  minValue: number;
  onChange: (value: number) => void;
  className?: string;
  isSmall?: boolean;
  style?: React.CSSProperties;
}

const CounterWrapper: React.FC<ICounterWrapperProps> = ({
  initialValue,
  changeBy,
  maxValue,
  minValue,
  onChange,
  className,
  isSmall,
  style,
}) => {
  const [value, setValue] = useState<number | ''>(initialValue);

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  const handleIncrement = () => {
    if (value === '') {
      setValue(changeBy);
      onChange(changeBy);
    } else if (value < maxValue) {
      const newValue = value + changeBy;
      setValue(newValue);
      onChange(newValue);
    }
  };

  const handleDecrement = () => {
    if (value === '') {
      setValue(minValue);
      onChange(minValue);
    } else if (value > minValue) {
      const newValue = value - changeBy;
      setValue(newValue);
      onChange(newValue);
    }
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value;
    if (inputValue === '') {
      setValue(minValue);
      onChange(minValue);
    } else {
      const numericValue = parseInt(inputValue, 10);
      if (!isNaN(numericValue) && numericValue >= minValue && numericValue <= maxValue) {
        setValue(numericValue);
        onChange(numericValue);
      }
    }
  };

  return (
    <div className={`counter ${className}`} style={style}>
      <button
        className={`counter-button decrement ${isSmall ? 'small' : ''}`}
        type="button"
        onClick={handleDecrement}
      >
        -
      </button>
      <input
        className="counter-value"
        type="text"
        value={value.toString()}
        onChange={handleInputChange}
      />
      <button
        className={`counter-button increment ${isSmall ? 'small' : ''}`}
        type="button"
        onClick={handleIncrement}
      >
        +
      </button>
    </div>
  );
};

export default CounterWrapper;
