import { Action } from 'redux';
import { IRebatesResponse } from '../../types';
import { AxiosResponse } from 'axios';
import { IGetRebatesParams, getRebatesApi } from '../../api/Rebates/getRebatesApi';

export const GET_REBATES_REQUEST = 'GET_REBATES_REQUEST';
export const GET_REBATES_RESPONSE = 'GET_REBATES_RESPONSE';
export const GET_REBATES_ERROR = 'GET_REBATES_ERROR';

export interface IGetRebatesResponse extends Action {
  type: typeof GET_REBATES_RESPONSE;
  response: IRebatesResponse;
}

export interface IGetRebatesError extends Action {
  type: typeof GET_REBATES_ERROR;
  error?: string;
}

export const getRebatesRequest = (params: IGetRebatesParams): (dispatch: any) => Promise<void> => {
  return (dispatch : any) => {

    dispatch({
      type: GET_REBATES_REQUEST,
    });

    return getRebatesApi(params)
      .then((response : AxiosResponse<IRebatesResponse>) => {
        if (response.status === 200) {

          dispatch({
            type: GET_REBATES_RESPONSE,
            response: response.data.rebates,
          });

        } else {
          onError(dispatch);
        }
      })
      .catch((err: any) => {
        console.log(err);
        onError(dispatch);
      });
  };
};

const onError = (dispatch: any) => dispatch({
  type: GET_REBATES_ERROR,
});
