import { ISortOrdersValueMapper } from '../interfaces/ISortOrdersValueMapper';

export const sortOrdersValueMapper: ISortOrdersValueMapper = {
  1: 'Ascending',
  2: 'Descending',
  3: 'Ascending',
  4: 'Descending',
  5: 'Ascending',
  6: 'Descending',
  7: 'Ascending',
  8: 'Descending',
};
