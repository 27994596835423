import { toastr } from 'react-redux-toastr';
import { AxiosResponse } from 'axios';
import { IUpdateProductExclusionsParams, updateProductExclusions } from '../api/productsApi';

export const UPDATE_PRODUCT_EXCLUSIONS_REQUEST = 'UPDATE_PRODUCT_EXCLUSIONS_REQUEST';
export type UPDATE_PRODUCT_EXCLUSIONS_REQUEST = typeof UPDATE_PRODUCT_EXCLUSIONS_REQUEST;
export const UPDATE_PRODUCT_EXCLUSIONS_RESPONSE = 'UPDATE_PRODUCT_EXCLUSIONS_RESPONSE';
export type UPDATE_PRODUCT_EXCLUSIONS_RESPONSE = typeof UPDATE_PRODUCT_EXCLUSIONS_RESPONSE;
export const UPDATE_PRODUCT_EXCLUSIONS_ERROR = 'UPDATE_PRODUCT_EXCLUSIONS_ERROR';
export type UPDATE_PRODUCT_EXCLUSIONS_ERROR = typeof UPDATE_PRODUCT_EXCLUSIONS_ERROR;

export const updateProductExclusionsRequest = (parameters: IUpdateProductExclusionsParams, onSuccess: () => void): any => {
  return (dispatch : any) => {
    dispatch({
      type: UPDATE_PRODUCT_EXCLUSIONS_REQUEST,
    });

    return updateProductExclusions(parameters)
      .then((response: AxiosResponse<IUpdateProductExclusionsParams>) => {
        if (response.status === 200) {
          toastr.success('Product Exclusions Saved', 'Product exclusions have been saved successfully.');
          dispatch({
            type: UPDATE_PRODUCT_EXCLUSIONS_RESPONSE,
          });
          onSuccess();
        } else {
          onError(dispatch);
        }
      })
      .catch((err: any) => {
        console.log(err);
        onError(dispatch);
      });
  };
};

const onError = (dispatch : any) => {
  dispatch({
    type: UPDATE_PRODUCT_EXCLUSIONS_ERROR,
  });
  toastr.error('Error', 'An error has occurred whilst saving product exclusions. Please try again.');
};
