import { Reducer } from 'redux';
import {
  GET_RETAILERS_ERROR,
  GET_RETAILERS_REQUEST,
  GET_RETAILERS_RESPONSE } from '../actions/retailersAction';
import { IRetailer, IApiRequest } from '../types';

const initialState: IApiRequest<IRetailer[]> = {
  data: [],
  error: '',
  UpdatedAt: null,
  loading: false,
};

const retailersReducer: Reducer<IApiRequest<IRetailer[]>>
= (state: IApiRequest<IRetailer[]> = initialState, action : any) => {
  switch (action.type) {
    case GET_RETAILERS_RESPONSE: {
      return {
        data: action.response,
        error: '',
        UpdatedAt: new Date(),
        loading: false,
      };
    }
    case GET_RETAILERS_REQUEST: {
      return {
        data: [],
        error: '',
        UpdatedAt: null,
        loading: true,
      };
    }
    case GET_RETAILERS_ERROR:
    default: {
      return state;
    }
  }
};

export default retailersReducer;
