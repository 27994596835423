import { IUpdateOrderStatusParams, updateOrderStatus } from '../../api/Orders/updateOrderStatusApi';
import { Dispatch } from 'redux';

export const UPDATE_ORDER_STATUS_REQUEST = 'UPDATE_ORDER_STATUS_REQUEST';
export type UPDATE_ORDER_STATUS_REQUEST = typeof UPDATE_ORDER_STATUS_REQUEST;

export const UPDATE_ORDER_STATUS_RESPONSE = 'UPDATE_ORDER_STATUS_RESPONSE';
export type UPDATE_ORDER_STATUS_RESPONSE = typeof UPDATE_ORDER_STATUS_RESPONSE;

export const UPDATE_ORDER_STATUS_ERROR = 'UPDATE_ORDER_STATUS_ERROR';
export type UPDATE_ORDER_STATUS_ERROR = typeof UPDATE_ORDER_STATUS_ERROR;

export const updateOrderStatusRequest = (params: IUpdateOrderStatusParams,
): (dispatch: Dispatch) => Promise<void> => {
  return async (dispatch: Dispatch): Promise<void> => {
    try {
      dispatch({
        type: UPDATE_ORDER_STATUS_REQUEST,
      });

      const response = await updateOrderStatus(params);

      if (response.status === 200) {
        dispatch({
          type: 'SET_CURRENT_BASKET',
          response: response.data.basket,
        });

        dispatch({
          type: UPDATE_ORDER_STATUS_RESPONSE,
        });
      } else {
        onError(dispatch, response.data as unknown as string);
      }
    } catch (error) {
      console.log(error);
      onError(dispatch);
    }
  };
};

const onError = (dispatch: Dispatch, message: string = null): any => dispatch({
  type: UPDATE_ORDER_STATUS_ERROR,
  response: message,
});
