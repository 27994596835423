import { padLeft } from './padLeft';

export const formatDateString24hr = (value: string) : string => {
  if (value === null) {
    return '-';
  }

  const date = new Date(value);

  const day = padLeft(date.getDate());
  const month = padLeft(date.getMonth() + 1);
  const year = date.getFullYear();
  const minute = padLeft(date.getMinutes());

  const hour = date.getHours();

  return `${day}/${month}/${year} ${padLeft(hour)}:${minute}`;
};
