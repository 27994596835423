import { Reducer } from 'redux';
import {
  GET_MY_ACCOUNT_ERROR,
  GET_MY_ACCOUNT_REQUEST,
  GET_MY_ACCOUNT_RESPONSE } from '../../actions/MyAccount/myAccountAction';
import { IMyAccount, IApiRequest } from '../../types';

const initialState: IApiRequest<IMyAccount> = {
  loading: false,
  UpdatedAt: null,
  data: null,
  error: '',
};

const myAccountReducer: Reducer<IApiRequest<IMyAccount>> = (
  state: IApiRequest<IMyAccount> = initialState,
  action : any,
  ) => {
  switch (action.type) {
    case GET_MY_ACCOUNT_REQUEST: {
      return {
        ...initialState,
        loading: true,
        UpdatedAt: new Date().toISOString(),
      };
    }
    case GET_MY_ACCOUNT_RESPONSE: {
      return {
        ...initialState,
        UpdatedAt: new Date().toISOString(),
        data: {
          userDetails: action.response.userDetails,
        },
      };
    }
    case GET_MY_ACCOUNT_ERROR: {
      return {
        ...initialState,
        UpdatedAt: new Date().toISOString(),
        error: action.error || 'There was an error loading this user.',
      };
    }
    default: {
      return state;
    }
  }
};

export default myAccountReducer;
