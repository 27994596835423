export const MAX_FILE_UPLOAD_SIZE = 100000000; // Equivalent to 100 megabytes.

export const getFileIcon = (mimeType: string) => {
  switch (mimeType) {
    case 'image/svg+xml':
    case 'image/png':
    case 'image/jpeg':
    case 'image/tiff': {
      return { className: 'icon-image-file' };
    }
    case 'application/pdf': {
      return { className: 'icon-pdf-file' };
    }
    case 'text/plain':
    case 'text/csv':
    case 'application/rtf': {
      return { className: 'icon-text-file' };
    }
    case 'application/zip': {
      return { className: 'icon-zip-file' };
    }
    case 'application/msword': {
      return { className: 'icon-word-file' };
    }
    case 'application/vnd.openxmlformats-officedocument.presentationml.presentation': {
      return { className: 'icon-presentation-file' };
    }
    case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': {
      return { className: 'icon-excel-file' };
    }
    case 'audio/mpeg':
    case 'audio/wav': {
      return { className: 'icon-audio-file' };
    }
    case 'video/avi':
    case 'video/quicktime':
    case 'video/mp4':
    case 'video/x-ms-wmv':
    case 'video/webm': {
      return { className: 'icon-video-file' };
    }
    default: {
      return { className: 'icon-empty-file' };
    }
  }
};
