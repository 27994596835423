import { Dispatch } from 'redux';
import { IGetOrderByOrderNumberParams, getOrderByOrderNumber } from '../../api/Orders/orderLookupApi';

export const GET_ORDER_BY_ORDERNUMBER_REQUEST = 'GET_ORDER_BY_ORDERNUMBER_REQUEST';
export const GET_ORDER_BY_ORDERNUMBER_RESPONSE = 'GET_ORDER_BY_ORDERNUMBER_RESPONSE';
export const GET_ORDER_BY_ORDERNUMBER_ERROR = 'GET_ORDER_BY_ORDERNUMBER_ERROR';

export const getOrderByOrderNumberRequest = (params: IGetOrderByOrderNumberParams): (dispatch: Dispatch) => Promise<any> => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: GET_ORDER_BY_ORDERNUMBER_REQUEST,
    });

    try {
      const response = await getOrderByOrderNumber(params);
      if (response.status === 200) {
        const { order } = response.data;
        dispatch({
          type: GET_ORDER_BY_ORDERNUMBER_RESPONSE,
          response: order,
        });

      } else {
        onGetOrderByOrderNumberRequestError(dispatch);
      }
    } catch (error) {
      console.log(error);
      onGetOrderByOrderNumberRequestError(dispatch);
    }
  };
};

const onGetOrderByOrderNumberRequestError = (dispatch: Dispatch) => dispatch({
  type: GET_ORDER_BY_ORDERNUMBER_ERROR,
});
