import React, { Dispatch } from 'react';
import { Button } from '@orderly/morrisons-component-library';
import { IStoreState, IBasketItem } from '../../types';
import { updateBasketRequest } from '../../actions/Basket/updateBasketAction';
import { connect } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import {
  viewCategorySlots,
  addToBasketText,
  requireNewSlot,
  addToBasketActive,
  allowAddToBasket,
} from '../../helpers/Basket';
import QuantityCounter from '../QuantityCounter/QuantityCounter';
import { IBasketOptionsProps } from './IBasketOptionsProps';

const BasketOptions = ({
  itemId,
  basket,
  addToBasket,
  category,
  status,
  available,
  showLabels,
  maxOrderQuantity,
}: IBasketOptionsProps): JSX.Element => {
  const getBasketItem = (itemId: number): IBasketItem => {
    if (basket.data && basket.data.items && basket.data.items.length > 0) {
      const item = basket.data.items.find(product => product.itemId === itemId);
      if (item) {
        return item;
      }
    }
    return null;
  };

  const onQuantityChange = (item: IBasketItem, quantity: number) => {
    if (item.quantity !== quantity) {
      handleButtonOnClick(item.itemId, quantity);
    }
  };

  const handleButtonOnClick = (item: number, quantity: number) => {
    if (requireNewSlot(basket)) {
      viewCategorySlots(category);
    } else if (!basket.loading) {
      toastr.success(
        '',
        quantity === 1 ? 'Item successfully added to the basket.' : 'Item successfully updated in your basket.');

      addToBasket(item, quantity);
    }
  };

  const item = getBasketItem(itemId);
  if (requireNewSlot(basket) || !allowAddToBasket(basket, category, status, available) || item === null) {
    return (
      <Button
        type="button"
        className={'add-to-basket tertiary'}
        text={addToBasketText(basket, category, status, itemId, available)}
        disabled={addToBasketActive(basket, category, status, available)}
        onClick={
          (): void => {
            handleButtonOnClick(itemId, item === null ? 1 : 0);
          }
        }
      />
    );
  }

  return (
    <React.Fragment>
      {
        showLabels ? <span>
          <div className="quantity">
            <span>Quantity</span>
            <QuantityCounter
              item={item}
              onQuantityChange={(item, quantity) => onQuantityChange(item, quantity)}
              maxOrderQuantity={maxOrderQuantity}
            />
          </div>
        </span> : <QuantityCounter
          item={item}
          onQuantityChange={(item, quantity) => onQuantityChange(item, quantity)}
          maxOrderQuantity={maxOrderQuantity}
        />
      }
    </React.Fragment>
  );
};

const mapStateToProps = (state: IStoreState) => {
  return {
    basket: state.basket,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<any>) => {
  return {
    addToBasket: (itemId: number, quantity: number) => dispatch(updateBasketRequest(itemId, quantity)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(BasketOptions);
