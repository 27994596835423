export const formatUTCDateShort = (value: Date) : string => {
  if (value === null) {
    return '-';
  }

  const day = value.getDate();
  const weekDay = value.toLocaleString('default', { weekday: 'short' });
  const month = value.toLocaleString('default', { month: 'short' });
  const year = value.getFullYear();

  return `${weekDay} ${day} ${month} ${year}`;
};
