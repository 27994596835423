import React from 'react';
import './SectionHeading.scss';

interface ISectionHeadingProps {
  children: React.ReactNode;
  className?: string;
}

const SectionHeading : React.SFC<ISectionHeadingProps> = ({ children, className = '' }) => (
  <h2 className={`section-heading ${className}`}>{children}</h2>
);

export default SectionHeading;
