import { Action } from 'redux';
import { IDocumentBlob } from '../../types';
import {
  IDownloadDocumentBlobParams,
  downloadDocumentBlob,
  IDownloadDocumentBlobResponse,
} from '../../api/Documents/downloadDocumentBlobApi';
import { AxiosResponse } from 'axios';

export const GET_DOCUMENT_BLOB_REQUEST = 'GET_DOCUMENT_BLOB_REQUEST';
export type GET_DOCUMENT_BLOB_REQUEST = typeof GET_DOCUMENT_BLOB_REQUEST;

export const GET_DOCUMENT_BLOB_RESPONSE = 'GET_DOCUMENT_BLOB_RESPONSE';
export type GET_DOCUMENT_BLOB_RESPONSE = typeof GET_DOCUMENT_BLOB_RESPONSE;

export const GET_DOCUMENT_BLOB_ERROR = 'GET_DOCUMENT_BLOB_ERROR';
export type GET_DOCUMENT_BLOB_ERROR = typeof GET_DOCUMENT_BLOB_ERROR;

export interface IDownloadDocumentBlobResponse extends Action {
  type: GET_DOCUMENT_BLOB_RESPONSE;
  response: IDocumentBlob;
}

export interface IDownloadDocumentBlobError extends Action {
  type: GET_DOCUMENT_BLOB_ERROR;
  error?: string;
}

export const downloadDocumentBlobRequest = (
  parameters: IDownloadDocumentBlobParams,
  onSuccess: (data: IDocumentBlob) => void,
  onFailure: () => void): any => {
  return (dispatch: any) => {

    dispatch({
      type: GET_DOCUMENT_BLOB_REQUEST,
    });

    return downloadDocumentBlob(parameters)
      .then((response: AxiosResponse<IDownloadDocumentBlobResponse>) => {
        if (response.status === 200) {
          dispatch({
            type: GET_DOCUMENT_BLOB_RESPONSE,
            response: response.data.document,
          });
          onSuccess(response.data.document);
        } else {
          onError(dispatch);
          onFailure();
        }
      })
      .catch((err: any) => {
        console.log(err);
        onError(dispatch);
        onFailure();
      });
  };
};

const onError = (dispatch: any) => dispatch({
  type: GET_DOCUMENT_BLOB_ERROR,
});
