import { isPrintshopPage } from './IsPrintshopPage';
import QueryString from 'query-string';

export const constructNavigationLink = (match: any, customRedirect?: string) => {
  let baseUrl = '';
  const { retailerId } = match.params;

  if (isPrintshopPage()) {
    baseUrl = '/admin/printshop';
    const parentFolder = QueryString.parse(window.location.search).parent;
    const folderPath = getFolderPath();
    if (parentFolder) {
      baseUrl = `${baseUrl}?folder=${parentFolder}`;
    } else if (folderPath) {
      baseUrl = `${baseUrl}?folder=${customRedirect ? folderPath.replace(getFolderName(), customRedirect) : folderPath}`;
    }
  } else if (retailerId === '-1') {
    baseUrl = '/admin/customer-groups';
  } else {
    baseUrl = `/admin/documents/${retailerId}`;
  }

  return baseUrl;
};

const getFolderName = () => {
  const folderName = QueryString.parse(window.location.search).name as string;
  return folderName.substring(folderName.lastIndexOf('/') + 1);
};

const getFolderPath = () => {
  return QueryString.parse(window.location.search).name as string;
};
