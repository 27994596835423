import React, { Dispatch } from 'react';
import './PasswordResetConfirmation.scss';
import { connect } from 'react-redux';
import * as siteWrapperAction from '../../actions/siteWrapperAction';
import * as forgotPasswordConfirmationAction from '../../actions/forgotPasswordConfirmationAction';
import { TextInput, Button, Card } from '@orderly/morrisons-component-library';
import { NavLink } from 'react-router-dom';
import logo from '../../assets/images/login_logo.png';
import { IConfirmForgotPasswordParams } from '../../api/userApi';
import QueryString from 'query-string';
import ReCAPTCHA from '../../components/ReCAPTCHA/ReCAPTCHA';
import { IStoreState, IForgotPasswordConfirmation } from '../../types';
import Settings from '../../settings';

interface IPasswordResetConfirmationState {
  password: string;
  confirmPassword: string;
  errorMessage: any;
  recaptchaError: any;
  loading: boolean;
  ReCAPTCHA: string;
}

class PasswordResetConfirmation extends React.Component<any, IPasswordResetConfirmationState> {
  public state: IPasswordResetConfirmationState = {
    password: '',
    confirmPassword: '',
    errorMessage: '',
    loading: false,
    ReCAPTCHA: '',
    recaptchaError: '',
  };

  public componentDidMount(): void {
    this.props.updateSiteWrapperVisibility(false);
  }

  public componentWillUnmount(): void {
    this.props.updateSiteWrapperVisibility(true);
  }

  public render(): JSX.Element {
    const { password, errorMessage, confirmPassword, recaptchaError } = this.state;
    const { loading, error } = this.props;

    return (
      <div className="confirm-password-reset-page">
        <div className="confirm-password-reset-underlay" />
        <div className="container content-wrapper">
          <div className="logo-wrapper">
            <NavLink className="logo" exact to={'/'}>
              <img src={logo} alt="site logo" className="img-responsive" />
            </NavLink>
          </div>
          <Card>
            <h1>Reset your password</h1>
            <form noValidate onSubmit={this.onSubmit}>
              <TextInput
                type="password"
                label="New Password"
                name="password"
                value={password}
                error={error || errorMessage}
                onChange={(val: any) => this.setState({ password: val })}
              />
                <TextInput
                  type="password"
                  label="Confirm Password"
                  name="confirm-password"
                  value={confirmPassword}
                  error={''}
                  onChange={(val: any) => this.setState({ confirmPassword: val })}
                />
              {
                recaptchaError &&
                <span className="recaptcha-error-text">
                  {recaptchaError}
                </span>
              }
              <ReCAPTCHA
                onChange={(value: string) => this.setState({ ReCAPTCHA: value })}
              />
              <Button
                disabled={loading}
                type="submit"
                text="Reset password"
                className="secondary confirm-password-reset-btn"
              />
            </form>
          </Card>
        </div>
      </div>
    );
  }

  public isFormValid = (): boolean => {
    const { password, confirmPassword, ReCAPTCHA } = this.state;
    let errorCount = 0;
    let errorMessage = '';
    let recaptchaError = '';

    if (!password || password.length <= 0) {
      errorMessage = 'Password is required';
      errorCount += 1;
    } else if (password !== confirmPassword) {
      errorMessage = 'Passwords must match';
      errorCount += 1;
    }

    if (Settings().RecaptchaEnabled && (ReCAPTCHA === null || ReCAPTCHA.length === 0)) {
      recaptchaError = 'reCAPTCHA is required';
      errorCount += 1;
    }

    this.setState({ errorMessage, recaptchaError });
    return errorCount <= 0;
  }

  private onSubmit = (e: React.SyntheticEvent): void => {
    e.preventDefault();
    if (!this.isFormValid()) {
      return;
    }

    const queryString = QueryString.parse(window.location.search);
    const { password, ReCAPTCHA } = this.state;

    const parameters : IConfirmForgotPasswordParams = {
      NewPassword: password,
      ResetToken: queryString['token'] as string,
      Recaptcha: ReCAPTCHA,
    };

    this.props.confirmForgotPassword(parameters, () => this.props.history.push('/'));
  }

  private handlePasswordChange = (value: string) : void => {
    this.setState({ password: value });
  }

  private handleConfirmPasswordChange = (value: string) : void => {
    this.setState({ confirmPassword: value });
  }
}

const mapStateToProps = (state: IStoreState) : IForgotPasswordConfirmation => {
  return {
    error: state.forgotPasswordConfirmation.error,
    loading: state.forgotPasswordConfirmation.loading,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<
                              siteWrapperAction.IUpdateSiteWrapperVisibility
                              | forgotPasswordConfirmationAction.IConfirmForgotPasswordRequest>) => {
  return {
    updateSiteWrapperVisibility: (status: any) => dispatch(siteWrapperAction.updateSiteWrapperVisibility(status)),
    confirmForgotPassword: (params: IConfirmForgotPasswordParams, onSuccess: () => void) =>
                              dispatch(forgotPasswordConfirmationAction.ConfirmForgotPasswordRequest(params, onSuccess)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PasswordResetConfirmation);
