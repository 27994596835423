import React, { Dispatch } from 'react';
import './Product.scss';
import '../../index.scss';
import { Breadcrumbs, Button } from '@orderly/morrisons-component-library';
import {
  IStoreState,
  IProductDetail,
  IApiRequest,
  ITopOffers,
  IBasketItem,
} from '../../types';
import { IGetProductParams, IGetTopOffersParams } from '../../api/productsApi';
import { getProductRequest } from '../../actions/productAction';
import { getTopOffersRequest } from '../../actions/topOffersAction';
import { connect } from 'react-redux';
import {
  allowAddToBasket,
  addToBasketText,
  requireNewSlot,
  addToBasketActive,
  viewCategorySlots,
} from '../../helpers/Basket';
import ProductDetails from './ProductDetails/ProductDetails';
import LoadingThrobber from '../../components/LoadingThrobber/LoadingThrobber';
import ProductCarousel from '../../components/ProductCarousel/ProductCarousel';
import { navigateTo } from '../../routing/Navigation';
import SearchError from '../../components/SearchError/SearchError';
import { mapCategory } from '../../helpers/Category/mapCategory';
import { updateBasketRequest } from '../../actions/Basket/updateBasketAction';
import { toastr } from 'react-redux-toastr';
import BasketOptions from '../../components/BasketOptions/BasketOptions';
import { getPriceByType, PriceType, PriceSelectionType, ViewMode } from '../../helpers/Product/ProductPriceHelper';
import getRetailerDetailsRequest from '../../actions/retailerDetailAction';
import ShoppingListButton from '../../components/ShoppingListButton';
import ProductPromotionalPrice from '../../components/ProductPromotionalPrice';

interface IProductState {
  quantity: number;
  youAlsoMightLike: ITopOffers[];
  maxOrderQuantity: number;
}

class Product extends React.Component<any, IProductState> {
  public state: IProductState = {
    quantity: 0,
    youAlsoMightLike: [],
    maxOrderQuantity: undefined,
  };

  public componentDidMount(): void {
    this.loadProduct();

    if (this.props.basket.data) {
      const basketItem = this.props.basket.data.items && this.props.basket.data.items.find(
        (x: IBasketItem) => x.itemId === parseInt(this.props.match.params.id, 10));
      if (basketItem) {
        this.setState({ quantity: basketItem.quantity });
      }
    }

    this.props.getRetailerDetails();
  }

  public componentDidUpdate(prevProps: any): void {
    if (this.props.match.params.id !== prevProps.match.params.id) {
      this.loadProduct();
    }

    this.populateYouAlsoMightLike();

    if (this.props.basket.data && !prevProps.basket.data) {
      const basketItem = this.props.basket.data.items && this.props.basket.data.items.find(
        (x: IBasketItem) => x.itemId === parseInt(this.props.match.params.id, 10));

      if (basketItem) {
        this.setState({ quantity: basketItem.quantity });
      }
    }

    if (!this.state.maxOrderQuantity && this.props.retailerDetails && this.props.retailerDetails.data) {
      const maxOrderQuantity = this.props.retailerDetails.data.meta.MaxOrderQuantity;
      if (maxOrderQuantity) {
        this.setState({
          maxOrderQuantity: parseInt(maxOrderQuantity, 10),
        });
      }
    }
  }

  public render(): JSX.Element {
    const { products, match, basket } = this.props;
    const { quantity } = this.state;
    const product = products[match.params.id] as IApiRequest<IProductDetail>;

    if (product === undefined || product.loading) {
      return <div className="product-container loading-container"><LoadingThrobber /></div>;
    }

    if (product.error) {
      return (
        <SearchError>
          There was an error loading this product.
          <br />
          <span className="mainText">Please try again.</span>
        </SearchError>
      );
    }

    return (
      <div className="product-container">
        <Breadcrumbs
          onHomeClick={() => navigateTo('/home')}
          onRedirect={navigateTo}
          segments={[
            {
              key: 0,
              text: mapCategory(product.data.category.description),
              url: `/catalogue?category=${product.data.category.id}`,
            },
            {
              key: 1,
              text: product.data.subCategory.description,
              url: `/catalogue?category=${product.data.category.id}&subcategory=${product.data.subCategory.id}`,
            },
          ]}
        />
        <div className="product-main">
          <div className="background" />
          <section className="product-information card">
            <h1>{product.data.description}</h1>
            <h4>Product ID: <b className="item-id">{product.data.itemId}</b></h4>
            {product.data.category &&
              <div className="category-tag">{product.data.category.description}</div>
            }
            <div>
              <img
                src={
                  product.data.images.length === 0 ? require('../../assets/images/404.jpg') : product.data.images[0].url
                }
                alt={product.data.images.length === 0 ? '' : product.data.images[0].description}
              />
            </div>
            <hr />
            <div className="information">
              {
                product.data.activeTradingPromotion ?
                  <ProductPromotionalPrice
                    product={product.data}
                    isListView={false}
                    viewMode={ViewMode.Price}
                  /> :
                  <div className="pricing">
                    <strong className="price">
                      {getPriceByType(product.data,
                        product.data.singlePick ? PriceType.UnitPrice : PriceType.CasePrice,
                        PriceSelectionType.NormalPrice)}
                    </strong>
                    {product.data.singlePick ?
                      <span className="case-size">(single)</span>
                      : <span className="case-size">({product.data.caseSize} pack)</span>}
                  </div>
              }
              <span className="description">
                {product.data.description}
              </span>
              <div className="info-wrapper">
                <div className="availability">
                  <b className="header">Availability</b>
                  <span className="status">{product.data.status}</span>
                </div>
                <div className="workflow">
                  <b className="header">Workflow</b>
                  <span className="status">None</span>
                </div>
                {product.data.category &&
                  <div className="category">
                    <b className="header">Category</b>
                    <span className="status">{product.data.category.description}</span>
                  </div>
                }
              </div>
            </div>
          </section>
          <div className="product-basket card">
            {
              product.data.activeTradingPromotion ?
                <ProductPromotionalPrice
                  product={product.data}
                  isListView={false}
                  viewMode={ViewMode.Price}
                /> :
                <div className="pricing">
                  <strong className="price">
                    {getPriceByType(product.data,
                      product.data.singlePick ? PriceType.UnitPrice : PriceType.CasePrice,
                      PriceSelectionType.NormalPrice)}
                  </strong>
                  {
                    product.data.singlePick ?
                      <span className="case-size padding">(single)</span>
                      :
                      <span className="case-size padding">({product.data.caseSize} pack)</span>
                  }
                </div>
            }
            <form onSubmit={this.submitProduct}>
              <div className="add-to-basket-wrapper">
                <BasketOptions
                  itemId={product.data.itemId}
                  category={product.data.category.description}
                  status={product.data.status}
                  available={product.data.available}
                  showLabels={true}
                  maxOrderQuantity={this.state.maxOrderQuantity}
                />
                <ShoppingListButton product={product.data} />
              </div>
              <hr />
              <div className="information">
                <span className="description">
                  {product.data.description}
                </span>
                <div className="info-wrapper">
                  <div className="availability">
                    <b className="header">Availability</b>
                    <span className="status">{product.data.status}</span>
                  </div>
                  <div className="workflow">
                    <b className="header">Workflow</b>
                    <span className="status">None</span>
                  </div>
                  {product.data.category &&
                    <div className="category">
                      <b className="header">Category</b>
                      <span className="status">{product.data.category.description}</span>
                    </div>
                  }
                </div>
              </div>
            </form>
          </div>
        </div>
        <div className="product-detail">
          <ProductDetails product={product.data} />
        </div>
        <section className="related-products">
          <div className="background" />
          <h2>You also might like</h2>
          {
            (this.state.youAlsoMightLike && this.state.youAlsoMightLike.length > 0) &&
            <ProductCarousel
              products={this.state.youAlsoMightLike}
              maxOrderQuantity={this.state.maxOrderQuantity}
            />
          }
        </section>
        <form onSubmit={this.submitProduct}>
          <div className="mobile-product-basket">
            {
              allowAddToBasket(
                basket,
                product.data.category.description,
                product.data.status,
                product.data.available) &&
              <label className="mobile-quantity">
                Qty
                <input
                  type="number"
                  value={quantity}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.onQuantityChanged(e.target.value)}
                  name="mobile-quantity"
                  max={this.state.maxOrderQuantity || 999}
                />
              </label>
            }
            <Button
              className="tertiary btn-basket"
              disabled={addToBasketActive(
                basket,
                product.data.category.description,
                product.data.status,
                product.data.available)
              }
              text={addToBasketText(
                basket,
                product.data.category.description,
                product.data.status,
                product.data.itemId,
                product.data.available)
              }
              type="submit"
            />
            <ShoppingListButton product={product.data} />
          </div>
        </form>
      </div>
    );
  }

  private loadProduct(): void {
    this.props.getProduct({
      productId: this.props.match.params.id,
      categoryId: -1,
    });
  }

  private onQuantityChanged = (quantity: number | string) => {
    const value: string = quantity.toString();
    if (!parseInt(value, 10)) {
      return;
    }

    this.setState({ quantity: parseInt(value, 10) });
  }

  private submitProduct = (e: React.FormEvent<HTMLFormElement>): void => {
    e.preventDefault();
    const { basket, addToBasket, products, match } = this.props;
    const basketItem = this.props.basket.data && this.props.basket.data.items.find(
      (x: IBasketItem) => x.itemId === parseInt(this.props.match.params.id, 10));

    if (requireNewSlot(basket)) {
      const product = products[this.props.match.params.id];
      if (product !== null) {
        viewCategorySlots(product.data.category.description);
      } else {
        navigateTo('/my-account');
      }
    } else if (basketItem) {
      toastr.success(
        'Item Updated',
        'Item quantity successfully updated.');

      addToBasket(this.props.match.params.id, this.state.quantity + 1);
    } else {
      toastr.success(
        'Item Added',
        'Item successfully added to the basket.');

      addToBasket(this.props.match.params.id, this.state.quantity + 1);
    }
  }

  private populateYouAlsoMightLike(): void {
    const { products, relatedProductArray, match } = this.props;
    const productId: string = match.params.id;
    const productResponse = products[productId] as IApiRequest<IProductDetail>;
    const categoryId: number = productResponse && productResponse.data && productResponse.data.category.id;
    const youAlsoMightLike: ITopOffers[] = relatedProductArray[categoryId] && relatedProductArray[categoryId].data;

    if (categoryId && youAlsoMightLike === undefined) {
      this.props.getTopOffers({ categoryId });
    }

    if (youAlsoMightLike && youAlsoMightLike.length > 0) {
      this.setState((prevState: IProductState): Pick<IProductState, 'youAlsoMightLike'> => {
        if (prevState.youAlsoMightLike.length === 0) {
          return { youAlsoMightLike };
        }
      });
    }
  }
}

const mapStateToProps = (state: IStoreState, ownProps: any) => {
  const basketItem = state.basket && state.basket.data && state.basket.data.items && state.basket.data.items.find(
    (x: IBasketItem) => x.itemId === parseInt(ownProps.match.params.id, 10));
  return {
    basketItem,
    products: state.products,
    relatedProductArray: state.topOffers,
    basket: state.basket,
    retailerDetails: state.retailerDetails,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<any>) => {
  return {
    getProduct: (parameters: IGetProductParams) => dispatch(getProductRequest(parameters)),
    getTopOffers: (parameters: IGetTopOffersParams) => dispatch(getTopOffersRequest(parameters)),
    addToBasket: (itemId: number, quantity: number) => dispatch(updateBasketRequest(itemId, quantity)),
    getRetailerDetails: () => dispatch(getRetailerDetailsRequest()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Product);
