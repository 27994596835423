import { getOrderItems } from '../../api/Orders/orderItemsApi';
import { IOrderItemsResponse } from '../../api/Orders/IOrderItemsResponse';
import { AxiosResponse } from 'axios';
import { Dispatch } from 'redux';

export const orderItemsRequest = (orderId: string, retailerName: string): any => {
  return (dispatch : Dispatch) => {
    dispatch({
      type: 'GET_ORDER_ITEMS_REQUEST',
    });

    return getOrderItems(orderId, retailerName)
      .then((response: AxiosResponse<IOrderItemsResponse>) => {
        if (response.status === 200) {
          dispatch({
            type: 'GET_ORDER_ITEMS_RESPONSE',
            response: response.data.orderitems.items,
          });
        } else {
          onError(dispatch, response.data as unknown as string);
        }
      })
      .catch((err: any) => {
        console.error(err);
        onError(dispatch);
      });
  };
};

const onError = (dispatch: Dispatch, message: string = null) => dispatch({
  type: 'GET_ORDER_ITEMS_ERROR',
  response: message,
});
