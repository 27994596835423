import { Reducer } from 'redux';
import { IApiRequest, IDocument } from '../../types/index';
import {
  EDIT_DOCUMENT_ERROR,
  EDIT_DOCUMENT_REQUEST,
  EDIT_DOCUMENT_RESPONSE,
} from '../../actions/Documents/editDocumentAction';

const initialState: IApiRequest<IDocument> = {
  data: undefined,
  error: '',
  UpdatedAt: null,
  loading: false,
};

const editDocumentReducer: Reducer<IApiRequest<IDocument>>
= (state: IApiRequest<IDocument> = initialState, action : any) => {
  switch (action.type) {
    case EDIT_DOCUMENT_RESPONSE: {
      return {
        data: action.response,
        error: '',
        UpdatedAt: new Date(),
        loading: false,
      };
    }
    case EDIT_DOCUMENT_REQUEST: {
      return {
        data: undefined,
        error: '',
        UpdatedAt: null,
        loading: true,
      };
    }
    case EDIT_DOCUMENT_ERROR:
    default: {
      return state;
    }
  }
};

export default editDocumentReducer;
