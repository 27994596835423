import { AnyAction, Reducer } from 'redux';
import {
  IMPORT_RETAILER_PRODUCT_PRICING_REQUEST,
  IMPORT_RETAILER_PRODUCT_PRICING_ERROR,
  IMPORT_RETAILER_PRODUCT_PRICING_RESPONSE,
} from '../../actions/Products';
import { IApiRequest } from '../../types';

const initialState: IApiRequest<string[]> = {
  data: [],
  error: '',
  UpdatedAt: null,
  loading: false,
};

const importRetailerProductPricingReducer: Reducer<IApiRequest<string[]>> = (
  state: IApiRequest<string[]> = initialState,
  action: AnyAction,
) => {
  switch (action.type) {
    case IMPORT_RETAILER_PRODUCT_PRICING_REQUEST: {
      return {
        ...initialState,
        loading: true,
      };
    }
    case IMPORT_RETAILER_PRODUCT_PRICING_RESPONSE: {
      return {
        data: action.response,
        error: '',
        UpdatedAt: new Date(),
        loading: false,
      };
    }
    case IMPORT_RETAILER_PRODUCT_PRICING_ERROR: {
      return {
        ...initialState,
        error: 'An error has occured',
      };
    }
    default: {
      return state;
    }
  }
};

export default importRetailerProductPricingReducer;
