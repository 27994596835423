import { toastr } from 'react-redux-toastr';
import { AxiosResponse } from 'axios';
import { IProcessedBulkOrdersResponse, getProcessedBulkOrdersReport } from '../../api/Reports/ReportsApi';

export const GET_PROCESSED_BULK_ORDERS_REPORT_REQUEST = 'GET_PROCESSED_BULK_ORDERS_REPORT_REQUEST';
export const GET_PROCESSED_BULK_ORDERS_REPORT_RESPONSE = 'GET_PROCESSED_BULK_ORDERS_REPORT_RESPONSE';
export const GET_PROCESSED_BULK_ORDERS_REPORT_ERROR = 'GET_PROCESSED_BULK_ORDERS_REPORT_ERROR';

export const getProcessedBulkOrdersReportRequest = (onSuccess: (data: any) => void): any => {
  return (dispatch: any) => {
    dispatch({
      type: GET_PROCESSED_BULK_ORDERS_REPORT_REQUEST,
    });

    return getProcessedBulkOrdersReport()
      .then((response: AxiosResponse<IProcessedBulkOrdersResponse>) => {
        if (response.status === 200) {
          dispatch({
            type: GET_PROCESSED_BULK_ORDERS_REPORT_RESPONSE,
          });
          toastr.success('Report Generated', 'The bulk manifest has been exported successfully.');
          onSuccess(response.data);
        } else {
          onError(dispatch);
        }
      })
      .catch((err: any) => {
        console.log(err);
        onError(dispatch);
      });
  };
};

const onError = (dispatch: any) => {
  dispatch({
    type: GET_PROCESSED_BULK_ORDERS_REPORT_ERROR,
  });
  toastr.error('Error', 'An error has occurred whilst exporting the bulk manifest. Please try again.');
};
