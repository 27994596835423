import React, { Dispatch, useEffect, useState, useCallback } from 'react';
import './ManageUsers.scss';
import { Breadcrumbs, Button, Card, Checkbox, Dropdown, TextInput } from '@orderly/morrisons-component-library';
import { navigateTo } from '../../../routing/Navigation';
import Table, { isHeaderSorted } from '../../../components/Table/Table';
import { IRetailer, IStoreState, IUserDetails } from '../../../types';
import { connect } from 'react-redux';
import { IGetUsersParams, IRemoveUserParams } from '../../../api/adminUserApi';
import { getUserPageRequest } from '../../../actions/usersAction';
import { removeUserRequest } from '../../../actions/removeUsersAction';
import LoadingThrobber from '../../../components/LoadingThrobber/LoadingThrobber';
import { formatDateString24hr } from '../../../helpers/Format';
import { Claims } from '../../../helpers/Users/UserHelper';
import { PageMode } from './enums/ManageUsers.enum';
import { getRetailersRequest } from '../../../actions/retailersAction';
import { impersonateUserRequest } from '../../../actions/UserManangement/impersonateUserAction';
import { IManageUsersProps } from './IManageUsersProps';
import Popup from '../../../components/popup/Popup';
import { IPopupPropsConfig } from '../../../components/popup/interfaces/IPopupPropsConfig';
import { IPopupPropsConfigContentAction } from '../../../components/popup/interfaces/IPopupPropsConfigContentAction';
import queryString from 'query-string';
import InfiniteScroll from 'react-infinite-scroller';

const PAGE_SIZE = 25;

const ManageUsers = (
  {
    data,
    getUsers,
    removeUser,
    removeUserDetails,
    retailers,
    getRetailers,
    impersonateUser,
    impersonation,
  }: IManageUsersProps) => {

  useEffect(
    () => {
      getRetailers();
    },
    []);

  const pageMode = window.location.pathname.indexOf('admin-users') === -1 ? PageMode.User : PageMode.Admin;

  const retailersQuery = queryString.parse(window.location.search).retailer;
  let retailerIds: number[] = [];
  if (retailersQuery) {
    if (retailersQuery instanceof Array) {
      retailerIds = retailersQuery.map(retailer => parseInt(retailer, 10));
    } else {
      retailerIds = [parseInt(retailersQuery, 10)];
    }
  }

  const [search, setSearch] = useState('');
  const [pagingIndex, setPagingIndex] = useState(0);
  const [selectedRetailers, setSelectedRetailers] = useState(retailerIds);
  const [orderBy, setOrderBy] = useState('email');
  const [orderDescending, setOrderDescending] = useState(false);
  const [openPopup, setOpenPopup] = useState(false);
  const [clickedUserId, setClickedUserId] = useState(NaN);
  const [popupTargetElement, setPopupTargetElement] = useState(null);

  useEffect(
    () => {
      const path = pageMode === PageMode.Admin ? '/admin/admin-users' : '/admin/users';
      if (selectedRetailers.length > 0) {
        navigateTo(`${path}?retailer=${selectedRetailers.join('&retailer=')}`);
      } else if (window.location.search !==  '') {
        navigateTo(path);
      }
    },
    [selectedRetailers],
  );

  const loadUsers = () =>
    getUsers({
      orderBy,
      orderDescending,
      search,
      pageSize: PAGE_SIZE,
      startIndex: pagingIndex,
      roles:
        pageMode === PageMode.Admin
          ? [
            Claims.Admin,
            Claims['Account Manager'],
            Claims['Credit Control'],
            Claims['Supply Team'],
            Claims['Logistics Team'],
            Claims['Finance Team'],
            Claims['Accounts Receivable'],
          ]
          : [Claims.User],
      retailers: selectedRetailers,
    });

  const retailerClicked = (value: string, checked: boolean): void => {
    const parsedValue = parseInt(value, 10);
    if (checked) {
      setSelectedRetailers([
        ...selectedRetailers,
        parsedValue,
      ]);
    } else {
      selectedRetailers.splice(selectedRetailers.indexOf(parsedValue), 1);
      setSelectedRetailers([
        ...selectedRetailers,
      ]);
    }

    setPagingIndex(0);
  };

  const onOrderChanged =
    (option: any) => {
      const value: string = option.value;
      const seperator: number = value.indexOf('-');

      setOrderBy(value.substring(0, seperator));
      setOrderDescending(value.substring(seperator + 1) === 'dsc');
      setPagingIndex(0);
    };

  const loadMore = () => setPagingIndex(pagingIndex + PAGE_SIZE);

  useEffect(
    () => {
      loadUsers();
    },
    [search, pagingIndex, selectedRetailers, orderBy, orderDescending]);

  const confirmRemoveUser = (userId: number): void => {
    const message = pageMode === PageMode.Admin
      ? 'Are you sure you want to permanently remove this staff? This is an irreversible operation.'
      : 'Are you sure you want to permanently remove this user? This is an irreversible operation.';

    if (window.confirm(message)) {
      removeUser({ userId });
    }
  };

  const handleNavigation = (url?: string): (url?: string) => void => {
    return (): void => {
      navigateTo(url);
    };
  };

  useEffect(
    () => {
      if (removeUserDetails === undefined || removeUserDetails.userId === -1) return;

      // reload users once response has been received from server
      if (pagingIndex === 0) {
        loadUsers();
      } else {
        setPagingIndex(0);
      }
    },
    [removeUserDetails]);

  const triggerImpersonateUser = (userId: number) => {
    impersonateUser(userId, () => navigateTo('/home'));
  };

  const setPopupConfig = (user: IUserDetails): IPopupPropsConfig => {
    const actions: IPopupPropsConfigContentAction[] = [
      {
        onClick: (): void => {
          navigateTo(
            `${pageMode === PageMode.Admin ? 'admin-users' : 'users'}/update/${user.userId}`,
            { user },
          );
        },
        label: {
          icon: '',
          text: 'Edit',
        },
      },
      {
        onClick: (): void => {
          triggerImpersonateUser(user.userId);
        },
        label: {
          icon: '',
          text: 'Impersonate',
        },
      },
      {
        onClick: (): void => {
          confirmRemoveUser(user.userId);
        },
        label: {
          icon: '',
          text: 'Delete',
        },
      },
    ];

    if (pageMode === PageMode.Admin) {
      actions.splice(1, 1);
    }

    return {
      content: {
        actions,
      },
    };
  };

  const showPopup = (id: number): boolean => {
    const popupInstance: any = document.getElementById(id.toString());

    if (popupInstance !== null) {
      setClickedUserId(NaN);

      return false;
    }
    return (id === clickedUserId);
  };

  const setPopupUniqueKey = (id: number): number => {
    return id;
  };

  const closePopup = (e: Element | HTMLElement): void => {
    setPopupTargetElement(e);
    setClickedUserId(clickedUserId);
    setOpenPopup(!openPopup);
  };

  const generateRetailerColumn: (userRetailerId: number) => JSX.Element = useCallback(
    (userRetailerId: number): JSX.Element => {
      const filtered: any = retailers && retailers.data.filter(x => x.id === userRetailerId);
      const retailerName: string = filtered.length ? filtered[0].externalIdentifier : '-';

      return (
        <td>
          {retailerName}
        </td>
      );
    },
    [retailers],
  );

  const navigateToCreateUser = (): void => {
    if (pageMode === PageMode.Admin) {
      navigateTo('admin-users/create');
    } else {
      navigateTo(`users/create${selectedRetailers.length === 1 ? `?retailer=${selectedRetailers[0]}` : ''}`);
    }
  };

  return (
    <div className={`admin-user-management mode-${pageMode}`}>
      <Breadcrumbs
        onHomeClick={handleNavigation('/admin/home')}
        onRedirect={handleNavigation(window.location.pathname)}
        segments={[
          { key: 0, text: pageMode === PageMode.Admin ? 'Staff Management' : 'User Management', url: '' },
        ]}
      />
      {(data.loading || impersonation.loading || retailers.loading) && <LoadingThrobber/>}
      <section className={`heading-wrapper ${pageMode === PageMode.Admin ? 'isAdmin' : ''}`}>
        <h1>{pageMode === PageMode.Admin ? 'Staff' : 'User'} Management</h1>

        <div className="display-filters">
          <Dropdown
            className="user-sort"
            label=""
            name="user-sort"
            onChange={onOrderChanged}
            selectedValue={`${orderBy}-${orderDescending ? 'dsc' : 'asc'}`}
            options={[
              { label: 'Firstname: A-Z', value: 'firstname-asc' },
              { label: 'Firstname: Z-A', value: 'firstname-dsc' },
              { label: 'Lastname: A-Z', value: 'lastname-asc' },
              { label: 'Lastname: Z-A', value: 'lastname-dsc' },
              { label: 'Username: A-Z', value: 'email-asc' },
              { label: 'Username: Z-A', value: 'email-dsc' },
              { label: 'Date Created: A-Z', value: 'created-asc' },
              { label: 'Date Created: Z-A', value: 'created-asc' },
              { label: 'Last Used: A-Z', value: 'lastvisited-asc' },
              { label: 'Last Used: Z-A', value: 'lastvisited-dsc' },
            ]}
          />
          <Button
            type="button"
            className="tertiary create-user"
            text={`Create ${pageMode === PageMode.Admin ? 'staff' : 'user'}`}
            onClick={() => navigateToCreateUser()}
          />
        </div>
      </section>
      <div className="content-wrapper">
        <div className="user-controls">
          <Card>
            <span className="box-shadow">
              <h3>Filter By</h3>
            </span>
            <div className="user-search-wrapper">
              <div className="searchbar-wrapper">
                <TextInput
                  error=""
                  label="Search"
                  name="user-search"
                  onChange={(val: string) => setSearch(val)}
                  type="text"
                  value={search}
                  className="user-search"
                />
                <span className="icon-search"/>
              </div>
              {pageMode === PageMode.User &&
              <h4>Retailer</h4>
              }
              {pageMode === PageMode.User && retailers.data.map(
                (x: IRetailer) => <Checkbox
                  checked={selectedRetailers.indexOf(x.id) !== -1}
                  key={x.id}
                  label={x.externalIdentifier}
                  name={x.id.toString()}
                  onChange={retailerClicked}
                  isSmall={true}
                />,
              )}
            </div>
          </Card>
        </div>
        <div className="user-list">
          {
            !(retailers && retailers.loading) &&
            <InfiniteScroll
              pageStart={0}
              loadMore={() => loadMore()}
              hasMore={data.data.canLoadMore}
              initialLoad={false}
            >
              <Table className="manage-users">
                <thead>
                  <tr>
                    <th className="Table--stickyColumn">
                      Firstname{isHeaderSorted('firstname', orderBy, orderDescending)}
                    </th>
                    <th>Lastname{isHeaderSorted('lastname', orderBy, orderDescending)}</th>
                    <th>Username{isHeaderSorted('email', orderBy, orderDescending)}</th>
                    {pageMode === PageMode.User && <th>Retailer</th>}
                    <th>Date Created{isHeaderSorted('created', orderBy, orderDescending)}</th>
                    <th>Last Used{isHeaderSorted('lastused', orderBy, orderDescending)}</th>
                    <th className="Table--fixedColumn tableActions">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {data.data !== null
                  && data.data.data.map(user => (
                    <tr key={user.userId}>
                      <td className="Table--stickyColumn">{user.firstname}</td>
                      <td>{user.lastname}</td>
                      <td>{user.emailAddress}</td>
                      {
                        pageMode === PageMode.User && generateRetailerColumn(user.retailerId)
                      }
                      <td>{formatDateString24hr(user.created)}</td>
                      <td>{formatDateString24hr(user.lastVisited)}</td>
                      <td className="Table--actions">
                      {setPopupConfig(user).content.actions
                          .map((action: IPopupPropsConfigContentAction, index: number): JSX.Element => {
                            return (
                              <div className="tableButtons" key={index} >
                                <span className={`icon icon-${action.label.icon}`}/>
                                <button
                                    className="Popup-button"
                                    name="popupButton"
                                    data-qaid={`popupActionQaId${user.userId}`}
                                    onClick={
                                      (): void => {
                                        action.onClick();
                                      }
                                    }
                                >
                                  { <span
                                      className={`Popup-buttonIcon icon-${action.label.text}`}
                                      title={action.label.text}
                                  />
                                  }
                                </button>
                              </div>
                            );
                          },
                          )
                        }
                        {
                          (clickedUserId === user.userId) && (
                            <Popup
                              config={setPopupConfig(user)}
                              show={showPopup(user.userId)}
                              targetElement={popupTargetElement}
                              uniqueKey={setPopupUniqueKey(user.userId)}
                              close={closePopup}
                            />
                          )
                        }
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </InfiniteScroll>
          }
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state: IStoreState): any => {
  return {
    data: state.users,
    removeUserDetails: state.removeUser,
    retailers: state.retailers,
    impersonation: state.impersonateUser,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<any>) => ({
  getUsers: (parameters: IGetUsersParams) => dispatch(getUserPageRequest(parameters)),
  removeUser: (parameters: IRemoveUserParams) => dispatch(removeUserRequest(parameters)),
  getRetailers: () => dispatch(getRetailersRequest()),
  impersonateUser: (userId: number, onSuccess: () => void) => dispatch(impersonateUserRequest(userId, onSuccess)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ManageUsers);
