import { Reducer } from 'redux';
import { IApiRequest, ITransportCostLine } from '../../types';
import { GET_TRANSPORT_COSTS_REQUEST,
         GET_TRANSPORT_COSTS_RESPONSE,
         GET_TRANSPORT_COSTS_ERROR,
       } from '../../actions/StoresManagement/transportCostsAction';

const initialState: IApiRequest<ITransportCostLine[]> = {
  data: [],
  error: '',
  UpdatedAt: null,
  loading: false,
};

const transportCostsReducer: Reducer<IApiRequest<ITransportCostLine[]>>
= (state: IApiRequest<ITransportCostLine[]> = initialState, action : any) => {
  switch (action.type) {
    case GET_TRANSPORT_COSTS_REQUEST: {
      return {
        ...initialState,
        loading: true,
      };
    }
    case GET_TRANSPORT_COSTS_RESPONSE: {
      return {
        ...initialState,
        data: action.response,
      };
    }
    case GET_TRANSPORT_COSTS_ERROR: {
      return {
        ...initialState,
        error: 'An error has occured',
      };
    }
    default: {
      return state;
    }
  }
};

export default transportCostsReducer;
