import React, { Dispatch, useEffect, useState } from 'react';
import './Register.scss';
import { NavLink, withRouter } from 'react-router-dom';
import { Card, TextInput, Button } from '@orderly/morrisons-component-library';
import logo from '../../assets/images/login_logo.svg';
import { connect } from 'react-redux';
import * as siteWrapperAction from '../../actions/siteWrapperAction';
import Validator from '../../helpers/validator/Validator';
import { RegisterUserRequest } from '../../actions/registerUserAction';
import { IRegistrationParams } from '../../api/userApi';
import { navigateTo } from '../../routing/Navigation';
import ReCAPTCHA from '../../components/ReCAPTCHA/ReCAPTCHA';
import IRegisterProps from './IRegisterProps';

const Register = ({ updateSiteWrapperVisibility, register, match }: IRegisterProps & any) => {

  useEffect(() => {
    updateSiteWrapperVisibility(false);

    return () => updateSiteWrapperVisibility(true);
  });

  const [error, setError] = useState('');
  const [confirmError, setConfirmError] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [recaptcha, setRecaptcha] = useState(null);
  const validator = new Validator({
    password: {
      [Validator.Rules.required]: true,
    },
    confirmPassword: {
      [Validator.Rules.required]: true,
    },
  });

  const onSubmit = (e : any) => {
    e.preventDefault();

    if (isValid()) {
      register(
        {
          recaptcha,
          password,
          userId: parseInt(match.params.userId, 10),
          registrationId: match.params.registrationId,
        },
        () => navigateTo(''));
    }
  };

  const isValid = () : boolean => {
    setError('');
    setConfirmError('');

    const validationResults = validator.validate([
      { name: 'password', value: password },
      { name: 'confirmPassword', value: confirmPassword },
    ]);

    for (const field of validationResults.fields) {
      if (!field.valid) {
        if (field.name === 'password') {
          setError(field.errorMessage);
        } else if (field.name === 'confirmPassword') {
          setConfirmError(field.errorMessage);
        }
      }
    }

    if (validationResults.isValid) {
      if (password !== confirmPassword) {
        setError('Passwords do not match');
        validationResults.isValid = false;
      }
    }

    return validationResults.isValid;
  };

  return (
  <div className="login-page">
    <div className="login-underlay" />
    <div className="container content-wrapper">
      <div className="logo-wrapper">
        <NavLink className="logo" exact to={'/'}>
          <img src={logo} alt="site logo" className="img-responsive" />
        </NavLink>
      </div>
      <Card>
        <h1>Register your account</h1>
        <form noValidate onSubmit={onSubmit}>
        <TextInput
          type="password"
          label="Password"
          name="password"
          value={password}
          error={error}
          onChange={(e: any) => setPassword(e)}
        />
        <div className="password">
          <TextInput
            type="password"
            label="Confirm Password"
            name="confirmPassword"
            value={confirmPassword}
            error={confirmError}
            onChange={(e: any) => setConfirmPassword(e)}
          />
          <ReCAPTCHA onChange={(value: string) => setRecaptcha(value)} />
        </div>
        <Button
          type="submit"
          text="Register"
          className="secondary login-btn"
        />
        </form>
      </Card>
    </div>
  </div>
  );
};

const mapDispatchToProps = (dispatch: Dispatch<any>) => {
  return {
    updateSiteWrapperVisibility: (status: any) =>
      dispatch(siteWrapperAction.updateSiteWrapperVisibility(status)),
    register: (parameters: IRegistrationParams, onSuccess : () => void) =>
      dispatch(RegisterUserRequest(parameters, onSuccess)),
  };
};

export default connect(null, mapDispatchToProps)(withRouter(Register));
