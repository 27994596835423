import { Reducer } from 'redux';
import { IPendingOrdersOptionsState } from './IPendingOrdersOptionsState';

const initialState: IPendingOrdersOptionsState = {
  sortOrder: 14,
  selectedCategoryCode: null,
};

const pendingOrdersOptionsReducer: Reducer<IPendingOrdersOptionsState> = (
  state: IPendingOrdersOptionsState = initialState,
  action: any,
): IPendingOrdersOptionsState => {
  if (action.type === 'CONFIRMED_ORDERS_OPTIONS_PERSIST') {
    return {
      ...state,
      sortOrder: action.data.sortOrder,
    };
  }

  return state;
};

export default pendingOrdersOptionsReducer;
