import { Reducer } from 'redux';
import {
  GET_CATALOGUE_META_ERROR,
  GET_CATALOGUE_META_REQUEST,
  GET_CATALOGUE_META_RESPONSE } from '../actions/catalogueMetaAction';
import { ICatalogueMeta } from '../types';

const initialState: ICatalogueMeta = { meta: { success: false, updatedAt: null } };

const catalogueMetaReducer: Reducer<ICatalogueMeta>
= (state: ICatalogueMeta = initialState, action : any) => {
  switch (action.type) {
    case GET_CATALOGUE_META_RESPONSE: {
      return action.response;
    }
    case GET_CATALOGUE_META_REQUEST:
    case GET_CATALOGUE_META_ERROR:
    default: {
      return state;
    }
  }
};

export default catalogueMetaReducer;
