import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { connect, ConnectedComponentClass } from 'react-redux';
import { IStoreState, IUserDetails } from '../types';
import { isAccountManager } from '../helpers/Users/UserHelper';

interface IPrivateRouteParams {
  Component: ConnectedComponentClass<any, any>;
  userDetails: IUserDetails;
}

const AccountManagerRoute = ({ component: Component, userDetails, ...rest }: IPrivateRouteParams & any) => (
  <Route
    {...rest}
    render={props =>
      isAccountManager(userDetails) ? (
        <Component {...props} />
      ) : (
        <Redirect
          to={{
            pathname: '/admin/login',
            state: { from: props.location },
          }}
        />
      )
    }
  />
);

const mapStateToProps = (state: IStoreState) => {
  return {
    userDetails: state.userDetails,
  };
};

export default connect(mapStateToProps)(AccountManagerRoute);
