import React, { useRef, useState } from 'react';
import './ImportsCard.scss';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { Button } from '@orderly/morrisons-component-library';
import { IStoreState, IUserDetails } from '../../types';
import Modal from '../Modal/Modal';
import { arrayBufferToBase64 } from '../../helpers/ArrayBufferHelper';
import { IImportSuppyDemandOrderFormParams }
from '../../api/Reports/SupplyDemandOrderForm/IImportSuppyDemandOrderFormParams';
import { importSupplyDemandOrderFormRequest } from '../../actions/Reports/importSupplyDemandOrderFormAction';
import { IImportNationalProductDepotFormParams } from '../../api/NationalProductDepot/IImportNationalProductDepotFormParams';
import { importNationalProductDepotFormRequest } from '../../actions/importNationalProductDepotFormAction';
import LoadingThrobber from '../LoadingThrobber/LoadingThrobber';
import {
  isAccountManager,
  isAdmin,
  isSupplyTeam,
} from '../../helpers/Users/UserHelper';
import { IBaseFormImportParams } from '../../api/IBaseFormImportParams';

interface IImportssCardProps {
  userDetails?: IUserDetails;
  importSupplyDemandOrderForm?: (
    parameters: IImportSuppyDemandOrderFormParams,
    onSuccess: (data: string[]) => void,
  ) => void;
  importNationalProductDepotForm?: (
    parameters: IImportNationalProductDepotFormParams,
    onSuccess: (data: string[]) => void,
  ) => void;
}

const ImportsCard = ({
  userDetails,
  importSupplyDemandOrderForm,
  importNationalProductDepotForm,
}: IImportssCardProps) => {
  const [importLoading, setImportLoading] = useState(false);
  const importSupplyDemandOrderFormElement = useRef(null);
  const importNationalProductDepotFormElement = useRef(null);
  const [showImportResponseModal, setShowImportResponseModal] =
    useState<boolean>(false);
  const [importResults, setImportResults] = useState<string[]>([]);

  const onFormImport = (
    element: React.MutableRefObject<HTMLInputElement>,
    importFunction: (
      parameters: IBaseFormImportParams,
      onSuccess: (data: string[]) => void,
    ) => void,
  ): void => {
    setImportLoading(true);

    const [file] = element.current.files;
    const fileReader = new FileReader();

    fileReader.onload = () => {
      const fileData = fileReader.result;
      const parameters: IBaseFormImportParams = {
        data: arrayBufferToBase64(fileData as ArrayBuffer),
      };
      importFunction(parameters, (results: string[]) => {
        setImportLoading(false);
        element.current.value = null;
        if (results.length > 0) {
          setImportResults(results);
          setShowImportResponseModal(true);
        }
      });
    };

    if (file) {
      fileReader.readAsArrayBuffer(file);
    }
  };

  return (
    <div className="imports card">
      {importLoading && <LoadingThrobber />}
      {isAdmin(userDetails) ||
      isAccountManager(userDetails) ||
      isSupplyTeam(userDetails) ? (
        <>
          <h4>Imports</h4>
          <input
            type="file"
            accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
            ref={importSupplyDemandOrderFormElement}
            style={{
              display: 'none',
            }}
            onChange={() =>
              onFormImport(
                importSupplyDemandOrderFormElement,
                importSupplyDemandOrderForm,
              )
            }
            aria-label="Upload supply demand order form"
          />

          <Button
            type="button"
            text="Supply Demand Order Form"
            className="tertiary"
            onClick={(): void =>
              importSupplyDemandOrderFormElement.current.click()
            }
          />
          {!isSupplyTeam(userDetails) ? (
            <>
              <input
                type="file"
                accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                ref={importNationalProductDepotFormElement}
                style={{
                  display: 'none',
                }}
                onChange={() =>
                  onFormImport(
                    importNationalProductDepotFormElement,
                    importNationalProductDepotForm,
                  )
                }
                aria-label="Upload national depot document"
              />
              <Button
                type="button"
                text="National Depot Import"
                className="tertiary"
                onClick={(): void =>
                  importNationalProductDepotFormElement.current.click()
                }
              />
            </>
          ) : null}
        </>
      ) : null}
      <Modal
        header="Import Results"
        isOpen={showImportResponseModal}
        onClose={() => setShowImportResponseModal(false)}
      >
        <ul>
          {importResults.map((result, index) => (
            <li key={index}>{result}</li>
          ))}
        </ul>
      </Modal>
    </div>
  );
};

const mapStateToProps = (state: IStoreState) => {
  return {
    userDetails: state.userDetails,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<any>) => {
  return {
    importSupplyDemandOrderForm: (
      parameters: IImportSuppyDemandOrderFormParams,
      onSuccess: (data: string[]) => void,
    ) => dispatch(importSupplyDemandOrderFormRequest(parameters, onSuccess)),

    importNationalProductDepotForm: (
      parameters: IImportNationalProductDepotFormParams,
      onSuccess: (data: string[]) => void,
    ) => dispatch(importNationalProductDepotFormRequest(parameters, onSuccess)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ImportsCard);
