import { IDateValues } from './IDateValues';
import { monthsValueMapper } from './Mappers/monthsMapper';
import { IFormatDateHelper } from './IFormatDateHelper';
import { formatTime } from '../formatTime';

class FormatDateService implements IFormatDateHelper {
  constructor() { }

  public formatDate(date: Date, formatter: string = 'YYYY-MM-DD'): string {
    if (formatter === 'YYYY-MM-DD') {
      return date.toISOString().split('T')[0];
    }
    throw new Error('Unsupported date format.');
  }

  public formatStandardizedDate(date: Date | string, hourExtension: boolean = false): string {
    if (this.isDefaultDate(date)) {
      return '-';
    }

    const options: Intl.DateTimeFormatOptions = {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
    };

    if (hourExtension) {
      options.hour = 'numeric';
      options.minute = 'numeric';
    }

    return new Date(date).toLocaleDateString('en-GB', options);
  }

  public formatCalendarDateToISO(selectedDate: Date): string {
    const { date, month, year } = this.splitDateString(selectedDate.toString());

    return `${year}-${monthsValueMapper[month]}-${date}`;
  }

  public formatShortDate(dateString: any): string {
    const { date, month, suffix, year } = this.getDateValues(dateString);

    return `${date}${suffix} ${month} ${year}`;
  }

  public getDateValues(dateString: string): IDateValues {
    const fullDate: Date = new Date(dateString);
    const { date, month, year } = this.splitDateString(fullDate.toString());
    const suffix = this.getDateSuffix(date);
    const time = formatTime(fullDate);

    return {
      month,
      suffix,
      year,
      time,
      date: Number(date).toString(),
    };
  }

  public GetDateExcludingWeekends = (date: Date, hours: number) =>  {
    const resultDate = new Date(date);
    let remainingHours = hours;

    while (remainingHours > 0) {
      resultDate.setHours(resultDate.getHours() - 1);

      const day = resultDate.getDay();
      if (day !== 0 && day !== 6) { // 0 = Sunday, 6 = Saturday
        remainingHours--;
      }
    }

    return resultDate;
  }

  private isDefaultDate(date: Date | string): boolean {
    if (date == null) {
      return true;
    }
    const parsedDate = new Date(date);
    return parsedDate.toISOString() === '0001-01-01T00:00:00.000Z';
  }

  private splitDateString(dateString: string): any {
    const dateValues: string[] = dateString.slice(3, 16).trim().split(' ');
    const month: string = dateValues[0];
    const date: string = dateValues[1];
    const year: string = dateValues[2];

    return {
      date,
      month,
      year,
    };
  }

  private getDateSuffix(day: number): string {
    if (day > 3 && day < 21) {
      return 'th';
    }

    switch (day % 10) {
      case 1: return 'st';
      case 2: return 'nd';
      case 3: return 'rd';
      default: return 'th';
    }
  }
}

const FormatDateHelper: FormatDateService = new FormatDateService();

export default FormatDateHelper;
