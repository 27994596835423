import React from 'react';
import './ProductDetails.scss';
import { Tabs, Tab } from '@orderly/morrisons-component-library';
import { IProductDetail } from '../../../types';
import { formatCurrency } from '../../../helpers/Format';
import {
  getPriceByType,
  PriceType,
  PriceSelectionType,
  calculatePOR,
} from '../../../helpers/Product/ProductPriceHelper';
import { IProductDetailsState } from './ProductDetails.interface';
import { mapCategory } from '../../../helpers/Category/mapCategory';
import { hasValue } from '../../../helpers/validator/Validator';
import { hasHfssInfo } from '../../../helpers/Product/ProductHelper';

export default class ProductDetails extends React.Component<any, IProductDetailsState> {
  public state: IProductDetailsState = {
    activeTab: 'product-identifiers',
  };

  public render(): JSX.Element {

    const { activeTab } = this.state;
    const product = this.props.product as IProductDetail;

    return (
      <Tabs onChange={this.onTabChanged} activeTab={activeTab}>
        <Tab id="product-identifiers" title="Product Identifiers">
          <table>
            <thead>
              <tr>
                <th>Name</th>
                <th>Value</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Product ID</td>
                <td>{product.itemId}</td>
              </tr>
              <tr>
                <td>Morrisons ID</td>
                <td>{product.morrisonsId}</td>
              </tr>
              <tr>
                <td>Barcode</td>
                <td>{product.barcodeEan}</td>
              </tr>
              <tr>
                <td>Legacy Code</td>
                <td>{product.legacyCode}</td>
              </tr>
            </tbody>
          </table>
        </Tab>
        <Tab id="product-pricing" title="Product Pricing">
          <table>
            <thead>
              <tr>
                <th>Type</th>
                <th>Value</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Unit Price</td>
                <td>
                  {
                    getPriceByType(
                      product,
                      PriceType.UnitPrice,
                      PriceSelectionType.NormalPrice,
                    )
                  }
                </td>
              </tr>
              <tr>
                <td>Case Price</td>
                <td>
                {
                    getPriceByType(
                      product,
                      PriceType.CasePrice,
                      PriceSelectionType.NormalPrice,
                    )
                  }
                </td>
              </tr>
              <tr>
                <td>Recommended Retail Price</td>
                <td>{formatCurrency(product.recommendedRetailPrice)}</td>
              </tr>
              <tr>
                <td>POR</td>
                <td>{calculatePOR(product).toFixed(2)}%</td>
              </tr>
            </tbody>
          </table>
        </Tab>
        <Tab id="product-attributes" title="Product Attributes">
          <table>
            <thead>
              <tr>
                <th>Attribute</th>
                <th>Value</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Case Dimensions (W*H*L)</td>
                <td>{product.width} * {product.height} * {product.length}</td>
              </tr>
              <tr>
                <td>Order Category</td>
                <td>{mapCategory(product.category.description)}</td>
              </tr>
              <tr>
                <td>Product Category</td>
                <td>{product.category.description}</td>
              </tr>
              <tr>
                <td>Single Pick</td>
                <td>{product.singlePick ? 'Y' : 'N'}</td>
              </tr>
              <tr>
                <td>VAT</td>
                <td>{product.vatRate}%</td>
              </tr>
              <tr>
                <td>Weight</td>
                <td>{product.weight}</td>
              </tr>
              <tr>
                <td>Case Size</td>
                <td>{product.caseSize}</td>
              </tr>
              <tr>
                <td>Cases per Pallet</td>
                <td>{product.casePerPallet}</td>
              </tr>
              <tr>
                <td>Cases per Layer</td>
                <td>{product.casePerLayer}</td>
              </tr>
              <tr>
                <td>Minimum Shipping Life</td>
                <td>{product.minimumShipLife} days</td>
              </tr>
              <tr>
                <td>Minimum Shelf Life</td>
                <td>{product.minimumShelfLife} days</td>
              </tr>
            </tbody>
          </table>
        </Tab>
        <Tab id="product-hfss" title={hasHfssInfo(product) ? 'HFSS' : ''}>
          <table>
            <thead>
              <tr>
                <th>Attribute</th>
                <th>Value</th>
              </tr>
            </thead>
            <tbody>
              {hasValue(product.hfssCategory) && <tr>
                <td>Within Scope of legislation</td>
                <td>{product.hfssCategory > 0 ? 'Yes' : 'No'}</td>
              </tr>}
              {hasValue(product.isLessHealthy) && <tr>
                <td>Classified as 'Less Healthy'</td>
                <td>{product.isLessHealthy ? 'Yes' : 'No'}</td>
              </tr>}
              {hasValue(product.hfssInScope) && <tr>
                <td>In Scope For HFSS</td>
                <td>{product.hfssInScope > 0 ? 'Yes' : 'No'}</td>
              </tr>}
              {hasValue(product.nutrientProfilingScore) && <tr>
                <td>Nutritional Profiling Score</td>
                <td>{product.nutrientProfilingScore}</td>
              </tr>}
              {hasValue(product.foodOrDrink) && <tr>
                <td>Food/Drink</td>
                <td>{product.foodOrDrink}</td>
              </tr>}
              {hasValue(product.asSoldOrAsConsumed) && <tr>
                <td>As Sold or Consumed</td>
                <td>{product.asSoldOrAsConsumed}</td>
              </tr>}
              {hasValue(product.isVegetarian) && <tr>
                <td>Vegetarian</td>
                <td>{product.isVegetarian ? 'Yes' : 'No'}</td>
              </tr>}
              {hasValue(product.isVegan) && <tr>
                <td>Vegan?</td>
                <td>{product.isVegan ? 'Yes' : 'No'}</td>
              </tr>}
              {hasValue(product.energyPer100g) && <tr>
                <td>Energy (Per 100g)</td>
                <td>{`${product.energyPer100g} Kcal`}</td>
              </tr>}
              {hasValue(product.sugarContentPer100g) && <tr>
                <td>Sugar Content (Per 100g)</td>
                <td>{`${product.sugarContentPer100g}g`}</td>
              </tr>}
              {hasValue(product.proteinContentPer100g) && <tr>
                <td>Protein Content (Per 100g)</td>
                <td>{`${product.proteinContentPer100g}g`}</td>
              </tr>}
              {hasValue(product.saturatedfatContentPer100g) && <tr>
                <td>Saturated Fat Content (Per 100g)</td>
                <td>{`${product.saturatedfatContentPer100g}g`}</td>
              </tr>}
              {hasValue(product.fibreContentPer100g) && <tr>
                <td>Fibre Content (Per 100g)</td>
                <td>{`${product.fibreContentPer100g}g`}</td>
              </tr>}
              {hasValue(product.saltContentPer100g) && <tr>
                <td>Salt Content (Per 100g)</td>
                <td>{`${product.saltContentPer100g}g`}</td>
              </tr>}
              {hasValue(product.sodiumContentPer100g) && <tr>
                <td>Sodium Content (Per 100g)</td>
                <td>{`${product.sodiumContentPer100g}mg`}</td>
              </tr>}
            </tbody>
          </table>
        </Tab>
      </Tabs>
    );
  }

  private onTabChanged = (tabId: string) => {
    this.setState({ activeTab: tabId });
  }
}
