import { Reducer } from 'redux';
import {
  GET_CUSTOMER_GROUPS_ERROR,
  GET_CUSTOMER_GROUPS_REQUEST,
  GET_CUSTOMER_GROUPS_RESPONSE } from '../actions/customerGroupsAction';
import { IApiRequest } from '../types';

const initialState: IApiRequest<string[]> = {
  data: [],
  error: '',
  UpdatedAt: null,
  loading: false,
};

const customerGroupReducer: Reducer<IApiRequest<string[]>>
= (state: IApiRequest<string[]> = initialState, action : any) => {
  switch (action.type) {
    case GET_CUSTOMER_GROUPS_RESPONSE: {
      return {
        data: action.response,
        error: '',
        UpdatedAt: new Date(),
        loading: false,
      };
    }
    case GET_CUSTOMER_GROUPS_REQUEST: {
      return {
        data: [],
        error: '',
        UpdatedAt: null,
        loading: true,
      };
    }
    case GET_CUSTOMER_GROUPS_ERROR:
    default: {
      return state;
    }
  }
};

export default customerGroupReducer;
