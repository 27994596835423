import React, { ReactNode, Dispatch } from 'react';
import './SelectRetailer.scss';
import { Dropdown, Card } from '@orderly/morrisons-component-library';
import { SelectRetailerHelper } from './Helper/SelectRetailerHelper';
import { ISelectRetailerComponent } from './interfaces/SelectRetailer.interface';
import { navigateTo } from '../../../routing/Navigation';
import { connect } from 'react-redux';
import { IStoreState, IRetailer } from '../../../types';
import { getRetailersRequest } from '../../../actions/retailersAction';
import LoadingThrobber from '../../../components/LoadingThrobber/LoadingThrobber';

class SelectRetailerComponent extends React.Component<any, any> implements ISelectRetailerComponent {
  public selectedValue: any;

  private _selectRetailerHelper: SelectRetailerHelper;

  constructor(props: any) {
    super(props);
    this._selectRetailerHelper = new SelectRetailerHelper();
  }

  public componentDidMount(): void {
    this.props.getRetailers();
  }

  public handleDropdownChange(): (event: any) =>  void {
    return this._selectRetailerHelper.handleDropdown(this.redirectToSate);
  }

  public redirectToSate(id: number): void {
    navigateTo(`/admin/documents/${id}`);
  }

  public render(): ReactNode {
    return (
          <div className="SelectRetailer-container">
            {
              this.props.retailers.loading && <LoadingThrobber />
            }
            <Card>
              <div className="SelectRetailer-formContainer">
                <form noValidate>
                  <div className="SelectRetailer-formGroup">
                    <div className="SelectRetailer-labelContainer">
                      <label htmlFor="selectRetailerDropdown" className="SelectRetailer-label">
                        Please select a retailer
                      </label>
                    </div>
                    <Dropdown
                          className="SelectRetailer-companySelect"
                          key="selectRetailerDropdown"
                          label=""
                          name="retailerId"
                          onChange={this.handleDropdownChange()}
                          selectedValue={this.selectedValue}
                          options={this.props.retailers.data.map((x: IRetailer) => {
                            return {
                              value: x.id,
                              label: x.externalIdentifier,
                            };
                          })}
                    />
                  </div>
                </form>
              </div>
            </Card>
          </div>
    );
  }
}

const mapStateToProps = (state: IStoreState) => {
  return {
    retailers: state.retailers,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<any>) => {
  return {
    getRetailers: () => dispatch(getRetailersRequest()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SelectRetailerComponent);
