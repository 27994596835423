import { IRemoveUserParams, removeUser } from '../api/adminUserApi';
import { AxiosResponse } from 'axios';

export const REMOVE_USER_RESPONSE = 'REMOVE_USER_RESPONSE';
export type REMOVE_USER_RESPONSE = typeof REMOVE_USER_RESPONSE;

export const removeUserRequest = (parameters: IRemoveUserParams): any => {
  return (dispatch : any) => {
    return removeUser(parameters)
      .then((response: AxiosResponse<void>) => {
        if (response.status === 200) {
          dispatch({
            type: REMOVE_USER_RESPONSE,
            userId: parameters.userId,
          });

        } else {
          onRemoveError(dispatch, parameters.userId);
        }
      })
      .catch((err: any) => {
        console.log(err);
        onRemoveError(dispatch, parameters.userId);
      });
  };
};

const onRemoveError = (dispatch: any, userId: number) => dispatch({
  userId,
  type: REMOVE_USER_RESPONSE,
});
