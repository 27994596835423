import React, { useEffect } from 'react';
import { FullPageLayout } from '../../../layouts';
import { IStoreState } from '../../../types';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { IEditTradingPackProps } from './IEditTradingPackProps';
import { getTradingPackRequest } from '../../../actions/Products';
import { LoadingThrobber, TradingPackDetail } from '../../../components';
import { getCustomerGroupsRequest } from '../../../actions/customerGroupsAction';
import { getRetailersRequest } from '../../../actions/retailersAction';

import styles from './EditTradingPack.module.scss';

function EditTradingPack({
  match,
  tradingPack,
  customerGroups,
  retailers,
  loading,
  getTradingPack,
  getCustomerGroups,
  getRetailers,
}: IEditTradingPackProps): JSX.Element {
  const isCreating = isNaN(match.params.id);
  const title = isCreating ? 'Create Trading Pack' : 'Edit Trading Pack';

  useEffect(
    () => {
      const tradingPackId = parseInt(isCreating ? -1 : match.params.id, 10);

      getTradingPack(tradingPackId);
      getCustomerGroups();
      getRetailers();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [],
  );

  return (
    <FullPageLayout
      heading={title}
      breadcrumbs={[
        {
          key: 0,
          text: 'Manage Trading Pack',
          url: '/admin/trading-packs',
        },
        {
          key: 1,
          text: title,
          url: window.location.pathname,
        },
      ]}
      homeRoute="/admin/home"
    >
      <div className={styles.container}>
        {tradingPack.error && <div>Error</div>}
        {loading && <LoadingThrobber />}
        {
          (!loading &&
            tradingPack.data &&
            retailers.data &&
            customerGroups.data) &&
          <TradingPackDetail
            key={tradingPack.data.id}
            tradingPack={tradingPack.data}
            retailers={retailers.data}
            customerGroups={customerGroups.data}
          />
        }
      </div>
    </FullPageLayout>
  );
}

const mapStateToProps = (state: IStoreState) => {
  return {
    tradingPack: state.tradingPack,
    customerGroups: state.customerGroups,
    retailers: state.retailers,
    loading: (state.tradingPack.loading || state.customerGroups.loading || state.retailers.loading),
  };
};

const mapDispatchToProps = (dispatch: Dispatch<any>) => {
  return {
    getTradingPack: (id: number) => dispatch(getTradingPackRequest(id)),
    getCustomerGroups: () => dispatch(getCustomerGroupsRequest()),
    getRetailers: () => dispatch(getRetailersRequest()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EditTradingPack);
