import React from 'react';
import Slider from 'react-slick';
import './ProductCarousel.scss';
import ProductPromo from '../ProductPromo/ProductPromo';
import { IProduct } from '../../types';

export default class ProductCarousel extends React.Component<any, any> {
  private slider: any;

  public render(): JSX.Element {
    const { products, maxOrderQuantity } = this.props;
    const settings = {
      arrows: false,
      draggable: true,
      dots: false,
      infinite: true,
      useTransform: false,
      slidesToShow: Math.min(products.length, 6),
      slidesToScroll: 1,
      className: 'carousel-items',
      variableWidth: true,
      swipeToSlide: true,
    };

    return (
      <div className="product-carousel">
        <Slider ref={s => (this.slider = s)} {...settings}>
          {products.map((product: IProduct, i: number) => (
            <ProductPromo
              key={product.id}
              product={product}
              lazyLoad={false}
              maxOrderQuantity={maxOrderQuantity}
            />
          ))}
        </Slider>
        {products.length > 5
          && <div className="carousel-navigation-wrapper">
            <button onClick={this.movePrev} className="circle">
              <span className="icon icon-arrow-left" />
            </button>
            <button onClick={this.moveNext} className="circle">
              <span className="icon icon-arrow-right" />
            </button>
          </div>
        }
      </div>
    );
  }

  private moveNext = () => {
    this.slider.slickNext();
  }

  private movePrev = () => {
    this.slider.slickPrev();
  }
}
