import Bowser, { Parser } from 'bowser';

class DetectBrowser {
  private _parser: Parser.Parser;

  constructor() {
    this._parser = Bowser.getParser(window.navigator.userAgent);
  }

  public getBrowser(): Parser.BrowserDetails {
    return this._parser.getBrowser();
  }

  public getBrowserName(): string {
    return this._parser.getBrowserName();
  }

  public getBrowserVersion(): string {
    return this._parser.getBrowserVersion();
  }

  public is(name: string): boolean {
    return this.getBrowserName().toLowerCase() === name.toLowerCase();
  }
}

const BrowserDetector = ((): any => {
  return new DetectBrowser();
})();

export default BrowserDetector;
