export enum POSPromotionTypes {
  No_Strikethrough = 0,
  Strikethrough = 1,
  Multibuy = 2,
  Half_Price = 3,
  Better_Than_Half_Price = 4,
  BOGOF = 5,
  Save_a_Third = 6,
  POS_General = 8,
}
