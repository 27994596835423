import { Card } from '@orderly/morrisons-component-library';
import React, { useState, Dispatch } from 'react';
import './ProductPromo.scss';
import { IProduct, IStoreState } from '../../types';
import LazyLoad from 'react-lazy-load';
import { navigateTo } from '../../routing/Navigation';
import { updateBasketRequest } from '../../actions/Basket/updateBasketAction';
import { connect } from 'react-redux';
import BasketOptions from '../BasketOptions/BasketOptions';
import {
  getPriceByType,
  PriceType,
  PriceSelectionType,
  calculatePOR,
  ViewMode,
} from '../../helpers/Product/ProductPriceHelper';
import { formatCurrency } from '../../helpers/Format';
import ShoppingListButton from '../ShoppingListButton';
import ProductCardInfo from '../ProductCardInfo';
import ProductPromotionalPrice from '../ProductPromotionalPrice';
import { shouldDisplayShelfLife } from '../../helpers/Product/ProductHelper';

interface IProductPromoProps {
  product: IProduct;
  visible?: boolean;
  lazyLoad: boolean;
  maxOrderQuantity: number;
}

const ProductPromo = ({
  product,
  visible = true,
  lazyLoad = true,
  maxOrderQuantity,
}: IProductPromoProps) => {
  const [imageLoaded, setImageLoaded] = useState(false);

  if (!visible) {
    return null;
  }

  let image;
  if (lazyLoad) {
    image = (
      <React.Fragment>
        <LazyLoad
          debounce={false}
          onContentVisible={() => setImageLoaded(true)}
          offset={750}
        >
          <img src={product.imageUrl} alt={product.description} />
        </LazyLoad>
        {!imageLoaded && <img className="not-found" alt="not-found" src={require('../../assets/images/404.jpg')} />}
      </React.Fragment>
    );
  } else {
    image = (
      <img src={product.imageUrl} alt={product.description} />
    );
  }

  if (product.imageUrl.length === 0) {
    image = <img className="not-found" alt="not-found" src={require('../../assets/images/404.jpg')} />;
  }

  const viewProductDetails = (url: string): void => {
    navigateTo(url);
  };

  const getRoundelDateString = (days: number): string => {
    if (!days || days === 0) {
      return '';
    }
    if (days >= 100) {
      return `${Math.floor(days / 31)}M`;
    }
    return `${days}D`;
  };

  return (
    <Card className="product-promo">
      <div className="category-tag">{product.category}</div>
      {shouldDisplayShelfLife(product) && <div className="shelf-life-roundel">
        {getRoundelDateString(product.minimumShelfLife)}
      </div>
      }
      <div
        className="image-container"
        onClick={
          (): void => {
            viewProductDetails(`/product/${product.itemId}`);
          }}
      >
        {image}
      </div>
      <div className="subtext">
        <div
          className="description"
          onClick={
            (): void => {
              viewProductDetails(`/product/${product.itemId}`);
            }}
        >
          {product.description}
        </div>
        <div className="pricing-wrapper">
          {
            product.activeTradingPromotion ?
              <ProductPromotionalPrice
                product={product}
                isListView={false}
                viewMode={ViewMode.Price}
              /> :
              <ProductCardInfo
                value={
                  getPriceByType(
                    product,
                    product.singlePick ? PriceType.UnitPrice : PriceType.CasePrice,
                    PriceSelectionType.NormalPrice,
                  )
                }
                description={product.singlePick ? '(single)' : `${product.caseSize} pack`}
              />
          }
          {
            product.activeRetailPromotion ?
              <ProductPromotionalPrice
                product={product}
                isListView={false}
                viewMode={ViewMode.RRP}
              /> :
              <ProductCardInfo
                value={formatCurrency(product.recommendedRetailPrice)}
                description="RRP"
              />
          }
          <ProductCardInfo
            value={`${calculatePOR(product).toFixed(2)}%`}
            description="POR"
          />
          <ShoppingListButton product={product} />
        </div>
        <BasketOptions
          itemId={product.itemId}
          category={product.category}
          status={product.status}
          available={product.available}
          showLabels={false}
          maxOrderQuantity={maxOrderQuantity}
        />
      </div>
    </Card>);
};

const mapStateToProps = (state: IStoreState) => {
  return {
    basket: state.basket,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<any>) => {
  return {
    addToBasket: (itemId: number, quantity: number) => dispatch(updateBasketRequest(itemId, quantity)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ProductPromo);
