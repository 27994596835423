import React, { useEffect, useState, useRef } from 'react';
import './ManageTransportCosts.scss';
import { IManageTransportCostsProps } from './IManageTransportCostsProps';
import { connect } from 'react-redux';
import { navigateTo } from '../../../routing/Navigation';
import { Breadcrumbs, Button } from '@orderly/morrisons-component-library';
import LoadingThrobber from '../../../components/LoadingThrobber/LoadingThrobber';
import { IStoreState } from '../../../types';
import { Dispatch } from 'redux';
import Table from '../../../components/Table/Table';
import {
  IExportTransportCostsResponse,
  IImportTransportCostsParams,
  IImportTransportCostsResponse,
} from '../../../api/Stores/storesApi';
import { exportTransportCostsRequest, importTransportCostsRequest } from '../../../actions/StoresManagement/storesAction';
import Modal from '../../../components/Modal/Modal';
import { arrayBufferToBase64 } from '../../../helpers/ArrayBufferHelper';
import { getTransportCostsRequest } from '../../../actions/StoresManagement/transportCostsAction';
import { GenerateDownload } from '../../../helpers/DownloadHelper';

const ManageTransportCosts = ({
  transportCosts,
  getTransportCosts,
  importTransportCosts,
  exportTransportCosts,
}: IManageTransportCostsProps) => {

  const importElement = useRef(null);
  const [importResults, setImportResults] = useState([]);
  const [showImportResponseModal, setshowImportResponseModal] = useState(false);

  useEffect(() => {
    getTransportCosts();
  }, []);

  const onFileUpload = (): void => {
    const file = importElement.current.files[0];
    if (file) {
      const fileReader = new FileReader();
      if (fileReader && file) {
        fileReader.readAsArrayBuffer(file);
        // tslint:disable-next-line: typedef
        fileReader.onload = function () {
          const imageData = fileReader.result;
          if (imageData) {
            const params: IImportTransportCostsParams = {
              file: arrayBufferToBase64(imageData as ArrayBuffer),
            };
            importTransportCosts(params, (results: any) => {
              importElement.current.value = null;
              if (results.length > 0) {
                setImportResults(results);
                setshowImportResponseModal(true);
              }
              getTransportCosts();
            });
          }
        };
      }
    }
  };

  const onExport = (): void => {
    exportTransportCosts((response: any) => {
      const file = response.exportTransortCosts;
      GenerateDownload(file.fileData, file.fileName);
    });
  };

  return (
    <div className={'admin-store-management'}>
      <Breadcrumbs
        onHomeClick={() => navigateTo('/admin/home')}
        onRedirect={() => navigateTo(window.location.pathname)}
        segments={[
          { key: 0, text: 'Transport Cost Management', url: '' },
        ]}
      />
      {(transportCosts.loading) && <LoadingThrobber />}
      <section className="heading-wrapper">
        <h1>Transport Costs</h1>
      </section>
      <div className="content-wrapper">
        <div className="stores-list">
          <div className="import-export-buttons">
            <input
              type="file"
              accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
              ref={importElement}
              style={{ display: 'none' }}
              onChange={() => onFileUpload()}
            />
            <div>
              <Button
                type="button"
                className="tertiary import-store-button"
                icon="icon-upload3"
                iconPos="left"
                text="Import Transport Costs"
                onClick={(): void => { importElement.current.click(); }}
              />
            </div>
            <div>
              <Button
                type="button"
                className="link tertiary load-store-button"
                text={'Export Transport Costs'}
                onClick={() => onExport()}
              />
            </div>
          </div>

          {
            (transportCosts && !transportCosts.loading) &&
            <Table className="stores-table">
              <thead>
                <tr>
                  <th>Retailer</th>
                  <th>Store ID</th>
                  <th>Store Name</th>
                  <th>Transport Cost</th>
                </tr>
              </thead>
              <tbody>
                {transportCosts && transportCosts.data.length > 0 && transportCosts.data.map(line => (
                  <tr key={`${line.retailer}-${line.externalId}`}>
                    <td>{line.retailer}</td>
                    <td>{line.externalId}</td>
                    <td>{line.name}</td>
                    <td>&pound;{line.transportCost}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          }
        </div>
      </div>
      <Modal
        header="Import Results"
        isOpen={showImportResponseModal}
        onClose={() => setshowImportResponseModal(false)}
      >
        <ul>
          {
            importResults.length > 0 ?
              importResults.map((message, key) => <li key={key}>{message}</li>) :
              null
          }
        </ul>
      </Modal>
    </div>
  );
};

const mapStateToProps = (state: IStoreState): any => {
  return {
    transportCosts: state.transportCosts,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<any>) => ({
  getTransportCosts: () => dispatch(getTransportCostsRequest()),
  importTransportCosts: (
    parameters: IImportTransportCostsParams,
    onResponse: (data: IImportTransportCostsResponse) => void) =>
    dispatch(importTransportCostsRequest(parameters, onResponse)),
  exportTransportCosts: (
    onExport: (data: IExportTransportCostsResponse) => void) =>
    dispatch(exportTransportCostsRequest(onExport)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ManageTransportCosts);
