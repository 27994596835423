import React, { Dispatch, useEffect, useState } from 'react';
import { Card, Checkbox, Breadcrumbs, BreadcrumbSegment, Button, TextInput } from '@orderly/morrisons-component-library';
import QueryString from 'query-string';
import { connect } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import './AdminDocuments.scss';
import { IAdminDocumentsProps } from './IAdminDocumentsProps';
import { Table } from 'react-bootstrap';
import { deleteDocumentRequest } from '../../../actions/Documents/deleteDocumentAction';
import { downloadDocumentBlobRequest } from '../../../actions/Documents/downloadDocumentBlobAction';
import { clearFolderContents, getFolderContentsRequest } from '../../../actions/Documents/folderContentsAction';
import {
  getUserAcceptanceReportRequest,
  resetUserAcceptanceReportData,
} from '../../../actions/Reports/userAcceptanceAction';
import { getRetailersRequest } from '../../../actions/retailersAction';
import { IDeleteDocumentParams } from '../../../api/Documents/deleteDocumentApi';
import { IDownloadDocumentBlobParams } from '../../../api/Documents/downloadDocumentBlobApi';
import { IGetFolderContentsParams } from '../../../api/Documents/getFolderContentsApi';
import { IUserAcceptanceParams } from '../../../api/Reports/ConfirmedOrders/IUserAcceptanceParams';
import FolderSelect from '../../../components/FolderSelect/FolderSelect';
import LoadingThrobber from '../../../components/LoadingThrobber/LoadingThrobber';
import { IPopupPropsConfigContentAction } from '../../../components/popup/interfaces/IPopupPropsConfigContentAction';
import { getFileIcon } from '../../../helpers/Documents/DocumentUploadHelper';
import { GenerateDownload } from '../../../helpers/DownloadHelper';
import { isAdmin } from '../../../helpers/Users/UserHelper';
import { navigateTo } from '../../../routing/Navigation';
import { IDocument, IRetailer, IDocumentBlob, IStoreState, FolderType } from '../../../types';
import EmptyDocumentsCard from '../../../components/EmptyDocumentsCard/EmptyDocumentsCard';
import { IDeleteFolderParams } from '../../../api/Documents/deleteFolderApi';
import { deleteFolderRequest } from '../../../actions/Documents/deleteFolderAction';

const AdminDocuments = (props: IAdminDocumentsProps) => {
  const [selectedTags, setSelectedTags] = useState<string[]>([]);
  const [search, setSearch] = useState<string>('');

  const {
    getRetailers,
    getFolderContents,
    userAcceptanceCsvDownload,
    retailers,
    match,
    folderContents,
    userDetails,
    downloadDocument,
    downloadUserAcceptanceCsv,
    clearUserAcceptanceCsvDownload,
  } = props;

  useEffect(() => {
    getRetailers();
    clearFolderContents();
    loadData();
  }, []);

  useEffect(() => {
    if (userAcceptanceCsvDownload.data) {
      const { filename, content } = userAcceptanceCsvDownload.data;
      generateDownload(filename, content);
    }
  }, [userAcceptanceCsvDownload]);

  useEffect(() => {
    getFilteredDocuments(selectedTags, search);
  }, [selectedTags, search]);

  if (retailers.loading) {
    return <LoadingThrobber />;
  }

  const clearAllFilters = () => {
    setSelectedTags([]);
  };

  const editFile = (documentId: number) => {
    const folder = QueryString.parse(window.location.search).folder;
    navigateTo(`/admin/documents/${match.params.retailerId}/edit/${documentId}?parent=${folder ? folder : ''}`);
  };

  const deleteDocument = (documentId: number) => {
    const toastrConfirmOptions = {
      onOk: () => {
        props.deleteDocument(
          {
            documentId,
          },
          () => {
            toastr.success(
              'Success',
              'File has been deleted.');
            loadData();
          },
          () => {
            toastr.success(
              'Success',
              'File has been deleted.');
            loadData();
          },
        );
      },
    };
    toastr.confirm('Are you sure you wish to delete this file?', toastrConfirmOptions);
  };

  const deleteFolder = (folderId: number) => {
    const toastrConfirmOptions = {
      onOk: () => {
        props.deleteFolder(
          {
            folderId,
          },
          () => {
            toastr.success(
              'Success',
              'Folder and its contents have been deleted.');
            navigateTo(`/admin/documents/${match.params.retailerId}`);
            loadData();
          },
          () => {
            toastr.error(
              'Failure',
              'There was an error deleting the folder. Please try again.');
            navigateTo(`/admin/documents/${match.params.retailerId}`);
            loadData();
          },
        );
      },
    };
    toastr.confirm('Are you sure you wish to delete this folder and its contents?', toastrConfirmOptions);
  };

  const folderIsPresent = () => {
    const folder = QueryString.parse(window.location.search).folder;
    return folder !== undefined && folder !== '';
  };

  const switchTagSelection = (tag: string) => {
    if (selectedTags.indexOf(tag) > -1) {
      setSelectedTags(selectedTags.filter((t: string) => t !== tag));
    } else {
      setSelectedTags([...selectedTags, tag]);
    }
  };

  const getFilteredDocuments = (tags: string[], search: string) => {
    let filteredDocs: IDocument[] = [];

    if (folderContents &&
      folderContents.data &&
      folderContents.data.documents &&
      folderContents.data.documents.length > 0) {
      filteredDocs = folderContents.data.documents.filter((document: IDocument) => {
        let showDocument = true;
        tags.forEach((tag: string) => {
          showDocument = showDocument && document.tags.indexOf(tag) > -1;
        });
        return showDocument && document.description.toLowerCase().indexOf(search.toLowerCase()) > -1;
      });
    }

    return filteredDocs;
  };

  const getAllTags = () => {
    if (folderContents &&
        folderContents.data &&
        folderContents.data.documents &&
        folderContents.data.documents.length > 0) {
      const tagsOfDocsArray = folderContents.data.documents
        .map((doc: IDocument) => doc.tags);
      const allTags = ([] as string[]).concat.apply([], tagsOfDocsArray);
      const unique = [...new Set(allTags)];
      return unique;
    }
    return [];
  };

  const getRetailerName = () => {
    const retailerId: number = parseInt(match.params.retailerId, 10);
    if (retailers.data && retailers.data.length > 0) {
      const name = retailers.data.filter((x: IRetailer) => x.id === retailerId)[0];
      return name ? name : '';
    }
    return '';
  };

  const getBreadcrumbs = () => {
    const queryString = QueryString.parse(window.location.search);
    const folderPath = queryString.folder as string;
    let catalogueName = '...';
    if (folderContents.data && folderContents.data.catalogue) {
      catalogueName = folderContents.data.catalogue;
    }

    const breadcrumbs: BreadcrumbSegment[] = [
      { key: 0, text: 'Admin', url: '/admin/home/' },
      { key: 1, text: catalogueName, url: `/admin/documents/${match.params.retailerId}` },
    ];

    if (folderPath) {
      const folderParts = folderPath.split('/');

      for (let i = 0; i < folderParts.length; i++) {
        let url = `/admin/documents/${match.params.retailerId}?folder=${folderParts[0]}`;
        for (let j = 1; j <= i; j++) {
          url = `${url}/${folderParts[j]}`;
        }
        breadcrumbs.push({ url, key: i + 3, text: folderParts[i] });
      }
    }

    return breadcrumbs;
  };

  const navigateToSubFolder = (folder: string) => {
    setSelectedTags([]);
    navigateTo(`/admin/documents/${match.params.retailerId}?folder=${folder}`);
    loadData(true);
  };

  const loadData = (navigation: boolean = false) => {
    const queryString = QueryString.parse(window.location.search);
    const folderPath = queryString.folder as string || '';

    getFolderContents(
      {
        folderPath,
        retailerId: parseInt(match.params.retailerId, 10),
        prefix: window.location.pathname.indexOf('/admin') === 0 ? 'admin/' : '',
        folderType: FolderType.Document,
      }, navigation);
  };

  const downloadFile = (fileId: number) => {
    downloadDocument(
      {
        retailerId: parseInt(props.match.params.retailerId, 10),
        documentId: fileId,
        prefix: 'admin/',
        userAcceptance: false,
        folderType: FolderType.Document,
      },
      (data: IDocumentBlob) => {
        generateDownload(data.fileName, data.data);
      },
      () => {
        alert('Failure');
      });
  };

  const downloadUserAcceptanceReport = async (documentId: number) => {
    await downloadUserAcceptanceCsv(
      {
        documentId,
        retailerId: parseInt(match.params.retailerId, 10),
      });
  };

  const generateDownload = (filename: string, contents: string) => {
    GenerateDownload(contents, filename);
    clearUserAcceptanceCsvDownload();
  };

  const setPopupConfig = (document: IDocument) => {
    return {
      content: {
        actions: [
          ...(document.requiresAcceptance
            ? [{
              onClick: async (): Promise<void> => {
                await downloadUserAcceptanceReport(document.documentId);
              },
              label: {
                icon: 'stats-bars',
                text: 'User Acceptance Report',
              },
            }]
            : []),
          {
            onClick: (): void => {
              editFile(document.documentId);
            },
            label: {
              icon: '',
              text: 'Edit',
            },
          },
          {
            onClick: (): void => {
              downloadFile(document.documentId);
            },
            label: {
              icon: '',
              text: 'Download',
            },
          },
          {
            onClick: (): void => {
              deleteDocument(document.documentId);
            },
            label: {
              icon: '',
              text: 'Delete',
            },
          },
        ],
      },
    };
  };

  const breadcrumbs = getBreadcrumbs();

  return (
    <div className="AdminDocuments-container">
      <Breadcrumbs
        className="AdminDocuments-breadCrumbs"
        onHomeClick={() => navigateTo('/admin/home')}
        onRedirect={(route: string) => {
          navigateTo(route);
          loadData();
        }}
        segments={breadcrumbs}
      />
      <section className="AdminDocuments-headingSection">
        <h1 className="AdminDocuments-heading">DOCUMENTS</h1>
        <div className="AdminDocuments-headingActions">
          {!folderIsPresent()
            && <Button
              type="button"
              className="AdminDocuments-actionCreateFolder"
              text="Create Folder"
              onClick={
                () => navigateTo(`${match.params.retailerId}/create-folder?`
                  + `parent=${QueryString.parse(window.location.search).folder
                    ? QueryString.parse(window.location.search).folder : ''}`)}
            />}
          {folderIsPresent()
            && <Button
              type="button"
              className="AdminDocuments-actionCreateFolder"
              text="Update Folder"
              onClick={
                () => navigateTo(`${match.params.retailerId}/update-folder?`
                  + `name=${QueryString.parse(window.location.search).folder
                    ? QueryString.parse(window.location.search).folder : ''}`)}
            />}
          <Button
            type="button"
            className="AdminDocuments-actionUploadDocument"
            text="Upload Documents"
            onClick={
              () => navigateTo(`${match.params.retailerId}/upload?parent=`)}
          />
          {(folderIsPresent() && folderContents.data.folderId != null)
            && <Button
              type="button"
              className="printshop-action-delete-folder"
              text="Delete Folder"
              onClick={() => deleteFolder(folderContents.data.folderId)}
            />}
        </div>
      </section>
      <div className="AdminDocuments-contentContainer">
        <section className="AdminDocuments-filtersSection">
          {
            folderContents &&
            folderContents.data &&
            folderContents.data.folders.length > 0 &&
            <Card className="AdminDocuments-card AdminDocuments-foldersContent">
              <FolderSelect
                retailer={getRetailerName()}
                folders={folderContents.data.folders}
                onFolderClick={navigateToSubFolder}
                selectedFolder={QueryString.parse(window.location.search).folder}
                catalogue={folderContents.data.catalogue}
              />
            </Card>
          }
          {
            folderContents &&
            folderContents.data &&
            folderContents.data.folders.length === 0 &&
            <Card className="AdminDocuments-card AdminDocuments-foldersContent">
              <FolderSelect
                retailer={getRetailerName()}
                folders={[]}
                onFolderClick={navigateToSubFolder}
                selectedFolder={QueryString.parse(window.location.search).folder}
                catalogue={folderContents.data.catalogue}
              />
            </Card>
          }
          <Card className="AdminDocuments-card">
            <h3 className="AdminDocuments-filtersHeading AdminDocuments-filterByHeading">FILTER</h3>
            <TextInput
              className="search"
              name="documentSearch"
              type="text"
              label="Filter by name"
              value={search}
              onChange={(e: any) => setSearch(e)}
            />
            <div className="AdminDocuments-tagsHeading">
              <h4>BADGES</h4>
              {
                selectedTags.length > 0 &&
                <button
                  className="clear-filters"
                  onClick={clearAllFilters}
                  value="Clear filters"
                >
                  <span>(Clear all)</span>
                </button>
              }
            </div>
            {
              getAllTags().length < 1 &&

              <p>No badges found for current view.</p>
            }
            {
              getAllTags().sort().map((tag: string, index: number) => {
                return (
                  <Checkbox
                    className="AdminDocuments-filterByCheckbox"
                    checked={selectedTags.indexOf(tag) > -1}
                    key={index}
                    label={tag}
                    name={tag}
                    onChange={() => {
                      switchTagSelection(tag);
                    }}
                    isSmall={true}
                  />
                );
              })
            }
          </Card>
        </section>
        <div className="AdminDocuments-foldersContentsContainer">
          {
            folderContents &&
            folderContents.data &&
            getFilteredDocuments(selectedTags, search) &&
            getFilteredDocuments(selectedTags, search).length < 1 &&
            <EmptyDocumentsCard
              text="Upload Documents"
              icon="icon-text-file"
              url={`${match.params.retailerId}/upload?parent=`}
              isAdmin={isAdmin(userDetails)}
            />
          }
          {
            folderContents &&
            folderContents.data &&
            getFilteredDocuments(selectedTags, search) &&
            getFilteredDocuments(selectedTags, search).length > 0 &&
            <Table className="AdminDocuments-list">
              <thead>
                <tr>
                  <th className="Table--stickyColumn">Document Name</th>
                  <th>Badges</th>
                  <th className="Table--fixedColumn">Last Modified</th>
                  <th className="Table--fixedColumn">Owner</th>
                  <th className="Table--fixedColumn tableActions">Actions</th>
                </tr>
              </thead>
              <tbody>
                {getFilteredDocuments(selectedTags, search)
                  .map((document: IDocument, index: number) => {
                    return (
                      <tr key={document.documentId}>
                        <td className="Table--stickyColumn">
                          <span className={`icon ${getFileIcon(document.mimeType).className}`} />{document.description}
                        </td>
                        <td>{document.tags.join(', ')}</td>
                        <td>{new Date(document.lastEditDate).toLocaleDateString()}</td>
                        <td>
                          {document.createdByUser &&
                            `${document.createdByUser.firstname} ${document.createdByUser.lastname}`}
                        </td>
                        <td className="Table--actions">
                          {
                            setPopupConfig(document).content.actions
                              .map((action: IPopupPropsConfigContentAction, index: number): JSX.Element => {
                                return (
                                  <div className="tableButtons" key={index} >
                                    <button
                                      className="Popup-button"
                                      name="popupButton"
                                      onClick={
                                        (): void => {
                                          action.onClick();
                                        }
                                      }
                                    >
                                      {<span
                                        className={`Popup-buttonIcon icon-${action.label.icon || action.label.text}`}
                                        title={action.label.text}
                                      />
                                      }
                                    </button>
                                  </div>
                                );
                              },
                              )
                          }
                        </td>
                      </tr>
                    );
                  })
                }
              </tbody>
            </Table>
          }
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state: IStoreState) => {
  return {
    folderContents: state.documentsFolderContents,
    downloadedDocument: state.documentsDownloadBlob,
    userDetails: state.userDetails,
    deletedDocument: state.documentsDeleteDocument,
    retailers: state.retailers,
    userAcceptanceCsvDownload: state.userAcceptance,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<any>) => {
  return {
    getFolderContents: (parameters: IGetFolderContentsParams, navigation: boolean) =>
      dispatch(getFolderContentsRequest(parameters, navigation)),
    downloadDocument: (parameters: IDownloadDocumentBlobParams, onSuccess: (data: IDocumentBlob) => void,
      onFailure: () => void) => dispatch(downloadDocumentBlobRequest(parameters, onSuccess, onFailure)),
    deleteDocument: (parameters: IDeleteDocumentParams, onSuccess: () => void, onFailure: () => void) =>
      dispatch(deleteDocumentRequest(parameters, onSuccess, onFailure)),
    deleteFolder: (parameters: IDeleteFolderParams, onSuccess: () => void, onFailure: () => void) =>
      dispatch(deleteFolderRequest(parameters, onSuccess, onFailure)),
    clearFolderContents: () => dispatch(clearFolderContents()),
    getRetailers: () => dispatch(getRetailersRequest()),
    downloadUserAcceptanceCsv: (parameters: IUserAcceptanceParams) => dispatch(getUserAcceptanceReportRequest(parameters)),
    clearUserAcceptanceCsvDownload: () => dispatch(resetUserAcceptanceReportData()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AdminDocuments);
