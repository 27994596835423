import { getRetailerDetails } from '../api/Retailers/retailersApi';
import { IGetRetailerDetailsResponse } from '../api/Retailers/IGetRetailerDetailsResponse';
import { AxiosResponse } from 'axios';
import { Dispatch } from 'redux';

export default (): any => {
  return (dispatch : any) => {

    dispatch({
      type: 'GET_RETAILER_DETAILS_REQUEST',
    });

    return getRetailerDetails()
      .then((response : AxiosResponse<IGetRetailerDetailsResponse>) => {
        if (response.status === 200) {

          dispatch({
            type: 'GET_RETAILER_DETAILS_RESPONSE',
            response: response.data.retailerDetails,
          });

        } else {
          if (response instanceof Error) {
            onError(dispatch, response);
          }
        }
      });
  };
};

const onError = (dispatch: Dispatch, error: Error) => dispatch({
  error,
  type: 'GET_RETAILER_DETAILS_ERROR',
});
