import React from 'react';
import Table from '../../../../../components/Table/Table';
import { Button } from '@orderly/morrisons-component-library';
import { formatDateString } from '../../../../../helpers/Format';
import { navigateTo } from '../../../../../routing/Navigation';
import { BannerLocations, IBanner } from '../../../../../types';
import { IBannerTableProps } from './IBannerTableProps';
import { PriorityUp } from 'iconoir-react';
import { deleteBanner } from '../../../../../api/Banners/bannersApi';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { toastr } from 'react-redux-toastr';
import { QUERY_KEYS } from '../../../../../constants';
import styles from './BannerTable.module.scss';

function BannerTable({
  tabId,
  data,
}: Readonly<IBannerTableProps>): JSX.Element {
  const queryClient = useQueryClient();
  const mutation = useMutation<void, Error, number>(deleteBanner, {
    onSuccess: () => {
      queryClient.invalidateQueries([QUERY_KEYS.ADMIN_BANNERS]);
      toastr.success('Banner Deleted', 'Banner has been successfully deleted.');
    },
    onError: (error: Error): void => {
      toastr.error('Deletion Failed', error.message);
    },
  });

  const handleDeleteClick = (bannerId: number) => {
    const toastrConfirmOptions = {
      onOk: () => mutation.mutate(bannerId),
      onCancel: () => { },
    };
    toastr.confirm('Are you sure you want to delete this banner?', toastrConfirmOptions);
  };

  return (
    <Table className={styles.table}>
      <thead>
        <tr>
          <th className={styles.center} title="Banner Order"><PriorityUp /></th>
          <th className={styles.preview}>Preview</th>
          <th>Location</th>
          <th>Title</th>
          <th>Customer Groups</th>
          <th>Start Date</th>
          <th>End Date</th>
          <th>Last Updated</th>
          <th />
        </tr>
      </thead>
      <tbody>
        {
          data.length === 0 ?
            <tr>
              <td colSpan={8}>
                No banners found
              </td>
            </tr> :
            data.map((banner: IBanner) => (
              <tr key={`${tabId}_${banner.id}`}>
                <td className={styles.center}>
                  <strong>{banner.order}</strong>
                </td>
                <td className={styles.preview}>
                  <img src={banner.imageUrl} title={banner.title} alt={banner.description} />
                </td>
                <td>{BannerLocations[banner.displayLocation]}</td>
                <td className={banner.active ? styles.active : null}>
                  {banner.title}
                </td>
                <td>
                  {banner.allRetailers ? 'All retailers' : banner.customerGroups.join(', ')}
                </td>
                <td>
                  {formatDateString(banner.startDate)}
                </td>
                <td>
                  {formatDateString(banner.endDate)}
                </td>
                <td>
                  {formatDateString(banner.modifiedDate)}
                </td>
                <td className={styles.actions}>
                  <span title="Edit Banner">
                    <Button
                      className=""
                      icon="icon-Edit"
                      type="button"
                      text=""
                      onClick={() => navigateTo(`/admin/manage-banners/${banner.id}`)}
                    />
                  </span>
                  <span title="Delete Banner">
                    <Button
                      className=""
                      icon="icon-Delete"
                      type="button"
                      text=""
                      onClick={() => handleDeleteClick(banner.id)}
                      disabled={mutation.isLoading}
                    />
                  </span>
                </td>
              </tr>
            ))
        }
      </tbody>
    </Table>
  );
}

export default BannerTable;
