import Fetch from '../../../api/apiConfig/apiConfig';
import { AxiosPromise } from 'axios';
import queryString from 'query-string';
import { IOrderImportProductsCsv } from '../../../types';

export interface IGetOrderImportCsvParams {
  [key: string]: any;
  categoryId: number;
  shoppingListId: number;
}

export interface IGetOrderImportCsvResponse {
  ordercsv: IOrderImportProductsCsv;
}

export const getOrderImportCsv = (params: IGetOrderImportCsvParams) : AxiosPromise<IGetOrderImportCsvResponse> => {
  return Fetch().then(axios => axios.get(`orders/csv/get?${queryString.stringify(params)}`));
};
