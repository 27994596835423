import IConfigSettings from './api/apiConfig/IConfigSettings';

const configSettings = () : IConfigSettings => {
  const settings = sessionStorage.getItem('settings.apiConfig');

  if (settings === null) {
    return {
      RecaptchaEnabled: false,
      RecaptchaKey: 'undefined',
      GoogleOAuthKey: undefined,
    };
  }

  return JSON.parse(settings);
};

export default configSettings;
