import { IDocumentBlob } from '../../types';
import {
  IDownloadStoreDocumentBlobParams,
  downloadStoreDocumentBlob,
  IDownloadStoreDocumentBlobResponse,
} from '../../api/StoreDocuments/downloadStoreDocumentBlobApi';
import { AxiosResponse } from 'axios';
import {
  GET_STORE_DOCUMENT_BLOB_ERROR,
  GET_STORE_DOCUMENT_BLOB_REQUEST,
  GET_STORE_DOCUMENT_BLOB_RESPONSE,
} from './downloadStoreDocumentAdminBlobAction';

export const downloadStoreDocumentBlobRequest = (parameters: IDownloadStoreDocumentBlobParams,
  onSuccess: (data: IDocumentBlob) => void,
  onFailure: () => void): any => {
  return (dispatch: any) => {
    dispatch({
      type: GET_STORE_DOCUMENT_BLOB_REQUEST,
    });

    return downloadStoreDocumentBlob(parameters)
      .then((response: AxiosResponse<IDownloadStoreDocumentBlobResponse>) => {
        if (response.status === 200) {
          dispatch({
            type: GET_STORE_DOCUMENT_BLOB_RESPONSE,
            response: response.data.document,
          });
          onSuccess(response.data.document);
        } else {
          onError(dispatch);
          onFailure();
        }
      })
      .catch((err: any) => {
        console.log(err);
        onError(dispatch);
        onFailure();
      });
  };
};

const onError = (dispatch: any) => dispatch({
  type: GET_STORE_DOCUMENT_BLOB_ERROR,
});
