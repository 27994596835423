import Fetch from './apiConfig/apiConfig';
import { AxiosPromise } from 'axios';
import { ISearchResult } from '../types';

export interface ISearchResponse {
  results: ISearchResult;
}

export const getSearchResults =
(searchTerm: string, startIndex: number, pageSize: number): AxiosPromise<ISearchResponse> => {
  return Fetch().then(
    axios => axios.get(`products/search?searchPhrase=${searchTerm}&startIndex=${startIndex}&pageSize=${pageSize}`),
  );
};

export const getSearchResultsAdmin = (
  searchTerm: string,
  startIndex: number,
  pageSize: number,
  retailerId: number,
  categoryCode: string,
): AxiosPromise<ISearchResponse> => {
  return Fetch().then(
    axios => axios.get(`admin/products/search?searchPhrase=${searchTerm}&startIndex=${startIndex}` +
    `&pageSize=${pageSize}&retailerId=${retailerId}&categoryCode=${categoryCode}`),
  );
};
