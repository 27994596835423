import React from 'react';
import { ConnectedComponentClass, connect } from 'react-redux';
import { IStoreState, IUserDetails } from '../types';
import { Redirect, Route } from 'react-router-dom';
import { isLogisticsTeam } from '../helpers/Users/UserHelper';

interface IPrivateRouteParams {
  Component: ConnectedComponentClass<any, any>;
  userDetails: IUserDetails;
}

const LogisticsTeamRoute = ({ component: Component, userDetails, ...rest }: IPrivateRouteParams & any) => (
  <Route
    {...rest}
    render={props =>
        isLogisticsTeam(userDetails) ? (
          <Component {...props} />
        ) : (
            <Redirect
              to={{
                pathname: '/admin/login',
                state: { from: props.location },
              }}
            />
        )
    }
  />
);

const mapStateToProps = (state: IStoreState) => {
  return {
    userDetails: state.userDetails,
  };
};

export default connect(mapStateToProps)(LogisticsTeamRoute);
