import { Reducer } from 'redux';
import { IApiRequest, IOrderClaimReview } from '../../types';
import {
  GET_ORDER_CLAIMS_REQUEST,
  GET_ORDER_CLAIMS_RESPONSE,
  GET_ORDER_CLAIMS_ERROR,
} from '../../actions/Orders/OrderClaims/orderClaimsAction';

const initialState: IApiRequest<IOrderClaimReview[]> = {
  data: null,
  error: '',
  UpdatedAt: null,
  loading: false,
};

const reviewOrderClaimsReducer: Reducer<IApiRequest<IOrderClaimReview[]>> =
  (state: IApiRequest<IOrderClaimReview[]> = initialState, action: any) => {
    switch (action.type) {
      case GET_ORDER_CLAIMS_RESPONSE: {
        return {
          ...initialState,
          loading: false,
          data: action.response,
        };
      }
      case GET_ORDER_CLAIMS_REQUEST: {
        return {
          ...initialState,
          loading: true,
        };
      }
      case GET_ORDER_CLAIMS_ERROR: {
        return {
          ...initialState,
          error: action.response || 'There was an error retrieving order claims. Please try again',
        };
      }
      default: {
        return state;
      }
    }
  };

export default reviewOrderClaimsReducer;
