import { Action } from 'redux';
import { IGetTopOffersParams, getTopOffers, ITopOffersResponse } from '../api/productsApi';
import { IStoreState } from '../types';
import { store } from '../store';
import { AxiosResponse } from 'axios';

export const GET_TOP_OFFERS_REQUEST = 'GET_TOP_OFFERS_REQUEST';
export type GET_TOP_OFFERS_REQUEST = typeof GET_TOP_OFFERS_REQUEST;
export const GET_TOP_OFFERS_RESPONSE = 'GET_TOP_OFFERS_RESPONSE';
export type GET_TOP_OFFERS_RESPONSE = typeof GET_TOP_OFFERS_RESPONSE;
export const GET_TOP_OFFERS_ERROR = 'GET_TOP_OFFERS_ERROR';
export type GET_TOP_OFFERS_ERROR = typeof GET_TOP_OFFERS_ERROR;

export interface IGetTopOffersResponse extends Action {
  type: GET_TOP_OFFERS_RESPONSE;
  response: ITopOffersResponse;
}

export interface IGetTopOffersError extends Action {
  type: GET_TOP_OFFERS_ERROR;
  error?: string;
}

export const getTopOffersRequest = (parameters: IGetTopOffersParams): any => {
  const state : IStoreState = store.getState();
  const topOffers = state.topOffers[parameters.categoryId];

  if (topOffers !== undefined
    && state.catalogueMeta.meta.updatedAt < topOffers.UpdatedAt) {
    return {
      type: GET_TOP_OFFERS_RESPONSE,
      response: { [parameters.categoryId]: topOffers },
    };
  }

  return (dispatch : any) => {
    dispatch({
      type: GET_TOP_OFFERS_REQUEST,
    });

    return getTopOffers(parameters)
      .then((response: AxiosResponse<ITopOffersResponse>) => {
        if (response.status === 200) {

          dispatch({
            type: GET_TOP_OFFERS_RESPONSE,
            response: {
              [parameters.categoryId]: {
                data: response.data.topOffers,
                UpdatedAt: new Date(),
                loading: false,
                error: '',
              },
            },
          });

        } else {
          onError(dispatch);
        }
      })
      .catch((err: any) => {
        console.log(err);
        onError(dispatch);
      });
  };
};

const onError = (dispatch: any) => dispatch({
  type: GET_TOP_OFFERS_ERROR,
});
