import Fetch from '../apiConfig/apiConfig';
import { AxiosPromise } from 'axios';

export interface IWatchFolderParams {
  folderId: number;
  userId: number;
  watch: boolean;
}

export interface IWatchFolderResponse {
  success: string;
}

export const watchFolder = (params: IWatchFolderParams) : AxiosPromise<IWatchFolderResponse> => {
  return Fetch().then(axios => axios.post('api/documents/watchfolder', params));
};
