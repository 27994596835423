import { Reducer } from 'redux';
import { IApiRequest, IFolderContents } from '../../types/index';
import {
  GET_FOLDER_CONTENTS,
  GET_FOLDER_CONTENTS_RESPONSE,
  GET_FOLDER_CONTENTS_ERROR,
  CLEAR_FOLDER_CONTENTS,
} from '../../actions/Documents/folderContentsAction';
import { WATCH_FOLDER_REQUEST } from '../../actions/Documents/watchFolderAction';

const initialState: IApiRequest<IFolderContents> = {
  data: {
    folderId: 0,
    parentFolderId: null,
    catalogue: '',
    documents: [],
    folders: [],
    users: [],
    retailerSubTypes: [],
    customerGroups: [],
    expiryDate: null,
  },
  error: '',
  UpdatedAt: null,
  loading: false,
};

const getFolderContentsReducer: Reducer<IApiRequest<IFolderContents>>
= (state: IApiRequest<IFolderContents> = initialState, action : any) => {
  switch (action.type) {
    case GET_FOLDER_CONTENTS_RESPONSE: {
      const documents = action.response.data.documents;
      const users = action.response.data.users;
      let oldFolders: any = [];
      let oldDocuments: any = [];

      if (state.data && state.data.documents) {
        oldDocuments = state.data.documents;
      }
      if (state.data && state.data.folders) {
        oldFolders = state.data.folders;
      }
      let newFolders = action.response.data.folders;
      if (newFolders.length === 0 && !action.response.useFolders) {
        newFolders = oldFolders;
      }
      return {
        data: {
          users,
          folderId: action.response.data.folderId,
          description: action.response.data.description,
          documents: action.response.useDocuments ? documents : oldDocuments,
          folders: action.response.useFolders ? newFolders : oldFolders,
          catalogue: action.response.data.catalogue,
          parentFolderId: action.response.data.parentFolderId,
          expiryDate: action.response.data.expiryDate,
          retailerSubTypes: action.response.data.retailerSubTypes,
          customerGroups: action.response.data.customerGroups,
        },
        error: '',
        UpdatedAt: new Date(),
        loading: false,
      };
    }
    case CLEAR_FOLDER_CONTENTS: {
      return initialState;
    }
    case GET_FOLDER_CONTENTS: {
      let oldFolders: any = [];
      if (state.data && state.data.folders) {
        oldFolders = state.data.folders;
      }
      let oldDocuments: any = undefined;
      if (!action.getFoldersOnly && state.data && state.data.documents) {
        oldDocuments = state.data.documents;
      }
      let catalogue = '';

      if (state.data) {
        catalogue = state.data.catalogue;
      }
      return {
        data: {
          catalogue,
          folderId: 0,
          description: state.data.description,
          parentFolderId: null,
          documents: oldDocuments,
          users: undefined,
          folders: oldFolders,
          retailerSubTypes: [],
          customerGroups: [],
          expiryDate: null,
        },
        error: '',
        UpdatedAt: null,
        loading: true,
      };
    }
    case WATCH_FOLDER_REQUEST: {
      const { folders } = state.data;

      const watchFolder = folders.find(x => x.folderId === action.payload.folderId);
      if (watchFolder !== undefined) {
        const existingUser = watchFolder.users.findIndex(x => x.id === action.payload.userId);

        if (existingUser === -1) {
          watchFolder.users.push({
            id: action.payload.userId,
            emailAddress: '',
            firstname: '',
            lastname: '',
          });
        } else {
          watchFolder.users.splice(existingUser, 1);
        }
      }

      return {
        ...state,
        data: {
          ...state.data,
          folders,
        },
      };
    }
    case GET_FOLDER_CONTENTS_ERROR:
      return {
        data: state.data,
        error: 'Something went wrong while loading folder content',
        loading: false,
        UpdatedAt: new Date(),
      };
    default: {
      return state;
    }
  }
};

export default getFolderContentsReducer;
