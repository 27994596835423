import React, { useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import { Card, Button } from '@orderly/morrisons-component-library';
import { toastr } from 'react-redux-toastr';

import './FileDropzone.scss';
import { MAX_FILE_UPLOAD_SIZE } from '../../helpers/Documents/DocumentUploadHelper';

interface IProps {
  onFileUpload: Function;
  disabled?: boolean;
  fileTypes?: string | string[];
}

const FileDropzone = (props: IProps) => {
  const acceptedFileTypes: string | string[] = props.fileTypes != null ? props.fileTypes : ['application/pdf'];

  const onDrop = useCallback(
      (acceptedFiles: File[]) => {
        const validFiles: File[] = [];
        acceptedFiles.forEach((file: File) => {
          const size = file.size;

          if (size > MAX_FILE_UPLOAD_SIZE) { // Check file size is less that 100 megabytes
            toastr.error(
              'File Size Error', `${file.name}: We support a maximum file size of: ${MAX_FILE_UPLOAD_SIZE / 1000000}MB`);
          } else {
            validFiles.push(file);
          }
        });
        if (props && props.onFileUpload) {
          props.onFileUpload(validFiles);
        }
      },
      [],
  );
  const { getRootProps, getInputProps } = useDropzone({ onDrop, accept: acceptedFileTypes });

  return (
    <div {...getRootProps()} className={`outer-documents-upload ${props.disabled ? 'disabled' : ''}`}>
      {
        !props.disabled && <input {...getInputProps()} />}
      {
        <div className="upload-documents">
          <Card className="upload-card">
            <span className="icon-cloud-upload" />
            <Button
              type="button"
              className="button"
              text="Choose files to upload"
              disabled={props.disabled}
            />
            <p>or drag and drop them here</p>
          </Card>
        </div>
      }
    </div>
  );
};

export default FileDropzone;
