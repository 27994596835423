import { IApiRequest, IBasket } from '../../types';

export const requireNewSlot = (basket: IApiRequest<IBasket>) : boolean => {
  if (!basket.data || basket.data === null) {
    return true;
  }

  const lockAt = new Date(basket.data.lockAt);
  // Order cut-off has passed
  if (lockAt.getTime() - new Date().getTime() <= 0) {
    return true;
  }

  return false;
};
