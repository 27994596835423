import { Action } from 'redux';
import {
  postResetPassword,
  IResetPasswordResponse as IResetPasswordResponseAPI,
} from '../../api/MyAccount/resetPasswordApi';
import { AxiosResponse } from 'axios';
import { toastr } from 'react-redux-toastr';

export const POST_RESET_PASSWORD_REQUEST = 'POST_RESET_PASSWORD_REQUEST';
export type POST_RESET_PASSWORD_REQUEST = typeof POST_RESET_PASSWORD_REQUEST;
export const POST_RESET_PASSWORD_RESPONSE = 'POST_RESET_PASSWORD_RESPONSE';
export type POST_RESET_PASSWORD_RESPONSE = typeof POST_RESET_PASSWORD_RESPONSE;
export const POST_RESET_PASSWORD_ERROR = 'GET_RESET_PASSWORD_ERROR ';
export type POST_RESET_PASSWORD_ERROR  = typeof POST_RESET_PASSWORD_ERROR ;

export interface IPostResetPasswordResponse extends Action {
  type: POST_RESET_PASSWORD_RESPONSE;
  response: IResetPasswordResponseAPI;
}

export interface IPostResetPasswordError extends Action {
  type: POST_RESET_PASSWORD_ERROR;
  error?: string;
}

export const postResetPasswordRequest = (): any => {
  return (dispatch : any) => {
    dispatch({
      type: POST_RESET_PASSWORD_REQUEST,
    });

    return postResetPassword()
      .then((response: AxiosResponse<IResetPasswordResponseAPI>) => {
        if (response.status === 200) {

          toastr.success(
            'Email sent',
            'An email has been sent to the specified account with instructions to reset the password.');

          dispatch({
            type: POST_RESET_PASSWORD_RESPONSE,
          });

        } else {
          onError(dispatch);
        }
      })
      .catch((err: any) => {
        console.log(err);
        onError(dispatch);
      });
  };
};

const onError = (dispatch: any) => dispatch({
  type: POST_RESET_PASSWORD_ERROR,
});
