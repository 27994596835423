import qs from 'query-string';

const getQueryString = (key: string, url: string = window.location.href) : string => {
  const queryString = qs.parseUrl(url);

  return queryString.query[key] as string;
};

const constructURLParams = (param: string, value: string, url: string = window.location.href): string => {
  const queryIndex = url.indexOf('?');
  const queryString = queryIndex !== -1 ? url.substring(queryIndex) : '';

  const queryParams = new URLSearchParams(queryString);
  queryParams.set(param, value);

  return queryParams.toString();
};

export {
  getQueryString,
  constructURLParams,
};
