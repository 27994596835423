/* eslint-disable no-use-before-define */
import Fetch from '../apiConfig/apiConfig';
import { AxiosPromise } from 'axios';
import { FolderType, IDocument } from '../../types';

export interface IUploadDocumentParams {
  retailerId: number;
  folderPath: string;
  customerGroups: string[];
  files: IUploadDocumentDetails[];
  tags: string[];
  notificationUsers?: number[];
  requiresAcceptance: boolean;
  folderType: FolderType;
}

export interface IUploadDocumentDetails {
  name: string;
  type: string;
  data: string;
}

export interface IUploadDocumentResponse {
  uploaded: IDocument;
}

export const uploadDocument = (params: IUploadDocumentParams) : AxiosPromise<IUploadDocumentResponse> => {
  return Fetch().then(axios => axios.post('documents/upload', params));
};
