import React, { Dispatch } from 'react';
import { navigateTo } from '../../routing/Navigation';
import { Card, Checkbox, Breadcrumbs, BreadcrumbSegment, TextInput } from '@orderly/morrisons-component-library';
import QueryString from 'query-string';
import Table from '../../components/Table/Table';
import { connect } from 'react-redux';

import './Documents.scss';
import { IStoreState, IFolder, IDocument, IDocumentBlob, IUserDetails, FolderType } from '../../types';
import { IGetFolderContentsParams } from '../../api/Documents/getFolderContentsApi';
import { getFolderContentsRequest } from '../../actions/Documents/folderContentsAction';
import getRetailerName from '../../actions/retailerDetailAction';
import { IDownloadDocumentBlobParams } from '../../api/Documents/downloadDocumentBlobApi';
import { downloadDocumentBlobRequest } from '../../actions/Documents/downloadDocumentBlobAction';
import { IDocumentsProps } from './IDocumentsProps';
import { IDocumentsState } from './IDocumentsState';
import EmptyDocumentsCard from '../../components/EmptyDocumentsCard/EmptyDocumentsCard';
import FolderSelect from '../../components/FolderSelect/FolderSelect';
import { getFileIcon } from '../../helpers/Documents/DocumentUploadHelper';
import { IWatchFolderParams } from '../../api/Documents/watchFolderApi';
import { watchFolderRequest } from '../../actions/Documents/watchFolderAction';
import MobileFiltersAction from '../../components/MobileFiltersAction/MobileFiltersAction';
import MobileFiltersActionHelper from '../../components/MobileFiltersAction/helper/MobileFiltersActionHelper';
import Modal from '../../components/Modal/Modal';
import { GenerateDownload } from '../../helpers/DownloadHelper';

class Documents extends React.Component<IDocumentsProps, IDocumentsState> {

  public state: IDocumentsState = {
    selectedTags: [] as string[],
    folder: undefined,
    search: '',
    showMobileFilters: false,
    showDocumentAcceptanceModal: false,
    downloadDocument: null,
  };

  constructor(props: any) {
    super(props);
    this.navigateToSubFolder = this.navigateToSubFolder.bind(this);
    this.toggleMobileFilters = this.toggleMobileFilters.bind(this);
    this.handleTextInputChange = this.handleTextInputChange.bind(this);
  }

  public componentDidMount(): void {
    this.loadData();
    this.props.getRetailerName();
  }

  public componentDidUpdate(): void {
    const folder = QueryString.parse(window.location.search).folder as string;
    if (this.state.folder !== folder) {
      this.setState({ folder });
      this.loadData();
    }
  }

  public render(): JSX.Element {
    const breadcrumbs = this.getBreadcrumbs();

    return (
      <div className="Documents-container">
        <Breadcrumbs
          className="Documents-breadCrumbs"
          onHomeClick={() => navigateTo('/home')}
          onRedirect={(route: string) => {
            navigateTo(route);
            this.loadData();
          }}
          segments={breadcrumbs}
        />
        <section className="Documents-headingSection">
          <h1 className="Documents-heading">DOCUMENTS</h1>
        </section>
        <div className="Documents-contentContainer">
          <section className="Documents-filtersSection">
            {
              this.props.folderContents &&
              this.props.folderContents.data &&
              this.props.folderContents.data.folders.length > 0 &&
              <Card className="Documents-card Documents-foldersContent">
                <FolderSelect
                  retailer={this.getRetailerName()}
                  folders={this.props.folderContents.data.folders}
                  onFolderClick={this.navigateToSubFolder}
                  selectedFolder={QueryString.parse(window.location.search).folder}
                  onWatchClick={(folderId: number, folder: IFolder, userDetails: IUserDetails) =>
                    this.watchFolder(folderId, folder, userDetails)}
                  userDetails={this.props.userDetails}
                  catalogue={this.props.folderContents.data.catalogue}
                />
              </Card>
            }
            {
              this.props.folderContents &&
              this.props.folderContents.data &&
              this.props.folderContents.data.folders.length === 0 &&
              <Card className="Documents-card Documents-foldersContent">
                <FolderSelect
                  retailer={this.getRetailerName()}
                  folders={[]}
                  onFolderClick={this.navigateToSubFolder}
                  selectedFolder={QueryString.parse(window.location.search).folder}
                  onWatchClick={null}
                  catalogue={this.props.folderContents.data.catalogue}
                />
              </Card>
            }
            <Card
              className={`Documents-card Documents-filters ${this.state.showMobileFilters
                ? 'Documents--showMobileFilters' : ''}`}
            >
              <h3 className="Documents-filtersHeading Documents-filterByHeading">FILTER</h3>
              <TextInput
                className="search"
                name="documentSearch"
                type="text"
                label="Filter by name"
                value={this.state.search}
                onChange={this.handleTextInputChange}
              />
              <div className="Documents-tagsHeading">
                <h4>BADGES</h4>
                {
                  this.state.selectedTags.length > 0 &&
                  <button
                    className="clear-filters"
                    onClick={this.clearAllFilters}
                    value="Clear filters"
                  >
                    <span>(Clear all)</span>
                  </button>
                }
              </div>
              {
                this.getAllTags().length < 1 &&

                <p>No badges found for current view.</p>
              }
              {
                this.getAllTags().sort().map((tag: string, index: number) => {
                  return (
                    <Checkbox
                      checked={this.state.selectedTags.indexOf(tag) > -1}
                      key={index}
                      label={tag}
                      name={tag}
                      onChange={() => {
                        this.switchTagSelection(tag);
                      }}
                      isSmall={true}
                    />
                  );
                })
              }
            </Card>
          </section>
          <div className="Documents-foldersContentsContainer">
            {
              this.props.folderContents &&
              this.props.folderContents.data &&
              this.getFilteredDocuments(this.state.selectedTags, this.state.search) &&
              this.getFilteredDocuments(this.state.selectedTags, this.state.search).length < 1 &&
              <EmptyDocumentsCard
                icon="icon-text-file"
                isAdmin={false}
                text=""
                url=""
              />
            }
            {
              this.props.folderContents &&
              this.props.folderContents.data &&
              this.getFilteredDocuments(this.state.selectedTags, this.state.search) &&
              this.getFilteredDocuments(this.state.selectedTags, this.state.search).length > 0 &&
              <Table className="Documents-list">
                <thead>
                  <tr>
                    <th className="Table--stickyColumn">Document Name</th>
                    <th>Badges</th>
                    <th className="Table--fixedColumn">Last Modified</th>
                    <th className="Table--fixedColumn">Owner</th>
                    <th className="Table--fixedColumn tableActions">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    this.getFilteredDocuments(this.state.selectedTags, this.state.search)
                      .map((document: IDocument, index: number) => {
                        return (
                          <tr key={index}>
                            <td className="Table--stickyColumn">
                              <span className={`icon ${getFileIcon(document.mimeType).className}`} /> {document.description}
                            </td>
                            <td>{document.tags.join(', ')}</td>
                            <td>{new Date(document.lastEditDate).toLocaleDateString()}</td>
                            <td>
                              {document.createdByUser &&
                                `${document.createdByUser.firstname} ${document.createdByUser.lastname}`}
                            </td>
                            <td>
                              <button
                                className="Table-Action"
                                onClick={
                                  (): void => {
                                    document.requiresAcceptanceByUser
                                      ? this.setState({
                                        showDocumentAcceptanceModal: true,
                                        downloadDocument: document,
                                      })
                                      : this.downloadFile(document, false);
                                  }
                                }
                              >
                                <span
                                  className="icon icon-Download"
                                  title="Download"
                                />
                              </button>
                            </td>
                          </tr>);
                      })
                  }
                </tbody>
              </Table>
            }
          </div>
        </div>
        <MobileFiltersAction
          config={
            {
              onClick: this.toggleMobileFilters,
              showFilters: this.state.showMobileFilters,
            }
          }
        />
        <Modal
          header="Confirmation Required"
          buttonText="Accept"
          isOpen={this.state.showDocumentAcceptanceModal}
          onClose={() => {
            this.downloadFile(this.state.downloadDocument, true);
            this.state.downloadDocument.requiresAcceptanceByUser = false;
            this.setState({
              showDocumentAcceptanceModal: false,
              downloadDocument: null,
            });
          }}
          onCancel={() => {
            this.setState({
              showDocumentAcceptanceModal: false,
              downloadDocument: null,
            });
          }}
        >
          <p>
            Morrisons has requested that you confirm download and receipt of this document -
            please can you accept below that you have downloaded the information to the agreed
            timelines, and that all necessary actions will be completed using this document.
          </p>
        </Modal>
      </div>
    );
  }

  private watchFolder(folderId: number, folder: IFolder, userDetails: IUserDetails): void {
    const watch = folder.users.find(x => x.id === userDetails.userId) === undefined;

    this.props.postWatchFolder({
      folderId,
      watch,
      userId: userDetails.userId,
    });
  }

  private switchTagSelection(tag: string): void {
    if (this.state.selectedTags.indexOf(tag) > -1) {
      this.setState({
        selectedTags: this.state.selectedTags.filter((t: string) => t !== tag),
      });
    } else {
      this.setState({
        selectedTags: [...this.state.selectedTags, tag],
      });
    }
  }

  private clearAllFilters = () => {
    this.setState({ selectedTags: [] });
  }

  private getFilteredDocuments(tags: string[], search: string): IDocument[] {
    let filteredDocs: IDocument[] = [];

    if (this.props.folderContents &&
      this.props.folderContents.data &&
      this.props.folderContents.data.documents &&
      this.props.folderContents.data.documents.length > 0) {
      filteredDocs = this.props.folderContents.data.documents.filter((document: IDocument) => {
        let showDocument = true;
        tags.forEach((tag: string) => {
          showDocument = showDocument && document.tags.indexOf(tag) > -1;
        });
        return showDocument && document.description.toLowerCase().indexOf(search.toLowerCase()) > -1;
      });
    }

    MobileFiltersActionHelper.isApplyFilters = tags.length > 0 || search.length > 0;

    return filteredDocs;
  }

  private getAllTags(): string[] {
    if (this.props
      && this.props.folderContents
      && this.props.folderContents.data
      && this.props.folderContents.data.documents
      && this.props.folderContents.data.documents.length > 0) {
      const tagsOfDocsArray = this.props.folderContents.data.documents
        .map((doc: IDocument) => doc.tags);
      const allTags = ([] as string[]).concat.apply([], tagsOfDocsArray);
      const unique = [...new Set(allTags)];
      return unique;
    }

    return [];
  }

  private getRetailerName(): string {
    if (this.props.retailerName.loading === false && this.props.retailerName.data) {
      return this.props.retailerName.data.externalIdentifier;
    }
    return '';
  }

  private getBreadcrumbs(): BreadcrumbSegment[] {
    const queryString = QueryString.parse(window.location.search);
    const folderPath: string = queryString.folder as string;

    const breadcrumbs: BreadcrumbSegment[] = [
      { key: 0, text: '...', url: '/documents' },
    ];

    if (this.props.folderContents.data
      && this.props.folderContents.data.catalogue) {
      breadcrumbs[0].text = this.props.folderContents.data.catalogue;
    }

    if (folderPath) {
      const folderParts = folderPath.split('/');

      for (let i = 0; i < folderParts.length; i++) {
        let url = `/documents?folder=${folderParts[0]}`;
        for (let j = 1; j <= i; j++) {
          url = `${url}/${folderParts[j]}`;
        }
        breadcrumbs.push({ url, key: i + 1, text: folderParts[i] });
      }
    }

    return breadcrumbs;
  }

  private navigateToSubFolder(folder: string): void {
    this.setState({ selectedTags: [] });

    navigateTo(`/documents?folder=${folder}`);
    this.loadData();
  }

  private loadData(): void {
    const queryString = QueryString.parse(window.location.search);
    const folderPath: string = queryString.folder as string || '';
    this.props.getFolderContents(
      {
        folderPath,
        retailerId: this.props.userDetails.retailerId,
        prefix: '',
        folderType: FolderType.Document,
      });
  }

  private downloadFile(document: IDocument, userAcceptance: boolean): void {
    this.props.downloadDocument(
      {
        userAcceptance,
        retailerId: this.props.userDetails.retailerId,
        documentId: document.documentId,
        prefix: '',
        folderType: FolderType.Document,
      },
      (data: IDocumentBlob) => {
        this.generateDownload(data.fileName, data.data, data.mimeType);
      },
      () => {
        alert('Failure');
      },
    );
  }

  private generateDownload(filename: string, contents: string, mimeType: string): void {
    GenerateDownload(contents, filename);
  }

  private toggleMobileFilters(): void {
    this.setState((state: IDocumentsState): Pick<IDocumentsState, 'showMobileFilters'> => {
      return {
        showMobileFilters: !state.showMobileFilters,
      };
    });
  }

  private handleTextInputChange(value: string): void {
    this.setState({ search: value }, () => this.getFilteredDocuments(this.state.selectedTags, this.state.search));
  }
}

const mapStateToProps = (state: IStoreState) => {
  return {
    folderContents: state.documentsFolderContents,
    downloadedDocument: state.documentsDownloadBlob,
    userDetails: state.userDetails,
    retailers: state.retailers.data,
    retailerName: state.retailerDetails,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<any>) => {
  return {
    getFolderContents: (parameters: IGetFolderContentsParams) => dispatch(getFolderContentsRequest(parameters)),
    downloadDocument: (parameters: IDownloadDocumentBlobParams, onSuccess: (data: IDocumentBlob) => void,
      onFailure: () => void) => dispatch(downloadDocumentBlobRequest(parameters, onSuccess, onFailure)),
    getRetailerName: () => dispatch(getRetailerName()),
    postWatchFolder: (parameters: IWatchFolderParams) => dispatch(watchFolderRequest(parameters)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Documents);
