export enum CategoriesEnum {
  Ambient = 'AM',
  Chilled = 'CH',
  ChilledLongLife = 'CL',
  ChilledShortLife = 'CS',
  Frozen = 'FR',
  Tobacco = 'TB',
  Expenses = 'EX',
  Combined = 'CO',
}
