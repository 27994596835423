import History from './History';

const navigateTo = (path: string, state?: any) => {
  const fullPath = window.location.pathname.toLowerCase() + window.location.search.toLowerCase();
  const pathDomain = new URL(path, window.location.origin).hostname.toLowerCase();

  if (pathDomain !== window.location.hostname.toLowerCase()) {
    window.open(path, '_blank');
  } else if (path.toLowerCase() !== fullPath) {
    History.push(path, [state]);
  }
};

export {
  navigateTo,
};
