import { AxiosResponse } from 'axios';
import { ICompletedOrdersParams } from '../../api/Reports/CompletedOrders/ICompletedOrdersParams';
import { ICompletedOrdersResponse } from '../../api/Reports/CompletedOrders/ICompletedOrdersResponse';
import { getCompletedOrdersReport } from '../../api/Reports/ReportsApi';
import { toastr } from 'react-redux-toastr';

export const GET_COMPLETED_ORDERS_REPORT_REQUEST = 'GET_COMPLETED_ORDERS_REPORT_REQUEST';
export const GET_COMPLETED_ORDERS_REPORT_RESPONSE = 'GET_COMPLETED_ORDERS_REPORT_RESPONSE';
export const GET_COMPLETED_ORDERS_REPORT_ERROR = 'GET_COMPLETED_ORDERS_REPORT_ERROR';

export const getCompletedOrdersReportRequest = (
  parameters: ICompletedOrdersParams,
  onSuccess: (data: any) => void): any => {

  return (dispatch: any) => {
    dispatch({
      type: GET_COMPLETED_ORDERS_REPORT_REQUEST,
    });

    return getCompletedOrdersReport(parameters)
      .then((response: AxiosResponse<ICompletedOrdersResponse>) => {
        if (response.status === 200) {
          if (response.data.report) {
            dispatch({
              type: GET_COMPLETED_ORDERS_REPORT_RESPONSE,
              response: response.data.report,
            });

            toastr.success(
              'Report Generated',
              'Your report has successfully been generated.',
            );
            onSuccess(response.data);
          } else {
            onError(dispatch);
          }
        } else {
          onError(dispatch);
        }
      })
      .catch((err: any) => {
        console.log(err);
        onError(dispatch);
      });
  };
};

const onError = (dispatch: any) => {
  toastr.error(
    'Error',
    'An error has occured while generating the report');

  dispatch({
    type: GET_COMPLETED_ORDERS_REPORT_ERROR,
    response: 'An error has occured while generating the report',
  });
};
