import { createStore, applyMiddleware } from 'redux';
import { persistStore, persistReducer, PersistPartial } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';
import rootReducer from '../reducers';
import { IStoreState } from '../types/index';
import refreshTokenMiddleware from '../middleware/refreshTokenMiddleware';

// create the composing function for middlewares
const composeEnhancers = composeWithDevTools({});

const persistConfig = {
  storage,
  key: 'root',
  whitelist: [
    'categories',
    'topOffers',
    'products',
    'subCategories',
    'tradingGroups',
    'userDetails',
    'retailers',
    'customerGroups',
    'searchResults',
    'retailerDetails',
  ],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = createStore<IStoreState & PersistPartial, any, any, any>(
  persistedReducer,
  composeEnhancers(
    applyMiddleware(thunk, refreshTokenMiddleware),
  ));

const persistor = persistStore(store);

export { store, persistor };
