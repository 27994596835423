import { IApiRequest, IBasket, IBasketItem } from '../../types';
import { requireNewSlot } from './requireNewSlot';

export const addToBasketText = (
  basket: IApiRequest<IBasket>,
  productCategory: string,
  productStatus: string,
  productItemId: number,
  productAvailability: boolean): string => {

  // No Basket
  if (requireNewSlot(basket)) {
    return 'Select Delivery Slot';
  }

  if (productStatus === null ||
    productStatus === undefined ||
    productAvailability === null ||
    productStatus.toLowerCase() !== 'orderable' ||
    !productAvailability) {
    return 'Not available';
  }

  const { combinedOrder, categoryCode } = basket.data;

  if (categoryCode !== productCategory && !combinedOrder) {
    return 'Invalid category';
  }

  // Product is orderable
  const existingItem = basket.data.items.find((x: IBasketItem) => x.itemId === productItemId, 10);
  return existingItem ? 'Update basket' : 'Add to basket';
};
