import { Reducer } from 'redux';
import { IApiRequest, IMultipleFileUpload, IUploadingFile } from '../../types/index';
import {
  UPLOAD_STORE_DOCUMENT_FILE_REQUEST,
  UPLOAD_STORE_DOCUMENT_FILE_PROGRESS,
  UPLOAD_STORE_DOCUMENT_FILE_RESPONSE,
  UPLOAD_STORE_DOCUMENT_FILE_ERROR,
} from '../../actions/StoreDocuments/uploadStoreDocumentAction';

const initialState: IMultipleFileUpload = {};
const uploadStoreDocumentsReducer: Reducer<IMultipleFileUpload>
  = (state: IMultipleFileUpload = initialState, action: any) => {
    switch (action.type) {
      case UPLOAD_STORE_DOCUMENT_FILE_RESPONSE: {
        const newstate = { ...state };
        delete newstate[action.response.fileName];

        return {
          ...newstate,
        };
      }
      case UPLOAD_STORE_DOCUMENT_FILE_PROGRESS: {
        return {
          ...state,
          [action.parameters.fileName]: {
            loading: true,
            data: {
              fileName: action.parameters.fileName,
              percentageComplete: action.parameters.percentComplete,
            },
            error: null,
            UpdatedAt: new Date(),
          } as IApiRequest<IUploadingFile>,
        };
      }
      case UPLOAD_STORE_DOCUMENT_FILE_REQUEST: {
        return {
          ...state,
          [action.parameters.fileName]: {
            loading: true,
            data: {
              fileName: action.parameters.fileName,
              percentageComplete: 0,
            },
            error: null,
            UpdatedAt: new Date(),
          } as IApiRequest<IUploadingFile>,
        };
      }
      case UPLOAD_STORE_DOCUMENT_FILE_ERROR: {
        return {
          ...state,
          [action.response.fileName]: {
            loading: false,
            data: {
              fileName: action.response.fileName,
              percentageComplete: 0,
            },
            error: action.response.error,
            UpdatedAt: new Date(),
          } as IApiRequest<IUploadingFile>,
        };
      }
      default: {
        return state;
      }
    }
  };

export default uploadStoreDocumentsReducer;
