import React from 'react';
import { connect } from 'react-redux';

const SelectedRetailerComponent = (): JSX.Element => {
  return (
    <div>
      <p>Selected Retailer</p>
    </div>
  );
};

export default connect(null, null)(SelectedRetailerComponent);
