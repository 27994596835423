import { DropdownOption } from '@orderly/morrisons-component-library';
import { IPendingOrder } from '../../types';
import { mapCategory } from '../Category/mapCategory';
import { calculateTotalPrice } from '../Product/ProductPriceHelper';

type KeyOfType<T, U> = { [key in keyof T]: T[key] extends U ? key : never }[keyof T];

const stringSort = (
  a: IPendingOrder,
  b: IPendingOrder,
  property: KeyOfType<IPendingOrder, string>,
): number => {
  return a[property].localeCompare(b[property]);
};

const numberSort = (
  a: IPendingOrder,
  b: IPendingOrder,
  property: KeyOfType<IPendingOrder, number>,
): number => {
  return a[property] - b[property];
};

const stringDateSort = (
  a: IPendingOrder,
  b: IPendingOrder,
  property: KeyOfType<IPendingOrder, string>,
): number => {
  return new Date(a[property]).getTime() - new Date(b[property]).getTime();
};

const categorySort = (
  a: IPendingOrder,
  b: IPendingOrder,
  property: KeyOfType<IPendingOrder, string>,
): number => {
  return mapCategory(a[property]).localeCompare(mapCategory(b[property]));
};

const arrayLengthSort = (
  a: IPendingOrder,
  b: IPendingOrder,
  property: KeyOfType<IPendingOrder, any[]>,
): number => {
  return a[property].length - b[property].length;
};

const reduceSort = (
  a: IPendingOrder,
  b: IPendingOrder,
  reducer: (items: any[]) => number,
  property: KeyOfType<IPendingOrder, any[]>,
): number => {
  return reducer(a[property]) - reducer(b[property]);
};

const statusSort = (
  a: IPendingOrder,
  b: IPendingOrder,
): number => {
  return a.status.description.localeCompare(b.status.description);
};

export class PendingOrdersHelper {
  public getSortOptions(): DropdownOption[] {
    return [
      { label: 'ORDER ID: low to high', value: 0 },
      { label: 'ORDER ID: high to low', value: 1 },
      { label: 'MODIFIED DATE: Ascending', value: 2 },
      { label: 'MODIFIED DATE: Descending', value: 3 },
      { label: 'DELIVERY DATE: Ascending', value: 4 },
      { label: 'DELIVERY DATE: Descending', value: 5 },
      { label: 'STORE ID: low to high', value: 6 },
      { label: 'STORE ID: high to low', value: 7 },
      { label: 'CATEGORY: A-Z', value: 8 },
      { label: 'CATEGORY: Z-A', value: 9 },
      { label: 'ITEMS: low to high', value: 10 },
      { label: 'ITEMS: high to low', value: 11 },
      { label: 'VALUE: low to high', value: 12 },
      { label: 'VALUE: high to low', value: 13 },
      { label: 'CUT-OFF: Ascending', value: 14 },
      { label: 'CUT-OFF: Descending', value: 15 },
      { label: 'STATUS: Ascending', value: 16 },
      { label: 'STATUS: Descending', value: 17 },
    ];
  }

  public sortOrders(orders: IPendingOrder[], orderBy: number): IPendingOrder[] {
    const sortedOrders = [...orders];
    const { property, descending } = this.getOrderByProperty(orderBy);
    if (property === 'orderNumber') {
      sortedOrders.sort((a, b) => stringSort(a, b, property));
    } else if (property === 'modifiedDate' ||
      property === 'deliveryDate' ||
      property === 'lockAt') {
      sortedOrders.sort((a, b) => stringDateSort(a, b, property));
    } else if (property === 'storeId') {
      sortedOrders.sort((a, b) => numberSort(a, b, property));
    } else if (property === 'category') {
      sortedOrders.sort((a, b) => categorySort(a, b, 'categoryCode'));
    } else if (property === 'items') {
      sortedOrders.sort((a, b) => arrayLengthSort(a, b, property));
    } else if (property === 'value') {
      sortedOrders.sort((a, b) => reduceSort(a, b, calculateTotalPrice, 'items'));
    } else if (property === 'status') {
      sortedOrders.sort((a, b) => statusSort(a, b));
    }

    if (descending) {
      sortedOrders.reverse();
    }

    return sortedOrders;
  }

  public getOrderByProperty(sortOrder: number): { property: string | keyof IPendingOrder, descending: boolean } {
    const descending = sortOrder % 2 !== 0;

    let property: string | keyof IPendingOrder = undefined;
    switch (sortOrder) {
      case 0:
      case 1:
        property = 'orderNumber';
        break;
      case 2:
      case 3:
        property = 'modifiedDate';
        break;
      case 4:
      case 5:
        property = 'deliveryDate';
        break;
      case 6:
      case 7:
        property = 'storeId';
        break;
      case 8:
      case 9:
        property = 'category';
        break;
      case 10:
      case 11:
        property = 'items';
        break;
      case 12:
      case 13:
        property = 'value';
        break;
      case 14:
      case 15:
        property = 'lockAt';
        break;
      case 16:
      case 17:
        property = 'status';
        break;
      default:
        break;
    }

    return { property, descending };
  }
}
