import React from 'react';
import { navigateTo } from '../../routing/Navigation';
import './EmptyDocumentsCard.scss';
import { Button } from '@orderly/morrisons-component-library';

interface IProps {
  isAdmin: boolean;
  text: string;
  url: string;
  icon: string;
}

const EmptyDocumentsCard = (props: IProps) => {
  const { isAdmin, text, url, icon } = props;

  return (
    <div className="empty-documents-card">
        <div className="empty-documents-card-container">
            <div className="empty-documents-card-wrapper">
                <span className={`empty-documents-card-icon icon ${icon}`} />
                <p>No items here</p>
                {
                  isAdmin &&
                  <Button
                    type="button"
                    className="button AdminDocuments-actionUploadDocument"
                    text={text}
                    onClick={
                      () => navigateTo(url)}
                  />
                }
            </div>
        </div>
    </div>
  );
};

export default EmptyDocumentsCard;
