import React, { Dispatch, useEffect, useState } from 'react';
import {
  FolderType,
  IApiRequest,
  IDocumentBlob,
  IOrderClaimReview,
  IStoreState,
  IUserDetails,
  ICompleteClaimReview,
} from '../../../types';
import { connect } from 'react-redux';
import {
  getSingleOrderClaimRequest,
  submitOrderClaimReviewRequest,
} from '../../../actions/Orders/OrderClaims/orderClaimsAction';
import LoadingThrobber from '../../../components/LoadingThrobber/LoadingThrobber';
import { Breadcrumbs, Button, TextArea } from '@orderly/morrisons-component-library';
import { navigateTo } from '../../../routing/Navigation';
import { getBasePath } from '../../../helpers/Users/UserHelper';
import DateHelper from '../../../helpers/Format/Date/FormatDateHelper';
import { IDownloadDocumentBlobParams } from '../../../api/Documents/downloadDocumentBlobApi';
import { downloadDocumentBlobRequest } from '../../../actions/Documents/downloadDocumentBlobAction';
import { GenerateDownload } from '../../../helpers/DownloadHelper';
import { toastr } from 'react-redux-toastr';
import Table from '../../../components/Table/Table';

interface IReviewClaimProps {
  userDetails: IUserDetails;
  orderClaimDetails: IApiRequest<IOrderClaimReview>;
  getOrderClaim: (claimNumber: string) => void;
  submitClaimReview: (params: ICompleteClaimReview) => Promise<any>;
  downloadDocument: (
    parameters: IDownloadDocumentBlobParams,
    onSuccess: (data: IDocumentBlob) => void,
    onFailure: () => void,
  ) => void;
  match: {
    params: {
      claimNumber: string;
    };
  };
}

const ReviewClaim = ({
  userDetails,
  orderClaimDetails,
  match,
  getOrderClaim,
  submitClaimReview,
  downloadDocument,
}: IReviewClaimProps): JSX.Element => {
  const [claim, setClaim] = useState<IOrderClaimReview>();
  const [reviewerFeedback, setReviewerFeedback] = useState('');
  const [errors, setErrors] = useState('');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!orderClaimDetails.data) {
      getOrderClaim(match.params.claimNumber);
    }
  }, []);

  useEffect(() => {
    if (orderClaimDetails.data) {
      const orderClaim = orderClaimDetails.data;

      if (orderClaim.claimNumber === match.params.claimNumber) {
        setClaim(orderClaim);
      } else {
        orderClaimDetails.data = null;
        getOrderClaim(match.params.claimNumber);
      }
    }
  }, [orderClaimDetails]);

  const validateFeedback = () => {
    const isValid = reviewerFeedback.trim().length > 0;

    setErrors(isValid ? undefined : 'Claim description is required');
    return isValid;
  };

  const completeClaimReview = (claimAccepted: boolean) => {
    const isFeedbackValid = validateFeedback();

    if (isFeedbackValid) {
      const confirmationMessage = `Are you sure you want to ${
        claimAccepted ? 'accept' : 'reject'
      } this claim?`;
      if (window.confirm(confirmationMessage)) {
        setLoading(true);
        submitClaimReview({ claimAccepted, reviewerFeedback, claimNumber: claim.claimNumber })
          .then(() => {
            orderClaimDetails.data = null;
            navigateTo(`${getBasePath(userDetails)}order-claims-review`);
          })
          .catch(() => console.warn('An error has occured'));
      }
    } else {
      return false;
    }
  };

  const downloadAttachment = () => {
    if (claim.attachments.length > 0) {
      setLoading(true);
      claim.attachments.forEach(file =>
        downloadDocument(
          {
            userAcceptance: false,
            retailerId: claim.retailerId,
            documentId: file.documentId,
            prefix: '',
            folderType: FolderType.Document,
          },
          (data: IDocumentBlob) => {
            GenerateDownload(data.data, data.fileName);
            setLoading(false);
            toastr.success(
              'Claim attachment(s) downloaded',
              'Attachment(s) have been successfully downloaded',
            );
          },
          () => {
            setLoading(false);
            toastr.error('An Error has occured', 'Please try again or contact support');
          },
        ),
      );
    }
  };

  return (
    <div className="review-order-claim-page">
      {(orderClaimDetails.loading || loading) && <LoadingThrobber />}
      <Breadcrumbs
        onHomeClick={() => navigateTo(`${getBasePath(userDetails)}home`)}
        onRedirect={navigateTo}
        segments={[
          {
            key: 0,
            text: 'Claims',
            url: `${getBasePath(userDetails)}order-claims-review`,
          },
          {
            key: 1,
            text: 'Review claim',
            url: window.location.pathname,
          },
        ]}
      />
      {claim ? (
        <>
          <section className="order-claim-details card">
            <h4 className="claim-review-headings">Claim Review</h4>
            <Table className="review-claim-table">
              <thead>
                <tr>
                  <th>Customer</th>
                  <th>Claim number</th>
                  <th>Raised date</th>
                  <th>Contact name</th>
                  <th>Contact email</th>
                  <th className="contact-phone-heading">Contact phone</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td data-label="Customer">{claim.customer}</td>
                  <td data-label="Claim number">{claim.claimNumber}</td>
                  <td data-label="Raised date">{DateHelper.formatShortDate(claim.dateRaised)}</td>
                  <td data-label="Contact name">{claim.contactName}</td>
                  <td data-label="Contact email">{claim.contactEmail}</td>
                  <td data-label="Contact phone">{claim.contactPhoneNumber}</td>
                </tr>
                <tr>
                  <td colSpan={6} className="downloadAttachments">
                      {claim.attachments.length > 0 ? (
                      <Button
                        type="button"
                        className="button link download-attachment-btn"
                        onClick={() => downloadAttachment()}
                        text="Download Claim Attachments"
                      />
                      ) : (
                        <strong>No attachments provided for this claim</strong>
                      )}
                  </td>
                  </tr>
              <thead>
                <tr>
                    <th colSpan={6} >
                      Claim Description
                    </th>
                </tr>
              </thead>
                <tr>
                  <td colSpan={6}>
                    <pre>{claim.claimDescription}</pre>
                  </td>
                </tr>
              </tbody>
            </Table>
          </section>
          <section className="order-claim-feedback card">
            <div>
              {claim.status === 'Raised' ? (
                <>
                  <h4 className="claim-review-headings">Enter Resolution</h4>
                  <TextArea
                    name="comment-input"
                    label="Claim Response"
                    className="comment-input"
                    value={reviewerFeedback}
                    onChange={(response: string) => {
                      setReviewerFeedback(response);
                      setErrors('');
                    }}
                  />
                  {errors && <span className="error">{errors}</span>}
                  <div className="actionButtons">
                    <Button
                      type="button"
                      className="btn btn-success"
                      icon="icon-tick"
                      iconPos="left"
                      text="Accept"
                      onClick={() => completeClaimReview(true)}
                    />
                    <Button
                      type="button"
                      className="btn btn-danger"
                      icon="icon-minus"
                      iconPos="left"
                      text="Reject"
                      onClick={() => completeClaimReview(false)}
                    />
                  </div>
                </>
              ) : (
                <>
                  <h4>Resolution Feedback</h4>
                  <pre>{claim.claimResolution}</pre>
                </>
              )}
            </div>
          </section>
        </>
      ) : null}
    </div>
  );
};

const mapStateToProps = (state: IStoreState) => {
  return {
    userDetails: state.userDetails,
    orderClaimDetails: state.orderClaimDetails,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<any>) => {
  return {
    getOrderClaim: (claimNumber: string) => dispatch(getSingleOrderClaimRequest(claimNumber)),
    submitClaimReview: (claimResponse: ICompleteClaimReview) =>
      dispatch(submitOrderClaimReviewRequest(claimResponse)),
    downloadDocument: (
      parameters: IDownloadDocumentBlobParams,
      onSuccess: (data: IDocumentBlob) => void,
      onFailure: () => void,
    ) => dispatch(downloadDocumentBlobRequest(parameters, onSuccess, onFailure)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ReviewClaim);
