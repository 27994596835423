import React from 'react';
import './OrderStatus.scss';
import { IOrderItemStatus } from './IOrderItemStatus';
import { IStatusMapping } from './IStatusMapping';

export enum OrderStatuses {
  COMPLETE = 1,
  SUBMITTED = 2,
  PENDING = 3,
  CANCELLED = 4,
  CONFIRMED = 5,
  PENDING_SHIPMENT = 6,
  SHIPPED = 7,
}

export enum OrderTextStatuses {
  COMPLETE = 'Complete',
  SUBMITTED = 'Submitted',
  PENDING = 'Pending',
  CANCELLED = 'Cancelled',
  CONFIRMED = 'Confirmed',
  PENDING_SHIPMENT = 'PendingShipment',
  SHIPPED = 'Shipped',
}

const orderStatusTextMapping: IStatusMapping = {
  raised: 'Ordered',
  confirmed: 'Confirmed',
  picked: 'Confirmed',
  shipped: 'Shipped',
  accounted: 'Shipped',
  invoiced: 'Shipped',
  'confirmed-partial': 'Ordered',
  'picked-partial': 'Ordered',
  'shipped-partial': 'Confirmed',
  'accounted-partial': 'Shipped',
  'invoiced-partial': 'Shipped',
};

// Ordered, Shipped, Confirmed mappings are Needed to support already mapped statuses - to be refactored
const orderStatusColourMapping: IStatusMapping = {
  raised: '#fddd7f',
  confirmed: '#fddd7f',
  picked: '#fddd7f',
  shipped: '#e5f3eb',
  accounted: '#e5f3eb',
  invoiced: '#e5f3eb',
  Ordered: '#fddd7f',
  Shipped: '#e5f3eb',
  Confirmed: '#fddd7f',
  'confirmed-partial': '#fddd7f',
  'picked-partial': '#fddd7f',
  'shipped-partial': '#fddd7f',
  'accounted-partial': '#e5f3eb',
  'invoiced-partial': '#e5f3eb',
};

const OrderItemStatus = ({ status }: IOrderItemStatus) => {
  const statusText = orderStatusTextMapping[status];
  const statusColour = orderStatusColourMapping[status];

  return (
    <div className="order-status" style={{ backgroundColor: statusColour }}>
      {statusText ? statusText : status}
    </div>
  );
};

export default OrderItemStatus;
