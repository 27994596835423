import { Reducer } from 'redux';
import { IApiRequest, IOrderDispatch } from '../../types';
import {
  GET_ORDER_DISPATCH_REQUEST,
  GET_ORDER_DISPATCH_RESPONSE,
  GET_ORDER_DISPATCH_ERROR,

  SUBMIT_ORDER_DISPATCH_REQUEST,
  SUBMIT_ORDER_DISPATCH_RESPONSE,
  SUBMIT_ORDER_DISPATCH_ERROR,

  IMPORT_ORDER_DISPATCH_REQUEST,
  IMPORT_ORDER_DISPATCH_RESPONSE,
  IMPORT_ORDER_DISPATCH_ERROR,

  EXPORT_ORDER_DISPATCH_REQUEST,
  EXPORT_ORDER_DISPATCH_RESPONSE,
  EXPORT_ORDER_DISPATCH_ERROR,
} from '../../actions/Orders/dispatchOrdersAction';

const initialState: IApiRequest<IOrderDispatch[]> = {
  data: null,
  error: '',
  UpdatedAt: null,
  loading: false,
};

const orderDispatchReducer: Reducer<IApiRequest<IOrderDispatch[]>> =
  (state: IApiRequest<IOrderDispatch[]> = initialState, action: any) => {
    switch (action.type) {
      case GET_ORDER_DISPATCH_RESPONSE: {
        return {
          ...initialState,
          data: action.response,
        };
      }
      case GET_ORDER_DISPATCH_REQUEST: {
        return {
          ...initialState,
          loading: true,
        };
      }
      case GET_ORDER_DISPATCH_ERROR: {
        return {
          ...initialState,
          error: action.response || 'There was an error retrieving logistic orders. Please try again',
        };
      }
      case SUBMIT_ORDER_DISPATCH_REQUEST:
      case IMPORT_ORDER_DISPATCH_REQUEST:
      case EXPORT_ORDER_DISPATCH_REQUEST: {
        return {
          ...state,
          loading: true,
        };
      }
      case SUBMIT_ORDER_DISPATCH_RESPONSE:
      case IMPORT_ORDER_DISPATCH_RESPONSE:
      case EXPORT_ORDER_DISPATCH_RESPONSE: {
        return {
          ...state,
          loading: false,
          UpdatedAt: new Date(),
        };
      }
      case SUBMIT_ORDER_DISPATCH_ERROR:
      case IMPORT_ORDER_DISPATCH_ERROR: {
        return {
          ...state,
          loading: false,
          error: action.response || 'There was an error updating logistic orders. Please try again',
        };
      }
      case EXPORT_ORDER_DISPATCH_ERROR: {
        return {
          ...state,
          loading: false,
          error: action.response || 'There was an error exporting the logistic order form. Please try again',
        };
      }
      default: {
        return state;
      }
    }
  };

export default orderDispatchReducer;
