import { IRegistrationParams, registerUser } from '../api/userApi';
import { toastr } from 'react-redux-toastr';
import { AxiosResponse } from 'axios';

export const REGISTER_USER_REQUEST = 'REGISTER_USER_REQUEST';
export type REGISTER_USER_REQUEST = typeof REGISTER_USER_REQUEST;
export const REGISTER_USER_RESPONSE = 'REGISTER_USER_RESPONSE';
export type REGISTER_USER_RESPONSE = typeof REGISTER_USER_RESPONSE;
export const REGISTER_USER_ERROR = 'REGISTER_USER_ERROR';
export type REGISTER_USER_ERROR = typeof REGISTER_USER_ERROR;

export const RegisterUserRequest = (parameters: IRegistrationParams, onSuccess: () => void): any => {
  return (dispatch : any) => {
    dispatch({
      type: REGISTER_USER_REQUEST,
    });

    return registerUser(parameters)
      .then((response: AxiosResponse<IRegistrationParams>) => {
        if (response.status === 200) {
          toastr.success('Account registered', 'Your account has been registered. You may now log into the system.');

          dispatch({
            type: REGISTER_USER_RESPONSE,
          });

          onSuccess();
        } else {
          const errorMessage = response.request.responseText;
          onError(dispatch, errorMessage);
        }
      })
      .catch((err: any) => {
        console.log(err);
        onError(dispatch);
      });
  };
};

const onError = (dispatch : any, errorMessage? : string) => {
  dispatch({
    type: REGISTER_USER_ERROR,
    error: errorMessage,
  });

  if (errorMessage) {
    toastr.error('Registration failed.', errorMessage, { timeOut: 10000 });
  } else {
    toastr.error('Registration failed.', 'An error has occurred whilst registering your account. Please try again.');
  }
};
