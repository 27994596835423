import { Dispatch } from 'redux';
import { IUploadStoreDocumentParams, uploadStoreDocument } from '../../api/StoreDocuments/uploadStoreDocumentApi';

export const UPLOAD_STORE_DOCUMENT_FILE_REQUEST = 'UPLOAD_STORE_DOCUMENT_FILE_REQUEST';
export const UPLOAD_STORE_DOCUMENT_FILE_PROGRESS = 'UPLOAD_STORE_DOCUMENT_FILE_PROGRESS';
export const UPLOAD_STORE_DOCUMENT_FILE_RESPONSE = 'UPLOAD_STORE_DOCUMENT_FILE_RESPONSE';
export const UPLOAD_STORE_DOCUMENT_FILE_ERROR = 'UPLOAD_STORE_DOCUMENT_FILE_ERROR';

export const uploadStoreDocumentRequest = (params: IUploadStoreDocumentParams, onSuccess: () => void):
  (dispatch: Dispatch) => Promise<void> => {
  return async (dispatch: Dispatch): Promise<void> => {
    dispatch({
      type: UPLOAD_STORE_DOCUMENT_FILE_REQUEST,
      parameters: { fileName: params.fileName },
    });

    try {
      const response = await uploadStoreDocument(params, onUploadProgress(dispatch));

      if (response.status === 200) {
        dispatch({
          type: UPLOAD_STORE_DOCUMENT_FILE_RESPONSE,
          response: { fileName: params.fileName, percentageComplete: 100 },
        });
        onSuccess();
      } else {
        onError(dispatch, params.fileName, 'An error has occured uploading the file');
      }
    } catch (error) {
      onError(dispatch, params.fileName, 'An error has occured uploading the file');
    }
  };
};

const onUploadProgress = (dispatch: Dispatch) => (fileName: string, percentComplete: number) => {
  dispatch({
    type: UPLOAD_STORE_DOCUMENT_FILE_PROGRESS,
    parameters: { fileName, percentComplete },
  });
};

const onError = (dispatch: Dispatch, fileName: string, error: string) => dispatch({
  type: UPLOAD_STORE_DOCUMENT_FILE_ERROR,
  response: { fileName, error },
});
