import {
  IUploadOrderImportCsvParams,
  uploadOrderImportCsv,
} from '../../api/Basket/OrderImportCsv/UploadOrderImportCsv';
import { toastr } from 'react-redux-toastr';
import { getShoppingListsRequest } from '../ShoppingLists/shoppingListsActions';

export const UPLOAD_ORDER_CSV_REQUEST = 'UPLOAD_ORDER_CSV_REQUEST';
export type UPLOAD_ORDER_CSV_REQUEST = typeof UPLOAD_ORDER_CSV_REQUEST;

export const UPLOAD_ORDER_CSV_RESPONSE = 'UPLOAD_ORDER_CSV_RESPONSE';
export type UPLOAD_ORDER_CSV_RESPONSE = typeof UPLOAD_ORDER_CSV_RESPONSE;

export const UPLOAD_ORDER_CSV_ERROR = 'UPLOAD_ORDER_CSV_ERROR';
export type UPLOAD_ORDER_CSV_ERROR = typeof UPLOAD_ORDER_CSV_ERROR;

export const uploadOrderImportCsvRequest = (parameters: IUploadOrderImportCsvParams): any => {
  return async (dispatch: any) => {
    dispatch({
      type: UPLOAD_ORDER_CSV_REQUEST,
    });

    try {
      const response = await uploadOrderImportCsv(parameters);

      if (response.status === 200) {
        dispatch({
          type: UPLOAD_ORDER_CSV_RESPONSE,
          response: response.data.ordercsv.order,
        });
        dispatch(getShoppingListsRequest());
        toastr.success('Import successful', 'Import has been processed successfully.');
      } else if ((response as any).response && (response as any).response.status === 400) {
        const errors = (response as any).response.data.errors;

        dispatch({
          type: UPLOAD_ORDER_CSV_ERROR,
          response: errors,
        });
      } else {
        onError(dispatch, response.data as unknown as string);
      }
    } catch (error) {
      onError(dispatch);
    }
  };
};

const onError = (dispatch: any, message: string = null) => dispatch({
  type: UPLOAD_ORDER_CSV_ERROR,
  response: message,
});
