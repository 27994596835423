import { Reducer } from 'redux';
import { USER_LOGOUT_REQUEST, USER_LOGOUT_RESPONSE, USER_LOGOUT_ERROR } from '../actions/Logout/userLogoutAction';

const userLogoutReducer: Reducer<any> = (state: any = {}, action : any) => {
  switch (action.type) {
    case USER_LOGOUT_REQUEST: {
      return {
        ...state,
        error: '',
        loading: true,
      };
    }
    case USER_LOGOUT_RESPONSE: {
      return {
        loading: false,
        error: '',
        userDetails: action.response,
      };
    }
    case USER_LOGOUT_ERROR: {
      return {
        ...state,
        error: action.error || 'Unable to logout. Please try again.',
        loading: false,
      };
    }
    default: {
      return state;
    }
  }
};

export default userLogoutReducer;
