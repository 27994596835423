import { Dispatch } from 'redux';
import { getScheduledDeliveries } from '../../api/Orders/scheduledDeliveriesApi';

export const GET_SCHEDULED_DELIVERIES_REQUEST = 'GET_SCHEDULED_DELIVERIES_REQUEST';
export const GET_SCHEDULED_DELIVERIES_RESPONSE = 'GET_SCHEDULED_DELIVERIES_RESPONSE';
export const GET_SCHEDULED_DELIVERIES_ERROR = 'GET_SCHEDULED_DELIVERIES_ERROR';

export const getScheduledDeliveriesRequest = (storeId: number): (dispatch: Dispatch) => Promise<void> => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: GET_SCHEDULED_DELIVERIES_REQUEST,
    });

    try {
      const response = await getScheduledDeliveries(storeId);
      if (response.status === 200) {
        dispatch({
          type: GET_SCHEDULED_DELIVERIES_RESPONSE,
          response: response.data.deliveryDates,
        });
      } else {
        onError(dispatch);
      }
    } catch (err) {
      console.log(err);
      onError(dispatch);
    }
  };
};

const onError = (dispatch: Dispatch) => dispatch({
  type: GET_SCHEDULED_DELIVERIES_ERROR,
});
