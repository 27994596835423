import IValidationFields from './IValidationFields';
import IPendingField from './IPendingField';
import IValidationResult from './IValidationResult';
import IValidatedFields from './IValidatedFields';
import { ValidationRules } from './ValidationRules';
import IFieldValidationResult from './IFieldValidationResult';
import { ValidatorRegex } from './ValidatorRegex';
import { ValidatorRules } from './ValidatorRules';
import { ValidatorOptionRules } from './ValidatorOptionRules';

export default class Validator {

  public static Rules: any = {
    required: ValidatorRules.REQUIRED,
    number: ValidatorRules.NUMBER,
    email: ValidatorRules.EMAIL,
    alphabet: ValidatorRules.ALPHABET,
    telephone: ValidatorRules.TELEPHONE,
  };

  private fields: IValidationFields;

  constructor(fields: IValidationFields) {
    this.fields = fields;
  }

  public validate(fields: IPendingField[], setState: (state: object) => void = undefined) : IValidationResult {
    const fieldResults : IValidatedFields[] = [];
    let formValid : boolean = true;

    fields.forEach((field) => {
      const rules = this.fields[field.name];

      let valid: boolean = true;
      let errorMessage: string = '';

      for (const rule of Object.keys(rules)) {
        const fieldValidationResult = this.validateRule(rule, field.value, rules[rule]);

        valid = fieldValidationResult.valid;

        if (!valid) {
          errorMessage = fieldValidationResult.error;
          break;
        }
      }

      formValid = formValid && valid;

      fieldResults.push({
        valid,
        errorMessage,
        name: field.name,
      });

      if (setState !== undefined) {
        if (!valid) {
          const state = {[field.name]: {
            value: field.value,
            error: errorMessage,
          }};

          setState(state);
        }
      }
    });

    return {
      isValid: formValid,
      fields: fieldResults,
    };
  }

  private validateRule(rule: string, value: any, rules: ValidationRules) : IFieldValidationResult {

    if (rules === false) {
      // The validation rule is explicitly not enabled
      return {
        valid: true,
        error: '',
      };
    }

    let result: IFieldValidationResult = null;

    switch (rule) {
      case Validator.Rules.required: {
        result = {
          valid: value !== 'null' && value !== null && value.length > 0,
          error: 'This is a required field',
        };
        break;
      }
      case Validator.Rules.number: {
        result = {
          valid: !isNaN(value),
          error: 'Invalid value. Please enter a number.',
        };
        break;
      }
      case Validator.Rules.email: {
        // Very basic validation of (string)@(domain).(tld)
        // Rely on server side for actual validation
        const emailRegex = new RegExp(ValidatorRegex.EMAIL);

        result = {
          valid: emailRegex.test(value),
          error: 'Invalid value. Please enter a valid email address.',
        };
        break;
      }
      case Validator.Rules.telephone: {
        const regex = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/;

        result = {
          valid: regex.test(value),
          error: 'Invalid value. Please enter a valid telephone number.',
        };
        break;
      }
      case Validator.Rules.alphabet: {
        const regex = new RegExp(ValidatorRegex.ALPHABET);

        result = {
          valid: regex.test(value),
          error: 'Invalid value. Please enter alphabets only.',
        };
        break;
      }
      default: {
        result = {
          valid: true,
        };
        break;
      }
    }

    if (result.valid && rules !== true) {
      result = this.validateRules(value, rules, result);
    }

    return result;
  }

  private validateRules(value: string, rules: ValidationRules, validationResult: IFieldValidationResult)
  : IFieldValidationResult {
    let result = validationResult;

    for (const rule of Object.keys(rules)) {
      switch (rule) {
        case ValidatorOptionRules.MINLENGTH:
          const minimumLength = (rules as any)[rule] as number;

          if (value.length < minimumLength) {
            result = {
              valid: false,
              error: `Value must be at least ${minimumLength} characters`,
            };
          }
          break;
        case ValidatorOptionRules.MAXLENGTH:
          const maximumLength = (rules as any)[rule] as number;

          if (value.length > maximumLength) {
            result = {
              valid: false,
              error: `Value must be less than ${maximumLength} characters`,
            };
          }
          break;
      }
    }

    return result;
  }
}
export function hasValue(value: any) : boolean { return value !== undefined && value !== null; }
