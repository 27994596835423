import { getUsersStoresDocuments } from '../../api/StoreDocuments/getUsersStoresDocumentsApi';

export const GET_USER_STORES_DOCUMENTS_REQUEST = 'GET_USER_STORES_DOCUMENTS_REQUEST';
export const GET_USER_STORES_DOCUMENTS_RESPONSE = 'GET_USER_STORES_DOCUMENTS_RESPONSE';
export const GET_USER_STORES_DOCUMENTS_ERROR = 'GET_USER_STORES_DOCUMENTS_ERROR';

export const getUserStoresDocumentsRequest = (): any => {
  return async (dispatch: any) => {
    dispatch({
      type: GET_USER_STORES_DOCUMENTS_REQUEST,
    });

    try {
      const response = await getUsersStoresDocuments();
      if (response.status === 200) {
        dispatch({
          type: GET_USER_STORES_DOCUMENTS_RESPONSE,
          response: response.data.stores,
        });
      } else {
        if (response instanceof Error) {
          onError(dispatch);
        }
      }
    } catch (error) {
      onError(dispatch);
    }
  };
};

const onError = (dispatch: any) => dispatch({
  type: GET_USER_STORES_DOCUMENTS_ERROR,
});
