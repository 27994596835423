import { toastr } from 'react-redux-toastr';
import { Dispatch } from 'redux';
import { IUserAcceptanceParams } from '../../api/Reports/ConfirmedOrders/IUserAcceptanceParams';
import { getUserAcceptanceReport } from '../../api/Reports/ReportsApi';

export const getUserAcceptanceReportRequest = (parameters: IUserAcceptanceParams): any => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch({
        type: 'GET_USER_ACCEPTANCE_REPORT_REQUEST',
      });

      const response = await getUserAcceptanceReport(parameters);

      if (response.status === 200) {
        dispatch({
          type: 'GET_USER_ACCEPTANCE_REPORT_RESPONSE',
          response: response.data.report,
        });

        toastr.success(
          'Report Generated',
          'Your report has successfully been generated.',
        );
      } else {
        onError(dispatch, response.data as unknown as string);
      }
    } catch (error) {
      console.log(error);
      onError(dispatch);
    }
  };
};

export const resetUserAcceptanceReportData = () => ({
  type: 'RESET_USER_ACCEPTANCE_REPORT',
});

const onError = (dispatch: Dispatch, message: string = null) => {
  dispatch({
    type: 'GET_USER_ACCEPTANCE_REPORT_ERROR',
    response: message,
  });
};
