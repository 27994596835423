import React from 'react';
import { formatCurrencyLocale } from '../../../../helpers/Format/formatCurrency';
import { IRebatePeriodCardProps } from './IRebatePeriodCardProps';
import styles from './RebatePeriodCard.module.scss';

function RebatePeriodCard({
  heading,
  rebateAmount,
  rebatePercentageApplicable,
  storeSalesAmount,
  tobaccoSalesbyStore,
  storeSalesEligibleAmount,
  previousPeriod,
}: IRebatePeriodCardProps): JSX.Element {
  return (
    <div className={`${styles.periodCard} ${previousPeriod ? styles.previous : styles.current} card`}>
      <h3>{heading}</h3>
      <div className={styles.rebate}>
        <div className={styles.info}>
          <h1>
            {formatCurrencyLocale(rebateAmount)}
          </h1>
          <div className={styles.description}>Rebate Value</div>
        </div>
        <div className={styles.info}>
          <h1>
            {rebatePercentageApplicable}%
          </h1>
          <div className={styles.description}>Rebate Tier</div>
        </div>
      </div>
      <div className={styles.sales}>
        <span>Total sales: {formatCurrencyLocale(storeSalesAmount)}</span>
        <span>Tobbaco total: {formatCurrencyLocale(tobaccoSalesbyStore)}</span>
        <span>Non-tobacco total: {formatCurrencyLocale(storeSalesEligibleAmount)}</span>
      </div>
    </div>
  );
}

export default RebatePeriodCard;
