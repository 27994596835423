import Fetch from '../apiConfig/apiConfig';
import { AxiosInstance, AxiosPromise } from 'axios';
import { ICompleteClaimReview, IOrderClaim, IOrderClaimReview } from '../../types';

export interface IOrderClaimsResponse {
  orderClaims: IOrderClaim[];
}

export interface IOrderClaimReviewResponse {
  orderClaim: IOrderClaimReview;
}

export interface IValidateOrdersClaimEligibilityRequest {
  orderNumbers: string[];
}

export interface IValidateOrdersClaimEligibilityResponse {
  getClaimEligibleOrders: { [key: string]: boolean };
}

export const validateOrdersClaimEligibilityApi =
  (params: IValidateOrdersClaimEligibilityRequest):
    AxiosPromise<IValidateOrdersClaimEligibilityResponse> => {
    return Fetch().then((axios: AxiosInstance):
      AxiosPromise<IValidateOrdersClaimEligibilityResponse> =>
      axios.post('orders/claims/validate', params));
  };

export const submitOrderClaimApi = (params: IOrderClaim): AxiosPromise<void> => {
  return Fetch().then(axios => axios.post('orders/claims/submit', params));
};

export const getOrderClaimsApi = (resolvedClaims: boolean): AxiosPromise<IOrderClaimsResponse> => {
  return Fetch().then(axios => axios.get(`orders/claims/get?resolvedClaims=${resolvedClaims}`));
};

export const getOrderClaimApi = (claimNumber: string): AxiosPromise<IOrderClaimReviewResponse> => {
  return Fetch().then(axios => axios.get(`orders/claim/get?claimNumber=${claimNumber}`));
};

export const submitOrderClaimReviewApi = (params: ICompleteClaimReview): AxiosPromise<void> => {
  return Fetch().then(axios => axios.post('orders/claims/review/submit', params));
};
