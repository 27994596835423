import React from 'react';
import './CustomerServices.scss';
import { Button } from '@orderly/morrisons-component-library';
import { GenerateDownload } from '../../helpers/DownloadHelper';

const DHL_PHONE_NUMBER = '0330 678 1235';
const DHL_EMAIL_ADDRESS = 'MWhCustomerServices@dhl.com';
const WHOLESALE_SUPPORT_ADDRESS = 'Wholesale.support@morrisonsplc.co.uk';

const CustomerServices = () => {

  const downloadClaimsForm = () => {
    const file = './files/DHL Claims Form.xlsx';
    const fileName = 'DHL Claims Form.xlsx';
    GenerateDownload(file, fileName);
  };

  return (
    <div className="customer-services-wrapper">
        <h1>DHL Customer Services</h1>
        <div className="customer-services-content">
            <p className="semibold">At Morrisons, delivering excellent customer service is our number one priority.</p>
            <p className="semibold">If you have a problem, our DHL customer services team is here to help,</p>
            <p className="semibold">simply get in touch via the phone number or email below!</p>
            <br />
            <p className="faded">Here to help!</p>
            <p className="service-schedule">07:00 - 18:00 - 7 days</p>
            <p className="faded">If you are calling outside these hours, please leave a <br />
                voicemail and we will get back to you as soon as possible!
            </p>
        </div>
        <div className="customer-services-phone">
            <a href={`tel:${DHL_PHONE_NUMBER}`}>
                {DHL_PHONE_NUMBER}
            </a>
        </div>
        <div className="customer-services-email">
            <a className="email-link-bold" href={`mailto:${DHL_EMAIL_ADDRESS}`}>
                {DHL_EMAIL_ADDRESS}
            </a>
        </div>
        <hr />
        <div className="customer-services-stock-claims">
            <h1>Stock Claims</h1>
            <h3>New Stock Claims Process</h3>
            <p className="faded">You can raise a claim by completing the claim form <br />
                  below and emailing at&nbsp;
                  <a href={`mailto:${DHL_EMAIL_ADDRESS}`}>
                      {DHL_EMAIL_ADDRESS}
                  </a>
            </p>
            <div className="claim-buttons">
                <Button
                    onClick={() => downloadClaimsForm()}
                    text="Download claim"
                    type="button"
                    className="btn-success"
                />
                <a href={`mailto:${DHL_EMAIL_ADDRESS}`}>
                    <Button className="btn-success" text="Email claim" type="button" />
                </a>
            </div>
        </div>
        <hr />
        <div className="customer-services-greencore">
            <h1>Greencore</h1>
            <p>For any changes to your Greencore order including increases, decreases or <br />
                special events please contact Greencore on the phone number below
            </p>
            <ul>
                <li>0333 136 3333</li>
                <li>Monday to Friday 7am - 5pm</li>
                <li>Saturday and Bank Holidays 8am - 1:30pm</li>
            </ul>
        </div>
        <hr />
        <div className="customer-services-it-support">
            <h1>Morrisons IT Support</h1>
            <div>
                For any issues with Morrisons technology including the Wholesale Portal and Wholesale App, please get <br/>
                in touch via the email address below. For all other technology related issues please contact your IT <br />
                provider.
            </div>
            <a className="email-link-bold" href={`mailto:${WHOLESALE_SUPPORT_ADDRESS}`}>
              {WHOLESALE_SUPPORT_ADDRESS}
            </a>
            <a href={`mailto:${WHOLESALE_SUPPORT_ADDRESS}`}>
              <Button className="btn-success" text="Send an email" type="button" />
            </a>
            <div>
                We aim to respond to all queries within 24 hours
            </div>
        </div>
        <hr />
        <div className="customer-services-it-queries">
            <h2>For all other queries please contact your Morrisons <br/> account management team</h2>
        </div>
        <hr />
        <div className="customer-services-weekend-cover">
            <h1>Weekend Cover</h1>
            <div>In the event of a business critical emergency over the weekend, please refer to your <br />
             weekly bulletin for Morrisons on call details and follow your normal escalation process
            </div>
        </div>
    </div>
  );
};

export default CustomerServices;
