import Fetch from './apiConfig/apiConfig';
import { AxiosPromise } from 'axios';
import { ICategory } from '../types';

export interface IGetCategoriesResponse {
  categories: ICategory[];
}

export const getCategories = () : AxiosPromise<IGetCategoriesResponse> => {
  return Fetch().then(axios => axios.get('products/categories'));
};
