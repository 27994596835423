import Fetch from '../apiConfig/apiConfig';
import { AxiosPromise } from 'axios';
import { IFolder } from '../../types';

export interface IDeleteFolderParams {
  folderId: number;
}

export interface IDeleteFolderResponse {
  document: IFolder;
}

export const deleteFolderApi = (params: IDeleteFolderParams) : AxiosPromise<IDeleteFolderResponse> => {
  return Fetch().then(axios => axios.post('documents/delete-folder', params));
};
