import Fetch from './apiConfig/apiConfig';
import { AxiosPromise } from 'axios';
import { IUser, IPagedDataResponse } from '../types';

export interface IGetUsersParams {
  startIndex: number;
  pageSize: number;
  search: string;
  roles: number[];
  retailers: number[];
  orderBy: string;
  orderDescending: boolean;
}

export const getUsers = (params: IGetUsersParams) : AxiosPromise<IPagedDataResponse<IUser[]>> => {
  return Fetch().then(axios => axios.post('users', params));
};

export interface IRemoveUserParams {
  userId: number;
}

export const removeUser = (params: IRemoveUserParams) : AxiosPromise<void> => {
  return Fetch().then(axios => axios.post('users/remove', params));
};
