import { AxiosResponse } from 'axios';
import { toastr } from 'react-redux-toastr';
import { importSupplyDemandOrderForm } from '../../api/Reports/ReportsApi';
import { IImportSuppyDemandOrderFormParams }
  from '../../api/Reports/SupplyDemandOrderForm/IImportSuppyDemandOrderFormParams';
import { IImportSuppyDemandOrderFormResponse }
  from '../../api/Reports/SupplyDemandOrderForm/IImportSuppyDemandOrderFormResponse';

export const IMPORT_SUPPLY_DEMAND_ORDER_FORM_REQUEST = 'IMPORT_SUPPLY_DEMAND_ORDER_FORM_REQUEST';
export const IMPORT_SUPPLY_DEMAND_ORDER_FORM_RESPONSE = 'IMPORT_SUPPLY_DEMAND_ORDER_FORM_RESPONSE';
export const IMPORT_SUPPLY_DEMAND_ORDER_FORM_ERROR = 'IMPORT_SUPPLY_DEMAND_ORDER_FORM_ERROR';

export const importSupplyDemandOrderFormRequest = (
  parameters: IImportSuppyDemandOrderFormParams,
  onSuccess: (data: string[]) => void): any => {
  return (dispatch: any) => {
    dispatch({
      type: IMPORT_SUPPLY_DEMAND_ORDER_FORM_REQUEST,
    });

    return importSupplyDemandOrderForm(parameters)
      .then((response: AxiosResponse<IImportSuppyDemandOrderFormResponse>) => {
        if (response.status === 200) {
          onSuccess(response.data.importSupplyDemandOrderForm.results);
          if (response.data.importSupplyDemandOrderForm.success) {
            toastr.success('Import Success', 'The supply demand order form has been imported successfully.');
            dispatch({
              type: IMPORT_SUPPLY_DEMAND_ORDER_FORM_RESPONSE,
            });
          } else {
            toastr.error('Import Error',
              'An error has occurred whilst importing the supply demand order form. Please try again.');
          }
        } else {
          onError(dispatch);
        }
      })
      .catch((err: any) => {
        console.log(err);
        onError(dispatch);
      });
  };
};

const onError = (dispatch: any) => {
  dispatch({
    type: IMPORT_SUPPLY_DEMAND_ORDER_FORM_ERROR,
  });
  toastr.error('Error', 'An error has occurred whilst importing the supply demand order form. Please try again.');
};
