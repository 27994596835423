import { Action } from 'redux';
import { IStoreState } from '../types';
import { store } from '../store';
import { AxiosResponse } from 'axios';
import { IGetTradingGroupsParams, getTradingGroups, ITradingGroupsResponse as IApiResponse } from '../api/tradingGroupsApi';

export const GET_TRADING_GROUPS_REQUEST = 'GET_TRADING_GROUPS_REQUEST';
export type GET_TRADING_GROUPS_REQUEST = typeof GET_TRADING_GROUPS_REQUEST;
export const GET_TRADING_GROUPS_RESPONSE = 'GET_TRADING_GROUPS_RESPONSE';
export type GET_TRADING_GROUPS_RESPONSE = typeof GET_TRADING_GROUPS_RESPONSE;
export const GET_TRADING_GROUPS_ERROR = 'GET_TRADING_GROUPS_ERROR';
export type GET_TRADING_GROUPS_ERROR = typeof GET_TRADING_GROUPS_ERROR;

export interface IGetTradingGroupsResponse extends Action {
  type: GET_TRADING_GROUPS_RESPONSE;
  response: IGetTradingGroupsResponse;
}

export interface IGetTradingGroupsError extends Action {
  type: GET_TRADING_GROUPS_ERROR;
  error?: string;
}

export const getTradingGroupsRequest = (parameters: IGetTradingGroupsParams): any => {
  const state: IStoreState = store.getState();
  const tradingGroups = state.tradingGroups[parameters.categoryId];

  if (tradingGroups && state.catalogueMeta.meta.updatedAt &&
      state.catalogueMeta.meta.updatedAt < tradingGroups.UpdatedAt) {
    return {
      type: GET_TRADING_GROUPS_RESPONSE,
      response: { [parameters.categoryId]: tradingGroups },
    };
  }

  return (dispatch : any) => {
    dispatch({
      type: GET_TRADING_GROUPS_REQUEST,
    });

    return getTradingGroups(parameters)
      .then((response: AxiosResponse<IApiResponse>) => {
        if (response.status === 200) {
          dispatch({
            type: GET_TRADING_GROUPS_RESPONSE,
            response: {
              [parameters.categoryId]: {
                data: response.data.tradingGroups.map(x => ({ description : x })),
                loading: false,
                UpdatedAt: new Date(),
                error: '',
              },
            },
          });

        } else {
          onError(dispatch);
        }
      })
      .catch((err: any) => {
        console.log(err);
        onError(dispatch);
      });
  };
};

const onError = (dispatch: any) => dispatch({
  type: GET_TRADING_GROUPS_ERROR,
});
