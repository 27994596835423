import React, { useEffect, useState } from 'react';
import { navigateTo } from '../../../routing/Navigation';
import { Breadcrumbs, BreadcrumbSegment } from '@orderly/morrisons-component-library';
import FileDropzone from '../../../components/FileDropzone/FileDropzone';
import FileUploadInformation from '../../../components/FileUploadInformation/FileUploadInformation';
import { FolderType, IStoreState } from '../../../types';
import { connect } from 'react-redux';
import QueryString from 'query-string';
import { toastr } from 'react-redux-toastr';

import './DocumentsUpload.scss';
import { Dispatch } from 'redux';
import { uploadDocumentRequest } from '../../../actions/Documents/uploadDocumentAction';
import { getFolderContentsRequest } from '../../../actions/Documents/folderContentsAction';
import { IDocumentsProps } from './IDocumentsProps';
import { IGetFolderContentsParams } from '../../../api/Documents/getFolderContentsApi';
import { IUploadDocumentParams } from '../../../api/Documents/uploadDocumentApi';
import { getUserPageRequest } from '../../../actions/usersAction';
import { IGetUsersParams } from '../../../api/adminUserApi';
import { IGetTagsParams } from '../../../api/Documents/getTags';
import { getTagsRequest } from '../../../actions/Documents/getTagsAction';
import { isPrintshopPage } from '../../../helpers/Documents/IsPrintshopPage';
import { constructNavigationLink } from '../../../helpers/Documents/DocumentNavigationHelper';
import LoadingThrobber from '../../../components/LoadingThrobber/LoadingThrobber';

const DocumentsUpload = (props: IDocumentsProps) => {
  const [files, setFiles] = useState<any[]>([]);
  const [fileDataArray, setFileDataArray] = useState<(string | ArrayBuffer)[]>([]);
  const [tags, setTags] = useState([]);
  const [folder, setFolder] = useState('');
  const [additionalUsers, setAdditionalUsers] = useState([]);
  const [requiresAcceptance, setRequiresAcceptance] = useState(false);
  const [folderSelected, setFolderSelected] = useState(false); // used for selecting the folder when there's only 1
  const customerGroupPresent = QueryString.parse(window.location.search).customerGroup;

  const {
    match, folderContents, availableUsers, retailers, documentTags,
    getFolderContents, getAvailableUsers, getDocumentTags, uploadDocument } = props;

  const { data, loading } = folderContents;
  const { customerGroups, catalogue, folders, users } = data;

  const getRetailerId = () => {
    if (isPrintshopPage() || customerGroupPresent) {
      return -1;
    }

    return parseInt(match.params.retailerId, 10);
  };

  const getRetailerName = () => {
    if (isPrintshopPage() || (customerGroups && customerGroups.length > 0)) {
      return '';
    }

    const retailer = retailers.filter(x => x.id === retailerId)[0];

    if (retailer) {
      return retailer.externalIdentifier;
    }
  };

  const retailerId = getRetailerId();
  const retailerName = getRetailerName();
  const customerGroup = QueryString.parse(window.location.search).customerGroup;

  useEffect(() => {
    const parentFolderPath: string = QueryString.parse(window.location.search).parent as string;
    const retailerId = getRetailerId();

    getFolderContents({
      retailerId,
      folderPath: parentFolderPath,
      prefix: '',
      folderType: getFolderType(),
      customerGroup: customerGroup as string,
    });

    if (!isPrintshopPage()) {
      getAvailableUsers({
        orderBy: 'firstname',
        orderDescending: false,
        pageSize: 1000,
        retailers: [retailerId],
        roles: [-1],
        search: '',
        startIndex: 0,
      });

      getDocumentTags({ retailerId });
    }
  }, []);

  useEffect(() => {
    const customerGroup = QueryString.parse(window.location.search).customerGroup as string;
    const customerGroupFolders = folders.filter(x => x.customerGroups.includes(customerGroup));

    // if only 1 folder present, auto select it
    if (!folderSelected && ((!customerGroup && folders.length === 1) ||
      (customerGroup && customerGroupFolders.length === 1))) {
      const selectedFolder = customerGroup ? customerGroupFolders[0].description : folders[0].description;
      onFolderChange(selectedFolder);
      setFolderSelected(true);
    }
  }, [folderContents]);

  const getFolderType = () => {
    if (isPrintshopPage()) {
      return FolderType.Printshop;
    }

    if (customerGroupPresent) {
      return FolderType.CustomerGroup;
    }

    return FolderType.Document;
  };

  const parentIsPresent = () => {
    const parent = QueryString.parse(window.location.search).parent;
    return parent !== undefined && parent !== '';
  };

  const getFolderPath = (folder: string) => {
    let folderParam = folder;

    if (!folder) {
      return '';
    }

    if (parentIsPresent() && isPrintshopPage()) {
      folderParam = `${QueryString.parse(window.location.search).parent}/${folder}`;
    }

    return folderParam;
  };

  const onFolderChange = (folder: string): void => {
    getFolderContents({
      folderPath: getFolderPath(folder),
      retailerId: getRetailerId(),
      prefix: '',
      folderType: getFolderType(),
      customerGroup: customerGroup as string,
    });

    setFolder(folder);
    if (isPrintshopPage()) {
      navigateTo(`/admin/printshop/upload?parent=${getFolderPath(folder)}`);
    }
  };

  const onUserSelect = (userId: number, checked: boolean): void => {
    const included = additionalUsers.includes(userId);
    if (checked && !included) {
      additionalUsers.push(userId);
      setAdditionalUsers(additionalUsers);
    } else if (!checked && included) {
      const index = additionalUsers.indexOf(userId);
      additionalUsers.splice(index, 1);
      setAdditionalUsers(additionalUsers);
    }
  };

  const onCancel = (): void => {
    let cancelRedirectionPath = '';

    if (isPrintshopPage()) {
      cancelRedirectionPath = `/admin/printshop?folder=${folderPath}`;
    } else if (customerGroupPresent) {
      cancelRedirectionPath = '/admin/customer-groups';
    } else {
      cancelRedirectionPath = `/admin/documents/${parseInt(match.params.retailerId, 10)}`;
    }

    const toastrConfirmOptions = {
      onOk: () =>
        navigateTo(cancelRedirectionPath),
    };
    toastr.confirm('Are you sure you wish to discard these changes?', toastrConfirmOptions);
  };

  const onDrop = (readFiles: any[]) => {
    const filesArray: any[] = [];
    const filesDataArray: any[] = [];

    readFiles.forEach((file) => {
      const reader = new FileReader();

      reader.onabort = () => console.log('file reading was aborted');
      reader.onerror = () => console.log('file reading has failed');
      reader.onload = (ev: any) => {
        const binaryStr = ev.target.result;
        filesDataArray.push(binaryStr);
        filesArray.push(file);

        if (filesDataArray.length === readFiles.length) {
          setFileDataArray(filesDataArray);
          setFiles(filesArray);
        }
      };
      reader.readAsDataURL(file);
    });
  };

  const onSave = (onError?: (isError: boolean) => void) => {
    const folderPath: string = isPrintshopPage() ? QueryString.parse(window.location.search).parent as string : folder;
    let selectedCustomerGroups: string[] = [];

    if (customerGroupPresent || (customerGroups && customerGroups.length > 0)) {
      selectedCustomerGroups = customerGroup ? [customerGroup] as string[] : customerGroups;
    }

    uploadDocument({
      folderPath,
      requiresAcceptance,
      customerGroups: selectedCustomerGroups,
      files: files.map((file: any, index: number) => ({
        name: file.name,
        type: file.type,
        data: fileDataArray[index] as string,
      })),
      retailerId: getRetailerId(),
      tags: tags.filter(x => x !== ''),
      notificationUsers: additionalUsers,
      folderType: getFolderType(),
    },
      () => {
        toastr.success(
          'Success',
          `${files.length > 1 ? 'Files were' : 'File was'} uploaded successfully.`);

        navigateTo(constructNavigationLink(match));
      },
      (err: any) => {
        if (err) {
          onError(err instanceof Error);
        }
      },
    );
  };

  const getBreadCrumbsNavigationLink = (depth: number) => {
    let navLink = '';

    if (depth === 2) {
      if (isPrintshopPage()) {
        navLink = `/admin/printshop?folder=${folderPath}`;
      } else if (customerGroupPresent) {
        navLink = '/admin/customer-groups';
      } else {
        navLink = `/admin/documents/${retailerId}`;
      }
    } else if (depth === 3) {
      if (isPrintshopPage()) {
        navLink = `/admin/printshop/upload?parent=${folderPath}`;
      } else if (customerGroupPresent) {
        navLink = `/admin/documents/-1/upload?parent=&customerGroup=${customerGroup}`;
      } else {
        navLink = `/admin/documents/${retailerId}/upload?parent=`;
      }
    }

    return navLink;
  };

  let catalogueName = '...';
  if (catalogue) {
    catalogueName = catalogue;
  }
  const folderPath: string = isPrintshopPage() ? QueryString.parse(window.location.search).parent as string : folder;
  const breadcrumbs: BreadcrumbSegment[] = [
    { key: 0, text: 'Admin', url: '/admin/home/' },
    { key: 1, text: catalogueName, url: '/admin/retailer' },
    {
      key: 2,
      text: isPrintshopPage() ? 'Printshop' : 'Documents',
      url: getBreadCrumbsNavigationLink(2),
    },
    {
      key: 3,
      text: 'Upload Documents',
      url: getBreadCrumbsNavigationLink(3),
    },
  ];

  const getFilteredFolders = () => {
    if (folders.length > 0) {
      if (customerGroupPresent) {
        const customerGroup = QueryString.parse(window.location.search).customerGroup as string;
        return folders.filter(x => x.customerGroups.includes(customerGroup));
      }
    }
    return folders;
  };

  return (
    <div className="documents-wrapper">
      <Breadcrumbs
        onHomeClick={() => navigateTo('/admin/home')}
        onRedirect={navigateTo}
        segments={breadcrumbs}
      />
      <section className="heading-wrapper">
        <h1>Documents Upload</h1>
      </section>
      <div className="content-wrapper">
        {loading && <LoadingThrobber/>}
        <div className="file-dropzone">
          <FileDropzone
            onFileUpload={onDrop}
            disabled={loading || (isPrintshopPage() && folders.length > 0)}
            fileTypes={isPrintshopPage() && ['image/*', 'application/*']}
          />
        </div>
        <FileUploadInformation
          files={files}
          tags={tags}
          folder={folder}
          folderList={folders ? getFilteredFolders() : []}
          onTagsChange={(tags: string[]) => setTags(tags)}
          onFolderChange={(folder: string) => onFolderChange(folder)}
          onNameChange={() => null}
          selectedFolder={folder}
          onCancel={() => onCancel()}
          onSave={onSave}
          retailerId={parseInt(match.params.retailerId, 10)}
          retailerName={retailerName}
          defaultUsers={users ? users : []}
          users={availableUsers.data}
          selectedUsers={additionalUsers}
          onUserSelect={onUserSelect}
          loading={loading}
          catalogue={catalogueName}
          requiresAcceptance={requiresAcceptance}
          onRequiresAcceptanceChange={(requiresAcceptance: boolean) => setRequiresAcceptance(requiresAcceptance)}
          allDocumentTags={documentTags.data ? documentTags.data : []}
          editMode={false}
          folderCustomerGroups={data.customerGroups || []}
        />
      </div>
    </div>
  );
};

const mapStateToProps = (state: IStoreState) => {
  return {
    folderContents: state.documentsFolderContents,
    uploadedDocument: state.documentsUploadDocument,
    userDetails: state.userDetails,
    retailers: state.retailers.data,
    availableUsers: state.users,
    documentTags: state.documentTags,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<any>) => {
  return {
    getFolderContents: (parameters: IGetFolderContentsParams) => dispatch(getFolderContentsRequest(parameters, true)),
    uploadDocument: (parameters: IUploadDocumentParams,
      onSuccess: () => void,
      onFailure: (err: any) => void) => dispatch(uploadDocumentRequest(parameters, onSuccess, onFailure)),
    getAvailableUsers: (params: IGetUsersParams) => dispatch(getUserPageRequest(params)),
    getDocumentTags: (params: IGetTagsParams) => dispatch(getTagsRequest(params)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DocumentsUpload);
