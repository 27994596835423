import { OrderDateValue } from '../../../components/OrdersList/OrdersListFilters/OrderDateValue.enum';

export const persistOrderFiltersAction = (date: Date, dateType: OrderDateValue, statusFilters: string[]) => {
  return {
    type: 'ORDERS_LIST_FILTERS_PERSIST',
    payload: {
      date,
      dateType,
      statusFilters,
    },
  };
};
