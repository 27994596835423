import Fetch from '../apiConfig/apiConfig';
import { AxiosPromise } from 'axios';

export interface IGetTagsParams {
  retailerId: number;
}

export interface IGetTagsResponse {
  tags: string[];
}

export const getTags = (params: IGetTagsParams) : AxiosPromise<IGetTagsResponse> => {
  return Fetch().then(axios => axios.get(`documents/tags?retailerId=${params.retailerId}`));
};
