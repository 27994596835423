import { Action } from 'redux';
import { IAmendOrderParams } from '../../api/Basket/AmendOrder/IAmendOrderParams';
import { IAmendOrderResponse } from '../../api/Basket/AmendOrder/IAmendOrderResponse';
import { AxiosResponse } from 'axios';
import { toastr } from 'react-redux-toastr';
import { amendOrder } from '../../api/Basket/AmendOrder/AmendOrderApi';

export const POST_AMEND_ORDER_REQUEST = 'POST_AMEND_ORDER_REQUEST';
export type POST_AMEND_ORDER_REQUEST = typeof POST_AMEND_ORDER_REQUEST;

export const POST_AMEND_ORDER_RESPONSE = 'POST_AMEND_ORDER_RESPONSE';
export type POST_AMEND_ORDER_RESPONSE = typeof POST_AMEND_ORDER_RESPONSE;

export const POST_AMEND_ORDER_ERROR = 'POST_AMEND_ORDER_ERROR';
export type POST_AMEND_ORDER_ERROR = typeof POST_AMEND_ORDER_ERROR;

export const SET_CURRENT_BASKET = 'SET_CURRENT_BASKET';
export type SET_CURRENT_BASKET = typeof SET_CURRENT_BASKET;

export interface IAmendOrdersResponse extends Action {
  type: POST_AMEND_ORDER_RESPONSE;
  response: IAmendOrderResponse;
}

export const amendOrderRequest = (parameters: IAmendOrderParams): any => {
  return (dispatch : any) => {
    dispatch({
      type: POST_AMEND_ORDER_REQUEST,
    });

    return amendOrder(parameters)
      .then((response: AxiosResponse<IAmendOrderResponse>) => {
        if (response.status === 200) {
          toastr.success(
            'Order Selected',
            'Your order has successfully been selected.');

          dispatch({
            type: SET_CURRENT_BASKET,
            response: response.data.basket,
          });

          dispatch({
            type: POST_AMEND_ORDER_RESPONSE,
          });
        } else {
          onError(dispatch);
        }
      })
      .catch((err: any) => {
        console.log(err);
        onError(dispatch);
      });
  };
};

const onError = (dispatch: any) => dispatch({
  type: POST_AMEND_ORDER_ERROR,
});
