import React, { Dispatch, ReactText } from 'react';
import './CreateUser.scss';
import { DropdownOption } from '@orderly/morrisons-component-library';
import { createUserRequest } from '../../../actions/UserManangement/userManagementAction';
import { connect } from 'react-redux';
import { navigateTo } from '../../../routing/Navigation';
import { ICreateUserState, ICreatedUser, IInputState, ICreatUserComponent } from './interfaces/CreateUser.interface';
import { PageMode } from '../ManageUsers/enums/ManageUsers.enum';
import { CreateUserHelper } from './Helper/CreateUserHelper';
import { toastr } from 'react-redux-toastr';
import { IStoreState, IRetailerStore } from '../../../types';
import { getRetailersRequest } from '../../../actions/retailersAction';
import CreateUserForm from './form/CreateUserForm';
import { ICreaterUserFormConfig, ICreateUserFormBreadCrumb, ICreateUserFormForm }
from './form/interfaces/CreateUserForm.interface';
import queryString from 'query-string';

class CreateUserComponent extends React.Component<any, ICreateUserState> implements ICreatUserComponent {
  public state: ICreateUserState;
  public pageMode: string;
  public userToEdit: ICreatedUser;

  private _payload: string;
  private _createUserHelper: CreateUserHelper;

  constructor(props: any) {
    super(props);
    this.pageMode = window.location.pathname.indexOf('admin-users') === -1 ? PageMode.User : PageMode.Admin;
    this.onInit();
  }

  public componentDidMount(): void {
    this.props.getRetailers();
    const retailersQuery = queryString.parse(window.location.search).retailer;
    if (retailersQuery && !(retailersQuery instanceof Array)) {
      this.setState({ retailerId: { value: retailersQuery, error: '' } as IInputState });
    }
  }

  public onInit(): void {
    this.extractUserToEdit();
    this.initiateCreateUserHelper();
    this.getOptions();
    this.initiateState();
  }

  public handleRetailerDropdown = (selected: DropdownOption): void  => {
    this.setState({ retailerId: { value: selected.value, error: '' } as IInputState });
  }

  public handleRegionCodeDropdown = (selected: DropdownOption): void  => {
    console.log(selected);
    this.setState({ regionCode: { value: selected.value, error: '' } as IInputState });
  }

  public handleRoleDropdown = (selected: DropdownOption): void  => {
    this.setState({ role: { value: selected.value, error: '' } as IInputState });
  }

  public postToApi = (event: any): any => {
    event.preventDefault();

    if (this.isValid()) {
      const url: string = this.userToEdit ? 'users/update' : 'users/create';
      if (this.state.userStores.length === 0 && this.pageMode === PageMode.User) {
        toastr.error('Error', 'You must select at least one store to save this user.');
        return;
      }
      this.setPayload();

      this.setState({ isLoading: true,  isDisabledOnSubmit: true });
      this.props.createUser(url, this._payload || this.state, (): void => {
        const title = this.pageMode === PageMode.Admin
          ? this.userToEdit
            ? 'Staff updated'
            : 'Staff created'
          : this.userToEdit
            ? 'User updated'
            : 'User created';

        const message = this.pageMode === PageMode.Admin
          ? this.userToEdit
            ? 'The staff has been successfully updated.'
            : 'The staff has been successfully created.'
          : this.userToEdit
            ? 'The user has been successfully updated.'
            : 'The user has been successfully created.';

        toastr.success(title, message);
        this.setState({ isLoading: false,  isDisabledOnSubmit: false });

        const retailerId = this.state.retailerId.value;
        this.navigateTo(this.pageMode === PageMode.Admin
          ? '/admin/admin-users'
          : `/admin/users${retailerId ? `?retailer=${retailerId}` : ''}`)(event);
      });
    }
  }

  public navigateTo(url?: string): (event: any) => void {
    return (eventUrl: any): void => {
      navigateTo(url || (typeof eventUrl === 'string' ? eventUrl : '/admin/home'));
    };
  }

  public getOptions(): void {
    this._createUserHelper.getOptions(this.props.retailers.data);
  }

  public getSelectedRetailerValue(): ReactText {
    return Number(this.state.retailerId.value);
  }

  public getSelectedRoleValue(): ReactText {
    return this.state.role.value;
  }

  public getSelectedRegionValue(): ReactText {
    return Number(this.state.regionCode.value);
  }

  public isValid(): boolean  {
    return this._createUserHelper.isValid(this.pageMode === PageMode.Admin);
  }

  public setCreateUserFormConfig(): ICreaterUserFormConfig {
    return this._createUserHelper.getCreateUserFormConfig();
  }

  public setCreateUserFormBreadCrumb(): ICreateUserFormBreadCrumb {
    return this._createUserHelper.getCreateUserFormBreadCrumb();
  }

  public setCreateUserForm(): ICreateUserFormForm {
    return this._createUserHelper.getCreateUserForm(this.state.retailerId.value);
  }

  public render(): JSX.Element {
    return (
      <CreateUserForm
        config={this.setCreateUserFormConfig()}
        breadCrumb={this.setCreateUserFormBreadCrumb()}
        form={this.setCreateUserForm()}
        retailerDetails={this.props.retailers.data}
        userId={this.userToEdit === undefined ? -1 : this.userToEdit.userId}
      />
    );
  }

  private initiateCreateUserHelper(): void {
    this._createUserHelper = new CreateUserHelper(this);
    this._createUserHelper.initiateValidation();
  }

  private initiateState(): void {
    this.state = this._createUserHelper.initiateState(this.userToEdit, this.pageMode);
  }

  private setPayload(): any {
    this._payload = this._createUserHelper.getPayload(this.userToEdit, this.state);
  }

  private extractUserToEdit(): void {
    const state: { user: ICreatedUser } = this.props.location.state && this.props.location.state[0];
    if (state && state.user) {
      this.userToEdit = this.props.location.state[0].user;
    }
  }
}

const mapStateToProps = (state: IStoreState) => {
  return {
    retailers: state.retailers,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<any>) => {
  return {
    createUser: (url: string, payload: any, onSuccess: () => any) => dispatch(createUserRequest(url, payload, onSuccess)),
    getRetailers: () => dispatch(getRetailersRequest()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateUserComponent);
