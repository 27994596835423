import { Reducer } from 'redux';
import { IApiRequest, IOrderOpportunity } from '../../types';
import {
  GET_ORDER_OPPORTUNITIES_ERROR,
  GET_ORDER_OPPORTUNITIES_REQUEST,
  GET_ORDER_OPPORTUNITIES_RESPONSE,
} from '../../actions/OrderOpportunities/orderOpportunitiesAction';

const initialState: IApiRequest<IOrderOpportunity[]> = {
  loading: false,
  UpdatedAt: null,
  data: [],
  error: '',
};

const orderOpportunitiesReducer: Reducer<IApiRequest<IOrderOpportunity[]>> = (
  state: IApiRequest<IOrderOpportunity[]> = initialState,
  action : any,
  ) => {
  switch (action.type) {
    case GET_ORDER_OPPORTUNITIES_REQUEST: {
      return {
        ...initialState,
        loading: true,
        UpdatedAt: new Date().toISOString(),
      };
    }
    case GET_ORDER_OPPORTUNITIES_RESPONSE: {
      return {
        ...initialState,
        UpdatedAt: new Date().toISOString(),
        data: action.response,
      };
    }
    case GET_ORDER_OPPORTUNITIES_ERROR: {
      return {
        ...initialState,
        UpdatedAt: new Date().toISOString(),
        error: action.error || 'There was an error loading the order opportunities.',
      };
    }
    default: {
      return state;
    }
  }
};

export default orderOpportunitiesReducer;
