import { Reducer } from 'redux';
import {
  POST_RESET_PASSWORD_ERROR,
  POST_RESET_PASSWORD_REQUEST,
  POST_RESET_PASSWORD_RESPONSE } from '../../actions/MyAccount/resetPasswordAction';
import { IResetPassword } from '../../types';

const initialState: IResetPassword = {
  loading: false,
  error: '',
};

const resetPasswordReducer: Reducer<IResetPassword> = (
  state: IResetPassword = initialState,
  action : any,
  ) => {
  switch (action.type) {
    case POST_RESET_PASSWORD_RESPONSE: {
      return {
        loading: false,
        error: '',
      };
    }
    case POST_RESET_PASSWORD_REQUEST: {
      return {
        loading: true,
        error: '',
      };
    }
    case POST_RESET_PASSWORD_ERROR: {
      return {
        loading: false,
        error: 'An error has occurred. Please try again.',
      };
    }
    default: {
      return state;
    }
  }
};

export default resetPasswordReducer;
