import { AnyAction, Reducer } from 'redux';
import { IApiRequest, IDeliveryDate } from '../../types';
import {
  GET_SCHEDULED_DELIVERIES_REQUEST,
  GET_SCHEDULED_DELIVERIES_RESPONSE,
  GET_SCHEDULED_DELIVERIES_ERROR,
} from '../../actions/Orders/scheduledDeliveriesAction';

const initialState: IApiRequest<IDeliveryDate[]> = {
  data: [],
  error: '',
  UpdatedAt: null,
  loading: false,
};

const scheduledDeliveriesReducer: Reducer<IApiRequest<IDeliveryDate[]>> = (
  state: IApiRequest<IDeliveryDate[]> = initialState,
  action: AnyAction,
) => {
  switch (action.type) {
    case GET_SCHEDULED_DELIVERIES_REQUEST: {
      return {
        data: [],
        error: '',
        UpdatedAt: null,
        loading: true,
      };
    }
    case GET_SCHEDULED_DELIVERIES_RESPONSE: {
      return {
        data: action.response,
        error: '',
        UpdatedAt: new Date(),
        loading: false,
      };
    }
    case GET_SCHEDULED_DELIVERIES_ERROR: {
      return {
        ...initialState,
        error: 'An error has occured',
      };
    }
    default: {
      return state;
    }
  }
};

export default scheduledDeliveriesReducer;
