import { AnyAction, Reducer } from 'redux';
import { IApiRequest, IShoppingList } from '../../types';
import {
  GET_SHOPPING_LIST_ERROR,
  GET_SHOPPING_LIST_REQUEST,
  GET_SHOPPING_LIST_RESPONSE,
  UPDATE_SHOPPING_LIST_ERROR,
  UPDATE_SHOPPING_LIST_REQUEST,
  UPDATE_SHOPPING_LIST_RESPONSE,
} from '../../actions/ShoppingLists/shoppingListActions';

const EMPTY_SHOPPING_LIST: IShoppingList = {
  id: -1,
  retailerId: -1,
  description: '',
  createdAt: new Date(),
  products: [],
  isTradingPack: false,
};

const initialState: IApiRequest<IShoppingList> = {
  data: EMPTY_SHOPPING_LIST,
  error: '',
  UpdatedAt: null,
  loading: false,
};

const shoppingListReducer: Reducer<IApiRequest<IShoppingList>> = (
  state: IApiRequest<IShoppingList> = initialState,
  action: AnyAction,
) => {
  switch (action.type) {
    case UPDATE_SHOPPING_LIST_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }
    case GET_SHOPPING_LIST_REQUEST: {
      const isSameList = action.data === state.data.id;
      return {
        data: {
          ...(isSameList ? state.data : EMPTY_SHOPPING_LIST),
          id: action.data,
        },
        error: '',
        UpdatedAt: null,
        loading: true,
      };
    }
    case UPDATE_SHOPPING_LIST_RESPONSE:
    case GET_SHOPPING_LIST_RESPONSE: {
      return {
        data: action.response,
        error: '',
        UpdatedAt: new Date(),
        loading: false,
      };
    }
    case UPDATE_SHOPPING_LIST_ERROR:
    case GET_SHOPPING_LIST_ERROR: {
      return {
        ...initialState,
        error: 'An error has occured',
      };
    }
    default: {
      return state;
    }
  }
};

export default shoppingListReducer;
