import React, { useEffect, useState } from 'react';
import { Dispatch } from 'redux';
import {
  IAdminRetailers,
  IApiRequest,
  IPresaleCampaign,
  IPresalesState,
  IStoreState,
  IUserDetails,
} from '../../../types';
import { connect } from 'react-redux';
import { navigateTo } from '../../../routing/Navigation';
import { getBasePath } from '../../../helpers/Users/UserHelper';
import LoadingThrobber from '../../../components/LoadingThrobber/LoadingThrobber';
import FullPageLayout from '../../../layouts/FullPageLayout/FullPageLayout';
import PresaleCampaignEditor from '../../../components/Presales/PresaleCampaignEditor';
import { createPresalesCampaignRequest } from '../../../actions/SupplyChain/presalesActions';

interface ICreateCampaignProps {
  userDetails: IUserDetails;
  customerGroups: IApiRequest<string[]>;
  presales: IPresalesState;
  retailers: IApiRequest<IAdminRetailers>;
  createPresaleCampaign: (
    campaignDetails: IPresaleCampaign,
    onSuccess: (data: string[], campaign: IPresaleCampaign) => void,
  ) => void;
}

const CreateCampaign = ({
  userDetails,
  customerGroups,
  retailers,
  presales,
  createPresaleCampaign,
}: ICreateCampaignProps) => {
  const [uploadResults, setUploadResults] = useState<string[]>([]);
  const [showUploadResponseModal, setShowUploadResponseModal] = useState<boolean>(false);
  const [template, setTemplate] = useState<any>();
  const [filteredCustomerList, setFilteredCustomerList] = useState<any[]>();
  const [visibleStoreList, setVisibleStoreList] = useState<any[]>();

  const [presaleCampaign, setPresaleCampaign] = useState<IPresaleCampaign>({
    name: '',
    deadline: new Date().toDateString(),
    extendLines: false,
    storeIds: [],
    customerGroups: [],
    assignmentType: 'CustomerGroup',
    associatedCampaignId: 0,
    published: false,
    template: '',
  });
  const [isButtonDisabled, setIsButtonDisabled] = useState<boolean>(true);
  const customerParamsEmpty =
    presaleCampaign.storeIds.length === 0 && presaleCampaign.customerGroups.length === 0;

  const invalidCampaignName = !presaleCampaign.name || presaleCampaign.name.length > 150;

  useEffect(() => {
    if (presaleCampaign) {
      const isInvalid =  invalidCampaignName || !presaleCampaign.template || customerParamsEmpty;
      setIsButtonDisabled(isInvalid);
    }
  }, [presaleCampaign]);

  const onCreateCampaign = () => {
    createPresaleCampaign(presaleCampaign, (results: string[], campaign:IPresaleCampaign) => {
      if (results.length > 0) {
        setUploadResults(results);
        setShowUploadResponseModal(true);
      } else {
        navigateTo(`${getBasePath(userDetails)}presales-campaigns/preview/campaign/${campaign.id}`);
      }
    });
  };

  return (
    <FullPageLayout
      heading="Create Presale Campaign"
      breadcrumbs={[
        {
          key: 0,
          text: 'Presales Campaigns',
          url: `${getBasePath(userDetails)}presales-campaigns`,
        },
        {
          key: 1,
          text: 'Create',
          url: window.location.pathname,
        },
      ]}
      homeRoute="/admin/home"
    >
      {customerGroups.loading || retailers.loading || presales.loading ? (
        <LoadingThrobber />
      ) : (
        <PresaleCampaignEditor
          action="Create"
          isButtonDisabled={isButtonDisabled}
          presaleCampaign={presaleCampaign}
          uploadResults={uploadResults}
          showUploadResponseModal={showUploadResponseModal}
          template={template}
          filteredCustomerList={filteredCustomerList}
          visibleStoreList={visibleStoreList}
          setShowUploadResponseModal={setShowUploadResponseModal}
          setPresaleCampaign={setPresaleCampaign}
          submitCampaignAction={onCreateCampaign}
          setUploadResults={setUploadResults}
          setTemplate={setTemplate}
          setFilteredCustomerList={setFilteredCustomerList}
          setVisibleStoreList={setVisibleStoreList}
        />
      )}
    </FullPageLayout>
  );
};

const mapStateToProps = (state: IStoreState) => {
  return {
    userDetails: state.userDetails,
    retailers: state.adminRetailers,
    customerGroups: state.customerGroups,
    presales: state.presales,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<any>) => ({
  createPresaleCampaign: (
    parameters: IPresaleCampaign,
    onSuccess: (data: string[], campaign: IPresaleCampaign) => void,
  ) => dispatch(createPresalesCampaignRequest(parameters, onSuccess)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CreateCampaign);
