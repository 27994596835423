import { Reducer } from 'redux';
import { IApiRequest, IAdminRetailers } from '../../types';
import {
  GET_ADMIN_RETAILERS_REQUEST,
  GET_ADMIN_RETAILERS_RESPONSE,
  GET_ADMIN_RETAILERS_ERROR,
} from '../../actions/Admin/adminRetailersAction';

const initialState: IApiRequest<IAdminRetailers> = {
  loading: false,
  UpdatedAt: null,
  data: null,
  error: '',
};

const adminRetailersReducer: Reducer<IApiRequest<IAdminRetailers>> = (
  state: IApiRequest<IAdminRetailers> = initialState,
  action: any,
) => {
  switch (action.type) {
    case GET_ADMIN_RETAILERS_REQUEST: {
      return {
        ...initialState,
        loading: true,
      };
    }
    case GET_ADMIN_RETAILERS_RESPONSE: {
      return {
        ...initialState,
        data: action.response,
      };
    }
    case GET_ADMIN_RETAILERS_ERROR: {
      return {
        ...initialState,
        error: '',
      };
    }
    default: {
      return state;
    }
  }
};

export default adminRetailersReducer;
