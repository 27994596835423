import { Action } from 'redux';
import { AxiosResponse } from 'axios';
import {
  IGetUserStoresParams,
  getUserStores,
  IGetUserStoresResponse as IGetUserStoresResponseAPI,
  getMyUserStores,
} from '../../api/userApi';

export const GET_USER_STORES_REQUEST = 'GET_USER_STORES_REQUEST';
export type GET_USER_STORES_REQUEST = typeof GET_USER_STORES_REQUEST;

export const GET_USER_STORES_RESPONSE = 'GET_USER_STORES_RESPONSE';
export type GET_USER_STORES_RESPONSE = typeof GET_USER_STORES_RESPONSE;

export const GET_USER_STORES_ERROR = 'GET_USER_STORES_ERROR';
export type GET_USER_STORES_ERROR = typeof GET_USER_STORES_ERROR;

export const GET_USER_STORES_CLEAR = 'GET_USER_STORES_CLEAR';
export type GET_USER_STORES_CLEAR = typeof GET_USER_STORES_CLEAR;

export interface IMyAccountResponse extends Action {
  type: GET_USER_STORES_RESPONSE;
  response: IGetUserStoresResponseAPI;
}

export interface IGetMyAccountError extends Action {
  type: GET_USER_STORES_ERROR;
  error?: string;
}

export const getMyUserStoresRequest = (): any => {
  return (dispatch : any) => {

    dispatch({
      type: GET_USER_STORES_REQUEST,
    });

    return getMyUserStores()
      .then((response: AxiosResponse<IGetUserStoresResponseAPI>) => {
        if (response.status === 200) {
          const data = {
            stores: response.data.stores,
          };

          dispatch({
            type: GET_USER_STORES_RESPONSE,
            response: data,
          });

        } else {
          onError(dispatch);
        }
      })
      .catch((err: any) => {
        console.log(err);
        onError(dispatch);
      });
  };
};

export const getUserStoresRequest = (parameters: IGetUserStoresParams): any => {
  return (dispatch : any) => {

    dispatch({
      type: GET_USER_STORES_REQUEST,
      userId: parameters.UserId,
    });

    return getUserStores(parameters)
      .then((response: AxiosResponse<IGetUserStoresResponseAPI>) => {
        if (response.status === 200) {
          const data = {
            stores: response.data.stores,
          };

          dispatch({
            type: GET_USER_STORES_RESPONSE,
            response: data,
          });

        } else {
          onError(dispatch);
        }
      })
      .catch((err: any) => {
        console.log(err);
        onError(dispatch);
      });
  };
};

const onError = (dispatch: any) => dispatch({
  type: GET_USER_STORES_ERROR,
});

export const clearData = () => {
  return {
    type: GET_USER_STORES_CLEAR,
  };
};
