import { getRetailerOrders } from '../../api/Basket/RetailerOrders/RetailerOrdersApi';
import { IRetailerOrdersResponse as IRetailerOrdersResponseAPI }
from '../../api/Basket/RetailerOrders/IRetailerOrdersResponse';

import { AxiosResponse } from 'axios';
import { Dispatch } from 'redux';

export const GET_RETAILER_ORDERS_REQUEST = 'GET_RETAILER_ORDERS_REQUEST';
export const GET_RETAILER_ORDERS_RESPONSE = 'GET_RETAILER_ORDERS_RESPONSE';
export const GET_RETAILER_ORDERS_ERROR = 'GET_RETAILER_ORDERS_ERROR';

export const getRetailerOrdersRequest = (): any => {
  return (dispatch : Dispatch) => {
    dispatch({
      type: GET_RETAILER_ORDERS_REQUEST,
    });

    return getRetailerOrders()
      .then((response: AxiosResponse<IRetailerOrdersResponseAPI>) => {
        if (response.status === 200) {
          dispatch({
            type: GET_RETAILER_ORDERS_RESPONSE,
            response: response.data.retailerOrders,
          });
        } else {
          onError(dispatch);
        }
      })
      .catch((err: any) => {
        console.log(err);
        onError(dispatch);
      });
  };
};

const onError = (dispatch: Dispatch) => dispatch({
  type: GET_RETAILER_ORDERS_ERROR,
});
