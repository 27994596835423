import React from 'react';
import styles from './EmptyRecord.module.scss';
import { InfoCircle } from 'iconoir-react';
import { IEmptyRecordProps } from './IEmptyRecordProps';

function EmptyRecord({ message }: IEmptyRecordProps): JSX.Element {
  return (
    <div className={styles.emptyState}>
      <InfoCircle className={styles.icon} />
      <h4>
        {message}
      </h4>
    </div>
  );
}

export default EmptyRecord;
