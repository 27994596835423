import { Reducer } from 'redux';
import { IApiRequest } from '../../types/index';
import {
  UPDATE_FOLDER_REQUEST, UPDATE_FOLDER_RESPONSE, UPDATE_FOLDER_ERROR,
} from '../../actions/Documents/updateFolderAction';

const initialState: IApiRequest<void> = {
  data: undefined,
  error: '',
  UpdatedAt: null,
  loading: false,
};

const updateFolderReducer: Reducer<IApiRequest<void>> = (state: IApiRequest<void> = initialState, action : any) => {
  switch (action.type) {
    case UPDATE_FOLDER_RESPONSE: {
      return {
        data: undefined,
        error: '',
        UpdatedAt: new Date(),
        loading: false,
      };
    }
    case UPDATE_FOLDER_REQUEST: {
      return {
        data: undefined,
        error: '',
        UpdatedAt: null,
        loading: true,
      };
    }
    case UPDATE_FOLDER_ERROR:
    default: {
      return state;
    }
  }
};

export default updateFolderReducer;
