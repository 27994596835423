import { Reducer } from 'redux';
import {
  GET_PRODUCT_ERROR,
  GET_PRODUCT_REQUEST,
  GET_PRODUCT_RESPONSE } from '../actions/productAction';
import { IProducts } from '../types';

const initialState: IProducts = [];

const productsReducer: Reducer<IProducts> = (state: IProducts = initialState, action : any) => {
  switch (action.type) {
    case GET_PRODUCT_RESPONSE: {
      return {
        ...state,
        ...{
          [action.productId] : { UpdatedAt: new Date().toISOString(), loading: false, error: '', data: action.response },
        },
      };
    }
    case GET_PRODUCT_REQUEST: {
      return {
        ...state,
        ...{ [action.productId] : { UpdatedAt: new Date().toISOString(), loading: true, error: '', data: null } },
      };
    }
    case GET_PRODUCT_ERROR: {
      return {
        ...state,
        ...{ [action.productId] : {
          UpdatedAt: new Date(),
          loading: false,
          error: action.error || 'There was an error loading this product. Please check the URL and try again.',
          data: null } },
      };
    }
    default: {
      return state;
    }
  }
};

export default productsReducer;
