import React, { useRef, Dispatch, useState } from 'react';
import { connect } from 'react-redux';
import './EdnTemplate.scss';
import Table from '../Table/Table';
import logo from '../../assets/images/logo.svg';
import { IEdnTemplateProps } from './IEdnTemplateProps';
import { clearOrderEdnContents } from '../../actions/Orders/orderEdnAction';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { IOrderItem } from '../../types';
import { IOrderItemSortBy } from '../../pages/Orders/OrderDetails/IOrderItemSortBy';
import { formatCurrency } from '../../helpers/Format';
import { getPriceInclusiveOfVAT } from '../../helpers/Product/ProductPriceHelper';

const EdnTemplate = ({ edn, clearOrderEdnContents, orderItems }: IEdnTemplateProps) => {
  const [orderBy] = useState('description-asc');
  const ednRefContainer = useRef(null);

  const printEdn = () => {
    const input = ednRefContainer.current;
    return html2canvas(input, { scrollY: (window.pageYOffset * -1) })
      .then((canvas) => {
        const imgData = canvas.toDataURL('image/png');
        const pdf = new jsPDF('p', 'mm', 'a4');

        const imgWidth = pdf.internal.pageSize.getWidth();
        const pageHeight = pdf.internal.pageSize.getHeight();

        const imgHeight = canvas.height * imgWidth / canvas.width;
        let heightLeft = imgHeight;

        let position = 0;

        pdf.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight, '', 'FAST');
        heightLeft -= pageHeight;
        while (heightLeft >= 0) {
          position = heightLeft - imgHeight;
          pdf.addPage();
          pdf.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight, '', 'FAST');
          heightLeft -= pageHeight;
        }
        pdf.save(`Order EDN ${edn.data.orders[0].shipToDeliverAt}.pdf`);
        clearOrderEdnContents();
      });
  };

  const onNextFrame = (callback: any) => {
    setTimeout(() => requestAnimationFrame(callback), 0);
  };

  const sortOrderItems = (items: IOrderItem[]): IOrderItem[] => {
    return items.sort((item1: IOrderItem, item2: IOrderItem): number => orderByFunction[orderBy](item1, item2));
  };

  if (!edn.data) {
    return null;
  }

  const orderTotal = edn.data.orders[0].items.reduce(
    (a: any, { quantityShipped, priceConfirmedAmount }: any) => {
      const price = priceConfirmedAmount * 100;
      return a + (price * quantityShipped);
    }, 0);

  const orderTotalWithVat = edn.data.orders[0].items.reduce(
      (a: any, { quantityShipped, priceConfirmedAmount, priceConfirmedTaxRate }: any) => {
        const price = priceConfirmedAmount * 100;
        const rowPrice = price * quantityShipped;
        return a + (getPriceInclusiveOfVAT(rowPrice, priceConfirmedTaxRate));
      }, 0);

  return (
    <div ref={ednRefContainer} className="ednTemplate">
      <div className="container">
        <div className="templateHeader">
          <div className="templateLogo">
            <img src={logo} onLoad={() => onNextFrame(printEdn)} />
          </div>
          <div className="templateOrderDetails">
            <div className="orderNumber">
              Order Number:
              <span>
                {
                  edn.data &&
                  edn.data.orders[0] &&
                  edn.data.orders[0].orderId ||
                  'N/A'
                }
              </span>
            </div>
            <div className="poNumber">
              PO Number:
              <span>
                {
                  edn.data &&
                    edn.data.orders[0] &&
                    edn.data.orders[0].customerPONumber ?
                    edn.data.orders[0].customerPONumber :
                    'N/A'
                }
              </span>
            </div>
            <div className="orderLocation">
              Location:
              <span>
                {
                  edn.data &&
                  edn.data.orders[0] &&
                  edn.data.orders[0].shipToLocationId ||
                  'N/A'
                }
              </span>
            </div>
            <div className="orderCustomer">
              Customer:
              <span>
                {
                  edn.data &&
                  edn.data.orders[0] &&
                  edn.data.orders[0].customerId ||
                  'N/A'
                }
              </span>
            </div>
            <div className="orderDate">
              Order Date:
              <span>
                {
                  edn.data &&
                  edn.data.orders[0] &&
                  edn.data.orders[0].orderRaisedDate ||
                  'N/A'
                }
              </span>
            </div>
            <div className="deliveryDate">
              Delivery Date:
              <span>
                {
                  edn.data &&
                  edn.data.orders[0] &&
                  edn.data.orders[0].shipToDeliverAt ||
                  'N/A'
                }
              </span>
            </div>
            <div className="orderType">
              Order Type:
              <span>
                {
                  edn.data &&
                  edn.data.orders[0] &&
                  edn.data.orders[0].orderReferenceCode ||
                  'N/A'
                }
              </span>
            </div>
            <div className="orderDeliveryAddress">
              Delivery Address:
              <span>
                <span>
                  {
                    edn.data &&
                    edn.data.orders[0] &&
                    edn.data.orders[0].shipToAddressName &&
                    edn.data.orders[0].shipToAddressName.toLowerCase() ||
                    'N/A'
                  },
                </span>
                <span>
                  {
                    edn.data &&
                    edn.data.orders[0] &&
                    edn.data.orders[0].shipToAddressLine1 &&
                    edn.data.orders[0].shipToAddressLine1.toLowerCase() ||
                    'N/A'
                  },
                </span>
                <span>
                  {
                    edn.data &&
                    edn.data.orders[0] &&
                    edn.data.orders[0].shipToAddressCity &&
                    edn.data.orders[0].shipToAddressCity.toLowerCase() ||
                    'N/A'
                  },
                </span>
                <span>
                  {
                    edn.data &&
                    edn.data.orders[0] &&
                    edn.data.orders[0].shipToAddressPostCode ||
                    'N/A'
                  }
                </span>
              </span>
            </div>
          </div>
        </div>
        <div className="ednTable">
          <Table className="order-items-table">
            <thead>
              <tr>
                <th>Product Name</th>
                <th>Product Id</th>
                <th className="right">Single Price</th>
                <th className="right">Case Size</th>
                <th className="right">UOM</th>
                <th className="right">Order QT</th>
                <th className="right">Delivered QT</th>
                <th className="right">Total (ex VAT)</th>
                <th className="right">Total (inc VAT)</th>
              </tr>
            </thead>
            <tbody>
              {
                edn.data &&
                sortOrderItems(edn.data.orders[0].items).map((item: IOrderItem, index: number) => {
                  const singlePrice = item.priceConfirmedAmount * 100;
                  const rowPrice = singlePrice * item.quantityShipped;

                  return (
                    <tr key={index}>
                      <td>{item.itemDescription}</td>
                      <td>{item.itemId}</td>
                      <td align="right">{formatCurrency(singlePrice)}</td>
                      <td align="right">{item.itemCaseSize}</td>
                      <td align="right">{item.quantityType}</td>
                      <td align="right">
                        {orderItems.data.find((x: IOrderItem) => x.itemId === item.itemId).quantityOrdered}
                      </td>
                      <td align="right">{item.quantityShipped}</td>
                      <td align="right">{formatCurrency(rowPrice)}</td>
                      <td align="right">{formatCurrency(getPriceInclusiveOfVAT(rowPrice, item.priceConfirmedTaxRate))}</td>
                    </tr>
                  );
                })
              }
              <tr className="tableTotals">
                <td align="right" colSpan={5}>Total</td>
                <td align="right">
                  {
                    orderItems.data &&
                    orderItems.data.reduce((a: any, { quantityOrdered }: any) => a + quantityOrdered, 0)
                  }
                </td>
                <td align="right">
                  {
                    edn.data &&
                    edn.data.orders[0].items.reduce((a: any, { quantityShipped }: any) =>
                      a + quantityShipped, 0)
                  }
                </td>
                <td align="right">
                  {formatCurrency(orderTotal)}
                </td>
                <td align="right">
                  {formatCurrency(orderTotalWithVat)}
                </td>
              </tr>
            </tbody>
          </Table>
        </div>
      </div>
    </div>
  );
};

const orderByFunction: IOrderItemSortBy = {
  'description-asc': (item1: IOrderItem, item2: IOrderItem): number =>
    sortStrings(item1.itemDescription, item2.itemDescription),
  'description-dsc': (item1: IOrderItem, item2: IOrderItem): number =>
    sortStrings(item2.itemDescription, item1.itemDescription),
};

const sortStrings = (s1: string, s2: string): number => {
  const parsed1 = s1.toUpperCase();
  const parsed2 = s2.toUpperCase();

  if (parsed1 < parsed2) {
    return -1;
  }

  if (parsed1 > parsed2) {
    return 1;
  }

  return 0;
};

const mapDispatchToProps = (dispatch: Dispatch<any>) => {
  return {
    clearOrderEdnContents: () => dispatch(clearOrderEdnContents()),
  };
};

export default connect(undefined, mapDispatchToProps)(EdnTemplate);
