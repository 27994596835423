import React, { useEffect, useState } from 'react';
import FullPageLayout from '../../../layouts/FullPageLayout/FullPageLayout';
import { BreadcrumbSegment, Button, Checkbox } from '@orderly/morrisons-component-library';
import { getBasePath } from '../../../helpers/Users/UserHelper';
import { connect } from 'react-redux';
import { IPresaleCampaign, IStoreState, IUserDetails } from '../../../types';
import CampaignEntryTable from '../../../components/Presales/CampaignEntryTable';
import { navigateTo } from '../../../routing/Navigation';
import { getPresalesCampaignRequest } from '../../../actions/SupplyChain/presalesActions';
import { Dispatch } from 'redux';
import './PreviewCampaign.scss';

interface IPreviewCampaign {
  userDetails: IUserDetails;
  presaleCampaign: IPresaleCampaign;
  match: {
    params: {
      campaignId: string;
    };
  };
  getPresalesCampaign: (campaignId: number) => void;
}

const PreviewCampaign = ({
  userDetails,
  presaleCampaign,
  match,
  getPresalesCampaign,
}:IPreviewCampaign) => {
  const [campaign, setCampaign] = useState<IPresaleCampaign>(null);
  const campaignId = parseInt(match.params.campaignId, 10);

  useEffect(() => {
    if (!presaleCampaign) {
      getPresalesCampaign(campaignId);
    }
  }, []);

  useEffect(() => {
    if (presaleCampaign) {
      setCampaign(presaleCampaign);
    }
  }, [presaleCampaign]);

  const breadcrumbs: BreadcrumbSegment[] =
    [
      { key: 0, text: 'Presales Campaigns', url: `${getBasePath(userDetails)}presales-campaigns` },
      { key: 1, text: 'Preview', url: window.location.pathname },
    ];

  return (
    <FullPageLayout
      heading="Preview Presale Campaign"
      breadcrumbs={breadcrumbs}
      homeRoute="/admin/home"
    >
      <div className="preview-campaign-wrapper">
        <CampaignEntryTable
          campaign={campaign}
          isAdminPreviewMode={true}
          onSetCampaign={setCampaign}
        />
        <div className="submit-container">
          <div className="submit-campaign-action-container" title="Edit">
            <Button
              className="submit-campaign tertiary"
              type="submit"
              text="Edit"
              onClick={() => navigateTo(`${getBasePath(userDetails)}presales-campaigns/campaign/${presaleCampaign.id}`)}
            />
          </div>
          <div>
            <div className="submit-campaign-action-container" title="Complete">
              <Button
                className="submit-campaign tertiary"
                type="submit"
                text="Complete"
                onClick={() => navigateTo(`${getBasePath(userDetails)}presales-campaigns`)}
              />
            </div>
          </div>
        </div>
      </div>
    </FullPageLayout>
  );
};

const mapStateToProps = (state: IStoreState) => {
  return {
    userDetails: state.userDetails,
    presaleCampaign: state.presales.campaign,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<any>) => ({
  getPresalesCampaign: (campaignId: number) => dispatch(getPresalesCampaignRequest(campaignId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PreviewCampaign);
