import React, { Dispatch, useRef, useState } from 'react';
import './OrderConfirmationTemplate.scss';
import Table, { isHeaderSorted } from '../Table/Table';
import logo from '../../assets/images/logo.svg';
import { IOrderConfirmationTemplateProps } from './IOrderConfirmationTemplateProps';
import { clearOrderConfirmationContents } from '../../actions/Orders/orderConfirmationAction';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { connect } from 'react-redux';
import { IOrderItem } from '../../types';
import { IOrderItemSortBy } from '../../pages/Orders/OrderDetails/IOrderItemSortBy';
import { formatCurrency } from '../../helpers/Format';

const OrderConfirmationTemplate = ({ confirmation, clearOrderConfirmationContents }: IOrderConfirmationTemplateProps) => {
  const [orderBy] = useState('description-asc');
  const orderConfirmationContainer = useRef(null);

  const printOrderConfirmation = () => {
    const input = orderConfirmationContainer.current;
    return html2canvas(input, { scrollY: (window.pageYOffset * -1) })
      .then((canvas) => {
        const imgData = canvas.toDataURL('image/png');
        const pdf = new jsPDF('p', 'mm', 'a4');

        const imgWidth = pdf.internal.pageSize.getWidth();
        const pageHeight = pdf.internal.pageSize.getHeight();

        const imgHeight = canvas.height * imgWidth / canvas.width;
        let heightLeft = imgHeight;
        let position = 0;

        pdf.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight, '', 'FAST');
        heightLeft -= pageHeight;
        while (heightLeft >= 0) {
          position = heightLeft - imgHeight;
          pdf.addPage();
          pdf.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight, '', 'FAST');
          heightLeft -= pageHeight;
        }
        pdf.save(`Order Confirmation ${confirmation.data.orders[0].orderRaisedDate}.pdf`);
        clearOrderConfirmationContents();
      });
  };

  const onNextFrame = (callback: any) => {
    setTimeout(() => requestAnimationFrame(callback), 0);
  };

  const sortOrderItems = (items: IOrderItem[]): IOrderItem[] => {
    return items.sort((item1: IOrderItem, item2: IOrderItem): number => orderByFunction[orderBy](item1, item2));
  };

  if (!confirmation.data) {
    return null;
  }

  const sortedColumnName = orderBy.substring(0, orderBy.lastIndexOf('-'));
  const sortedDescending = orderBy.substring(orderBy.lastIndexOf('-') + 1) === 'dsc';

  return (
    <div ref={orderConfirmationContainer} className="orderConfirmationTemplate">
      <div className="container">
        <div className="templateHeader">
          <div className="templateLogo">
            <img src={logo} onLoad={() => onNextFrame(printOrderConfirmation)} />
          </div>
          <div className="templateOrderDetails">
            <div className="orderNumber">
              Order Number:
              <span>
                {
                  confirmation.data &&
                  confirmation.data.orders[0] &&
                  confirmation.data.orders[0].orderId ||
                  'N/A'
                }
              </span>
            </div>
            <div className="poNumber">
              PO Number:
              <span>
                {
                  confirmation.data &&
                    confirmation.data.orders[0] &&
                    confirmation.data.orders[0].customerPONumber ?
                    confirmation.data.orders[0].customerPONumber :
                    'N/A'
                }
              </span>
            </div>
            <div className="orderLocation">
              Location:
              <span>
                {
                  confirmation.data &&
                  confirmation.data.orders[0] &&
                  confirmation.data.orders[0].shipToLocationId ||
                  'N/A'
                }
              </span>
            </div>
            <div className="orderCustomer">
              Customer:
              <span>
                {
                  confirmation.data &&
                  confirmation.data.orders[0] &&
                  confirmation.data.orders[0].customerId ||
                  'N/A'
                }
              </span>
            </div>
            <div className="orderDate">
              Order Date:
              <span>
                {
                  confirmation.data &&
                  confirmation.data.orders[0] &&
                  confirmation.data.orders[0].orderRaisedDate ||
                  'N/A'
                }
              </span>
            </div>
            <div className="deliveryDate">
              Delivery Date:
              <span>
                {
                  confirmation.data &&
                  confirmation.data.orders[0] &&
                  confirmation.data.orders[0].shipToDeliverAt ||
                  'N/A'
                }
              </span>
            </div>
            <div className="orderType">
              Order Type:
              <span>
                {
                  confirmation.data &&
                  confirmation.data.orders[0] &&
                  confirmation.data.orders[0].orderReferenceCode ||
                  'N/A'
                }
              </span>
            </div>
          </div>
        </div>
        <div className="orderConfirmationTable">
          <Table className="order-items-table">
            <thead>
              <tr>
                <th>Product Name{isHeaderSorted('description', sortedColumnName, sortedDescending)}</th>
                <th>Product Id</th>
                <th className="right">Case Size</th>
                <th className="right">Ordered QT</th>
                <th className="right">Confirmed QT</th>
                <th className="right">Rejected</th>
              </tr>
            </thead>
            <tbody>
              {
                confirmation.data &&
                sortOrderItems(confirmation.data.orders[0].items).map((item: any, index: number) => {
                  return (
                    <tr key={index}>
                      <td>{item.itemDescription}</td>
                      <td>{item.itemId}</td>
                      <td align="right">{item.itemCaseSize}</td>
                      <td align="right">{item.quantityOrdered}</td>
                      <td align="right">{item.quantityConfirmed}</td>
                      <td align="right">{item.quantityOrdered - item.quantityConfirmed}</td>
                    </tr>
                  );
                })
              }
              <tr className="tableTotals">
                <td align="right" colSpan={3}>Total</td>
                <td align="right">
                  {
                    confirmation.data &&
                    confirmation.data.orders[0].items.reduce((a: any, { quantityOrdered }: any) => a + quantityOrdered, 0)
                  }
                </td>
                <td align="right">
                  {
                    confirmation.data &&
                    confirmation.data.orders[0].items.reduce(
                      (a: any, { quantityConfirmed }: any) => a + quantityConfirmed, 0)
                  }
                </td>
                <td align="right">
                  {confirmation.data.orders[0].items.reduce((a: any, { quantityOrdered }: any) => a + quantityOrdered, 0)
                    - confirmation.data.orders[0].items.reduce(
                      (a: any, { quantityConfirmed }: any) => a + quantityConfirmed, 0)}
                </td>
              </tr>
            </tbody>
          </Table>
        </div>
      </div>
    </div>
  );
};

const orderByFunction: IOrderItemSortBy = {
  'description-asc': (item1: IOrderItem, item2: IOrderItem): number =>
    sortStrings(item1.itemDescription, item2.itemDescription),
  'description-dsc': (item1: IOrderItem, item2: IOrderItem): number =>
    sortStrings(item2.itemDescription, item1.itemDescription),
};

const sortStrings = (s1: string, s2: string): number => {
  const parsed1 = s1.toUpperCase();
  const parsed2 = s2.toUpperCase();

  if (parsed1 < parsed2) {
    return -1;
  }

  if (parsed1 > parsed2) {
    return 1;
  }

  return 0;
};

const mapDispatchToProps = (dispatch: Dispatch<any>) => {
  return {
    clearOrderConfirmationContents: () => dispatch(clearOrderConfirmationContents()),
  };
};

export default connect(undefined, mapDispatchToProps)(OrderConfirmationTemplate);
