import { Dispatch } from 'redux';
import { getCurrentTradingPack } from '../../api/Products';

export const GET_CURRENT_TRADING_PACK_REQUEST = 'GET_CURRENT_TRADING_PACK_REQUEST';
export const GET_CURRENT_TRADING_PACK_RESPONSE = 'GET_CURRENT_TRADING_PACK_RESPONSE';
export const GET_CURRENT_TRADING_PACK_ERROR = 'GET_CURRENT_TRADING_PACK_ERROR';

export const getCurrentTradingPackRequest = (): (dispatch: Dispatch) => Promise<void> => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: GET_CURRENT_TRADING_PACK_REQUEST,
    });

    try {
      const response = await getCurrentTradingPack();
      if (response.status === 200) {
        dispatch({
          type: GET_CURRENT_TRADING_PACK_RESPONSE,
          response: response.data.tradingPack,
        });
      } else {
        onError(dispatch);
      }
    } catch (err) {
      console.log(err);
      onError(dispatch);
    }
  };
};

const onError = (dispatch: Dispatch) => dispatch({
  type: GET_CURRENT_TRADING_PACK_ERROR,
});
