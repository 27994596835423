import { Action, Dispatch } from 'redux';
import {
  getMyAccount,
  IMyAccountResponse as IMyAccountResponseAPI,
} from '../../api/MyAccount/myAccountApi';
import { AxiosResponse } from 'axios';

export const GET_MY_ACCOUNT_REQUEST = 'GET_MY_ACCOUNT_REQUEST';
export type GET_MY_ACCOUNT_REQUEST = typeof GET_MY_ACCOUNT_REQUEST;

export const GET_MY_ACCOUNT_RESPONSE = 'GET_MY_ACCOUNT_RESPONSE';
export type GET_MY_ACCOUNT_RESPONSE = typeof GET_MY_ACCOUNT_RESPONSE;

export const GET_MY_ACCOUNT_ERROR = 'GET_MY_ACCOUNT_ERROR';
export type GET_MY_ACCOUNT_ERROR = typeof GET_MY_ACCOUNT_ERROR;

export interface IMyAccountResponse extends Action {
  type: GET_MY_ACCOUNT_RESPONSE;
  response: IMyAccountResponseAPI;
}

export interface IGetMyAccountError extends Action {
  type: GET_MY_ACCOUNT_ERROR;
  error?: string;
}

export const getMyAccountRequest = (): any => {
  return (dispatch : Dispatch) => {
    dispatch({
      type: GET_MY_ACCOUNT_REQUEST,
    });

    return getMyAccount()
      .then((response: AxiosResponse<IMyAccountResponseAPI>) => {
        if (response.status === 200) {
          dispatch({
            type: GET_MY_ACCOUNT_RESPONSE,
            response: response.data,
          });
        } else {
          onError(dispatch);
        }
      })
      .catch((err: any) => {
        console.log(err);
        onError(dispatch);
      });
  };
};

const onError = (dispatch: Dispatch) => dispatch({
  type: GET_MY_ACCOUNT_ERROR,
});
