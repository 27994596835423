import { Action } from 'redux';
import {
  IGetSubCategoriesParams, getSubCategories, IGetSubCategoriesResponse as IApiResponse,
} from '../api/subCategoriesApi';
import { IStoreState } from '../types';
import { store } from '../store';
import { AxiosResponse } from 'axios';

export const GET_SUB_CATEGORIES_REQUEST = 'GET_SUB_CATEGORIES_REQUEST';
export type GET_SUB_CATEGORIES_REQUEST = typeof GET_SUB_CATEGORIES_REQUEST;
export const GET_SUB_CATEGORIES_RESPONSE = 'GET_SUB_CATEGORIES_RESPONSE';
export type GET_SUB_CATEGORIES_RESPONSE = typeof GET_SUB_CATEGORIES_RESPONSE;
export const GET_SUB_CATEGORIES_ERROR = 'GET_SUB_CATEGORIES_ERROR';
export type GET_SUB_CATEGORIES_ERROR = typeof GET_SUB_CATEGORIES_ERROR;

export interface IGetSubCategoriesResponse extends Action {
  type: GET_SUB_CATEGORIES_RESPONSE;
  response: IGetSubCategoriesResponse;
}

export interface IGetSubCategoriesError extends Action {
  type: GET_SUB_CATEGORIES_ERROR;
  error?: string;
}

export const getSubCategoriesRequest = (parameters: IGetSubCategoriesParams): any => {
  const state: IStoreState = store.getState();
  const subCategories = state.subCategories[parameters.categoryId];

  if (subCategories  && state.catalogueMeta.meta.updatedAt &&
      state.catalogueMeta.meta.updatedAt < subCategories.UpdatedAt) {
    return {
      type: GET_SUB_CATEGORIES_RESPONSE,
      response: { [parameters.categoryId]: subCategories },
    };
  }

  return (dispatch : any) => {
    dispatch({
      type: GET_SUB_CATEGORIES_REQUEST,
    });

    return getSubCategories(parameters)
      .then((response: AxiosResponse<IApiResponse>) => {
        if (response.status === 200) {
          dispatch({
            type: GET_SUB_CATEGORIES_RESPONSE,
            response: {
              [parameters.categoryId]: {
                data: response.data.subcategories,
                loading: false,
                UpdatedAt: new Date(),
                error: '',
              },
            },
          });

        } else {
          onError(dispatch);
        }
      })
      .catch((err: any) => {
        console.log(err);
        onError(dispatch);
      });
  };
};

const onError = (dispatch: any) => dispatch({
  type: GET_SUB_CATEGORIES_ERROR,
});
