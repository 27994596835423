import React, { FormEvent, useState } from 'react';
import './ContactUsCard.scss';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { Button, Dropdown, DropdownOption, TextArea } from '@orderly/morrisons-component-library';
import { IAdminContactForm, IStoreState } from '../../types';
import { IAdminContactFormResponse, IPostAdminContactFormParams } from '../../api/Admin/adminContactFormApi';
import { postAdminContactFormRequest } from '../../actions/Admin/adminContactFormAction';

interface IContactUsCardProps {
  contactForm: IAdminContactForm;
  postContactForm?: (params: IPostAdminContactFormParams) => Promise<IAdminContactFormResponse>;
}

const ContactUsCard = ({
  contactForm,
  postContactForm,
}: IContactUsCardProps) => {
  const [subject, setSubject] = useState('');
  const [subjectError, setSubjectError] = useState('');
  const [message, setMessage] = useState('');
  const [messageError, setMessageError] = useState('');

  const contactOptions = [
    { label: 'Order Query', value: 'Order Query' },
    { label: 'Product/Catalogue Query', value: 'Product/Catalogue Query' },
    { label: 'Website Query', value: 'Website Query' },
    { label: 'Order Opportunities Query', value: 'Order Opportunities Query' },
    { label: 'Account Query', value: 'Account Query' },
    { label: 'Other', value: 'Other' },
  ];

  const submitContactForm = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (!validateMessage()) {
      return;
    }

    try {
      await postContactForm({ subject, message });
      resetForm();
    } catch (error) {
      console.log('An error has occured: ', error);
    }
  };

  const resetForm = () => {
    setSubjectError('');
    setSubject('');
    setMessageError('');
    setMessage('');
  };

  const validateMessage = () => {
    const subjectValid = subject.trim().length !== 0;
    const messageValid = message.trim().length !== 0;

    setSubjectError(subjectValid ? '' : 'This is a required field');
    setMessageError(messageValid ? '' : 'This is a required field');

    return subjectValid && messageValid;
  };

  return (
    <div className="contact-us card">
      <h4>Contact Us</h4>
      <p>Please use the form below to get in touch with Orderly Support.</p>
      <form onSubmit={submitContactForm}>
        <Dropdown
          name="subject"
          label="Subject"
          className="contact-us-subject"
          placeholder="Select a subject"
          options={contactOptions}
          selectedValue={subject}
          onChange={(value: DropdownOption) => { setSubject(value.value as string); }}
          error={subjectError}
        />
        <TextArea
          name="message"
          label="Message"
          className="contact-us-message"
          value={message}
          validationError={messageError}
          onChange={(value: string) => { setMessage(value); }}
        />
        <Button
          type="submit"
          text={contactForm.loading ? 'Sending' : 'Get in Touch!'}
          className="secondary"
          disabled={contactForm.loading}
        />
        <Button
          type="button"
          text="Reset form"
          className="link contact-us-reset"
          onClick={() => resetForm()}
        />
      </form>
    </div>
  );
};

const mapStateToProps = (state: IStoreState) => {
  return {
    contactForm: state.adminContactForm,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<any>) => {
  return {
    postContactForm: (parameters: IPostAdminContactFormParams) =>
      dispatch(postAdminContactFormRequest(parameters)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ContactUsCard);
