import React, { useEffect } from 'react';
import './Modal.scss';
import { Button } from '@orderly/morrisons-component-library';
import ScrollHelper from '../../helpers/ScrollHelper';

interface IModalProps {
  isOpen: boolean;
  header: string;
  children: React.ReactNode;
  buttonText?: string;
  onClose: () => void;
  onCancel?: () => void;
  disableClose?: boolean;
}

const Modal = ({
  isOpen,
  header,
  children,
  onClose,
  buttonText = 'Close',
  onCancel,
  disableClose = false,
}: IModalProps) => {

  useEffect(
    () => {
      if (isOpen) {
        ScrollHelper.preventBodyScroll();
      } else {
        ScrollHelper.allowBodyScroll();
      }

      return () => ScrollHelper.allowBodyScroll();
    },
    [isOpen],
  );

  return (
    <div className={`modal-dialog-wrapper ${isOpen && 'open'}`}>
      <div className="modal-dialog-container">
        <div className="modal-dialog-header">
          <h4>{header}</h4>
        </div>
        <div className="modal-dialog-content">
          {children}
        </div>
        <div className="modal-dialog-footer">
          <Button
            type="button"
            className="primary"
            text={buttonText}
            onClick={() => { onClose(); }}
            disabled={disableClose}
          />
          {
            onCancel ?
            <Button
              type="button"
              className="tertiary"
              text="Cancel"
              onClick={() => { onCancel(); }}
            />
            : null
          }
        </div>
      </div>
    </div>
  );
};

export default Modal;
