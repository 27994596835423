import React from 'react';
import { IProductDiscountPriceProps } from './IProductDiscountPriceProps';
import { formatCurrency } from '../../helpers/Format';
import styles from './ProductDiscountPrice.module.scss';
import ProductCardInfo from '../ProductCardInfo';

function ProductDiscountPrice({
  oldPrice,
  newPrice,
  difference,
  tooltip,
  description,
  isListView,
}: IProductDiscountPriceProps): JSX.Element {

  if (isListView) {
    return (
      <div className={styles.container} title={tooltip}>
        <div className={styles.row}>
          <div>Was:</div>
          <div className={styles.strikethrough}>
            {oldPrice}
          </div>
        </div>
        <div className={styles.row}>
          <div>Now:</div>
          <div className="discount-price">
            {newPrice}
          </div>
        </div>
        {
          difference > 0 &&
          <div className={styles.row}>
            <div>Save:</div>
            <div className={styles.green}>
              {formatCurrency(difference)}
            </div>
          </div>
        }
      </div>
    );
  }

  return (
    <div className={styles.altContainer} title={tooltip}>
      <ProductCardInfo
        value={newPrice}
        description={description}
        strikethrough={oldPrice}
      />
      {
        difference > 0 &&
        <div>
          <span>Save:&nbsp;</span>
          <span className={styles.green}>
            {formatCurrency(difference)}
          </span>
        </div>
      }
    </div>
  );
}

export default ProductDiscountPrice;
