import { Dispatch } from 'redux';
import { getTags, IGetTagsParams } from '../../api/Documents/getTags';

export const getTagsRequest = (params: IGetTagsParams): (dispatch: Dispatch) => Promise<void> => {
  return async (dispatch: Dispatch): Promise<void> => {
    try {
      dispatch({
        type: 'GET_TAGS_REQUEST',
      });

      const response = await getTags(params);

      if (response.status === 200) {
        dispatch({
          type: 'GET_TAGS_RESPONSE',
          response: response.data.tags,
        });
      }
    } catch (error) {
      console.log(error);
      onError(dispatch);
    }
  };
};

const onError = (dispatch: Dispatch, message: string = null): any => dispatch({
  type: 'GET_TAGS_ERROR',
  response: message,
});
