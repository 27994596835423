import { Reducer } from 'redux';
import { IOrdersDisplayModeState } from './IOrdersDisplayModeState';

const initialState: IOrdersDisplayModeState = {
  displayMode: 'submitted',
};

const ordersDisplayModeReducer: Reducer<IOrdersDisplayModeState> = (
  state: IOrdersDisplayModeState = initialState,
  action: any,
): IOrdersDisplayModeState => {
  if (action.type === 'ORDERS_DISPLAY_MODE_PERSIST') {
    return {
      displayMode: action.data.displayMode,
    };
  }

  return state;
};

export default ordersDisplayModeReducer;
