import './Presales.scss';
import React, { Dispatch, useEffect, useState } from 'react';
import LoadingThrobber from '../../components/LoadingThrobber/LoadingThrobber';
import BootstrapTable from 'react-bootstrap-table-next';
import { IApiRequest, IPresaleCampaign, IRetailerStores, IStoreState } from '../../types';
import { connect } from 'react-redux';
import moment from 'moment';
import { getPresalesCampaignsRequest } from '../../actions/SupplyChain/presalesActions';
import { BreadcrumbSegment, Button } from '@orderly/morrisons-component-library';
import { navigateTo } from '../../routing/Navigation';
import FullPageLayout from '../../layouts/FullPageLayout/FullPageLayout';
import { getMyUserStoresRequest } from '../../actions/Users/userStoresAction';
import Table from '../../components/Table/Table';

interface IPresalesProps {
  dbCampaigns: IPresaleCampaign[];
  loading: boolean;
  userStores: IApiRequest<IRetailerStores>;
  getCampaigns: () => void;
  getUserStores: () => void;
}

const Presales = ({
  dbCampaigns,
  loading,
  userStores,
  getCampaigns,
  getUserStores,
}: IPresalesProps) => {
  const [campaigns, setCampaigns] = useState([]);

  useEffect(() => {
    getCampaigns();
    getUserStores();
  }, []);

  useEffect(() => {
    if (dbCampaigns && dbCampaigns.length > 0 && userStores.data && userStores.data.stores) {
      const sortedCampaigns = dbCampaigns.sort((a: IPresaleCampaign, b: IPresaleCampaign) => {
        return new Date(a.deadline).getTime() - new Date(b.deadline).getTime();
      });

      let index = 1;

      const formattedCampaigns = sortedCampaigns
        .flatMap((campaign) => {
          return userStores.data.stores.map((store) => {
            const matchedStore = campaign.stores.find(x => x.storeId === store.id);
            if (!matchedStore) {
              return null;
            }

            return {
              ...campaign,
              index: index++,
              storeName: `${store.name} - ${store.externalId}`,
              url: `presales/campaign${campaign.id}/store${store.id}`,
              completion: matchedStore.campaignCompletionPercentage || 0,
            };
          });
        })
        .filter(campaign => campaign !== null);

      setCampaigns(formattedCampaigns);
    }
  }, [dbCampaigns, userStores]);

  const breadcrumbs: BreadcrumbSegment[] = [
    { key: 0, text: 'Home', url: '/home/' },
    { key: 1, text: 'Presales', url: window.location.pathname },
  ];

  return (
    <FullPageLayout heading="Presale Campaigns" breadcrumbs={breadcrumbs}>
      {(loading || userStores.loading || !campaigns) && <LoadingThrobber />}
        {!loading && (
          <Table className="presales-campaigns-table">
            <thead>
              <tr>
                <th>Event</th>
                <th>Category</th>
                <th>Presale</th>
                <th>Store</th>
                <th>Deadline</th>
                <th>Completion</th>
                <th />
              </tr>
            </thead>
            <tbody>
              {
                campaigns.length === 0 ?
                  <tr>
                    <td colSpan={6}>No presale campaigns to review</td>
                  </tr> :
                  campaigns.map((campaign, index) => {
                    return (
                      <tr key={index}>
                      <td>
                          {campaign.event}
                        </td>
                      <td>
                          {campaign.category}
                        </td>
                      <td>
                      <Button
                        type="button"
                        className="Table-viewDetailsAction"
                        text={campaign.name}
                        onClick={() => {
                          navigateTo(campaign.url);
                        }}
                      />
                        </td>
                      <td>
                          {campaign.storeName}
                        </td>
                      <td>
                        {(() => {
                          const formattedDate = moment(campaign.deadline).format('DD/MM/YYYY');

                          const today = moment().startOf('day');
                          const deadlineDate = moment(campaign.deadline).startOf('day');
                          const differenceInDays = deadlineDate.diff(today, 'days');

                          let color = 'red';
                          let suffix = `${differenceInDays} day${differenceInDays === 1 ? '' : 's'}`;

                          if (differenceInDays < 0) {
                            suffix = 'Overdue';
                            color = 'red';
                          } else if (differenceInDays === 0) {
                            suffix = 'Today';
                          } else if (differenceInDays <= 3) {
                            color = 'orange';
                          } else {
                            color = 'green';
                          }

                          return (
                            <p style={{ color }}>
                              {formattedDate} ({suffix})
                            </p>
                          );
                        })()}
                        </td>
                        <td>
                          {campaign.completion}%
                        </td>
                      </tr>
                    );
                  })
              }
            </tbody>
          </Table>
        )}
    </FullPageLayout>
  );
};

const mapStateToProps = (state: IStoreState): any => {
  return {
    dbCampaigns: state.presales.campaigns.activeCampaigns,
    loading: state.presales.loading,
    userStores: state.userStores,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<any>) => ({
  getCampaigns: () => dispatch(getPresalesCampaignsRequest(true)),
  getUserStores: () => dispatch(getMyUserStoresRequest()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Presales);
