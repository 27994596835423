import { Reducer } from 'redux';
import { IApiRequest, IOrderOpportunity } from '../../types';
import {
  GET_ORDER_OPPORTUNITIES_COUNTDOWN_ERROR,
  GET_ORDER_OPPORTUNITIES_COUNTDOWN_REQUEST,
  GET_ORDER_OPPORTUNITIES_COUNTDOWN_RESPONSE,
} from '../../actions/OrderOpportunities/orderOpportunitiesCountdownAction';

const initialState: IApiRequest<IOrderOpportunity[]> = {
  loading: false,
  UpdatedAt: null,
  data: [],
  error: '',
};

const orderOpportunitiesCountdownReducer: Reducer<IApiRequest<IOrderOpportunity[]>> = (
  state: IApiRequest<IOrderOpportunity[]> = initialState,
  action : any,
  ) => {
  switch (action.type) {
    case GET_ORDER_OPPORTUNITIES_COUNTDOWN_REQUEST: {
      return {
        ...initialState,
        loading: true,
        UpdatedAt: new Date().toISOString(),
      };
    }
    case GET_ORDER_OPPORTUNITIES_COUNTDOWN_RESPONSE: {
      return {
        ...initialState,
        UpdatedAt: new Date().toISOString(),
        data: action.response,
      };
    }
    case GET_ORDER_OPPORTUNITIES_COUNTDOWN_ERROR: {
      return {
        ...initialState,
        UpdatedAt: new Date().toISOString(),
        error: action.error || 'There was an error loading the order opportunities countdown.',
      };
    }
    default: {
      return state;
    }
  }
};

export default orderOpportunitiesCountdownReducer;
