import { toastr } from 'react-redux-toastr';
import { AxiosResponse } from 'axios';
import {
  IImportTransportCostsParams,
  IImportTransportCostsResponse,
  exportTransportCosts,
  importTransportCosts,
  IExportTransportCostsResponse,
} from '../../api/Stores/storesApi';

export const EXPORT_TRANSPORT_COSTS_REQUEST = 'EXPORT_TRANSPORT_COSTS_REQUEST';
export const EXPORT_TRANSPORT_COSTS_RESPONSE = 'EXPORT_TRANSPORT_COSTS_RESPONSE';
export const EXPORT_TRANSPORT_COSTS_ERROR = 'EXPORT_TRANSPORT_COSTS_ERROR';

export const IMPORT_TRANSPORT_COSTS_REQUEST = 'IMPORT_TRANSPORT_COSTS_REQUEST';
export const IMPORT_TRANSPORT_COSTS_RESPONSE = 'IMPORT_TRANSPORT_COSTS_RESPONSE';
export const IMPORT_TRANSPORT_COSTS_ERROR = 'IMPORT_TRANSPORT_COSTS_ERROR';

export const exportTransportCostsRequest = (onExport: (data: any) => void): any => {
  return (dispatch: any) => {
    dispatch({
      type: EXPORT_TRANSPORT_COSTS_REQUEST,
    });
    return exportTransportCosts()
        .then((response: AxiosResponse<IExportTransportCostsResponse>) => {
          if (response.status === 200) {
            toastr.success('Transport Costs', 'Transport Costs have been exported successfully.');
            dispatch({
              type: EXPORT_TRANSPORT_COSTS_RESPONSE,
            });
            onExport(response.data);
          } else {
            onExportError(dispatch);
          }
        })
        .catch((err: any) => {
          console.log(err);
          onExportError(dispatch);
        });
  };
};
const onExportError = (dispatch: any) => {
  dispatch({
    type: EXPORT_TRANSPORT_COSTS_RESPONSE,
  });
  toastr.error('Error', 'An error has occurred whilst exporting transport costs. Please try again.');
};

export const importTransportCostsRequest = (
  parameters: IImportTransportCostsParams,
  onResponse: (data: any) => void): any => {
  return (dispatch: any) => {
    dispatch({
      type: IMPORT_TRANSPORT_COSTS_REQUEST,
    });

    return importTransportCosts(parameters)
      .then((response: AxiosResponse<IImportTransportCostsResponse>) => {
        if (response.status === 200) {
          onResponse(response.data.importTransportCosts.results);
          if (response.data.importTransportCosts.success) {
            toastr.success('Transport Costs', 'Transport costs have been imported successfully.');
            dispatch({
              type: IMPORT_TRANSPORT_COSTS_RESPONSE,
            });
          } else {
            toastr.error('Error', 'An error has occurred whilst importing transport costs. Please try again.');
          }
        } else {
          onImportError(dispatch);
        }
      })
      .catch((err: any) => {
        console.log(err);
        onImportError(dispatch);
      });
  };
};

const onImportError = (dispatch: any) => {
  dispatch({
    type: IMPORT_TRANSPORT_COSTS_ERROR,
  });
  toastr.error('Error', 'An error has occurred whilst importing transport costs. Please try again.');
};
