import React, { Dispatch, useEffect, useMemo, useState } from 'react';
import './Rebates.scss';
import { Card, Dropdown } from '@orderly/morrisons-component-library';
import { formatDateToMonthYear } from '../../helpers/Format/formatDateMonthYear';
import {
  IApiRequest,
  IRebate,
  IRebatesResponse,
  IRetailerDetails,
  IRetailerStore,
  IStoreState,
  IUserDetails,
} from '../../types';
import { IGetRebatesParams } from '../../api/Rebates/getRebatesApi';
import { getRebatesRequest } from '../../actions/Rebates/rebatesAction';
import { connect } from 'react-redux';
import LoadingThrobber from '../../components/LoadingThrobber/LoadingThrobber';
import { formatCurrencyLocale } from '../../helpers/Format/formatCurrency';
import FormatDateHelper from '../../helpers/Format/Date/FormatDateHelper';
import moment from 'moment';
import { FullPageLayout } from '../../layouts';
import { EmptyRecord } from '../../components';
import { HelpCircle } from 'iconoir-react';
import RebatePeriodCard from './components/RebatePeriodCard';
import { toastr } from 'react-redux-toastr';

interface IProps {
  getRebates: (params: IGetRebatesParams) => void;
  rebatesState: IApiRequest<IRebatesResponse>;
  userDetails: IUserDetails;
  retailerDetails: IRetailerDetails;
}

const Rebates = (props: IProps) => {
  const [selectedStore, setSelectedStore] = useState(null);
  const [currentRebate, setCurrentRebate] = useState<IRebate>(null);

  const { getRebates, rebatesState, retailerDetails } = props;
  const { data, loading } = rebatesState;
  const { rebates } = data;

  const getLatestRebate = (rebates: IRebate[]) => {
    if (rebates.length === 0) {
      return null;
    }

    const latestRebate = rebates[rebates.length - 1];
    return latestRebate;
  };

  useEffect(
    () => {
      if (retailerDetails.meta && retailerDetails.stores.length > 0) {
        const requestParams: IGetRebatesParams = {
          retailerId: retailerDetails.id,
          storeId: retailerDetails.stores[0].externalId,
          date: FormatDateHelper.formatDate(new Date()),
          limit: 100,
          offset: 0,
        };

        getRebates(requestParams);

        setSelectedStore(retailerDetails.stores[0].externalId);
      }
    },
    [retailerDetails],
  );

  useEffect(
    () => {
      const latestRebate = getLatestRebate(rebates);
      setCurrentRebate(latestRebate);
    },
    [rebates],
  );

  useEffect(() => {
    if (!rebatesState.loading && selectedStore) {
      const requestParams: IGetRebatesParams = {
        retailerId: retailerDetails.id,
        storeId: selectedStore,
        date: FormatDateHelper.formatDate(new Date()),
        limit: 100,
        offset: 0,
      };

      getRebates(requestParams);
    }
  }, [selectedStore]);

  const getProgressBarBackgroundSize = (value: number, range: string[]) => {
    let position = 0;
    let rangeValues = range;
    if (rangeValues == null) {
      rangeValues = ['1%', '2%', '3%', '4%', '5%'];
    }

    let rangeNumbers = rangeValues.map((value: string): number => {
      const castedRangeValue = Number.parseFloat(value.replace('%', '').trim());
      if (!Number.isNaN(castedRangeValue)) {
        return castedRangeValue;
      }
      return 0;
    });
    rangeNumbers = [...rangeNumbers, 0];
    rangeNumbers = rangeNumbers.sort((a, b) => a - b);

    for (let i = 0; i < rangeNumbers.length - 1; i++) {
      if (rangeNumbers[i] <= value && value <= rangeNumbers[i + 1]) {
        const intervalStart = rangeNumbers[i];
        const intervalEnd = rangeNumbers[i + 1];
        const intervalRange = intervalEnd - intervalStart;
        const relativePosition = (value - intervalStart) / intervalRange;
        const iterationRelativePosition = i * 100 / (rangeNumbers.length - 1);
        const rangeRelativePosition = relativePosition * 100 / (rangeNumbers.length - 1);
        position = Math.floor(iterationRelativePosition + rangeRelativePosition);
      }
    }
    if (!position) {
      position = 1;
    }

    return { backgroundSize: `${position}% 100%` };
  };

  const getValue = (value: number | null): number => {
    if (value === null) {
      return 0;
    }

    return value;
  };

  const endDate = useMemo(() => {
    if (!currentRebate) {
      return new Date();
    }

    return moment(currentRebate.rebatePeriodEndDate).subtract(2, 'days').toDate();
  }, [currentRebate]);

  const byTooltip = useMemo(() => {
    if (!currentRebate) {
      return '';
    }

    const endDateFormatted = FormatDateHelper.formatShortDate(endDate);
    return `Orders should be placed before the ${endDateFormatted} to be considered within the current rebate period`;
  }, [currentRebate]);

  const showHelpMessage = () => {
    toastr.confirm(byTooltip, { disableCancel: true, onCancel: () => { } });
  };

  return (
    <FullPageLayout
      heading={`Rebates Overview ${currentRebate ? `- ${currentRebate.storeName}` : ''}`}
      breadcrumbs={[
        {
          key: 0,
          text: 'Rebates',
          url: window.location.pathname,
        },
      ]}
      headerAdditionalContent={
        retailerDetails.stores.length > 1 &&
        <Dropdown
          className="rebates-stores-select"
          label=""
          name="catalogue-sort"
          onChange={value => setSelectedStore(value.value)}
          selectedValue={selectedStore}
          options={retailerDetails.stores.map((store: IRetailerStore) => {
            return {
              value: store.externalId,
              label: `${store.name} [${store.externalId}]`,
            };
          })}
        />
      }
    >
      <div className="rebates-wrapper">
        {
          loading ? <LoadingThrobber /> :
            (!loading && !currentRebate) &&
            <EmptyRecord message="No rebates for the current period." />
        }
        {
          currentRebate &&
          <>
            <Card className="rebates-content-desktop">
              <table className="rebates-overall-info">
                <thead>
                  <tr>
                    <th>PERIOD</th>
                    <th>STATEMENT DATE</th>
                    <th>REBATE AMOUNT</th>
                    <th>REBATE %</th>
                    <th>SALES AMOUNT</th>
                    <th>ADDITIONAL SALES REQUIRED</th>
                    <th onClick={showHelpMessage} title={byTooltip}>
                      BY <HelpCircle className="help-icon" />
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{formatDateToMonthYear(new Date(currentRebate.rebatePeriodStartDate), true)}</td>
                    <td>{FormatDateHelper.formatDate(new Date(currentRebate.createdDate))}</td>
                    <td>{formatCurrencyLocale(currentRebate.rebateAmount)}</td>
                    <td>{getValue(currentRebate.rebatePercentageApplicable)}%</td>
                    <td>{formatCurrencyLocale(currentRebate.storeSalesEligibleAmount)}</td>
                    <td>{formatCurrencyLocale(currentRebate.currentRebateCycleTarget.salesRequiredToReachNextTier)}</td>
                    <td>{FormatDateHelper.formatShortDate(endDate)}</td>
                  </tr>
                </tbody>
              </table>
            </Card>
            <div className="rebates-content-mobile">
              <div className="rebate-progress-bar">
                <input
                  type="range"
                  disabled
                  style={
                    getProgressBarBackgroundSize(
                      currentRebate.rebatePercentageApplicable,
                      currentRebate.rebateTierRange,
                    )
                  }
                />
                <div className="rebate-progress-bar-values">
                  <div />
                  {currentRebate.rebateTierRange != null
                    ? currentRebate.rebateTierRange.map((rangeValue: string, idx: number): any =>
                      <div key={idx}>{rangeValue}</div>,
                    ) : <>
                      <div>1%</div>
                      <div>2%</div>
                      <div>3%</div>
                      <div>4%</div>
                      <div>5%</div>
                    </>}
                </div>
              </div>
              <div className="period-cards">
                {
                  (
                    currentRebate.previousRebateCycleInformation &&
                    currentRebate.previousRebateCycleInformation.rebateId
                  ) &&
                  <RebatePeriodCard
                    heading="Last Period"
                    rebateAmount={
                      getValue(currentRebate.previousRebateCycleInformation.rebateAmount)
                    }
                    rebatePercentageApplicable={
                      getValue(currentRebate.previousRebateCycleInformation.rebatePercentageApplicable)
                    }
                    storeSalesAmount={
                      getValue(currentRebate.previousRebateCycleInformation.storeSalesAmount)
                    }
                    tobaccoSalesbyStore={
                      getValue(currentRebate.previousRebateCycleInformation.tobaccoSalesbyStore)
                    }
                    storeSalesEligibleAmount={
                      getValue(currentRebate.previousRebateCycleInformation.storeSalesEligibleAmount)
                    }
                    previousPeriod={true}
                  />
                }
                <RebatePeriodCard
                  heading="Current Period"
                  rebateAmount={getValue(currentRebate.rebateAmount)}
                  rebatePercentageApplicable={getValue(currentRebate.rebatePercentageApplicable)}
                  storeSalesAmount={getValue(currentRebate.storeSalesAmount)}
                  tobaccoSalesbyStore={getValue(currentRebate.tobaccoSalesbyStore)}
                  storeSalesEligibleAmount={getValue(currentRebate.storeSalesEligibleAmount)}
                />
              </div>
              <div className="expenditure-blocks">
                <div className="expenditure-block card current-rebate-value">
                  <p>Rebated earned to date</p>
                  <h1>{formatCurrencyLocale(currentRebate.rebateAmount)}</h1>
                </div>
                <div className="expenditure-block card next-rebate-tier">
                  <p>Spend an Extra</p>
                  <h1>{formatCurrencyLocale(currentRebate.currentRebateCycleTarget.salesRequiredToReachNextTier)}</h1>
                  <p>
                    <span>
                      By {FormatDateHelper.formatDate(endDate)}
                      &nbsp;to reach the next rebate tier of
                      &nbsp;{currentRebate.currentRebateCycleTarget.nextQualifyingRebatePercentage}%
                    </span>
                  </p>
                </div>
              </div>
            </div>
          </>
        }
      </div>
    </FullPageLayout>
  );
};

const mapStateToProps = (state: IStoreState) => {
  return {
    rebatesState: state.rebates,
    retailerDetails: state.retailerDetails.data,
    userDetails: state.userDetails,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<any>) => {
  return {
    getRebates: (parameters: IGetRebatesParams) => dispatch(getRebatesRequest(parameters)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Rebates);
