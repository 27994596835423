import React, { Dispatch, useEffect, useState, useRef } from 'react';
import './UploadShoppingList.scss';
import { Button, Card, Checkbox, TextInput } from '@orderly/morrisons-component-library';
import Table from '../../../../components/Table/Table';
import { IRetailer, IStoreState } from '../../../../types';
import { connect } from 'react-redux';
import LoadingThrobber from '../../../../components/LoadingThrobber/LoadingThrobber';
import { getRetailersRequest } from '../../../../actions/retailersAction';
import Popup from '../../../../components/popup/Popup';
import { IPopupPropsConfig } from '../../../../components/popup/interfaces/IPopupPropsConfig';
import { IPopupPropsConfigContentAction } from '../../../../components/popup/interfaces/IPopupPropsConfigContentAction';
import { IUploadShoppingListItem, IUploadShoppingListProps } from './IUploadShoppingListProps';
import * as xlsx from 'xlsx';
import { IUploadShoppingListBody } from '../../../../api/ShoppingLists/shoppingListsApi';
import { uploadShoppingListRequest } from '../../../../actions/ShoppingLists/shoppingListsActions';
import { toastr } from 'react-redux-toastr';
import { FullPageLayout } from '../../../../layouts';

interface ICustomerGroup {
  description: string;
  numRetailers: number;
}

const UploadShoppingList = (
  {
    retailers,
    shoppingListState,
    getRetailers,
    uploadShoppingList,
  }: IUploadShoppingListProps) => {
  const [importLoading, setImportLoading] = useState(false);
  const importShoppingListElement = useRef(null);
  const [importResults, setImportResults] = useState<IUploadShoppingListItem[]>([]);
  const [description, setDescription] = useState('');
  const [isTradingPack, setIsTradingPack] = useState(false);
  const [customerGroups, setCustomerGroups] = useState<ICustomerGroup[]>([]);
  const [search, setSearch] = useState('');
  const [selectedRetailers, setSelectedRetailers] = useState([]);
  const [selectedCustomerGroups, setSelectedCustomerGroups] = useState([]);
  const [openPopup, setOpenPopup] = useState(false);
  const [clickedItemId, setClickedItemId] = useState(0);
  const [popupTargetElement, setPopupTargetElement] = useState(null);

  useEffect(() => {
    getRetailers();
  }, []);

  useEffect(() => {
    if (retailers.data && retailers.data.length > 0) {
      const uniqueCustomerGroups = [...new Set(retailers.data.map(x => x.meta.CustomerGroup))];
      const finalCustomerGroups = uniqueCustomerGroups.filter(x => x).map((customerGroup) => {
        return (
        {
          description: customerGroup,
          numRetailers: retailers.data.filter(x => x.meta.CustomerGroup === customerGroup).length,
        }
        );
      });
      setCustomerGroups(finalCustomerGroups);
    }
  }, [retailers]);

  const onShoppingListImport = () => {
    setImportLoading(true);
    const [file] = importShoppingListElement.current.files;
    const fileReader = new FileReader();

    fileReader.onload = () => {
      setImportLoading(false);
      const fileData = fileReader.result;

      const workbook = xlsx.read(fileData, { type: 'array' });

      const sheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[sheetName];
      const jsonData: Record<string, number>[] = xlsx.utils.sheet_to_json(worksheet);

      if (jsonData.length > 0 && !('MIN' in jsonData[0])) {
        toastr.error('Error',
        "The sheet is missing the required 'MIN' column.");
        return;
      }

      const items: IUploadShoppingListItem[] = jsonData.map((x: any) => ({ itemId: x.MIN }));
      const invalidItems = items.filter(x => x.itemId.toString().length !== 9);

      if (invalidItems.length > 0) {
        toastr.error('Error',
          "The sheet contains invalid 'MIN' values. 'MIN' values must have 9 digits.");
        return;
      }

      setImportResults(items);
      importShoppingListElement.current.value = null;
    };

    if (file) {
      fileReader.readAsArrayBuffer(file);
    }
  };

  const retailerClicked = (value: string, checked: boolean): void => {
    const parsedValue = parseInt(value, 10);
    const customerGroup = retailers.data.find(x => x.id === parsedValue).meta.CustomerGroup;

    const updatedRetailers = checked
      ? [...new Set([...selectedRetailers, parsedValue])]
      : selectedRetailers.filter(x => x !== parsedValue);

    setSelectedRetailers(updatedRetailers);

    // if every retailer of a group is selected, make the group selected as well
    if (customerGroup) {
      const relatedRetailerIds = retailers.data
        .filter(x => x.meta.CustomerGroup === customerGroup)
        .map(x => x.id);

      const customerGroupChecked = relatedRetailerIds.every(x => updatedRetailers.includes(x));

      const updatedCustomerGroups = customerGroupChecked
        ? [...new Set([...selectedCustomerGroups, customerGroup])]
        : selectedCustomerGroups.filter(x => x !== customerGroup);
      setSelectedCustomerGroups(updatedCustomerGroups);
    }
  };

  const customerGroupClicked = (value: string, checked: boolean): void => {
    const customerGroupRetailerIds = retailers.data
      .filter(x => x.meta.CustomerGroup === value)
      .map(x => x.id);

    if (customerGroupRetailerIds.length > 0) {
      const updatedRetailers = checked
        ? [...new Set([...selectedRetailers, ...customerGroupRetailerIds])]
        : selectedRetailers.filter(x => !customerGroupRetailerIds.includes(x));
      setSelectedRetailers(updatedRetailers);
    }

    const updatedCustomerGroups = checked
      ? [...new Set([...selectedCustomerGroups, value])]
      : selectedCustomerGroups.filter(x => x !== value);
    setSelectedCustomerGroups(updatedCustomerGroups);
  };

  const onItemRemoved = (itemId: number) => {
    const index = importResults.findIndex(item => item.itemId === itemId);

    if (index !== -1) {
      const newImportResults = [...importResults];
      newImportResults.splice(index, 1);
      setImportResults(newImportResults);
    }
  };

  const setPopupConfig = (item: IUploadShoppingListItem): IPopupPropsConfig => {
    const actions: IPopupPropsConfigContentAction[] = [
      {
        onClick: (): void => {
          onItemRemoved(item.itemId);
        },
        label: {
          icon: '',
          text: 'Delete',
        },
      },
    ];

    return {
      content: {
        actions,
      },
    };
  };

  const showPopup = (id: number): boolean => {
    const popupInstance: any = document.getElementById(id.toString());

    if (popupInstance !== null) {
      setClickedItemId(NaN);

      return false;
    }
    return (id === clickedItemId);
  };

  const closePopup = (e: Element | HTMLElement): void => {
    setPopupTargetElement(e);
    setClickedItemId(clickedItemId);
    setOpenPopup(!openPopup);
  };

  const handleUpload = () => {
    const itemIds = importResults.map(x => x.itemId);
    const params : IUploadShoppingListBody = {
      itemIds,
      description,
      isTradingPack,
      retailerIds: selectedRetailers,
    };
    uploadShoppingList(params);
  };

  const canUpload = () => {
    return importResults && importResults.length > 0 &&
      description.length > 0 &&
      selectedRetailers.length > 0;
  };

  return (
    <FullPageLayout
      heading="Upload Shopping List"
      breadcrumbs={[
        {
          key: 0,
          text: 'Admin',
          url: '/admin/home',
        },
        {
          key: 1,
          text: 'Manage Shopping Lists',
          url: '/admin/manage-shopping-lists',
        },
        {
          key: 2,
          text: 'Upload Shopping Lists',
          url: '/admin/upload-shopping-lists',
        },
      ]}
    >
      {(importLoading || retailers.loading || shoppingListState.loading) && <LoadingThrobber />}
      <div className="upload-shopping-list-content-wrapper">
        <input
          type="file"
          accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
          ref={importShoppingListElement}
          style={{
            display: 'none',
          }}
          onChange={() => onShoppingListImport()}
          aria-label="Import shopping list form"
        />
        <div className="shopping-list-controls">
          <Card>
            <span className="box-shadow">
              <h3>Filter By</h3>
            </span>
            <div className="shopping-list-search-wrapper">
              <div className="searchbar-wrapper">
                <TextInput
                  error=""
                  label="Search"
                  name="shopping-list-search"
                  onChange={(val: string) => setSearch(val)}
                  type="text"
                  value={search}
                  className="shopping-list-search"
                />
                <span className="icon-search" />
              </div>
              <h4>Customer Groups</h4>
              {customerGroups.filter(x => x.description.toLowerCase().includes(search)).map(
                (x: ICustomerGroup) => <Checkbox
                  checked={selectedCustomerGroups.indexOf(x.description) !== -1}
                  key={x.description}
                  label={`${x.description} (${x.numRetailers})`}
                  name={x.description}
                  onChange={customerGroupClicked}
                  isSmall={true}
                />,
              )}
              <h4>Retailer</h4>
              {retailers.data.filter(x => x.externalIdentifier.toLowerCase().includes(search)).map(
                (x: IRetailer) => <Checkbox
                  checked={selectedRetailers.indexOf(x.id) !== -1}
                  key={x.id}
                  label={x.externalIdentifier}
                  name={x.id.toString()}
                  onChange={retailerClicked}
                  isSmall={true}
                />,
              )}
            </div>
          </Card>
        </div>
        <div className="shopping-list-table">
          <div className="shopping-list-header-controls">
            <div className="shopping-list-inputs">
              <TextInput
                label="Description"
                name="description"
                onChange={value => setDescription(value)}
                type="text"
                value={description}
              />
              <Checkbox
                checked={isTradingPack}
                label="Trading Pack"
                name="trading pack"
                onChange={(id, checked) => setIsTradingPack(checked)}
              />
            </div>
            <div className="shopping-list-upload-btns">
              <Button
                type="button"
                className="tertiary create-shopping-list"
                text="Import Items Sheet"
                onClick={() => importShoppingListElement.current.click()}
              />
              <Button
                type="button"
                className="tertiary create-shopping-list"
                text="Upload Shopping List"
                disabled={!canUpload()}
                onClick={() => handleUpload()}
              />
            </div>
          </div>
          {
            !(retailers && retailers.loading) &&
            <Table className="manage-shopping-list">
              <thead>
                <tr>
                  <th className="Table--stickyColumn">MIN</th>
                  <th className="Table--fixedColumn tableActions">Action</th>
                </tr>
              </thead>
              <tbody>
                {importResults !== null
                    && importResults.map(item => (
                    <tr key={item.itemId}>
                      <td className="Table--stickyColumn">{item.itemId}</td>
                      <td className="Table--actions">
                        {setPopupConfig(item).content.actions
                          .map((action: IPopupPropsConfigContentAction, index: number): JSX.Element => {
                            return (
                              <div className="tableButtons" key={index} >
                                <span className={`icon icon-${action.label.icon}`} />
                                <button
                                  className="Popup-button"
                                  name="popupButton"
                                  data-qaid={`popupActionQaId${item.itemId}`}
                                  onClick={
                                    (): void => {
                                      action.onClick();
                                    }
                                  }
                                >
                                  {<span
                                    className={`Popup-buttonIcon icon-${action.label.text}`}
                                    title={action.label.text}
                                  />
                                  }
                                </button>
                              </div>
                            );
                          },
                          )
                        }
                        {
                          (clickedItemId === item.itemId) && (
                            <Popup
                              config={setPopupConfig(item)}
                              show={showPopup(item.itemId)}
                              targetElement={popupTargetElement}
                              uniqueKey={item.itemId}
                              close={closePopup}
                            />
                          )
                        }
                      </td>
                    </tr>
                  ))}
              </tbody>
            </Table>
          }
        </div>
      </div>
    </FullPageLayout>
  );
};

const mapStateToProps = (state: IStoreState): any => {
  return {
    retailers: state.retailers,
    shoppingListState: state.shoppingList,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<any>) => ({
  getRetailers: () => dispatch(getRetailersRequest()),
  uploadShoppingList: (body: IUploadShoppingListBody) => dispatch(uploadShoppingListRequest(body)),
});

export default connect(mapStateToProps, mapDispatchToProps)(UploadShoppingList);
