import React, { useQuery } from '@tanstack/react-query';
import { useMemo, useState } from 'react';
import { getBanners } from '../../api/Banners/bannersApi';
import { QUERY_KEYS } from '../../constants';
import { IBanner } from '../../types';
import { IBannerCarouselProps } from './IBannerCarouselProps';
import { Carousel } from 'react-bootstrap';
import styles from './BannerCarousel.module.scss'; // Import custom styles
import { NavArrowLeft, NavArrowRight, Pause, Play } from 'iconoir-react';

function BannerCarousel({
  controls,
  location,
}: Readonly<IBannerCarouselProps>): JSX.Element {
  const { data: allBanners } = useQuery({
    queryKey: [QUERY_KEYS.BANNERS],
    queryFn: getBanners,
    initialData: [],
  });

  const banners: IBanner[] = useMemo(() => {
    return allBanners.filter((x: IBanner) => x.displayLocation === location);
  }, [allBanners, location]);

  const [activeIndex, setActiveIndex] = useState(0);
  const [isPaused, setIsPaused] = useState(false);

  const handleSelect = (selectedIndex: number) => {
    if (isPaused) {
      return;
    }
    setActiveIndex(selectedIndex);
  };

  const handlePause = () => {
    setIsPaused(prev => !prev);
  };

  const handleNext = () => {
    const nextIndex = (activeIndex + 1) % banners.length;
    setActiveIndex(nextIndex);
  };

  const handlePrev = () => {
    const prevIndex = (activeIndex - 1 + banners.length) % banners.length;
    setActiveIndex(prevIndex);
  };

  if (banners.length === 0) {
    return null;
  }

  return (
    <div className={styles.carouselWrapper}>
      <Carousel
        fade
        controls={false}
        indicators={false}
        activeIndex={activeIndex}
        onSelect={handleSelect}
      >
        {banners.map((banner: IBanner) => (
          <Carousel.Item interval={banner.duration * 1000} key={banner.id} className={styles.carouselItem}>
            {banner.link.length > 0 ? (
              <a href={banner.link} target="_self" rel="noopener noreferrer">
                <img
                  title={banner.title}
                  src={banner.imageUrl}
                  alt={banner.description}
                  className={styles.carouselImage}
                />
              </a>
            ) : (
              <img
                title={banner.title}
                src={banner.imageUrl}
                alt={banner.description}
                className={styles.carouselImage}
              />
            )}
          </Carousel.Item>
        ))}
      </Carousel>
      {
        controls && banners.length > 1 && (
          <div className={styles.controls}>
            <button className={styles.controlButton} onClick={handlePrev}>
              <NavArrowLeft />
            </button>
            <div className={styles.mainControls}>
              <button className={styles.pauseButton} onClick={handlePause}>
                {isPaused ? <Play /> : <Pause />}
              </button>
              <div className={styles.separator} />
              <ol className={styles.carouselIndicators}>
                {banners.map((banner, index) => (
                  <li
                    key={`indicator_${banner.id}`}
                    className={index === activeIndex ? styles.active : ''}
                    onClick={() => setActiveIndex(index)}
                  />
                ))}
              </ol>
            </div>
            <button className={styles.controlButton} onClick={handleNext}>
              <NavArrowRight />
            </button>
          </div>
        )
      }
    </div >
  );
}

export default BannerCarousel;
