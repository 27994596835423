import Fetch from '../apiConfig/apiConfig';
import { AxiosPromise } from 'axios';
import { IShoppingList } from '../../types';

interface IGetShoppingListsResponse {
  shoppingLists: IShoppingList[];
}

export const getAdminShoppingLists = (): AxiosPromise<IGetShoppingListsResponse> => {
  return Fetch().then(axios => axios.get('/products/admin/shopping-lists'));
};

export const getShoppingLists = (): AxiosPromise<IGetShoppingListsResponse> => {
  return Fetch().then(axios => axios.get('shopping-lists'));
};

export interface IGetShoppingListParams {
  id: number;
}

interface IShoppingListResponse {
  shoppingList: IShoppingList;
}

export const getShoppingList = ({ id }: IGetShoppingListParams): AxiosPromise<IShoppingListResponse> => {
  return Fetch().then(axios => axios.get(`shopping-list?id=${id}`));
};

export interface IUpdateShoppingListItemBody {
  id: number;
  itemId: number;
}

export const updateShoppingListItem = (body: IUpdateShoppingListItemBody): AxiosPromise<IShoppingListResponse> => {
  return Fetch().then(axios => axios.post('shopping-list/update', body));
};

export interface ICreateShoppingListBody {
  description: string;
}

export const createShoppingList = (body: ICreateShoppingListBody): AxiosPromise<IGetShoppingListsResponse> => {
  return Fetch().then(axios => axios.post('shopping-list/create', body));
};

export interface IUploadShoppingListBody {
  description: string;
  retailerIds: number[];
  itemIds: number[];
  isTradingPack: boolean;
}

export interface IUploadShoppingListResponse {
  success: boolean;
}

export const uploadShoppingList = (body: IUploadShoppingListBody): AxiosPromise<IUploadShoppingListResponse> => {
  return Fetch().then(axios => axios.post('shopping-lists/upload', body));
};

export interface IDeleteShoppingListBody {
  id: number;
}

export interface IDeleteShoppingListsBody {
  ids: number[];
}

export interface IAddProductsToShoppingListBody {
  id: number;
  barcodes: string;
}

export const deleteShoppingList = (body: IDeleteShoppingListBody): AxiosPromise<IGetShoppingListsResponse> => {
  return Fetch().then(axios => axios.post('shopping-list/delete', body));
};

export const deleteShoppingLists = (body: IDeleteShoppingListsBody): AxiosPromise<IGetShoppingListsResponse> => {
  return Fetch().then(axios => axios.post('/shopping-lists/delete', body));
};

interface ISubmitShoppingListBarcodesResponse {
  submitProductBarcodes: {
    shoppingLists: IShoppingList[];
    unmatchedBarcodes: string[];
  };
}

export const addProductsToShoppingList = (
  body: IAddProductsToShoppingListBody,
): AxiosPromise<ISubmitShoppingListBarcodesResponse> => {
  return Fetch().then(axios => axios.post('shopping-list/submit-barcodes', body));
};
