import { Reducer } from 'redux';
import { IAdminContactForm } from '../../types';
import {
  POST_ADMIN_CONTACT_FORM_REQUEST,
  POST_ADMIN_CONTACT_FORM_RESPONSE,
  POST_ADMIN_CONTACT_FORM_ERROR,
 } from '../../actions/Admin/adminContactFormAction';

const initialState: IAdminContactForm = {
  loading: false,
  error: null,
};

const adminContactFormReducer: Reducer<IAdminContactForm> = (
  state: IAdminContactForm = initialState,
  action: any,
) => {
  switch (action.type) {
    case POST_ADMIN_CONTACT_FORM_REQUEST: {
      return {
        ...initialState,
        loading: true,
      };
    }
    case POST_ADMIN_CONTACT_FORM_RESPONSE: {
      return {
        ...initialState,
      };
    }
    case POST_ADMIN_CONTACT_FORM_ERROR: {
      return {
        loading: false,
        error: 'An error has occurred. Please try again.',
      };
    }
    default: {
      return state;
    }
  }
};

export default adminContactFormReducer;
