import { IOrder } from '../../../pages/Orders/IOrder';
import { IPersistOrderListSearchAction } from './IPersistOrderListSearchAction';

export const persistOrderListSearchAction = (
    searchedValue: string,
    searchedOrders: IOrder[],
    fieldToSearch?: string,
): IPersistOrderListSearchAction => {
  return  {
    type: 'ORDERS_LIST_SEARCH_PERSIST',
    data: {
      searchedOrders,
      searchedValue,
      fieldToSearch,
    },
  };
};
