import { Reducer } from 'redux';
import { IApiRequest, IDocumentBlob } from '../../types/index';
import { GET_DOCUMENT_BLOB_REQUEST,
  GET_DOCUMENT_BLOB_RESPONSE,
  GET_DOCUMENT_BLOB_ERROR } from '../../actions/Documents/downloadDocumentBlobAction';

const initialState: IApiRequest<IDocumentBlob> = {
  data: undefined,
  error: '',
  UpdatedAt: null,
  loading: false,
};

const downloadDocumentBlobReducer: Reducer<IApiRequest<IDocumentBlob>>
= (state: IApiRequest<IDocumentBlob> = initialState, action : any) => {
  switch (action.type) {
    case GET_DOCUMENT_BLOB_REQUEST: {
      return {
        data: action.response,
        error: '',
        UpdatedAt: new Date(),
        loading: false,
      };
    }
    case GET_DOCUMENT_BLOB_RESPONSE: {
      return {
        data: undefined,
        error: '',
        UpdatedAt: null,
        loading: true,
      };
    }
    case GET_DOCUMENT_BLOB_ERROR:
    default: {
      return state;
    }
  }
};

export default downloadDocumentBlobReducer;
