import { Action } from 'redux';
import {
  login, adminLogin, ILoginParams, getUserStores, loginStoresError, refresh,
  IRetailerLoginParams,
  retailerLogin,
} from '../../api/userApi';
import { USER_DETAILS } from '../userDetailsAction';
import { AxiosResponse } from 'axios';
import {
  IUserDetails,
  IAdminLoginParams,
  IAuthenticationResponse,
  IUserRetailer,
} from './interfaces/userLoginAction.interface';

export const USER_LOGIN_REQUEST = 'USER_LOGIN_REQUEST';
export type USER_LOGIN_REQUEST = typeof USER_LOGIN_REQUEST;
export const USER_LOGIN_RETAILERS_RESPONSE = 'USER_LOGIN_RETAILERS_RESPONSE';
export type USER_LOGIN_RETAILERS_RESPONSE = typeof USER_LOGIN_RETAILERS_RESPONSE;
export const USER_LOGIN_RESPONSE = 'USER_LOGIN_RESPONSE';
export type USER_LOGIN_RESPONSE = typeof USER_LOGIN_RESPONSE;
export const USER_LOGIN_ERROR = 'USER_LOGIN_ERROR';
export type USER_LOGIN_ERROR = typeof USER_LOGIN_ERROR;

export interface IUserLoginRequest extends Action {
  type: USER_LOGIN_REQUEST;
  payload: ILoginParams;
}

export interface IUserLoginResponse extends Action {
  type: USER_LOGIN_RESPONSE;
  response: IUserDetails;
}

export interface IUserLoginError extends Action {
  type: USER_LOGIN_ERROR;
  error?: string;
}

export const userLoginRequest =
  (parameters: ILoginParams, onSuccess: (userRetailers: IUserRetailer[]) => void, onStoresFailure: () => void): any => {
    return async (dispatch: any) => {
      dispatch({
        type: USER_LOGIN_REQUEST,
      });

      return login(parameters)
        .then(async (response: AxiosResponse<IAuthenticationResponse>): Promise<void> => {
          if (response.status === 200) {
            if (response.data.userDetails) {
              const hasStores = await hasUserAnyStores(response.data.userDetails.userId);

              if (!hasStores) {
                onStoresFailure();

                dispatch({
                  type: USER_LOGIN_ERROR,
                });

                return;
              }

              const userDetails = setUserLoginData(response.data.userDetails);

              dispatch({
                type: USER_DETAILS,
                payload: userDetails,
              });

              dispatch({
                type: USER_LOGIN_RESPONSE,
                response: userDetails,
              });

              onSuccess([]);
            } else {
              dispatch({
                type: USER_LOGIN_RETAILERS_RESPONSE,
                response: response.data,
              });
              localStorage.setItem('token', response.data.retailerToken);
              onSuccess(response.data.userRetailers);
            }
          } else {
            dispatch({
              type: USER_LOGIN_ERROR,
              error: 'Email address or password is incorrect.',
            });
          }
        })
        .catch((err: any) => {
          console.log(err);
          dispatch({
            type: USER_LOGIN_ERROR,
          });
        });
    };
  };

export const retailerLoginRequest =
  (parameters: IRetailerLoginParams, onSuccess: () => void, onStoresFailure: () => void): any => {
    return async (dispatch: any) => {
      dispatch({
        type: USER_LOGIN_REQUEST,
      });

      return retailerLogin(parameters)
        .then(async (response: AxiosResponse<IUserDetails>): Promise<void> => {
          if (response.status === 200) {
            const hasStores = await hasUserAnyStores(response.data.userId);

            if (!hasStores) {
              onStoresFailure();
              localStorage.removeItem('token');

              dispatch({
                type: USER_LOGIN_ERROR,
              });

              return;
            }

            const userDetails = setUserLoginData(response.data);

            dispatch({
              type: USER_DETAILS,
              payload: userDetails,
            });

            dispatch({
              type: USER_LOGIN_RESPONSE,
              response: userDetails,
            });

            onSuccess();

          } else {
            dispatch({
              type: USER_LOGIN_ERROR,
              error: 'Email address or password is incorrect.',
            });
          }
        })
        .catch((err: any) => {
          console.log(err);
          dispatch({
            type: USER_LOGIN_ERROR,
          });
        });
    };
  };

export const adminLoginRequest =
  (params: IAdminLoginParams,
    onSuccess: (userDetails: any) => void): (dispatch: () => void) => Promise<void> => {
    return (dispatch: (param: any) => void): Promise<void> => {
      return adminLogin(params).then(
        (response: AxiosResponse<IUserDetails>): void => {
          const userDetails = setUserLoginData(response.data);
          dispatch({
            type: USER_DETAILS,
            payload: userDetails,
          });
          onSuccess(userDetails);
        },
      ).catch((err: any) => {
        console.log(err);
        dispatch({
          type: USER_LOGIN_ERROR,
        });
      });
    };
  };

export const refreshToken = (): (dispatch: () => void) => Promise<void> => {
  return (dispatch: (payload: any) => void) => {
    const token = localStorage.getItem('token');
    const refreshToken = localStorage.getItem('refreshToken');
    const userId = localStorage.getItem('userId');

    if (!token || !refreshToken || !userId) {
      return;
    }

    return refresh({ refreshToken, expiredToken: token, userId: parseInt(userId, 10) })
      .then(
        (response: any) => {
          if (response.status === 400) {
            return;
          }
          const userDetails = setUserLoginData(response.data);
          dispatch({
            type: USER_DETAILS,
            payload: userDetails,
          });
        },
      )
      .catch((error: any) => console.log(error));
  };
};

const hasUserAnyStores = async (userId: number) => {
  const result = await getUserStores({ UserId: userId });
  const stores = result.data.stores;

  if (stores.length === 0) {
    void loginStoresError({ UserId: userId });
    return false;
  }
  return true;
};

export const setUserLoginData = (userDetails: IUserDetails): IUserDetails => {
  const {
    expiration,
    issuedAt,
    userId,
    claims,
    token,
    firstName,
    lastName,
    emailAddress,
    lastVisited,
    retailerId,
    isImpersonating,
    refreshToken,
    regionCode,
  } = userDetails;

  localStorage.setItem('token', token);
  localStorage.setItem('refreshToken', refreshToken);
  localStorage.setItem('userId', userId.toString());

  return {
    expiration,
    userId,
    issuedAt,
    claims,
    token,
    firstName,
    lastName,
    emailAddress,
    lastVisited,
    retailerId,
    isImpersonating,
    refreshToken,
    regionCode,
  };
};
