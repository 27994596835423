interface IViewClaimsDisplayModeAction {
  type: string;
  data: {
    displayMode: string;
  };
}
export const viewClaimsDisplayModeAction = (
  displayMode: string,
): IViewClaimsDisplayModeAction => {
  return {
    type: 'CLAIMS_DISPLAY_MODE_PERSIST',
    data: {
      displayMode,
    },
  };
};
