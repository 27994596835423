import Fetch from '../apiConfig/apiConfig';
import { AxiosPromise } from 'axios';

export interface IPostContactFormParams {
  retailer: string;
  subject: string;
  message: string;
}

export interface IContactFormResponse {
  [key: string]: any;
}

export const postContactForm = (params: IPostContactFormParams) : AxiosPromise<IContactFormResponse> => {
  return Fetch().then(axios => axios.post('users/contact', params));
};
