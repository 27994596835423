import { Action } from 'redux';
import { IFolderContents } from '../../types';
import { IEnsureFolderPathParams, ensurePath, IEnsureFolderPathResponse } from '../../api/Documents/ensurePathApi';
import { AxiosResponse } from 'axios';

export const ENSURE_PATH_REQUEST = 'ENSURE_PATH_REQUEST';
export type ENSURE_PATH_REQUEST = typeof ENSURE_PATH_REQUEST;

export const ENSURE_PATH_RESPONSE = 'ENSURE_PATH_RESPONSE';
export type ENSURE_PATH_RESPONSE = typeof ENSURE_PATH_RESPONSE;

export const ENSURE_PATH_ERROR = 'ENSURE_PATH_ERROR';
export type ENSURE_PATH_ERROR = typeof ENSURE_PATH_ERROR;

export interface IGetFolderContentsResponse extends Action {
  type: ENSURE_PATH_RESPONSE;
  response: IFolderContents;
}

export interface IGetFolderContentsError extends Action {
  type: ENSURE_PATH_ERROR;
  error?: string;
}

export const ensurePathRequest =
 (parameters: IEnsureFolderPathParams,
  onSuccess: () => void,
  onFailure: (error: string) => void): any => {
   return (dispatch : any) => {

     dispatch({
       type: ENSURE_PATH_REQUEST,
     });

     return ensurePath(parameters)
      .then((response: AxiosResponse<IEnsureFolderPathResponse>) => {
        if (response.status === 200) {
          dispatch({
            type: ENSURE_PATH_RESPONSE,
            response: response.data.ensurePath,
          });
          onSuccess();
        } else {
          const errorResponse = response as any;
          onError(dispatch, errorResponse.response.data);
          onFailure(errorResponse.response.data);
        }
      })
      .catch((err: any) => {
        console.log(err);
        onError(dispatch);
        onFailure(err);
      });
   };
 };

const onError = (dispatch: any, error?: string) => dispatch({
  error,
  type: ENSURE_PATH_ERROR,
});
