import { Action } from 'redux';
import { IUserDetails } from '../types';

export const USER_DETAILS = 'USER_DETAILS';
export type USER_DETAILS = typeof USER_DETAILS;

export interface IUpdateUserDetails extends Action {
  type: USER_DETAILS;
  payload: IUserDetails;
}

export type UserDetailsAction = IUpdateUserDetails;

export const updateUserDetails = (userDetails: IUserDetails): IUpdateUserDetails => {
  return {
    type: USER_DETAILS,
    payload: userDetails,
  };
};
